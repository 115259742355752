import React from "react";
import { employeeService } from "../../api";
import { recycleRequestService } from "../../api/recycle-request.service";
import { alertError, enums, globalPopupManager, globalPopupNames, notifySuccess, prompt2 } from "../../base";
import { AssignEmployeePopup, ContentBlock } from "../../components";
import { EmployeeRef, RecycleRequestModel } from "../../models";
import AuthorizedComponent from "../authorized-component";
import RecycleRequestGrid from "./recycle-request-grid";
import RecycleRequestToolbar from "./recycle-request-toolbar";
import RecycleRequestForm from "./recycle-request-form";

export default class RecycleRequestPage extends AuthorizedComponent {
    objectName = "RecycleRequest";
    gridRef = React.createRef<RecycleRequestGrid>();
    formRef = React.createRef<RecycleRequestForm>();
    toolbarRef = React.createRef<RecycleRequestToolbar>();
    empPopupRef = React.createRef<AssignEmployeePopup>();
    modelForAssign?: RecycleRequestModel;

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.onSaved = this.onSaved.bind(this);
        this.getDelivers = this.getDelivers.bind(this);
        this.assignDeliver = this.assignDeliver.bind(this);
    }

    get actionNames(): string[] {
        return [enums.stdActions.create, "Assign", enums.stdActions.delete];
    }

    onModelSelected(model: RecycleRequestModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onFiltering(filterValue: any) {
        this.gridRef.current?.filter(filterValue);
    }

    onButtonClick(model: RecycleRequestModel, name: string) {
        switch (name) {
            case "callcenter":
                globalPopupManager.show(globalPopupNames.callCenter, { id: model.customerId });
                break;

            case "assign":
                if (this.permissions["Assign"]) {
                    this.showEmployeePopup(model);
                }
                break;

            case "discard":
                if (this.permissions[enums.stdActions.delete]) {
                    this.discard(model);
                }               
                break;

            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.formRef.current?.show(model);
                }
                break;
        }
    }

    showEmployeePopup(model: RecycleRequestModel) {
        this.modelForAssign = model;
        this.empPopupRef.current?.show(model.deliverId);
    }

    async getDelivers() {
        const delivers = await employeeService.getDelivers();
        return delivers.filter(d => d.organizationId === this.modelForAssign?.customer?.organizationId);
    }

    async assignDeliver(deliver: EmployeeRef) {
        const result = await recycleRequestService.assign(this.modelForAssign!.id!, deliver.id!);

        if (result.succeeded) {
            notifySuccess("已成功派工");
            this.empPopupRef.current?.hide();
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async discard(model: RecycleRequestModel) {
        const note = (await prompt2("请输入作废理由："))?.trim();

        if (!note) {
            alertError("未提供理由，作废失败！");
            return;
        }

        const res = await recycleRequestService.discard(model.id!, note);
        if (res.succeeded) {
            this.gridRef.current?.refresh();
            notifySuccess("已成功作废数据");
        } else {
            alertError(res.message!);
        }
    }
    
    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return (
            <ContentBlock caption="退瓶还瓶单">
                <RecycleRequestToolbar
                    ref={this.toolbarRef} 
                    permissions={this.permissions} 
                    onFiltering={this.onFiltering}
                    onButtonClick={this.onButtonClick} />
                <RecycleRequestGrid
                    ref={this.gridRef} 
                    onButtonClick={this.onButtonClick} 
                    onModelSelected={this.onModelSelected} />
                <RecycleRequestForm ref={this.formRef} onSaved={this.onSaved} />
                <AssignEmployeePopup ref={this.empPopupRef} getFunc={this.getDelivers} save={this.assignDeliver} />
            </ContentBlock>
        )
    }
}