import { BaseApiService, createStoreFromUrl } from "../base";
import { FillingPrecheckModel } from "../models";

class FillingPrecheckService extends BaseApiService<FillingPrecheckModel> {
    moduleName = 'fillingprecheck';

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id', true);
    }
}

export const fillingPrecheckService = new FillingPrecheckService();
export const fillingPrecheckStore = fillingPrecheckService.getDxData();