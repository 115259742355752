import React from "react";
import ProductGrid from "./product-grid";
import ProductForm from "./product-form";
import AuthorizedComponent from "../authorized-component";
import { ContentBlock, StdToolbar } from "../../components";
import { productService } from "../../api";
import { alertError, confirm2, notifySuccess, enums } from "../../base";
import { ProductModel } from "../../models";

export default class ProductPage extends AuthorizedComponent {
    objectName = "Product";
    gridRef = React.createRef<ProductGrid>();
    formRef = React.createRef<ProductForm>();
    toolbarRef = React.createRef<StdToolbar>();

    constructor(props: any) {
        super(props);

        this.onProductSelected = this.onProductSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
    }

    onProductSelected(model: ProductModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onButtonClick(model: ProductModel, name: string) {
        switch (name) {
            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.formRef.current?.show({});
                }
                break;
                
            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.formRef.current?.show(model);
                }
                break;

            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(model.id!);
                }
                break;
        }
    }

    async delete(id: number) {
        if (!await confirm2("确定要删除该产品吗？")) {
            return;
        }

        const res = await productService.delete(id);

        if (res.succeeded) {
            notifySuccess("已成功删除数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(res.message!);
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return (
            <ContentBlock caption={"产品管理"}>
                <StdToolbar 
                    permissions={this.permissions}
                    ref={this.toolbarRef} 
                    onButtonClick={this.onButtonClick} />
                <ProductGrid
                    ref={this.gridRef}
                    onProductSelected={this.onProductSelected}
                    onButtonClick={this.onButtonClick} />
                <ProductForm ref={this.formRef} onSaved={this.onSaved} />
            </ContentBlock>
        );
    }
}