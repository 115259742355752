import { Popup, ScrollView } from "devextreme-react";
import React from "react";
import { announcementService } from "../../api";
import { alertError, baseApiUrl, enums, modelClone, notifySuccess, prompt2 } from "../../base";
import { PopupContentBox, RefPopup } from "../../components";
import { AnnouncementEmployeeModel } from "../../models";

export interface MyAnnouncementDetailProps {
    onSaved: () => void;
}

interface MyAnnouncementDetailState {
    model: AnnouncementEmployeeModel;
}

export default class MyAnnouncementDetail
    extends React.Component<MyAnnouncementDetailProps, MyAnnouncementDetailState> {
    popupRef = React.createRef<Popup>();

    constructor(props: Readonly<MyAnnouncementDetailProps>) {
        super(props);

        this.state = {
            model: { announcement: {} }
        };

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    async show(model: AnnouncementEmployeeModel) {
        this.popupRef.current?.instance.show();
        
        this.setState({ model: modelClone(model) });

        // 自动标志已读
        if (!model.readAt && model.announcement?.replyType === enums.announcementTypes.auto) {
            await announcementService.read(model.announcementId!);
            this.props.onSaved();
        }
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const sign = await prompt2("请签名");
        
        if (!sign) {
            alertError("未提供签名，签收失败");
            return;
        }

        const res = await announcementService.reply(this.state.model.announcementId!, sign);

        if (res.succeeded) {
            notifySuccess("签收成功");
            this.hide();
            this.props.onSaved();
        } else {
            alertError(res.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render(): React.ReactNode {
        const model = this.state.model;

        return (
            <RefPopup
                popupRef={this.popupRef}
                title={model.announcement?.caption || ""}
                width={800}
                height={600}>
                {model.announcement?.replyType === enums.announcementTypes.manual && (
                    <PopupContentBox scrollable={true} saveButtonText="签收" onSave={this.save} onCancel={this.cancel}>
                        {this.renderContent()}
                    </PopupContentBox>
                )}
                {model.announcement?.replyType !== enums.announcementTypes.manual && (
                    <ScrollView width={"100%"} scrollByContent={true}>
                        {this.renderContent()}
                    </ScrollView>
                )}
            </RefPopup>
        )
    }

    renderContent() {
        const model = this.state.model.announcement!;

        return (
            <div>
                <div className="announcement-content">
                    {(model.content || '').split('\n').map((line, index) => (
                        <p key={index}>{line.trim()}</p>
                    ))}
                </div>
                <div>
                    {model.attachments?.map(a => (
                        <div key={a.id}>
                            <a target={"_blank"} rel="noreferrer" href={`${baseApiUrl}/api/file?name=${a.path}`}>{a.name}</a> &nbsp;
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}