import { Form, Popup } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { ValueChangedEvent as TextChangedEvent } from "devextreme/ui/text_box";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import React from "react";
import { cpBatchService, cpCheckBackService, cpDataSourceService } from "../../api";
import { StringMap, alertError, dateAdd, enums, mergeModel, modelClone, notifySuccess } from "../../base";
import { PopupContentBox, RefPopup, setItemDataSource, StdForm } from "../../components";
import { CpBatchRef, CpCheckBackModel, CpDataSourceRef } from "../../models";

export interface CpCheckBackFormProps {
    onSaved: () => void;
}

interface CpCheckBackFormState {
    model: CpCheckBackModel;
    items: IItemProps[];
    batches: CpBatchRef[];
    checkBatches: CpBatchRef[];
    checkers: CpDataSourceRef[];
    organizations: CpDataSourceRef[];
    bottleTypes: CpDataSourceRef[];
}

export default class CpCheckBackForm extends React.Component<CpCheckBackFormProps, CpCheckBackFormState> {
    moduleName = "CpCheckBack";
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    batch: CpBatchRef = {};
    nameMap: StringMap = { "checkCodeNum": "checkCode" };

    constructor(props: Readonly<CpCheckBackFormProps>) {
        super(props);

        this.state = {
            model: {},
            batches: [],
            checkBatches: [],
            checkers: [],
            organizations: [],
            bottleTypes: [],
            items: [
                {
                    dataField: "checkBatchId",
                    editorType: "dxSelectBox",
                    editorOptions: {
                        dataSource: [],
                        displayExpr: 'batchCode',
                        valueExpr: 'id',
                        onValueChanged: this.onCheckBatchChanged.bind(this)
                    },
                    label: { text: "批次" }
                },
                {
                    dataField: "batchId",
                    editorType: "dxSelectBox",
                    visible: false,
                    editorOptions: {
                        dataSource: [],
                        displayExpr: 'batchCode',
                        valueExpr: 'id',
                        onValueChanged: this.onBatchChanged.bind(this)
                    },
                    label: { text: "无批次分组" }
                },
                {
                    dataField: "checkerId",
                    editorType: "dxSelectBox",
                    editorOptions: { 
                        dataSource: [], 
                        displayExpr: 'text', 
                        valueExpr: 'id',
                        onValueChanged: this.onCheckerChanged.bind(this)
                    }
                },
                {
                    dataField: "code",
                    editorOptions: {
                        onValueChanged: this.onCodeChanged.bind(this)
                    }
                },
                { 
                    dataField: "checkCodeNum",
                    editorType: "dxTextBox",
                    editorOptions: { },
                    validationRules: [{ type: "pattern", pattern: '^\\d+$', message: '请输入正确的验证码（数字）' }]
                },
                { dataField: "weight" },
                { dataField: "bottleType" },
                { dataField: "vendor" },
                { dataField: "produceDate", editorType: "dxDateBox" },
                { dataField: "enterDate", editorType: "dxDateBox" },
                {
                    dataField: "inspectDate",
                    editorType: "dxDateBox",
                    editorOptions: {
                        onValueChanged: this.onInspectDateChanged.bind(this)
                    }
                },
                { dataField: "nextInspectDate", editorType: "dxDateBox" },
                {
                    dataField: "organizationId",
                    editorType: "dxSelectBox",
                    editorOptions: { dataSource: [], displayExpr: 'text', valueExpr: 'id' }
                },
                { dataField: "barcode" },
                { dataField: "note", colSpan: 2 }
            ]
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount(): void {
        this.loadData();
    }

    async loadData() {
        let batches: CpBatchRef[] = [],
            checkBatches: CpBatchRef[] = [],
            checkers: CpDataSourceRef[] = [],
            organizations: CpDataSourceRef[] = [],
            bottleTypes: CpDataSourceRef[] = [];

        await Promise.all([
            (async () => batches = await cpBatchService.getRefs(enums.cpBatchTypes.specialCheckBack))(),
            (async () => checkBatches = await cpBatchService.getRefs(enums.cpBatchTypes.check))(),
            (async () => checkers = await cpDataSourceService.getDataSource(enums.cpDataSourceTypes.checker))(),
            (async () => organizations = await cpDataSourceService.getDataSource(enums.cpDataSourceTypes.organization))(),
            (async () => bottleTypes = await cpDataSourceService.getDataSource(enums.cpDataSourceTypes.bottleType))()
        ]);

        checkBatches.unshift({ id: 0, batchCode: '无批次' });
        batches.unshift({ id: 0, batchCode: '创建新分组' });

        setItemDataSource(this.state.items, "batchId", batches);
        setItemDataSource(this.state.items, "checkBatchId", checkBatches)
        setItemDataSource(this.state.items, "checkerId", checkers);
        setItemDataSource(this.state.items, "organizationId", organizations);

        this.setState({
            batches: batches,
            checkBatches: checkBatches,
            checkers: checkers,
            organizations: organizations,
            bottleTypes: bottleTypes
        });
    }

    async onBatchChanged(e: ValueChangedEvent) {
        const model = this.state.model;

        if (e.value && e.event) {   // 仅手工事件触发
            const template = await cpCheckBackService.getTemplate(e.value);
            mergeModel(model, template);
            this.setState({ model: model });
            this.setCheckCode();
        }
    }

    async onCheckBatchChanged(e: ValueChangedEvent) {
        const item = this.state.items.find(i => i.dataField === "batchId");
        item!.visible = e.value === 0;
        this.setState({ items: this.state.items });

        if (e.event) {   // 仅手工事件触发
            const model = this.state.model;

            if (model.code && model.code.length === 6) {
                this.loadCodeData();
            } else if ((model.checkBatchId || 0) > 0) {
                const template = await cpCheckBackService.getTemplateFromCheck(model.checkBatchId!);
                mergeModel(model, template);
                this.setState({ model: model });
                this.setCheckCode();
            }
        }
    }

    async onCodeChanged(e: TextChangedEvent) {
        if (e.event) {   // 仅手工事件触发
            this.loadCodeData();
        }
    }

    async loadCodeData() {
        const model = this.state.model;

        if (model.checkBatchId !== undefined && model.checkBatchId !== null &&
            model.code && model.code.length === 6) {
            const bottle = await cpCheckBackService.getDataByCode(model.checkBatchId, model.code);

            if (bottle) {
                model.bottleType = bottle.bottleType;
                model.vendor = bottle.vendor;
                model.produceDate = bottle.produceDate;
                model.enterDate = bottle.enterDate;
                model.weight = bottle.weight;
                model.status = bottle.organizationId ? 'Normal' : 'Exception';
                model.barcode = bottle.barcode;

                if (bottle.organizationId) {
                    model.organizationId = bottle.organizationId;
                }

                this.setState({ model: model });
                this.setNextInspectDate();
            } else {
                alertError("找不到指定的钢瓶编号");
            }
        }
    }

    onCheckerChanged() {
        this.setCheckCode();
    }

    setCheckCode() {
        const checker = this.state.checkers.find(c => c.id === this.state.model.checkerId);
        const items = this.state.items;
        const index = items.findIndex(c => c.dataField === "checkCodeNum")!;
        const copy: IItemProps = modelClone(items[index]);
        (copy as any).editorOptions.buttons = checker?.extra ? 
            [{ location: "before", name: 'p', options: { text: checker.extra, stylingMode: "text", disabled: true } }] : [];
        items.splice(index, 1, copy);

        const model = this.state.model;
        (model as any).checkCodeNum = model.checkCode ? (model.checkCode.match(/\d+/) || ["0"])[0] : "";
        
        this.setState({ model: model, items: items });
    }

    onInspectDateChanged(e: DateChangedEvent) {
        if (e.event) {
            this.setNextInspectDate();
        }
    }

    setNextInspectDate() {
        const model = this.state.model;

        if (model.inspectDate && model.bottleType) {
            const bottleType = this.state.bottleTypes.find(t => t.text === model.bottleType);
            model.bottleTypeId = bottleType?.id;

            const gasType = bottleType?.extra || 
                (model.bottleType.indexOf("HLD") !== -1 || model.bottleType.indexOf("丙烷") !== -1 ? 
                    enums.gasTypes.propane : enums.gasTypes.gas)

            const year = gasType === enums.gasTypes.propane ? 3 : 4;
            let nextInspectDate = dateAdd(dateAdd(new Date(model.inspectDate), year, "y"), -1, "m");
            if (nextInspectDate.getMonth() === new Date(model.inspectDate).getMonth())
                nextInspectDate = dateAdd(nextInspectDate, -nextInspectDate.getDate(), "d");

            if (nextInspectDate !== model.nextInspectDate) {
                model.nextInspectDate = nextInspectDate;
                this.setState({ model: model });
            }
        }
    }

    setBatch(batch: CpBatchRef) {
        const item = this.state.items.find(i => i.dataField === "batchId");
        item!.editorOptions.readOnly = true;
        setItemDataSource(this.state.items, "batchId", this.state.batches.filter(b => b.id === batch.id));

        this.batch = batch;

        this.setState({ items: this.state.items });
    }

    async show(model: CpCheckBackModel) {
        this.popupRef.current?.instance.show();

        if (this.batch.id && !model.id) {
            model.batchId = this.batch.id;
            const template = await cpCheckBackService.getTemplate(this.batch.id);
            mergeModel(model, template);
        }

        const batchItem = setItemDataSource(this.state.items, "batchId", 
            model.batch ? [model.batch] : 
            this.batch.id ? [this.batch] : this.state.batches);
        batchItem.editorOptions.readOnly = !!model.batch || !!this.batch.id;

        const checkBatchItem = setItemDataSource(
            this.state.items, "checkBatchId",
            model.batch ?
                [{ id: model.checkBatchId || 0, batchCode: (model.checkBatchId || 0) > 0 ? model.batch.batchCode : "无批次" }] :
                this.batch.id ? 
                [{ id: model.checkBatchId || 0, batchCode: (model.checkBatchId || 0) > 0 ? this.batch.batchCode : "无批次" }] : 
                this.state.checkBatches);
        checkBatchItem.editorOptions.readOnly = !!model.batch || !!this.batch.id;

        model = modelClone(model);

        this.setState({ model: model });
        this.setCheckCode();
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const model = this.state.model;
            const checker = this.state.checkers.find(v => v.id === model.checkerId);

            model.checker = checker?.text;
            model.organization = this.state.organizations.find(v => v.id === model.organizationId)?.text;
            model.checkCode = (checker?.extra || "") + ((model as any).checkCodeNum || "");

            const res = model.id ?
                await cpCheckBackService.update(model) :
                await cpCheckBackService.create(model);

            if (res.succeeded) {
                this.hide();
                notifySuccess("已成功保存");
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"编辑"}
                width={800}
                height={500}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        nameMap={this.nameMap}
                        formData={this.state.model} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}