import React from "react";
import { Form, Popup } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import { bottleTypeService, bottleService } from "../../api";
import { alertError, modelClone } from "../../base";
import { PopupContentBox, StdForm, RefPopup, setItemDataSource } from "../../components";
import { BottleModel, BottleTypeModel } from "../../models";

export interface BottleFormProps {
    onSaved: () => void;
}

interface BottleFormState {
    bottleTypes: BottleTypeModel[];
    items: IItemProps[];
    bottle: any;
}

export default class BottleForm extends React.Component<BottleFormProps, BottleFormState> {
    moduleName = "Bottle";

    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();

    constructor(props: Readonly<BottleFormProps>) {
        super(props);

        this.state = {
            bottleTypes: [],
            bottle: {},
            items: [
                { dataField: 'barCode' },
                { dataField: 'code' },
                { 
                    dataField: 'typeId',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                        dataSources: [],
                        displayExpr: 'name',
                        valueExpr: 'id'
                    }
                },
                { dataField: 'vendor' },
                { dataField: 'typeCode' },
                { dataField: 'produceDate' },
                { dataField: 'inspectDate' },
                { dataField: 'nextInspectDate' },
                { dataField: 'enterDate' },
                { dataField: 'scrapDate' },
                { dataField: 'originalWeight' },
                { dataField: 'weight' },
                { dataField: 'volume' },
                { dataField: 'thickness' },
                { dataField: 'gpressure' },
                { dataField: 'wpressure' },
                { dataField: 'phase' },
                { dataField: 'gasType' },
                { dataField: 'status' }
            ]
        };

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.loadBottleTypes();
    }
    
    async loadBottleTypes() {
        const data = await bottleTypeService.getAll();
        setItemDataSource(this.state.items, "typeId", data);

        this.setState({
            bottleTypes: data,
        });
    }

    show(bottle: BottleModel) {
        this.popupRef.current?.instance.show();

        this.setState({ bottle: modelClone(bottle) });        
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const bottle = this.state.bottle;
            const res = bottle.id ?
                await bottleService.update(bottle) :
                await bottleService.create(bottle);

            if (res.succeeded) {
                this.hide();
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup 
                popupRef={this.popupRef}
                title={"编辑"}
                width={800}
                height={450}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm 
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        formData={this.state.bottle} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}