import { createStoreFromUrl, http } from "../../base";
import { AverageUsageReport, AverageUsageReportCondition, DeliverPerformanceReport, DeliverPerformanceReportCondition, OrderForecastCondition, OrderTrendReport, OrderTrendReportCondition } from "../../models";

class OrderReportService {
    apiPath = "/api/report/order";

    getSummary(filterValue: any) {
        return http.getData(`${this.apiPath}/summary?filter=${JSON.stringify(filterValue)}`);
    }

    getPerformance(condition: DeliverPerformanceReportCondition) {
        return http.getData<DeliverPerformanceReport[]>(
            `${this.apiPath}/performance?${http.serialize([condition])}`);
    }

    getAverageUsage(condition: AverageUsageReportCondition) {
        return http.getData<AverageUsageReport[]>(
            `${this.apiPath}/averageusage?${http.serialize([condition])}`);
    }

    getTrendReport(condition: OrderTrendReportCondition) {
        return http.getData<OrderTrendReport[]>(`${this.apiPath}/trend?${http.serialize([condition])}`);
    }
  
    getForecastReport(condition: OrderForecastCondition) {
        return createStoreFromUrl(`${this.apiPath}/forecast?${http.serialize([condition])}`, 'id');
    }
}

export const orderReportService = new OrderReportService();