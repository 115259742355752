import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import { enums } from "../../base/enums";
import { bottleVendorStore } from "../../api";
import { ContentBlock, StdGrid, StdToolbar, createGridOptions, stdReadOnlyGridOptions } from "../../components";
import AuthorizedComponent from "../authorized-component";
import { BottleVendorModel } from "../../models";

export default class BottleVendorPage extends AuthorizedComponent {
    objectName = "BottleVendor";
    moduleName = "BottleVendor";
    gridRef = React.createRef<DataGrid>();
    toolbarRef = React.createRef<StdToolbar>();
    gridOptions = createGridOptions("bottleVendors", this.gridRef, stdReadOnlyGridOptions);
    columns: IColumnProps[] = [
        { dataField: 'code' },
        { dataField: 'name' }
    ];

    constructor(props: Readonly<any>) {
        super(props);

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.onButtonClick(e.data, "edit");
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.toolbarRef.current?.setModel(e.selectedRowsData[0]);
        } else {
            this.toolbarRef.current?.setModel({});
        }
    }

    onButtonClick(model: BottleVendorModel, name: string) {
        const grid = this.gridRef.current?.instance;
        
        if (grid) {
            switch (name) {
                case "add":
                    if (this.permissions[enums.stdActions.create]) {
                        grid.addRow();
                    }
                    break;

                case "edit":
                    if (this.permissions[enums.stdActions.update]) {
                        grid.editRow(grid.getRowIndexByKey(model.code));
                    }
                    break;
                
                case "delete":
                    if (this.permissions[enums.stdActions.delete]) {
                        grid.deleteRow(grid.getRowIndexByKey(model.code));
                    }
                    break;
            }
        }
    }
    
    render() {
        return (
            <ContentBlock caption={"钢瓶厂家"}>
                <StdToolbar 
                    permissions={this.permissions} 
                    ref={this.toolbarRef} 
                    onButtonClick={this.onButtonClick} />
                <StdGrid
                    moduleName={this.moduleName}
                    gridRef={this.gridRef}
                    dataSource={bottleVendorStore}
                    options={this.gridOptions}
                    columns={this.columns}
                    popupWidth={400}
                    popupHeight={250}
                    formColCount={1} />
            </ContentBlock>
        );
    }
}