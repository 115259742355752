import { MessageBool, createStoreFromUrl, http } from "../base";

class BottleFlowService {
    apiPath = "/api/bottleflow";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id', true);
    }

    setFlag(id: number, flag?: string) {
        return http.putData<MessageBool>(`${this.apiPath}/setflag/${id}?flag=${flag||''}`, '');
    }

    setLost(bottleId: number) {
        return http.postData<MessageBool>(`${this.apiPath}/setlost?bottleId=${bottleId}`, '');
    }
}

export const bottleFlowService = new BottleFlowService();
export const bottleFlowStore = bottleFlowService.getDxData();