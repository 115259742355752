import Popup, { IPopupOptions } from "devextreme-react/popup";
import { PropsWithChildren } from "react";
import { createResizablePopupOptions } from "../options";
import { useScreenSize } from "../../hooks";

export interface RefPopupProps {
    popupRef: React.LegacyRef<Popup>;
    title: string;
    width?: any;
    height?: any;
    showCloseButton?: boolean;
    baseOptions?: IPopupOptions;
}

export default function RefPopup(props: PropsWithChildren<RefPopupProps>) {
    const screenSize = useScreenSize();
    const options = createResizablePopupOptions(
        props.width, 
        props.height, 
        props.showCloseButton !== false, 
        props.baseOptions, 
        screenSize);

    return (
        <Popup
            ref={props.popupRef}
            title={props.title}
            { ...options }>
            {props.children}
        </Popup>
    )

}