import React from "react";
import DropDownButton from "devextreme-react/drop-down-button";
import ImageUploader from "../file/image-uploader";
import { baseApiUrl, temporaryUploadUrl } from "../../base";

export interface FormImageUploaderProps {
    formData: any;
    dataField: string;
    sourceField: string;
    id: string;
    uploadUrl?: string;
}


export default class FormImageUploader extends React.Component<FormImageUploaderProps, any> {
    constructor(props: Readonly<FormImageUploaderProps>) {
        super(props);

        this.renderImageUploader = this.renderImageUploader.bind(this);
        this.onUploaded = this.onUploaded.bind(this);
    }

    onUploaded(tmpName: string, source: any) {
        this.props.formData[this.props.dataField] = tmpName;
        this.props.formData[this.props.sourceField] = source;

        this.setState({});
    }

    render() {
        const { formData, dataField } = this.props;
        const fileName: string = formData[dataField];
        const name = fileName ? fileName.split('/').pop() : undefined;

        return (
            <DropDownButton 
                width={"100%"}
                className={"file-dropdown"}
                text={name || "请选择要上传的图片..."}
                dropDownContentRender={this.renderImageUploader} />
        )
    }

    renderImageUploader() {
        const { formData, sourceField, dataField, id, uploadUrl } = this.props;
        const source = formData[sourceField] || 
            (formData[dataField] && `${baseApiUrl}/api/file?name=${formData[dataField]}`);
        const url = `${baseApiUrl}${uploadUrl || temporaryUploadUrl}`;

        return (
            <ImageUploader
                id={id}
                uploadUrl={url} 
                source={source}
                onUploaded={this.onUploaded} />
        );
    }
}