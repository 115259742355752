import React from "react";
import { Button, DateBox, SelectBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { DataMap, dateAdd, enums, ListItem, today } from "../../base";
import { AreaRef, BottleDebtModel, EmployeeRef, OrganizationRef } from "../../models";
import { areaService, employeeService, emptyDataSources, organizationService } from "../../api";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { OrgTreeDropDown } from "../../components";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { ValueChangedEvent as TextChangedEvent } from "devextreme/ui/text_box";

interface BottleDebtToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (bottleDebt: BottleDebtModel, name: string) => void;
    onFiltering: (filterValue: any, showDeleted: boolean) => void;
}

interface BottleDebtToolbarState {
    bottleDebt: BottleDebtModel;
    orgs: OrganizationRef[];
    allEmployees: EmployeeRef[];
    employees: EmployeeRef[];
    counties: AreaRef[];
    allStreets: AreaRef[];
    streets: AreaRef[];
    organizationId?: number;
    createOperator?: string;
    completeOperator?: string;
    countyId?: number;
    streetId?: number;
    timeProperty: "chargedAt" | "completedAt";
    startDate?: Date | string;
    endDate?: Date | string;
    showDeleted: boolean;
    customerKey?: string;
    photoStatus?: string;
}

export default class BottleDebtToolbar
    extends React.Component<BottleDebtToolbarProps, BottleDebtToolbarState> {
    constructor(props: Readonly<BottleDebtToolbarProps>) {
        super(props);

        const startDate = today();

        this.state = {
            bottleDebt: {},
            orgs: [],
            allEmployees: [],
            employees: [],
            counties: [],
            allStreets: [],
            streets: [],
            timeProperty: 'chargedAt',
            showDeleted: false,
            startDate: dateAdd(startDate, -7),
            endDate: startDate
        };

        this.onTimePropertyChanged = this.onTimePropertyChanged.bind(this);
        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onCreateOperatorChanged = this.onCreateOperatorChanged.bind(this);
        this.onCompleteOperatorChanged = this.onCompleteOperatorChanged.bind(this);
        this.onCountyChanged = this.onCountyChanged.bind(this);
        this.onStreetChanged = this.onStreetChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);
        this.onPhotoStatusChanged = this.onPhotoStatusChanged.bind(this);
        this.onCustomerChanged = this.onCustomerChanged.bind(this);
    }

    componentDidMount() {
        this.loadDataSources();
        setTimeout(this.onFiltering, 100);
    }

    async loadDataSources() {
        const dataSources = emptyDataSources();

        await Promise.all([
            (async () => dataSources.orgs = await organizationService.getRefs())(),
            (async () => dataSources.counties = await areaService.getCounties())(),
            (async () => dataSources.streets = await areaService.getStreets())(),
            (async () => dataSources.employees = await employeeService.getAllRefs())()
        ]);

        this.setState({
            orgs: dataSources.orgs,
            counties: dataSources.counties,
            allStreets: dataSources.streets,
            streets: dataSources.streets,
            allEmployees: dataSources.employees,
            employees: dataSources.employees
        })
    }

    setBottleDebt(bottleDebt: BottleDebtModel) {
        this.setState({ bottleDebt: bottleDebt });
    }

    onTimePropertyChanged(e: ValueChangedEvent) {
        this.setState({ timeProperty: e.value });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onOrganizationChanged(organizationId?: number) {
        const employees = organizationId ?
            this.state.allEmployees.filter(e => e.organizationId === organizationId) :
            this.state.allEmployees;

        const createOperator = employees.find(e => e.id === this.state.createOperator) ? 
            this.state.createOperator : undefined;

        const completeOperator = employees.find(e => e.id === this.state.completeOperator) ?
            this.state.completeOperator : undefined;

        this.setState({ 
            organizationId: organizationId,
            employees: employees,
            createOperator: createOperator,
            completeOperator: completeOperator 
        });
    }

    onCreateOperatorChanged(e: ValueChangedEvent) {
        this.setState({ createOperator: e.value });
    }

    onCompleteOperatorChanged(e: ValueChangedEvent) {
        this.setState({ completeOperator: e.value });
    }

    onFiltering() {
        this.props.onFiltering(this.getFilterValue(), this.state.showDeleted);
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { organizationId, createOperator, completeOperator, countyId, streetId, 
            timeProperty, startDate, endDate, customerKey, photoStatus } = this.state;

        if (startDate) {
            filters.push([timeProperty, ">=", startDate]);
        }
        if (endDate) {
            filters.push([timeProperty, "<", dateAdd(new Date(endDate), 1)])
        }
        if (organizationId) {
            filters.push(["customer.organizationId", "=", organizationId]);
        }
        if (createOperator) {
            filters.push(["createOperator", "=", createOperator]);
        }
        if (completeOperator) {
            filters.push(["completeOperator", "=", completeOperator]);
        }
        if (countyId) {
            filters.push(["customer.areaId", "=", countyId]);
        }
        if (streetId) {
            filters.push(["customer.streetId", "=", streetId]);
        }
        if (photoStatus) {
            if (photoStatus === "exception") {
                filters.push(["photo", "=", null]);
            } else {
                filters.push(["photo", "<>", null]);
            }
        }
        if (customerKey) {
            filters.push([
                [ "customer.gasCode", "contains", customerKey],
                "or",
                [ "customer.name", "contains", customerKey]
            ]);
        }

        return filters.length > 0 ? filters : null;
    }

    onCountyChanged(e: ValueChangedEvent) {
        const streets = e.value ?
            this.state.allStreets.filter(s => s.parentId === e.value) :
            this.state.allStreets;

        const streetId = streets.find(s => s.id === this.state.streetId) ? this.state.streetId : undefined;

        this.setState({
            countyId: e.value,
            streets: streets,
            streetId: streetId
        });
    }

    onStreetChanged(e: ValueChangedEvent) {
        this.setState({ streetId: e.value });
    }

    onStatusChanged(e: ValueChangedEvent) {
        this.setState({ showDeleted: e.value });
    }

    onPhotoStatusChanged(e: ValueChangedEvent) {
        this.setState({ photoStatus: e.value });
    }

    onCustomerChanged(e: TextChangedEvent) {
        this.setState({ customerKey: e.value });
    }

    render() {
        const { bottleDebt, orgs, counties, streets, organizationId, countyId, streetId, employees, 
            createOperator, completeOperator, timeProperty, startDate, endDate, showDeleted, customerKey, photoStatus } = this.state;
        const permissions = this.props.permissions;
        const canReceived = !!bottleDebt?.id && 
            bottleDebt.type === enums.debtTypes.deposit && 
            !bottleDebt.depositReceived && 
            !bottleDebt.deleted;

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={timeProperty}
                            dataSource={timePropertySource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={100}
                            onValueChanged={this.onTimePropertyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={startDate}
                            placeholder="开始时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions[enums.stdActions.create]}>
                        <Button
                            text="新增"
                            onClick={() => this.props.onButtonClick({}, "add")} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions[enums.stdActions.update]}>
                        <Button
                            text="退还"
                            disabled={!bottleDebt?.id || bottleDebt.completed}
                            onClick={() => this.props.onButtonClick(bottleDebt, "edit")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions["Archive"]}>
                        <Button
                            text="归档"
                            disabled={!bottleDebt?.id || bottleDebt.archived}
                            onClick={() => this.props.onButtonClick(bottleDebt, "archive")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions["SetRecieved"]}>
                        <Button
                            text="回款"
                            disabled={!canReceived}
                            onClick={() => this.props.onButtonClick(bottleDebt, "setreceived")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.update]}>
                        <Button
                            text="备注"
                            disabled={!bottleDebt?.id}
                            onClick={() => this.props.onButtonClick(bottleDebt, "note")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.update]}>
                        <Button
                            text="上传照片"
                            disabled={!bottleDebt?.id}
                            onClick={() => this.props.onButtonClick(bottleDebt, "uploadphoto")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.delete]}>
                        <Button
                            text="作废"
                            disabled={!bottleDebt?.id || bottleDebt?.deleted}
                            onClick={() => this.props.onButtonClick(bottleDebt, "delete")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" 
                        visible={permissions[enums.stdActions.delete] && !!bottleDebt?.deleted}>
                        <Button
                            text="还原"
                            onClick={() => this.props.onButtonClick(bottleDebt, "restore")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            text="导出押金明细"
                            onClick={() => this.props.onButtonClick(bottleDebt, "exportdetail")} />
                    </Item>
                </Toolbar>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={showDeleted}
                            dataSource={statusSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={120}
                            onValueChanged={this.onStatusChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={createOperator}
                            dataSource={employees}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={160}
                            placeholder="操作员"
                            onValueChanged={this.onCreateOperatorChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={completeOperator}
                            dataSource={employees}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={160}
                            placeholder="归还操作员"
                            onValueChanged={this.onCompleteOperatorChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={countyId}
                            dataSource={counties}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder="行政区域"
                            onValueChanged={this.onCountyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={streetId}
                            dataSource={streets}
                            displayExpr="name"
                            valueExpr={"id"}
                            searchEnabled={true}
                            showClearButton={true}
                            width={100}
                            placeholder="街道"
                            onValueChanged={this.onStreetChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={photoStatus}
                            dataSource={photoStatusList}
                            displayExpr="text"
                            valueExpr={"value"}
                            showClearButton={true}
                            width={120}
                            placeholder="照片状态"
                            onValueChanged={this.onPhotoStatusChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <TextBox
                            value={customerKey}
                            showClearButton={true}
                            placeholder="客户名称或卡号"
                            onValueChanged={this.onCustomerChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
               </Toolbar>
            </div>
        )
    }
}

const timePropertySource: ListItem<string>[] = [
    { value: "chargedAt", text: "创建时间" },
    { value: "completedAt", text: "退还时间" },
    { value: "receivedAt", text: "回款时间" }
]

const statusSource: ListItem<boolean>[] = [
    { value: false, text: "查看正常数据" },
    { value: true, text: "查看已作废" }
]

const photoStatusList: ListItem<string>[] = [
    { value: "normal", text: "图片正常" },
    { value: "exception", text: "图片缺失" }
]