import React from "react";
import { areaService, employeeService, emptyDataSources, organizationService } from "../../api";
import { DataMap, dateAdd, today } from "../../base";
import { AreaRef, EmployeeRef, OrderEvaluationModel, OrganizationRef } from "../../models";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { ValueChangedEvent as TextChangedEvent } from "devextreme/ui/text_box";
import { Button, DateBox, SelectBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { OrgTreeDropDown } from "../../components";

export interface OrderEvaluationToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (model: OrderEvaluationModel, name: string) => void;
    onFiltering: (filterValue: any) => void;
}

interface OrderEvaluationToolbarState {
    model: OrderEvaluationModel;
    orgs: OrganizationRef[];
    allDelivers: EmployeeRef[];
    delivers: EmployeeRef[];
    counties: AreaRef[];
    allStreets: AreaRef[];
    streets: AreaRef[];
    organizationId?: number;
    deliverId?: string;
    countyId?: number;
    streetId?: number;
    startDate?: Date|string;
    endDate?: Date|string;
    customerKey?: string;
}

export default class OrderEvaluationToolbar 
    extends React.Component<OrderEvaluationToolbarProps, OrderEvaluationToolbarState> {
    constructor(props: Readonly<OrderEvaluationToolbarProps>) {
        super(props);

        const endDate = today();

        this.state = {
            model: {},
            orgs: [],
            allDelivers: [],
            delivers: [],
            counties: [],
            allStreets: [],
            streets: [],
            startDate: dateAdd(endDate, -1, "m"),
            endDate: endDate,
        };

        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onDeliverChanged = this.onDeliverChanged.bind(this);
        this.onCountyChanged = this.onCountyChanged.bind(this);
        this.onStreetChanged = this.onStreetChanged.bind(this);
        this.onCustomerChanged = this.onCustomerChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount() {
        this.loadDataSources();
        setTimeout(this.onFiltering, 500);
    }

    async loadDataSources() {
        const dataSources = emptyDataSources();

        await Promise.all([
            (async () => dataSources.orgs = await organizationService.getRefs())(),
            (async () => dataSources.employees = await employeeService.getDelivers())(),
            (async () => dataSources.counties = await areaService.getCounties())(),
            (async () => dataSources.streets = await areaService.getStreets())(),
        ]);

        this.setState({
            orgs: dataSources.orgs,
            allDelivers: dataSources.employees,
            delivers: dataSources.employees,
            counties: dataSources.counties,
            allStreets: dataSources.streets,
            streets: dataSources.streets
        });
    }

    setModel(model: OrderEvaluationModel) {
        this.setState({ model: model });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onOrganizationChanged(organizationId?: number) {
        const delivers = organizationId ?
            this.state.allDelivers.filter(d => d.organizationId === organizationId) :
            this.state.allDelivers;

        const deliverId = delivers.find(d => d.id === this.state.deliverId) ? this.state.deliverId : undefined;

        this.setState({
            organizationId: organizationId,
            delivers: delivers,
            deliverId: deliverId
        });
    }

    onFiltering() {
        this.props.onFiltering(this.getFilterValue());
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { startDate, endDate, organizationId, deliverId, countyId, streetId, customerKey } = this.state;

        if (startDate) {
            filters.push(["evaluatedAt", ">=", startDate]);
        }
        if (endDate) {
            filters.push(["evaluatedAt", "<", dateAdd(new Date(endDate), 1)])
        }
        if (organizationId) {
            filters.push(["order.organizationId", "=", organizationId]);
        }
        if (deliverId) {
            filters.push(["order.deliverId", "=", deliverId]);
        }
        if (countyId) {
            filters.push(["order.customer.areaId", "=", countyId]);
        }
        if (streetId) {
            filters.push(["order.customer.streetId", "=", streetId]);
        }
        if (customerKey) {
            filters.push([
                [ "order.customer.gasCode", "contains", customerKey],
                "or",
                [ "order.customer.name", "contains", customerKey]
            ]);
        }

        return filters.length > 0 ? filters : null;
    }

    onDeliverChanged(e: ValueChangedEvent) {
        this.setState({ deliverId: e.value });
    }

    onCountyChanged(e: ValueChangedEvent) {
        const streets = e.value ?
            this.state.allStreets.filter(s => s.parentId === e.value) :
            this.state.allStreets;

        const streetId = streets.find(s => s.id === this.state.streetId) ? this.state.streetId : undefined;

        this.setState({
            countyId: e.value,
            streets: streets,
            streetId: streetId
        });
    }

    onStreetChanged(e: ValueChangedEvent) {
        this.setState({ streetId: e.value });
    }

    onCustomerChanged(e: TextChangedEvent) {
        this.setState({ customerKey: e.value });
    }

    render() {
        const { orgs, delivers, counties, streets, organizationId, deliverId, 
            countyId, streetId, startDate, endDate, customerKey } = this.state;

            return (
                <div className={"page-toolbar page-large-toolbar"}>
                    <Toolbar>
                        <Item location={"before"} locateInMenu="false" text={"评价时间"}>
                        </Item>
                        <Item location={"before"} locateInMenu="auto">
                            <DateBox
                                value={startDate}
                                placeholder="开始时间"
                                width={120}
                                onValueChanged={this.onStartDateChanged} />
                        </Item>
                        <Item location={"before"} locateInMenu="auto">
                            <DateBox
                                value={endDate}
                                placeholder="结束时间"
                                width={120}
                                onValueChanged={this.onEndDateChanged} />
                        </Item>
                        <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                            <OrgTreeDropDown
                                organizationId={organizationId}
                                organizations={orgs}
                                placeholder="供应站"
                                width={180}
                                onValueChanged={this.onOrganizationChanged} />
                        </Item>
                        <Item location={"before"} locateInMenu={"auto"}>
                            <SelectBox
                                value={deliverId}
                                dataSource={delivers}
                                displayExpr="nameAndCode"
                                valueExpr="id"
                                searchEnabled={true}
                                showClearButton={true}
                                width={160}
                                placeholder="送气工"
                                onValueChanged={this.onDeliverChanged} />
                        </Item>
                        <Item location={"before"} locateInMenu={"auto"}>
                            <SelectBox
                                value={countyId}
                                dataSource={counties}
                                displayExpr="name"
                                valueExpr={"id"}
                                showClearButton={true}
                                width={100}
                                placeholder="行政区域"
                                onValueChanged={this.onCountyChanged} />
                        </Item>
                        <Item location={"before"} locateInMenu={"auto"}>
                            <SelectBox
                                value={streetId}
                                dataSource={streets}
                                displayExpr="name"
                                valueExpr={"id"}
                                searchEnabled={true}
                                showClearButton={true}
                                width={100}
                                placeholder="街道"
                                onValueChanged={this.onStreetChanged} />
                        </Item>
                        <Item location={"before"} locateInMenu="auto">
                            <TextBox
                                value={customerKey}
                                showClearButton={true}
                                placeholder="客户名称或卡号"
                                onValueChanged={this.onCustomerChanged}
                                onEnterKey={this.onFiltering} />
                        </Item>
                        <Item location={"before"} locateInMenu="false">
                            <Button
                                text="查询"
                                type="default"
                                onClick={this.onFiltering} />
                        </Item>
                    </Toolbar>
                </div>
            );    
    }

}