import CustomStore from "devextreme/data/custom_store";
import { ExceptionDataSummary, exceptionTypes, OrganizationRef } from "../../../models";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import React from "react";
import { createEmptyStore, dateAdd, fdate, historyInstance, today } from "../../../base";
import { createGridOptions, StdGrid, stdReadOnlyGridOptions } from "../../../components";
import { organizationService } from "../../../api";
import { exceptionDataService } from "../../../api/exception-data.service";

export interface ExceptionDataSummaryGridProps {
    groupType: string;
    type?: string;
    organizationId?: number;
    onModelSelected(model: ExceptionDataSummary): void;
    onDataLoaded(data: ExceptionDataSummary[]): void;
}

export interface ExceptionDataSummaryGridState {
    store: CustomStore;
    orgs: OrganizationRef[];
    columns: IColumnProps[];
}

export default class ExceptionDataSummaryGrid
    extends React.Component<ExceptionDataSummaryGridProps, ExceptionDataSummaryGridState> {
    moduleName = "ExceptionData";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions(
        "exceptionData",
        this.gridRef,
        stdReadOnlyGridOptions,
        (items: any[]) => {
            // state清空会同时清空filter，需要重新绑定旧的
            items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
            return items;
        });

    constructor(props: Readonly<ExceptionDataSummaryGridProps>) {
        super(props);

        this.state = {
            store: createEmptyStore(),
            orgs: [],
            columns: [
                {
                    dataField: "type",
                    cellRender: (e: any) => {
                        return (this.props.type || this.props.organizationId || this.state.orgs.length === 1) ?
                            (<React.Fragment>{exceptionTypes.find(t => t.value === e.data.type)?.text ?? ""}</React.Fragment>) :
                            (
                                <span className="link-button" onClick={() => this.onTypeChanged(e.data.type)}>
                                    {exceptionTypes.find(t => t.value === e.data.type)?.text ?? ""}
                                </span>
                            )
                    }
                },
                {
                    dataField: "organizationId",
                    cellRender: (e: any) => {
                        return (this.props.type || this.props.organizationId || this.state.orgs.length === 1) ?
                            (<React.Fragment>{this.state.orgs.find(o => o.id === e.data.organizationId)?.name ?? ""}</React.Fragment>) :
                            (
                                <span className="link-button" onClick={() => this.onOrganizationIdChanged(e.data.organizationId)}>
                                    {this.state.orgs.find(o => o.id === e.data.organizationId)?.name ?? ""}
                                </span>
                            )
                    }
                },
                {
                    dataField: "yearDataCount",
                    caption: "一年内",
                    cellRender: (e: any) => {
                        return (
                            <span className="link-button" onClick={() => this.gotoDetail(e.data, 'Year')}>{e.data.yearDataCount}</span>
                        )
                    }
                },
                {
                    dataField: "monthDataCount",
                    caption: "一月内",
                    cellRender: (e: any) => {
                        return (
                            <span className="link-button" onClick={() => this.gotoDetail(e.data, 'Month')}>{e.data.monthDataCount}</span>
                        )
                    }
                },
                {
                    dataField: "weekDataCount",
                    caption: "一周内",
                    cellRender: (e: any) => {
                        return (
                            <span className="link-button" onClick={() => this.gotoDetail(e.data, 'Week')}>{e.data.weekDataCount}</span>
                        )
                    }
                },
                {
                    dataField: "dayDataCount",
                    caption: "今天",
                    cellRender: (e: any) => {
                        return (
                            <span className="link-button" onClick={() => this.gotoDetail(e.data, 'Day')}>{e.data.dayDataCount}</span>
                        )
                    }
                },
                { dataField: "latestTime", caption: "最近发生时间", dataType: "datetime" }
            ]
        }
    }

    async componentDidMount(): Promise<void> {
        await this.loadOrgs();
        this.loadData();
    }

    async loadOrgs() {
        const orgs = await organizationService.getAllRefs();
        this.setState({ orgs: orgs });
    }

    componentDidUpdate(prevProps: Readonly<ExceptionDataSummaryGridProps>): void {
        if ((prevProps.groupType !== this.props.groupType ||
            prevProps.type !== this.props.type ||
            prevProps.organizationId !== this.props.organizationId) && 
            this.state.orgs.length > 0) {
            this.loadData();
        }
    }

    async loadData() {
        const { groupType, type, organizationId } = this.props;

        const list = groupType === 'OrganizationId' ?
            (organizationId ?
                await exceptionDataService.getSummaryByType(organizationId) :
                await exceptionDataService.GetSummaryByOrganization()) :
            (type ?
                await exceptionDataService.GetSummaryByOrganization(type) :
                await exceptionDataService.getSummaryByType());

        this.setState({
            store: new CustomStore(
                {
                    key: groupType === 'OrganizationId' ?
                        (organizationId ? ['type', 'organizationId'] : 'organizationId') :
                        (type ? ['type', 'organizationId'] : 'type'),
                    load: function () {
                        return list;
                    }
                })
        });

        this.props.onDataLoaded(list);
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    onTypeChanged(type: string) {
        historyInstance.push(`/report/exception-summary?groupType=Type&type=${type}`);
    }

    onOrganizationIdChanged(organizationId: number) {
        historyInstance.push(`/report/exception-summary?groupType=OrganizationId&organizationId=${organizationId}`);
    }

    gotoDetail(data: ExceptionDataSummary, summaryType: string) {
        const day = today();
        var url = `/exception-data?endTime=${fdate(day, true)}`;

        if (data.type) {
            url += '&type=' + data.type;
        }

        if (data.organizationId) {
            url += '&organizationId=' + data.organizationId;
        }

        switch (summaryType) {
            case "Year":
                url += `&startTime=${fdate(dateAdd(day, -1, 'y'), true)}`;
                break;
            case "Month":
                url += `&startTime=${fdate(dateAdd(day, -1, 'm'), true)}`;
                break;
            case "Week":
                url += `&startTime=${fdate(dateAdd(day, -6), true)}`;
                break;
            case "day":
            default:
                url += `&startTime=${fdate(day, true)}`;
                break;
        }

        historyInstance.push(url);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns}>
            </StdGrid>
        )
    }
}

