import { Button, DateBox, SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import React from "react";
import { employeeService, organizationService } from "../../../api";
import { OrgTreeDropDown } from "../../../components";
import { EmployeeRef, exceptionTypes, OrganizationRef } from "../../../models";
import { DataMap, ListItem, dateAdd, enums, today } from "../../../base";

export interface ExceptionDataToolbarProps {
    urlData: any;
    permissions: DataMap<boolean>;
    onFiltering: (filterValue: any) => void;
    handle: (ids: number[]) => void;
}

interface ExceptionDataToolbarState {
    orgs: OrganizationRef[];
    allEmployees: EmployeeRef[];
    employees: EmployeeRef[];
    type?: string;
    organizationId?: number;
    employeeId?: string;
    ids: number[];
    handled?: boolean;
    startTime?: Date | string;
    endTime?: Date | string;
}

export default class ExceptionDataToolbar extends
    React.Component<ExceptionDataToolbarProps, ExceptionDataToolbarState> {

    constructor(props: Readonly<ExceptionDataToolbarProps>) {
        super(props);

        const urlData = props.urlData;
        const day = today();

        this.state = {
            orgs: [],
            allEmployees: [],
            employees: [],
            ids: [],
            startTime: urlData.startTime || dateAdd(day, -7),
            endTime: urlData.endTime || day,
            type: urlData.type || undefined,
            organizationId: urlData.organizationId ? parseInt(urlData.organizationId) : undefined
        };

        this.onTypeChanged = this.onTypeChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onEmployeeChanged = this.onEmployeeChanged.bind(this);
        this.onHandledChanged = this.onHandledChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.handle = this.handle.bind(this);
        this.onStartTimeChanged = this.onStartTimeChanged.bind(this);
        this.onEndTimeChanged = this.onEndTimeChanged.bind(this);
    }

    componentDidMount() {
        this.loadData();

        setTimeout(this.onFiltering, 100);
    }

    async loadData() {
        let orgs: OrganizationRef[] = [], employees: EmployeeRef[] = [];

        await Promise.all([
            (async () => orgs = await organizationService.getRefs())(),
            (async () => employees = await employeeService.getRefs())()
        ]);

        this.setState({
            orgs: orgs,
            allEmployees: employees,
            employees: employees
        });
    }

    setIds(ids: number[]) {
        this.setState({ ids: ids });
    }

    onTypeChanged(e: ValueChangedEvent) {
        this.setState({ type: e.value });
    }

    onOrganizationChanged(organizationId?: number) {
        const employees = organizationId ?
            this.state.allEmployees.filter(e => e.organizationId === organizationId) :
            this.state.allEmployees;

        const employeeId = employees.find(e => e.id === this.state.employeeId) ?
            this.state.employeeId : undefined;

        this.setState({
            organizationId: organizationId,
            employees: employees,
            employeeId: employeeId,
        });
    }

    onEmployeeChanged(e: ValueChangedEvent) {
        this.setState({ employeeId: e.value });
    }

    onHandledChanged(e: ValueChangedEvent) {
        this.setState({ handled: e.value });
    }

    onStartTimeChanged(e: DateChangedEvent) {
        this.setState({ startTime: e.value });
    }

    onEndTimeChanged(e: DateChangedEvent) {
        this.setState({ endTime: e.value });
    }

    onFiltering() {
        this.props.onFiltering(this.getFilterValue());
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { type, employeeId, organizationId, handled, startTime, endTime } = this.state;

        if (type) {
            filters.push(["type", "=", type]);
        }

        if (organizationId) {
            filters.push(["organizationId", "=", organizationId]);
        }

        if (employeeId) {
            filters.push(["operatorId", "=", employeeId]);
        }

        if (handled === true) {
            filters.push(["handledAt", "<>", null]);
        } else if (handled === false) {
            filters.push(["handledAt", "=", null]);
        }

        if (startTime) {
            filters.push(["operationTime", ">=", startTime]);
        }

        if (endTime) {
            filters.push(["operationTime", "<", dateAdd(new Date(endTime), 1)])
        }

        return filters.length > 0 ? filters : null;
    }

    handle() {
        this.props.handle(this.state.ids);
    }

    render() {
        const { startTime, endTime, orgs, employees, type, organizationId, employeeId, ids, handled } = this.state;
        const permissions = this.props.permissions;

        return (
            <div className="page-toolbar">
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <DateBox
                            value={startTime}
                            placeholder="开始时间"
                            type="datetime"
                            showClearButton={true}
                            onValueChanged={this.onStartTimeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endTime}
                            placeholder="结束时间"
                            type="datetime"
                            showClearButton={true}
                            onValueChanged={this.onEndTimeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={type}
                            dataSource={exceptionTypes}
                            displayExpr={"text"}
                            valueExpr={"value"}
                            width={180}
                            onValueChanged={this.onTypeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="选择供应站过滤"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={employeeId}
                            dataSource={employees}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={160}
                            placeholder="员工过滤"
                            onValueChanged={this.onEmployeeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={handled}
                            dataSource={handleList}
                            displayExpr={"text"}
                            valueExpr={"value"}
                            showClearButton={true}
                            width={120}
                            placeholder="是否处理"
                            onValueChanged={this.onHandledChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <Button text="查询" onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions[enums.stdActions.update]}>
                        <Button disabled={!ids.length} text="设置为已处理" onClick={this.handle} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}

const handleList: ListItem<boolean>[] = [
    { value: true, text: "已处理" },
    { value: false, text: "未处理" }
];