import { IPopupOptions } from "devextreme-react/popup";
import { ScreenSize } from "../../hooks";

export const stdPopupOptions: IPopupOptions = {
    maxWidth: "98%",
    maxHeight: "98%",
    resizeEnabled: true,
    dragEnabled: true
};

export const stdGridPopupOptions: IPopupOptions = {
    ...stdPopupOptions,
    showTitle: true,
    title: "编辑"
};

export function createResizablePopupOptions(
    width: any,
    height: any,
    showCloseButton?: boolean,
    baseOptions?: IPopupOptions,
    screenSize?: ScreenSize)
    : IPopupOptions {

    return {
        ...(baseOptions || stdPopupOptions),
        showCloseButton: showCloseButton,
        maxWidth: "98%",
        maxHeight: "98%",
        defaultWidth: screenSize?.isXSmall ? "90%" : width,
        defaultHeight: screenSize?.isXSmall && height > 300 ? "95%" : height
    }
}