import "./exception-setting.scss";
import { Button, RadioGroup, ScrollView, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { ContentBlock, FullPanel } from "../../components";
import { dataSources, DataMap, alertError, notifySuccess } from "../../base";
import { exceptionSettingService } from "../../api";
import AuthorizedComponent from "../authorized-component";

export default class ExceptionSettingPage extends AuthorizedComponent {
    objectName = "ExceptionSetting";
    moduleName = "ExceptionSetting";
    flowValues = [{ value: '', text: '使用默认值' }, ...dataSources.exceptionSettingValues];
    otherValues = [{ value: '', text: '使用默认值' }, ...dataSources.otherExceptionSettingValues];

    defaultData: any[] = [
        { key: `Default`, text: '特定异常默认', dataSource: dataSources.otherExceptionSettingValues },
        { key: `BottleFlow`, text: '流转记录默认', dataSource: dataSources.exceptionSettingValues }
    ];

    flowData: any[] = dataSources.bottleOperations.map(o => ({
        key: `BottleFlow.${o.value}`,
        text: o.text
    }));

    otherData: any[] = [
        // { value: 'BarcodeNotEqualsCode', text: '重瓶领出时发现条码和钢印号不一致' },
        { value: 'RequireFilling', text: '使用没有任何充装记录的气瓶' },
        // { value: 'NoRepeatFlow', text: '在短时间内创建重复的流转记录' },
        { value: 'StockOutTimeout', text: '气瓶领出后超时未送达' },
        { value: 'FillingTimeout', text: '气瓶充装后未送达' },
        { value: 'RecycleTimeout', text: '气瓶回收后超时未入库' },
        { value: 'RepeatDeliver', text: '重瓶多次送达' },
        { value: 'NoRecycle', text: '回收空瓶未扫描' },
        { value: 'RecycleNotMatch', text: '回收气瓶不匹配' },
        // { value: 'EmptyInNotMatch', text: '入库空瓶异常' },
        { value: 'SiteNotMatch', text: '窜瓶' },
        { value: 'OverloadDeliver', text: '超限额配送' },
        { value: 'EmptyInTimeout', text: '气瓶入库超时未充装' },
    ];

    constructor(props: Readonly<any>) {
        super(props);

        this.save = this.save.bind(this);
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.loadData();
    }

    async loadData() {
        const data = await exceptionSettingService.getAll();

        for (const item of this.defaultData) {
            item.value = data[item.key] || "";
        }

        for (const item of this.flowData) {
            item.value = data[item.key] || "";
        }

        for (const item of this.otherData) {
            item.value = data[item.key] || "";
        }

        this.setState({});
    }

    setValue(item: any, value: string) {
        item.value = value;
        this.setState({});
    }

    async save() {
        const data: DataMap<string> = {};

        for (const item of this.defaultData) {
            data[item.key] = item.value;
        }

        for (const item of this.flowData) {
            data[item.key] = item.value;
        }

        for (const item of this.otherData) {
            data[item.key] = item.value;
        }

        const res = await exceptionSettingService.save(data);

        if (res.succeeded) {
            notifySuccess("已成功保存");
        } else {
            alertError(res.message!);
        }
    }

    render() {
        return (
            <ContentBlock caption={"异常规则设置"}>
                <div className={"page-toolbar"}>
                    <Toolbar>
                        <Item location={"after"} locateInMenu={"auto"}>
                            <Button
                                text="保存设置"
                                onClick={this.save} />
                        </Item>
                    </Toolbar>
                </div>
                <FullPanel>
                    <ScrollView>
                        <div>
                            <div className="exception-setting">
                                <div className="dx-fieldset">
                                    <div className="dx-fieldset-header">
                                        <span className="caption-label">默认设置</span>
                                    </div>
                                    {
                                        this.defaultData.map(d => (
                                            <div className="dx-field" key={d.key}>
                                                <div className="dx-field-label">{d.text}</div>
                                                <div className="dx-field-value">
                                                    <RadioGroup
                                                        value={d.value}
                                                        items={d.dataSource}
                                                        displayExpr={"text"}
                                                        valueExpr={"value"}
                                                        layout="horizontal"
                                                        onValueChanged={e => this.setValue(d, e.value)} />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="dx-fieldset">
                                    <div className="dx-fieldset-header">
                                        <span className="caption-label">特定异常</span>
                                    </div>
                                    {
                                        this.otherData.map(o => (
                                            <div className="dx-field" key={o.key}>
                                                <div className="dx-field-label">{o.text}</div>
                                                <div className="dx-field-value">
                                                    <RadioGroup
                                                        value={o.value}
                                                        items={this.otherValues}
                                                        displayExpr={"text"}
                                                        valueExpr={"value"}
                                                        layout="horizontal"
                                                        onValueChanged={e => this.setValue(o, e.value)} />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="dx-fieldset">
                                    <div className="dx-fieldset-header">
                                        <span className="caption-label">流转记录异常</span>
                                    </div>
                                    {
                                        this.flowData.map(f => (
                                            <div className="dx-field" key={f.key}>
                                                <div className="dx-field-label">{f.text}</div>
                                                <div className="dx-field-value">
                                                    <RadioGroup
                                                        value={f.value}
                                                        items={this.flowValues}
                                                        displayExpr={"text"}
                                                        valueExpr={"value"}
                                                        layout="horizontal"
                                                        onValueChanged={e => this.setValue(f, e.value)} />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </FullPanel>
            </ContentBlock>
        )
    }
}