import React from "react";
import { DropDownBox, TreeView } from "devextreme-react";
import { ValueChangedEvent } from "devextreme/ui/drop_down_box";
import { ItemSelectionChangedEvent } from "devextreme/ui/tree_view";
import { modelClone } from "../../base";

export interface OrgTreeDropDownProps {
    organizations: any;
    organizationId?: number;
    placeholder?: string;
    width?: string | number | (() => string | number) | undefined;
    onValueChanged: (organizationId?: number, organization?: any) => void;
}

interface OrgTreeDropDownState {
    orgs: any;
    opened: boolean;
    rootValue?: number;
}

export default class OrgTreeDropDown extends React.Component<OrgTreeDropDownProps, OrgTreeDropDownState> {
    treeViewRef = React.createRef<TreeView>();

    constructor(props: Readonly<OrgTreeDropDownProps>) {
        super(props);

        this.state = {
            orgs: [],
            opened: false,
            rootValue: undefined
        };

        this.syncTreeView = this.syncTreeView.bind(this);
        this.optionChanged = this.optionChanged.bind(this);
        this.treeViewContentReady = this.treeViewContentReady.bind(this);
        this.treeViewItemClick = this.treeViewItemClick.bind(this);
        this.treeViewItemSelectionChanged = this.treeViewItemSelectionChanged.bind(this);
        this.treeViewRender = this.treeViewRender.bind(this);
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps?.organizations.length !== this.props.organizations.length) {
            this.convertOrgs(this.props.organizations);
        }
    }

    componentDidMount() {
        this.convertOrgs(this.props.organizations);
    }

    convertOrgs(orgs: any[]) {
        var copies = modelClone(orgs);
        const orgIds = orgs.map((o: any) => o.id);
        const rootValue = orgs.find((o: any) => !o.parentId) ?
            undefined :
            orgs.find((o: any) => orgIds.indexOf(o.parentId) === -1)?.parentId;

        for (const c of copies) {
            c.expanded = c.parentId !== rootValue;
        }

        this.setState({
            orgs: copies,
            rootValue: rootValue
        });
    }

    syncTreeView(e: ValueChangedEvent) {
        if (!e.value) {
            this.props.onValueChanged();
        }

        if (this.treeViewRef.current) {
            if (e.value) {
                this.treeViewRef.current.instance.selectItem(e.value);
            } else {
                this.treeViewRef.current.instance.unselectAll();
            }
        }
    }

    optionChanged(e: any) {
        if (e.name === 'opened') {
            this.setState({ opened: e.value });
        }
    }

    treeViewContentReady(e: any) {
        e.component.selectItem(this.props.organizationId);
    }

    treeViewItemClick() {
        this.setState({ opened: false });
    }

    treeViewItemSelectionChanged(e: ItemSelectionChangedEvent) {
        if (e.itemData?.id !== this.props.organizationId) {
            this.props.onValueChanged(e.itemData!.id as any, e.itemData);
        }
    }

    render() {
        const { organizationId, placeholder, width } = this.props;
        const { opened, orgs } = this.state;

        return (
            <DropDownBox
                width={width}
                value={organizationId}
                opened={opened}
                placeholder={placeholder}
                valueExpr="id"
                displayExpr="name"
                showClearButton={true}
                dataSource={orgs}
                dropDownOptions={{ maxHeight: 600 }}
                onValueChanged={this.syncTreeView}
                onOptionChanged={this.optionChanged}
                contentRender={this.treeViewRender} />
        )
    }

    treeViewRender() {
        const { orgs, rootValue } = this.state;

        return (
            <TreeView
                ref={this.treeViewRef}
                dataSource={orgs}
                rootValue={rootValue || 0}
                dataStructure="plain"
                keyExpr="id"
                parentIdExpr="parentId"
                expandedExpr="expanded"
                displayExpr="name"
                selectionMode="single"
                selectByClick={true}
                onContentReady={this.treeViewContentReady}
                onItemClick={this.treeViewItemClick}
                onItemSelectionChanged={this.treeViewItemSelectionChanged} />
        )
    }
}