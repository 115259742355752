import { IDataGridOptions } from "devextreme-react/data-grid";
import { IColumnProps } from "devextreme-react/tree-list";
import React from "react"
import { securityCheckService } from "../../api";
import { createBoolColumn } from "../options";
import { stdTimeFormat, ModuleConfig, StringMap } from "../../base";
import { StdGrid } from "../grid";
import { BriefSecurityCheck } from "../../models";

export interface SecurityCheckHistoryProps {
    id?: number;
}

interface SecurityCheckHistoryState {
    securityChecks: BriefSecurityCheck[];
}

export default class SecurityCheckHistory 
    extends React.Component<SecurityCheckHistoryProps, SecurityCheckHistoryState> {
    moduleName: ModuleConfig = {
        moduleName: "SecurityCheck",
        children: {
            "checker": "Employee"
        }
    };

    gridOptions: IDataGridOptions = {
        columnAutoWidth: true,
        scrolling: { showScrollbar: "never" },
        paging: { pageSize: 10 }
    };

    columns: IColumnProps[] = [
        { dataField: "checker.name" },
        { dataField: "checkTime", dataType: "datetime", format: stdTimeFormat },
        createBoolColumn({ dataField: "hasDanger" })
    ];

    nameMap: StringMap = { "checker.name": "checkerId" };

    constructor(props: Readonly<SecurityCheckHistoryProps>) {
        super(props);

        this.state = {
            securityChecks: []
        };
    }

    componentDidUpdate(prevProps: SecurityCheckHistoryProps) {
        if (this.props.id && this.props.id !== prevProps.id) {
            this.loadData();
        } else if (!this.props.id && prevProps.id) {
            this.setState({ securityChecks: [] });
        }
    }

    async loadData() {
        const data = await securityCheckService.searchByCustomerId(this.props.id!);
        this.setState({ securityChecks: data });
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                dataSource={this.state.securityChecks}
                columns={this.columns}
                columnNameMap={this.nameMap}
                options={this.gridOptions}
                autoHeight={true} />
        );
    }    
}