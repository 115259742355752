import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import { createGridOptions, stdReadOnlyGridOptions, StdGrid } from "../../components";
import { customerTypeStore, serverDataService } from "../../api";
import { CustomerTypeModel } from "../../models";

export interface CustomerTypeGridProps {
    onButtonClick: (model: CustomerTypeModel, name: string) => void;
    onModelSelected: (model: CustomerTypeModel) => void;
}

export default class CustomerTypePage extends React.Component<CustomerTypeGridProps> {
    moduleName = "CustomerType";

    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("customerTypes", this.gridRef, stdReadOnlyGridOptions);

    columns: IColumnProps[] = [
        { dataField: 'sortNumber' },
        { dataField: 'name' },
        { dataField: 'label' },
        { dataField: 'securityCheckPeriod' },
        { dataField: 'notifyAhead' },
        { dataField: 'lockAhead' },
        ...serverDataService.bottleTypes.map((t: any) => ({
            dataField: `limit_${t.id}`,
            caption: `${t.name}最大配送瓶数`,
            allowGrouping: false,
            allowSorting: false,
            allowFiltering: false,
            cellRender: function (e: any) {
                return (
                    <div>
                        {e.data.limits ?
                            e.data.limits.find((i: any) => i.bottleTypeId === t.id)?.maxNumber :
                            undefined}
                    </div>
                )
            }
        })),
    ]

    constructor(props: any) {
        super(props);

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
        this.gridOptions.export!.enabled = false;
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});
        }
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={customerTypeStore}
                options={this.gridOptions}
                columns={this.columns} />
        );
    }
}