import React from "react";
import { Button, SelectBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { ListItem, enums, alertWarning, DataMap } from "../../base";
import { OrgTreeDropDown } from "../../components";
import { BottlePositionModel, EmployeeRef, OrganizationRef, PositionCondition } from "../../models";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { employeeService, organizationService } from "../../api";

export interface BottlePositionToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (model: BottlePositionModel, name: string) => void;
    onFiltering: (condition: PositionCondition) => void;
}

interface BottleFillingToolbarState {
    model: BottlePositionModel;
    orgs: OrganizationRef[];
    delivers: EmployeeRef[];
    keyType: string;
    key?: string;
}

export default class BottleFillingToolbar
    extends React.Component<BottlePositionToolbarProps, BottleFillingToolbarState> {

    constructor(props: Readonly<BottlePositionToolbarProps>) {
        super(props);

        this.state = {
            model: {},
            orgs: [],
            delivers: [],
            keyType: 'Bottle'
        };

        this.onKeyTypeChanged = this.onKeyTypeChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        const orgs = await organizationService.getRefs();
        const delivers = await employeeService.getDelivers();

        this.setState({
            orgs: orgs,
            delivers: delivers
        });
    }

    setModel(model: BottlePositionModel) {
        this.setState({ model: model });
    }

    onKeyTypeChanged(e: ValueChangedEvent) {
        this.setState({ keyType: e.value, key: undefined });
    }

    onKeyChanged(key?: string) {
        this.setState({ key: key?.trim() });
    }

    onFiltering() {
        if ((this.state.keyType === "Bottle") && !this.state.key) {
            alertWarning("请输入要查询的气瓶陶瓷码或钢印号");
            return;
        }

        this.props.onFiltering({ keyType: this.state.keyType, key: this.state.key });
    }

    render() {
        const { key, keyType, orgs, delivers } = this.state;
        const showTextBox = keyType === "Bottle" || keyType === enums.bottlePositions.customer;
        const textBoxHolder = keyType === "Bottle" ? "请输入陶瓷码或钢印号" : showTextBox ? "请输入卡号或名称" : "";

        const updateButtonVisible = this.props.permissions[enums.stdActions.update] &&
            !!this.state.model.bottleId && keyType === "Bottle" && !!key;

        const lostButtonVisible = this.props.permissions["SetLost"] && 
            !!this.state.model.bottleId && keyType === enums.bottlePositions.customer;

        return (
            <div className={"page-toolbar page-large-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            dataSource={keyTypes}
                            displayExpr={"text"}
                            valueExpr={"value"}
                            value={keyType}
                            width={120}
                            placeholder="选择类型"
                            onValueChanged={this.onKeyTypeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto" visible={showTextBox}>
                        {showTextBox && (<TextBox
                            value={key}
                            placeholder={textBoxHolder}
                            onValueChanged={e => this.onKeyChanged(e.value?.trim())}
                            onEnterKey={this.onFiltering} />
                        )}
                    </Item>
                    <Item location={"before"} locateInMenu="auto" visible={keyType === enums.bottlePositions.site}>
                        {keyType === enums.bottlePositions.site && (<OrgTreeDropDown
                            organizationId={key ? parseInt(key) : undefined}
                            organizations={orgs}
                            placeholder="选择供应站过滤"
                            width={180}
                            onValueChanged={id => this.onKeyChanged(id?.toString())} />
                        )}
                    </Item>
                    <Item location={"before"} locateInMenu="auto" visible={keyType === enums.bottlePositions.deliveryman}>
                        {keyType === enums.bottlePositions.deliveryman && (<SelectBox
                            value={key}
                            dataSource={delivers}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={160}
                            placeholder="送气工过滤"
                            onValueChanged={e => this.onKeyChanged(e.value)} />
                        )}
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <Button
                            text={"查询"}
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={updateButtonVisible}>
                        <Button
                            text={"根据气瓶流转重新计算位置"}
                            onClick={() => this.props.onButtonClick(this.state.model, "edit")} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={lostButtonVisible}>
                        <Button
                            text="疑似流失瓶"
                            onClick={() => this.props.onButtonClick(this.state.model, "setlost")} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}

const keyTypes: ListItem[] = [
    { value: "Bottle", text: "钢瓶" },
    { value: enums.bottlePositions.customer, text: "客户" },
    { value: enums.bottlePositions.deliveryman, text: "送气工" },
    { value: enums.bottlePositions.site, text: "供应站" }
];