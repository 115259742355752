import Form, { Item, Label } from "devextreme-react/form";
import React from "react";
import { StdGrid } from "../grid";
import { bottleTypeService, employeeService, productService, orderService } from "../../api";
import { Panel } from "../layouts";
import DataGrid, { IDataGridOptions, IColumnProps } from "devextreme-react/data-grid";
import { Button } from "devextreme-react";
import { alertError, alertWarning, dataSources, enums, stdTimeFormat } from "../../base";
import { BottleModel, BottleTypeModel, ProductRef, EmployeeRef, OrderEditModel } from "../../models";

export interface NewOrderProps {
    id?: number;
    customer?: any;
    onOrderSaved: () => void;
}

interface NewOrderState {
    order: OrderEditModel;
    bottleTypes: BottleTypeModel[];
    products: ProductRef[];
    delivers: EmployeeRef[];
    orderSaved: boolean;
}

export default class NewOrder extends React.Component<NewOrderProps, NewOrderState> {
    itemModuleName = "OrderItem";
    itemGridRef = React.createRef<DataGrid>();
    itemGridOptions: IDataGridOptions = {
        columnAutoWidth: true,
        editing: { mode: "cell", allowUpdating: true, selectTextOnEditStart: true },
        sorting: { mode: "none" },
        scrolling: { showScrollbar: "never" },
        summary: {
            texts: { sum: "" },
            calculateCustomSummary: () => 0,
            totalItems: [
                { column: "typeName", summaryType: "custom", displayFormat: "共计" },
                { column: "amount", summaryType: "sum" },
                { column: "totalPrice", summaryType: "sum" }
            ]
        }
    }

    itemColumns: IColumnProps[] = [
        { 
            dataField: "typeName", 
            caption: "项目", 
            allowEditing: false 
        },
        { 
            dataField: "amount", 
            showEditorAlways: true,
            setCellValue: function(newData: any, value: any, currentData: any) {
                newData.amount = value;
                newData.totalPrice = (currentData.piecePrice - currentData.discount) * value;
            }
        },
        { 
            dataField: "piecePrice",
            showEditorAlways: true,
            setCellValue: function(newData: any, value: any, currentData: any) {
                newData.piecePrice = value;
                newData.totalPrice = (value - currentData.discount) * currentData.amount;
            }
        },
        {
            dataField: "discount",
            allowEditing: false,
        },
        { 
            dataField: "totalPrice", 
            caption: "金额", 
            allowEditing: false 
        }
    ];

    constructor(props: Readonly<NewOrderProps>) {
        super(props);

        this.state = {
            order: this.createNewOrder(props.id),
            bottleTypes: [],
            products: [],
            delivers: [],
            orderSaved: false
        };

        this.onNoteFocusOut = this.onNoteFocusOut.bind(this);
        this.createOrder = this.createOrder.bind(this);
    }

    createNewOrder(customerId?: number): OrderEditModel {
        return { 
            customerId: customerId,  
            deliverType: enums.deliverTypes.deliver, 
            items: this.createItems(this.state?.bottleTypes || [], this.state?.products || [])
        };
    }

    createItems(bottleTypes: any[], products: any[]) {
        return bottleTypes.map(t => {
            const product = products.find(p => p.bottleTypeId === t.id);
            const price = product?.webPrice || product?.price || 0;
            const discount = this.props.customer.discounts?.find((d: any) => d.bottleTypeId === t.id)?.discountValue || 0;

            return {
                bottleTypeId: t.id,
                typeName: t.name,
                amount: 0,
                piecePrice: price,
                discount: discount,
                shippingFee: 0,
                totalPrice: 0
            }
        });
    }

    componentDidMount() {
        this.loadBottleTypes();
    }

    async loadBottleTypes() {
        let bottleTypes: BottleModel[] = [], products: ProductRef[] = [];

        await Promise.all([
            (async () => bottleTypes = await bottleTypeService.getAuthorizedRefs())(),
            (async () => products = await productService.getBottles())()
        ]);

        this.state.order.items = this.createItems(bottleTypes, products);

        this.setState({ 
            bottleTypes: bottleTypes,
            products: products 
        });
    }

    componentDidUpdate(prevProps: NewOrderProps) {
        if (this.props.id && this.props.id !== prevProps.id) {
            this.loadDelivers();
            this.setState({ 
                order: this.createNewOrder(this.props.id),
                orderSaved: false
            });
        }
    }

    async loadDelivers() {
        const data = await employeeService.searchDelivers(this.props.id!);
        this.setState({ delivers: data });
    }

    onNoteFocusOut(e: any) {
        if (!e.event.shiftKey && e.event.keyCode === 9 /* tab */) {
            this.itemGridRef.current?.instance.editCell(0, "amount");
        }
    }

    async createOrder() {
        const items = this.state.order.items!;

        if (!items.find(i => i.amount)) {
            alertWarning("请选择要配送的项目");
            return;
        }

        const res = await orderService.create(this.state.order);

        if (res.succeeded) {
            this.props.onOrderSaved();
            
            this.setState({
                order: this.createNewOrder(this.props.customer.id),
                orderSaved: true
            });
        } else {
            alertError(res.message!);
        }
    }

    render() {
        const customer = this.props.customer;
        const notFound = !customer.id;
        const inactive = !notFound && customer?.status !== enums.customerStatus.active;
        // const locked = !notFound && !inactive && customer?.locked;
        const enabled = !notFound && !inactive; // && !customer?.locked;

        return (
            <Panel caption={"创建新订单"}>
                <Form colCount={2} formData={this.state.order}>
                    <Item
                        colSpan={2}
                        dataField={"deliverType"}
                        editorType={"dxRadioGroup"}
                        editorOptions={{
                            layout: "horizontal",
                            dataSource: dataSources.deliverTypes,
                            displayExpr: 'text',
                            valueExpr: 'value',
                            readOnly: true
                        }}>
                        <Label visible={false} />
                    </Item>
                    <Item
                        dataField={"deliverId"}
                        editorType={"dxSelectBox"}
                        editorOptions={{
                            dataSource: this.state.delivers,
                            displayExpr: 'nameAndCode',
                            valueExpr: 'id',
                            searchEnabled: true
                        }}>
                        <Label text={"送气工"} />
                    </Item>
                    <Item
                        dataField={"reservedTime"}
                        editorType={"dxDateBox"}
                        editorOptions={{ type: "datetime", displayFormat: stdTimeFormat }}>
                        <Label text={"预约时间"} />
                    </Item>
                    <Item
                        dataField={"note"}
                        colSpan={2}
                        editorOptions={{ onKeyDown: this.onNoteFocusOut }}>
                        <Label text={"备注"} />
                    </Item>
                </Form>
                <StdGrid
                    gridRef={this.itemGridRef}
                    moduleName={this.itemModuleName}
                    dataSource={this.state.order.items}
                    columns={this.itemColumns}
                    options={this.itemGridOptions}
                    autoHeight={true} />
                <div className="buttonbar">
                    {notFound && (<p className="text-danger">找不到客户，如仍需下单，请先开户</p>)}
                    {inactive && (<p className="text-danger">该客户已退户，不能下单，如仍需下单，请先恢复该客户</p>)}
                    {/* {locked && (<p className="text-danger">客户已被锁定，解锁后才能下单</p>)} */}
                    {enabled && (
                        <React.Fragment>
                            {this.state.orderSaved && (<p className="text-warning">已成功创建订单，请勿重复提交</p>)}
                            <Button text="提交" type="default" onClick={this.createOrder} />
                        </React.Fragment>
                    )}
                </div>
            </Panel>
        );
    }
}