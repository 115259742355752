import React from "react";
import { fillingRecheckService } from "../../api";
import { alertError, confirm2, enums, notifySuccess } from "../../base";
import { ContentBlock } from "../../components";
import { FillingRecheckModel } from "../../models";
import AuthorizedComponent from "../authorized-component";
import FillingRecheckForm from "./filling-recheck-form";
import FillingRecheckGrid from "./filling-recheck-grid";
import FillingRecheckToolbar from "./filling-recheck-toolbar";

export default class FillingRecheckPage extends AuthorizedComponent {
    objectName = "FillingRecheck";
    gridRef = React.createRef<FillingRecheckGrid>();
    formRef = React.createRef<FillingRecheckForm>();
    toolbarRef = React.createRef<FillingRecheckToolbar>();

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
    }

    onModelSelected(model: FillingRecheckModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onFiltering(filterValue: any) {
        this.gridRef.current?.filter(filterValue);
    }

    onButtonClick(model: FillingRecheckModel, name: string) {
        switch (name) {
            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.formRef.current?.show({});
                }
                break;

            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.formRef.current?.show(model);
                }
                break;

            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(model);
                }
                break;
        }
    }

    async delete(bottleDebt: FillingRecheckModel) {
        if (!await confirm2("确定要删除该数据吗？")) {
            return;
        }

        const result = await fillingRecheckService.delete(bottleDebt.id!);

        if (result.succeeded) {
            notifySuccess("已成功删除数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return (
            <ContentBlock caption={"充后检查"}>
                <FillingRecheckToolbar
                    ref={this.toolbarRef}
                    permissions={this.permissions}
                    onFiltering={this.onFiltering}
                    onButtonClick={this.onButtonClick} />
                <FillingRecheckGrid
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
                <FillingRecheckForm ref={this.formRef} onSaved={this.onSaved} />
            </ContentBlock>
        );
    }
}