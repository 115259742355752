import { createStoreFromUrl, BaseApiService, http, MessageBool } from "../base";
import { SiteCheckModel } from "../models";

class SiteCheckService extends BaseApiService<SiteCheckModel> {
    moduleName = "sitecheck";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }

    getDeletedDxData() {
        return createStoreFromUrl(`${this.apiPath}/deleteddx`, 'id');
    }

    restore(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/restore/${id}`, '');
    }
}

export const siteCheckService = new SiteCheckService();
export const siteCheckStore = siteCheckService.getDxData();
export const siteCheckDeletedStore = siteCheckService.getDeletedDxData();