import { ContentBlock } from "../../components";
import React from "react";
import AnnoucementForm from "./annoucement-form";
import AuthorizedComponent from "../authorized-component";
import AnnouncementGrid from "./announcement-grid";
import { AnnouncementModel } from "../../models";
import AnnouncementForm from "./annoucement-form";
import AnnouncementToolbar from "./announcement-toolbar";
import { alertError, confirm2, enums, notifySuccess } from "../../base";
import { announcementService } from "../../api";
import AnnouncementDetail from "./announcement-detail";


export default class AnnouncementPage extends AuthorizedComponent {
    objectName = "Announcement";
    gridRef = React.createRef<AnnouncementGrid>();
    formRef = React.createRef<AnnoucementForm>();
    toolbarRef = React.createRef<AnnouncementToolbar>();
    detailRef = React.createRef<AnnouncementDetail>();

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.filter = this.filter.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
    }

    onModelSelected(model: AnnouncementModel) {
        this.toolbarRef.current?.setModel(model);
    }

    filter(filterValue: any) {
        this.gridRef.current?.filter(filterValue);
    }

    onButtonClick(model: AnnouncementModel, name: string) {
        switch (name) {
            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.formRef.current?.show({});
                }
                break;

            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.formRef.current?.show(model);
                }
                break;

            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(model);
                }
                break;

            case "detail":
                this.detailRef.current?.show(model);
                break;
        }
    }

    async delete(model: AnnouncementModel) {
        if (!await confirm2("确定要删除此数据吗？")) {
            return;
        }

        const result = await announcementService.delete(model.id!);

        if (result.succeeded) {
            notifySuccess("已成功删除数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return (
            <ContentBlock caption={"公告管理"}>
                <AnnouncementToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onButtonClick={this.onButtonClick}
                    onFiltering={this.filter} />
                <AnnouncementGrid
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
                <AnnouncementForm ref={this.formRef} onSaved={this.onSaved} />
                <AnnouncementDetail ref={this.detailRef} />
            </ContentBlock>
        );
    }
}