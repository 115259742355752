import './code-print-dialog.scss';
import { Button, CheckBox, Form, Popup } from "devextreme-react";
import React from "react";
import { PopupContentBox, RefPopup } from "../../components";
import { ListItem, alertError, alertWarning, confirm2, enums } from "../../base";
import { cpCheckBackService, cpLogService, cpNewService } from "../../api";
import { CodePrintService, UsbPrinter, tscPrinter } from "../../base/tsc-plugin";
import { Item, Label } from "devextreme-react/form";
import { CpBatchModel, CpLogModel } from "../../models";
import { ValueChangedEvent } from 'devextreme/ui/check_box';

interface CodePrintDialogState {
    codes: ListItem[];
    printers: UsbPrinter[];
    formData: any;
    batch: CpBatchModel;
    message?: string;
    printing: boolean;
    cancelPrint: boolean;
    checkAll: boolean;
}

export default class CodePrintDialog extends React.Component<any, CodePrintDialogState> {
    popupRef = React.createRef<Popup>();
    numbers: number[] = [1, 2, 3, 4, 5];

    constructor(props: any) {
        super(props);

        this.state = {
            codes: [],
            printers: [],
            formData: { copies: 2 },
            batch: {},
            printing: false,
            cancelPrint: false,
            checkAll: true
        }

        this.checkAllChanged = this.checkAllChanged.bind(this);
        this.print = this.print.bind(this);
        this.cancel = this.cancel.bind(this);
        this.cancelPrint = this.cancelPrint.bind(this);
    }

    async getPrinters() {
        const printers = await tscPrinter.getUsbPrinters() || [];
        const newState: any = { printers: printers };

        if (printers.length === 1) {
            newState.formData = { printer: printers[0].USBPath, copies: this.state.formData.copies };
        }

        this.setState(newState);
    }

    async show(batchId: number, type: string) {
        this.popupRef.current?.instance.show();
        this.getPrinters();

        if (type === enums.cpBatchTypes.new) {
            const batch = await cpNewService.getBatch(batchId);

            this.setState({
                batch: batch.batch,
                codes: batch.models.map(m => ({ value: m.barcode!, text: `${m.barcode}（${m.bottleType}）`, selected: true })),
                message: '',
                cancelPrint: false,
                printing: false,
                checkAll: true
            });
        } else {
            const batch = await cpCheckBackService.getBatch(batchId);

            this.setState({
                batch: batch.batch,
                codes: batch.models.map(m => ({ value: m.barcode!, text: `${m.barcode}（${m.bottleType}）`, selected: true })),
                message: '',
                cancelPrint: false,
                printing: false,
                checkAll: true
            });
        }
    }

    async hide() {
        if (this.state.printing) {
            this.setState({
                cancelPrint: true
            });
        }

        this.popupRef.current?.instance.hide();
    }

    checkAllChanged(e: ValueChangedEvent) {
        for (const item of this.state.codes) {
            item.selected = e.value;
        }

        this.setState({ checkAll: e.value, codes: this.state.codes });
    }

    async print() {
        const testPrinter: UsbPrinter = { USBName: 'te344', USBPath: 'Test' };

        if (!this.state.formData.printer) {
            if (this.state.printers.length) {
                alertError("未选择打印机");
                return;
            }
            else if (!await confirm2("未发现打印机，是否模拟打印流程？")) {
                return;
            }
        }

        if (this.state.formData.printer && !await confirm2("确定打印选中的条码吗？")) {
            return;
        }

        const printer = this.state.printers.find(p => p.USBPath === this.state.formData.printer) || testPrinter;
        const service = new CodePrintService(printer);
        tscPrinter.continueOnError = printer === testPrinter;
        const logs: CpLogModel[] = [];
        const codes = this.state.codes.filter(c => c.selected);

        try {
            this.setState({
                printing: true,
                message: `准备开始打印，共计【${codes.length}】条码...`
            });

            await service.prepare();

            for (let i = 0; i < codes.length; i++) {
                if (this.state.cancelPrint) {
                    alertWarning("打印已取消");
                    this.setState({ message: '打印已取消', printing: false, cancelPrint: false });
                    break;
                }

                const code = codes[i];

                await service.print(code.value, this.state.formData.copies);
                logs.push({ logTime: new Date(), barcode: code.value, batchCode: this.state.batch.batchCode });

                this.setState({
                    message: codes.length === i + 1 ? '打印完成' : `打印中，剩余【${codes.length - i - 1}】条码...`,
                    printing: codes.length > i + 1
                });
            }
        } catch (e: any) {
            this.setState({ message: `打印失败，错误信息：${e}`, cancelPrint: false, printing: false });
            alertError(`打印失败，错误信息：${e}`);
        } finally {
            try {
                await cpLogService.batchCreate(logs);
            } catch { }
        }
    }

    async cancelPrint() {
        if (await confirm2("确定要取消打印吗？")) {
            this.setState({ cancelPrint: true });
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title="条码打印"
                width={800}
                height={600}
                showCloseButton={false}>
                <PopupContentBox
                    saveButtonText="打印"
                    scrollable={true}
                    onSave={this.print}
                    onCancel={this.cancel}>
                    <div>
                        {this.state.message && (<div className="code-print-message">
                            {this.state.message} &nbsp;&nbsp;
                            <Button
                                type="danger"
                                visible={this.state.printing && !this.state.cancelPrint}
                                text="取消"
                                onClick={this.cancelPrint} />
                        </div>)}
                        <Form formData={this.state.formData} colCount={2} labelLocation="left">
                            <Item dataField={"printer"} editorType={"dxSelectBox"}
                                editorOptions={{ dataSource: this.state.printers, displayExpr: 'USBName', valueExpr: 'USBPath' }}>
                                <Label text={"打印机"} />
                            </Item>
                            <Item dataField={"copies"} editorType={"dxSelectBox"} editorOptions={{ dataSource: this.numbers }}>
                                <Label text={"份数"} />
                            </Item>
                        </Form>
                        <br />
                        <div>
                            共计：{this.state.codes.length} &nbsp;&nbsp;&nbsp;
                            <CheckBox value={this.state.checkAll} text='全选' onValueChanged={this.checkAllChanged} />
                        </div>
                        <hr />
                        <div className="checkbox-list-3">
                            {this.state.codes.map(c => (
                                <CheckBox
                                    key={c.value}
                                    text={c.text}
                                    value={c.selected}
                                    onValueChanged={(e) => {
                                        c.selected = e.value;
                                        this.setState({ codes: this.state.codes });
                                    }} />
                            ))}
                        </div></div>
                </PopupContentBox>
            </RefPopup>
        )
    }
}