import React from "react";
import { List, Popup } from "devextreme-react";
import TextBox, { Button as TextBoxButton } from "devextreme-react/text-box";
import Box, { Item as BoxItem } from "devextreme-react/box";
import { RefPopup } from "../popup";
import { customerService } from "../../api";
import { alertWarning, enums } from "../../base";
import { CustomerModel } from "../../models";

export interface CustomerPopupProps {
    onCustomerSelected: (customer: CustomerModel) => void;
}

interface CustomerPopupState {
    customers: CustomerModel[];
}

export default class CustomerPopup extends React.Component<CustomerPopupProps, CustomerPopupState> {
    popupRef = React.createRef<Popup>();
    inputRef = React.createRef<TextBox>();

    searchButton: any;
    key?: string;

    constructor(props: Readonly<CustomerPopupProps>) {
        super(props);

        this.state = {
            customers: []
        };

        this.search = this.search.bind(this);

        this.searchButton = {
            icon: 'search',
            type: 'default',
            stylingMode: 'text',
            onClick: this.search
        }
    }

    async show(key?: string) {
        this.popupRef.current?.instance.show();

        if (key) {
            const match = key.match(/^.+（(\w+)(，已退户)?）$/);
            if (match) key = match[1];
        } else {
            setTimeout(() => this.inputRef.current?.instance.focus(), 500);    // 没有key时聚集文本框，方便输入
        }

        this.key = key;
        this.inputRef.current?.instance.option("value", key || "");

        const customers = key ? await customerService.search(key) : [];

        this.setState({ customers: customers });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async search() {
        if (!this.key) {
            alertWarning("请输入关键字搜索");
            return;
        }

        const customers = await customerService.search(this.key);
        if (customers.length === 1) {
            this.select(customers[0]);
        } else {
            this.setState({ customers: customers });
        }
    }

    select(customer: any) {
        this.hide();
        this.props.onCustomerSelected(customer);
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"选择客户"}
                width={800}
                height={600}>
                <Box direction={"col"} height="100%">
                    <BoxItem baseSize="50" ratio={1}>
                        <TextBox
                            ref={this.inputRef}
                            placeholder={"请输入卡号、名称、电话、地址进行查询"}
                            stylingMode={"outlined"}
                            defaultValue={this.key}
                            onValueChanged={(e) => this.key = e.value}
                            onEnterKey={this.search}>
                            <TextBoxButton name="search" location="after" options={this.searchButton} />
                        </TextBox>
                    </BoxItem>
                    <BoxItem ratio={3} baseSize="100%">
                        <List
                            visible={!!this.key}
                            dataSource={this.state.customers}
                            showScrollbar={"always"}
                            itemRender={(e: CustomerModel) => (
                                `${e.name}（${e.gasCode}${e.status === enums.customerStatus.inactive ? '，已退户' : ''}），电话：${e.phoneNumber}，地址：${e.address}`
                            )}
                            onItemClick={e => this.select(e.itemData)} />
                    </BoxItem>
                </Box>
            </RefPopup>
        )
    }
}