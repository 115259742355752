
import { Component } from "react";
import { roleService, employeeRoleService } from "../../api";
import Popup from "devextreme-react/popup";
import { PopupContentBox } from "../layouts";
import { CheckBox } from "devextreme-react";
import { alertError, notifySuccess, authorizationService, enums, modelClone } from "../../base";
import { ValueChangedEvent } from "devextreme/ui/check_box";
import React from "react";
import { RefPopup } from "../popup";
import { RoleModel } from "../../models";

interface EmployeeRoleState {
    defaultRoles: RoleModel[];
    selectedRoleIds: number[];
    canEdit: boolean;
}

interface SelectableRole extends RoleModel {
    selected?: boolean;
}

export default class EmployeeRolePopup extends Component<any, EmployeeRoleState> {
    popupRef = React.createRef<Popup>();
    employee: any;

    constructor(props: any) {
        super(props);

        this.state = {
            defaultRoles: [],
            selectedRoleIds: [],
            canEdit: false
        };

        this.onValueChanged = this.onValueChanged.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    async componentDidMount() {
        const canEdit = await authorizationService.authorize("EmployeeRole", enums.stdActions.update);
        this.setState({ canEdit: canEdit });

        this.loadDefaultRoles();
    }

    async loadDefaultRoles() {
        const data = await roleService.getRefs();
        this.setState({
            defaultRoles: data
        });
    }

    show(employee: any) {
        this.popupRef.current &&
            this.popupRef.current.instance.show();

        this.employee = employee;
        this.loadEmployeeRoles();
    }

    hide() {
        this.popupRef.current &&
            this.popupRef.current.instance.hide();
    }

    async loadEmployeeRoles() {
        const eroles = await employeeRoleService.getByEmployeeId(this.employee.id);
        this.setState({
            selectedRoleIds: eroles.map(r => r.roleId!)
        });
    }

    onValueChanged(e: ValueChangedEvent, role: SelectableRole) {
        const ids = e.value ?
            [...this.state.selectedRoleIds, role.id!] :
            this.state.selectedRoleIds.filter(id => id !== role.id);

        this.setState({ selectedRoleIds: ids });
    }

    async save() {
        const eroles = this.state.selectedRoleIds.map(id => ({ roleId: id }));
        const result = await employeeRoleService.save(this.employee.id, eroles);

        if (result.succeeded) {
            notifySuccess('已成功保存员工角色');
            this.hide();
        } else {
            alertError(result.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        const { defaultRoles, selectedRoleIds, canEdit } = this.state;

        const roles: SelectableRole[] = modelClone(defaultRoles);
        roles.forEach(r => r.selected = selectedRoleIds.indexOf(r.id!) !== -1);

        return (
            <RefPopup
                popupRef={this.popupRef}
                width={400}
                height={400}
                title={`设置角色 - ${this.employee?.name}`}>
                <PopupContentBox 
                    onSave={this.save} 
                    onCancel={this.cancel} 
                    hideSavebutton={!canEdit} 
                    hideCancelButton={!canEdit}>
                    <div className={"checkbox-list"}>
                        {
                            roles.map(r => (
                                <div key={r.id}>
                                    <CheckBox
                                        readOnly={!canEdit}
                                        key={r.id}
                                        value={r.selected}
                                        text={r.name}
                                        onValueChanged={e => this.onValueChanged(e, r)} />
                                </div>
                            ))
                        }</div>
                </PopupContentBox>
            </RefPopup>
        )
    }
}