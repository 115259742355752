import React from "react";
import { Button, DateBox, SelectBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { DataMap, dateAdd, enums, ListItem, today } from "../../base";
import { AreaRef, EmployeeRef, OrganizationRef, TroubleshootingModel } from "../../models";
import { areaService, employeeService, emptyDataSources, organizationService } from "../../api";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { OrgTreeDropDown } from "../../components";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { ValueChangedEvent as TextChangedEvent } from "devextreme/ui/text_box";

interface TroubleshootingToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (troubleshooting: TroubleshootingModel, name: string) => void;
    onFiltering: (filterValue: any, showDeleted: boolean) => void;
}

interface TroubleshootingToolbarState {
    model: TroubleshootingModel;
    orgs: OrganizationRef[];
    allDelivers: EmployeeRef[];
    delivers: EmployeeRef[];
    counties: AreaRef[];
    allStreets: AreaRef[];
    streets: AreaRef[];
    organizationId?: number;
    deliverId?: string;
    countyId?: number;
    streetId?: number;
    timeProperty: "reportTime" | "handleTime";
    startDate?: Date | string;
    endDate?: Date | string;
    assigned?: boolean;
    showDeleted: boolean;
    customerKey?: string;
}

export default class TroubleshootingToolbar
    extends React.Component<TroubleshootingToolbarProps, TroubleshootingToolbarState> {
    constructor(props: Readonly<TroubleshootingToolbarProps>) {
        super(props);

        const startDate = today();

        this.state = {
            model: {},
            orgs: [],
            allDelivers: [],
            delivers: [],
            counties: [],
            allStreets: [],
            streets: [],
            timeProperty: "reportTime",
            showDeleted: false,
            startDate: dateAdd(startDate, -7),
            endDate: startDate
        };

        this.onTimePropertyChanged = this.onTimePropertyChanged.bind(this);
        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onDeliverChanged = this.onDeliverChanged.bind(this);
        this.onCountyChanged = this.onCountyChanged.bind(this);
        this.onStreetChanged = this.onStreetChanged.bind(this);
        this.onAssignedChanged = this.onAssignedChanged.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);
        this.onCustomerChanged = this.onCustomerChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount() {
        this.loadDataSources();
        setTimeout(this.onFiltering, 100);
    }

    async loadDataSources() {
        const dataSources = emptyDataSources();

        await Promise.all([
            (async () => dataSources.orgs = await organizationService.getRefs())(),
            (async () => dataSources.employees = await employeeService.getDelivers())(),
            (async () => dataSources.counties = await areaService.getCounties())(),
            (async () => dataSources.streets = await areaService.getStreets())(),
        ]);

        this.setState({
            orgs: dataSources.orgs,
            allDelivers: dataSources.employees,
            delivers: dataSources.employees,
            counties: dataSources.counties,
            allStreets: dataSources.streets,
            streets: dataSources.streets
        })
    }

    setModel(model: TroubleshootingModel) {
        this.setState({ model: model });
    }

    onTimePropertyChanged(e: ValueChangedEvent) {
        this.setState({ timeProperty: e.value });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onOrganizationChanged(organizationId?: number) {
        const delivers = organizationId ?
            this.state.allDelivers.filter(d => d.organizationId === organizationId) :
            this.state.allDelivers;

        const deliverId = delivers.find(d => d.id === this.state.deliverId) ? this.state.deliverId : undefined;

        this.setState({
            organizationId: organizationId,
            delivers: delivers,
            deliverId: deliverId
        });
    }

    onFiltering() {
        this.props.onFiltering(this.getFilterValue(), this.state.showDeleted);
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { timeProperty, startDate, endDate, organizationId, deliverId,
            countyId, streetId, assigned, customerKey } = this.state;

        if (startDate) {
            filters.push([timeProperty, ">=", startDate]);
        }
        if (endDate) {
            filters.push([timeProperty, "<", dateAdd(new Date(endDate), 1)])
        }
        if (organizationId) {
            filters.push(["organizationId", "=", organizationId]);
        }
        if (deliverId) {
            filters.push(["handlerId", "=", deliverId]);
        }
        if (countyId) {
            filters.push(["customer.areaId", "=", countyId]);
        }
        if (streetId) {
            filters.push(["customer.streetId", "=", streetId]);
        }
        if (typeof (assigned) === "boolean") {
            filters.push(["handlerId", assigned ? "<>" : "=", null])
        }
        if (customerKey) {
            filters.push([
                [ "customer.gasCode", "contains", customerKey],
                "or",
                [ "customer.name", "contains", customerKey]
            ]);
        }

        return filters.length > 0 ? filters : null;
    }

    onDeliverChanged(e: ValueChangedEvent) {
        this.setState({ deliverId: e.value });
    }

    onCountyChanged(e: ValueChangedEvent) {
        const streets = e.value ?
            this.state.allStreets.filter(s => s.parentId === e.value) :
            this.state.allStreets;

        const streetId = streets.find(s => s.id === this.state.streetId) ? this.state.streetId : undefined;

        this.setState({
            countyId: e.value,
            streets: streets,
            streetId: streetId
        });
    }

    onStreetChanged(e: ValueChangedEvent) {
        this.setState({ streetId: e.value });
    }

    onAssignedChanged(e: ValueChangedEvent) {
        this.setState({ assigned: e.value });
    }

    onStatusChanged(e: ValueChangedEvent) {
        this.setState({ showDeleted: e.value });
    }

    onCustomerChanged(e: TextChangedEvent) {
        this.setState({ customerKey: e.value });
    }

    render() {
        const { model, orgs, delivers, counties, streets, organizationId, deliverId,
            countyId, streetId, timeProperty, startDate, endDate, assigned, showDeleted, customerKey } = this.state;
        const permissions = this.props.permissions;
        const canAssign = !!(model?.id) && model?.status !== enums.troubleshootingStatus.complete;

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={timeProperty}
                            dataSource={timePropertySource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={160}
                            onValueChanged={this.onTimePropertyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={startDate}
                            placeholder="开始时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions[enums.stdActions.create]}>
                        <Button
                            text="新增"
                            onClick={() => this.props.onButtonClick({}, "add")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions["Assign"]}>
                        <Button
                            text="分配人员"
                            disabled={!canAssign}
                            onClick={() => this.props.onButtonClick(model, "assign")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.delete]}>
                        <Button
                            text="作废"
                            disabled={!model?.id || model.deleted}
                            onClick={() => this.props.onButtonClick(model, "delete")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto"
                        visible={permissions[enums.stdActions.delete] && !!model?.deleted}>
                        <Button
                            text="还原"
                            onClick={() => this.props.onButtonClick(model, "restore")} />
                    </Item>
                </Toolbar>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={showDeleted}
                            dataSource={statusSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={120}
                            onValueChanged={this.onStatusChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={deliverId}
                            dataSource={delivers}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={160}
                            placeholder="送气工"
                            onValueChanged={this.onDeliverChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={countyId}
                            dataSource={counties}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder="行政区域"
                            onValueChanged={this.onCountyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={streetId}
                            dataSource={streets}
                            displayExpr="name"
                            valueExpr={"id"}
                            searchEnabled={true}
                            showClearButton={true}
                            width={100}
                            placeholder="街道"
                            onValueChanged={this.onStreetChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={assigned}
                            dataSource={assignSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={100}
                            showClearButton={true}
                            placeholder="分配情况"
                            onValueChanged={this.onAssignedChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <TextBox
                            value={customerKey}
                            showClearButton={true}
                            placeholder="客户名称或卡号"
                            onValueChanged={this.onCustomerChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                </Toolbar>
            </div>
        )
    }
}

const timePropertySource: ListItem<string>[] = [
    { value: "reportTime", text: "报修时间" },
    { value: "handleTime", text: "抢修时间" }
]


const assignSource: ListItem<boolean>[] = [
    { value: true, text: "已分配" },
    { value: false, text: "未分配" }
]

const statusSource: ListItem<boolean>[] = [
    { value: false, text: "查看正常数据" },
    { value: true, text: "查看已作废" }
]