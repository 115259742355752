import React from "react";
import { Button, DateBox, SelectBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { DataMap, dateAdd, enums, ListItem, today } from "../../base";
import { AreaRef, DangerBatchModel, DangerModel, EmployeeRef, OrganizationRef } from "../../models";
import { areaService, employeeService, emptyDataSources, organizationService } from "../../api";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { OrgTreeDropDown } from "../../components";
import { ValueChangedEvent as TextChangedEvent } from "devextreme/ui/text_box";

interface DangerToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (danger: DangerModel, name: string) => void;
    onContextButtonClick: (dangers: DangerBatchModel[], name: string) => void;
    onFiltering: (filterValue: any, showDeleted: boolean, key: string) => void;
}

interface DangerToolbarState {
    model: DangerModel;
    dangers: DangerBatchModel[];
    orgs: OrganizationRef[];
    allCheckers: EmployeeRef[];
    checkers: EmployeeRef[];
    counties: AreaRef[];
    allStreets: AreaRef[];
    streets: AreaRef[];
    organizationId?: number;
    checkerId?: string;
    countyId?: number;
    streetId?: number;
    timeProperty: "checkTime" | "fixTime";
    startDate?: Date | string;
    endDate?: Date | string;
    fixStatus?: "fixed" | "unfixed" | "fail";
    assigned?: boolean;
    expiration?: "expired" | "expiring" | "normal";
    showDeleted: boolean;
    customerKey?: string;
    key?: string;
}

export default class DangerToolbar
    extends React.Component<DangerToolbarProps, DangerToolbarState> {
    constructor(props: Readonly<DangerToolbarProps>) {
        super(props);

        const startDate = today();

        this.state = {
            model: {},
            dangers: [],
            orgs: [],
            allCheckers: [],
            checkers: [],
            counties: [],
            allStreets: [],
            streets: [],
            timeProperty: "checkTime",
            showDeleted: false,
            startDate: dateAdd(startDate, -7),
            endDate: startDate
        };

        this.onTimePropertyChanged = this.onTimePropertyChanged.bind(this);
        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onDeliverChanged = this.onDeliverChanged.bind(this);
        this.onCountyChanged = this.onCountyChanged.bind(this);
        this.onStreetChanged = this.onStreetChanged.bind(this);
        this.onFixStatusChanged = this.onFixStatusChanged.bind(this);
        this.onAssignedChanged = this.onAssignedChanged.bind(this);
        this.onExpirationChanged = this.onExpirationChanged.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);
        this.onCustomerChanged = this.onCustomerChanged.bind(this);
        this.onKeyChanged = this.onKeyChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount() {
        this.loadDataSources();
        setTimeout(this.onFiltering, 100);
    }

    async loadDataSources() {
        const dataSources = emptyDataSources();

        await Promise.all([
            (async () => dataSources.orgs = await organizationService.getRefs())(),
            (async () => dataSources.employees = await employeeService.getRefs(
                enums.employeePosts.deliver, enums.employeePosts.securityInspector))(),
            (async () => dataSources.counties = await areaService.getCounties())(),
            (async () => dataSources.streets = await areaService.getStreets())(),
        ]);

        this.setState({
            orgs: dataSources.orgs,
            allCheckers: dataSources.employees,
            checkers: dataSources.employees,
            counties: dataSources.counties,
            allStreets: dataSources.streets,
            streets: dataSources.streets
        })
    }

    setModel(model: DangerModel) {
        this.setState({ model: model });
    }

    setBatchModels(dangers: DangerBatchModel[]) {
        this.setState({ dangers: dangers });
    }

    onTimePropertyChanged(e: ValueChangedEvent) {
        this.setState({ timeProperty: e.value });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onOrganizationChanged(organizationId?: number) {
        const delivers = organizationId ?
            this.state.allCheckers.filter(d => d.organizationId === organizationId) :
            this.state.allCheckers;

        const deliverId = delivers.find(d => d.id === this.state.checkerId) ? this.state.checkerId : undefined;

        this.setState({
            organizationId: organizationId,
            checkers: delivers,
            checkerId: deliverId
        });
    }

    onFiltering() {
        this.props.onFiltering(this.getFilterValue(), this.state.showDeleted, this.state.key || "");
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { timeProperty, startDate, endDate, organizationId, checkerId, countyId, streetId, 
            fixStatus, assigned, expiration, customerKey } = this.state;

        if (startDate) {
            filters.push([timeProperty, ">=", startDate]);
        }
        if (endDate) {
            filters.push([timeProperty, "<", dateAdd(new Date(endDate), 1)])
        }
        if (organizationId) {
            filters.push(["organizationId", "=", organizationId]);
        }
        if (checkerId) {
            filters.push(["handlerId", "=", checkerId]);
        }
        if (countyId) {
            filters.push(["customer.areaId", "=", countyId]);
        }
        if (streetId) {
            filters.push(["customer.streetId", "=", streetId]);
        }
        switch (fixStatus) {
            case "fail":
                filters.push(["fixStatus", "=", enums.dangerFixStatus.fail]);
                break;

            case "fixed":
                filters.push(["fixed", "=", true]);
                break;

            case "unfixed":
                filters.push(["fixed", "=", false], ["fixStatus", "<>", enums.dangerFixStatus.fail]);
                break;
        }
        if (typeof(assigned) === "boolean") {
            filters.push(["handlerId", assigned ? "<>" : "=", null])
        }
        if (customerKey) {
            filters.push([
                [ "customer.gasCode", "contains", customerKey],
                "or",
                [ "customer.name", "contains", customerKey]
            ]);
        }
        switch (expiration) {
            case "expired":
                filters.push(["expirationDate", "<", today()]);
                break;

            case "expiring":
                filters.push(["expirationDate", ">=", today()], ["expirationDate", "<", dateAdd(today(), 3)]);
                break;

            case "normal":
                filters.push(["expirationDate", ">=", dateAdd(today(), 3)]);
        }

        return filters.length > 0 ? filters : null;
    }

    onDeliverChanged(e: ValueChangedEvent) {
        this.setState({ checkerId: e.value });
    }

    onCountyChanged(e: ValueChangedEvent) {
        const streets = e.value ?
            this.state.allStreets.filter(s => s.parentId === e.value) :
            this.state.allStreets;

        const streetId = streets.find(s => s.id === this.state.streetId) ? this.state.streetId : undefined;

        this.setState({
            countyId: e.value,
            streets: streets,
            streetId: streetId
        });
    }

    onStreetChanged(e: ValueChangedEvent) {
        this.setState({ streetId: e.value });
    }

    onFixStatusChanged(e: ValueChangedEvent) {
        this.setState({ fixStatus: e.value });
    }

    onAssignedChanged(e: ValueChangedEvent) {
        this.setState({ assigned: e.value });
    }

    onExpirationChanged(e: ValueChangedEvent) {
        this.setState({ expiration: e.value});
    }

    onStatusChanged(e: ValueChangedEvent) {
        this.setState({ showDeleted: e.value });
    }

    onCustomerChanged(e: TextChangedEvent) {
        this.setState({ customerKey: e.value });
    }

    onKeyChanged(e: TextChangedEvent) {
        this.setState({ key: e.value });
    }

    render() {
        const { model, dangers, orgs, checkers, counties, streets, organizationId, checkerId, countyId, streetId, 
            timeProperty, startDate, endDate, fixStatus, assigned, expiration, showDeleted, customerKey, key } = this.state;
        const permissions = this.props.permissions;
        const canAudit = !!model.id && (model.fixed || model.fixStatus === enums.dangerFixStatus.fail);
        const canEdit = !!model.id && model.type === enums.dangerTypes.issue &&
            !model.fixed && model.fixStatus !== enums.dangerFixStatus.fail;
        const canDelete = dangers.length > 0 && dangers.filter(d => d.deleted).length === 0;
        const canRestore = dangers.length > 0 && dangers.filter(d => !d.deleted).length === 0;

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={timeProperty}
                            dataSource={timePropertySource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={160}
                            onValueChanged={this.onTimePropertyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={startDate}
                            placeholder="开始时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <TextBox
                            value={key}
                            showClearButton={true}
                            placeholder="输入内容搜索"
                            onValueChanged={this.onKeyChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions["Issue"]}>
                        <Button
                            text="下发隐患"
                            onClick={() => this.props.onButtonClick({}, "issue")} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"}>
                        <Button
                            text="查看详情"
                            disabled={!model?.id}
                            onClick={() => this.props.onButtonClick(model, "detail")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            text="导出整改单"
                            disabled={!dangers.length}
                            onClick={() => this.props.onContextButtonClick(dangers, "export")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions["Assign"]}>
                        <Button
                            text="分配人员"
                            disabled={!model?.id}
                            onClick={() => this.props.onButtonClick(model, "assign")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions["Assign"]}>
                        <Button
                            text="提醒"
                            disabled={!model?.id}
                            onClick={() => this.props.onButtonClick(model, "notify")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.update]}>
                        <Button
                            text="编辑"
                            disabled={!canEdit}
                            onClick={() => this.props.onButtonClick(model, "edit")} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions["Audit"]}>
                        <Button
                            text="审核"
                            disabled={!canAudit}
                            onClick={() => this.props.onButtonClick(model, "audit")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions["LockCustomer"]}>
                        <Button
                            text={model?.customer?.locked ? "解锁" : "锁定"}
                            disabled={!model?.id}
                            onClick={() => this.props.onButtonClick(model, "switchLock")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.delete]}>
                        <Button
                            text="作废"
                            disabled={canDelete}
                            onClick={() => this.props.onContextButtonClick(dangers, "delete")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.delete]}>
                        <Button
                            disabled={canRestore}
                            text="还原"
                            onClick={() => this.props.onContextButtonClick(dangers, "restore")} />
                    </Item>
                </Toolbar>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={showDeleted}
                            dataSource={statusSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={120}
                            onValueChanged={this.onStatusChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={checkerId}
                            dataSource={checkers}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={160}
                            placeholder="巡查人员"
                            onValueChanged={this.onDeliverChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={countyId}
                            dataSource={counties}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder="行政区域"
                            onValueChanged={this.onCountyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={streetId}
                            dataSource={streets}
                            displayExpr="name"
                            valueExpr={"id"}
                            searchEnabled={true}
                            showClearButton={true}
                            width={100}
                            placeholder="街道"
                            onValueChanged={this.onStreetChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <TextBox
                            value={customerKey}
                            showClearButton={true}
                            placeholder="客户名称或卡号"
                            onValueChanged={this.onCustomerChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={fixStatus}
                            dataSource={fixStatusSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={120}
                            showClearButton={true}
                            placeholder="整改情况"
                            onValueChanged={this.onFixStatusChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={assigned}
                            dataSource={assignSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={100}
                            showClearButton={true}
                            placeholder="分配情况"
                            onValueChanged={this.onAssignedChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={expiration}
                            dataSource={expirationSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={100}
                            showClearButton={true}
                            placeholder="超期整改"
                            onValueChanged={this.onExpirationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                </Toolbar>
            </div>
        )
    }
}

const timePropertySource: ListItem<string>[] = [
    { value: "checkTime", text: "安检/隐患下发时间" },
    { value: "fixTime", text: "隐患整改时间" }
]

const fixStatusSource: ListItem<string>[] = [
    { value: "fixed", text: "已整改" },
    { value: "unfixed", text: "未整改" },
    { value: "fail", text: enums.dangerFixStatus.fail }
]

const assignSource: ListItem<boolean>[] = [
    { value: true, text: "已分配" },
    { value: false, text: "未分配" }
]

const expirationSource: ListItem<string>[] = [
    { value: "expired", text: "已超期" },
    { value: "expiring", text: "即将超期" },
    { value: "normal", text: "未超期" }
]

const statusSource: ListItem<boolean>[] = [
    { value: false, text: "查看正常数据" },
    { value: true, text: "查看已作废" }
]