import React from "react";
import { Button, DateBox, NumberBox, SelectBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { enums, DataMap, dataSources, ListItem, fdate } from "../../../base";
import { AreaRef, CustomerBottleCondition, CustomerWithBottles, EmployeeRef, OrganizationRef } from "../../../models";
import { emptyDataSources } from "../../../api/data-source-helper";
import { areaService, employeeService, organizationService } from "../../../api";
import { OrgTreeDropDown } from "../../../components";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { ValueChangedEvent as TextChangedEvent } from "devextreme/ui/text_box";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";

interface CustomerBottleReportToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick(customer: CustomerWithBottles, name: string): void;
    onFiltering: (filterValue: any, condition: CustomerBottleCondition) => void;
}

interface CustomerBottleReportToolbarState extends CustomerBottleCondition {
    customer?: CustomerWithBottles;
    orgs: OrganizationRef[];
    allDelivers: EmployeeRef[];
    delivers: EmployeeRef[];
    counties: AreaRef[];
    allStreets: AreaRef[];
    streets: AreaRef[];
    organizationId?: number;
    deliverId?: string;
    countyId?: number;
    streetId?: number;
    status?: string;
    customerKey?: string;
    phone?: string;
}

export default class CustomerBottleReportToolbar 
    extends React.Component<CustomerBottleReportToolbarProps, CustomerBottleReportToolbarState> {

    constructor(props: Readonly<CustomerBottleReportToolbarProps>) {
        super(props);

        this.state = {
            orgs: [],
            allDelivers: [],
            delivers: [],
            counties: [],
            allStreets: [],
            streets: [],
            status: enums.customerStatus.active,
            dateField: 'ScrapDate'
        };

        this.onMinBottleChanged = this.onMinBottleChanged.bind(this);
        this.onDateFieldChanged = this.onDateFieldChanged.bind(this);
        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onDeliverChanged = this.onDeliverChanged.bind(this);
        this.onCountyChanged = this.onCountyChanged.bind(this);
        this.onStreetChanged = this.onStreetChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);
        this.onCustomerKeyChanged = this.onCustomerKeyChanged.bind(this);
        this.onPhoneChanged = this.onPhoneChanged.bind(this);
    }

    componentDidMount() {
        this.loadDataSources();
    }

    async loadDataSources() {
        setTimeout(this.onFiltering, 100);
        
        const dataSources = emptyDataSources();

        await Promise.all([
            (async () => dataSources.orgs = await organizationService.getRefs())(),
            (async () => dataSources.employees = await employeeService.getDelivers())(),
            (async () => dataSources.counties = await areaService.getCounties())(),
            (async () => dataSources.streets = await areaService.getStreets())(),
        ]);

        this.setState({
            orgs: dataSources.orgs,
            allDelivers: dataSources.employees,
            delivers: dataSources.employees,
            counties: dataSources.counties,
            allStreets: dataSources.streets,
            streets: dataSources.streets
        });
    }

    setCustomer(customer?: CustomerWithBottles) {
        this.setState({ customer: customer });
    }

    onMinBottleChanged(e: any) {
        this.setState({ minBottle: e.value });
    }

    onDateFieldChanged(e: ValueChangedEvent) {
        this.setState({ dateField: e.value });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onOrganizationChanged(organizationId?: number) {
        const delivers = organizationId ?
            this.state.allDelivers.filter(d => d.organizationId === organizationId) :
            this.state.allDelivers;

        const deliverId = delivers.find(d => d.id === this.state.deliverId) ? this.state.deliverId : undefined;

        this.setState({
            organizationId: organizationId,
            delivers: delivers,
            deliverId: deliverId
        });
    }

    onFiltering() {
        this.props.onFiltering(this.getFilterValue(), {
            minBottle: this.state.minBottle,
            dateField: this.state.dateField,
            startDate: this.state.startDate ? fdate(this.state.startDate) : undefined,
            endDate: this.state.endDate ? fdate(this.state.endDate) : undefined
        });
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { organizationId, deliverId, countyId, streetId, status, customerKey, phone } = this.state;

        if (organizationId) {
            filters.push(["customer.organizationId", "=", organizationId]);
        }
        if (deliverId) {
            filters.push(["customer.deliverId", "=", deliverId]);
        }
        if (countyId) {
            filters.push(["customer.areaId", "=", countyId]);
        }
        if (streetId) {
            filters.push(["customer.streetId", "=", streetId]);
        }
        if (status) {
            filters.push(["customer.status", "=", status]);
        }
        if (customerKey) {
            filters.push([
                [ "customer.gasCode", "contains", customerKey],
                "or",
                [ "customer.name", "contains", customerKey]
            ]);
        }
        if (phone) {
            filters.push([
                [ "customer.phoneNumber", "contains", phone],
                "or",
                [ "customer.phoneNumber2", "contains", phone]
            ])
        }

        return filters.length > 0 ? filters : null;
    }

    onDeliverChanged(e: ValueChangedEvent) {
        this.setState({ deliverId: e.value });
    }

    onCountyChanged(e: ValueChangedEvent) {
        const streets = e.value ?
            this.state.allStreets.filter(s => s.parentId === e.value) :
            this.state.allStreets;

        const streetId = streets.find(s => s.id === this.state.streetId) ? this.state.streetId : undefined;

        this.setState({
            countyId: e.value,
            streets: streets,
            streetId: streetId
        });
    }

    onStreetChanged(e: ValueChangedEvent) {
        this.setState({ streetId: e.value });
    }

    onStatusChanged(e: ValueChangedEvent) {
        this.setState({ status: e.value });
    }

    onCustomerKeyChanged(e: TextChangedEvent) {
        this.setState({ customerKey: e.value });
    }

    onPhoneChanged(e: TextChangedEvent) {
        this.setState({ phone: e.value });
    }

    render() {
        const { customer, orgs, delivers, counties, streets, organizationId, deliverId, countyId,
             streetId, status, customerKey, phone, minBottle, dateField, startDate, endDate } = this.state;

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto" text={"在用气瓶数量 >="} />
                    <Item location={"before"} locateInMenu={"auto"}>
                        <NumberBox 
                            min={0} 
                            width={80} 
                            showSpinButtons={true}
                            value={minBottle}
                            onValueChanged={this.onMinBottleChanged}  />
                    </Item>
                    <Item location={"before"} locateInMenu="auto" text="限定时间" />
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={dateField}
                            dataSource={dateFields}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={120}
                            onValueChanged={this.onDateFieldChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={startDate}
                            placeholder="开始时间"
                            width={120}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束时间"
                            width={120}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={this.props.permissions["AddOrder"]}>
                        <Button
                            disabled={!customer}
                            type="default"
                            text="呼叫中心"
                            onClick={() => this.props.onButtonClick(customer!, "callcenter")} />
                    </Item>
                </Toolbar>
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={deliverId}
                            dataSource={delivers}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={160}
                            placeholder="送气工"
                            onValueChanged={this.onDeliverChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={countyId}
                            dataSource={counties}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder="行政区域"
                            onValueChanged={this.onCountyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={streetId}
                            dataSource={streets}
                            displayExpr="name"
                            valueExpr={"id"}
                            searchEnabled={true}
                            showClearButton={true}
                            width={100}
                            placeholder="街道"
                            onValueChanged={this.onStreetChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <TextBox
                            value={customerKey}
                            showClearButton={true}
                            placeholder="客户名称或卡号"
                            onValueChanged={this.onCustomerKeyChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <TextBox
                            value={phone}
                            showClearButton={true}
                            placeholder="电话"
                            onValueChanged={this.onPhoneChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={status}
                            dataSource={dataSources.customerStatus}
                            displayExpr="text"
                            valueExpr={"value"}
                            showClearButton={true}
                            width={100}
                            placeholder="状态"
                            onValueChanged={this.onStatusChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}

const dateFields: ListItem[] = [
    { value: "ScrapDate", text: "报废时间" },
    { value: "NextInspectDate", text: "下检时间" }
]