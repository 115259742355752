import React from "react";
import CustomerBottleReportGrid from "./customer-bottle-report-grid";
import CustomerBottleReportToolbar from "./customer-bottle-report-toolbar";
import { ContentBlock } from "../../../components";
import { enums, authorizationService, globalPopupManager, globalPopupNames } from "../../../base";
import { CustomerBottleCondition, CustomerWithBottles } from "../../../models";
import AuthorizedComponent from "../../authorized-component";

export default class CustomerBottleReportPage extends AuthorizedComponent {
    objectName = "Customer";

    gridRef = React.createRef<CustomerBottleReportGrid>();
    toolbarRef = React.createRef<CustomerBottleReportToolbar>();

    constructor(props: any) {
        super(props);

        this.onCustomerSelected = this.onCustomerSelected.bind(this);
        this.filter = this.filter.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    async authorize(): Promise<void> {
        this.permissions["AddOrder"] = await authorizationService.authorize("Order", enums.stdActions.create);

        this.setState({});
    }

    onCustomerSelected(customer?: CustomerWithBottles) {
        this.toolbarRef.current?.setCustomer(customer);
    }

    filter(filterValue: any, condition: CustomerBottleCondition) {
        this.gridRef.current?.filter(filterValue, condition);
    }

    onButtonClick(customer: CustomerWithBottles, name: string) {
        switch (name) {
            case "callcenter":
                if (this.permissions["AddOrder"]) {
                    globalPopupManager.show(globalPopupNames.callCenter, { id: customer.customer.id });
                }
                break;
        }
    }

    render() {
        return (
            <ContentBlock caption={"客户在用气瓶"}>
                <CustomerBottleReportToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onFiltering={this.filter}
                    onButtonClick={this.onButtonClick} />
                <CustomerBottleReportGrid
                    permissions={this.permissions}
                    ref={this.gridRef}
                    onCustomerSelected={this.onCustomerSelected}
                    onButtonClick={this.onButtonClick} />
            </ContentBlock>
        );
    }
}