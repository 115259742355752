import React from "react";
import { areaService, customerTypeService, organizationService, predefinedLabelService } from "../../../api";
import { enums, fdate, ListItem, today } from "../../../base";
import { AreaRef, CustomerTypeRef, OrderTrendReportCondition, OrganizationRef, PredefinedLabelRef } from "../../../models";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { Button, DateBox, SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { OrgTreeDropDown } from "../../../components";

export interface OrderTrendToolbarProps {
    onFiltering: (condition: OrderTrendReportCondition) => void;
    showChart: () => void;
}

interface OrderTrendToolbarState {
    orgs: OrganizationRef[];
    counties: AreaRef[];
    customerTypeLabels: PredefinedLabelRef[];
    customerTypes: CustomerTypeRef[];
    condition: OrderTrendReportCondition;
}

export default class OrderTrendToolbar extends
    React.Component<OrderTrendToolbarProps, OrderTrendToolbarState> {
    
    constructor(props: Readonly<OrderTrendToolbarProps>) {
        super(props);

        const day = today();

        this.state = {
            orgs: [],
            counties: [],
            customerTypeLabels: [],
            customerTypes: [],
            condition: {
                startDate: fdate(new Date(day.getFullYear(), day.getMonth(), 1)),
                endDate: fdate(day),
                groupedBy: "month"
            }
        };

        this.onGroupedByChanged = this.onGroupedByChanged.bind(this);
        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onCountyChanged = this.onCountyChanged.bind(this);
        this.onCustomerTypeLabelChanged = this.onCustomerTypeLabelChanged.bind(this);
        this.onCustomerTypeChanged = this.onCustomerTypeChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.showChart = this.showChart.bind(this);
    }

    componentDidMount(): void {
        this.loadDataSources();
    }

    async loadDataSources() {
        let orgs: OrganizationRef[] = [],
            counties: AreaRef[] = [],
            customerTypeLabels: PredefinedLabelRef[] = [],
            customerTypes: CustomerTypeRef[] = [];

        await Promise.all([
            (async () => orgs = await organizationService.getRefs())(),
            (async () => counties = await areaService.getCounties())(),
            (async () => customerTypeLabels = 
                await predefinedLabelService.getByDataType(enums.labelDataTypes.customerType))(),
            (async () => customerTypes = await customerTypeService.getRefs())()
        ]);

        this.setState({
            orgs: orgs,
            counties: counties,
            customerTypeLabels: customerTypeLabels,
            customerTypes: customerTypes
        });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.updateCondition({ startDate: e.value });
    }

    private updateCondition(updated: any) {
        const cond = { ...this.state.condition, ...updated };
        cond.startDate = fdate(cond.startDate);
        cond.endDate = fdate(cond.endDate);

        this.setState({ 
            condition: cond
        });
    }

    onGroupedByChanged(e: ValueChangedEvent) {
        this.updateCondition({ groupedBy: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.updateCondition({ endDate: e.value });
    }

    onOrganizationChanged(organizationId?: number) {
        this.updateCondition({ organizationId: organizationId });
    }

    onCustomerTypeLabelChanged(e: ValueChangedEvent) {
        this.updateCondition({ typeLabelId: e.value });
    }

    onCustomerTypeChanged(e: ValueChangedEvent) {
        this.updateCondition({ typeId: e.value });
    }

    onCountyChanged(e: ValueChangedEvent) {
        this.updateCondition({ countyId: e.value });
    }
    
    onFiltering() {
        this.props.onFiltering(this.state.condition);
    }

    showChart() {
        this.props.showChart();
    }

    render() {
        const { condition, orgs, counties, customerTypes, customerTypeLabels } = this.state;

        return (
            <div className="page-toolbar">
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={condition.groupedBy}
                            dataSource={groupTypes}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={100}
                            onValueChanged={this.onGroupedByChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={condition.startDate}
                            placeholder="开始时间"
                            width={120}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={condition.endDate}
                            placeholder="结束时间"
                            width={120}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={condition.organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={condition.typeLabelId}
                            dataSource={customerTypeLabels}
                            displayExpr="labelText"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder={"客户分组"}
                            onValueChanged={this.onCustomerTypeLabelChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={condition.typeId}
                            dataSource={customerTypes}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder={"客户类型"}
                            onValueChanged={this.onCustomerTypeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={condition.countyId}
                            dataSource={counties}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder={"行政区域"}
                            onValueChanged={this.onCountyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu="false">
                        <Button
                            text="显示图表"
                            type="default"
                            onClick={this.showChart} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}

const groupTypes: ListItem[] = [
    { value: "year", text: "按年统计" },
    { value: "month", text: "按月统计" },
    { value: "week", text: "按周统计" },
    { value: "day", text: "按天统计" },
]