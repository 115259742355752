import React from "react";
import AuthorizedComponent from "../authorized-component";
import StockInitForm from "./stock-init-form";
import StockInitGrid from "./stock-init-grid";
import { enums } from "../../base";
import { ContentBlock, StdToolbar } from "../../components";
import { OrganizationStockInitModel } from "../../models";

export default class StockInitPage extends AuthorizedComponent {
    objectName = "Stock";
    moduleName = "Stock";
    gridRef = React.createRef<StockInitGrid>();
    formRef = React.createRef<StockInitForm>();
    toolbarRef = React.createRef<StdToolbar>();

    get actionNames() {
        return [enums.stdActions.update];
    }

    constructor(props: Readonly<any>) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
    }

    componentDidMount(): void {
        this.permissions[enums.stdActions.create] = false;
        this.permissions[enums.stdActions.delete] = false;

        super.componentDidMount();
    }

    onModelSelected(model: OrganizationStockInitModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onButtonClick(model: OrganizationStockInitModel, name: string) {
        switch (name) {
            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.formRef.current?.show(model);
                }
                break;
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return (
            <ContentBlock caption={"库存初量设置"}>
                <StdToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onButtonClick={this.onButtonClick} />
                <StockInitGrid 
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
                <StockInitForm ref={this.formRef} onSaved={this.onSaved} />
            </ContentBlock>
        );
    }
}