import React from "react";
import { ExportingEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import DataGrid, { IColumnProps, IDataGridOptions } from "devextreme-react/data-grid";
import { ModuleConfig, dataSources, createEmptyStore, baseApiUrl, globalPopupManager, globalPopupNames, authorizationService, enums, allBottleOperations } from "../../base";
import { createGridOptions, ExportModel, remoteReadOnlyGridOptions, setColumnDataSource, StdGrid } from "../../components";
import { bottleFlowService, bottleFlowStore, bottleTypeService } from "../../api";
import { BottleFlowModel, BottleTypeModel } from "../../models";
import CustomStore from "devextreme/data/custom_store";

export interface BottleFlowGridProps {
    showDangerIssueButton: boolean;
    onModelSelected(model: BottleFlowModel): void;
    onButtonClick(model: BottleFlowModel, name: string): void;
}

interface BottleFlowGridState {
    store: CustomStore;
    bottleTypes: BottleTypeModel[];
    columns: IColumnProps[];
}

export default class BottleFlowGrid
    extends React.Component<BottleFlowGridProps, BottleFlowGridState> {
    objectName = "BottleFlow";
    moduleName: ModuleConfig = {
        moduleName: "BottleFlow",
        children: {
            "bottle": "Bottle"
        }
    };

    gridRef = React.createRef<DataGrid>();
    gridOptions: IDataGridOptions;

    constructor(props: Readonly<BottleFlowGridProps>) {
        super(props);

        this.gridOptions = createGridOptions(
            "bottleFlows",
            this.gridRef,
            remoteReadOnlyGridOptions,
            (items: any[]) => {
                // state清空会同时清空filter，需要重新绑定旧的
                items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
                return items;
            });

        this.state = {
            store: createEmptyStore(),
            bottleTypes: [],
            columns: [
                { dataField: "barCode" },
                { dataField: "bottle.code" },
                {
                    dataField: "bottle.typeId",
                    lookup: { dataSource: [], displayExpr: "name", valueExpr: "id" }
                },
                { dataField: "createdAt", dataType: "datetime", allowFiltering: false, caption: "操作时间" },
                { dataField: "createdBy", allowFiltering: false, caption: "操作员" },
                { dataField: "batchCode" },
                {
                    dataField: "flowType",
                    lookup: { dataSource: allBottleOperations, displayExpr: "text", valueExpr: "value" }
                },
                {
                    dataField: "sourceType",
                    lookup: { dataSource: dataSources.bottlePositions, displayExpr: "text", valueExpr: "value" }
                },
                {
                    dataField: "source",
                    cellRender: (e) => {
                        if (e.data.sourceType === enums.bottlePositions.customer) {
                            return (
                                <div>
                                    <span className={"link-button"}
                                        onClick={() => this.props.onButtonClick(e.data, "callCenter")}>
                                        {e.data.source}
                                    </span>
                                    {this.props.showDangerIssueButton && (
                                        <span className="link-button" onClick={() => this.props.onButtonClick(e.data, "issueDanger")}>
                                            下发隐患
                                        </span>
                                    )}
                                </div>
                            )
                        } else {
                            return <span>{e.data.source}</span>
                        }
                    }
                },
                {
                    dataField: "targetType",
                    lookup: { dataSource: dataSources.bottlePositions, displayExpr: "text", valueExpr: "value" }
                },
                {
                    dataField: "target",
                    cellRender: (e) => {
                        if (e.data.targetType === enums.bottlePositions.customer) {
                            return (
                                <div>
                                    <span className={"link-button"}
                                        onClick={() => this.props.onButtonClick(e.data, "callCenter")}>
                                        {e.data.target}
                                    </span>
                                    {this.props.showDangerIssueButton && (
                                        <span className="link-button"
                                            onClick={() => this.props.onButtonClick(e.data, "issueDanger")}>
                                            下发隐患
                                        </span>
                                    )}
                                </div>
                            )
                        } else {
                            return <span>{e.data.target}</span>
                        }
                    }
                }, {
                    dataField: "flag",
                    lookup: { dataSource: dataSources.bottleFlowFlags, displayExpr: "text", valueExpr: "value" }
                }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onExporting = this.onExporting.bind(this);
        this.gridOptions.export!.allowExportSelectedData = false;
        this.gridOptions.export!.enabled = false;
    }

    componentDidMount() {
        this.authorizeImport();
        this.loadBottleTypes();
    }

    async loadBottleTypes() {
        const bottleTypes = await bottleTypeService.getAll();
        setColumnDataSource(this.state.columns, "bottle.typeId", bottleTypes);
        this.setState({ bottleTypes: bottleTypes });
    }

    async authorizeImport() {
        if (await authorizationService.authorize(this.objectName, enums.stdActions.export)) {
            this.gridOptions.export!.enabled = true;
            this.gridRef.current?.instance.option("export.enabled", true);
        }
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});
        }
    }

    onExporting(e: ExportingEvent) {
        const args: ExportModel = {
            moduleName: this.moduleName.moduleName,
            exportUrl: `${baseApiUrl}${bottleFlowService.apiPath}/export`,
            columns: this.state.columns,
            keys: e.component.getSelectedRowKeys(),
            filter: e.component.getCombinedFilter()
        };

        e.cancel = true;
        globalPopupManager.show(globalPopupNames.exportPopup, args);
    }

    async refresh() {
        await this.gridRef.current?.instance.refresh();
        const model = this.gridRef.current?.instance.getSelectedRowsData()[0];
        this.props.onModelSelected(model);
    }

    filter(filterValue: any) {
        if (filterValue) {
            this.setState({ store: bottleFlowStore }, () => this.gridRef.current?.instance.filter(filterValue));
        } else {
            this.setState({ store: createEmptyStore() });
        }
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns}>
            </StdGrid>
        );
    }

}