import React, { } from "react";
import Form, { IItemProps } from "devextreme-react/form";
import { PopupContentBox } from "../layouts";
import { StdForm, createPopupField, FormMultiImageUploader } from "../form";
import { ModuleConfig, StringMap, alertError, notifySuccess, dataSources, alertWarning, enums } from "../../base";
import { organizationService, employeeService, dangerService, customerService } from "../../api";
import { Popup, ScrollView, TextBox } from "devextreme-react";
import { RefPopup } from "../popup";
import { setItemDataSource } from "../options";
import CustomerPopup from "./customer-popup";
import { DangerItemModel, DangerModel, EmployeeRef, OrganizationRef } from "../../models";

export interface IssueDangerPopupProps {
    onSaved?: () => void;
}

interface DangerWithName extends DangerModel {
    _customerName?: string;
}

interface IssueDangerState {
    danger: DangerWithName;
    orgs: OrganizationRef[];
    allEmployees: EmployeeRef[];
    items: IItemProps[];
}

export default class IssueDangerPopup extends React.Component<IssueDangerPopupProps, IssueDangerState> {
    moduleName: ModuleConfig = {
        moduleName: "Danger",
        children: {
            customer: "Customer"
        }
    };

    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    scrollViewRef = React.createRef<ScrollView>();
    customerPopupRef = React.createRef<CustomerPopup>();
    nameMap: StringMap = { "_customerName": "customerId" };
    items: IItemProps[] = [
        createPopupField("_customerName", this.customerPopupRef),
        {
            dataField: 'organizationId',
            editorType: 'dxSelectBox',
            editorOptions: { dataSource: [], displayExpr: 'name', valueExpr: 'id', readOnly: true }
        },
        {
            dataField: 'handlerId',
            label: { text: '送气工/安检员' },
            editorType: 'dxSelectBox',
            editorOptions: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
        },
        { dataField: 'checker', label: { text: '检查单位' } },
        { dataField: 'checkTime', editorType: 'dxDateBox', editorOptions: { type: "datetime" } },
        { dataField: 'expirationDate', editorType: 'dxDateBox' },
        { dataField: 'address' },
        { dataField: 'phoneNumber' },
        {
            dataField: 'dangerLevel',
            editorType: 'dxSelectBox',
            editorOptions: { dataSource: dataSources.dangerLevels, displayExpr: 'text', valueExpr: 'value' }
        },
        { name: 'empty', itemType: 'empty' },
        {
            name: 'addbutton',
            itemType: 'button',
            horizontalAlignment: 'left',
            buttonOptions: { text: "添加隐患项", type: 'success', onClick: this.addItem.bind(this) },
            colSpan: 2
        },
        {
            dataField: 'note',
            colSpan: 2,
            cssClass: "nopadding-file",
            editorType: 'dxTextArea',
            editorOptions: {
                height: 100,
                stylingMode: "outlined"
            }
        }
    ];

    constructor(props: Readonly<IssueDangerPopupProps>) {
        super(props);

        this.state = {
            danger: {},
            orgs: [],
            allEmployees: [],
            items: [...this.items]
        };

        this.onCustomerSelected = this.onCustomerSelected.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let orgs: OrganizationRef[] = [], employees: EmployeeRef[] = [];

        await Promise.all([
            (async () => orgs = await organizationService.getRefs())(),
            (async () => employees = await employeeService.getDelivers())()
        ]);

        setItemDataSource(this.state.items, "organizationId", orgs);
        setItemDataSource(this.state.items, "handlerId", employees);

        this.setState({
            orgs: orgs,
            allEmployees: employees
        });
    }

    show(customer?: any) {
        this.popupRef.current?.instance.show();

        const dangerItems: DangerItemModel[] = [{}];
        customer = customer ?? {};

        this.setState({
            danger: {
                customer: customer,
                customerId: customer.id,
                _customerName: this.getCustomerName(customer),
                phoneNumber: customer.phoneNumber,
                address: customer.address,
                organizationId: customer.organizationId,
                items: dangerItems,
                checkTime: new Date()
            },
            items: [
                ...this.items.slice(0, this.items.length - 2),
                ...this.createItem(dangerItems[0], 0),
                ...this.items.slice(this.items.length - 2)
            ]
        }, () => {
            if (customer.id) {
                const employees = this.state.allEmployees.filter(e => e.organizationId === customer.organizationId);
                setItemDataSource(this.state.items, "handlerId", employees);
                this.setState({ });
                this.getDefaultHandler(customer.id);
            }
        });
    }

    async getDefaultHandler(id: any) {
        const danger = {
            ...this.state.danger,
            handlerId: await customerService.getLastDeliver(id)
        };

        this.setState({ danger: danger });
    }

    getCustomerName(customer: any) {
        return customer?.name ?
            `${customer.name}（${customer.gasCode}${customer.status === enums.customerStatus.inactive ? '，已退户' : ''}）` :
            "";
    }

    onCustomerSelected(customer: any) {
        const danger = {
            ...this.state.danger,
            customer: customer,
            customerId: customer.id,
            _customerName: this.getCustomerName(customer),
            phoneNumber: customer.phoneNumber,
            address: customer.address,
            organizationId: customer.organizationId,
        }

        const employees = this.state.allEmployees.filter(e => e.organizationId === customer.organizationId);
        setItemDataSource(this.state.items, "handlerId", employees);

        this.setState({ danger: danger });
        this.getDefaultHandler(customer.id);
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    addItem() {
        const items = this.state.danger.items!;
        const len = items.length;
        items.push({});

        this.setState({
            items: [
                ...this.state.items.slice(0, this.state.items.length - 2),
                ...this.createItem(items[len], len),
                ...this.state.items.slice(this.state.items.length - 2)
            ]
        });
    }

    createItem(item: any, index: number): IItemProps[] {
        return [
            {
                dataField: `item_itemName${index}`,
                label: { text: `隐患${index + 1}` },
                render: () => (
                    <TextBox defaultValue={item.itemName}
                        onValueChanged={this.onItemValueChanged.bind(this, index)} />
                )
            },
            {
                dataField: `item_photo${index}`,
                label: { text: '图片' },
                render: () => (
                    <FormMultiImageUploader
                        formData={item}
                        dataField={"photo"}
                        sourceField={"photoSource"}
                        id={`dangerIssueItem${index}`} />
                )
            }
        ]
    }

    onItemValueChanged(index: number, e: any) {
        const items: DangerItemModel[] = this.state.danger.items!;
        items[index].itemName = e.value;

        this.setState({ danger: this.state.danger });
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();
        if (!isValid?.isValid) {
            return;
        } else if (!this.state.danger.items?.filter(i => i.itemName).length) {
            alertWarning("请填写隐患项");
            return;
        }

        const result = await dangerService.create(this.state.danger);

        if (result.succeeded) {
            this.setState({ danger: {} });
            notifySuccess("已成功下发隐患");
            this.hide();
            this.props.onSaved && this.props.onSaved();
        } else {
            alertError(result.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render() {

        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"隐患下发"}
                width={1000}
                height={580}>
                <PopupContentBox onSave={this.save} onCancel={this.cancel}>
                    <ScrollView ref={this.scrollViewRef}>
                        <StdForm
                            moduleName={this.moduleName}
                            formRef={this.formRef}
                            colCount={2}
                            items={this.state.items}
                            nameMap={this.nameMap}
                            formData={this.state.danger} />
                    </ScrollView>
                </PopupContentBox>
                <CustomerPopup ref={this.customerPopupRef} onCustomerSelected={this.onCustomerSelected} />
            </RefPopup>
        );
    }
}

