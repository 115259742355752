import { enums, http, MessageBool, BaseApiService, createStoreFromUrl } from "../base";
import { ChangePasswordModel, EmployeeModel, EmployeeMoveModel, EmployeeRef, SetPasswordModel } from "../models";

class EmployeeService extends BaseApiService<EmployeeModel> {
    moduleName = "employee";
    caches: any = {};

    getDxData(roleId?: number) {
        return createStoreFromUrl(`${this.apiPath}/dx?roleId=${roleId || ''}`, 'id');
    }

    getDelivers(): Promise<EmployeeRef[]> {
        return this.getRefs(enums.employeePosts.deliver);
    }

    getRefs(...posts: string[]): Promise<EmployeeRef[]> {
        const postStr = posts ? posts.join(',') : "";
        return this.getCached(
            () => http.getData(`${this.apiPath}/refs`, { posts: postStr }), 300, true, postStr);
    }

    /**
     * 为解决押金、钢瓶使用费没有外键级联加载的问题，使用加载所有员工的方式来关联
     */
    getAllRefs(...posts: string[]): Promise<EmployeeRef[]> {
        const postStr = posts ? posts.join(',') : "";
        return this.getCached(
            () => http.getData(`${this.apiPath}/allrefs`, { posts: postStr }), 300, true, `${postStr}.all`);
    }

    searchDelivers(customerId: number): Promise<EmployeeRef[]> {
        return http.getData(`${this.apiPath}/delivers?customerId=${customerId || ""}`);
    }

    changePassword(model: ChangePasswordModel): Promise<MessageBool> {
        return http.putData(`${this.apiPath}/changepassword`, model);
    }

    setPassword(model: SetPasswordModel): Promise<MessageBool> {
        return http.putData(`${this.apiPath}/setpassword`, model);
    }

    move(model: EmployeeMoveModel): Promise<MessageBool> {
        return http.putData(`${this.apiPath}/move`, model);
    }

    transferCustomers(id: string, targetId: string): Promise<MessageBool> {
        return http.putData(`${this.apiPath}/transfercustomers/${id}?targetId=${targetId}`, '');
    }

    unbindWeiXin(weiXinOpenId: string): Promise<MessageBool> {
        return http.putData(`${this.apiPath}/unbindweixin?weiXinOpenId=${weiXinOpenId}`, '');
    }
}

export const employeeService = new EmployeeService();
export const employeeDefaultStore = employeeService.getDxData();