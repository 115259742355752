import { http, SysObject, CachableApiService } from '../base';

class SysObjectService extends CachableApiService {
    cacheSeconds = 1200;

    getAll(): Promise<SysObject[]> {
        return this.getCached(() => http.getData('/api/object'), this.cacheSeconds);
    }
    
    async getVisible(): Promise<SysObject[]> {
        const all = await this.getAll();
        return all.filter(a => a.orderNumber !== -1);
    }
}

export const sysObjectService = new SysObjectService();