import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";
import React from "react";
import { bottleTypeService, serverDataService } from "../../../api";
import { orderReportService } from "../../../api/reports";
import { baseApiUrl, download, http } from "../../../base";
import { ContentBlock, createGridOptions, StdGrid, stdReadOnlyGridOptions } from "../../../components";
import { AverageUsageReport, AverageUsageReportCondition, BottleTypeModel } from "../../../models";
import AuthorizedComponent from "../../authorized-component";
import AverageUsageToolbar from "./average-usage-toolbar";

interface AverageUsageReportPageState {
    bottleTypes: BottleTypeModel[];
    columns: IColumnProps[];
    data: AverageUsageReport[];
}

export default class AverageUsageReportPage
    extends AuthorizedComponent<any, AverageUsageReportPageState> {
    
    objectName = "AverageUsage";
    moduleName = "";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("averageUsageReport", this.gridRef, stdReadOnlyGridOptions);

    constructor(props: any) {
        super(props);

        this.state = {
            bottleTypes: [],
            data: [],
            columns: [
                { dataField: "countyName", caption: "行政区域" },
                { dataField: "organizationName", caption: "站点" },
                { dataField: "typeName", caption: "客户类型" },
                { dataField: "averageOrderCount", caption: "平均配送次数" },
                ...serverDataService.gasBottleTypes.map(t => ({
                    dataField: `item_${t.id}`,
                    caption: `${t.name}平均数`,
                    allowGrouping: false,
                    allowSorting: false,
                    cellRender: (e: any) => {
                        const count = e.data.averageBottleCount[t.id!] || 0;
                        return (<span>{count}</span>)
                    }
                }))
            ]
        };

        this.gridOptions.filterRow!.visible = false;
        this.gridOptions.export!.enabled = false;
        this.gridOptions.keyExpr = ["countyId", "typeId", "organizationId"]

        this.search = this.search.bind(this);
        this.export = this.export.bind(this);
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.loadBottleTypes();
    }

    async loadBottleTypes() {
        const bottleTypes = await bottleTypeService.getRefs();
        this.setState({ bottleTypes: bottleTypes });
    }

    async search(condition: AverageUsageReportCondition) {
        const data = await orderReportService.getAverageUsage(condition);
        this.setState({ data: data });
    }

    async export(condition: AverageUsageReportCondition) {
        await download(
            `${baseApiUrl}${orderReportService.apiPath}/averageusage/export?${http.serialize([condition])}`,
            "平均用气量报表.xlsx");
    }

    render() {
        return (
            <ContentBlock caption="平均用气量报表">
                <AverageUsageToolbar onFiltering={this.search} onExporting={this.export} />
                <StdGrid
                    moduleName={this.moduleName}
                    gridRef={this.gridRef}
                    dataSource={this.state.data}
                    options={this.gridOptions}
                    columns={this.state.columns} />
            </ContentBlock>
        )
    }
}