import './message-box.scss';
import React from "react";
import { announcementService, webMessageService } from '../../api';
import { historyInstance } from '../../base';

interface MessageBoxState {
    messageCount: number;
    announcementCount: number;
}

const checkInterval = 60000;

export default class MessageBox extends React.Component<any, MessageBoxState> {
    timer: any;

    constructor(props: any) {
        super(props);

        this.state = {
            messageCount: 0,
            announcementCount: 0
        };
    }

    componentDidMount() {
        this.checkMessage();
    }

    async checkMessage() {
        try {
            const messageCount = await webMessageService.count();
            const announcementCount = await announcementService.count();

            if (messageCount > this.state.messageCount || announcementCount > this.state.announcementCount) {
                (document.getElementById('message-audio') as HTMLAudioElement).play();
            }

            this.setState({ 
                messageCount: messageCount,
                announcementCount: announcementCount
            });
        }
        finally {
            this.startTimer();
        }
    }

    startTimer() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }

        this.timer = setTimeout(() => this.checkMessage(), checkInterval);
    }

    gotoMessage() {
        historyInstance.push('/web-message');
    }

    gotoAnnouncement() {
        historyInstance.push('/my-announcement');
    }

    render() {
        return (
            <React.Fragment>
                <div className="message-box" onClick={this.gotoAnnouncement}>
                    <i className='bi bi-megaphone'></i>
                    {!!this.state.announcementCount && (
                        <span className='message-num'>{this.state.announcementCount}</span>
                    )}
                </div>
                <div className="message-box" onClick={this.gotoMessage}>
                    <i className="bi bi-bell"></i>
                    {!!this.state.messageCount && (
                        <span className="message-num">{this.state.messageCount}</span>
                    )}
                    <audio id='message-audio' src="/newmsg.mp3" autoPlay={false} />
                </div>
            </React.Fragment>
        )
    }
}