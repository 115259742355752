import React from "react";
import ProductOrderGrid from "./product-order-grid";
import ProductOrderToolbar from "./product-order-toolbar";
import AuthorizedComponent from "../authorized-component";
import { ContentBlock } from "../../components";
import { ProductOrderModel } from "../../models";
import { alertError, confirm2, enums, globalPopupManager, globalPopupNames, notifySuccess, prompt2 } from "../../base";
import { productOrderService } from "../../api";

export default class ProductOrderPage extends AuthorizedComponent {
    objectName = "ProductOrder";
    gridRef = React.createRef<ProductOrderGrid>();
    toolbarRef = React.createRef<ProductOrderToolbar>();

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.filter = this.filter.bind(this);
    }

    onModelSelected(model: ProductOrderModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onButtonClick(model: ProductOrderModel, name: string) {
        switch (name) {
            case "callcenter":
                globalPopupManager.show(globalPopupNames.callCenter, { id: model.customerId });
                break;

            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.setNote(model);
                }
                break;

            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(model);
                }
                break;

            case "restore":
                if (this.permissions[enums.stdActions.delete]) {
                    this.restore(model);
                }
                break;
        }
    }

    filter(filterValue: any, showDeleted: boolean) {
        this.gridRef.current?.filter(filterValue, showDeleted);
    }

    async setNote(model: ProductOrderModel) {
        const note = await prompt2("请输入备注：", { defaultValue: model.note || "" });
        
        if (note) {
            const res = await productOrderService.setNote({ id: model.id!, note: note });
            if (res.succeeded) {
                notifySuccess("成功保存备注");
                this.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    async delete(model: ProductOrderModel) {
        if (!await confirm2("确定要作废该条数据吗？")) {
            return;
        }

        const result = await productOrderService.delete(model.id!);

        if (result.succeeded) {
            notifySuccess("已成功作废数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async restore(model: ProductOrderModel) {
        if (!await confirm2("确定要还原该条数据吗？")) {
            return;
        }

        const result = await productOrderService.restore(model.id!);

        if (result.succeeded) {
            notifySuccess("已成功还原数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return (
            <ContentBlock caption={"配件订单"}>
                <ProductOrderToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onFiltering={this.filter}
                    onButtonClick={this.onButtonClick} />
                <ProductOrderGrid
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
            </ContentBlock>
        )
    }
}