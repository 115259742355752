import React, { PropsWithChildren } from "react";
import TreeList, { Column, IColumnProps, ITreeListOptions } from "devextreme-react/tree-list";
import { ModuleConfig, StringMap } from "../../base/models";
import { useGridHeight, useScreenSize, useTreeColumnFill } from "../../hooks";
import { setTreeListPopupOptions } from "../options/tree-list-options";

export interface StdTreeListProps {
    moduleName: string | ModuleConfig;
    treeRef?: React.LegacyRef<TreeList>;
    dataSource: any;
    keyName: string;
    parentIdExpr: string;
    options: ITreeListOptions;
    columns: IColumnProps[];
    columnNameMap?: StringMap;
    popupWidth?: number;
    popupHeight?: number;
    formColCount?: number;
}

/**
 * 使用该类以使用系统公共设置；
 * 这里应使用Column子元素而不是columns属性（不更新）
 */
export default function StdTreeList(props: PropsWithChildren<StdTreeListProps>) {
    const height = useGridHeight();
    const fill = useTreeColumnFill();
    const screenSize = useScreenSize();

    fill(props.moduleName, props.columns, props.columnNameMap);
    setTreeListPopupOptions(props.options, props.popupWidth, props.popupHeight, screenSize);

    if (props.formColCount && props.options.editing) {
        props.options.editing.form = {
            ...(props.options.editing.form || {}),
            colCount: props.formColCount
        };
    } else if (!props.formColCount && props.options.editing?.form) {
        props.options.editing.form.colCount = undefined;
    }

    return (
        <TreeList
            ref={props.treeRef}
            dataSource={props.dataSource}
            keyExpr={props.keyName}
            parentIdExpr={props.parentIdExpr}
            height={height}
            {...props.options}>
            {
                props.columns.map(c => (
                    <Column key={c.dataField || c.name} {...c} />
                ))
            }
            {props.children}
        </TreeList>
    );
}