import React from "react";
import { customerRequestService } from "../../api";
import { alertError, authorizationService, enums, notifySuccess, prompt2 } from "../../base";
import { AssignOrganizationPopup, ContentBlock } from "../../components";
import { CustomerRequestModel, OrganizationRef } from "../../models";
import AuthorizedComponent from "../authorized-component";
import CustomerForm from "./customer-form";
import CustomerRequestGrid from "./customer-request-grid";
import CustomerRequestToolbar from "./customer-request-toolbar";

export default class CustomerRequestPage extends AuthorizedComponent {
    objectName = "CustomerRequest";
    gridRef = React.createRef<CustomerRequestGrid>();
    toolbarRef = React.createRef<CustomerRequestToolbar>();
    formRef = React.createRef<CustomerForm>();
    organizationRef = React.createRef<AssignOrganizationPopup>();
    modelForAssign?: CustomerRequestModel;

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.onSaved = this.onSaved.bind(this);
        this.assign = this.assign.bind(this);
    }

    get actionNames(): string[] {
        return [enums.stdActions.delete, "Assign"];
    }

    async authorize(): Promise<void> {
        await super.authorize();

        this.permissions["CreateCustomer"] = 
            await authorizationService.authorize('Customer', enums.stdActions.create);
    }

    onModelSelected(model: CustomerRequestModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onFiltering(filterValue: any) {
        this.gridRef.current?.filter(filterValue);
    }

    onButtonClick(model: CustomerRequestModel, name: string) {
        switch (name) {
            case "assign":
                if (this.permissions["Assign"] && model.status === enums.customerRequestStatus.requested) {
                    this.showOrganizationPopup(model);
                }
                break;

            case "customer":
                if (this.permissions["CreateCustomer"] && model.status === enums.customerRequestStatus.requested) {
                    this.formRef.current?.show(Object.assign({}, model, { id: 0, requestId: model.id }));
                }
                break;

            case "discard":
                if (this.permissions[enums.stdActions.delete] && model.status === enums.customerRequestStatus.requested) {
                    this.discard(model);
                }               
                break;
        }
    }

    showOrganizationPopup(model: CustomerRequestModel) {
        this.modelForAssign = model;
        this.organizationRef.current?.show(model.organizationId);
    }

    async assign(org: OrganizationRef) {
        const result = await customerRequestService.assign(this.modelForAssign?.id!, org.id!);

        if (result.succeeded) {
            this.organizationRef.current?.hide();
            notifySuccess("分配成功");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async discard(model: CustomerRequestModel) {
        const note = (await prompt2("请输入理由："))?.trim();
        if (!note) {
            alertError("未提供理由，作废失败！");
            return;
        }

        const res = await customerRequestService.discard(model.id!, note);
        if (res.succeeded) {
            this.gridRef.current?.refresh();
            notifySuccess("已成功作废数据");
        } else {
            alertError(res.message!);
        }
    }
    
    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return (
            <ContentBlock caption="微信开户申请">
                <CustomerRequestToolbar
                    ref={this.toolbarRef} 
                    permissions={this.permissions} 
                    onFiltering={this.onFiltering}
                    onButtonClick={this.onButtonClick} />
                <CustomerRequestGrid
                    ref={this.gridRef} 
                    onButtonClick={this.onButtonClick} 
                    onModelSelected={this.onModelSelected} />
                <CustomerForm 
                    ref={this.formRef}
                    onSaved={this.onSaved} />
                <AssignOrganizationPopup ref={this.organizationRef} save={this.assign} />
            </ContentBlock>
        )
    }
}