import { Button, SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import React from "react";
import { areaService, organizationService } from "../../api";
import { DataMap, enums } from "../../base";
import { OrgTreeDropDown } from "../../components";
import { AreaRef, OrganizationRef, RecycleRequestModel } from "../../models";

export interface RecycleRequestToolbarProps {
    permissions?: DataMap<boolean>;
    onButtonClick: (model: RecycleRequestModel, name: string) => void;
    onFiltering: (filterValue: any) => void;
}

interface RecycleRequestToolbarState {
    model: RecycleRequestModel;
    organizationId?: number;
    countyId?: number;
    streetId?: number;
    orgs: OrganizationRef[];
    counties: AreaRef[];
    allStreets: AreaRef[];
    streets: AreaRef[];
}

export default class RecycleRequestToolbar
    extends React.Component<RecycleRequestToolbarProps, RecycleRequestToolbarState> {
    constructor(props: Readonly<RecycleRequestToolbarProps>) {
        super(props);

        this.state = {
            model: {},
            counties: [],
            allStreets: [],
            streets: [],
            orgs: []
        };

        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onCountyChanged = this.onCountyChanged.bind(this);
        this.onStreetChanged = this.onStreetChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount() {
        this.loadDataSources();
    }

    async loadDataSources() {
        let counties: AreaRef[] = [], streets: AreaRef[] = [];
        let orgs: OrganizationRef[] = [];

        await Promise.all([
            (async () => counties = await areaService.getCounties())(),
            (async () => streets = await areaService.getStreets())(),
            (async () => orgs = await organizationService.getRefs())()
        ]);

        this.setState({
            counties: counties,
            allStreets: streets,
            streets: streets,
            orgs: orgs
        });
    }

    setModel(model: RecycleRequestModel) {
        this.setState({ model: model });
    }

    onOrganizationChanged(organizationId?: number) {
        this.setState({
            organizationId: organizationId
        });
    }

    onCountyChanged(e: ValueChangedEvent) {
        const streets = e.value ?
            this.state.allStreets.filter(s => s.parentId === e.value) :
            this.state.allStreets;

        const streetId = streets.find(s => s.id === this.state.streetId) ? this.state.streetId : undefined;

        this.setState({
            countyId: e.value,
            streets: streets,
            streetId: streetId
        });
    }

    onStreetChanged(e: ValueChangedEvent) {
        this.setState({ streetId: e.value });
    }

    onFiltering() {
        this.props.onFiltering(this.getFilterValue());
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { countyId, streetId, organizationId } = this.state;

        if (countyId) {
            filters.push(["customer.areaId", "=", countyId]);
        }
        if (streetId) {
            filters.push(["customer.streetId", "=", streetId]);
        }
        if (organizationId) {
            filters.push(["customer.organizationId", "=", organizationId]);
        }

        return filters;
    }

    render() {
        const { model, countyId, counties, streets, streetId, orgs, organizationId } = this.state;
        const permissions = this.props.permissions || {};
        const enabled = !!model.id && 
            (model.status === enums.recycleRequestStatus.requested ||
                model.status === enums.recycleRequestStatus.assigned);

        return (
            <div className="page-toolbar">
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={countyId}
                            dataSource={counties}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder="行政区域"
                            onValueChanged={this.onCountyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={streetId}
                            dataSource={streets}
                            displayExpr="name"
                            valueExpr={"id"}
                            searchEnabled={true}
                            showClearButton={true}
                            width={120}
                            placeholder="街道"
                            onValueChanged={this.onStreetChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={!!permissions[enums.stdActions.create]}>
                        <Button
                            text="新增"
                            onClick={() => this.props.onButtonClick({}, "add")} />
                    </Item>
                    <Item location="after" locateInMenu={"auto"} visible={!!permissions["Assign"]}>
                        <Button
                            text="分配送气工"
                            disabled={!enabled}
                            onClick={() => this.props.onButtonClick(model, "assign")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={!!permissions[enums.stdActions.delete]}>
                        <Button
                            text="作废该申请"
                            disabled={!enabled}
                            onClick={() => this.props.onButtonClick(model, "discard")} />
                    </Item>
                </Toolbar>
            </div>
        )
    }
}