import { DataGrid } from "devextreme-react";
import React from "react";
import { StdGrid, createBoolColumn, createGridOptions, remoteReadOnlyGridOptions, setColumnDataSource } from "../../components";
import { IColumnProps } from "devextreme-react/data-grid";
import { cpLogStore, organizationService } from "../../api";
import { OrganizationRef } from "../../models";

interface CpLogState {
    orgs: OrganizationRef[];
    columns: IColumnProps[];
}

export default class CpLogGrid extends React.Component<any, CpLogState> {
    moduleName = "CpLog";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("cpLogs", this.gridRef, remoteReadOnlyGridOptions, (items: any[]) => {
        // state清空会同时清空filter，需要重新绑定旧的
        items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
        return items;
    });

    constructor(props: any) {
        super(props);

        this.state = {
            orgs: [],
            columns: [
                { dataField: "logTime", dataType: "datetime" },
                { dataField: "barcode" },
                { dataField: "code" },
                { dataField: "batchCode" },
                createBoolColumn({ dataField: "hasQR" }),
                { dataField: "operatorName" },
                {
                    dataField: "organizationId",
                    lookup: { dataSource: [], displayExpr: "name", valueExpr: "id" }
                },
                { dataField: "printCount" }
            ]
        };
    }

    componentDidMount(): void {
        this.loadOrgs();
    }

    async loadOrgs() {
        const orgs = await organizationService.getRefs();
        setColumnDataSource(this.state.columns, "organizationId", orgs);
        
        this.setState({
            columns: this.state.columns,
            orgs: orgs
        });
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={cpLogStore}
                options={this.gridOptions}
                columns={this.state.columns}>
            </StdGrid>
        );
    }
}