import "./permission-popup.scss";
import { RadioGroup, ScrollView, TabPanel } from "devextreme-react";
import Popup from "devextreme-react/popup";
import { PopupContentBox } from "../layouts";
import { sysObjectService, roleClaimService } from "../../api";
import { dataSources, enums, ListItem, SysAction, SysObject, alertError, notifySuccess, authorizationService, modelClone } from "../../base";
import React from "react";
import { RefPopup } from "../popup";
import { RoleClaimModel, RoleModel } from "../../models";

interface ObjectGroup {
    groupName: string;
    objects: SysObject[];
}

interface PermissionState {
    defaultObjects: SysObject[];
    objectGroups: ObjectGroup[];
    claims: RoleClaimModel[];
    selectedGroup: number;
    canEdit: boolean;
}

export default class PermissionPopup extends React.Component<any, PermissionState> {
    popupRef = React.createRef<Popup>();
    role: RoleModel = {};

    constructor(props: any) {
        super(props);

        this.state = {
            defaultObjects: [],
            objectGroups: [],
            claims: [],
            selectedGroup: 0,
            canEdit: false
        };

        this.onTabIndexChanged = this.onTabIndexChanged.bind(this);
        this.renderTabContent = this.renderTabContent.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    async componentDidMount() {
        const canEdit = await authorizationService.authorize("RoleClaim", enums.stdActions.update);
        this.setState({ canEdit: canEdit });

        this.loadDefaultObjects();
    }

    async loadDefaultObjects() {
        const data = await sysObjectService.getAll();
        this.setState({
            defaultObjects: data
        });
    }

    show(role: RoleModel) {
        this.popupRef.current?.instance.show();

        this.role = role;
        this.loadRoleClaims();
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async loadRoleClaims() {
        const claims = await roleClaimService.getByRoleId(this.role.id!);
        const objects = this.getObjects(claims);

        this.setState({
            claims: claims,
            objectGroups: objects
        });
    }

    getObjects(claims: RoleClaimModel[]) {
        const objects: SysObject[] = modelClone(this.state.defaultObjects);
        const groups: ObjectGroup[] = [];

        for (const obj of objects) {
            if (obj.orderNumber === -1) {
                continue;
            }

            let group = groups.find(g => g.groupName === obj.groupName);

            if (!group) {
                group = { groupName: obj.groupName || "未分组", objects: [obj] };
                groups.push(group);
            } else {
                group.objects.push(obj);
            }

            obj.sysActions = obj.sysActions.sort((a, b) => (a.orderNumber || 0) - (b.orderNumber || 0));

            for (const action of obj.sysActions) {
                const claim = claims.find(
                    c => c.objectName === obj.objectName && c.actionName === action.actionName);

                if (claim) {
                    action.value = claim.permissionValue;
                } else {
                    action.value = null;
                }
            }
        }

        return groups;
    }

    onTabIndexChanged(index: number) {
        if (index !== this.state.selectedGroup) {
            this.setState({ selectedGroup: index });
        }
    }

    async save() {
        const claims = this.getClaims();

        const result = await roleClaimService.save(this.role.id!, claims);
        if (result.succeeded) {
            notifySuccess('已成功保存角色权限');
            this.hide();
        } else {
            alertError(result.message!);
        }
    }

    getClaims() {
        const { claims, objectGroups } = this.state;

        for (const group of objectGroups) {
            for (const obj of group.objects) {
                for (const action of obj.sysActions) {
                    this.getClaimValue(claims, action, obj);
                }
            }
        }

        return claims;
    }

    getClaimValue(claims: RoleClaimModel[], action: SysAction, obj: SysObject) {
        const claim = claims
            .find(c => c.objectName === obj.objectName && c.actionName === action.actionName);

        if (claim) {
            claim.permissionValue = action.value || undefined;
        } else {
            claims.push({
                objectName: obj.objectName,
                actionName: action.actionName,
                permissionValue: action.value || undefined
            });
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        const { objectGroups, selectedGroup, canEdit } = this.state;

        return (
            <RefPopup
                popupRef={this.popupRef}
                width={800}
                height={600}
                title={`设置权限 - ${this.role?.name}`}>
                <PopupContentBox 
                    onSave={this.save} 
                    onCancel={this.cancel} 
                    scrollable={false} 
                    hideSavebutton={!canEdit} 
                    hideCancelButton={!canEdit}>
                    <TabPanel
                        showNavButtons={true}
                        height={480}
                        dataSource={objectGroups}
                        selectedIndex={selectedGroup}
                        onSelectedIndexChange={this.onTabIndexChanged}
                        itemTitleRender={this.renderTabTitle}
                        itemRender={this.renderTabContent}
                        animationEnabled={true}
                        swipeEnabled={true} />
                </PopupContentBox>
            </RefPopup>
        );
    }

    renderTabTitle(group: ObjectGroup) {
        return (<span>{group.groupName}</span>);
    }

    renderTabContent(group: ObjectGroup) {
        return (
            <ScrollView width={"100%"} scrollByContent={true}>
                <div className={"form permission-form"}>
                    {
                        group.objects.map(o => (
                            <ActionComponent
                                key={o.objectName}
                                obj={o}
                                allObjects={this.state.defaultObjects}
                                role={this.role}
                                canEdit={this.state.canEdit} />
                        ))
                    }
                </div>
            </ScrollView>
        );
    }
}

interface ActionComponentProps {
    obj: SysObject;
    allObjects: SysObject[];
    role: any;
    canEdit: boolean;
}

class ActionComponent extends React.Component<ActionComponentProps, any> {
    constructor(props: Readonly<ActionComponentProps>) {
        super(props);

        this.state = {};

        this.getValues = this.getValues.bind(this);
        this.setMaxValue = this.setMaxValue.bind(this);
        this.setMinValue = this.setMinValue.bind(this);
    }

    render() {
        const { obj, allObjects, role, canEdit} = this.props;

        return (
            <div className={"dx-fieldset"}>
                <div className={"dx-fieldset-header"}>
                    <span className="object-label">{obj.label}</span>
                    <span className="link-button" onClick={() => this.setMinValue(obj)}>无权限</span>
                    <span className="link-button" onClick={() => this.setMaxValue(obj, allObjects, role)}>最大权限</span>
                </div>
                {
                    obj.sysActions.map(a => (
                        <div className={"dx-field"} key={a.actionName}>
                            <div className={"dx-field-label"}>{a.label}</div>
                            <div className={"dx-field-value"}>
                                <RadioGroup 
                                    readOnly={!canEdit}
                                    items={this.getValues(obj, a, allObjects, role)}
                                    displayExpr={"text"}
                                    valueExpr={"value"}
                                    value={a.value}
                                    layout="horizontal"
                                    onValueChanged={e => this.onValueChanged(e.value, a)} />
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }

    getValues(obj: SysObject, action: SysAction, allObjects: SysObject[], role: any) {
        const type = action.permissionType || obj.permissionType;

        switch (type) {
            case enums.permissionTypes.main:
                return permissionValues.filter(v => (v.value ?? 0) <= role.permissionValue);
            case enums.permissionTypes.create:
                return createPermissionValues;
            case enums.permissionTypes.affiliate:
                const mainObj = allObjects.find(o => o.objectName === obj.dependency);
                const item = dataSources.affiliatePermissionValues[0];
                return [
                    nonePermission,
                    { value: item.value, text: item.text.replace("{0}", mainObj?.label || "") }
                ];
            default:
                return openPermissionValues;
        }
    }

    onValueChanged(value: any, action: SysAction) {
        action.value = value;
        this.setState({});
    }

    setMinValue(obj: SysObject) {
        obj.sysActions.forEach(a => a.value = null)
        this.setState({});
    }

    setMaxValue(obj: SysObject, allObjects: SysObject[], role: any) {
        for (const action of obj.sysActions) {
            const values = this.getValues(obj, action, allObjects, role);
            action.value = Math.max(...values.map(v => v.value ?? 0));
        }

        this.setState({});
    }
}


const nonePermission = { value: null, text: '无权限' };

const permissionValues: ListItem<number | null>[] = [
    nonePermission,
    ...dataSources.permissionValues
];

const openPermissionValues: ListItem<number | null>[] = [
    nonePermission,
    ...dataSources.openPermissionValues
];

const createPermissionValues: ListItem<number | null>[] = [
    nonePermission,
    ...dataSources.createPermissionValues
]