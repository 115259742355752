import { createStoreFromUrl, BaseApiService } from "../base";
import { FillingSettingModel } from "../models";

class FillingSettingService extends BaseApiService<FillingSettingModel> {
    moduleName = "fillingsetting";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }
}

export const fillingSettingService = new FillingSettingService();
export const fillingSettingStore = fillingSettingService.getDxData();