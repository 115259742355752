import React from "react";
import CustomerComplainGrid from "./customer-complain-grid";
import CustomerComplainForm from "./customer-complain-form";
import AuthorizedComponent from "../authorized-component";
import { ContentBlock } from "../../components";
import { customerComplainService } from "../../api";
import { alertError, confirm2, notifySuccess, enums } from "../../base";
import { CustomerComplainModel } from "../../models";
import CustomerComplainToolbar from "./customer-complain-toolbar";

export default class CustomerComplainPage extends AuthorizedComponent {
    objectName = "CustomerComplain";
    gridRef = React.createRef<CustomerComplainGrid>();
    formRef = React.createRef<CustomerComplainForm>();
    toolbarRef = React.createRef<CustomerComplainToolbar>();

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    onModelSelected(model: CustomerComplainModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onButtonClick(model: any, name: string) {
        const grid = this.gridRef.current?.gridRef.current?.instance;
        
        if (grid) {
            switch (name) {
                case "add":
                    if (this.permissions[enums.stdActions.create]) {
                        this.formRef.current?.show({ customer: {} });
                    }
                    break;

                case "edit":
                    if (this.permissions[enums.stdActions.update]) {
                        this.formRef.current?.show(model);
                    }
                    break;
                
                case "delete":
                    if (this.permissions[enums.stdActions.delete]) {
                        this.delete(model.id);
                    }
                    break;
            }
        }
    }
    
    async delete(id: number) {
        if (!await confirm2("确定要删除该数据吗？")) {
            return;
        }

        const res = await customerComplainService.delete(id);
        if (res.succeeded) {
            this.gridRef.current?.refresh();
            notifySuccess("已成功删除数据");
        } else {
            alertError(res.message!);
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    onFiltering(filterValue: any) {
        this.gridRef.current?.filter(filterValue);
    }

    render() {
        return (
            <ContentBlock caption={"客户投诉"}>
                <CustomerComplainToolbar 
                    permissions={this.permissions}
                    ref={this.toolbarRef} 
                    onButtonClick={this.onButtonClick} 
                    onFiltering={this.onFiltering} />
                <CustomerComplainGrid
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
                <CustomerComplainForm ref={this.formRef} onSaved={this.onSaved} />
            </ContentBlock>
        )
    }
}