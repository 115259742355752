import { Form, Popup } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import React from "react";
import { fillingSettingService } from "../../api";
import { alertError, modelClone } from "../../base";
import { PopupContentBox, RefPopup, StdForm } from "../../components";
import { FillingSettingModel } from "../../models";

export interface FillingSettingFormProps {
    onSaved: () => void;
}

interface FillingSettingFormState {
    model: FillingSettingModel;
}

export default class FillingSettingForm 
    extends React.Component<FillingSettingFormProps, FillingSettingFormState> {
    moduleName = "FillingSetting";

    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();

    items: IItemProps[] = [
        { dataField: 'settingDate', editorType: "dxDateBox" },
        { dataField: 'prechecker' },
        { dataField: 'rechecker' }
    ]

    constructor(props: Readonly<FillingSettingFormProps>) {
        super(props);

        this.state = {
            model: {}
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    show(model: FillingSettingModel) {
        this.popupRef.current?.instance.show();

        this.setState({ model: modelClone(model) });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const model = this.state.model;
            const res = model.id ?
                await fillingSettingService.update(model) :
                await fillingSettingService.create(model);

            if (res.succeeded) {
                this.hide();
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup 
                popupRef={this.popupRef}
                title={"编辑"}
                width={400}
                height={280}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm 
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={1}
                        items={this.items}
                        formData={this.state.model} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}