import { Button, DateBox, SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import React from "react";
import { employeeService, organizationService } from "../../api";
import { OrgTreeDropDown } from "../../components";
import { EmployeeRef, OrganizationRef } from "../../models";
import { dateAdd, today } from "../../base";

export interface OperationLogToolbarProps {
    onFiltering: (filterValue: any, orgainzationId?: number) => void;
}

interface OperationLogToolbarState {
    orgs: OrganizationRef[];
    allEmployees: EmployeeRef[];
    employees: EmployeeRef[];
    organizationId?: number;
    employeeId?: string;
    startTime?: Date | string;
    endTime?: Date | string;
}

export default class OperationLogToolbar extends
    React.Component<OperationLogToolbarProps, OperationLogToolbarState> {

    constructor(props: Readonly<OperationLogToolbarProps>) {
        super(props);

        const day = today();

        this.state = {
            orgs: [],
            allEmployees: [],
            employees: [],
            startTime: dateAdd(day, -7),
            endTime: day
        };

        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onEmployeeChanged = this.onEmployeeChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.onStartTimeChanged = this.onStartTimeChanged.bind(this);
        this.onEndTimeChanged = this.onEndTimeChanged.bind(this);
    }

    componentDidMount() {
        this.loadData();

        setTimeout(this.onFiltering, 100);
    }

    async loadData() {
        let orgs: OrganizationRef[] = [], employees: EmployeeRef[] = [];

        await Promise.all([
            (async () => orgs = await organizationService.getRefs())(),
            (async () => employees = await employeeService.getRefs())()
        ]);

        this.setState({
            orgs: orgs,
            allEmployees: employees,
            employees: employees
        });
    }

    onOrganizationChanged(organizationId?: number) {
        const employees = organizationId ?
            this.state.allEmployees.filter(e => e.organizationId === organizationId) :
            this.state.allEmployees;

        const employeeId = employees.find(e => e.id === this.state.employeeId) ?
            this.state.employeeId : undefined;

        this.setState({
            organizationId: organizationId,
            employees: employees,
            employeeId: employeeId,
        });
    }

    onEmployeeChanged(e: ValueChangedEvent) {
        this.setState({ employeeId: e.value });
    }

    onStartTimeChanged(e: DateChangedEvent) {
        this.setState({ startTime: e.value });
    }

    onEndTimeChanged(e: DateChangedEvent) {
        this.setState({ endTime: e.value });
    }

    onFiltering() {
        this.props.onFiltering(this.getFilterValue(), this.state.organizationId);
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { employeeId, startTime, endTime } = this.state;

        if (employeeId) {
            filters.push(["operatorId", "=", employeeId]);
        }

        if (startTime) {
            filters.push(["logTime", ">=", startTime]);
        }

        if (endTime) {
            filters.push(["logTime", "<", dateAdd(new Date(endTime), 1)])
        }

        return filters.length > 0 ? filters : null;
    }

    render() {
        const { orgs, employees, organizationId, employeeId, startTime, endTime } = this.state;

        return (
            <div className="page-toolbar">
                <Toolbar>
                <Item location={"before"} locateInMenu={"auto"}>
                        <DateBox
                            value={startTime}
                            placeholder="开始时间"
                            type="datetime"
                            showClearButton={true}
                            onValueChanged={this.onStartTimeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endTime}
                            placeholder="结束时间"
                            type="datetime"
                            showClearButton={true}
                            onValueChanged={this.onEndTimeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="选择供应站过滤"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={employeeId}
                            dataSource={employees}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={160}
                            placeholder="员工过滤"
                            onValueChanged={this.onEmployeeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <Button text="查询" onClick={this.onFiltering} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}