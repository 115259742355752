import React from "react";
import { Button, SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { DataMap, enums } from "../../base";
import { EmployeeModel, OrganizationRef, RoleRef } from "../../models";
import { OrgTreeDropDown } from "../../components";
import { organizationService, roleService } from "../../api";
import { ValueChangedEvent } from "devextreme/ui/select_box";

export interface EmployeeToolbarProps {
    permissions?: DataMap<boolean>;
    onButtonClick: (model: EmployeeModel, name: string) => void;
    onContextButtonClick: (models: EmployeeModel[], name: string) => void;
    onFiltering: (condition: any) => void;
    onRoleChanged: (roleId?: number) => void;
}

interface EmployeeToolbarState {
    model: EmployeeModel;
    models: EmployeeModel[];
    orgs: OrganizationRef[];
    roles: RoleRef[];
    organizationId?: number;
    roleId?: number;
}

export default class EmployeeToolbar extends React.Component<EmployeeToolbarProps, EmployeeToolbarState> {
    constructor(props: Readonly<EmployeeToolbarProps>) {
        super(props);

        this.state = {
            model: {},
            models: [],
            orgs: [],
            roles: []
        };

        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onRoleChanged = this.onRoleChanged.bind(this);
    }

    componentDidMount() {
        this.loadOrgs();
        this.loadRoles();
    }

    async loadOrgs() {
        const orgs = await organizationService.getRefs();
        this.setState({ orgs: orgs });
    }

    async loadRoles() {
        const roles = await roleService.getRefs();
        this.setState({ roles: roles });
    }

    setModels(models: EmployeeModel[]) {
        const model = models.length === 1 ? models[0] : {};

        this.setState({ model: model, models: models });
    }

    onOrganizationChanged(organizationId?: number) {
        this.setState({ organizationId: organizationId });
        this.props.onFiltering(organizationId ? ["organizationId", "=", organizationId] : null);
    }

    onRoleChanged(e: ValueChangedEvent) {
        this.setState({ roleId: e.value });
        this.props.onRoleChanged(e.value);
    }

    render() {
        const { model, models, organizationId, orgs, roles, roleId } = this.state;
        const permissions = this.props.permissions || {};

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu={true} visible={orgs.length > 1}>
                        <OrgTreeDropDown 
                            organizationId={organizationId} 
                            organizations={orgs} 
                            placeholder="选择供应站过滤"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={true}>
                        <SelectBox
                            value={roleId}
                            dataSource={roles}
                            displayExpr="name"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={130}
                            placeholder="选择角色过滤"
                            onValueChanged={this.onRoleChanged} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions[enums.stdActions.create]}>
                        <Button
                            text="新增"
                            onClick={() => this.props.onButtonClick({}, "add")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.update]}>
                        <Button
                            disabled={!model?.id}
                            text="编辑"
                            onClick={() => this.props.onButtonClick(model, "edit")} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions["EditShare"]}>
                        <Button
                            disabled={!model?.id}
                            text="员工共享"
                            onClick={() => this.props.onButtonClick(model, "share")} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions["EditShare"]}>
                        <Button
                            disabled={models.length < 2}
                            text="选中员工数据互相共享"
                            onClick={() => this.props.onContextButtonClick(models, "mutualshare")} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions["SetPassword"]}>
                        <Button
                            disabled={!model?.id}
                            text="设置密码"
                            onClick={() => this.props.onButtonClick(model, "password")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions["Move"]}>
                        <Button
                            disabled={models.length === 0}
                            text="迁移"
                            onClick={() => this.props.onContextButtonClick(models, "move")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions["TransferCustomers"]}>
                        <Button
                            disabled={!model?.id}
                            text="转移客户"
                            onClick={() => this.props.onButtonClick(model, "transfercustomers")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.delete]}>
                        <Button
                            disabled={!model?.id}
                            text="删除"
                            onClick={() => this.props.onButtonClick(model, "delete")} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}