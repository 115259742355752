import { Button, DateBox, SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import React from "react";
import { dateAdd, ListItem } from "../../base";
import { AnnouncementEmployeeModel } from "../../models";

export interface MyAnnouncementToolbarProps {
    onButtonClick: (model: AnnouncementEmployeeModel, name: string) => void;
    onFiltering: (filterValue: any, showAll: boolean) => void;
}

interface MyAnnouncementToolbarState {
    model: AnnouncementEmployeeModel;
    startDate?: Date | string;
    endDate?: Date | string;
    showAll: boolean;
}

export default class MyAnnouncementToolbar
    extends React.Component<MyAnnouncementToolbarProps, MyAnnouncementToolbarState> {
    constructor(props: Readonly<MyAnnouncementToolbarProps>) {
        super(props);

        this.state = {
            model: {},
            showAll: false
        };

        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    setModel(model: AnnouncementEmployeeModel) {
        this.setState({ model: model });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onStatusChanged(e: ValueChangedEvent) {
        this.setState({ showAll: e.value });
    }

    onFiltering() {
        const { startDate, endDate, showAll } = this.state;
        const filters: any[] = [];

        if (startDate) {
            filters.push(["announcement.createdAt", ">=", startDate]);
        }

        if (endDate) {
            filters.push(["announcement.createdAt", "<", dateAdd(new Date(endDate), 1)]);
        }

        this.props.onFiltering(filters.length > 0 ? filters : null, showAll);
    }

    render() {
        const { model, startDate, endDate, showAll } = this.state;

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={showAll}
                            dataSource={statusSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={120}
                            onValueChanged={this.onStatusChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={startDate}
                            placeholder="开始时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"}>
                        <Button
                            text="查看"
                            disabled={!model?.announcementId}
                            onClick={() => this.props.onButtonClick(model, "view")} />
                    </Item>
                </Toolbar>
            </div>
        )
    }
}

const statusSource: ListItem<boolean>[] = [
    { value: false, text: "查看未读" },
    { value: true, text: "查看所有" }
]