import { Popup } from "devextreme-react";
import Form, { IItemProps } from "devextreme-react/form";
import React from "react";
import { fillingCheckTypeService } from "../../api";
import { alertError, dataSources, enums, modelClone, notifySuccess } from "../../base";
import { PopupContentBox, RefPopup, setItemDataSource, StdForm } from "../../components";
import { FillingCheckTypeModel } from "../../models";

export interface FillingCheckTypeFormProps {
    onSaved: () => void;
}

interface FillingCheckTypeFormState {
    model: FillingCheckTypeModel;
    items: IItemProps[];
}

export default class FillingCheckTypeForm 
    extends React.Component<FillingCheckTypeFormProps, FillingCheckTypeFormState> {
    moduleName = "FillingCheckType";
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    
    constructor(props: Readonly<FillingCheckTypeFormProps>) {
        super(props);

        this.state = {
            model: {},
            items: [
                {
                    dataField: 'checkType',
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: dataSources.fillingCheckTypes, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: 'sortNumber' },
                { dataField: 'itemName' },
                { dataField: 'syncFieldName' },
                { 
                    dataField: 'displayType',
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: dataSources.fillingCheckTypeValues, displayExpr: 'text', valueExpr: 'value' }
                },
                {
                    dataField: 'defaultValue',
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSources: [], displayExpr: 'text', valueExpr: 'value' }
                }
            ]
        };

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    show(model: FillingCheckTypeModel) {
        this.popupRef.current?.instance.show();

        const defaultValues = model.displayType === enums.fillingCheckTypeValues.trueFalse ?
            dataSources.trueFalseOfPass : dataSources.qualifiedOrNot;

        setItemDataSource(
            this.state.items, 
            "defaultValue", 
            [{ value: null, text: '' }, ...defaultValues])

        this.setState({ model: modelClone(model) })
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const model = this.state.model;
            const res = model.id ?
                await fillingCheckTypeService.update(model) :
                await fillingCheckTypeService.create(model);

            if (res.succeeded) {
                this.hide();
                notifySuccess("已成功保存");
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title="编辑"
                width={400}
                height={420}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        items={this.state.items}
                        formData={this.state.model} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}