import React from "react";
import { Popup } from "devextreme-react";
import { RefPopup } from "../popup";
import { baseApiUrl } from "../../base";

interface BarcodePopupState {
    barCode?: string;
}

export default class BarcodePopup extends React.Component<any, BarcodePopupState> {
    popupRef = React.createRef<Popup>();
    url = `${baseApiUrl}/api/file/barcode`;

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            barCode: undefined
        };

        this.hide = this.hide.bind(this);
    }

    show(barCode: string) {
        this.popupRef.current?.instance.show();

        this.setState({ barCode: barCode });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    render() {
        const { barCode } = this.state;

        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"条形码"}
                width={500}
                height={200}
                showCloseButton={false}
                baseOptions={{ closeOnOutsideClick: true }}>
                <div className={"flex-box"}>
                    {barCode && (
                        <img src={`${this.url}/${barCode}`} alt="" />
                    )} 
                </div>
            </RefPopup>
        );
    }
}