import React from "react";
import SecurityCheckGrid from "./security-check-grid";
import SecurityCheckDetail from "./security-check-detail";
import SecurityCheckToolbar from "./security-check-toolbar";
import AuthorizedComponent from "../authorized-component";
import { alertError, confirm2, notifySuccess, enums, globalPopupManager, globalPopupNames, baseApiUrl, download } from "../../base";
import { securityCheckService } from "../../api";
import { ContentBlock, DangerAuditPopup } from "../../components";
import { SecurityCheckAuditModel, SecurityCheckModel } from "../../models";

export default class SecurityCheckPage extends AuthorizedComponent {
    objectName = "SecurityCheck";
    gridRef = React.createRef<SecurityCheckGrid>();
    detailRef = React.createRef<SecurityCheckDetail>();
    toolbarRef = React.createRef<SecurityCheckToolbar>();
    auditRef = React.createRef<DangerAuditPopup>();

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onRowChecked = this.onRowChecked.bind(this);
        this.filter = this.filter.bind(this);
        this.onSaved = this.onSaved.bind(this); 
    }

    get actionNames(): string[] {
        return [
            enums.stdActions.create,
            enums.stdActions.delete,
            "Archive",
            "Audit"
        ]
    }

    onModelSelected(model: SecurityCheckModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onRowChecked(ids: number[]) {
        this.toolbarRef.current?.setIds(ids);
    }

    async onButtonClick(model: SecurityCheckModel, name: string) {
        switch (name) {
            case "callcenter":
                globalPopupManager.show(globalPopupNames.callCenter, { id: model.customerId });
                break;

            case "detail":
                this.detailRef.current?.show(model);
                break;

            case "audit":
                if (this.permissions["Audit"]) {
                    this.showAuditPopup(model);
                }
                break;

            case "archive":
                if (this.permissions["Archive"]) {
                    this.archive(model);
                }
                break;

            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(model);
                }
                break;

            case "restore":
                if (this.permissions[enums.stdActions.delete]) {
                    this.restore(model);
                }
                break;    
        }
    }

    async onContextButtonClick(ids: number[], name: string) {
        switch (name) {
            case "export":
                if (!await confirm2("确定导出选中的安全检查吗？")) {
                    return;
                }

                const url = `${baseApiUrl}${securityCheckService.apiPath}/exportform?ids=${ids.join(',')}`;
                download(url, `安全检查表.xls`);
                break;
        }
    }

    showAuditPopup(model: SecurityCheckModel) {
        this.auditRef.current?.show(model.id!);
    }

    async audit(model: SecurityCheckAuditModel) {
        return securityCheckService.audit(model);
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    async archive(model: SecurityCheckModel) {
        if (!await confirm2("确定要归档此记录吗？")) {
            return;
        }

        const result = await securityCheckService.archive(model.id!);

        if (result.succeeded) {
            notifySuccess("已成功归档记录");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async delete(model: SecurityCheckModel) {
        if (!await confirm2("确定要作废此记录吗？")) {
            return;
        }

        const result = await securityCheckService.delete(model.id!);

        if (result.succeeded) {
            notifySuccess("已成功作废记录");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async restore(model: SecurityCheckModel) {
        if (!await confirm2("确定要还原该条数据吗？")) {
            return;
        }

        const result = await securityCheckService.restore(model.id!);

        if (result.succeeded) {
            notifySuccess("已成功还原数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    filter(filterValue: any, showDeleted: boolean) {
        this.gridRef.current?.filter(filterValue, showDeleted);
    }

    render() {
        return (
            <ContentBlock caption={"安全检查"}>
                <SecurityCheckToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onFiltering={this.filter}
                    onButtonClick={this.onButtonClick}
                    onContextButtonClick={this.onContextButtonClick} />
                <SecurityCheckGrid 
                    ref={this.gridRef} 
                    permissions={this.permissions}
                    onRowChecked={this.onRowChecked}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
                <SecurityCheckDetail ref={this.detailRef} />
                <DangerAuditPopup 
                    ref={this.auditRef} 
                    title="安全检查审核" 
                    saveFunc={this.audit} 
                    onSaved={this.onSaved} />
            </ContentBlock>
        );
    }
}