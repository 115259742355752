import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { ExportingEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import { authorizationService, baseApiUrl, dataSources, enums, globalPopupManager, globalPopupNames, ModuleConfig, StringMap } from "../../base";
import { createGridOptions, remoteReadOnlyGridOptions, setColumnDataSource, createImageColumn, StdGrid, ExportModel } from "../../components";
import { troubleshootingStore, dataSourceService, customerTypeService, troubleshootingService, troubleshootingDeletedStore } from "../../api";
import { CustomerTypeRef, TroubleshootingModel } from "../../models";
import CustomStore from "devextreme/data/custom_store";

export interface TroubleshootingGridProps {
    onModelSelected: (model: TroubleshootingModel) => void;
    onButtonClick: (model: TroubleshootingModel, name: string) => void;
}

interface TroubleshootingGridState {
    customerTypes: CustomerTypeRef[];
    columns: IColumnProps[];
    store: CustomStore;
}

export default class TroubleshootingGrid extends 
    React.Component<TroubleshootingGridProps, TroubleshootingGridState> {
    objectName = "Troubleshooting";
    moduleName: ModuleConfig = {
        moduleName: "Troubleshooting",
        children: {
            customer: 'Customer',
            handler: 'Employee',
            organization: 'Organization'
        }
    }

    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("troubleshootings", this.gridRef, remoteReadOnlyGridOptions);
    nameMap: StringMap = { 
        "customer.name": "customerId",
        "organization.name": "organizationId",
        "handler.name": "handlerId"
    };

    constructor(props: Readonly<TroubleshootingGridProps>) {
        super(props);

        this.state = {
            customerTypes: [],
            store: troubleshootingStore,
            columns: [
                {
                    dataField: 'customer.name',
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.name}
                        </span>
                    )
                },
                {
                    dataField: 'customer.gasCode',
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.gasCode}
                        </span>
                    )
                },
                {
                    dataField: 'customer.typeId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: "reportTime", dataType: "datetime" },
                { 
                    dataField: "type",
                    lookup: { dataSource: [] }
                },
                { dataField: "reason" },
                { 
                    dataField: "status",
                    lookup: { dataSource: dataSources.troubleshootingStatus, displayExpr: 'text', valueExpr: 'value' }
                },
                { 
                    dataField: "organization.name",
                    allowFiltering: false
                },
                { 
                    dataField: "handler.name",
                    calculateFilterExpression: function(filterValue: any, op: string) {
                        return [
                            ["handler.name", op, filterValue],
                            "or",
                            ["handler.code", op, filterValue]
                        ]
                    },
                    cellRender: (e: any) => (<span>{e.data.handler?.nameAndCode}</span>)
                },
                createImageColumn({ dataField: 'photo' }),
                { dataField: "handling", lookup: { dataSource: [] } },
                { dataField: "result", lookup: { dataSource: [] }},
                { dataField: "serviceFee" },
                { dataField: "accessoryFee" },
                createImageColumn({ dataField: "fixedPhoto" }),
                { dataField: "handleTime", dataType: "datetime" }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "assign");
        this.gridOptions.onExporting = this.onExporting.bind(this);
        this.gridOptions.export!.allowExportSelectedData = false;
        this.gridOptions.export!.enabled = false;
    }

    componentDidMount() {
        this.authorizeImport();
        this.loadData();
    }
    
    async authorizeImport() {
        if (await authorizationService.authorize(this.objectName, enums.stdActions.export)) {
            this.gridOptions.export!.enabled = true;
            this.gridRef.current?.instance.option("export.enabled", true);
        }
    }

    async loadData() {
        let customerTypes: CustomerTypeRef[] = [], 
            types: string[] = [],
            handlings: string[] = [],
            results: string[] = [];

        await Promise.all([
            (async () => customerTypes = await customerTypeService.getRefs())(),
            (async () => types = await dataSourceService.getDataSource(enums.dataSourceTypes.troubleTypes))(),
            (async () => handlings = await dataSourceService.getDataSource(enums.dataSourceTypes.troubleHandlings))(),
            (async () => results = await dataSourceService.getDataSource(enums.dataSourceTypes.troubleResults))()
        ]);

        setColumnDataSource(this.state.columns, "customer.typeId", customerTypes);
        setColumnDataSource(this.state.columns, "type", types);
        setColumnDataSource(this.state.columns, "handling", handlings);
        setColumnDataSource(this.state.columns, "result", results);

        this.setState({
            customerTypes: customerTypes
        });
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});    // 为避免混乱，多选不能编辑
        }
    }

    onExporting(e: ExportingEvent) {
        const args: ExportModel = {
            moduleName: this.moduleName.moduleName,
            exportUrl: `${baseApiUrl}${troubleshootingService.apiPath}/export`,
            columns: this.state.columns,
            keys: e.component.getSelectedRowKeys(),
            filter: e.component.getCombinedFilter(),
            showDeleted: this.state.store === troubleshootingDeletedStore
        };

        e.cancel = true;
        globalPopupManager.show(globalPopupNames.exportPopup, args);
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    filter(filterValue: any, showDeleted: boolean) {
        if (showDeleted && this.state.store !== troubleshootingDeletedStore) {
            this.setState({ store: troubleshootingDeletedStore }, () => this._filter(filterValue));
        } else if (!showDeleted && this.state.store !== troubleshootingStore) {
            this.setState({ store: troubleshootingStore }, () => this._filter(filterValue))
        } else {
            this._filter(filterValue);
        }
    }

    _filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns}
                columnNameMap={this.nameMap}
                toolbarHeight={80}>
            </StdGrid>
        );
    }
}