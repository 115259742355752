/**
 * 格式化日期
 */
export function fdate(value: Date | string, dateOnly?: boolean): string {
    if (!value) { return ''; }
    if (typeof (value) === 'string') { 
        const noYear = value.match(/^\s*\d+[.\-/]\d+\s*$/);
        value = new Date(value); 
        if (noYear) { value.setFullYear(new Date().getFullYear()); }
    }

    const year = value.getFullYear(),
        month = value.getMonth() + 1,
        day = value.getDate(),
        hours = value.getHours(),
        minutes = value.getMinutes(),
        seconds = value.getSeconds();

    let str = `${year}-${month >= 10 ? month : '0' + month}-${day >= 10 ? day : '0' + day}`;
    
    if (!dateOnly) {
        str += ` ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    }

    return str;
}

export function fdatePicker(value: Date | string): string {
    if (!value) { return ''; }
    if (typeof (value) === 'string') { 
        const noYear = value.match(/^\s*\d+[.\-/]\d+\s*(\d+:\d+)?$/);
        value = new Date(value); 
        if (noYear) { value.setFullYear(new Date().getFullYear()); }
    }
    if (isNaN(value as any)) { return ''; }

    const year = value.getFullYear(),
        month = value.getMonth() + 1,
        day = value.getDate();

    const str = `${year}-${month >= 10 ? month : '0' + month}-${day >= 10 ? day : '0' + day}`;
    return str;
}

export function ftimePicker(value: Date | string): string {
    if (!value) { return ''; }
    if (typeof (value) === 'string') { value = new Date(value); }
    if (isNaN(value as any)) { return ''; }

    const hours = value.getHours(),
        minutes = value.getMinutes();

    const str = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    return str;
}

export function yearMonth(value: Date | string): number {
    var date = new Date(value), 
        year = date.getFullYear(), 
        month = date.getMonth() + 1;

    return year * 100 + month;
}

export function fmonth(value?: number): string {
    return value ? `${Math.round(value / 100)}年${value % 100}月` : "";
}

/**
* 格式化布尔值
*/
export function fbool(value?: boolean): string {
    return value ? "是" : "否";
}

/**
* 创建枚举格式化函数
*/
export function fenum(source: { value: number | string, text: string }[])
    : (value?: string | number) => string | undefined {
    return function (value?: string | number) {
        return source.find(i => i.value === value)?.text;
    }
}

/**
 * 自动根据类型格式化
 */
export function fauto(value?: any) {
    if (typeof(value) === "boolean") {
        return fbool(value);
    } else if (typeof(value) === "number") {
        return value;
    } else if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/g.test(value)) {
        return fdate(value);
    } else {
        return value;
    }
}

/**
 * 模型复制（无法正确复制函数）
 */
export function modelClone(data: any): any {
    return JSON.parse(JSON.stringify(data));
}

/**
 * 深层次复制（可正确处理函数）
 */
export function deepClone(data: any): any {
    if (!data) { 
        return data; 
    } 
    else if (data instanceof Array) {
        const copyArr = [];
        for (const item of data) {
            copyArr.push(deepClone(item));
        }
        return copyArr;
    }
    else if (typeof(data) === "object") {
        const copyObj: any = {};
        for (const key in data) {
            copyObj[key] = deepClone(data[key]);
        }
        return copyObj;
    } else {
        return data;
    }
}

/**
 * 和Object.assign不同的是，该方法会清空原对象的所有值再复制
 */
export function setModelTo(target: any, ...sources: any[]) {
    if (!target) {
        target = {};
    } else {
        for (const key in target) { delete target[key]; }
    }

    Object.assign(target, ...sources)
}

/**
 * 将值合并到目标，如目标中已有值，则放弃合并
 */
export function mergeModel(target: any, ...sources: any[]) {
    target = target || {};
    const valueBackup: any = {};

    for (const key in target) {
        if (target[key] !== undefined && target[key] !== null) {
            valueBackup[key] = target[key];
        }
    }
    
    Object.assign(target, ...sources, valueBackup);
}

export function isPorcelainCode(code: string) {
    return !!(code && code.match(/^01\d{8}$/));
}

export function toShortCode(code: string) {
    return code && code.length <= 6 ? code : code.substring(code.length - 6);
}

export function parseQueryString(queryString: string) {
    const items = (queryString.replace(/^\?/, "") || '').split('&');
    const result: any = {};

    for (const item of items) {
        const pair = item.split('=');
        result[pair[0]] = pair[1];
    }

    return result;
}