import { MessageBool, createStoreFromUrl, http } from "../base";
import { CpLogModel } from "../models";

class CpLogService {
    apiPath = "/api/cplog";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, "id");
    }

    batchCreate(models: CpLogModel[]) {
        return http.postData<MessageBool>(
            `${this.apiPath}?clientNow=${new Date().toISOString()}`, models);
    }
}

export const cpLogService = new CpLogService();
export const cpLogStore = cpLogService.getDxData();