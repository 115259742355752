import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/tree-list";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import React from "react";
import { areaService, customerTypeService } from "../../api";
import { recycleRequestStore } from "../../api/recycle-request.service";
import { dataSources, ModuleConfig, StringMap } from "../../base";
import { createGridOptions, remoteReadOnlyGridOptions, setColumnDataSource, StdGrid } from "../../components";
import { AreaRef, RecycleRequestModel, CustomerTypeRef } from "../../models";

export interface RecycleRequestGridProps {
    onButtonClick: (model: RecycleRequestModel, name: string) => void;
    onModelSelected: (model: RecycleRequestModel) => void;
}

interface RecycleRequestGridState {
    columns: IColumnProps[];
    counties: AreaRef[];
    streets: AreaRef[];
    customerTypes: CustomerTypeRef[];
}

export default class RecycleRequestGrid
    extends React.Component<RecycleRequestGridProps, RecycleRequestGridState> {
    moduleName: ModuleConfig = {
        moduleName: "RecycleRequest",
        children: {
            "customer": {
                moduleName: "Customer",
                children: {
                    "organization": "Organization"
                }
            },
            "deliver": "Employee"
        }
    };

    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("recycleRequests", this.gridRef, remoteReadOnlyGridOptions,
        (items: any[]) => {
            // state清空会同时清空filter，需要重新绑定旧的
            items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
            return items;
        });

    nameMap: StringMap = {
        "customer.organization.name": "customer.organizationId",
        "customer.name": "customerId",
        "deliver.nameAndCode": "deliverId"
    };

    constructor(props: Readonly<RecycleRequestGridProps>) {
        super(props);

        this.state = {
            counties: [],
            streets: [],
            customerTypes: [],
            columns: [
                { 
                    dataField: 'source',
                    lookup: { dataSource: dataSources.platforms, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: 'customer.organization.name' },
                {
                    dataField: 'customer.name',
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.name}
                        </span>
                    )
                },
                {
                    dataField: 'customer.gasCode',
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.gasCode}
                        </span>
                    )
                },
                {
                    dataField: 'customer.typeId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: 'customer.phoneNumber' },
                {
                    dataField: 'customer.areaId',
                    allowFiltering: false,
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                {
                    dataField: 'customer.streetId',
                    allowFiltering: false,
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: 'customer.address' },
                { dataField: 'createdAt', dataType: 'datetime' },
                { 
                    dataField: 'status',
                    lookup: { dataSource: dataSources.recycleRequestStatus, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: 'deliver.nameAndCode' },
                { dataField: 'assignedAt', dataType: 'datetime' },
                { dataField: 'completedAt', dataType: 'datetime' }
            ]
        }

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = (e) => this.props.onButtonClick(e.data, "assign");
    }

    componentDidMount() {
        this.loadDataSources();
    }

    async loadDataSources() {
        let counties: AreaRef[] = [], streets: AreaRef[] = [], customerTypes: CustomerTypeRef[] = [];

        await Promise.all([
            (async () => counties = await areaService.getCounties())(),
            (async () => streets = await areaService.getStreets())(),
            (async () => customerTypes = await customerTypeService.getRefs())()
        ]);

        setColumnDataSource(this.state.columns, "customer.areaId", counties);
        setColumnDataSource(this.state.columns, "customer.streetId", streets);
        setColumnDataSource(this.state.columns, "customer.typeId", customerTypes);

        this.setState({
            counties: counties,
            streets: streets,
            customerTypes: customerTypes
        });
    }
    
    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});    // 为避免混乱，多选不能编辑
        }
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
        const model = this.gridRef.current?.instance.getSelectedRowsData()[0];
        if (model) {
            this.props.onModelSelected(model);
        }
    }

    filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={recycleRequestStore}
                options={this.gridOptions}
                columns={this.state.columns}
                columnNameMap={this.nameMap}>
            </StdGrid>
        )
    }
}