import { Chart, Popup } from "devextreme-react";
import { CommonSeriesSettings, Export, Grid, Label, Legend, Margin, Series, Tooltip, ValueAxis } from "devextreme-react/chart";
import React from "react";
import { RefPopup } from "../../../components";
import { CustomerTrendReport } from "../../../models";

interface CustomerTrendChartState {
    data: CustomerTrendReport[];
}

export default class CustomerTrendChart
    extends React.Component<any, CustomerTrendChartState> {

    popupRef = React.createRef<Popup>();

    constructor(props: any) {
        super(props);

        this.state = { data: [] };
    }

    show(data: CustomerTrendReport[]) {
        this.popupRef.current?.instance.show();

        this.setState({ data: data });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                width={"90%"}
                height={600}
                title={"客户增长/衰减统计报表"}>
                <div className={"popup-content-box"}>
                    <Chart
                        palette={"Violet"}
                        dataSource={this.state.data}
                        width={"100%"}
                        height={500}
                        title={"客户增长/衰减统计报表"}>
                        <CommonSeriesSettings argumentField={"time"} type="spline" />
                        <ValueAxis>
                            <Label format={"decimal"} />
                            <Grid visible={true} />
                        </ValueAxis>
                        <Series key={"newCount"} valueField="newCount" name="开户" color={"#009688"} />
                        <Series key={"exitCount"} valueField="exitCount" name="退户" color={"#ed9c28"} />
                        <Series key={"increase"} valueField="increase" name="增减" color={"#515159"} />
                        <Margin bottom={20} />
                        <Legend verticalAlignment="bottom" horizontalAlignment="center" />
                        <Export enabled={true} />
                        <Tooltip enabled={true} />
                    </Chart>
                </div>
            </RefPopup>
        )
    }
}