import { RouteConfig } from "./route-model";
import AnnouncementPage from "../pages/announcement/annoucement";
import SmsPage from "../pages/sms/sms";
import WebMessagePage from "../pages/web-message/web-message";
import MyAnnouncementPage from "../pages/announcement/my-announcement";

export const announcementRoutes: RouteConfig[] = [
    {
        path: '/announcement',
        component: AnnouncementPage
    },
    {
        path: '/sms',
        component: SmsPage
    },
    {
        path: '/web-message',
        component: WebMessagePage
    },
    {
        path: '/my-announcement',
        component: MyAnnouncementPage
    }
]