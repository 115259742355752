import React, { } from "react";
import Form, { IItemProps } from "devextreme-react/form";
import { ModuleConfig, StringMap, alertError, notifySuccess, dataSources, alertWarning, enums } from "../../base";
import { dangerService } from "../../api";
import { Popup, ScrollView, TextBox } from "devextreme-react";
import { DangerItemModel, DangerModel } from "../../models";
import { FormMultiImageUploader, PopupContentBox, RefPopup, StdForm } from "../../components";

export interface DangerEditFormProps {
    onSaved?: () => void;
}

interface DangerWithName extends DangerModel {
    _customerName?: string;
}

interface DangerEditFormState {
    danger: DangerWithName;
    items: IItemProps[];
}

export default class DangerEditForm extends React.Component<DangerEditFormProps, DangerEditFormState> {
    moduleName: ModuleConfig = {
        moduleName: "Danger",
        children: {
            customer: "Customer",
            handler: 'Employee',
            organization: 'Organization'
        }
    };

    nameMap: StringMap = { 
        "_customerName": "customerId",
        "organizataion.name": "organizationId",
        "hanlder.name": "handlerId"
    };
    
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    scrollViewRef = React.createRef<ScrollView>();
    items: IItemProps[] = [
        { 
            dataField: "_customerName",
            editorOptions: { readOnly: true }
        },
        { 
            dataField: 'organization.name',
            editorOptions: { readOnly: true }
        },
        {
            dataField: 'handler.name',
            editorOptions: { readOnly: true }
        },
        { dataField: 'checker', label: { text: '检查单位' } },
        { dataField: 'checkTime', editorType: 'dxDateBox', editorOptions: { type: "datetime" } },
        { dataField: 'expirationDate', editorType: 'dxDateBox' },
        { dataField: 'address' },
        { dataField: 'phoneNumber' },
        {
            dataField: 'dangerLevel',
            editorType: 'dxSelectBox',
            editorOptions: { dataSource: dataSources.dangerLevels, displayExpr: 'text', valueExpr: 'value' }
        },
        { name: 'empty', itemType: 'empty' },
        {
            name: 'addbutton',
            itemType: 'button',
            horizontalAlignment: 'left',
            buttonOptions: { text: "添加隐患项", type: 'success', onClick: this.addItem.bind(this) },
            colSpan: 2
        },
        {
            dataField: 'note',
            colSpan: 2,
            cssClass: "nopadding-file",
            editorType: 'dxTextArea',
            editorOptions: {
                height: 100,
                stylingMode: "outlined"
            }
        }
    ];

    constructor(props: Readonly<DangerEditFormProps>) {
        super(props);

        this.state = {
            danger: {},
            items: [...this.items]
        };

        this.onCustomerSelected = this.onCustomerSelected.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    async show(danger: DangerModel) {
        this.popupRef.current?.instance.show();

        const model: DangerWithName = await dangerService.get(danger.id!);
        model._customerName = `${danger.customer?.name}（${danger.customer?.gasCode}）`;

        const newItems = this.items.slice(0, this.items.length - 2);

        for (let i = 0; i < model.items!.length; i++) {
            newItems.push(...this.createItem(model.items![i], i));
        }

        newItems.push(...this.items.slice(this.items.length - 2));

        this.setState({
            danger: model,
            items: newItems
        });
    }

    getCustomerName(customer: any) {
        return customer?.name ? 
            `${customer.name}（${customer.gasCode}${customer.status === enums.customerStatus.inactive ? '，已退户' : ''}）` :
            "";
    }

    onCustomerSelected(customer: any) {
        const danger = {
            ...this.state.danger,
            customer: customer,
            customerId: customer.id,
            _customerName: this.getCustomerName(customer),
            phoneNumber: customer.phoneNumber,
            address: customer.address,
            organizationId: customer.organizationId,
        }

        this.setState({ danger: danger });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    addItem() {
        const items = this.state.danger.items!;
        const len = items.length;
        items.push({});

        this.setState({
            items: [
                ...this.state.items.slice(0, this.state.items.length - 2),
                ...this.createItem(items[len], len),
                ...this.state.items.slice(this.state.items.length - 2)
            ]
        });
    }

    createItem(item: DangerItemModel, index: number): IItemProps[] {
        return [
            {
                dataField: `item_itemName${index}`,
                label: { text: `隐患${index + 1}` },
                render: () => (
                    <TextBox defaultValue={item.itemName}
                        onValueChanged={this.onItemValueChanged.bind(this, index)} />
                )
            },
            {
                dataField: `item_photo${index}`,
                label: { text: '图片' },
                render: () => (
                    <FormMultiImageUploader
                        formData={item}
                        dataField={"photo"}
                        sourceField={"photoSource"}
                        id={`dangerIssueItem${index}`} />
                )
            }
        ]
    }

    onItemValueChanged(index: number, e: any) {
        const items: DangerItemModel[] = this.state.danger.items!;
        items[index].itemName = e.value;

        this.setState({ danger: this.state.danger });
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();
        if (!isValid?.isValid) {
            return;
        } else if (!this.state.danger.items?.filter(i => i.itemName).length) {
            alertWarning("请填写隐患项");
            return;
        }

        const result = await dangerService.update(this.state.danger);

        if (result.succeeded) {
            this.setState({ danger: {} });
            notifySuccess("保存成功");
            this.hide();
            this.props.onSaved && this.props.onSaved();
        } else {
            alertError(result.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render() {

        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"编辑隐患"}
                width={1000}
                height={580}>
                <PopupContentBox onSave={this.save} onCancel={this.cancel}>
                    <ScrollView ref={this.scrollViewRef}>
                        <StdForm
                            moduleName={this.moduleName}
                            formRef={this.formRef}
                            colCount={2}
                            items={this.state.items}
                            nameMap={this.nameMap}
                            formData={this.state.danger} />
                    </ScrollView>
                </PopupContentBox>
            </RefPopup>
        );
    }
}

