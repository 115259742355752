import { createStoreFromUrl, enums, http, MessageBool, BaseApiService } from "../base";
import { CustomerCondition, CustomerModel } from "../models";

class CustomerService extends BaseApiService<CustomerModel> {
    moduleName = "customer";

    getDxData(condition: CustomerCondition) {
        return createStoreFromUrl(`${this.apiPath}/dx?${http.serialize([condition]) || ''}`, 'id');
    }

    get(id: number) {
        return http.getData<CustomerModel>(`${this.apiPath}/detail/${id}`);
    }

    find(codeOrPhone: string) {
        return http.getData<CustomerModel>(`${this.apiPath}/find?codeOrPhone=${codeOrPhone}`);
    }

    search(key: string) {
        return http.getData<CustomerModel[]>(`${this.apiPath}/search?key=${key}`);
    }

    getLastDeliver(id: number) {
        return http.getData<string>(`${this.apiPath}/lastdeliver/${id}`);
    }

    newCustomer() : CustomerModel {
        return { status: enums.customerStatus.active };
    }

    activate(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/activate/${id}`, '');
    }

    batchActivate(ids: number[]) {
        return http.putData<MessageBool>(`${this.apiPath}/activate`, ids);
    }

    inactivate(id: number, reason: string) {
        return http.putData<MessageBool>(`${this.apiPath}/inactivate/${id}`, reason);
    }

    batchInactivate(ids: number[], reason: string) {
        return http.putData<MessageBool>(`${this.apiPath}/inactivate`, { ids: ids, reason: reason });
    }

    changeGasCode(id: number, newCode: string) {
        return http.putData<MessageBool>(`${this.apiPath}/changegascode/${id}`, newCode);
    }

    switchLock(id: number, note: string) {
        return http.putData<MessageBool>(`${this.apiPath}/switchlock/${id}`, note);
    }

    batchSwitchLock(ids: number[], note: string) {
        return http.putData<MessageBool>(`${this.apiPath}/switchlock`, { ids: ids, note: note });
    }

    unbindWeiXin(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/unbindweixin/${id}`, '');
    }

    setSecurityCodePrintedAsync(ids: number[]) {
        return http.putData<MessageBool>(`${this.apiPath}/securitycode`, ids);
    }

    batchSwitchStdAddress(ids: number[]) {
        return http.putData<MessageBool>(`${this.apiPath}/stdaddress`, ids);
    }
}

export const customerService = new CustomerService();