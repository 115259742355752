import { PieChart } from "devextreme-react";
import { Connector, Export, Label, Legend, Margin, Series, Size } from "devextreme-react/chart";
import { useMemo } from "react";
import { useScreenSizeClass } from "../../hooks";
import { HomeReportOrderSourceData } from "../../models";

export interface OrderSourceProps {
    data?: HomeReportOrderSourceData;
}

export default function OrderSource(props: OrderSourceProps) {
    const dataSource = useMemo(() => {
        const list: any[] = [];
        const data = props.data;
        if (!data) { return []; }

        for (const key in data.counts) {
            list.push({
                source: key,
                count: data.counts[key],
                amount: data.amounts[key]
            });
        }

        return list;
    }, [props]);

    const sizeClass = useScreenSizeClass();

    function customizeText(arg: any) {
        return `${arg.valueText}（${arg.percentText}）\n￥${props.data!.amounts[arg.argument]}`;
    }

    return (
        <PieChart
            palette={"Bright"}
            dataSource={dataSource}
            title={"订单来源分布图"}
            resolveLabelOverlapping={"shift"}>
            <Series argumentField={"source"} valueField="count">
                <Label visible={true} customizeText={customizeText}>
                    <Connector visible={true} width={1} />
                </Label>
            </Series>
            <Margin bottom={20} />
            <Legend verticalAlignment="bottom" horizontalAlignment="center" />
            <Export enabled={true} />
            <Size width={sizeClass === "screen-x-small" ? 320 : 400} />
        </PieChart>
    )
}

