import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import { organizationService, siteDangerDeletedStore, siteDangerStore } from "../../api";
import { createGridOptions, createImageColumn, remoteReadOnlyGridOptions, setColumnDataSource, StdGrid } from "../../components";
import { OrganizationRef, SiteDangerModel } from "../../models";
import CustomStore from "devextreme/data/custom_store";
import { dataSources } from "../../base";

export interface SiteDangerGridProps {
    onModelSelected: (model: SiteDangerModel) => void;
    onRowChecked: (ids: number[]) => void;
    onButtonClick: (model: SiteDangerModel, name: string) => void;
}

interface SiteDangerGridState {
    store: CustomStore;
    orgs: OrganizationRef[];
    columns: IColumnProps[];
}

export default class SiteDangerGrid extends 
    React.Component<SiteDangerGridProps, SiteDangerGridState> {
    moduleName = "SiteDanger";

    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions(
        "siteDangers", 
        this.gridRef, 
        remoteReadOnlyGridOptions,
        (items: any[]) => {
            // state清空会同时清空filter，需要重新绑定旧的
            items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
            return items;
        });

    constructor(props: Readonly<SiteDangerGridProps>) {
        super(props);

        this.state = {
            store: siteDangerStore,
            orgs: [],
            columns: [
                { dataField: "checkTime", dataType: "datetime" },
                { 
                    dataField: "organizationId",
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: "personInCharge" },
                { dataField: "fixRequirement" },
                { dataField: "fixExpiration" },
                { dataField: "fixTime", dataType: "datetime" },
                { dataField: "fixCost", dataType: "number", format: "currency" },
                { dataField: "fixDesc" },
                { 
                    dataField: "auditStatus",
                    lookup: { dataSource: dataSources.dangerAuditStatus, displayExpr: 'text', valueExpr: 'value' }
                },
                createImageColumn({ dataField: "checkerSign" }),
                createImageColumn({ dataField: "receiverSign" }),
                createImageColumn({ dataField: "inChargeFixSign" }),
                createImageColumn({ dataField: "recheckerFixSign" })
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = (e) => this.props.onButtonClick(e.data, "detail");
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let orgs = await organizationService.getRefs();

        setColumnDataSource(this.state.columns, "organizationId", orgs);

        this.setState({ orgs: orgs });
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});    // 为避免混乱，多选不能编辑
        }

        this.props.onRowChecked(e.selectedRowKeys);
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }
    
    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    filter(filterValue: any, showDeleted: boolean) {
        if (showDeleted && this.state.store !== siteDangerDeletedStore) {
            this.setState({ store: siteDangerDeletedStore }, () => this._filter(filterValue));
        } else if (!showDeleted && this.state.store !== siteDangerStore) {
            this.setState({ store: siteDangerStore }, () => this._filter(filterValue))
        } else {
            this._filter(filterValue);
        }
    }

    _filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }
    
    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns}>
            </StdGrid>
        );
    }
}