import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import { createGridOptions, setColumnDataSource, stdReadOnlyGridOptions, StdGrid } from "../../components";
import { dataSources } from "../../base";
import { bottleTypeService, productStore } from "../../api";
import { BottleTypeModel, ProductModel } from "../../models";

export interface ProductGridProps {
    onButtonClick: (product: ProductModel, name: string) => void;
    onProductSelected: (product: ProductModel) => void;
}

interface ProductGridState {
    bottleTypes: BottleTypeModel[];
    columns: IColumnProps[];
}

export default class ProductGrid extends React.Component<ProductGridProps, ProductGridState> {
    moduleName = "Product";

    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("products", this.gridRef, stdReadOnlyGridOptions);

    constructor(props: Readonly<ProductGridProps>) {
        super(props);

        this.state = {
            bottleTypes: [],
            columns: [
                { 
                    dataField: "type",
                    lookup: { dataSource: dataSources.productTypes, displayExpr: 'text', valueExpr: 'value' },
                },
                { dataField: "name" },
                { 
                    dataField: "bottleTypeId",
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: "price" },
                // { dataField: "webPrice" },
                // { dataField: "appPrice" },
                // { dataField: "wxPrice" }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
    }
    
    componentDidMount() {
        this.loadBottleTypes();
    }

    async loadBottleTypes() {
        const data = await bottleTypeService.getAll();
        setColumnDataSource(this.state.columns, "bottleTypeId", data);

        this.setState({
            bottleTypes: data,
        });
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onProductSelected(e.selectedRowsData[0]);
        } else {
            this.props.onProductSelected({});
        }
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={productStore}
                options={this.gridOptions}
                columns={this.state.columns}>
            </StdGrid>
        )
    }
}