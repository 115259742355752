import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import { createGridOptions, remoteReadOnlyGridOptions, setColumnDataSource, StdGrid } from "../../components";
import { customerCallbackStore, organizationService } from "../../api";
import { StringMap } from "../../base";
import { CustomerCallbackModel, OrganizationRef } from "../../models";

export interface CustomerCallbackProps {
    onButtonClick: (model: CustomerCallbackModel, name: string) => void;
    onModelSelected: (model: CustomerCallbackModel) => void;
}

interface CustomerCallbackState {
    columns: IColumnProps[];
    organizations: OrganizationRef[];
}

export default class CustomerCallbackGrid 
    extends React.Component<CustomerCallbackProps, CustomerCallbackState> {
    moduleName = "CustomerCallback";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("customerCallbacks", this.gridRef, remoteReadOnlyGridOptions);
    nameMap: StringMap = { "customer.name": "customerId" };

    constructor(props: Readonly<CustomerCallbackProps>) {
        super(props);

        this.state = {
            organizations: [],
            columns: [
                { 
                    dataField: "organizationId",
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                {
                    dataField: "customer.name",
                    cellRender: e => {
                        return <span>{e.data.customer.name}（{e.data.customer.gasCode}）</span>
                    }
                },
                { dataField: "customerName" },
                { dataField: "phoneNumber" },
                { dataField: "callbackTime" },
                { dataField: "score" },
                { dataField: "content" }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
    }

    componentDidMount() {
        this.loadDataSources();
    }

    async loadDataSources() {
        const orgs = await organizationService.getRefs();
        setColumnDataSource(this.state.columns, "organizationId", orgs);
        this.setState({ organizations: orgs });
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});
        }
    }

    filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={customerCallbackStore}
                options={this.gridOptions}
                columnNameMap={this.nameMap}
                columns={this.state.columns}>
            </StdGrid>
        )
    }
}

