import React from "react";

export function renderExceptionData(
    data: string,
    showFlowPopup: (code: string) => void,
    showCallCenter: (gasCode: string) => void) {
    const matches = data.match(/条码：(\d+)/);
    const customerMatches = data.match(/客户：\D+(\d+)/);
    const hasCustomer = !!customerMatches && customerMatches.length > 1;
    const customerPrefixLength = hasCustomer ? customerMatches![0].length - customerMatches![1].length : 0;

    if (matches && matches.length > 1) {
        const code = matches[1];

        return (
            <React.Fragment>
                {
                    hasCustomer && (customerMatches!.index || 0) < (matches.index || 0) ?
                    <React.Fragment>
                        {data.substring(0, customerMatches!.index)}客户：
                        <span className="link-button" onClick={() => showCallCenter(customerMatches![1])}>
                            {customerMatches![0].substring(3, customerPrefixLength)}
                            {customerMatches![1]}）
                        </span>
                        {data.substring((customerMatches!.index || 0) + customerMatches![0].length + 1, matches.index)}
                    </React.Fragment> :
                    data.substring(0, matches.index)
                }
                条码：
                <span className="link-button" onClick={() => showFlowPopup(code)}>
                    {matches[1]}
                </span>
                {
                    hasCustomer && (customerMatches!.index || 0) > (matches.index || 0) ?
                    <React.Fragment>
                        {data.substring((matches.index || 0) + matches[0].length, customerMatches!.index)}客户：
                        <span className="link-button" onClick={() => showCallCenter(customerMatches![1])}>
                            {customerMatches![0].substring(3, customerPrefixLength)}
                            {customerMatches![1]}）
                        </span>
                        {data.substring((customerMatches!.index || 0) + customerMatches![0].length + 1)}
                    </React.Fragment> :
                    data.substring((matches.index || 0) + matches[0].length)
                }
            </React.Fragment>
        )
    } else {    // 理论上不会出现
        return (<React.Fragment>{data}</React.Fragment>);
    }
}