import React from "react";
import { Form, Popup } from "devextreme-react";
import { IItemProps } from "devextreme-react/form"
import { CustomerPopup, PopupContentBox, RefPopup, StdForm, createPopupField, setItemDataSource } from "../../components";
import { StringMap, alertError, alertWarning, modelClone, notifySuccess } from "../../base";
import { CustomerModel, EmployeeRef, RecycleRequestModel } from "../../models";
import { recycleRequestService } from "../../api/recycle-request.service";
import { employeeService } from "../../api";

interface RecycleRequestWithName extends RecycleRequestModel {
    _customerName?: string;
}

export interface RecycleRequestFormProps {
    onSaved: () => void;
}

interface RecycleRequestFormState {
    model: RecycleRequestWithName;
    employees: EmployeeRef[];
    items: IItemProps[];
}

export default class RecycleRequestForm
    extends React.Component<RecycleRequestFormProps, RecycleRequestFormState> {
    moduleName = "RecycleRequest";
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    customerPopupRef = React.createRef<CustomerPopup>();
    nameMap: StringMap = { "_customerName": "customerId" };

    constructor(props: Readonly<RecycleRequestFormProps>) {
        super(props);

        this.state = {
            model: {},
            employees: [],
            items: [
                createPopupField("_customerName", this.customerPopupRef),
                { 
                    dataField: "deliverId",
                    editorType: "dxSelectBox",
                    editorOptions: { dataSource: [], displayExpr: 'nameAndCode', valueExpr: 'id', searchEnabled: true }
                }
            ]
        }

        this.onCustomerSelected = this.onCustomerSelected.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    show(model: RecycleRequestWithName) {
        this.popupRef.current?.instance.show();
        model = modelClone(model);
        this.setState({ model: model });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async onCustomerSelected(customer: CustomerModel) {
        const model = {
            ...this.state.model,
            customerId: customer.id,
            _customerName: `${customer.name}（${customer.gasCode}）`,
        };

        var employees = await employeeService.getDelivers();
        employees = employees.filter(e => e.organizationId === customer.organizationId);

        setItemDataSource(this.state.items, "deliverId", employees);

        this.setState({ 
            model: model,
            employees: employees
        });
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (!isValid?.isValid) {
            return;
        } else if (!this.state.model.customerId) {
            alertWarning("请选择客户！");
            return;
        }

        const model = this.state.model;
        const res = await recycleRequestService.create(model);

        if (res.succeeded) {
            notifySuccess("已成功保存");
            this.hide();
            this.props.onSaved();
        } else {
            alertError(res.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"编辑"}
                width={400}
                height={250}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        items={this.state.items}
                        nameMap={this.nameMap}
                        formData={this.state.model} />
                </PopupContentBox>
                <CustomerPopup ref={this.customerPopupRef} onCustomerSelected={this.onCustomerSelected} />
            </RefPopup>
        )
    }
}