import { createStoreFromApi, http, BaseApiService } from "../base";
import { OrderCheckTypeModel, OrderCheckTypeRef } from "../models";

class OrderCheckTypeService extends BaseApiService<OrderCheckTypeModel> {
    moduleName = "orderchecktype";
    cacheSeconds = 60;

    getRefs(): Promise<OrderCheckTypeRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/refs`), this.cacheSeconds);
    }
}

export const orderCheckTypeService = new OrderCheckTypeService();
export const orderCheckTypeStore = createStoreFromApi(orderCheckTypeService);