import { on } from "devextreme/events";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";

export const modalIcons = {
    question: "bi bi-question-circle",
    success: "bi bi-check-circle",
    info: "bi bi-info-circle",
    warning: "bi bi-exclamation-circle",
    danger: "bi bi-x-circle"
};

export interface ModalOption {
    title?: string;
    defaultValue?: string;
    style?: "primary" | "success" | "warning" | "danger";
    icon?: string;
    buttonText?: string;
    cancelButtonText?: string;
    isPassword?: boolean;
}

interface InnerOption {
    cancelButton?: boolean;
    input?: boolean;
}

export function notifySuccess(msg: string) {
    _notify(msg, "success");
}
export function notifyWarning(msg: string) {
    _notify(msg, "warning");
}
export function notifyError(msg: string) {
    _notify(msg, "error");
}

function _notify(msg: string, type: string) {
    notify({ message: msg, position: 'bottom right' }, type);
}

export async function alert2(msg: string, options?: ModalOption) {
    await modal(msg, options);
}

export function alertError(msg: string) {
    return alert2(msg, { style: "danger", icon: modalIcons.danger, title: '错误' });
}

export function alertSuccess(msg: string) {
    return alert2(msg, { style: "success", icon: modalIcons.success, title: '提示' });
}

export function alertWarning(msg: string) {
    return alert2(msg, { style: "warning", icon: modalIcons.warning }); 
}

export async function confirm2(msg: string, options?: ModalOption) {
    const value = await modal(msg, 
        Object.assign({ icon: modalIcons.question, style: "warning" }, options),
        { cancelButton: true });

    return !!value;
}

export async function prompt2(msg: string, options?: ModalOption) {
    const value = await modal(msg,
        Object.assign({ title: '提示' }, options),
        { cancelButton: true, input: true });

    return typeof(value) === "string" ? value : undefined;
}

let dialog: any;

function modal(msg: string, options?: ModalOption, innerOptions?: InnerOption): Promise<any> {
    if (dialog) {
        dialog.hide();
    }
    
    const buttons: any[] = [
        { 
            text: options?.buttonText || '确定', 
            stylingMode: 'filled',
            onClick: () => {
                const value = innerOptions?.input ? 
                    (document.getElementById("g_prompt") as HTMLInputElement)?.value :
                    true;

                dialog.hide(value);
            }
        }
    ];

    if (innerOptions?.cancelButton) {
        buttons.push({
            text: options?.cancelButtonText || '取消',
            stylingMode: 'filled',
            type: 'normal',
            onClick: () => {
                dialog.hide(false);
            }
        })
    }

    dialog = custom({
        title: options?.title || "警告",
        messageHtml: createMessage(msg, options, innerOptions?.input),
        buttons: buttons,
        showTitle: true,
        dragEnabled: true
    });

    var promise = dialog.show();

    if (innerOptions?.input) {
        const input = document.getElementById("g_prompt") as HTMLInputElement;
        input.type = options?.isPassword ? "password" : "text";

        on(input, "keydown", function(event: any) {
            if (event.keyCode === 13) {
                const value = input.value.trim();
                input.className = value ? "dx-texteditor-input" : "dx-texteditor-input invalid";
                if (value) {
                    dialog.hide(value);
                }
            }
        });

        setTimeout(() => {
            try { input.focus(); } catch {}
        }, 500)
    }

    return promise.then((result: any) => {
        return result;
    }).catch(() => {
        return false;
    });
}

function createMessage(msg: string, options?: ModalOption, input?: boolean) {
    let html = `<div class="global-dialog global-dialog-${options?.style || 'primary'}">`;

    if (input) {
        html += `<div>${msg}</div>` +
            `<input id="g_prompt" class="dx-texteditor-input" value="${options?.defaultValue || ''}" />`;
    } else if (options?.icon) {
        html += `<div class="icon-body">` +
                `<div><i class="${options?.icon}"></i></div>` +
                `<div>${msg}</div>` +
            `</div>`
    } else {
        html += msg;
    }

    html += "</div>";
    return html;
}


type Handler = (args: any) => void;
const ghandlers: { [key: string]: Handler[] } = {};

class GlobalPopupManager {

    subscribe(name: string, handler: Handler) {
        if (!ghandlers[name]) {
            ghandlers[name] = [];
        }

        ghandlers[name].push(handler);
    }

    unsubscribe(name: string, handler: Handler) {
        const handlers = ghandlers[name];
        if (!handlers) {
            return;
        }

        const index = handlers.indexOf(handler);
        if (index !== -1) {
            handlers.splice(index, 1);
        }
    }

    show(name: string, args: any) {
        const handlers = ghandlers[name] || [];
        handlers.forEach(h => h(args));
    }
}

export const globalPopupManager = new GlobalPopupManager();

export const globalPopupNames = {
    callCenter: 'callcenter',
    exportPopup: 'exportpopup',
    orderGrid: 'orderGrid'  // 订单表格实际上是刷新列表
};