import React from "react";
import { Popup } from "devextreme-react";
import Form, { IItemProps } from "devextreme-react/form";
import { bottleDebtService } from "../../api";
import { RefPopup } from "../popup";
import { PopupContentBox } from "../layouts";
import { StdForm } from "../form";
import { alertError, modelClone, notifySuccess } from "../../base";
import { BottleDebtModel } from "../../models";

export interface CompleteDebtPopupProps {
    onSaved: () => void;
}

interface CompleteDebtPopupState {
    bottleDebt: BottleDebtModel;
}

export default class CompleteDebtPopup extends React.Component<CompleteDebtPopupProps, CompleteDebtPopupState> {
    moduleName = "BottleDebt";

    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();

    items: IItemProps[] = [
        {
            dataField: 'completedAt',
            editorType: 'dxDateBox',          
            editorOptions: { type: "datetime", readOnly: true },
            validationRules: [{ type: "required", message: "退还时间不能为空" }]
        },
        {
            dataField: 'note',
            editorType: 'dxTextArea',
            editorOptions: { height: 100 }
        }
    ]

    constructor(props: Readonly<CompleteDebtPopupProps>) {
        super(props);

        this.state = {
            bottleDebt: {}
        };

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    show(bottleDebt: BottleDebtModel) {
        this.popupRef.current?.instance.show();

        bottleDebt = modelClone(bottleDebt);
        bottleDebt.completedAt = new Date();

        this.setState({ bottleDebt: bottleDebt });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const valid = this.formRef.current?.instance.validate();
        if (!valid?.isValid) {
            return;
        }

        const result = await bottleDebtService.update(this.state.bottleDebt);

        if (result.succeeded) {
            notifySuccess("已成功退还");
            this.hide();
            this.props.onSaved();
        } else {
            alertError(result.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"退还"}
                width={400}
                height={300}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        formRef={this.formRef}
                        moduleName={this.moduleName}
                        formData={this.state.bottleDebt}
                        items={this.items} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}