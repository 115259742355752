import { TreeList, ITreeListOptions } from "devextreme-react/tree-list";
import { ToolbarPreparingEvent } from "devextreme/ui/tree_list";
import { ScreenSize } from "../../hooks";
import { updatePack } from "./dev-packs";
import { confirm2 } from "../../base/dialogs";
import { deepClone } from "../../base/format-functions";
import { createResizablePopupOptions, stdGridPopupOptions } from "./popup-options";

export const stdReadOnlyTreeListOptions: ITreeListOptions = {
    columnAutoWidth: true,
    onRowUpdating: updatePack,
    rootValue: undefined,
    selection: { mode: 'single' },
    onRowPrepared: e => {
        // 测试数据标志
        if (e.data?.testFlag) {
            e.rowElement.className += " test-row";
        }
    },
    onRowRemoving: function(e) {
        e.component.option("loadPanel.enabled", false);
        e.cancel = (async function() {
            const res =  !(await confirm2("确定要删除此数据吗？"));
            e.component.option("loadPanel.enabled", true);
            return res;
        })() as any;
    },    
    editing: {
        confirmDelete: false,
        mode: "popup",
        form: { labelLocation: "left" },
        popup: { showTitle: true, title: "编辑" }
    }
};

export const stdTreeListOptions: ITreeListOptions = {
    ...stdReadOnlyTreeListOptions,    
    editing: {
        ...stdReadOnlyTreeListOptions.editing,
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true
    }
};

export const addOnlyTreeListOptions: ITreeListOptions = {
    ...stdReadOnlyTreeListOptions,
    onRowRemoving: stdTreeListOptions.onRowRemoving,  
    editing: {
        allowAdding: true,
        confirmDelete: false,
        mode: "popup",
        popup: { showTitle: true, title: "编辑" }
    }   
}

export function createTreeListOptions(
    id: string,
    ref: React.MutableRefObject<TreeList | null> | (() => TreeList | null),
    baseOptions: ITreeListOptions,
    toolbarItems?: any[]) {

    const toolbarFunc = function (e: ToolbarPreparingEvent) {
        e.toolbarOptions.items?.push(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: "refresh",
                    hint: "刷新",
                    onClick: function () {
                        (typeof (ref) === "function" ? ref() : ref.current)?.instance.refresh();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: "undo",
                    hint: "还原默认设置",
                    onClick: function () {
                        (typeof (ref) === "function" ? ref() : ref.current)?.instance.state(null);
                    }
                }
            },
            ...(toolbarItems || [])
        );
    };

    const options: ITreeListOptions = {
        ...deepClone(baseOptions),
        id: id,
        stateStoring: {
            enabled: true,
            type: "localStorage",
            storageKey: `tree-list-options-${id}`
        },
        onToolbarPreparing: toolbarFunc
    };

    setCustomEditButtons(options, ref);

    return options;
}

function setCustomEditButtons(
    options: ITreeListOptions,
    ref: React.MutableRefObject<TreeList | null> | (() => TreeList | null)) {
    if (!options.editing!.popup)
        options.editing!.popup = { ...stdGridPopupOptions };

    const popup = options.editing!.popup!;
    popup.toolbarItems = [
        {
            widget: "dxButton",
            toolbar: "bottom",
            location: "after",
            options: {
                text: "保存",
                stylingMode: "contained",
                onClick: function () {
                    (typeof (ref) === "function" ? ref() : ref.current)?.instance.saveEditData();
                }
            }
        },
        {
            widget: "dxButton",
            toolbar: "bottom",
            location: "after",
            options: {
                text: "取消",
                stylingMode: "contained",
                type: "normal",
                onClick: function () {
                    (typeof (ref) === "function" ? ref() : ref.current)?.instance.cancelEditData();
                }
            }
        }
    ]
}

export function setTreeListPopupOptions(
    options: ITreeListOptions,
    width?: number,
    height?: number,
    screenSize?: ScreenSize) {

    if (options.editing) {
        if (width && height) {
            options.editing.popup = createResizablePopupOptions(
                width, height, true, options.editing.popup || stdGridPopupOptions, screenSize);
            options.editing.popup.width = width;
            options.editing.popup.height = height;
        } else {
            options.editing.popup = { ...stdGridPopupOptions }
        }
    }

    return options;
}