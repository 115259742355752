import { Popup } from "devextreme-react";
import React from "react";
import { RefPopup } from "../../components";
import CpScrapPage from "./cp-scrap";

export default class CpScrapDialog extends CpScrapPage {
    popupRef = React.createRef<Popup>();

    constructor(props: any) {
        super(props);

        this.deferLoad = true;
    }

    show(batchId: number) {
        this.popupRef.current?.instance.show();
        
        this.gridRef.current?.load(batchId);
        this.formRef.current?.setBatchId(batchId);
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }
    
    render() {
        return (
            <RefPopup           
                popupRef={this.popupRef} 
                title={"报废瓶批次详情"}>
                {this.renderContent()}
            </RefPopup>
        );
    }
}