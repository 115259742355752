import React from "react";
import { Button, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { DataMap, enums } from "../../base";
import { OrganizationRef, VehicleModel } from "../../models";
import { organizationService } from "../../api";
import { OrgTreeDropDown } from "../../components";

export interface VehicleToolbarProps {
    permissions?: DataMap<boolean>;
    onButtonClick: (model: VehicleModel, name: string) => void;
    onFiltering: (condition: any) => void;
}

interface VehicleToolbarState {
    model: VehicleModel;
    orgs: OrganizationRef[];
    organizationId?: number;
}

export default class StdToolbar extends React.Component<VehicleToolbarProps, VehicleToolbarState> {
    constructor(props: Readonly<VehicleToolbarProps>) {
        super(props);

        this.state = {
            model: {},
            orgs: []
        };

        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount(): void {
        this.loadOrgs();
    }

    async loadOrgs() {
        const orgs = await organizationService.getRefs();
        this.setState({ orgs: orgs });
    }

    setModel(model: any) {
        this.setState({ model: model });
    }

    onFiltering(organizationId?: number) {
        this.props.onFiltering(organizationId ? ["organizationIdString", "contains", ',' + organizationId + ','] : null);
        this.setState({ organizationId: organizationId });
    }

    render() {
        const { model, organizationId, orgs } = this.state;
        const permissions = this.props.permissions || {};
        const hasModel = !!model['id'];

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu={true}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="选择供应站过滤"
                            width={180}
                            onValueChanged={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions[enums.stdActions.create]}>
                        <Button
                            text="新增"
                            onClick={() => this.props.onButtonClick({}, "add")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.update]}>
                        <Button
                            disabled={!hasModel}
                            text="编辑"
                            onClick={() => this.props.onButtonClick(model, "edit")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.delete]}>
                        <Button
                            disabled={!hasModel}
                            text="删除"
                            onClick={() => this.props.onButtonClick(model, "delete")} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}