import CustomStore from "devextreme/data/custom_store";
import { http, MessageBool } from "../base";
import { BottleFlowModel, BriefBottlePosition } from "../models";

class BottlePositionService {
    apiPath = "/api/bottleposition";

    async searchAsync(condition: any): Promise<BottleFlowModel[]> {
        return cache = await http.getData(this.apiPath, condition);
    }

    async searchByCustomerId(customerId: number) {
        return await http.getData<BriefBottlePosition[]>(`${this.apiPath}/bycustomer/${customerId}`);
    }

    async update(id: number, flowId?: number) {
        return await http.putData<MessageBool>(`${this.apiPath}/${id}?flowId=${flowId}`, '');
    }
}

let cache: any[] = [];

export const bottlePositionService = new BottlePositionService();
export const bottlePositionStore = new CustomStore({
    key: 'id',
    load: function() {
        return cache;
    }
})