import { Popup } from "devextreme-react";
import Form, { IItemProps } from "devextreme-react/form";
import React from "react";
import { bugTraceService, sysObjectService } from "../../api";
import { alertError, dataSources, modelClone, notifySuccess } from "../../base";
import { FormMultiImageUploader, PopupContentBox, RefPopup, setItemDataSource, StdForm } from "../../components";
import { BugTraceModel, SysObjectModel } from "../../models";

export interface BugTraceFormProps {
    onSaved: () => void;
}

interface BugTraceFormState {
    model: BugTraceModel;
    sysObjects: SysObjectModel[];
    items: IItemProps[];
}

export default class BugTraceForm extends React.Component<BugTraceFormProps, BugTraceFormState> {
    moduleName = "BugTrace";
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();

    constructor(props: Readonly<BugTraceFormProps>) {
        super(props);

        this.state = {
            model: {},
            sysObjects: [],
            items: [
                {
                    dataField: 'status',
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: dataSources.bugStatus, displayExpr: 'text', valueExpr: 'value' }
                },
                {
                    dataField: 'priority',
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: dataSources.bugPriority, displayExpr: 'text', valueExpr: 'value' }
                },
                {
                    dataField: 'platform',
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: dataSources.platforms, displayExpr: 'text', valueExpr: 'value' }
                },
                { 
                    dataField: 'objectName',
                    editorType: 'dxSelectBox',
                    editorOptions: { 
                        dataSources: [], 
                        displayExpr: 'label',
                        valueExpr: 'objectName',
                        searchEnabled: true
                    }
                },
                { dataField: 'actionName' },
                { dataField: 'operator' },
                { 
                    dataField: 'photos',
                    colSpan: 2,
                    render: (e: any) => {
                        return (
                            <FormMultiImageUploader
                                formData={this.state.model}
                                dataField={"photos"}
                                sourceField={"photoSource"}
                                id={"bugTracePhoto"} />
                        )
                    }
                },
                { 
                    dataField: 'question',
                    colSpan: 2,
                    editorType: 'dxTextArea',
                    editorOptions: { height: 80 }
                },
                { 
                    dataField: 'answer',
                    colSpan: 2,
                    editorType: 'dxTextArea',
                    editorOptions: { height: 80 }
                }
            ]
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount(): void {
        this.loadData();
    }

    async loadData() {
        const objs = await sysObjectService.getVisible();
        setItemDataSource(this.state.items, "objectName", objs);
        this.setState({ sysObjects: objs })
    }

    show(model: BugTraceModel) {
        this.popupRef.current?.instance.show();
        this.setState({ model: modelClone(model) });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const model = this.state.model;
            const res = model.id ?
                await bugTraceService.update(model) :
                await bugTraceService.create(model);

            if (res.succeeded) {
                this.hide();
                notifySuccess("已成功保存");
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title="编辑"
                width={800}
                height={520}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        formData={this.state.model} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}