import "./image-uploader.scss";
import React from "react";
import { FileUploader, ProgressBar } from "devextreme-react";
import { DropZoneEnterEvent, DropZoneLeaveEvent, UploadedEvent, ProgressEvent, UploadErrorEvent } from "devextreme/ui/file_uploader";
import { alertError, tokenStorage } from "../../base";

export interface ImageUploaderProps {
    id: string;
    uploadUrl: string;
    source?: any;
    onUploaded?: (tmpName: string, source: any) => void;
}

interface ImageUploaderState {
    isDropZoneActive: boolean;
    source?: any;
    textVisible: boolean;
    progressVisible: boolean;
    progressValue: number
}

export default class ImageUploader extends React.Component<ImageUploaderProps, ImageUploaderState> {
    allowedFileExtension = ['.jpg', '.jpeg', '.gif', '.png'];

    constructor(props: Readonly<ImageUploaderProps>) {
        super(props);

        this.state = {
            isDropZoneActive: false,
            source: props.source,
            textVisible: !props.source,
            progressVisible: false,
            progressValue: 0
        };

        this.onDropZoneEnter = this.onDropZoneEnter.bind(this);
        this.onDropZoneLeave = this.onDropZoneLeave.bind(this);
        this.onUploaded = this.onUploaded.bind(this);
        this.onProgress = this.onProgress.bind(this);
        this.onUploadStarted = this.onUploadStarted.bind(this);
    }

    onDropZoneEnter(e: DropZoneEnterEvent) {
        if (e.dropZoneElement.id === this.props.id) {
            this.setState({ isDropZoneActive: true });
        }
    }

    onDropZoneLeave(e: DropZoneLeaveEvent) {
        if (e.dropZoneElement.id === this.props.id) {
            this.setState({ isDropZoneActive: false });
        }
    }

    onUploaded(e: UploadedEvent) {
        const file = e.file;
        const fileReader = new FileReader();

        fileReader.onload = () => {
            this.setState({
                isDropZoneActive: false,
                source: fileReader.result
            });

            this.props.onUploaded && 
                this.props.onUploaded(e.request.responseText!, fileReader.result);
        }

        fileReader.readAsDataURL(file);

        this.setState({
            textVisible: false,
            progressVisible: false,
            progressValue: 0
        });
    }

    onProgress(e: ProgressEvent) {
        this.setState({ progressValue: e.bytesLoaded / e.bytesTotal * 100 })
    }

    onUploadStarted() {
        this.setState({
            source: '',
            progressVisible: true,
        })
    }

    onUploadError(e: UploadErrorEvent) {
        console.log(e);
        alertError("无法上传文件，请稍后再试，如仍无法上传，请与管理员联系！");
    }

    render() {
        const { id, uploadUrl } = this.props;
        const { isDropZoneActive, source, textVisible, progressVisible, progressValue } = this.state;

        const externalCss = isDropZoneActive ? 'dropzone dropzone-active' : 'dropzone';
        const header = { Authorization: `bearer ${tokenStorage.token}` };

        return (
            <div className="image-uploader flex-box">
                <div id={id} className={`flex-box ${externalCss}`}>
                    {source && (<img src={source} alt="" />)}
                    {textVisible && (
                        <div>
                            <div>拖动文件到此处</div>
                            <div>...或点击选择文件</div>
                        </div>
                    )}
                    <ProgressBar
                        min={0}
                        max={100}
                        width="70%"
                        showStatus={false}
                        visible={progressVisible}
                        value={progressValue} />
                </div>
                <FileUploader
                    dialogTrigger={`#${id}`}
                    dropZone={`#${id}`}
                    multiple={false}
                    allowedFileExtensions={this.allowedFileExtension}
                    uploadMode="instantly"
                    uploadUrl={uploadUrl}
                    uploadHeaders={header}
                    visible={false}
                    name={"file"}
                    onDropZoneEnter={this.onDropZoneEnter}
                    onDropZoneLeave={this.onDropZoneLeave}
                    onUploaded={this.onUploaded}
                    onProgress={this.onProgress}
                    onUploadStarted={this.onUploadStarted}
                    onUploadError={this.onUploadError} />
            </div>
        )
    }
}