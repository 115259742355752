import React from "react";
import { SelectionChangedEvent } from "devextreme/ui/tree_list";
import { IColumnProps, TreeList } from "devextreme-react/tree-list";
import { Item } from "devextreme-react/toolbar";
import { Button } from "devextreme-react";
import { enums } from "../../base";
import { areaStore } from "../../api";
import { ContentBlock, StdToolbar, createTreeListOptions, stdReadOnlyTreeListOptions, StdTreeList } from "../../components";
import AuthorizedComponent from "../authorized-component";
import { AreaModel } from "../../models";

export default class AreaPage extends AuthorizedComponent {
    objectName = "Area";
    moduleName = "Area";
    treeRef = React.createRef<TreeList>();
    toolbarRef = React.createRef<StdToolbar>();
    treeOptions = createTreeListOptions("areas", this.treeRef, stdReadOnlyTreeListOptions);
    columns: IColumnProps[] = [
        { 
            dataField: 'name',
            cellRender: function (e: any) {
                return `${e.data.name}（${e.data.id}）`;
            }
        },
        { dataField: 'id', visible: false },
        {
            dataField: 'parentId',
            lookup: { dataSource: areaStore, displayExpr: 'name', valueExpr: 'id' }
        }
    ];

    constructor(props: Readonly<any>) {
        super(props);

        this.treeOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.treeOptions.onRowDblClick = e => this.onButtonClick(e.data, "edit");
        this.onButtonClick = this.onButtonClick.bind(this);
        this.renderAddItem = this.renderAddItem.bind(this);
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.toolbarRef.current?.setModel(e.selectedRowsData[0]);
        } else {
            this.toolbarRef.current?.setModel({});
        }
    }

    onButtonClick(model: AreaModel, name: string) {
        const tree = this.treeRef.current?.instance;

        if (tree) {
            switch (name) {
                case "add":
                    if (this.permissions[enums.stdActions.create]) {
                        tree.addRow(model?.id);
                    }
                    break;

                case "edit":
                    if (this.permissions[enums.stdActions.update]) {
                        tree.editRow(tree.getRowIndexByKey(model.id));
                    }
                    break;

                case "delete":
                    if (this.permissions[enums.stdActions.delete]) {
                        tree.deleteRow(tree.getRowIndexByKey(model.id));
                    }
                    break;
            }
        }
    }

    render() {
        return (
            <ContentBlock caption={"地区"}>
                <StdToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    itemsBefore={this.renderAddItem}
                    onButtonClick={this.onButtonClick} />
                <StdTreeList
                    moduleName={this.moduleName}
                    treeRef={this.treeRef}
                    dataSource={areaStore}
                    columns={this.columns}
                    options={this.treeOptions}
                    keyName={"id"}
                    parentIdExpr={"parentId"}
                    popupWidth={400}
                    popupHeight={280}
                    formColCount={1} />
            </ContentBlock>
        );
    }

    renderAddItem(model: any) {
        return (
            <Item location={"after"} locateInMenu="auto">
                <Button
                    visible={this.permissions[enums.stdActions.create]}
                    disabled={!model || model.level === 4}
                    text="新增子地区"
                    onClick={() => this.onButtonClick(model, "add")} />
            </Item>
        );
    }
}