import { Popup } from "devextreme-react";
import React from "react";
import { RefPopup } from "../../components";
import { CpBatchRef } from "../../models";
import CpCheckBackPage from "./cp-check-back";

export default class CpCheckBackDialog extends CpCheckBackPage {
    popupRef = React.createRef<Popup>();

    constructor(props: any) {
        super(props);

        this.deferLoad = true;
    }

    show(batch: CpBatchRef) {
        this.popupRef.current?.instance.show();
        
        this.gridRef.current?.load(batch.id!);
        this.formRef.current?.setBatch(batch);
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }
    
    render() {
        return (
            <RefPopup           
                popupRef={this.popupRef} 
                title={"检回批次详情"}>
                {this.renderContent()}
            </RefPopup>
        );
    }
}