import { createStoreFromApi, http, BaseApiService } from "../base";
import { FillingCheckTypeModel, FillingCheckTypeRef } from "../models";

class FillingCheckTypeService extends BaseApiService<FillingCheckTypeModel> {
    moduleName = "fillingchecktype";
    cacheSeconds = 60;

    getRefs(type: string): Promise<FillingCheckTypeRef[]> {
        return this.getCached(() => 
            http.getData(`${this.apiPath}/refs?type=${type}`), this.cacheSeconds, false, type);
    }
}

export const fillingCheckTypeService = new FillingCheckTypeService();
export const fillingCheckTypeStore = createStoreFromApi(fillingCheckTypeService);