import { http, MessageBool, StringMap } from "../base";

class WxConfigService {
    apiPath = "/api/wxconfig";

    getImages(keys: string[]) {
        return http.getData<{ [key: string]: string[] }>(`${this.apiPath}/images?keys=${keys.join(',')}`);
    }

    setImages(images: { [key: string]: string[] }) {
        return http.putData<MessageBool>(`${this.apiPath}/images`, images);
    }

    getConfigs(keys: string[]) {
        return http.getData<StringMap>(`${this.apiPath}/configs?keys=${keys.join(',')}`);
    }

    setConfigs(configs: StringMap) {
        return http.putData<MessageBool>(`${this.apiPath}/configs`, configs);
    }
}

export const wxConfigService = new WxConfigService();