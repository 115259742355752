import { Button, DateBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import React from "react";
import { DataMap, dateAdd, enums } from "../../base";
import { AnnouncementModel } from "../../models";

export interface AnnouncementToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (model: AnnouncementModel, name: string) => void;
    onFiltering: (filterValue: any) => void;
}

interface AnnouncementToolbarState {
    model: AnnouncementModel;
    startDate?: Date | string;
    endDate?: Date | string;
}

export default class AnnouncementToolbar
    extends React.Component<AnnouncementToolbarProps, AnnouncementToolbarState> {
    constructor(props: Readonly<AnnouncementToolbarProps>) {
        super(props);

        this.state = {
            model: {},
        };

        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    setModel(model: AnnouncementModel) {
        this.setState({ model: model });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onFiltering() {
        const { startDate, endDate } = this.state;
        const filters: any[] = [];

        if (startDate) {
            filters.push(["createdAt", ">=", startDate]);
        }

        if (endDate) {
            filters.push(["createdAt", "<", dateAdd(new Date(endDate), 1)]);
        }

        this.props.onFiltering(filters.length > 0 ? filters : null);
    }

    render() {
        const { model, startDate, endDate } = this.state;
        const permissions = this.props.permissions;

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={startDate}
                            placeholder="开始时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.create]}>
                        <Button
                            text="新增"
                            onClick={() => this.props.onButtonClick({}, "add")} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions[enums.stdActions.update]}>
                        <Button
                            text="编辑"
                            disabled={!model?.id}
                            onClick={() => this.props.onButtonClick(model, "edit")} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"}>
                        <Button
                            text="查看阅读/签收详情"
                            disabled={!model?.id}
                            onClick={() => this.props.onButtonClick(model, "detail")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.delete]}>
                        <Button
                            text="删除"
                            disabled={!model?.id}
                            onClick={() => this.props.onButtonClick(model, "delete")} />
                    </Item>
                </Toolbar>
            </div>
        )
    }
}