import { createStoreFromApi, http, BaseApiService } from "../base";
import { FillingUserModel, FillingUserRef } from "../models";

class FillingUserService extends BaseApiService<FillingUserModel> {
    moduleName = "fillinguser";
    cacheSeconds = 60;

    async searchAll() {
        const users = await http.getData<FillingUserModel[]>(this.apiPath);
        users.forEach(user => user.deleted = user.deleted || false);
        return users;
    }

    getRefs(): Promise<FillingUserRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/refs`), this.cacheSeconds);
    }
}

export const fillingUserService = new FillingUserService();
export const fillingUserStore = createStoreFromApi(fillingUserService, "code");