import { createStoreFromApi, http, BaseApiService } from "../base";
import { AreaModel, AreaRef } from "../models";

class AreaService extends BaseApiService<AreaModel> {
    moduleName = "area";
    cacheSeconds = 1200;

    getRefs(): Promise<AreaRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/refs`), this.cacheSeconds, false, "areas");
    }

    getCounties(): Promise<AreaRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/counties`), this.cacheSeconds, false, "counties");
    }

    getStreets(): Promise<AreaRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/streets`), this.cacheSeconds, false, "streets");
    }
}

export const areaService = new AreaService();
export const areaStore = createStoreFromApi(areaService);