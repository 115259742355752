import React, { useCallback, useState } from "react";
import { baseApiUrl } from "../../base";
import { Tooltip } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";

export function createImageColumn(baseColumn: IColumnProps): IColumnProps {
    return {
        ...baseColumn,
        allowFiltering: false,
        cellRender: function(e: any) {
            if (e.rowType !== "data" || !e.data[baseColumn.dataField]) {
                return "无";
            }
            
            const fileNames: string[] = e.data[baseColumn.dataField].split(';').filter((s: string) => !!s);
            return (<ImagesComponent fileNames={fileNames} />);
        }
    }
}

export interface ImagesProps {
    fileNames: string[];
}

export function ImagesComponent({ fileNames }: ImagesProps) {
    const rndId = new Date().getTime();

    const [popoverToggles, setPopoverToggles] = useState<boolean[]>(fileNames.map(() => false));

    const onMouseEnter = useCallback((index: number) => {
        popoverToggles[index] = true;
        setPopoverToggles([...popoverToggles]);
    }, [popoverToggles, setPopoverToggles]);

    const onMouseLeave = useCallback((index: number) => {
        popoverToggles[index] = false;
        setPopoverToggles([...popoverToggles]);
    }, [popoverToggles, setPopoverToggles]);

    return (
        <div className="grid-images">
            {fileNames.map((n, index) => (
                <React.Fragment key={n} >
                    <img                
                        id={`i_${rndId}_${index}`} 
                        src={`${baseApiUrl}/api/file?name=${n}`} 
                        alt="" 
                        onMouseEnter={() => onMouseEnter(index)}
                        onMouseLeave={() => onMouseLeave(index)}
                        />
                    <Tooltip 
                        target={`#i_${rndId}_${index}`}
                        maxWidth={500}
                        maxHeight={500}
                        showTitle={false}
                        className="image-popover"
                        visible={popoverToggles[index]}>
                        <div className="pimage-container">
                            <img src={`${baseApiUrl}/api/file?name=${n}`} alt="" />
                        </div>
                    </Tooltip>
                </React.Fragment>
            ))}
        </div>
    );
}