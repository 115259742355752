import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React from "react";
import { sysObjectService } from "../../api";
import { operationLogService, operationLogStore } from "../../api/operation-log.service";
import { ContentBlock, createGridOptions, remoteReadOnlyGridOptions, setColumnDataSource, StdGrid } from "../../components";
import { SysObjectModel } from "../../models";
import AuthorizedComponent from "../authorized-component";
import OperationLogToolbar from "./operation-log-toolbar";
import { createEmptyStore } from "../../base";

interface OperationLogState {
    objs: SysObjectModel[];
    store: CustomStore;
    organizationId?: number;
    columns: IColumnProps[];
}

export default class OperationLogPage extends AuthorizedComponent<any, OperationLogState> {
    objectName = "OperationLog";
    moduleName = "OperationLog";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("operationLog", this.gridRef, remoteReadOnlyGridOptions);

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            objs: [],
            store: createEmptyStore(),
            columns: [
                {
                    dataField: "refType",
                    lookup: { dataSource: [], displayExpr: 'label', valueExpr: 'objectName' }
                },
                { dataField: "refName" },
                { dataField: "logTime", dataType: "datetime" },
                { dataField: "operatorName", allowFiltering: false },
                { dataField: "logEvent" },
                { dataField: "desc" }
            ]
        };

        this.filter = this.filter.bind(this);
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.loadObjects();
    }

    async loadObjects() {
        const objs = await sysObjectService.getAll();
        setColumnDataSource(this.state.columns, "refType", objs);
        this.setState({ objs: objs });
    }

    filter(filterValue: any, orgainzationId?: number) {
        if (orgainzationId) {
            const store = operationLogService.getDxData(orgainzationId);
            this.setState({ store: store, organizationId: orgainzationId }, () => this._filter(filterValue));
        } else if (filterValue) {
            this.setState({ store: operationLogStore, organizationId: undefined }, () => this._filter(filterValue));
        } else {
            this.setState({ store: createEmptyStore() });
        }
    }

    _filter(filterValue: any) {
        if (filterValue) {
            this.gridRef.current?.instance.filter(filterValue);
        }
    }

    render() {
        return (
            <ContentBlock caption="操作日志">
                <OperationLogToolbar onFiltering={this.filter} />
                <StdGrid
                    moduleName={this.moduleName}
                    gridRef={this.gridRef}
                    dataSource={this.state.store}
                    options={this.gridOptions}
                    columns={this.state.columns} />
            </ContentBlock>
        )
    }
}