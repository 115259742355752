import React from "react";
import { SelectionChangedEvent } from "devextreme/ui/tree_list";
import { IColumnProps, TreeList } from "devextreme-react/tree-list";
import { dataSources } from "../../base";
import { createBoolColumn, createTreeListOptions, stdReadOnlyTreeListOptions, StdTreeList } from "../../components";
import { OrganizationModel } from "../../models";

export interface OrganizationTreeListProps {
    organizations: OrganizationModel[];
    onButtonClick: (organization: OrganizationModel, name: string) => void;
    onOrganizationSelected: (organization?: OrganizationModel) => void;
}

export default class OrganizationTreeList extends React.Component<OrganizationTreeListProps> {
    moduleName = "Organization";
    treeRef = React.createRef<TreeList>();
    treeOptions = createTreeListOptions("organizations", this.treeRef, stdReadOnlyTreeListOptions);
    columns: IColumnProps[];

    constructor(props: Readonly<OrganizationTreeListProps>) {
        super(props);

        this.columns = [
            { dataField: 'name' },
            { dataField: 'sortNumber' },
            {
                dataField: 'type',
                lookup: { dataSource: Object.values(dataSources.organizationTypes), displayExpr: 'text', valueExpr: 'value' }
            },
            createBoolColumn({ dataField: "turnover" }),
            {
                dataField: 'parentId',
                lookup: { dataSource: props.organizations, displayExpr: 'name', valueExpr: 'id' },
            },
            { dataField: 'contact' },
            { dataField: 'phoneNumber' },
            { dataField: 'address' },
            { dataField: 'note' }
        ];


        this.treeOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.treeOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onOrganizationSelected(e.selectedRowsData[0]);
        } else {
            this.props.onOrganizationSelected({});
        }
    }

    render() {
        const orgIds = this.props.organizations.map(o => o.id);
        this.treeOptions.rootValue = this.props.organizations.find(o => !o.parentId) ?
            undefined:
            this.props.organizations.find(o => orgIds.indexOf(o.parentId) === -1)?.parentId;

        return (
            <StdTreeList
                moduleName={this.moduleName}
                treeRef={this.treeRef}
                dataSource={this.props.organizations}
                columns={this.columns}
                options={this.treeOptions}
                keyName={"id"}
                parentIdExpr={"parentId"}
                popupWidth={800}
                popupHeight={360} />
        );
    }
}