import React from "react";
import { Popup, ScrollView } from "devextreme-react";
import DataGrid, { IColumnProps, IDataGridOptions } from "devextreme-react/data-grid";
import { createBoolColumn, StdGrid, RefPopup, createImageColumn } from "../../components";
import { SiteCheckModel } from "../../models";
import { siteCheckService } from "../../api";

interface SiteCheckDetailState {
    siteCheck: SiteCheckModel;
}

export default class SiteCheckDetail extends React.Component<any, SiteCheckDetailState> {
    moduleName = "SiteCheckItem";

    popupRef = React.createRef<Popup>();
    gridRef = React.createRef<DataGrid>();
    gridOptions: IDataGridOptions = {
        columnAutoWidth: true,
        paging: { enabled: false }
    };
    
    columns: IColumnProps[] = [
        { dataField: "itemName" },
        createBoolColumn({ dataField: "hasDanger" }),
        createImageColumn({ dataField: "photo" })
    ];

    constructor(props: any) {
        super(props);

        this.state = { 
            siteCheck: {}
        };
    }

    async show(model: SiteCheckModel) {
        this.popupRef.current?.instance.show();

        const siteCheck = await siteCheckService.get(model.id!);
        this.setState({ siteCheck: siteCheck });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"站点检查详情"}
                width={800}
                height={600}>
                <ScrollView height={"100%"} scrollByContent={true}>
                    <StdGrid
                        moduleName={this.moduleName}
                        gridRef={this.gridRef}
                        dataSource={this.state.siteCheck.items || []}
                        columns={this.columns}
                        options={this.gridOptions}
                        autoHeight={true}
                    />
                </ScrollView>
            </RefPopup>
        );
    }
}