import { createStoreFromApi, http, BaseApiService } from "../base";
import { VehicleUserModel, VehicleUserRef } from "../models";

class VehicleUserService extends BaseApiService<VehicleUserModel> {
    moduleName = "vehicleuser";

    getRefs(): Promise<VehicleUserRef[]> {
        return http.getData(`${this.apiPath}/refs`);
    }
}

export const vehicleUserService = new VehicleUserService();
export const vehicleUserStore = createStoreFromApi(vehicleUserService, "id");