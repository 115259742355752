import './home.scss';
import { ContentBlock, FullPanel, Panel } from "../../components";
import React from 'react';
import { authorizationService, ListItem } from '../../base';
import Welcome from './welcome';
import { ScrollView, SelectBox, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/toolbar';
import HomeCount from './home-count';
import { HomeCountReport, HomeTrendReport } from '../../models';
import { homeReportService } from '../../api/reports';
import OrderTrend from './order-trend';
import CustomerTrend from './customer-trend';
import OrderSource from './order-source';
import NewCustomerType from './new-customer-type';
import ExitCustomerType from './exit-customer-type';

interface HomePageState {
    authorized: boolean;
    countType: string;
    trendType: string;
    countReport: HomeCountReport;
    trendReport: HomeTrendReport;
}

export default class HomePage extends React.Component<any, HomePageState> {
    constructor(props: any) {
        super(props);

        this.state = {
            authorized: false,
            countType: "day",
            trendType: "year",
            countReport: {},
            trendReport: {}
        };

        this.onCountTypeChanged = this.onCountTypeChanged.bind(this);
        this.onTrendTypeChanged = this.onTrendTypeChanged.bind(this);
    }

    async componentDidMount(): Promise<void> {
        const res = await authorizationService.authorize('GlobalPermission', 'HomePage');
        this.setState({ authorized: res });

        if (res) {
            this.loadCountReport();
            this.loadTrendReport();
        }
    }

    async loadCountReport() {
        const report = await homeReportService.getCountReport(this.state.countType);
        this.setState({ countReport: report });
    }

    async loadTrendReport() {
        const report = await homeReportService.getTrendReport(this.state.trendType);
        this.setState({ trendReport: report });
    }

    onCountTypeChanged(e: any) {
        if (e.value !== this.state.countType) {
            this.setState({
                countType: e.value,
                countReport: {}
            }, () => this.loadCountReport());
        }
    }

    onTrendTypeChanged(e: any) {
        if (e.value !== this.state.trendType) {
            this.setState({
                trendType: e.value,
                trendReport: {}
            }, () => this.loadTrendReport());
        }
    }

    render() {
        const { authorized, countType, trendType, countReport, trendReport } = this.state;
        return (
            <ContentBlock caption="首页">
                {authorized && (
                    <div className="home-content">
                        <div className="page-toolbar">
                            <Toolbar>
                                <Item location={"after"}>
                                    <SelectBox
                                        width={160}
                                        dataSource={countTypes}
                                        displayExpr="text"
                                        valueExpr={"value"}
                                        value={countType}
                                        onValueChanged={this.onCountTypeChanged} />
                                </Item>
                                <Item location={"after"}>
                                    <SelectBox
                                        width={160}
                                        dataSource={trendTypes}
                                        displayExpr="text"
                                        valueExpr={"value"}
                                        value={trendType}
                                        onValueChanged={this.onTrendTypeChanged} />
                                </Item>
                            </Toolbar>
                        </div>
                        <FullPanel>
                            <ScrollView width={"100%"} scrollByContent={true}>
                                <div className='home-row'>
                                    <HomeCount data={countReport.count} />
                                </div>
                                <div className='home-row'>
                                    <Panel caption="订单趋势">
                                        <OrderTrend trendType={trendType} data={trendReport.orderTrend} />
                                    </Panel>
                                </div>
                                <div className='home-row'>
                                    <Panel caption="客户趋势">
                                        <CustomerTrend trendType={trendType} data={trendReport.customerTrend} />
                                    </Panel>
                                </div>
                                <div className='home-row home-flex-row'>
                                    <Panel caption="开户（按类型）">
                                        <NewCustomerType data={countReport?.customerType} />
                                    </Panel>
                                    <Panel caption="退户（按类型）">
                                        <ExitCustomerType data={countReport?.customerType} />
                                    </Panel>
                                    <Panel caption="订单来源">
                                        <OrderSource data={countReport?.orderSource} />
                                    </Panel>
                                </div>
                            </ScrollView>
                        </FullPanel>
                    </div>
                )}
                {!authorized && (
                    <Welcome />
                )}
            </ContentBlock>
        )
    }
}

const countTypes: ListItem[] = [
    { value: 'day', text: '显示本日统计' },
    { value: 'month', text: '显示本月统计' },
    { value: 'year', text: '显示本年度统计' }
]

const trendTypes: ListItem[] = [
    { value: 'month', text: '显示30天趋势' },
    { value: 'year', text: '显示12个月趋势' }
]