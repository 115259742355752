import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React from "react";
import { employeeService } from "../../api";
import { employeeAppStatusService, employeeAppStatusStore } from "../../api/employee-app-status.service";
import { ContentBlock, createBoolColumn, createGridOptions, remoteReadOnlyGridOptions, setColumnDataSource, StdGrid } from "../../components";
import { EmployeeRef } from "../../models";
import AuthorizedComponent from "../authorized-component";
import EmployeeAppStatusToolbar from "./employee-app-status-toolbar";

interface EmployeeAppStatusState {
    employees: EmployeeRef[];
    columns: IColumnProps[];
    store: CustomStore;
    organizationId?: number;
}

export default class EmployeeAppStatusPage extends AuthorizedComponent<any, EmployeeAppStatusState> {
    objectName = "EmployeeAppStatus";
    moduleName = "EmployeeAppStatus";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("employeeAppStatus", this.gridRef, remoteReadOnlyGridOptions);

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            employees: [],
            store: employeeAppStatusStore,
            columns: [
                { dataField: "sessionId" },
                { 
                    dataField: "employeeId",
                    lookup: { dataSource: [], displayExpr: 'nameAndCode', valueExpr: 'id' }
                },
                { dataField: 'appVersion' },
                { dataField: 'loginTime', dataType: 'datetime' },
                { dataField: 'expiration', dataType: 'datetime' },
                createBoolColumn({ dataField: 'current'}),
                { dataField: 'codePrefix' }
            ]
        }

        this.filter = this.filter.bind(this);
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.loadData();
    }

    async loadData() {
        const employees = await employeeService.getRefs();
        setColumnDataSource(this.state.columns, "employeeId", employees);
        this.setState({ employees: employees });
    }

    filter(filterValue: any, orgainzationId?: number) {
        if (orgainzationId && this.state.organizationId !== orgainzationId) {
            const store = employeeAppStatusService.getDxData(orgainzationId);
            this.setState({ store: store, organizationId: orgainzationId }, () => this._filter(filterValue));
        } else if (!orgainzationId && this.state.store !== employeeAppStatusStore) {
            this.setState({ store: employeeAppStatusStore, organizationId: undefined }, () => this._filter(filterValue));
        } else {
            this._filter(filterValue);
        }
    }

    _filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    render() {
        return (
            <ContentBlock caption="APP在线情况">
                <EmployeeAppStatusToolbar onFiltering={this.filter} />
                <StdGrid
                    moduleName={this.moduleName}
                    gridRef={this.gridRef}
                    dataSource={this.state.store}
                    options={this.gridOptions}
                    columns={this.state.columns} />
            </ContentBlock>
        )
    }
}