import "./toolbar-date-filter.scss";
import { Button, ContextMenu, DateBox, TextBox } from "devextreme-react";
import { ValueChangedEvent } from "devextreme/ui/date_box";
import React from "react";
import { dateAdd, stdTimeFormat, timeAdd, today, alertWarning } from "../../base";

export interface ToolbarDateFilterEvent {
    startTime?: Date | string;
    endTime?: Date | string;
    code?: string;
    type: "date" | "code";
}

export interface ToolbarDateFilterProps {
    initInterval: number;
    maxInterval?: number;
    intervalType: string;
    dateType: "date" | "datetime";
    filterType: "date" | "code";
    disableSwitch?: boolean;
    onChanged: (e: ToolbarDateFilterEvent) => void;
    onFilterTypeChanged?: (e: ToolbarDateFilterEvent) => void;
}

interface ToolbarDateFilterState {
    startTime: Date;
    endTime: Date;
}

/**
 * 可限制必须填写的时间过滤器，最大的时间差不超过指定的数据
 */
export default class ToolbarDateFilter
    extends React.Component<ToolbarDateFilterProps, ToolbarDateFilterState> {
    filterItems: any[];

    constructor(props: Readonly<ToolbarDateFilterProps>) {
        super(props);

        this.filterItems = [
            {
                text: '使用日期过滤',
                icon: 'clock',
                onClick: () => {
                    setTimeout(() =>
                        props.onFilterTypeChanged && props.onFilterTypeChanged({
                            "type": "date",
                            startTime: this.state.startTime,
                            endTime: this.state.endTime
                        }), 0);
                }
            },
            {
                text: '使用钢瓶编号过滤',
                icon: 'find',
                onClick: () => {
                    setTimeout(() =>
                        props.onFilterTypeChanged && props.onFilterTypeChanged({
                            "type": "code"
                        }), 0);
                }
            }
        ];

        const etime = props.dateType === "datetime" ? timeAdd(dateAdd(today(), 1, 'd'), -1, 's') : today();
        const stime = dateAdd(etime, -props.initInterval, props.intervalType);

        this.state = {
            startTime: stime,
            endTime: etime
        };

        this.startDateChanged = this.startDateChanged.bind(this);
        this.endDateChanged = this.endDateChanged.bind(this);
        this.codeChanged = this.codeChanged.bind(this);
    }

    componentDidMount() {
        if (this.props.filterType === "date") {
            setTimeout(() =>
                this.props.onChanged({
                    "type": "date",
                    startTime: this.state.startTime,
                    endTime: this.state.endTime
                }), 0);
        }
    }

    startDateChanged(e: ValueChangedEvent) {
        let etime = this.state.endTime;
        const { maxInterval, intervalType, onChanged } = this.props;

        if (maxInterval) {
            const maxEndTime = dateAdd(e.value, maxInterval, intervalType);
            if (etime > maxEndTime) {
                etime = maxEndTime;
            }
        }

        this.setState({
            startTime: e.value,
            endTime: etime
        });

        onChanged({ type: "date", startTime: e.value, endTime: etime });
    }

    endDateChanged(e: ValueChangedEvent) {
        let stime = this.state.startTime;
        const { maxInterval, intervalType, onChanged } = this.props;

        if (maxInterval) {
            const minStartTime = dateAdd(e.value, -maxInterval, intervalType);
            if (stime < minStartTime) {
                stime = minStartTime;
            }
        }

        this.setState({
            startTime: stime,
            endTime: e.value
        });

        onChanged({ type: "date", startTime: stime, endTime: e.value });
    }

    codeChanged(e: any) {
        if (!e.value || e.value.trim().length !== 6) {
            alertWarning("请输入6位钢瓶编号");
            return;
        }

        this.props.onChanged({ type: "code", code: e.value });
    }

    render() {
        const { disableSwitch, filterType, dateType } = this.props;
        const { startTime, endTime } = this.state;

        return (
            <div className={"toolbar-date-filter"}>
                {
                    !disableSwitch && (
                        <Button id="gtb_filter" icon="filter"></Button>
                    )
                }
                {
                    filterType === "date" && (
                        <div>
                            <DateBox
                                type={dateType}
                                value={startTime}
                                displayFormat={stdTimeFormat}
                                onValueChanged={this.startDateChanged} />
                            <DateBox
                                type={dateType}
                                value={endTime}
                                displayFormat={stdTimeFormat}
                                onValueChanged={this.endDateChanged} />
                        </div>
                    )
                }
                {
                    filterType === "code" && (
                        <TextBox placeholder="请输入6位钢瓶编号" onValueChanged={this.codeChanged} />
                    )
                }
                <ContextMenu
                    items={this.filterItems}
                    displayExpr={"text"}
                    keyExpr={"value"}
                    position={{ at: "left bottom" }}
                    showEvent={{ name: "dxclick" }}
                    target={"#gtb_filter"} />
            </div>
        );
    }
}