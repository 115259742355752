import { RouteConfig } from "./route-model";
import BottlePage from "../pages/bottle/bottle";
import BottleFillingPage from "../pages/bottle/bottle-filling";
import BottleFlowPage from "../pages/bottle/bottle-flow";
import BottlePositionPage from "../pages/bottle/bottle-position";
import VehiclePage from "../pages/shipping/vehicle";
import VehicleUserPage from "../pages/shipping/vehicle-user";
import ShippingPage from "../pages/shipping/shipping";
import NewShippingPage from "../pages/shipping/new-shipping";
import FillingPrecheckPage from "../pages/bottle/filling-precheck";
import FillingRecheckPage from "../pages/bottle/filling-recheck";
import FillingSettingPage from "../pages/bottle/filling-setting";
import BottleRecycleLogPage from "../pages/bottle/bottle-recycle-log";

export const bottleRoutes: RouteConfig[] = [
    {
        path: '/bottle',
        component: BottlePage
    },
    {
        path: '/filling',
        component: BottleFillingPage
    },
    {
        path: "/bottle-flow",
        component: BottleFlowPage
    },
    {
        path: "/bottle-position",
        component: BottlePositionPage
    },
    {
        path: "/vehicle",
        component: VehiclePage
    },
    {
        path: '/vehicle-user',
        component: VehicleUserPage
    },
    {
        path: "/shipping-log",
        component: ShippingPage
    },
    {
        path: '/new-shipping',
        component: NewShippingPage
    },
    {
        path: '/filling-precheck',
        component: FillingPrecheckPage
    },
    {
        path: '/filling-recheck',
        component: FillingRecheckPage
    },
    {
        path: '/filling-setting',
        component: FillingSettingPage
    },
    {
        path: '/bottle-recycle-log',
        component: BottleRecycleLogPage
    }
]