import { Popup, Form } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import React from "react";
import { dataSourceService, fillingCheckTypeService, organizationService } from "../../api";
import { fillingPrecheckService } from "../../api/filling-precheck.service";
import { alertError, dataSources, enums, modelClone, ModuleConfig } from "../../base";
import { OrgTreeDropDown, PopupContentBox, RefPopup, setItemDataSource, StdForm } from "../../components";
import { FillingCheckTypeRef, FillingPrecheckModel, OrganizationRef } from "../../models";

export interface FillingPrecheckFormProps {
    onSaved: () => void;
}

interface FillingPrecheckFormState {
    types: FillingCheckTypeRef[];
    orgs: OrganizationRef[];
    reasons: string[];
    items: IItemProps[];
    model: FillingPrecheckModel;
}

export default class FillingPrecheckForm
    extends React.Component<FillingPrecheckFormProps, FillingPrecheckFormState> {
    moduleName: ModuleConfig = {
        moduleName: "FillingPrecheck",
        children: {
            "bottle": "Bottle"
        }
    };

    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();

    constructor(props: Readonly<FillingPrecheckFormProps>) {
        super(props);

        this.state = {
            types: [],
            orgs: [],
            reasons: [],
            items: [
                { dataField: "barcode", validationRules: [{ type: "required", message: "气瓶条码不可为空" }] },
                {
                    dataField: "organizationId",
                    render: () => (
                        <OrgTreeDropDown
                            organizationId={this.state.model.organizationId}
                            organizations={this.state.orgs}
                            onValueChanged={(organizationId) => {
                                this.state.model.organizationId = organizationId;
                                this.setState({});
                            }} />
                    )
                },
                { dataField: "checkTime", editorType: 'dxDateBox', editorOptions: { type: "datetime" } },
                { dataField: "checker" },
                {
                    dataField: "result",
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: dataSources.qualifiedOrNot, displayExpr: 'text', valueExpr: 'value' },
                    validationRules: [boolValidator]
                },
                { 
                    dataField: "reason",
                    editorType: 'dxSelectBox',
                    editorOptions: { 
                        acceptCustomValue: true,
                        dataSources: [] 
                    }
                },
                { dataField: "note" }
            ],
            model: { bottle: {} }
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let types: FillingCheckTypeRef[] = [], orgs: OrganizationRef[] = [], reasons: string[] = [];

        await Promise.all([
            (async () => orgs = await organizationService.getRefs())(),
            (async () => types = await fillingCheckTypeService.getRefs(enums.fillingCheckTypes.precheck))(),
            (async () => reasons = await dataSourceService.getDataSource(enums.dataSourceTypes.fillingPrecheckReasons))()
        ]);

        setItemDataSource(this.state.items, "reason", reasons);

        const items: IItemProps[] = [
            ...this.state.items.slice(0, 4),
            ...types.map(t => ({
                dataField: `item_${t.itemName}`,
                label: { text: t.itemName },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: t.displayType === enums.fillingCheckTypeValues.trueFalse ?
                        dataSources.trueFalseOfPass : dataSources.qualifiedOrNot,
                    displayExpr: 'text',
                    valueExpr: 'value'
                },
                validationRules: [boolValidator]
            })),
            ...this.state.items.slice(4)
        ]

        this.setState({
            types: types,
            orgs: orgs,
            reasons: reasons,
            items: items
        });
    }

    show(model: FillingPrecheckModel) {
        this.popupRef.current?.instance.show();

        if (model.reason && this.state.reasons.indexOf(model.reason) === -1) {
            setItemDataSource(this.state.items, "reason", [model.reason, ...this.state.reasons]);
        }

        this.setState({ model: this.setDefault(model) });
    }

    setDefault(model: FillingPrecheckModel) {
        model.checkTime = model.checkTime || new Date();
        const precheck: any = modelClone(model);

        // 默认为否，减少选择量
        for (const type of this.state.types) {
            precheck[`item_${type.itemName}`] = type.defaultValue;
        }

        for (const item of model.items || []) {
            precheck[`item_${item.itemName}`] = item.pass;
        }

        return precheck as FillingPrecheckModel;
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const model = this.state.model;
            model.items = model.items || [];
            const modelAny: any = model;

            for (const type of this.state.types) {
                const pass = modelAny[`item_${type.itemName}`];
                const item = this.state.model.items?.find(i => i.itemName === type.itemName);

                if (item) {
                    item.pass = pass;
                } else {
                    model.items.push({
                        precheckId: model.id,
                        itemName: type.itemName,
                        pass: pass
                    });
                }
            }

            const res = model.id ?
                await fillingPrecheckService.update(model) :
                await fillingPrecheckService.create(model);

            if (res.succeeded) {
                this.hide();
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"编辑"}
                width={800}
                height={560}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        formData={this.state.model} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}

const boolValidator = {
    type: "custom",
    message: "请选择",
    validationCallback: (e: any) => {
        return typeof (e.value) === "boolean";
    }
}
