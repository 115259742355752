import { Form, Popup } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import React from "react";
import { bottleRentService } from "../../api";
import { alertError, yearMonth, modelClone, ModuleConfig, notifySuccess, StringMap } from "../../base";
import { createPopupField, CustomerPopup, PopupContentBox, RefPopup, StdForm } from "../../components";
import { BottleRentModel, CustomerModel } from "../../models";

interface BottleRentWithName extends BottleRentModel {
    _customerName?: string;
}

export interface BottleRentFormProps {
    onSaved: () => void;
}

export interface BottleRentFormState {
    model: BottleRentWithName;
}

export default class BottleRentForm extends React.Component<BottleRentFormProps, BottleRentFormState> {
    moduleName: ModuleConfig = {
        moduleName: "BottleRent",
        children: {
            "customer": "Customer"
        }
    };

    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    customerRef = React.createRef<CustomerPopup>();
    nameMap: StringMap = { "_customerName": "customerId" };

    items: IItemProps[] = [
        createPopupField("_customerName", this.customerRef),
        { 
            dataField: "startTime", 
            editorType: 'dxTextBox', 
            editorOptions: { placeholder: "格式：YYYYMM，如：202202" },
            validationRules: [{ type: 'pattern', message: "格式：YYYYMM，如：202202", pattern: "^20\\d{2}(10|11|12|0\\d)$" }] 
        },
        { 
            dataField: "endTime", 
            editorType: 'dxTextBox',
            editorOptions: { placeholder: "格式：YYYYMM，如：202202" },
            validationRules: [{ type: 'pattern', message: "格式：YYYYMM，如：202202", pattern: "^20\\d{2}(10|11|12|0\\d)$" }] 
        },
        { dataField: "amount" },
        // { dataField: "operator" },
        { dataField: "chargeTime", editorOptions: { type: 'datetime' } },
        { dataField: "note" }
    ]

    constructor(props: Readonly<BottleRentFormProps>) {
        super(props);

        this.state = {
            model: {}
        };

        this.onCustomerSelected = this.onCustomerSelected.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    show(model: BottleRentModel) {
        this.popupRef.current?.instance.show();

        this.setState({ model: modelClone(model) });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async onCustomerSelected(customer: CustomerModel) {
        const endTime = yearMonth(new Date());
        const model: BottleRentWithName = {
            ...this.state.model,
            customer: customer,
            customerId: customer.id,
            _customerName: `${customer.name}（${customer.gasCode}）`,
            startTime: customer.rentEndTime,
            endTime: !customer.rentEndTime || customer.rentEndTime < endTime ? endTime : undefined
        };

        this.setState({ model: model });
    }

    async save() {
        const valid = this.formRef.current?.instance.validate();

        if (valid?.isValid) {
            const result = await bottleRentService.create(this.state.model);

            if (result.succeeded) {
                notifySuccess("已成功保存数据");
                this.hide();
                this.props.onSaved();
            } else {
                alertError(result.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"添加钢瓶使用费"}
                width={800}
                height={300}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.items}
                        nameMap={this.nameMap}
                        formData={this.state.model} />
                </PopupContentBox>
                <CustomerPopup ref={this.customerRef} onCustomerSelected={this.onCustomerSelected} />
            </RefPopup>
        )
    }
}