import React from "react";
import SiteCheckGrid from "./site-check-grid";
import SiteCheckToolbar from "./site-check-toolbar";
import SiteCheckDetail from "./site-check-detail";
import AuthorizedComponent from "../authorized-component";
import { ContentBlock } from "../../components";
import { enums, alertError, confirm2, notifySuccess, baseApiUrl, download } from "../../base";
import { siteCheckService } from "../../api";
import { SiteCheckModel } from "../../models";

export default class SiteCheckPage extends AuthorizedComponent {
    objectName = "SiteCheck";
    gridRef = React.createRef<SiteCheckGrid>();
    toolbarRef = React.createRef<SiteCheckToolbar>();
    detailRef = React.createRef<SiteCheckDetail>();

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onRowChecked = this.onRowChecked.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    onModelSelected(model: SiteCheckModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onRowChecked(models: SiteCheckModel[]) {
        this.toolbarRef.current?.setModels(models);
    }

    onFiltering(filterValue: any, showDeleted: boolean) {
        this.gridRef.current?.filter(filterValue, showDeleted);
    }

    onButtonClick(model: SiteCheckModel, name: string) {
        switch (name) {
            case "detail":
                this.detailRef.current?.show(model);
                break;

            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(model);
                }
                break;

            case "restore":
                if (this.permissions[enums.stdActions.delete]) {
                    this.restore(model);
                }
                break;
        }
    }

    async onContextButtonClick(models: SiteCheckModel[], name: string) {
        switch (name) {
            case "export":
                const type = models[0].type;
                if (models.find(m => m.type !== type)) {
                    alertError("不支持同时导出公司检查和站点自检，因模板不同");
                    return;
                }

                if (!await confirm2("确定要导出选中的安全检查单吗？")) {
                    return;
                }

                const ids = models.map(m => m.id);
                const url = `${baseApiUrl}${siteCheckService.apiPath}/export/${type?.toLowerCase()}?ids=${ids.join(',')}`;
                download(url, type === enums.siteCheckType.selfCheck ? '站点安全自检单.xls' : '站点安全检查单.xls');
                break;
        }
    }
    
    async delete(model: SiteCheckModel) {
        if (!await confirm2("确定要删除此数据吗？")) {
            return;
        }

        const result = await siteCheckService.delete(model.id!);
        
        if (result.succeeded) {
            notifySuccess("已成功作废");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async restore(model: SiteCheckModel) {
        if (!await confirm2("确定要还原此数据吗？")) {
            return;
        }

        const result = await siteCheckService.restore(model.id!);
        
        if (result.succeeded) {
            notifySuccess("已成功还原");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    render() {
        return (
            <ContentBlock caption={"站点安全检查"}>
                <SiteCheckToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onButtonClick={this.onButtonClick}
                    onContextButtonClick={this.onContextButtonClick}
                    onFiltering={this.onFiltering} />
                <SiteCheckGrid 
                    ref={this.gridRef} 
                    onModelSelected={this.onModelSelected} 
                    onRowChecked={this.onRowChecked}
                    onButtonClick={this.onButtonClick} />
                <SiteCheckDetail ref={this.detailRef} />
            </ContentBlock>
        )
    }
}