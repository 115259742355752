import React from "react";
import { useFavorite } from "../../hooks";
import './content-block.scss';

export interface ContentBlockProps {
    caption: string;
    children: any;
    hideLines?: boolean;
}

export default function  ContentBlock(props: ContentBlockProps) {
    const { setPageCaption } = useFavorite();
    
    // 刷新时Provider还未就绪，延时执行避免出错
    setTimeout(() => setPageCaption!(props.caption), 0);

    return (
        <React.Fragment>
            <div className={props.hideLines ? "" : "grid-container"}>
                {props.children}
            </div>
        </React.Fragment>
    )
}