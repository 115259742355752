import { IColumnProps } from "devextreme-react/tree-list";
import React from "react";
import { StdGrid } from "../grid";
import { bottlePositionService } from "../../api";
import { BriefBottlePosition } from "../../models";
import { IDataGridOptions } from "devextreme-react/data-grid";

export interface CustomerBottleProps {
    id?: number;
}

interface CustomerBottleState {
    bottles: BriefBottlePosition[];
}

export default class CustomerBottle extends React.Component<CustomerBottleProps, CustomerBottleState> {
    moduleName = "BottleFlow";

    gridOptions: IDataGridOptions = {
        columnAutoWidth: true,
        scrolling: { showScrollbar: "never" },
    };

    columns: IColumnProps[] = [
        { dataField: "bottle.barCode", caption: "钢瓶条码" },
        { dataField: "bottle.bottleType.name", caption: "类型" },
        { dataField: "createdAt", dataType: "datetime", caption: "扫描时间" }
    ];

    constructor(props: Readonly<CustomerBottleProps>) {
        super(props);

        this.state = {
            bottles: []
        };
    }

    componentDidUpdate(prevProps: CustomerBottleProps) {
        if (this.props.id && this.props.id !== prevProps.id) {
            this.loadData();
        } else if (!this.props.id && prevProps.id) {
            this.setState({ bottles: [] });
        }
    }

    async loadData() {
        const data = await bottlePositionService.searchByCustomerId(this.props.id!);
        this.setState({ bottles: data });
    }

    render() {
        return (
            <StdGrid 
                moduleName={this.moduleName}
                dataSource={this.state.bottles} 
                columns={this.columns}  
                options={this.gridOptions} 
                autoHeight={true} />
        )
    }
}