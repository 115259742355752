import React from "react";
import { ExportingEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { ImagesComponent, StdGrid, createGridOptions, remoteReadOnlyGridOptions, setColumnDataSource, ExportModel } from "../../components";
import { customerTypeService, dangerService } from "../../api";
import { DataMap, ModuleConfig, StringMap, enums, dataSources, authorizationService, baseApiUrl, globalPopupManager, globalPopupNames, createEmptyStore } from "../../base";
import { CustomerTypeRef, DangerBatchModel, DangerModel } from "../../models";
import CustomStore from "devextreme/data/custom_store";

export interface DangerGridProps {
    permissions: DataMap<boolean>;
    onDangerSelected: (danger: DangerModel) => void;
    onRowChecked: (dangers: DangerBatchModel[]) => void;
    onButtonClick: (danger: DangerModel, name: string) => void;
}

interface DangerGridState {
    customerTypes: CustomerTypeRef[];
    columns: IColumnProps[];
    store: CustomStore;
    showDeleted: boolean;
    key: string;
}

export default class DangerPage extends React.Component<DangerGridProps, DangerGridState> {
    objectName = "Danger";
    moduleName: ModuleConfig = {
        moduleName: "Danger",
        children: {
            customer: 'Customer',
            handler: 'Employee',
            organization: 'Organization'
        }
    };

    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("dangers", this.gridRef, remoteReadOnlyGridOptions);

    nameMap: StringMap = { 
        "customer.name": "customerId",
        "organization.name": "organizationId"
    };

    constructor(props: Readonly<DangerGridProps>) {
        super(props);

        this.state = {
            customerTypes: [],
            showDeleted: false,
            key: '',
            store: createEmptyStore(),
            columns: [
                { dataField: 'checker' },
                {
                    dataField: 'customer.name',
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.name}
                        </span>
                    )
                },
                {
                    dataField: 'customer.gasCode',
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.gasCode}
                        </span>
                    )
                },
                {
                    dataField: 'customer.typeId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                {
                    dataField: 'customer.status',
                    lookup: { dataSource: dataSources.customerStatus, displayExpr: 'text', valueExpr: 'value' }
                },
                {
                    dataField: 'customer.locked',
                    trueText: "锁定",
                    falseText: "正常",
                    cellRender: (e: any) => {
                        if (e.rowType !== "data") {
                            return "";
                        }

                        const locked = !!e.data.customer.locked,
                            text = locked ? "锁定" : "正常",
                            buttonText = locked ? "解锁" : "锁定";

                        return (
                            <React.Fragment>
                                {text} &nbsp;
                                {this.props.permissions["LockCustomer"] && (
                                    <span
                                        className="link-button"
                                        onClick={() => this.props.onButtonClick(e.data, "switchLock")}>
                                        {buttonText}
                                    </span>
                                )}
                            </React.Fragment>
                        );
                    }
                },
                { dataField: 'customer.lockNote' },
                {
                    caption: '送气工/安检员',
                    dataField: 'handler.name',
                    calculateFilterExpression: function(filterValue: any, op: string) {
                        return [
                            ["handler.name", op, filterValue],
                            "or",
                            ["handler.code", op, filterValue]
                        ]
                    },
                    cellRender: (e: any) => (<span>{e.data.handler?.nameAndCode}</span>)
                },
                { dataField: 'phoneNumber' },
                { dataField: 'address' },
                {
                    dataField: 'organization.name',
                    allowFiltering: false,
                },
                { dataField: 'checkTime', dataType: 'datetime' },
                { dataField: 'expirationDate', dataType: 'date' },
                {
                    dataField: 'dangerLevel',
                    lookup: { dataSource: dataSources.dangerLevels, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: 'fixTime', dataType: 'datetime' },
                { 
                    dataField: 'fixStatus',
                    allowFiltering: false,
                    allowSorting: false,
                    allowGrouping: false,
                    cellRender: (e) => (
                        <div>
                            {e.data.fixed ? 
                            enums.dangerFixStatus.fixed : 
                            e.data.fixStatus === enums.dangerFixStatus.fail ? 
                            enums.dangerFixStatus.fail : 
                            enums.dangerFixStatus.unfixed}
                        </div>
                    )
                },
                {
                    dataField: 'auditStatus',
                    lookup: { dataSource: dataSources.dangerAuditStatus, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: 'note' },
                this.createImageColumn({ dataField: 'customerFixSign' }, 'customerSign'),
                this.createImageColumn({ dataField: 'userFixSign' }, 'checkerSign'),
                { dataField: 'updatedAt', dataType: 'datetime' }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "detail");
        this.gridOptions.onExporting = this.onExporting.bind(this);
        this.gridOptions.export!.allowExportSelectedData = false;
        this.gridOptions.export!.enabled = false;
    }

    createImageColumn(baseColumn: IColumnProps, alterField: string): IColumnProps {
        return {
            ...baseColumn,
            allowFiltering: false,
            cellRender: function(e: any) {
                if (!e.data.fixed && e.data.fixStatus !== enums.dangerFixStatus.fail) {
                    return "";
                } else if (e.rowType !== "data" || (!e.data[baseColumn.dataField] && !e.data[alterField])) {
                    return "无";
                }
                
                const fileNames: string[] = (e.data[baseColumn.dataField] || e.data[alterField])
                    .split(';').filter((s: string) => !!s);
                    
                return (<ImagesComponent fileNames={fileNames} />);
            }
        }
    } 

    componentDidMount() {
        this.authorizeImport();
        this.loadData();
    }

    async authorizeImport() {
        if (await authorizationService.authorize(this.objectName, enums.stdActions.export)) {
            this.gridOptions.export!.enabled = true;
            this.gridRef.current?.instance.option("export.enabled", true);
        }
    }

    async loadData() {
        let types = await customerTypeService.getRefs();
        setColumnDataSource(this.state.columns, "customer.typeId", types);

        this.setState({
            customerTypes: types
        });
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onDangerSelected(e.selectedRowsData[0]);
        } else {
            this.props.onDangerSelected({});    // 为避免混乱，多选不能编辑
        }

        this.props.onRowChecked(e.selectedRowsData.map((d: DangerModel) => ({ id: d.id!, deleted: !d.deleted })));
    }

    onExporting(e: ExportingEvent) {
        const args: ExportModel = {
            moduleName: this.moduleName.moduleName,
            exportUrl: `${baseApiUrl}${dangerService.apiPath}/export?key=${this.state.key}`,
            columns: this.state.columns,
            keys: e.component.getSelectedRowKeys(),
            filter: e.component.getCombinedFilter(),
            showDeleted: this.state.showDeleted
        };

        e.cancel = true;
        globalPopupManager.show(globalPopupNames.exportPopup, args);
    }
    
    refresh() {
        return this.gridRef.current?.instance.refresh();
    }

    filter(filterValue: any, showDeleted: boolean, key: string) {
        if (showDeleted !== this.state.showDeleted || key !== this.state.key || "id" !== this.state.store.key as any) {
            this.setState({
                showDeleted: showDeleted,
                key: key,
                store: showDeleted ? dangerService.getDeletedDxData(key) : dangerService.getDxData(key)
            }, () => this._filter(filterValue));
        } else {
            this._filter(filterValue);
        }
    }

    _filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    getSelectedDanger() {
        return this.gridRef.current?.instance.getSelectedRowsData()[0];
    }

    clearSelection() {
        this.gridRef.current?.instance.clearSelection();
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns}
                columnNameMap={this.nameMap}
                toolbarHeight={80}>
            </StdGrid>
        );
    }
}