import React, { PropsWithChildren } from "react";
import DataGrid, { Column, IColumnProps, IDataGridOptions } from "devextreme-react/data-grid";
import { useGridHeight, useGridColumnFill, useScreenSize } from "../../hooks";
import { ModuleConfig, StringMap } from "../../base";
import { setGridPopupOptions } from "../options";

export interface StdGridProps {
    moduleName: string | ModuleConfig;
    gridRef?: React.LegacyRef<DataGrid>;
    dataSource: any;
    options: IDataGridOptions;
    columns: IColumnProps[];
    columnNameMap?: StringMap;
    autoHeight?: boolean;
    popupWidth?: number;
    popupHeight?: number;
    formColCount?: number;
    toolbarHeight?: number;
}

/**
 * 使用该类以使用系统公共设置；
 * 这里应使用Column子元素而不是columns属性（不更新）
 */
export default function StdGrid(props: PropsWithChildren<StdGridProps>) {
    let height = useGridHeight();
    const fill = useGridColumnFill();
    const screenSize = useScreenSize();

    fill(props.moduleName, props.columns, props.columnNameMap);
    setGridPopupOptions(props.options, props.popupWidth, props.popupHeight, screenSize);

    if (height && props.toolbarHeight) {
        height = height + 40 - props.toolbarHeight;
    }

    if (props.formColCount && props.options.editing) {
        props.options.editing.form = {
            ...(props.options.editing.form || {}),
            colCount: props.formColCount
        };
    } else if (!props.formColCount && props.options.editing?.form) {
        props.options.editing.form.colCount = undefined;
    }

    return (
        <DataGrid 
            ref={props.gridRef} 
            dataSource={props.dataSource}
            height={(props.autoHeight ? undefined: height)}
            {...props.options}>
            {
                props.columns.map(c => (
                    <Column key={c.dataField||c.name} {...c} />
                ))
            }
            { props.children }
        </DataGrid>
    );
}