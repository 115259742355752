import React from "react";
import NewShippingGrid from "./new-shipping-grid";
import NewShippingToolbar from "./new-shipping-toolbar";
import { shippingService } from "../../api";
import { alertError, alertWarning, notifySuccess } from "../../base";
import { ContentBlock, VehicleData, VehiclePopup } from "../../components";

export default class NewShippingPage extends React.Component<any> {
    popupRef = React.createRef<VehiclePopup>();
    gridRef = React.createRef<NewShippingGrid>();
    toolbarRef = React.createRef<NewShippingToolbar>();
    fillingIds: number[] = [];
    organizationId?: number;

    constructor(props: any) {
        super(props);

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.save = this.save.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
    }

    onSelectionChanged(fillingIds: number[]) {
        this.toolbarRef.current?.setModel(fillingIds);
    }

    onFiltering(filterValue: any) {
        this.gridRef.current?.filter(filterValue);
    }

    onOrganizationChanged(organizationId?: number) {
        this.organizationId = organizationId;
    }

    onButtonClick(fillingIds: number[], name: string) {
        switch (name) {
            case "add":
                this.add(fillingIds);
                break;
        }
    }

    add(fillingIds: number[]) {
        if (fillingIds.length === 0) {
            alertWarning("请选择充装记录");
            return;
        }

        this.fillingIds = fillingIds;
        this.popupRef.current?.show(this.organizationId);
    }

    async save(vehicle: VehicleData) {
        const data = {
            vehicleId: vehicle.id,
            driver: vehicle.driver,
            supercargo: vehicle.supercargo,
            organizationId: this.organizationId,
            items: this.fillingIds.map(id => ({ fillingId: id }))
        };

        const result = await shippingService.create(data);

        if (result.succeeded) {
            notifySuccess("已成功创建运输记录，请勿重复添加");
            this.popupRef.current?.hide();
            this.toolbarRef.current?.reset();
            this.gridRef.current?.clear();
        } else {
            alertError(result.message!);
        }
    }

    render() {
        return (
            <ContentBlock caption={"添加运输记录"}>
                <NewShippingToolbar
                    ref={this.toolbarRef}
                    onOrganizationChanged={this.onOrganizationChanged}
                    onFiltering={this.onFiltering}
                    onButtonClick={this.onButtonClick} />
                <NewShippingGrid 
                    ref={this.gridRef} 
                    onSelectionChanged={this.onSelectionChanged} />
                <VehiclePopup ref={this.popupRef} onSelected={this.save} />
            </ContentBlock>
        )
    }
}