import { DataMap } from "./models";
import { tokenService } from "./token.service"

class AuthorizationService {
    async authorize(objectName: string, actionName: string) {
        const user = await tokenService.getUser();

        if (!user) {
            return false;
        } else if (user.isAdmin) {
            return true;
        }

        const claimValue = `${objectName}.${actionName}`.toLowerCase();
        const claim = user.claims.find(c => c.toLowerCase() === claimValue);
        return !!claim;
    }

    async authorizeAll(objectName: string, actionNames: string[]) {
        const result: DataMap<boolean> = {};

        for (const actionName of actionNames) {
            result[actionName] = await this.authorize(objectName, actionName);
        }

        return result;
    }
}

export const authorizationService = new AuthorizationService();