import { DataMap, http } from "../../base";
import { GasStocktakingModel, StocktakingReportCondition } from "../../models";

class GasStocktakingReportService {
    apiPath = "/api/report/gasstocktaking";

    get(condition: StocktakingReportCondition) {
        return http.getData<DataMap<GasStocktakingModel>>(`${this.apiPath}`, condition);
    }
}

export const gasStocktakingReportService = new GasStocktakingReportService();