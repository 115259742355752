import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import React from "react";
import { dataSourceService, trainingDeletedStore, trainingStore } from "../../api";
import { enums, ModuleConfig, StringMap } from "../../base";
import { createGridOptions, remoteReadOnlyGridOptions, setColumnDataSource, StdGrid } from "../../components";
import { TrainingModel } from "../../models";

export interface TrainingGridProps {
    onModelSelected: (model: TrainingModel) => void;
    onRowChecked: (ids: number[]) => void;
    onButtonClick: (model: TrainingModel, name: string) => void;
}

interface TrainingGridState {
    types: string[];
    methods: string[];
    store: CustomStore;
    columns: IColumnProps[];
}

export default class TrainingGrid extends React.Component<TrainingGridProps, TrainingGridState> {
    moduleName: ModuleConfig = {
        moduleName: "Training",
        children: {
            "organization": "Organization"
        }
    };
    
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions(
        "trainings", 
        this.gridRef, 
        remoteReadOnlyGridOptions, 
        (items: any[]) => {
            // state清空会同时清空filter，需要重新绑定旧的
            items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
            return items;
        });

    nameMap: StringMap = { "organization.name": "organizationId" }

    constructor(props: Readonly<TrainingGridProps>) {
        super(props);

        this.state = {
            types: [],
            methods: [],
            store: trainingStore,
            columns: [
                { dataField: "organization.name" },
                { dataField: "date" },
                { dataField: "caption" },
                { dataField: "type", lookup: { dataSource: [] } },
                { dataField: "method", lookup: { dataSource: [] } },
                { dataField: "address" }
            ]
        }

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = (e) => this.props.onButtonClick(e.data, "edit");
    }
    
    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let types: string[] = [], methods: string[] = [];

        await Promise.all([
            (async () => types = await dataSourceService.getDataSource(enums.dataSourceTypes.trainingTypes))(),
            (async () => methods = await dataSourceService.getDataSource(enums.dataSourceTypes.trainingMethods))(),
        ]);

        setColumnDataSource(this.state.columns, "type", types);
        setColumnDataSource(this.state.columns, "method", methods);

        this.setState({
            types: types,
            methods: methods
        });
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});    // 为避免混乱，多选不能编辑
        }

        this.props.onRowChecked(e.selectedRowKeys);
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }
    
    filter(filterValue: any, showDeleted: boolean) {
        if (showDeleted && this.state.store !== trainingDeletedStore) {
            this.setState({ store: trainingDeletedStore }, () => this._filter(filterValue));
        } else if (!showDeleted && this.state.store !== trainingStore) {
            this.setState({ store: trainingStore }, () => this._filter(filterValue))
        } else {
            this._filter(filterValue);
        }
    }

    _filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns}
                columnNameMap={this.nameMap}>
            </StdGrid>
        );
    }
}