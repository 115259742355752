import { Form, Popup } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { ValueChangedEvent as TextChangedEvent } from "devextreme/ui/text_box";
import React from "react";
import { bottleService, cpBatchService, cpDataSourceService } from "../../api";
import { cpScrapService } from "../../api/cp-scrap.service";
import { alertError, enums, mergeModel, modelClone, notifySuccess } from "../../base";
import { PopupContentBox, RefPopup, setItemDataSource, StdForm } from "../../components";
import { CpBatchRef, CpDataSourceRef, CpScrapModel } from "../../models";

export interface CpScrapFormProps {
    onSaved: () => void;
}

interface CpScrapFormState {
    model: CpScrapModel;
    items: IItemProps[];
    batches: CpBatchRef[];
    checkers: CpDataSourceRef[];
    organizations: CpDataSourceRef[];
}

export default class CpScrapForm extends React.Component<CpScrapFormProps, CpScrapFormState> {
    moduleName = "CpScrap";
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    batchId?: number;

    constructor(props: Readonly<CpScrapFormProps>) {
        super(props);

        this.state = {
            model: {},
            batches: [],
            checkers: [],
            organizations: [],
            items: [
                {
                    dataField: "batchId",
                    editorType: "dxSelectBox",
                    editorOptions: {
                        dataSource: [],
                        displayExpr: 'batchCode',
                        valueExpr: 'id',
                        onValueChanged: this.onBatchChanged.bind(this)
                    }
                },
                {
                    dataField: "code",
                    editorOptions: {
                        onValueChanged: this.onCodeChanged.bind(this)
                    }
                },
                { dataField: "bottleType" },
                { dataField: "vendor" },
                { dataField: "produceDate", editorType: "dxDateBox" },
                { dataField: "enterDate", editorType: "dxDateBox" },
                { dataField: "scrapDate", editorType: "dxDateBox" },
                {
                    dataField: "checkerId",
                    editorType: "dxSelectBox",
                    editorOptions: { dataSource: [], displayExpr: 'text', valueExpr: 'id' }
                },
                {
                    dataField: "organizationId",
                    editorType: "dxSelectBox",
                    editorOptions: { dataSource: [], displayExpr: 'text', valueExpr: 'id' },
                    validationRules: [{ type: "required", message: "站点不能为空" }]
                },
                { dataField: "note", colSpan: 2 }
            ]
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount(): void {
        this.loadData();
    }

    async loadData() {
        let batches: CpBatchRef[] = [],
            checkers: CpDataSourceRef[] = [],
            organizations: CpDataSourceRef[] = [];

        await Promise.all([
            (async () => batches = await cpBatchService.getRefs(enums.cpBatchTypes.scrap))(),
            (async () => checkers = await cpDataSourceService.getDataSource(enums.cpDataSourceTypes.checker))(),
            (async() => organizations = await cpDataSourceService.getDataSource(enums.cpDataSourceTypes.organization))()
        ]);

        setItemDataSource(this.state.items, "batchId", batches);
        setItemDataSource(this.state.items, "checkerId", checkers);
        setItemDataSource(this.state.items, "organizationId", organizations);

        this.setState({
            batches: batches,
            checkers: checkers,
            organizations: organizations
        });
    }

    async onBatchChanged(e: ValueChangedEvent) {
        const model = this.state.model;

        if (e.value && e.event) {   // 仅手工事件触发
            const template = await cpScrapService.getTemplate(e.value);
            mergeModel(model, template);
            this.setState({ model: model });
        }
    }

    async onCodeChanged(e: TextChangedEvent) {
        const model = this.state.model;

        if (e.value && e.value.length === 6 && e.event) {   // 仅手工事件触发
            const bottle = await bottleService.publicGetByCode(e.value);

            if (bottle) {
                model.bottleType = bottle.bottleType?.name || bottle.typeCode;
                model.vendor = bottle.vendor;
                model.produceDate = bottle.produceDate;
                model.enterDate = bottle.enterDate;

                this.setState({ model: model });
            } else {
                alertError("找不到指定的钢瓶编号");
            }
        }
    }

    setBatchId(batchId: number) {
        const item = this.state.items.find(i => i.dataField === "batchId");
        item!.editorOptions.readOnly = true;
        setItemDataSource(this.state.items, "batchId", this.state.batches.filter(b => b.id === batchId));

        this.batchId = batchId;

        this.setState({ items: this.state.items });
    }

    async show(model: CpScrapModel) {
        this.popupRef.current?.instance.show();

        const item = setItemDataSource(this.state.items, "batchId",
            model.batch ? [model.batch] : 
            this.batchId ? [this.state.batches.find(b => b.id === this.batchId)] : this.state.batches);
        item.editorOptions.readOnly = !!model.batch || !!this.batchId;

        model = modelClone(model);

        if (this.batchId && !model.id) {
            model.batchId = this.batchId;
            const template = await cpScrapService.getTemplate(this.batchId);
            mergeModel(model, template);
        }

        this.setState({ model: model });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const model = this.state.model;
            model.checker = this.state.checkers.find(v => v.id === model.checkerId)?.text;
            model.organization = this.state.organizations.find(o => o.id === model.organizationId)?.text;

            const res = model.id ?
                await cpScrapService.update(model) :
                await cpScrapService.create(model);

            if (res.succeeded) {
                this.hide();
                notifySuccess("已成功保存");
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"编辑"}
                width={800}
                height={400}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        formData={this.state.model} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}