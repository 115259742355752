import "./panel.scss";
import { PropsWithChildren } from "react";

export interface PanelProps {
    caption?: string;
    noBodyPadding?: boolean;
    height?: any;
}

export default function Panel(props: PropsWithChildren<PanelProps>) {
    return (
        <div className={"dx-card custom-panel"} style={{ height:props.height || "auto" }}>
            <div className={"custom-panel-title"}>
                {props.caption}
            </div>
            <div className={`custom-panel-body ${props.noBodyPadding ? "" : "custom-panel-padding"}`}>
                {props.children}
            </div>
        </div>
    );
}