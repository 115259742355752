import React from "react";
import AuthorizedComponent from "../authorized-component";
import EmployeeGrid from "./employee-grid";
import EmployeeForm from "./employee-form";
import EmployeeToolbar from "./employee-toolbar";
import { alertError, confirm2, enums, notifySuccess, prompt2 } from "../../base";
import { AssignEmployeePopup, AssignOrganizationPopup, ContentBlock, EmployeeRolePopup, EmployeeSharePopup, MutualSharePopup } from "../../components";
import { EmployeeModel, EmployeeRef, OrganizationRef } from "../../models";
import { employeeService } from "../../api";

export default class EmployeePage extends AuthorizedComponent {
    objectName = "Employee";
    popupRef = React.createRef<EmployeeRolePopup>();
    toolbarRef = React.createRef<EmployeeToolbar>();
    gridRef = React.createRef<EmployeeGrid>();
    formRef = React.createRef<EmployeeForm>();
    shareRef = React.createRef<EmployeeSharePopup>();
    mutualRef = React.createRef<MutualSharePopup>();
    orgRef = React.createRef<AssignOrganizationPopup>();
    employeeRef = React.createRef<AssignEmployeePopup>();
    idsForMove?: string[];
    employeeForTransfer?: EmployeeModel;

    get actionNames(): string[] {
        return [
            enums.stdActions.read,
            enums.stdActions.create,
            enums.stdActions.update,
            enums.stdActions.delete,
            "EditRole",
            "EditShare",
            "SetPassword",
            "Move",
            "TransferCustomers"
        ]
    }

    constructor(props: any) {
        super(props);

        this.onEmployeeSelected = this.onEmployeeSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onMutualShare = this.onMutualShare.bind(this);
        this.onSaved = this.onSaved.bind(this);
        this.filter = this.filter.bind(this);
        this.onRoleChanged = this.onRoleChanged.bind(this);
        this.moveEmployee = this.moveEmployee.bind(this);
        this.transferCustomers = this.transferCustomers.bind(this);
    }

    onEmployeeSelected(models: EmployeeModel[]) {
        this.toolbarRef.current?.setModels(models);
    }

    onButtonClick(model: EmployeeModel, name: string) {
        switch (name) {
            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.formRef.current?.show({});
                }
                break;

            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.formRef.current?.show(model);
                }
                break;

            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(model);
                }
                break;

            case "share":
                if (this.permissions["EditShare"]) {
                    this.shareRef.current?.show(model);
                }
                break;

            case "password":
                if (this.permissions["SetPassword"]) {
                    this.setPassword(model);
                }
                break;

            case "transfercustomers":
                if (this.permissions["TransferCustomers"]) {
                    this.employeeForTransfer = model;
                    this.employeeRef.current?.show(model.id);
                }
                break;
        }
    }

    async delete(model: EmployeeModel) {
        if (!await confirm2("可通过设置员工状态为离职来阻止该员工登录系统，确定仍然要删除该员工吗？")) {
            return;
        }

        const res = await employeeService.delete(model.id!);

        if (res.succeeded) {
            notifySuccess("已成功删除数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(res.message!);
        }
    }

    async setPassword(model: EmployeeModel) {
        const newPassword = await prompt2("请输入新密码：", { isPassword: true });

        if (newPassword) {
            const res = await employeeService.setPassword({ employeeId: model.id, password: newPassword });
            if (res.succeeded) {
                notifySuccess("已成功设置密码");
            } else {
                alertError(res.message!);
            }
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    onMutualShare(models: EmployeeModel[], name: string) {
        switch (name) {
            case "move":
                if (this.permissions["Move"]) {
                    this.idsForMove = models.map(e => e.id!);
                    this.orgRef.current?.show();
                }
                break;

            case "mutualshare":
                if (this.permissions["EditShare"]) {
                    this.mutualRef.current?.show(models);
                }
                break;
        }
    }

    onRoleChanged(roleId?: number) {
        this.gridRef.current?.onRoleChanged(roleId);
    }

    filter(condition: any) {
        this.gridRef.current?.filter(condition);
    }

    async moveEmployee(org: OrganizationRef) {
        const res = await employeeService.move({
            organizationId: org.id!,
            employeeIds: this.idsForMove!
        });

        if (res.succeeded) {
            notifySuccess("已成功迁移员工");
            this.orgRef.current?.hide();
            this.gridRef.current?.refresh();
        } else {
            alertError(res.message!);
        }
    }

    async transferCustomers(employee: EmployeeRef) {
        if (employee.id === this.employeeForTransfer?.id) {
            this.employeeRef.current?.hide();
            return;
        }

        const res = await employeeService.transferCustomers(this.employeeForTransfer!.id!, employee.id!);

        if (res.succeeded) {
            notifySuccess("已成功转移客户");
            this.employeeRef.current?.hide();
            this.gridRef.current?.refresh();
        } else {
            alertError(res.message!);
        }
    }

    render() {
        return (
            <ContentBlock caption={"员工"}>
                <EmployeeToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onButtonClick={this.onButtonClick}
                    onContextButtonClick={this.onMutualShare}
                    onFiltering={this.filter}
                    onRoleChanged={this.onRoleChanged} />
                <EmployeeGrid
                    ref={this.gridRef}
                    onEmployeeSelected={this.onEmployeeSelected}
                    onButtonClick={this.onButtonClick} />
                <EmployeeForm 
                    ref={this.formRef} 
                    rolePermission={this.permissions["EditRole"]} 
                    onSaved={this.onSaved} />
                <EmployeeSharePopup ref={this.shareRef} />
                <MutualSharePopup ref={this.mutualRef} />
                <AssignOrganizationPopup
                    ref={this.orgRef}
                    caption="迁移员工至新站点"
                    save={this.moveEmployee} />
                <AssignEmployeePopup
                    ref={this.employeeRef}
                    caption="转移送气工的所有客户至"
                    save={this.transferCustomers}
                    getFunc={() => employeeService.getDelivers()} />
            </ContentBlock>
        )
    }
}