import CpBatchPage from "../pages/code-print/cp-batch";
import CpBottleTypePage from "../pages/code-print/cp-bottle-type";
import CpCheckPage from "../pages/code-print/cp-check";
import CpCheckBackPage from "../pages/code-print/cp-check-back";
import CpCheckerPage from "../pages/code-print/cp-checker";
import CpNewPage from "../pages/code-print/cp-new";
import CpOrganizationPage from "../pages/code-print/cp-organization";
import CpScrapPage from "../pages/code-print/cp-scrap";
import CpVendorPage from "../pages/code-print/cp-vendor";
import CpLogPage from "../pages/code-print/cp-log";
import { RouteConfig } from "./route-model";

export const codePrintRoutes: RouteConfig[] = [
    {
        path: '/cp-bottle-type',
        component: CpBottleTypePage
    },
    {
        path: '/cp-vendor',
        component: CpVendorPage
    },
    {
        path: '/cp-organization',
        component: CpOrganizationPage
    },
    {
        path: '/cp-checker',
        component: CpCheckerPage
    },
    {
        path: '/cp-batch',
        component: CpBatchPage
    },
    {
        path: '/cp-new',
        component: CpNewPage
    },
    {
        path: '/cp-check',
        component: CpCheckPage
    },
    {
        path: '/cp-check-back',
        component: CpCheckBackPage
    },
    {
        path: '/cp-scrap',
        component: CpScrapPage
    },
    {
        path: '/cp-log',
        component: CpLogPage
    }
]