import React from "react";
import { organizationService } from "../../../api";
import { fdate, today } from "../../../base";
import { FillingDeliveringReportCondition, OrganizationRef } from "../../../models";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { Button, DateBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { OrgTreeDropDown } from "../../../components";

export interface FillingDeliveringToolbarProps {
    onFiltering: (condition: FillingDeliveringReportCondition) => void;
    onExporting: (condition: FillingDeliveringReportCondition) => void;
}

interface FillingDeliveringToolbarState {
    orgs: OrganizationRef[];
    condition: FillingDeliveringReportCondition;
}

export default class FillingDeliveringToolbar extends
    React.Component<FillingDeliveringToolbarProps, FillingDeliveringToolbarState> {

    constructor(props: Readonly<FillingDeliveringToolbarProps>) {
        super(props);

        const day = today();

        this.state = {
            orgs: [],
            condition: {
                startDate: fdate(new Date(day.getFullYear(), day.getMonth(), 1)),
                endDate: fdate(day)
            }
        }

        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.onExporting = this.onExporting.bind(this);

    }

    componentDidMount(): void {
        this.loadDataSources();
    }

    async loadDataSources() {
        const orgs = await organizationService.getRefs();

        this.setState({
            orgs: orgs
        });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.updateCondition({ startDate: e.value });
    }

    private updateCondition(updated: any) {
        const cond = { ...this.state.condition, ...updated };
        cond.startDate = fdate(cond.startDate);
        cond.endDate = fdate(cond.endDate);

        this.setState({
            condition: cond
        });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.updateCondition({ endDate: e.value });
    }

    onOrganizationChanged(organizationId?: number) {
        this.updateCondition({ organizationId: organizationId });
    }

    onFiltering() {
        this.props.onFiltering(this.state.condition);
    }

    onExporting() {
        this.props.onExporting(this.state.condition);
    }

    render() {
        const { condition, orgs } = this.state;

        return (
            <div className="page-toolbar">
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={condition.startDate}
                            placeholder="开始时间"
                            width={120}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={condition.endDate}
                            placeholder="结束时间"
                            width={120}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={condition.organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            text="导出"
                            onClick={this.onExporting} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}