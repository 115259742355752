import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import React from "react";
import { annoucementStore } from "../../api";
import { dataSources } from "../../base";
import { createGridOptions, remoteReadOnlyGridOptions, StdGrid } from "../../components";
import { AnnouncementModel } from "../../models";

export interface AnnouncementGridProps {
    onModelSelected: (model: AnnouncementModel) => void;
    onButtonClick: (model: AnnouncementModel, name: string) => void;
}

interface AnnouncementGridState {
    columns: IColumnProps[];
}

export default class AnnouncementGrid 
    extends React.Component<AnnouncementGridProps, AnnouncementGridState> {
    moduleName = "Announcement";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions(
        "announcements", 
        this.gridRef, 
        remoteReadOnlyGridOptions,
        (items: any[]) => {
            // state清空会同时清空filter，需要重新绑定旧的
            items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
            return items;
        });

    constructor(props: Readonly<AnnouncementGridProps>) {
        super(props);

        this.state = {
            columns: [
                { dataField: "caption" },
                { 
                    dataField: "replyType", 
                    lookup: { dataSource: dataSources.announcementTypes, displayExpr: 'text', valueExpr: 'value' } 
                },
                { dataField: "createdBy" },
                { dataField: "createdAt", dataType: "datetime" },
                {
                    dataField: "employeeCount",
                    allowFiltering: false,
                    allowSorting: false,
                    allowGrouping: false,
                    caption: "员工数/已读",
                    cellRender: (e: any) => (<span>{e.data.employeeCount}/{e.data.readEmployeeCount}</span>)
                }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = (e) => this.props.onButtonClick(e.data, "edit");
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});    // 为避免混乱，多选不能编辑
        }
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={annoucementStore}
                options={this.gridOptions}
                columns={this.state.columns}>
            </StdGrid>
        );
    }
}