import React from "react";
import DataGrid, { IColumnProps} from "devextreme-react/data-grid"
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import { createGridOptions, stdReadOnlyGridOptions, StdGrid } from "../../components";
import { bottleTypeService, stockInitStore } from "../../api";
import { BottleTypeModel, OrganizationStockInitModel } from "../../models";

export interface StockInitGridProps {
    onModelSelected: (model: OrganizationStockInitModel) => void;
    onButtonClick: (model: OrganizationStockInitModel, name: string) => void;
}

interface StockInitGridState {
    bottleTypes: BottleTypeModel[];
    columns: IColumnProps[];
}

export default class StockInitGrid extends React.Component<StockInitGridProps, StockInitGridState> {
    objectName = "StockInit";
    moduleName = "StockInit";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("stockInit", this.gridRef, stdReadOnlyGridOptions);

    constructor(props: Readonly<StockInitGridProps>) {
        super(props);

        this.state = {
            bottleTypes: [],
            columns: [
                { dataField: "organizationName", caption: "供应站" },
            ]
        }

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = (e) => this.props.onButtonClick(e.data, "edit");
    }

    componentDidMount(): void {
        this.loadBottleTypes();
    }

    async loadBottleTypes() {
        const bottleTypes = await bottleTypeService.getRefs();
        const columns: IColumnProps[] = [
            ...this.state.columns,
            ...bottleTypes.map(t => ({ 
                dataField: `bottle_${t.id}`, 
                caption: `${t.name}瓶`,
                allowGrouping: false,
                allowSorting: false,
                allowFiltering: false,
                cellRender: function(e: any) {
                    return <div>{e.data.stocks.find((s: any) => s.bottleTypeId === t.id)?.initBottles}</div>;
                }
            })),
            ...bottleTypes.map(t => ({ 
                dataField: `gas_${t.id}`, 
                caption: `${t.name}气`,
                allowGrouping: false,
                allowSorting: false,
                allowFiltering: false,
                cellRender: function(e: any) {
                    return <div>{e.data.stocks.find((s: any) => s.bottleTypeId === t.id)?.initGas}</div>;
                }
            })),
        ]

        this.setState({ 
            bottleTypes: bottleTypes,
            columns: columns
        });
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});
        }
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={stockInitStore}
                options={this.gridOptions}
                columns={this.state.columns} />
        )
    }
}
