import React from "react";
import { Form, Popup } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import { bottleFillingService, fillingTargetService, fillingUserService } from "../../api";
import { alertError, modelClone, ModuleConfig } from "../../base";
import { setItemDataSource, PopupContentBox, StdForm, RefPopup } from "../../components";
import { BottleFillingModel, FillingTargetRef, FillingUserRef } from "../../models";

export interface BottleFillingFormProps {
    onSaved: () => void;
}

interface BottleFillingFormState {
    users: FillingUserRef[];
    targets: FillingTargetRef[];
    items: IItemProps[];
    model: BottleFillingModel;
}

export default class BottleFillingForm 
    extends React.Component<BottleFillingFormProps, BottleFillingFormState> {
    moduleName: ModuleConfig = {
        moduleName: "BottleFilling",
        children: {
            "bottle": "Bottle"
        }
    };
    
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();

    constructor(props: Readonly<BottleFillingFormProps>) {
        super(props);

        this.state = {
            users: [],
            targets: [],
            model: { bottle: {} },
            items: [
                { dataField: 'barCode' },
                { dataField: 'bottle.code' },
                { 
                    dataField: 'fillingTime',
                    editorOptions: { type: "datetime" }
                },
                {
                    dataField: 'userCode',
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: [], displayExpr: 'name', valueExpr: 'code' }
                },
                { dataField: 'originalWeight' },
                { dataField: 'weight' },
                { dataField: 'fillingWeight' },
                {
                    dataField: 'target',
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: [], displayExpr: 'name', valueExpr: 'code' }
                }
            ]
        };

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }
    
    async loadData() {
        const newState: BottleFillingFormState = { ...this.state };

        await Promise.all([
            (async () => newState.users = await fillingUserService.getRefs())(),
            (async () => {
                const data = await fillingTargetService.getRefs();
                newState.targets = data.map(t => ({ code: t.code, name: `${t.organization!.name} | ${t.code}` }));
            })()
        ]);

        setItemDataSource(newState.items, "userCode", newState.users);
        setItemDataSource(newState.items, "target", newState.targets);

        this.setState(newState);
    }

    show(model: BottleFillingModel) {
        this.popupRef.current?.instance.show();

        this.setState({ model: modelClone(model) });        
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const model = this.state.model;
            const res = model.id ?
                await bottleFillingService.update(model) :
                await bottleFillingService.create(model);

            if (res.succeeded) {
                this.hide();
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup 
                popupRef={this.popupRef}
                title={"编辑"}
                width={800}
                height={400}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm 
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        formData={this.state.model} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}