import React from "react";
import { Button, DateBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { OrgTreeDropDown, ToolbarTimeFilter } from "../../components";
import { FillingTargetRef, OrganizationRef } from "../../models";
import { fillingTargetService, organizationService } from "../../api";
import { alertWarning, dateAdd, stdTimeOnlyFormat, timeAdd, today } from "../../base";
import { ValueChangedEvent } from "devextreme/ui/date_box";
import { ValueChangedEvent as TextChangedEvent } from "devextreme/ui/text_box";

export interface BottleFillingToolbarProps {
    onButtonClick: (fillingIds: number[], name: string) => void;
    onOrganizationChanged: (organizationId?: number) => void;
    onFiltering: (filterValue: any) => void;
}

interface BottleFillingToolbarState {
    targetCode?: string;
    fillingIds: number[];
    orgs: OrganizationRef[];
    fillingTargets: FillingTargetRef[];
    organizationId?: number;
    date: Date;
    startTime?: Date;
    endTime?: Date;
}

export default class BottleFillingToolbar
    extends React.Component<BottleFillingToolbarProps, BottleFillingToolbarState> {
    toolbarRef = React.createRef<ToolbarTimeFilter>();

    constructor(props: Readonly<BottleFillingToolbarProps>) {
        super(props);

        const day = today();

        this.state = {
            fillingIds: [],
            orgs: [],
            fillingTargets: [],
            date: day,
            startTime: day,
            endTime: timeAdd(dateAdd(day, 1), -1, "s")
        };

        this.onTargetCodeChanged = this.onTargetCodeChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onDateChanged = this.onDateChanged.bind(this);
        this.onStartTimeChanged = this.onStartTimeChanged.bind(this);
        this.onEndTimeChanged = this.onEndTimeChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount() {
        this.loadOrgs();
    }

    async loadOrgs() {
        const orgs = await organizationService.getRefs();
        const targets = await fillingTargetService.getRefs();
        this.setState({ orgs: orgs, fillingTargets: targets });
    }

    setModel(fillingIds: number[]) {
        this.setState({ fillingIds: fillingIds });
    }

    onTargetCodeChanged(e: TextChangedEvent) {
        const organizationId = this.state.fillingTargets.find(t => t.code === e.value)?.organizationId;

        this.setState({ 
            targetCode: e.value,
            organizationId: organizationId
        });

        this.props.onOrganizationChanged(organizationId);
    }

    onOrganizationChanged(organizationId?: number) {
        this.setState({ organizationId: organizationId });
        this.props.onOrganizationChanged(organizationId);
    }

    onDateChanged(e: ValueChangedEvent) {
        this.setState({ date: e.value });
    }

    onStartTimeChanged(e: ValueChangedEvent) {
        this.setState({ startTime: e.value });
    }

    onEndTimeChanged(e: ValueChangedEvent) {
        this.setState({ endTime: e.value });
    }

    onFiltering() {
        const filterValue: any[] = [];
        const { targetCode, organizationId, date, startTime, endTime, fillingTargets } = this.state;

        if (!organizationId) {
            alertWarning("请输入去向编码或选择充装去向站点");
            return;
        } else if (targetCode) {
            filterValue.push(["target", "=", targetCode]);
        } else {
            const targets = fillingTargets
                .filter(t => t.organizationId === this.state.organizationId)
                .map(t => ["target", "=", t.code]);

            const targetFilter = [];
            for (const target of targets) {
                targetFilter.push(target, "or");
            }

            targetFilter.length--;

            filterValue.push(targetFilter);
        }

        if (!startTime || !endTime) {
            alertWarning("请选择起止时间");
            return;
        } else {
            const stime = new Date(date.getFullYear(), date.getMonth(), date.getDate(),
                startTime.getHours(), startTime.getMinutes(), startTime.getSeconds());
            const etime = new Date(date.getFullYear(), date.getMonth(), date.getDate(),
                endTime.getHours(), endTime.getMinutes(), endTime.getSeconds());

            filterValue.push(["fillingTime", ">=", stime], ["fillingTime", "<=", etime]);
        }

        this.props.onFiltering(filterValue);
    }

    reset() {
        // 按用户说明：不需要重置条件，会在同一条件下反复操作
    }

    render() {
        const { fillingIds, orgs, targetCode, organizationId, date, startTime, endTime } = this.state;

        return (
            <div className={"page-toolbar page-large-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <TextBox 
                            value={targetCode} 
                            width={80}
                            placeholder={"去向编码"}
                            onValueChanged={this.onTargetCodeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="选择供应站过滤"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            type={"date"}
                            value={date}
                            onValueChanged={this.onDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            type={"time"}
                            value={startTime}
                            displayFormat={stdTimeOnlyFormat}
                            onValueChanged={this.onStartTimeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            type={"time"}
                            value={endTime}
                            displayFormat={stdTimeOnlyFormat}
                            onValueChanged={this.onEndTimeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            text="添加运输记录"
                            onClick={() => this.props.onButtonClick(fillingIds, "add")} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}