import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { ExportingEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import { createGridOptions, createBoolColumn, setColumnDataSource, remoteReadOnlyGridOptions, createImageColumn, StdGrid, ExportModel } from "../../components";
import { ModuleConfig, StringMap, enums, authorizationService, baseApiUrl, globalPopupManager, globalPopupNames, DataMap, dataSources } from "../../base";
import { securityCheckStore, customerTypeService, securityCheckService, securityCheckDeletedStore, areaService } from "../../api";
import { AreaRef, CustomerTypeRef, SecurityCheckModel } from "../../models";
import CustomStore from "devextreme/data/custom_store";

export interface SecurityCheckGridProps {
    permissions: DataMap<boolean>;
    onModelSelected: (model: SecurityCheckModel) => void;
    onRowChecked: (ids: number[]) => void;
    onButtonClick: (model: SecurityCheckModel, name: string) => void;
}

interface SecurityCheckGridState {
    customerTypes: CustomerTypeRef[];
    counties: AreaRef[];
    streets: AreaRef[];
    columns: IColumnProps[];
    store: CustomStore;
}

export default class SecurityCheckGrid extends React.Component<SecurityCheckGridProps, SecurityCheckGridState> {
    objectName = "SecurityCheck";
    moduleName: ModuleConfig = {
        moduleName: "SecurityCheck",
        children: {
            customer: 'Customer',
            checker: 'Employee',
            organization: 'Organization'
        }
    };

    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("securityChecks", this.gridRef, remoteReadOnlyGridOptions);

    nameMap: StringMap = {
        "customer.name": "customerId",
        "checker.name": "checkerId",
        "organization.name": "organizationId"
    };

    constructor(props: Readonly<SecurityCheckGridProps>) {
        super(props);

        this.state = {
            customerTypes: [],
            counties: [],
            streets: [],
            store: securityCheckStore,
            columns: [
                {
                    dataField: 'customer.name',
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.name}
                        </span>
                    )
                },
                {
                    dataField: 'customer.gasCode',
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.gasCode}
                        </span>
                    )
                },
                {
                    dataField: 'customer.typeId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: 'checkTime', dataType: "datetime" },
                {
                    dataField: 'checker.name',
                    calculateFilterExpression: function(filterValue: any, op: string) {
                        return [
                            ["checker.name", op, filterValue],
                            "or",
                            ["checker.code", op, filterValue]
                        ]
                    },
                    cellRender: (e: any) => (<span>{e.data.checker?.nameAndCode}</span>)
                },
                {
                    dataField: 'organization.name',
                    allowFiltering: false,
                },
                { 
                    dataField: 'customer.areaId',
                    allowFiltering: false,
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                {
                    dataField: 'customer.streetId',
                    allowFiltering: false,
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: 'address' },
                { dataField: 'customer.personInCharge' },
                { dataField: 'customer.phoneNumber' },
                { dataField: 'bottleCodes' },
                createBoolColumn({ dataField: 'hasDanger' }),
                { 
                    dataField: 'dangerLevel',
                    lookup: { dataSource: dataSources.dangerLevels, displayExpr: 'text', valueExpr: 'value' }
                },
                {
                    dataField: 'archived',
                    trueText: "已归档",
                    falseText: "未归档",
                    cellRender: (e: any) => {
                        if (e.rowType !== "data") {
                            return "";
                        }

                        const archived = !!e.data.archived,
                            text = archived ? "已归档" : "未归档";

                        return (
                            <React.Fragment>
                                {text} &nbsp;
                                {this.props.permissions["Archive"] && !archived && (
                                    <span
                                        className="link-button"
                                        onClick={() => this.props.onButtonClick(e.data, "archive")}>
                                        归档
                                    </span>
                                )}
                            </React.Fragment>
                        );
                    }
                },
                createImageColumn({ dataField: 'customerSign' }),
                createImageColumn({ dataField: 'envPhoto' }),
                { dataField: 'depositCert' },
                { 
                    dataField: 'auditStatus',
                    lookup: { dataSource: dataSources.dangerAuditStatus, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: 'auditNote' },
                {
                    dataField: 'customer.alerter',
                    lookup: { 
                        dataSource: [
                            { value: null, text: "未填写" },
                            ...dataSources.alerterStatus
                        ],
                        displayExpr: 'text', 
                        valueExpr: 'value' 
                    }
                },
                { dataField: 'customer.label', caption: '客户标签' },
                { dataField: 'customer.note', caption: '客户备注' }
            ]
        }

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "detail");
        this.gridOptions.onExporting = this.onExporting.bind(this);
        this.gridOptions.export!.allowExportSelectedData = false;
        this.gridOptions.export!.enabled = false;
    }

    componentDidMount() {
        this.authorizeImport();
        this.loadData();
    }

    async authorizeImport() {
        if (await authorizationService.authorize(this.objectName, enums.stdActions.export)) {
            this.gridOptions.export!.enabled = true;
            this.gridRef.current?.instance.option("export.enabled", true);
        }
    }

    async loadData() {
        let types: CustomerTypeRef[] = [],
            counties: AreaRef[] = [],
            streets: AreaRef[] = [];

        await Promise.all([
            (async () => types = await customerTypeService.getRefs())(),
            (async () => counties = await areaService.getCounties())(),
            (async () => streets = await areaService.getStreets())()
        ]);

        setColumnDataSource(this.state.columns, "customer.typeId", types);
        setColumnDataSource(this.state.columns, "customer.areaId", counties);
        setColumnDataSource(this.state.columns, "customer.streetId", streets);

        this.setState({
            customerTypes: types,
            counties: counties,
            streets: streets
        });
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});    // 为避免混乱，多选不能编辑
        }

        this.props.onRowChecked(e.selectedRowKeys);
    }

    onExporting(e: ExportingEvent) {
        const args: ExportModel = {
            moduleName: this.moduleName.moduleName,
            exportUrl: `${baseApiUrl}${securityCheckService.apiPath}/export`,
            columns: this.state.columns,
            keys: e.component.getSelectedRowKeys(),
            filter: e.component.getCombinedFilter(),
            showDeleted: this.state.store === securityCheckDeletedStore
        };

        e.cancel = true;
        globalPopupManager.show(globalPopupNames.exportPopup, args);
    }
    
    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    filter(filterValue: any, showDeleted: boolean) {
        if (showDeleted && this.state.store !== securityCheckDeletedStore) {
            this.setState({ store: securityCheckDeletedStore }, () => this._filter(filterValue));
        } else if (!showDeleted && this.state.store !== securityCheckStore) {
            this.setState({ store: securityCheckStore }, () => this._filter(filterValue))
        } else {
            this._filter(filterValue);
        }
    }

    _filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns}
                columnNameMap={this.nameMap}
                toolbarHeight={80}>
            </StdGrid>
        );
    }
}