import { createStoreFromUrl } from "../base";

class BottleRecycleLogService {
    apiPath = "/api/bottlerecyclelog";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }
}

export const bottleRecycleLogService = new BottleRecycleLogService();
export const bottleRecycleLogStore = bottleRecycleLogService.getDxData();