import { http } from "../../base";
import { FillingDeliveringReport, FillingDeliveringReportCondition } from "../../models";

class OrganizationReportService {
    apiPath = "/api/report/organization";

    getFillingDelivering(condition: FillingDeliveringReportCondition) {
        return http.getData<FillingDeliveringReport[]>(
            `${this.apiPath}/fillingdelivering?${http.serialize([condition])}`);
    }
}

export const organizationReportService = new OrganizationReportService();