import React from "react";
import { ExportingEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import DataGrid, { IColumnProps, IDataGridOptions } from "devextreme-react/data-grid";
import { createImageColumn, StdGrid, createGridOptions, setColumnDataSource, remoteReadOnlyGridOptions, ExportModel } from "../../components";
import { DataMap, ModuleConfig, dataSources, enums, createEmptyStore, authorizationService, baseApiUrl, globalPopupManager, globalPopupNames, StringMap } from "../../base";
import { orderStore, serverDataService, orderService, customerTypeService } from "../../api";
import { CustomerTypeModel, OrderItemModel, OrderModel } from "../../models";
import CustomStore from "devextreme/data/custom_store";

export interface OrderGridProps {
    permissions: DataMap<boolean>;
    onOrderSelected(order: OrderModel): void;
    onButtonClick(order: OrderModel, name: string): void;
}

interface OrderGridState {
    store: CustomStore;
    customerTypes: CustomerTypeModel[];
    columns: IColumnProps[];
}

export default class OrderGrid extends React.Component<OrderGridProps, OrderGridState> {
    objectName = "Order";
    moduleName: ModuleConfig = {
        moduleName: "Order",
        children: {
            "organization": "Organization",
            "customer": "Customer",
            "deliver": "Employee"
        }
    };

    gridRef = React.createRef<DataGrid>();
    gridOptions: IDataGridOptions = createGridOptions(
        "orders",
        this.gridRef, 
        remoteReadOnlyGridOptions,
        (items: any[]) => {
            // state清空会同时清空filter，需要重新绑定旧的
            items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
            return items;
        });

    nameMap: StringMap = {
        "customer.name": "customerId",
        "deliver.name": "deliverId",
        "organization.name": "organizationId"
    };

    constructor(props: Readonly<OrderGridProps>) {
        super(props);

        this.state = {
            store: createEmptyStore(),
            customerTypes: [],
            columns: [
                { dataField: 'code' },
                {
                    dataField: 'status',
                    lookup: { dataSource: dataSources.orderStatus, displayExpr: 'text', valueExpr: 'value' }
                },
                {
                    dataField: 'paid',
                    lookup: { dataSource: dataSources.payment, displayExpr: 'text', valueExpr: 'value' },
                    cellRender: (e: any) => (
                        <div>
                            <span>{dataSources.payment.find(p => p.value === (e.data.paid || false))?.text}</span> &nbsp;
                            {e.data.status === enums.orderStatus.delivery && (<span className={"link-button"}
                                onClick={() => this.props.onButtonClick(e.data, "next")}>
                                回款
                            </span>)}
                        </div>
                    )
                },
                {
                    dataField: 'customer.typeId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                {
                    dataField: 'customer.gasCode',
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.gasCode}
                        </span>
                    )
                },
                {
                    dataField: 'customer.name',
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.name}
                        </span>
                    )
                },
                ...serverDataService.bottleTypes.map((t) => ({
                    dataField: `item_${t.id}`,
                    caption: t.name,
                    allowGrouping: false,
                    allowSorting: false,
                    allowFiltering: false,
                    cellRender: (e: any) => {
                        const amount = e.data.items ?
                            e.data.items.find((i: any) => i.bottleTypeId === t.id)?.amount || 0 : 0;
                        return (
                            <span className={"link-button"}
                                onClick={() => this.props.onButtonClick(e.data, "bottles")}>
                                {amount}
                            </span>
                        )
                    }
                })),
                {
                    dataField: `item_0`,
                    caption: "余气",
                    allowGrouping: false,
                    allowSorting: false,
                    allowFiltering: false,
                    cellRender: (e: any) => {
                        const amount = e.data.items ? 
                            e.data.items.find((i: OrderItemModel) => !i.bottleTypeId)?.amount || '' : '';
                        return (<span>{amount}</span>)
                    }
                },
                { dataField: 'originalTotal', dataType: "number", format: "currency" },
                { dataField: 'totalDiscount', dataType: "number", format: "currency" },
                { dataField: 'totalPrice', dataType: "number", format: "currency" },
                { 
                    dataField: 'address',
                    cellRender: (e: any) => (
                        <span className={e.data.address !== e.data.customer.address ? 'text-warning' : ''}>
                            {e.data.address}
                        </span>
                    )
                },
                {
                    dataField: 'deliver.name',
                    filterOperations: [ "contains", "notcontains", "startswith", "endswith", "=", "<>" ],
                    calculateFilterExpression: function(filterValue: any, op: string) {
                        return [
                            ["deliver.name", op, filterValue],
                            "or",
                            ["deliver.code", op, filterValue]
                        ]
                    },
                    cellRender: (e: any) => (<span>{e.data.deliver?.nameAndCode}</span>) 
                },
                { dataField: 'createdBy' },
                {
                    dataField: 'organization.name',
                    allowFiltering: false
                },
                {
                    dataField: 'source',
                    lookup: { dataSource: dataSources.platforms, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: 'orderTime', dataType: 'datetime' },
                { dataField: 'completedAt', dataType: 'datetime' },
                { dataField: 'paidAt', dataType: 'datetime' },
                { dataField: 'receiverIdNumber' },
                { dataField: 'receiver' },
                createImageColumn({ dataField: 'customerSign' }),
                createImageColumn({ dataField: 'checkPhoto' }),
                {
                    dataField: 'installType',
                    lookup: { dataSource: dataSources.installTypes, displayExpr: 'text', valueExpr: 'value' }
                },
                {
                    dataField: 'paidStatus',
                    lookup: { dataSource: dataSources.paidStatus, displayExpr: 'text', valueExpr: 'value' }
                },
                {
                    dataField: 'onlinePay',
                    lookup: { dataSource: dataSources.onlinePayTypes, displayExpr: 'text', valueExpr: 'value' }
                },
                { 
                    dataField: 'accessory', 
                    caption: "配件",
                    allowSorting: false,
                    allowFiltering: false,
                    allowGrouping: false
                },
                {
                    dataField: "accessoryPrice",
                    caption: "配件价格",
                    dataType: "number", 
                    format: "currency",
                    allowSorting: false,
                    allowFiltering: false,
                    allowGrouping: false
                },
                { dataField: 'depositCert' },
                { dataField: 'note' }
            ]
        };

        this.applyFilter = this.applyFilter.bind(this);
        this.refresh = this.refresh.bind(this);

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
        this.gridOptions.onExporting = this.onExporting.bind(this);
        this.gridOptions.export!.allowExportSelectedData = false;
        this.gridOptions.export!.enabled = false;
        this.gridOptions.summary = {
            totalItems: [
                { column: "totalPrice", summaryType: "sum", valueFormat: "currency", displayFormat: "计：{0}"  },
                ...serverDataService.bottleTypes.map((t) => ({
                    column: `item_${t.id}`, summaryType: "sum", displayFormat: "计：{0}"
                })),
                { column: 'item_0', summaryType: "sum", displayFormat: "计：{0}" }
            ],
        };
    }

    componentDidMount() {
        this.authorizeImport();
        this.loadData();

        globalPopupManager.subscribe(globalPopupNames.orderGrid, this.refresh);
    }

    async authorizeImport() {
        if (await authorizationService.authorize(this.objectName, enums.stdActions.export)) {
            this.gridOptions.export!.enabled = true;
            this.gridRef.current?.instance.option("export.enabled", true);
        }
    }

    async loadData() {
        const customerTypes = await customerTypeService.getRefs();
        setColumnDataSource(this.state.columns, "customer.typeId", customerTypes);

        this.setState({
            customerTypes: customerTypes
        });
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onOrderSelected(e.selectedRowsData[0]);
        } else {
            this.props.onOrderSelected({});    // 为避免混乱，多选不能编辑
        }
    }

    onExporting(e: ExportingEvent) {
        const args: ExportModel = {
            moduleName: this.moduleName.moduleName,
            exportUrl: `${baseApiUrl}${orderService.apiPath}/export`,
            columns: this.state.columns,
            keys: e.component.getSelectedRowKeys(),
            filter: e.component.getCombinedFilter()
        };

        e.cancel = true;
        globalPopupManager.show(globalPopupNames.exportPopup, args);
    }

    async refresh() {
        const grid = this.gridRef.current?.instance;
        if (grid) {
            await grid.refresh();
            this.props.onOrderSelected(grid.getSelectedRowsData()[0]);
        }
    }

    async applyFilter(filterValue: any) {
        if (filterValue) {
            this.setState({ store: orderStore }, () => this.gridRef.current?.instance.filter(filterValue));
        } else {
            this.setState({ store: createEmptyStore() });
        }
    }

    componentWillUnmount() {
        globalPopupManager.unsubscribe(globalPopupNames.orderGrid, this.refresh);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                toolbarHeight={80}
                columns={this.state.columns}
                columnNameMap={this.nameMap}>
            </StdGrid>
        );
    }
}