import './employee-position.scss';
import { Button, DateBox, SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/tree-list";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import React from "react";
import { ReactNode } from "react";
import { confirm2, fdate, today } from "../../base";
import { ContentBlock, FullPanel, OrgTreeDropDown } from "../../components";
import { employeePositionService, employeeService, organizationService } from '../../api';
import { EmployeePositionModel, EmployeeRef, OrganizationRef } from '../../models';
import { ValueChangedEvent } from 'devextreme/ui/select_box';

interface EmployeePositionPageState {
    date: Date | string;
    orgs: OrganizationRef[];
    organizationId?: number;
    allEmployees: EmployeeRef[];
    employees: EmployeeRef[];
    employeeId?: string;
    list: EmployeePositionModel[];
    type: "All" | "Employee";
    buttonText: string;
}

export default class EmployeePositionPage extends React.Component<any, EmployeePositionPageState> {
    map: any = null;
    
    constructor(props: any) {
        super(props);

        this.state = {
            date: today(),
            orgs: [],
            allEmployees: [],
            employees: [],
            employeeId: "",
            list: [],
            type: "All",
            buttonText: "< 返回"
        };

        this.initMap = this.initMap.bind(this);
        this.addPointsToMap = this.addPointsToMap.bind(this);
        this.onDateChanged = this.onDateChanged.bind(this);
        this.onEmployeeChanged = this.onEmployeeChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.back = this.back.bind(this);
    }

    componentDidMount(): void {
        this.loadData(this.initMap);
        this.loadOrgs();
        this.loadEmployees();
    }

    async loadOrgs() {
        const orgs = await organizationService.getRefs();
        this.setState({ orgs: orgs });
    }

    async loadEmployees() {
        const employees = await employeeService.getDelivers();
        this.setState({
            allEmployees: employees,
            employees: employees
        });
    }

    async loadData(callback?: VoidFunction) {
        const list = await employeePositionService.get(fdate(this.state.date), this.state.organizationId);

        this.setState({ list: list }, callback);
    }

    initMap() {
        const map = new window.BMapGL.Map("mapContainer");

        const zoomCtrl = new window.BMapGL.ZoomControl();  // 添加缩放控件
        map.addControl(zoomCtrl);

        const point = new window.BMapGL.Point(118.14280423502791, 24.545367358436536); // 以高崎机场为中心点
        map.centerAndZoom(point, 13);

        this.map = map;
        this.addPointsToMap();

        // map.addEventListener('click', function(e: any) {
        //     console.log(e.latlng.lng + ',' + e.latlng.lat);
        // })
    }

    addPointsToMap() {
        const list = this.state.list;
        const posPoints = list.map(p => new window.BMapGL.Point(p.longitude, p.latitude));
        const thisObj = this;

        this.map.clearOverlays();

        setTimeout(() => {
            const convertor = new window.BMapGL.Convertor();

            for (let i = 0; i < posPoints.length; i += 10) {
                convertor.translate(posPoints.slice(i, Math.min(i + 10, posPoints.length)), 1, 5, function(i: number, data: any) {
                    if (data.status === 0) {
                        for (let j = 0; j < data.points.length; j++) {
                            const p = data.points[j];
                            const realIndex = i + j;
                            (list[realIndex] as any).converted = p;

                            const marker = new window.BMapGL.Marker(p);
                            thisObj.map.addOverlay(marker);
                            
                            const label = new window.BMapGL.Label(
                                thisObj.state.type === "All" ? (list[realIndex].employee as any)?.nameAndCode : ftime(list[realIndex].updatedAt), 
                                { offset: new window.BMapGL.Size(10, -10) });  // 使用员工名

                            marker.setLabel(label);

                            if (thisObj.state.type === "All") {
                                marker.addEventListener("click", thisObj.onMarkerClick.bind(thisObj, realIndex));
                                label.addEventListener("click", thisObj.onMarkerClick.bind(thisObj, realIndex));
                            }

                            if (realIndex === list.length - 1 && thisObj.state.type === "Employee") {
                                var polyline = new window.BMapGL.Polyline(list.map(p => (p as any).converted),
                                    { strokeColor: "darkred", strokeWeight: 2, strokeOpacity: 0.5 });

                                thisObj.map.addOverlay(polyline);
                            }
                        }
                    }
                }.bind(this, i));
            }
        }, 1000);     
    }

    onDateChanged(e: DateChangedEvent) {
        this.setState({ date: e.value }, () => this.loadData(this.addPointsToMap));
    }

    onOrganizationChanged(organizationId?: number) {
        const employees = this.state.allEmployees.filter(e => e.organizationId === organizationId);

        this.setState({ 
            organizationId: organizationId,
            employees: employees,
            employeeId: undefined
        }, () => this.loadData(this.addPointsToMap));
    }

    onEmployeeChanged(event: ValueChangedEvent) {
        if (event.value) {
            const employee = this.state.employees.find(e => e.id === event.value);

            if (employee) {
                this.setState({
                    type: "Employee",
                    employeeId: employee.id,
                    buttonText: `< 正在查看${(employee as any)?.nameAndCode}，点击返回`
                });

                this.gotoDetail(employee);
            }
        } else {
            this.back();
        }
    }

    async onMarkerClick(index: number) {
        const data = this.state.list[index];
        const detail = await confirm2(
            `员工：${(data.employee as any)?.nameAndCode}<br/>刷新时间：${fdate(data.updatedAt || "")}`,
            { title: "是否跳转查看该员工轨迹？"});

        if (detail) {
            this.setState({
                type: "Employee",
                employeeId: data.employee?.id,
                buttonText: `< 正在查看${(data.employee as any)?.nameAndCode}，点击返回`
            });

            this.gotoDetail(data.employee!);
        }
    }

    async gotoDetail(data: EmployeeRef) {
        const list = await employeePositionService.getEmployee(fdate(this.state.date), data.id!);

        this.setState({ list: list }, this.addPointsToMap);
    }

    async back() {
        this.setState({ type: "All", employeeId: "" });
        this.loadData(this.addPointsToMap);
    }

    render(): ReactNode {
        const { date, type, employees, buttonText, employeeId, orgs, organizationId } = this.state;

        return (
            <ContentBlock caption="定位跟踪">
                <div className="page-toolbar">
                    <Toolbar>
                        <Item location="before" locateInMenu={"auto"} text="日期："></Item>
                        <Item location="before" locateInMenu={"auto"}>
                            <DateBox
                                value={date}
                                width={120}
                                onValueChanged={this.onDateChanged} />
                        </Item>
                        <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                        </Item>
                        <Item location={"before"} locateInMenu="auto">
                            <SelectBox
                                dataSource={employees}
                                displayExpr="nameAndCode"
                                valueExpr={"id"}
                                value={employeeId}
                                searchEnabled={true}
                                showClearButton={true}
                                placeholder="送气工"
                                onValueChanged={this.onEmployeeChanged} />
                        </Item>
                        <Item location={"after"} locateInMenu="auto" visible={type === "Employee"}>
                            <Button type="default" text={buttonText} onClick={this.back} />
                        </Item>
                    </Toolbar>
                    <FullPanel style={{ padding: "0", borderTop: "1px solid #ddd" }}>
                        <div id="mapContainer" className="map-container"></div>
                    </FullPanel>
                </div>
            </ContentBlock>
        )
    }
}

function ftime(date: string | Date | undefined) {
    if (!date) {
        return "";
    }

    date = new Date(date);
    const hours = date.getHours(),
        minutes = date.getMinutes();

    return `${hours > 9 ? hours: '0' + hours}:${minutes > 9 ? minutes : '0' + minutes}`
}