import React from "react";
import { Popup, ScrollView } from "devextreme-react";
import DataGrid, { IColumnProps, IDataGridOptions } from "devextreme-react/data-grid";
import { dangerService, securityCheckTypeService } from "../../api";
import { StdGrid, RefPopup, createImageColumn } from "../../components";
import { DangerModel, SecurityCheckTypeRef } from "../../models";

interface DangerNotificationDetailState {
    itemTypes: SecurityCheckTypeRef[];
    danger: DangerModel;
}

export default class DangerNotificationDetail extends React.Component<any, DangerNotificationDetailState> {
    moduleName = "DangerItem";

    popupRef = React.createRef<Popup>();
    gridRef = React.createRef<DataGrid>();
    gridOptions: IDataGridOptions = {
        columnAutoWidth: true,
        paging: { enabled: false }
    };

    columns: IColumnProps[] = [
        { dataField: "itemName" },
        createImageColumn({ dataField: "photo" }),
        { 
            dataField: "suggest", 
            caption: "整改建议",
            cellRender: (e: any) => {
                const item = e.data, customerTypeId = this.state.danger.customer!.typeId;
                const type = this.state.itemTypes.find(t => item.typeId === t.id || 
                    (customerTypeId === t.customerTypeId && item.itemName === t.name));

                return type?.suggest;
            }
        }
    ];

    constructor(props: any) {
        super(props);

        this.state = {
            itemTypes: [],
            danger: {}
        };
    }

    componentDidMount() {
        this.loadItemTypes();
    }

    async loadItemTypes() {
        const types = await securityCheckTypeService.getRefs();
        this.setState({ itemTypes: types.filter(t => t.suggest) });
    }

    async show(model: DangerModel) {
        this.popupRef.current?.instance.show();

        const danger = await dangerService.get(model.id!);
        this.setState({ danger: danger });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"整改通知书详情"}
                width={1200}
                height={600}>
                <ScrollView height={"100%"} scrollByContent={true} showScrollbar={"always"}>
                    <StdGrid
                        moduleName={this.moduleName}
                        gridRef={this.gridRef}
                        dataSource={this.state.danger.items}
                        columns={this.columns}
                        options={this.gridOptions}
                        autoHeight={true}
                    />
                </ScrollView>
            </RefPopup>
        );
    }
}