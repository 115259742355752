import { MessageBool, http } from "../base";
import { OrganizationAdvProfileModel } from "../models";

class OrganizationProfileService {
    apiPath = "/api/organizationprofile";

    get(id: number): Promise<OrganizationAdvProfileModel> {
        return http.getData(`${this.apiPath}/${id}`);
    }

    save(model: OrganizationAdvProfileModel): Promise<MessageBool> {
        return http.putData(this.apiPath, model);
    }
}

export const organizationProfileService = new OrganizationProfileService();