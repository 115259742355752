import { ImagesComponent } from "../grid";

export interface PhotoListProps {
    fileNames?: string[];
}

export default function PhotoList(props: PhotoListProps) {
    const fileNames = props.fileNames;

    return (
        <div style={{padding:"10px"}}>
            {fileNames && fileNames.length > 0 && (
                <div className="large-images">
                    <ImagesComponent fileNames={fileNames} />
                </div>
            )}
            {!fileNames?.length && (
                <p>无图片</p>
            )}
        </div>
    )
}