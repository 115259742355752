import React from "react";
import { Button } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import AuthorizedComponent from "../authorized-component";
import RoleGrid from "./role-grid";
import { authorizationService, enums } from "../../base";
import { ContentBlock, PermissionPopup, StdToolbar } from "../../components";
import { RoleModel } from "../../models";

export default class RolePage extends AuthorizedComponent {
    objectName = "Role";
    popupRef = React.createRef<PermissionPopup>();
    toolbarRef = React.createRef<StdToolbar>();
    gridRef = React.createRef<RoleGrid>();
    roleClaimPermission = false;
    
    constructor(props: any) {
        super(props);

        this.onRoleSelected = this.onRoleSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.renderPermissionItem = this.renderPermissionItem.bind(this);
    }

    async authorize(): Promise<void> {
        await super.authorize();

        this.roleClaimPermission = await authorizationService.authorize("RoleClaim", enums.stdActions.read);
        this.permissions["_roleClaim"] = this.roleClaimPermission;  // 添加额外的用于StdToolbar判断空
        this.setState({});
    }

    onRoleSelected(model: RoleModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onButtonClick(model: RoleModel, name: string) {
        const grid = this.gridRef.current?.gridRef.current?.instance;
        
        if (grid) {
            switch (name) {
                case "add":
                    if (this.permissions[enums.stdActions.create]) {
                        grid.addRow();
                    }
                    break;

                case "edit":
                    if (this.permissions[enums.stdActions.update]) {
                        grid.editRow(grid.getRowIndexByKey(model.id));
                    }
                    break;
                
                case "delete":
                    if (this.permissions[enums.stdActions.delete]) {
                        grid.deleteRow(grid.getRowIndexByKey(model.id));
                    }
                    break;

                case "permission":
                    if (this.roleClaimPermission) {
                        this.popupRef.current?.show(model);
                    }
                    break;
            }
        }
    }

    render() {      
        return (
            <ContentBlock caption={"角色"}>
                <StdToolbar 
                    permissions={this.permissions}
                    ref={this.toolbarRef} 
                    itemsBefore={this.renderPermissionItem} 
                    onButtonClick={this.onButtonClick} />
                <RoleGrid 
                    ref={this.gridRef} 
                    onRowSelected={this.onRoleSelected} 
                    onButtonClick={this.onButtonClick} />
                <PermissionPopup ref={this.popupRef} />
            </ContentBlock>
        );
    }

    renderPermissionItem(model: RoleModel) {
        return (
            <Item location={"after"} locateInMenu="auto">
                <Button
                    visible={this.roleClaimPermission}
                    disabled={!model?.id}
                    text="角色权限"
                    onClick={() => this.onButtonClick(model, "permission")} />
            </Item>
        );        
    }
}