import React from "react";
import AuthorizedComponent from "../authorized-component";
import VehicleUserGrid from "./vehicle-user-grid";
import VehicleUserForm from "./vehicle-user-form";
import VehicleUserToolbar from "./vehicle-user-toolbar";
import { ContentBlock } from "../../components";
import { vehicleUserService } from "../../api";
import { alertError, confirm2, enums, notifySuccess } from "../../base";
import { VehicleModel, VehicleUserModel } from "../../models";


export default class VehicleUserPage extends AuthorizedComponent {
    objectName = "VehicleUser";
    gridRef = React.createRef<VehicleUserGrid>();
    toolbarRef = React.createRef<VehicleUserToolbar>();
    formRef = React.createRef<VehicleUserForm>();

    constructor(props: Readonly<any>) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
        this.filter = this.filter.bind(this);
    }

    onModelSelected(model: VehicleUserModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onButtonClick(model: VehicleModel, name: string) {
        switch (name) {
            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.formRef.current?.show({});
                }
                break;

            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.formRef.current?.show(model);
                }
                break;

            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(model.id!);
                }
                break;
        }

    }

    async delete(id: number) {
        if (!await confirm2("确定要删除该车辆档案吗？")) {
            return;
        }

        const res = await vehicleUserService.delete(id);

        if (res.succeeded) {
            notifySuccess("已成功删除数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(res.message!);
        }
    }

    filter(condition: any) {
        this.gridRef.current?.filter(condition);
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return (
            <ContentBlock caption={"车辆人员"}>
                <VehicleUserToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onButtonClick={this.onButtonClick}
                    onFiltering={this.filter} />
                <VehicleUserGrid
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
                <VehicleUserForm ref={this.formRef} onSaved={this.onSaved} />
            </ContentBlock>
        );
    }
}