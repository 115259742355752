import copy from "copy-to-clipboard";
import React from "react";
import AuthorizedComponent from "../authorized-component";
import ShippingGrid from "./shipping-grid";
import ShippingToolbar from "./shipping-toolbar";
import { notifySuccess, enums, historyInstance, baseApiUrl, download, alertError, confirm2 } from "../../base";
import { ContentBlock, ShippingBottlePopup } from "../../components";
import { ShippingModel } from "../../models";
import { shippingService } from "../../api";
import { formatDate } from "devextreme/localization";

export default class ShippingPage extends AuthorizedComponent {
    objectName = "Shipping";
    popupRef = React.createRef<ShippingBottlePopup>();
    toolbarRef = React.createRef<ShippingToolbar>();
    gridRef = React.createRef<ShippingGrid>();

    constructor(props: any) {
        super(props);

        this.onShippingSelected = this.onShippingSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.filter = this.filter.bind(this);
    }

    onShippingSelected(model: ShippingModel) {
        this.toolbarRef.current?.setModel(model);
    }

    async onButtonClick(model: ShippingModel, name: string) {
        switch (name) {
            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    historyInstance.push("/new-shipping");
                }
                break;

            case "export":
                if (model.shippingType !== enums.bottleOperations.allot) {
                    alertError("平台端只支持导出充装调拨的运输记录");
                    return;
                } else if (!await confirm2("确定导出运输记录吗？")) {
                    return;
                }
                
                const url = `${baseApiUrl}${shippingService.apiPath}/export/${model.id}`;
                download(url, `${formatDate(new Date(model.shippingTime!), 'yyyyMMddHHmmss')}运输记录.xls`);
                break;
                
            case "view":
                this.popupRef.current?.show(model.bottleCodes!.join('，'));
                break;

            case "copy":
                copy(model.bottleCodes!.join('，'))
                notifySuccess("已复制到剪贴板")
                break;
        }
    }

    filter(filterValue: any) {
        this.gridRef.current?.filter(filterValue);
    }

    render() {
        return (
            <ContentBlock caption={"运输记录"}>
                <ShippingToolbar 
                    permissions={this.permissions}
                    ref={this.toolbarRef} 
                    onFiltering={this.filter}
                    onButtonClick={this.onButtonClick} />
                <ShippingGrid 
                    ref={this.gridRef}
                    onShippingSelected={this.onShippingSelected} 
                    onButtonClick={this.onButtonClick} />
                <ShippingBottlePopup ref={this.popupRef} />
            </ContentBlock>
        );
    }
}