import React from "react";
import { emptyDataSources, organizationService } from "../../api";
import { DataMap, dateAdd, enums, today } from "../../base";
import { CustomerCallbackModel, OrganizationRef } from "../../models";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { ValueChangedEvent as TextChangedEvent } from "devextreme/ui/text_box";
import { Button, DateBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { OrgTreeDropDown } from "../../components";

export interface CustomerCallbackToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (model: CustomerCallbackModel, name: string) => void;
    onFiltering: (filterValue: any) => void;
}

interface CustomerCallbackToolbarState {
    model: CustomerCallbackModel;
    orgs: OrganizationRef[];
    organizationId?: number;
    startDate?: Date | string;
    endDate?: Date | string;
    customerKey?: string;
}

export default class CustomerCallbackToolbar
    extends React.Component<CustomerCallbackToolbarProps, CustomerCallbackToolbarState> {
    constructor(props: Readonly<CustomerCallbackToolbarProps>) {
        super(props);

        const endDate = today();

        this.state = {
            model: {},
            orgs: [],
            startDate: dateAdd(endDate, -1, "m"),
            endDate: endDate,
        };

        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onCustomerChanged = this.onCustomerChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount() {
        this.loadDataSources();
        setTimeout(this.onFiltering, 100);
    }

    async loadDataSources() {
        const dataSources = emptyDataSources();

        await Promise.all([
            (async () => dataSources.orgs = await organizationService.getRefs())(),
        ]);

        this.setState({
            orgs: dataSources.orgs,
        });
    }

    setModel(model: CustomerCallbackModel) {
        this.setState({ model: model });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onOrganizationChanged(organizationId?: number) {
        this.setState({
            organizationId: organizationId
        });
    }

    onFiltering() {
        this.props.onFiltering(this.getFilterValue());
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { startDate, endDate, organizationId, customerKey } = this.state;

        if (startDate) {
            filters.push(["callbackTime", ">=", startDate]);
        }
        if (endDate) {
            filters.push(["callbackTime", "<", dateAdd(new Date(endDate), 1)])
        }
        if (organizationId) {
            filters.push(["organizationId", "=", organizationId]);
        }
        if (customerKey) {
            filters.push([
                ["customer.gasCode", "contains", customerKey],
                "or",
                ["customer.name", "contains", customerKey]
            ]);
        }

        return filters.length > 0 ? filters : null;
    }

    onCustomerChanged(e: TextChangedEvent) {
        this.setState({ customerKey: e.value });
    }

    render() {
        const { model, orgs, organizationId, startDate, endDate, customerKey } = this.state;
        const permissions = this.props.permissions;

        return (
            <div className={"page-toolbar page-large-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu="false" text={"评价时间"}>
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={startDate}
                            placeholder="开始时间"
                            width={120}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束时间"
                            width={120}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <TextBox
                            value={customerKey}
                            showClearButton={true}
                            placeholder="客户名称或卡号"
                            onValueChanged={this.onCustomerChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={!!permissions[enums.stdActions.create]}>
                        <Button
                            text="新增"
                            onClick={() => this.props.onButtonClick({}, "add")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={!!permissions[enums.stdActions.update]}>
                        <Button
                            disabled={!model.id}
                            text="编辑"
                            onClick={() => this.props.onButtonClick(model, "edit")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={!!permissions[enums.stdActions.delete]}>
                        <Button
                            disabled={!model.id}
                            text="删除"
                            onClick={() => this.props.onButtonClick(model, "delete")} />
                    </Item>

                </Toolbar>
            </div>
        );
    }

}