import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { ExportingEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import CustomStore from "devextreme/data/custom_store";
import { areaService, customerTypeService, DataSources, emptyDataSources, serverDataService } from "../../../api";
import { dataSources, enums, DataMap, baseApiUrl, globalPopupManager, globalPopupNames, authorizationService, StringMap, ModuleConfig, createEmptyStore, http } from "../../../base";
import { StdGrid, createGridOptions, remoteReadOnlyGridOptions, setColumnDataSource, ExportModel } from "../../../components";
import { CustomerBottleCondition, CustomerWithBottles } from "../../../models";
import { customerReportService } from "../../../api/reports/customer-report.service";

export interface CustomerBottleReportGridProps {
    permissions: DataMap<boolean>;
    onCustomerSelected(customer?: CustomerWithBottles): void;
    onButtonClick(customer: CustomerWithBottles, name: string): void;
}

interface CustomerBottleReportGridState {
    dataSources: DataSources;
    columns: IColumnProps[];
    customerStore: CustomStore;
    conditionJson?: string;
}

export default class CustomerBottleReportGrid
    extends React.Component<CustomerBottleReportGridProps, CustomerBottleReportGridState> {

    moduleName: ModuleConfig = {
        moduleName: "_",
        children: {
            customer: {
                moduleName: "Customer",
                children: {
                    deliver: 'Employee',
                    organization: 'Organization'
                }
            }
        }
    }

    objectName = "Customer";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("customerBottleReport", this.gridRef, {
        ...remoteReadOnlyGridOptions,
        summary: {
            groupItems: [
                { summaryType: "count" },
                { summaryType: "sum", column: "bottleCount", displayFormat: "总气瓶数：{0}" }
            ]
        },
    },
        (items: any[]) => {
            // state清空会同时清空filter，需要重新绑定旧的
            items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
            return items;
        }
    );

    nameMap: StringMap = {
        "customer.organization.name": "customer.organizationId",
        "customer.deliver.name": "customer.deliverId"
    }

    constructor(props: Readonly<CustomerBottleReportGridProps>) {
        super(props);

        this.state = {
            dataSources: emptyDataSources(),
            customerStore: createEmptyStore(),
            columns: [
                { dataField: 'bottleCount', caption: '气瓶数' },
                { dataField: 'customer.gasCode' },
                { dataField: 'customer.name' },
                {
                    dataField: 'customer.typeId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: 'customer.phoneNumber' },
                { dataField: 'customer.phoneNumber2' },
                {
                    dataField: 'customer.areaId',
                    allowFiltering: false,
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                {
                    dataField: 'customer.streetId',
                    allowFiltering: false,
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: 'customer.address' },
                {
                    dataField: 'customer.organization.name',
                    allowFiltering: false,
                },
                {
                    dataField: 'customer.deliver.name',
                    calculateFilterExpression: function (filterValue: any, op: string) {
                        return [
                            ["customer.deliver.name", op, filterValue],
                            "or",
                            ["customer.deliver.code", op, filterValue]
                        ]
                    },
                    cellRender: (e: any) => (<span>{e.data.customer.deliver?.nameAndCode}</span>)
                },
                {
                    dataField: 'customer.status',
                    minWidth: 80,
                    allowFiltering: false,
                    lookup: { dataSource: dataSources.customerStatus, displayExpr: 'text', valueExpr: 'value' }
                },
                {
                    dataField: 'lastOrderTime',
                    caption: '最后下单时间',
                    dataType: "datetime"
                },
                ...serverDataService.bottleTypes.map((t) => ({
                    dataField: `count_${t.id}`,
                    caption: `${t.name}数量`,
                    allowGrouping: false,
                    allowSorting: false,
                    allowFiltering: false,
                    cellRender: (e: any) => {
                        const amount = e.data.typedBarcodes[t.id || 0]?.length || 0;
                        return (
                            <span>{amount}</span>
                        )
                    }
                })),
                ...serverDataService.bottleTypes.map((t) => ({
                    dataField: `codes_${t.id}`,
                    caption: `${t.name}气瓶`,
                    allowGrouping: false,
                    allowSorting: false,
                    allowFiltering: false,
                    cellRender: (e: any) => {
                        const list = e.data.typedBarcodes[t.id || 0] || [];
                        return (
                            <span>{list.join("，")}</span>
                        )
                    }
                }))
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = (e) => this.props.onButtonClick(e.data, "callcenter");
        this.gridOptions.onExporting = this.onExporting.bind(this);
        this.gridOptions.export!.allowExportSelectedData = false;
        this.gridOptions.export!.enabled = false;
        this.gridOptions.summary = {
            totalItems: [
                { column: "bottleCount", summaryType: "sum", displayFormat: "计：{0}" }
            ]
        }
    }

    componentDidMount() {
        this.authorizeImport();
        this.loadDataSources();
    }

    async authorizeImport() {
        if (await authorizationService.authorize(this.objectName, enums.stdActions.export)) {
            this.gridOptions.export!.enabled = true;
            this.gridRef.current?.instance.option("export.enabled", true);
        }
    }

    async loadDataSources() {
        const dataSources = emptyDataSources();

        await Promise.all([
            (async () => dataSources.types = await customerTypeService.getRefs())(),
            (async () => dataSources.counties = await areaService.getCounties())(),
            (async () => dataSources.streets = await areaService.getStreets())(),
        ]);

        this.setDataSource({
            "customer.typeId": dataSources.types,
            "customer.areaId": dataSources.counties,
            "customer.streetId": dataSources.streets,
        }, this.state.columns);

        this.setState({ dataSources: dataSources });
    }

    setDataSource(map: { [key: string]: any[] }, columns: IColumnProps[]) {
        for (const key in map) {
            setColumnDataSource(columns, key, map[key]);
        }
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onCustomerSelected(e.selectedRowsData[0]);
        } else {
            this.props.onCustomerSelected(undefined);    // 为避免混乱，多选不能编辑
        }
    }

    onExporting(e: ExportingEvent) {
        const args: ExportModel = {
            moduleName: "Customer",
            exportUrl: `${baseApiUrl}/api/report/customer/export/withbottles?` +
                http.serialize([JSON.parse(this.state.conditionJson || '')]),
            columns: this.state.columns,
            keys: e.component.getSelectedRowKeys(),
            filter: e.component.getCombinedFilter()
        };

        e.cancel = true;
        globalPopupManager.show(globalPopupNames.exportPopup, args);
    }

    async refresh() {
        await this.gridRef.current?.instance.refresh();
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    filter(filterValue: any, condition: CustomerBottleCondition) {
        if (JSON.stringify(condition) !== this.state.conditionJson || !this.state.customerStore.key?.length) {
            this.setState({
                conditionJson: JSON.stringify(condition),
                customerStore: customerReportService.getWithBottles(condition)
            }, () => this.gridRef.current?.instance.filter(filterValue));
        } else {
            this.gridRef.current?.instance.filter(filterValue);
        }
    }

    getSelectedCustomer() {
        return this.gridRef.current?.instance.getSelectedRowsData()[0];
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.customerStore}
                options={this.gridOptions}
                columns={this.state.columns}
                columnNameMap={this.nameMap}
                toolbarHeight={80}>
            </StdGrid>
        );
    }
}