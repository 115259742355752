import React from "react";
import CustomerGrid from "./customer-grid";
import CustomerForm from "./customer-form";
import CustomerToolbar from "./customer-toolbar";
import TroubleshootingForm from "../security/troubleshooting-form";
import AuthorizedComponent from "../authorized-component";
import { ContentBlock, IssueDangerPopup, BarcodePopup, ExitCustomerPopup, SecurityCodePopup } from "../../components";
import { alertError, confirm2, notifySuccess, prompt2, enums, authorizationService, alert2 } from "../../base";
import { customerService } from "../../api";
import { CustomerBatchModel, CustomerCondition, CustomerModel } from "../../models";

export default class CustomerPage extends AuthorizedComponent {
    objectName = "Customer";

    gridRef = React.createRef<CustomerGrid>();
    formRef = React.createRef<CustomerForm>();
    toolbarRef = React.createRef<CustomerToolbar>();
    dangerRef = React.createRef<IssueDangerPopup>();
    troubleshootingRef = React.createRef<TroubleshootingForm>();
    barcodeRef = React.createRef<BarcodePopup>();
    exitCustomerRef = React.createRef<ExitCustomerPopup>();
    securityCodeRef = React.createRef<SecurityCodePopup>();

    constructor(props: any) {
        super(props);

        this.onCustomerSelected = this.onCustomerSelected.bind(this);
        this.onRowChecked = this.onRowChecked.bind(this);
        this.filterSpecial = this.filterSpecial.bind(this);
        this.filter = this.filter.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onBatchButtonClick = this.onBatchButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
        this.onCustomerExit = this.onCustomerExit.bind(this);
    }

    async authorize(): Promise<void> {
        await super.authorize();

        this.permissions["Lock"] = await authorizationService.authorize(this.objectName, "Lock");
        this.permissions["Barcode"] = await authorizationService.authorize(this.objectName, "Barcode");
        this.permissions["UnbindWeiXin"] = await authorizationService.authorize(this.objectName, "UnbindWeiXin");
        this.permissions["AddOrder"] = await authorizationService.authorize("Order", enums.stdActions.create);
        this.permissions["IssueDanger"] = await authorizationService.authorize("Danger", "Issue");
        this.permissions["AddTrouble"] =
            await authorizationService.authorize("Troubleshooting", enums.stdActions.create);

        this.setState({});
    }

    onCustomerSelected(customer: CustomerModel) {
        this.toolbarRef.current?.setCustomer(customer);
    }

    onRowChecked(customers: CustomerBatchModel[]) {
        this.toolbarRef.current?.setBatchCustomers(customers);
    }

    filterSpecial(condition: CustomerCondition, filterValue: any) {
        this.gridRef.current?.filterSpecial(condition, filterValue);
    }

    filter(filterValue: any) {
        this.gridRef.current?.filter(filterValue);
    }

    onButtonClick(customer: CustomerModel, name: string) {
        switch (name) {
            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.edit({ status: enums.customerStatus.active });
                }
                break;

            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.edit(customer);
                }
                break;

            case "issueDanger":
                if (this.permissions["IssueDanger"]) {
                    this.issueDanger(customer);
                }
                break;

            case "addTrouble":
                if (this.permissions["AddTrouble"]) {
                    this.addTrouble(customer);
                }
                break;

            case "changeGasCode":
                if (this.permissions[enums.stdActions.update]) {
                    this.changeGasCode(customer);
                }
                break;

            case "barcode":
                this.barcodeRef.current?.show(customer.gasCode ?? "");
                break;

            case "unbindWeiXin":
                if (this.permissions["UnbindWeiXin"]) {
                    this.unbindWeiXin(customer);
                }
                break;

            case "changeStatus":
                if (this.permissions[enums.stdActions.update]) {
                    this.changeStatus([customer as any]);
                }
                break;

            case "switchLock":
                if (this.permissions["Lock"]) {
                    this.switchLock([customer as any]);
                }
                break;
        }
    }

    onBatchButtonClick(customers: CustomerBatchModel[], name: string) {
        switch (name) {
            case "changeStatus":
                if (this.permissions[enums.stdActions.update]) {
                    this.changeStatus(customers);
                }
                break;

            case "switchLock":
                if (this.permissions["Lock"]) {
                    this.switchLock(customers);
                }
                break;

            case "printSecurityCode":
                this.printSecurityCode(customers);
                break;

            case "switchStdAddress":
                this.switchStdAddress(customers);
                break;
        }
    }

    edit(customer: CustomerModel) {
        this.formRef.current?.show(customer);
    }

    async onSaved() {
        await this.gridRef.current?.refresh();
        this.toolbarRef.current?.setBatchCustomers(this.gridRef.current?.getSelectedCustomers() || []);
        this.toolbarRef.current?.setCustomer(this.gridRef.current?.getSelectedCustomer());
    }

    issueDanger(customer: CustomerModel) {
        this.dangerRef.current?.show(customer);
    }

    addTrouble(customer: CustomerModel) {
        this.troubleshootingRef.current?.show({ customer: customer, reportTime: new Date() });
    }

    async changeGasCode(customer: CustomerModel) {
        const newCode = (await prompt2("请输入新的卡号："))?.trim();

        if (!newCode) {
            return;
        } else if (newCode.length !== 6 && newCode.length !== 8) {
            await alert2("卡号不正确，卡号长度应为6位或8位");
            this.changeGasCode(customer);
            return;
        }

        const result = await customerService.changeGasCode(customer.id!, newCode);

        if (result.succeeded) {
            notifySuccess("已成功为客户更新卡号");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async changeStatus(customers: CustomerBatchModel[]) {
        const status = customers[0].status;

        if (status === enums.customerStatus.inactive) {
            if (await confirm2("确定要恢复选中的客户吗？")) {
                const result = await customerService.batchActivate(customers.map(c => c.id));
                if (result.succeeded) {
                    notifySuccess("已成功恢复客户");
                    this.gridRef.current?.clearSelection();
                    await this.gridRef.current?.refresh();
                    this.toolbarRef.current?.setBatchCustomers(this.gridRef.current?.getSelectedCustomers() || []);
                } else {
                    alertError(result.message!);
                }
            }
        } else {
            this.exitCustomerRef.current?.show(customers.map(c => c.id));
        }
    }

    onCustomerExit() {
        this.gridRef.current?.clearSelection();
        this.onSaved();
    }

    async switchLock(customers: CustomerBatchModel[]) {
        const locked = customers[0].locked,
            action = locked ? "解锁" : "锁定";

        const note = await prompt2(`确定要${action}选中的客户吗？请输入备注（可选）`);

        if (note === undefined) {
            return;
        }

        const result = await customerService.batchSwitchLock(customers.map(c => c.id), note);

        if (result.succeeded) {
            notifySuccess(`已成功${action}客户`);
            this.gridRef.current?.clearSelection();
            await this.gridRef.current?.refresh();
            this.toolbarRef.current?.setBatchCustomers(this.gridRef.current?.getSelectedCustomers() || []);
        } else {
            alertError(result.message!);
        }
    }

    async unbindWeiXin(customer: CustomerModel) {
        if (!await confirm2('确定要为该客户解绑微信吗？')) {
            return;
        }

        const result = await customerService.unbindWeiXin(customer.id!);

        if (result.succeeded) {
            notifySuccess(`已成功解绑微信`);
            await this.gridRef.current?.refresh();
            this.toolbarRef.current?.setCustomer(this.gridRef.current?.getSelectedCustomer());
        } else {
            alertError(result.message!);
        }
    }

    async printSecurityCode(customers: CustomerBatchModel[]) {
        this.securityCodeRef.current?.show(customers);        
    }

    async switchStdAddress(customers: CustomerBatchModel[]) {
        const isStdAddress = customers[0].isStdAddress,
            action = isStdAddress ? "不是" : "是";

        if (!await confirm2(`确定选中的客户地址${action}标准地址吗？`)) {
            return;
        }

        const result = await customerService.batchSwitchStdAddress(customers.map(c => c.id));

        if (result.succeeded) {
            notifySuccess(`已成功设置`);
            this.gridRef.current?.clearSelection();
            await this.gridRef.current?.refresh();
            this.toolbarRef.current?.setBatchCustomers(this.gridRef.current?.getSelectedCustomers() || []);
        } else {
            alertError(result.message!);
        }
    }

    render() {
        return (
            <ContentBlock caption={"客户管理"}>
                <CustomerToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onSpecialFiltering={this.filterSpecial}
                    onFiltering={this.filter}
                    onButtonClick={this.onButtonClick}
                    onBatchButtonClick={this.onBatchButtonClick} />
                <CustomerGrid
                    permissions={this.permissions}
                    ref={this.gridRef}
                    onCustomerSelected={this.onCustomerSelected}
                    onRowChecked={this.onRowChecked}
                    onButtonClick={this.onButtonClick} />
                <CustomerForm ref={this.formRef} onSaved={this.onSaved} />
                <IssueDangerPopup ref={this.dangerRef} />
                <TroubleshootingForm ref={this.troubleshootingRef} />
                <BarcodePopup ref={this.barcodeRef} />
                <ExitCustomerPopup ref={this.exitCustomerRef} onSaved={this.onCustomerExit} />
                <SecurityCodePopup ref={this.securityCodeRef} onSaved={this.onSaved} />
            </ContentBlock>
        );
    }
}