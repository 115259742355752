import React from "react";
import AuthorizedComponent from "../authorized-component";
import { ContentBlock } from "../../components";
import CpLogGrid from "./cp-log-grid";
import CpLogToolbar from "./cp-log-toolbar";

export default class CpLogPage extends AuthorizedComponent {
    objectName = "CpLog";
    gridRef = React.createRef<CpLogGrid>();
    toolbarRef = React.createRef<CpLogToolbar>();

    constructor(props: any) {
        super(props);

        this.onFiltering = this.onFiltering.bind(this);
    }

    onFiltering(filterValue: any) {
        this.gridRef.current?.filter(filterValue);
    }

    render() {
        return (
            <ContentBlock caption="打印日志">
                <CpLogToolbar ref={this.toolbarRef} onFiltering={this.onFiltering} />
                <CpLogGrid ref={this.gridRef} />
            </ContentBlock>
        )
    }
}