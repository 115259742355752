import { Popup, TextArea } from "devextreme-react";
import React from "react";
import { RefPopup } from "../popup";

interface ShippingBottlePopupState {
    data?: string;
}

export default class ShippingBottlePopup extends React.Component<any, ShippingBottlePopupState> {
    popupRef = React.createRef<Popup>();

    constructor(props: any) {
        super(props);

        this.state = { };
    }

    show(data: string) {
        this.popupRef.current?.instance.show();
        
        this.setState({ data: data });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"钢瓶条码"}
                width={800}
                height={500}>
                <TextArea readOnly={true} height="100%" value={this.state.data} />
            </RefPopup>
        );
    }
}