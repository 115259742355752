import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import React from "react";
import { fillingCheckTypeStore } from "../../api";
import { dataSources, enums } from "../../base";
import { createGridOptions, StdGrid, stdReadOnlyGridOptions } from "../../components";
import { FillingCheckTypeModel } from "../../models";

export interface FillingCheckTypeGridProps {
    onButtonClick: (model: FillingCheckTypeModel, name: string) => void;
    onModelSelected: (model?: FillingCheckTypeModel) => void;
}

export default class FillingCheckTypeGrid extends React.Component<FillingCheckTypeGridProps> {
    moduleName = "FillingCheckType";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("fillingCheckTypes", this.gridRef, stdReadOnlyGridOptions);

    columns: IColumnProps[] = [
        {
            dataField: 'checkType',
            lookup: { dataSource: dataSources.fillingCheckTypes, displayExpr: 'text', valueExpr: 'value' }
        },
        { dataField: 'sortNumber' },
        { dataField: 'itemName' },
        { dataField: 'syncFieldName' },
        { 
            dataField: 'displayType',
            lookup: { dataSource: dataSources.fillingCheckTypeValues, displayExpr: 'text', valueExpr: 'value' }
        },
        {
            dataField: 'defaultValue',
            cellRender: (e) => (
                <div>
                    {typeof(e.data.defaultValue) !== "boolean" ? 
                    "" : 
                    e.data.displayType === enums.fillingCheckTypeValues.trueFalse ? 
                    dataSources.trueFalseOfPass.find(i => i.value === e.data.defaultValue)?.text :
                    dataSources.qualifiedOrNot.find(i => i.value === e.data.defaultValue)?.text}
                </div>
            )
        },
    ];

    constructor(props: Readonly<FillingCheckTypeGridProps>) {
        super(props);

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});
        }
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={fillingCheckTypeStore}
                options={this.gridOptions}
                columns={this.columns}
                popupWidth={400}
                popupHeight={380} />
        );
    }
}