export type UBoxEventHandler = (phoneNumber: string) => void;

class UBoxPlugIn {
    ws?: WebSocket;
    handlers: UBoxEventHandler[] = [];

    start(handler?: UBoxEventHandler) {
        if (handler) {
            this.handlers.push(handler);
        }

        const protocol = (window.location.protocol === "https") ? "wss://" : "ws://";
        const ws = new WebSocket(`${protocol}127.0.0.1:8054`);
        this.ws = ws;
        ws.onopen = function (ev) { console.log(ev); }
        ws.onerror = function (ev) { console.log(ev); }
        ws.onclose = function (ev) { console.log(ev); }

        ws.onmessage = (ev) => {
            // eslint-disable-next-line
            const msg = eval(`(${ev.data})`);

            if (msg.event === "callerId") {
                for (const handler of this.handlers) {
                    handler(msg.callernumber);
                }
            }
        }
    }

    stop() {
        this.ws?.close();
    }

    addHandler(handler: UBoxEventHandler) {
        const index = this.handlers.findIndex(h => h === handler);
        if (index === -1) {
            this.handlers.push(handler);
        }
    }

    removeHandler(handler: UBoxEventHandler) {
        const index = this.handlers.findIndex(h => h === handler);
        if (index > -1) {
            this.handlers.splice(index, 1);
        }
    }

    clearHandler() {
        this.handlers.length = 0;
    }
}

export const ubox = new UBoxPlugIn();