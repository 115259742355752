import React from "react";
import BottleFlowGrid from "./bottle-flow-grid";
import BottleFlowToolbar from "./bottle-flow-toolbar";
import { ContentBlock, IssueDangerPopup } from "../../components";
import { BottleFlowModel } from "../../models";
import AuthorizedComponent from "../authorized-component";
import { alertError, authorizationService, confirm2, enums, globalPopupManager, globalPopupNames, notifySuccess } from "../../base";
import { bottleFlowService, bottlePositionService, customerService } from "../../api";

export default class BottleFlowPage extends AuthorizedComponent<any> {
    objectName = "BottleFlow";
    gridRef = React.createRef<BottleFlowGrid>();
    toolbarRef = React.createRef<BottleFlowToolbar>();
    dangerRef = React.createRef<IssueDangerPopup>();

    get actionNames(): string[] {
        return ["Global", "SetFlag"];
    }

    async authorize(): Promise<void> {
        await super.authorize();

        this.permissions["IssueDanger"] = await authorizationService.authorize("Danger", "Issue");
        this.permissions["UpdatePosition"] = await authorizationService.authorize("BottlePosition", enums.stdActions.update);
    }

    constructor(props: any) {
        super(props);

        this.filter = this.filter.bind(this);
        this.onModelSelected = this.onModelSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    filter(filterValue: any) {
        this.gridRef.current?.filter(filterValue);
    }

    onModelSelected(model: BottleFlowModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onButtonClick(model: BottleFlowModel, name: string) {
        switch (name) {
            case "callCenter":
                globalPopupManager.show(globalPopupNames.callCenter, { id: model.customerId! });
                break;

            case "issueDanger":
                if (this.permissions["IssueDanger"]) {
                    this.issueDanger(model.customerId!);
                }
                break;

            case "updateposition":
                if (this.permissions["UpdatePosition"]) {
                    this.updatePosition(model);
                }
                break;

            case "setflag":
                if (this.permissions["SetFlag"]) {
                    this.setFlag(model);
                }
        }
    }

    async issueDanger(customerId: number) {
        const customer = await customerService.get(customerId);
        this.dangerRef.current?.show(customer);
    }

    async updatePosition(model: BottleFlowModel) {
        if (!await confirm2("确定要修改气瓶位置吗？")) {
            return;
        }
        
        const res = await bottlePositionService.update(model.bottleId!, model.id);

        if (res.succeeded) {
            notifySuccess("已成功修改气瓶最后位置");
        } else {
            alertError(res.message!);
        }
    }
    
    async setFlag(model: BottleFlowModel) {
        if (model.flag) {
            if (!await confirm2("确定要删除标志吗？该操作不可逆")) {
                return;
            }

            const res = await bottleFlowService.setFlag(model.id!);

            if (res.succeeded) {
                this.gridRef.current?.refresh();
            } else {
                alertError(res.message!);
            }
        } else {
            if (!await confirm2("确定要该记录标记为错误吗？")) {
                return;
            }

            const res = await bottleFlowService.setFlag(model.id!, enums.bottleFlowFlags.error);

            if (res.succeeded) {
                this.gridRef.current?.refresh();
            } else {
                alertError(res.message!);
            }
        }
    }

    render() {
        return (
            <ContentBlock caption={"气瓶流转"}>
                <BottleFlowToolbar
                    ref={this.toolbarRef}
                    permissions={this.permissions}
                    onFiltering={this.filter}
                    onButtonClick={this.onButtonClick} />
                <BottleFlowGrid
                    showDangerIssueButton={true}
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
                <IssueDangerPopup ref={this.dangerRef} />
            </ContentBlock>
        );
    }

}