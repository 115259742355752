import { BaseApiService, createStoreFromUrl, http, MessageBool } from "../base";
import { CpNewBatchModel, CpNewModel } from "../models";

class CpNewService extends BaseApiService<CpNewModel> {
    moduleName = "cpnew";

    getDxData(batchId?: number) {
        return createStoreFromUrl(`${this.apiPath}/dx?batchId=${batchId || ''}`, 'id');
    }

    getTemplate(batchId: number) {
        return http.getData<CpNewModel>(`${this.apiPath}/template?batchId=${batchId}`);
    }

    deleteSelected(ids: number[]) {
        return http.deleteData<MessageBool>(`${this.apiPath}?ids=${ids}`);
    }

    getBatch(batchId: number) {
        return http.getData<CpNewBatchModel>(`${this.apiPath}/batch/${batchId}`);
    }
}

export const cpNewService = new CpNewService();
export const cpNewStore = cpNewService.getDxData();