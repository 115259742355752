import React from "react";
import TroubleshootingGrid from "./troubleshooting-grid";
import TroubleshootingForm from "./troubleshooting-form";
import TroubleshootingToolbar from "./troubleshooting-toolbar";
import AuthorizedComponent from "../authorized-component";
import { AssignEmployeePopup, ContentBlock } from "../../components";
import { enums, alertError, confirm2, notifySuccess, globalPopupManager, globalPopupNames } from "../../base";
import { troubleshootingService, employeeService } from "../../api";
import { EmployeeRef, TroubleshootingModel } from "../../models";

export default class TroubleshootingPage extends AuthorizedComponent {
    objectName = "Troubleshooting";
    gridRef = React.createRef<TroubleshootingGrid>();
    formRef = React.createRef<TroubleshootingForm>();
    toolbarRef = React.createRef<TroubleshootingToolbar>();
    employeeRef = React.createRef<AssignEmployeePopup>();
    modelOfAssign: TroubleshootingModel = {};

    get actionNames(): string[] {
        return [
            enums.stdActions.create,
            enums.stdActions.delete,
            "Assign"
        ]
    }

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
        this.getHandlers = this.getHandlers.bind(this);
        this.assignHandler = this.assignHandler.bind(this);
        this.filter = this.filter.bind(this);
    }

    onModelSelected(model: TroubleshootingModel) {
        this.toolbarRef.current?.setModel(model);
    }

    filter(filterValue: any, showDeleted: boolean) {
        this.gridRef.current?.filter(filterValue, showDeleted);
    }

    onButtonClick(model: TroubleshootingModel, name: string) {
        switch (name) {
            case "callcenter":
                globalPopupManager.show(globalPopupNames.callCenter, { id: model.customerId });
                break;

            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.formRef.current?.show({ reportTime: new Date() });
                }
                break;

            case "assign":
                if (model.status !== enums.troubleshootingStatus.complete && this.permissions["Assign"]) {
                    this.showEmployeePopup(model);
                }
                break;

            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(model);
                }
                break;

            case "restore":
                if (this.permissions[enums.stdActions.delete]) {
                    this.restore(model);
                }
                break;
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    showEmployeePopup(model: TroubleshootingModel) {
        this.modelOfAssign = model;
        this.employeeRef.current?.show(model.handlerId);
    }

    async getHandlers() {
        const handlers = await employeeService.getRefs(
            enums.employeePosts.deliver,
            enums.employeePosts.securityInspector);

        return handlers.filter(c => c.organizationId === this.modelOfAssign.organizationId);
    }

    async assignHandler(employee: EmployeeRef) {
        const result = await troubleshootingService.assign(this.modelOfAssign.id!, employee.id!);

        if (result.succeeded) {
            notifySuccess("成功分配抢修人员");
            this.employeeRef.current?.hide();
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async restore(model: TroubleshootingModel) {
        if (!await confirm2("确定要还原该条数据吗？")) {
            return;
        }

        const result = await troubleshootingService.restore(model.id!);

        if (result.succeeded) {
            notifySuccess("已成功还原数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async delete(model: TroubleshootingModel) {
        if (!await confirm2("确定要作废此抢修数据吗？")) {
            return;
        }

        const result = await troubleshootingService.delete(model.id!);

        if (result.succeeded) {
            notifySuccess("已成功作废抢修数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    render() {
        return (
            <ContentBlock caption={"抢修管理"}>
                <TroubleshootingToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onFiltering={this.filter}
                    onButtonClick={this.onButtonClick} />
                <TroubleshootingGrid
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
                <TroubleshootingForm ref={this.formRef} onSaved={this.onSaved} />
                <AssignEmployeePopup ref={this.employeeRef} getFunc={this.getHandlers} save={this.assignHandler} />
            </ContentBlock>
        )
    }
}