import "./toolbar-position-filter.scss";
import { SelectBox, TextBox } from "devextreme-react";
import { enums, ListItem } from "../../base";
import React from "react";

export interface ToolbarPositionFilterEvent {
    key?: string;
    keyType?: string;
}

export interface ToolbarPositionFilterProps {
    keyType: string;
    onChanged: (e: ToolbarPositionFilterEvent) => void;
    onKeyTypeChanged: (e: string) => void;
}

export const bottleKeyType = "Bottle";
const keyTypes: ListItem[] = [
    { value: bottleKeyType, text: "钢瓶" },
    { value: enums.bottlePositions.customer, text: "客户" },
    { value: enums.bottlePositions.deliveryman, text: "送气工" },
    { value: enums.bottlePositions.site, text: "供应站" }
];

export default class ToolbarPositionFilter extends React.Component<ToolbarPositionFilterProps> {
    constructor(props: Readonly<ToolbarPositionFilterProps>) {
        super(props);

        this.onKeyTypeChanged = this.onKeyTypeChanged.bind(this);
        this.onKeyChanged = this.onKeyChanged.bind(this);
    }

    onKeyTypeChanged(e: any) {
        this.props.onKeyTypeChanged(e.value);
    }

    onKeyChanged(e: any) {
        if (e.value?.trim()) {
            this.props.onChanged({ key: e.value, keyType: this.props.keyType });
        }
    }

    render() {
        return (
            <div className={"toolbar-position-filter"}>
                <SelectBox
                    dataSource={keyTypes}
                    displayExpr={"text"}
                    valueExpr={"value"}
                    value={this.props.keyType}
                    width={80}
                    onValueChanged={this.onKeyTypeChanged} />
                <TextBox onValueChanged={this.onKeyChanged} />
            </div>
        );
    }
}