import { createStoreFromUrl, DataBool, http, MessageBool } from "../base";
import { RecycleRequestModel } from "../models";

class RecycleRequestService  {
    apiPath = "/api/recyclerequest";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }

    discard(id: number, note: string) {
        return http.putData<MessageBool>(`${this.apiPath}/discard`,  { id: id, note: note });
    }

    assign(id: number, deliverId: string) {
        return http.putData<MessageBool>(`${this.apiPath}/assign/${id}?deliverId=${deliverId}`, '');
    }

    create(model: RecycleRequestModel) {
        return http.postData<DataBool<RecycleRequestModel>>(`${this.apiPath}`, model);
    }
}

export const recycleRequestService = new RecycleRequestService();
export const recycleRequestStore = recycleRequestService.getDxData();