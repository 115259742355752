import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { ExportingEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import CustomStore from "devextreme/data/custom_store";
import { customerService, serverDataService } from "../../api";
import { dataSources, enums, DataMap, baseApiUrl, globalPopupManager, globalPopupNames, authorizationService, fdate, http, StringMap, ModuleConfig, createEmptyStore } from "../../base";
import { StdGrid, createGridOptions, remoteReadOnlyGridOptions, setColumnDataSource, ExportModel, createBoolColumn } from "../../components";
import { DataSources, emptyDataSources, loadCustomerDataSources } from "../../api/data-source-helper";
import { CustomerBatchModel, CustomerCondition, CustomerModel } from "../../models";

export interface CustomerGridProps {
    permissions: DataMap<boolean>;
    onCustomerSelected(customer: CustomerModel): void;
    onRowChecked(customers: CustomerBatchModel[]): void;
    onButtonClick(customer: CustomerModel, name: string): void;
}

interface CustomerState {
    dataSources: DataSources;
    columns: IColumnProps[];
    customerStore: CustomStore;
    condition: CustomerCondition;
}

export default class CustomerGrid extends React.Component<CustomerGridProps, CustomerState> {
    moduleName: ModuleConfig = {
        moduleName: "Customer",
        children: {
            deliver: 'Employee',
            organization: 'Organization'
        }
    }
    objectName = "Customer";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("customers", this.gridRef, {
        ...remoteReadOnlyGridOptions,
        summary: {
            groupItems: [{ summaryType: "count" }]
        },
    },
        (items: any[]) => {
            // state清空会同时清空filter，需要重新绑定旧的
            items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
            return items;
        }
    );

    nameMap: StringMap = {
        "organization.name": "organizationId",
        "deliver.name": "deliverId"
    }

    constructor(props: Readonly<CustomerGridProps>) {
        super(props);

        this.state = {
            dataSources: emptyDataSources(),
            customerStore: createEmptyStore(),
            condition: {},
            columns: [
                { dataField: 'gasCode' },
                { dataField: 'name' },
                {
                    dataField: 'typeId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: 'phoneNumber' },
                { dataField: 'phoneNumber2' },
                {
                    dataField: 'areaId',
                    allowFiltering: false,
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                {
                    dataField: 'streetId',
                    allowFiltering: false,
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { 
                    dataField: 'address',
                    cellRender(e: any) {
                        return (<span className={e.data.isStdAddress ? '' : 'text-danger'}>{e.data.address}</span>)
                    },
                },
                { dataField: 'personInCharge' },
                { dataField: 'idNumber' },
                { dataField: 'businessLicense' },
                {
                    dataField: 'addressType',
                    lookup: { dataSource: [] }
                },
                {
                    dataField: 'organization.name',
                    allowFiltering: false,
                },
                {
                    dataField: 'deliver.name',
                    calculateFilterExpression: function (filterValue: any, op: string) {
                        return [
                            ["deliver.name", op, filterValue],
                            "or",
                            ["deliver.code", op, filterValue]
                        ]
                    },
                    cellRender: (e: any) => (<span>{e.data.deliver?.nameAndCode}</span>)
                },
                { dataField: 'contractNumber', filterOperations: ["contains", "isblank", "isnotblank"] },
                { dataField: 'contractDate', dataType: 'date' },
                { dataField: 'securityCheckTime', dataType: 'date' },
                { dataField: 'activatedDate', dataType: 'date' },
                { dataField: 'rentReceiveTime', dataType: 'datetime' },
                createBoolColumn({
                    dataField: 'weiXinBound',
                    caption: '绑定微信',
                    allowFiltering: false,
                    allowSorting: false,
                    allowGrouping: false
                }),
                {
                    dataField: 'status',
                    minWidth: 80,
                    allowFiltering: false,
                    lookup: { dataSource: dataSources.customerStatus, displayExpr: 'text', valueExpr: 'value' },
                    cellRender: (e: any) => {
                        const item = dataSources.customerStatus
                            .find(s => s.value === (e.data.status || enums.customerStatus.active))!;
                        const buttonText = item.value === enums.customerStatus.active ? "退户" : "恢复";

                        return (
                            <div>
                                <span>{item.text}</span> &nbsp;
                                {this.props.permissions[enums.stdActions.update] && (
                                    <span
                                        className={"link-button"}
                                        onClick={() => this.props.onButtonClick(e.data, "changeStatus")}>
                                        {buttonText}
                                    </span>
                                )}
                            </div>
                        )
                    }
                },
                {
                    dataField: 'locked',
                    trueText: "锁定",
                    falseText: "正常",
                    cellRender: (e: any) => {
                        if (e.rowType !== "data") {
                            return "";
                        }

                        const locked = !!e.data.locked,
                            text = locked ? "锁定" : "正常",
                            buttonText = locked ? "解锁" : "锁定";

                        return (
                            <React.Fragment>
                                {text} &nbsp;
                                {this.props.permissions["Lock"] && (
                                    <span className="link-button" onClick={() => this.props.onButtonClick(e.data, "switchLock")}>
                                        {buttonText}
                                    </span>
                                )}
                            </React.Fragment>
                        );
                    }
                },
                { dataField: 'lockNote' },
                createBoolColumn({ dataField: 'hasDanger' }),
                createBoolColumn({ dataField: 'hasTrouble' }),
                {
                    dataField: 'alerter',
                    allowFiltering: false,
                    lookup: {
                        dataSource: [
                            { value: null, text: "未填写" },
                            ...dataSources.alerterStatus
                        ],
                        displayExpr: 'text',
                        valueExpr: 'value'
                    }
                },
                { dataField: 'alerterNumber' },
                createBoolColumn({ dataField: 'securityCodePrinted' }),
                { dataField: 'securityCodePrintTime', dataType: 'datetime' },
                {
                    dataField: 'exitTime',
                    dataType: 'date',
                    cellRender: function (e: any) {
                        return e.data.status === enums.customerStatus.inactive ? fdate(e.data.exitTime) : undefined;
                    }
                },
                {
                    dataField: 'exitNote',
                    cellRender: function (e: any) {
                        return e.data.status === enums.customerStatus.inactive ? e.data.exitNote : undefined;
                    }
                },
                createBoolColumn({ dataField: 'isolatedStoreroom' }),
                { dataField: 'code' },
                { dataField: 'stdCode' },
                { dataField: 'division' },
                { dataField: 'label' },
                ...serverDataService.bottleTypes.map((t: any) => ({
                    dataField: `discount_${t.id}`,
                    caption: `${t.name}优惠折扣`,
                    allowGrouping: false,
                    allowSorting: false,
                    allowFiltering: false,
                    cellRender: function (e: any) {
                        return e.data.discounts ?
                            e.data.discounts.find((i: any) => i.bottleTypeId === t.id)?.discountValue :
                            undefined;
                    }
                })),
                { dataField: 'note' },
                { dataField: 'updatedAt', dataType: 'datetime' }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = (e) => this.props.onButtonClick(e.data, "edit");
        this.gridOptions.onExporting = this.onExporting.bind(this);
        this.gridOptions.export!.allowExportSelectedData = false;
        this.gridOptions.export!.enabled = false;
    }

    componentDidMount() {
        this.authorizeExport();
        this.loadDataSources();
    }

    async authorizeExport() {
        if (await authorizationService.authorize(this.objectName, enums.stdActions.export)) {
            this.gridOptions.export!.enabled = true;
            this.gridRef.current?.instance.option("export.enabled", true);
        }
    }

    async loadDataSources() {
        const dataSources: DataSources = await loadCustomerDataSources();

        this.setDataSource({
            "typeId": dataSources.types,
            "areaId": dataSources.counties,
            "streetId": dataSources.streets,
            "addressType": dataSources.addressTypes
        }, this.state.columns);

        this.setState({ dataSources: dataSources });
    }

    setDataSource(map: { [key: string]: any[] }, columns: IColumnProps[]) {
        for (const key in map) {
            setColumnDataSource(columns, key, map[key]);
        }
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onCustomerSelected(e.selectedRowsData[0]);
        } else {
            this.props.onCustomerSelected({});    // 为避免混乱，多选不能编辑
        }

        this.props.onRowChecked(e.selectedRowsData.map(d => ({
            id: d.id,
            name: d.name,
            gasCode: d.gasCode,
            status: d.status,
            locked: d.locked,
            isStdAddress: d.isStdAddress
        })));
    }

    onExporting(e: ExportingEvent) {
        const args: ExportModel = {
            moduleName: this.moduleName.moduleName,
            exportUrl: `${baseApiUrl}${customerService.apiPath}/export?${http.serialize([this.state.condition]) || ''}`,
            columns: this.state.columns,
            keys: e.component.getSelectedRowKeys(),
            filter: e.component.getCombinedFilter()
        };

        e.cancel = true;
        globalPopupManager.show(globalPopupNames.exportPopup, args);
    }

    async refresh() {
        await this.gridRef.current?.instance.refresh();
    }

    filterSpecial(condition: CustomerCondition, filterValue: any) {
        if (Object.keys(this.state.condition).length === 0 ||
            JSON.stringify(condition) !== JSON.stringify(this.state.condition)) {
            this.setState({
                condition: condition,
                customerStore: customerService.getDxData(condition)
            }, () => this.filter(filterValue));
        } else {
            this.filter(filterValue);
        }
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    getSelectedCustomer() {
        return this.gridRef.current?.instance.getSelectedRowsData()[0];
    }

    getSelectedCustomers(): CustomerBatchModel[] | undefined {
        return this.gridRef.current?.instance.getSelectedRowsData().map(d => ({
            id: d.id,
            name: d.name,
            gasCode: d.gasCode,
            status: d.status,
            locked: d.locked,
            isStdAddress: d.isStdAddress
        }));
    }

    clearSelection() {
        this.gridRef.current?.instance.clearSelection();
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.customerStore}
                options={this.gridOptions}
                columns={this.state.columns}
                columnNameMap={this.nameMap}
                toolbarHeight={120}>
            </StdGrid>
        );
    }
}