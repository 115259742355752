import { createStoreFromApi, http, BaseApiService } from "../base";
import { OrganizationModel, OrganizationRef } from "../models";

class OrganizationService extends BaseApiService<OrganizationModel> {
    moduleName = "organization";
    cacheSeconds = 60;

    getRefs(): Promise<OrganizationRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/refs`), this.cacheSeconds, true, "refs");
    }

    getAllRefs(): Promise<OrganizationRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/refs?getAll=true`), this.cacheSeconds, true, "allrefs");
    }

    move(id: number, targetParentId?: number) {
        return http.putData(`${this.apiPath}/move/${id}?targetParentId=${targetParentId || ''}`, '');   
    }

    moveEmployees(id: number, targetId: number) {
        return http.putData(`${this.apiPath}/moveemployees/${id}?targetId=${targetId}`, '');
    }
}

export const organizationService = new OrganizationService();
export const organizationStore = createStoreFromApi(organizationService);