import { Button, DateBox, SelectBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import React from "react";
import { bottleTypeService } from "../../../api";
import { dataSources, dateAdd, fdate, today } from "../../../base";
import { BottleTypeModel, TurnoverReportCondition } from "../../../models";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { ValueChangedEvent as TextChangedEvent } from "devextreme/ui/text_box";

export interface TurnoverToolbarProps {
    onFiltering: (condition: TurnoverReportCondition) => void;
}

interface TurnoverToolbarState {
    condition: TurnoverReportCondition;
    bottleTypes: BottleTypeModel[];
}

export default class TurnoverToolbar extends
    React.Component<TurnoverToolbarProps, TurnoverToolbarState> {
    
    constructor(props: Readonly<TurnoverToolbarProps>) {
        super(props);

        this.state = {
            condition: {
                startDate: fdate(dateAdd(today(), -6)),
                endDate: fdate(today())
            },
            bottleTypes: []
        };

        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onBottleTypeChanged = this.onBottleTypeChanged.bind(this);
        this.onKeyTypeChanged = this.onKeyTypeChanged.bind(this);
        this.onKeyChanged = this.onKeyChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount(): void {
        this.loadData();
    }

    async loadData() {
        const types = await bottleTypeService.getRefs();
        this.setState({ bottleTypes: types });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.updateCondition({ startDate: e.value });
    }

    private updateCondition(updated: any) {
        const cond = { ...this.state.condition, ...updated };
        cond.startDate = fdate(cond.startDate);
        cond.endDate = fdate(cond.endDate);

        this.setState({ 
            condition: cond
        });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.updateCondition({ endDate: e.value });
    }

    onBottleTypeChanged(e: ValueChangedEvent) {
        this.updateCondition({ bottleTypeId: e.value });
    }

    onKeyTypeChanged(e: ValueChangedEvent) {
        this.updateCondition({ keyType: e.value });
    }

    onKeyChanged(e: TextChangedEvent) {
        this.updateCondition({ key: e.value?.trim() });
    }

    onFiltering() {
        this.props.onFiltering(this.state.condition);
    }

    render() {
        const { condition, bottleTypes } = this.state;

        return (
            <div className="page-toolbar">
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={condition.startDate}
                            placeholder="开始时间"
                            width={120}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={condition.endDate}
                            placeholder="结束时间"
                            width={120}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={condition.bottleTypeId}
                            dataSource={bottleTypes}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder={"钢瓶类型"}
                            onValueChanged={this.onBottleTypeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={condition.keyType}
                            dataSource={dataSources.keyTypes}
                            displayExpr="text"
                            valueExpr={"value"}
                            showClearButton={true}
                            width={100}
                            placeholder={"查询方式"}
                            onValueChanged={this.onKeyTypeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <TextBox
                            value={condition.key}
                            showClearButton={true}
                            placeholder="客户名称或卡号"
                            onValueChanged={this.onKeyChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                </Toolbar>
            </div>
        )
    }
}