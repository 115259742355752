import { IColumnProps, IDataGridOptions } from "devextreme-react/data-grid";
import React from "react"
import { StdGrid } from "../grid";
import { bottleTypeService, orderService } from "../../api";
import { dataSources, ModuleConfig, StringMap, stdTimeFormat } from "../../base";
import { Panel } from "../layouts";
import { BottleTypeModel, BriefOrder } from "../../models";

export interface OrderHistoryProps {
    id?: number;
    orderSaved: boolean;
}

interface OrderHistoryState {
    orders: BriefOrder[];
    types: BottleTypeModel[];
    columns: IColumnProps[];
}

export default class OrderHistory extends React.Component<OrderHistoryProps, OrderHistoryState> {
    moduleName: ModuleConfig = {
        moduleName: "Order",
        children: {
            "deliver": "Employee"
        }
    };
    
    gridOptions: IDataGridOptions = {
        columnAutoWidth: true,
        paging: { pageSize: 10 }
    };

    nameMap: StringMap = { "deliver.name": "deliverId" };

    constructor(props: Readonly<OrderHistoryProps>) {
        super(props);

        this.state = {
            orders: [],
            types: [],
            columns: [
                { dataField: "totalPrice" },
                { dataField: "deliver.name" },
                { dataField: "createdAt", dataType: "datetime", format: stdTimeFormat },
                {
                    dataField: "status",
                    lookup: { dataSource: dataSources.orderStatus, displayExpr: "text", valueExpr: "value" }
                }
            ]
        };
    }

    componentDidMount() {
        this.loadTypes();
    }

    async loadTypes() {
        const types = await bottleTypeService.getAuthorizedRefs();
        const columns = [
            {
                name: 'items',
                caption: '订单详情',
                cellRender: function (e: any) {
                    const texts: string[] = [];

                    for (const type of types) {
                        const item = e.data.items.find((i: any) => i.bottleTypeId === type.id);
                        if (item && item.amount) {
                            texts.push(`${type.name}(${item.amount})`);
                        }
                    }

                    return texts.join("，");
                }
            },
            ...this.state.columns
        ];

        this.setState({
            types: types,
            columns: columns
        });
    }

    componentDidUpdate(prevProps: OrderHistoryProps) {
        if (this.props.id && this.props.id !== prevProps.id) {
            this.loadData();
        } else if (!this.props.id && prevProps.id) {
            this.setState({ orders: [] });
        } else if (this.props.orderSaved && !prevProps.orderSaved) {
            this.loadData();    // 订单保存后刷新
        }
    }

    async loadData() {
        const data = await orderService.searchByCustomerId(this.props.id!);
        this.setState({ orders: data });
    }

    render() {
        return (
            <Panel caption={"历史订单"} noBodyPadding={true}>
                <StdGrid
                    moduleName={this.moduleName}
                    dataSource={this.state.orders}
                    columns={this.state.columns}
                    columnNameMap={this.nameMap}
                    options={this.gridOptions}
                    autoHeight={true} />
            </Panel>
        )
    }
}