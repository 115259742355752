import { createStoreFromUrl, http, MessageBool } from "../base";

class CustomerRequestService {
    apiPath = "/api/customerrequest";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }

    assign(id: number, organizationId: number) {
        return http.putData<MessageBool>(`${this.apiPath}/assign/${id}?organizationId=${organizationId}`, '');
    }

    discard(id: number, note: string) {
        return http.putData<MessageBool>(`${this.apiPath}/discard`, { id: id, note: note });
    }
}

export const customerRequestService = new CustomerRequestService();
export const customerRequestStore = customerRequestService.getDxData();