import './side-navigation-menu.scss';
import { FavoriteContext, FavoriteContextType, NavigationContext, NavigationContextType } from '../../hooks';
import { favoriteItem, homeItem, navigation, NavigationItem, authorizationService, enums, ListItem } from '../../base';
import TreeView from 'devextreme-react/tree-view';
import React from 'react';
import { ContextMenu } from 'devextreme-react';
import { ItemContextMenuEvent } from 'devextreme/ui/tree_view';
import copy from 'copy-to-clipboard';

export interface SideNavigationMenuProps {
    selectedItemChanged: any;
    onMenuReady: any;
}

export default class SideNavigationMenu
    extends React.Component<SideNavigationMenuProps, any> {
    treeViewRef = React.createRef<TreeView>();
    contextMenuRef = React.createRef<ContextMenu>();
    items: NavigationItem[] = [homeItem, favoriteItem, ...navigation];
    contextMenuItems: ListItem[] = [
        { value: "newwin", text: "在新窗口打开" },
        { value: "copy", text: "复制链接" }
    ];
    contextMenuOn?: NavigationItem;

    constructor(props: Readonly<SideNavigationMenuProps>) {
        super(props);

        this.setItemId(this.items);
        this.itemContextMenu = this.itemContextMenu.bind(this);
        this.contextMenuItemClick = this.contextMenuItemClick.bind(this);
    }

    setItemId(items: NavigationItem[]) {
        for (const item of items) {
            item.id = item.id || item.path;

            if (item.items?.length) {
                this.setItemId(item.items);
            }
        }
    }

    componentDidMount() {
        this.authorize(this.items);

        this.setState({});
    }

    async authorize(items: NavigationItem[]) {
        for (let i = items.length - 1; i >= 0; i--) {
            const item = items[i];

            if (item.objectName && !(await authorizationService.authorize(
                item.objectName!, item.actionName ?? enums.stdActions.read))) {
                items.splice(i, 1);
            } else if (item.items?.length) {
                await this.authorize(item.items!);
                if (!item.items!.length) {
                    items.splice(i, 1);
                }
            }
        }
    }

    itemContextMenu(e: ItemContextMenuEvent) {
        const hasLink = !!e.itemData?.path;
        const contextMenu = this.contextMenuRef.current?.instance;
        this.contextMenuOn = e.itemData as any;

        if (contextMenu) {
            contextMenu.option("items[0].visible", hasLink);
            contextMenu.option("items[1].visible", hasLink);
        }
    }

    contextMenuItemClick(e: any) {
        switch (e.itemData.value) {
            case 'newwin':
                window.open(this.contextMenuOn?.path);
                break;

            case 'copy':
                const l = window.location;
                copy(`${l.protocol}//${l.host}${this.contextMenuOn?.path}` || "");
                break;
        }
    }

    render() {
        return (
            <FavoriteContext.Consumer>
                {favoriteContext => (
                    <NavigationContext.Consumer>
                        {navigationContext => this.renderMain(favoriteContext, navigationContext)}
                    </NavigationContext.Consumer>
                )}
            </FavoriteContext.Consumer>

        );
    }

    renderMain(favoriteContext: FavoriteContextType, navigationContext: NavigationContextType) {
        const currentPath = navigationContext.navigationData?.currentPath;
        const treeView = this.treeViewRef.current?.instance;

        if (this.items[1].items !== favoriteContext.favorite) {
            this.items = [
                homeItem,
                { text: favoriteItem.text, icon: favoriteItem.icon, items: favoriteContext.favorite },
                ...this.items.slice(2)
            ];
        }

        if (treeView && currentPath !== undefined) {
            const fitem = favoriteContext.favorite!.find(i => i.path === currentPath);

            treeView.selectItem(fitem?.id || currentPath);
            treeView.expandItem(fitem?.id || currentPath);
        }

        return (
            <div className={'dx-swatch-additional side-navigation-menu'}>
                <div className={'menu-container'}>
                    <TreeView id='navTree'
                        ref={this.treeViewRef}
                        items={this.items}
                        keyExpr={'id'}
                        selectionMode={'single'}
                        focusStateEnabled={false}
                        expandEvent={'click'}
                        onItemClick={this.props.selectedItemChanged}
                        onContentReady={this.props.onMenuReady}
                        onItemContextMenu={this.itemContextMenu}
                        width={'100%'}
                    >
                    </TreeView>
                    <ContextMenu
                        cssClass='tree-context-menu'
                        ref={this.contextMenuRef}
                        dataSource={this.contextMenuItems}
                        target="#navTree .dx-treeview-item"
                        onItemClick={this.contextMenuItemClick} />
                </div>
            </div>
        );
    }
}