import React from "react";
import { ContentBlock } from "../../../components";
import AuthorizedComponent from "../../authorized-component";
import ExceptionDataSummaryToolbar from "./exception-data-summary-toolbar";
import ExceptionDataSummaryGrid from "./exception-data-summary-grid";
import { ExceptionDataSummary } from "../../../models";
import { confirm2, fdate, historyInstance, parseQueryString } from "../../../base";
import { exceptionDataService } from "../../../api/exception-data.service";
import { UnregisterCallback } from "history";

interface ExceptionDataSummaryState {
    groupType: string;
    type?: string;
    organizationId?: number;
}

export default class ExceptionDataSummaryPage extends AuthorizedComponent<any, ExceptionDataSummaryState> {
    objectName = "ExceptionData";
    toolbarRef = React.createRef<ExceptionDataSummaryToolbar>();
    gridRef = React.createRef<ExceptionDataSummaryGrid>();
    callback: UnregisterCallback = historyInstance.listen(this.onLocationChanged.bind(this));

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            groupType: 'Type'
        };

        this.onGroupTypeChanged = this.onGroupTypeChanged.bind(this);
        this.refreshSummary = this.refreshSummary.bind(this);
        this.onModelSelected = this.onModelSelected.bind(this);
        this.onDataLoaded = this.onDataLoaded.bind(this);
    }

    componentDidMount(): void {
        this.onLocationChanged(this.props.location);
    }

    componentWillUnmount(): void {
        this.callback();
    }

    onLocationChanged(location: any) {
        const urlData = parseQueryString(location.search);

        this.setState({
            groupType: urlData.groupType || 'Type',
            type: urlData.type || undefined,
            organizationId: urlData.organizationId || undefined
        });
    }

    onGroupTypeChanged(groupType: string) {
        historyInstance.push(`/report/exception-summary?groupType=${groupType}`);
    }

    async refreshSummary() {
        if (await confirm2("确定要立即更新统计数据吗？")) {
            await exceptionDataService.clearSummaryCache();
            this.gridRef.current?.loadData();
        }
    }

    onModelSelected(_model: ExceptionDataSummary) {

    }

    onDataLoaded(data: ExceptionDataSummary[]) {
        if (data.length) {
            this.toolbarRef.current?.setUpdatedAt(fdate(data[0].updatedAt));
        }
    }

    render() {
        const { groupType, type, organizationId } = this.state;

        return (
            <ContentBlock caption="流转异常日志">
                <ExceptionDataSummaryToolbar
                    ref={this.toolbarRef}
                    groupType={groupType}
                    onGroupTypeChanged={this.onGroupTypeChanged}
                    refreshSummary={this.refreshSummary} />
                <ExceptionDataSummaryGrid
                    ref={this.gridRef}
                    groupType={groupType}
                    type={type}
                    organizationId={organizationId}
                    onModelSelected={this.onModelSelected}
                    onDataLoaded={this.onDataLoaded} />
            </ContentBlock>
        )
    }
}