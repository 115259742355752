import React from "react";
import { alertWarning, DataMap, dateAdd, enums, isPorcelainCode, timeAdd, today, toShortCode } from "../../base";
import { FillingPrecheckModel } from "../../models";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { Button, DateBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";

export interface FillingPrecheckToolbarProps {
    permissions?: DataMap<boolean>;
    onButtonClick: (model: FillingPrecheckModel, name: string) => void;
    onFiltering: (filterValue: any) => void;
}

interface FillingPrecheckToolbarState {
    model: FillingPrecheckModel;
    startTime?: Date | string;
    endTime?: Date | string;
    code?: string;
}

export default class FillingPrecheckToolbar
    extends React.Component<FillingPrecheckToolbarProps, FillingPrecheckToolbarState> {
    constructor(props: Readonly<FillingPrecheckToolbarProps>) {
        super(props);

        const day = today();

        this.state = {
            model: {},
            startTime: day,
            endTime: timeAdd(dateAdd(day, 1), -1, "s")
        };

        this.onCodeChanged = this.onCodeChanged.bind(this);
        this.onStartTimeChanged = this.onStartTimeChanged.bind(this);
        this.onEndTimeChanged = this.onEndTimeChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    setModel(model: FillingPrecheckModel) {
        this.setState({ model: model });
    }

    onCodeChanged(code?: string) {
        this.setState({ code: code?.trim() });
    }

    onStartTimeChanged(e: DateChangedEvent) {
        this.setState({ startTime: e.value });
    }

    onEndTimeChanged(e: DateChangedEvent) {
        this.setState({ endTime: e.value });
    }

    onFiltering() {
        const filterValue: any[] = [];

        if (this.state.startTime && this.state.endTime) {
            filterValue.push(["checkTime", ">=", this.state.startTime]);
            filterValue.push(["checkTime", "<=", this.state.endTime]);
        }

        if (!filterValue.length) {
            alertWarning("请输入起止时间查询");
            return;
        }

        if (this.state.code) {
            if (isPorcelainCode(this.state.code)) {
                filterValue.push(["bottle.barCode", "=", this.state.code]);        
            } else {
                filterValue.push(["bottle.code", "=", toShortCode(this.state.code)]);        
            }
        }

        this.props.onFiltering(filterValue);
    }

    render() {
        const { onButtonClick } = this.props;
        const { model, startTime, endTime, code } = this.state;
        const permissions = this.props.permissions || {};
        const hasModel = !!model.id;

        return (
            <div className={"page-toolbar page-large-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <TextBox
                            value={code}
                            placeholder="陶瓷码或钢印号"
                            width={120}
                            showClearButton={true}
                            onValueChange={this.onCodeChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <DateBox
                            value={startTime}
                            placeholder="开始时间"
                            type="datetime"
                            showClearButton={true}
                            onValueChanged={this.onStartTimeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endTime}
                            placeholder="结束时间"
                            type="datetime"
                            showClearButton={true}
                            onValueChanged={this.onEndTimeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={!!permissions[enums.stdActions.create]}>
                        <Button
                            text="新增"
                            onClick={() => onButtonClick(model, "add")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={!!permissions[enums.stdActions.update]}>
                        <Button
                            disabled={!hasModel}
                            text="编辑"
                            onClick={() => this.props.onButtonClick(model, "edit")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={!!permissions[enums.stdActions.delete]}>
                        <Button
                            disabled={!hasModel}
                            text="删除"
                            onClick={() => this.props.onButtonClick(model, "delete")} />
                    </Item>
                </Toolbar>
            </div>
        );
    }    
}