import { Popup, SelectBox, TextBox } from "devextreme-react";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import React from "react";
import { customerService, dataSourceService } from "../../api";
import { alertError, alertWarning, enums, notifySuccess } from "../../base";
import { PopupContentBox } from "../layouts";
import RefPopup from "../popup/ref-popup";

export interface ExitCustomerPopupProps {
    onSaved: () => void;
}

export interface ExitCustomerPopupState {
    ids: number[];
    reason?: string;
    reasonNote?: string;
    reasons: string[];
}

export default class ExitCustomerPopup extends React.Component<ExitCustomerPopupProps, ExitCustomerPopupState> {
    popupRef = React.createRef<Popup>();

    constructor(props: Readonly<ExitCustomerPopupProps>) {
        super(props);

        this.state = {
            ids: [],
            reasons: []
        };

        this.onReasonChanged = this.onReasonChanged.bind(this);
        this.onReasonNoteChanged = this.onReasonNoteChanged.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.loadReasons();
    }

    async loadReasons() {
        const reasons = await dataSourceService.getDataSource(enums.dataSourceTypes.exitReasons);
        this.setState({
            reasons: [
                ...reasons,
                '其它'
            ]
        });
    }

    show(ids: number[]) {
        this.popupRef.current?.instance.show();

        this.setState({ ids: ids });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    onReasonChanged(e: ValueChangedEvent) {
        this.setState({
            reason: e.value,
            reasonNote: e.value === '其它' ? this.state.reasonNote : undefined
        });
    }

    onReasonNoteChanged(e: any) {
        this.setState({ reasonNote: e.value });
    }

    async save() {
        if (!this.state.reason || (this.state.reason === '其它' && !this.state.reasonNote)) {
            alertWarning("请选择或输入退户理由");
            return;
        }

        const res = await customerService.batchInactivate(this.state.ids,
            this.state.reasonNote || this.state.reason || "");

        if (res.succeeded) {
            notifySuccess("已成功办理退户");
            this.props.onSaved();
            this.hide();
        } else {
            alertError(res.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"请选择退户原因"}
                width={300}
                height={220}>
                <PopupContentBox onSave={this.save} onCancel={this.cancel}>
                    <SelectBox
                        dataSource={this.state.reasons}
                        onValueChanged={this.onReasonChanged} />
                    <br />
                    {this.state.reason === "其它" && (
                        <TextBox
                            value={this.state.reasonNote}
                            placeholder="请输入其它原因"
                            onValueChanged={this.onReasonNoteChanged} />
                    )}
                </PopupContentBox>
            </RefPopup>
        )
    }
}