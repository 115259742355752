import { IColumnProps } from "devextreme-react/data-grid";
import { cpBottleTypeStore } from "../../api";
import { enums, ListItem } from "../../base";
import CpDataSourcePage from "./cp-data-source";

const typesOfType: ListItem[] = [
    { value: 'Gas', text: '液化气'},
    { value: 'Propane', text: '丙烷' }
]

export default class CpBottleTypePage extends CpDataSourcePage {
    type = enums.cpDataSourceTypes.bottleType;
    caption = "条码打印-气瓶类型";
    store = cpBottleTypeStore;
    
    columns: IColumnProps[] = [
        { 
            dataField: "createdAt",
            formItem: { visible: false },
            dataType: 'datetime' 
        },
        { 
            dataField: "updatedAt", 
            formItem: { visible: false },
            dataType: 'datetime' 
        },
        { dataField: "text" },
        { 
            dataField: "extra",
            caption: '燃气类型',
            lookup: { dataSource: typesOfType, valueExpr: 'value', displayExpr: 'text' }
        }
    ]
}