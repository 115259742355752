import { BaseApiService, createStoreFromUrl } from "../base";
import { BugTraceModel } from "../models";

class BugTraceService extends BaseApiService<BugTraceModel> {
    moduleName = "bugtrace";
    
    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }
}

export const bugTraceService = new BugTraceService();
export const bugTraceStore = bugTraceService.getDxData();