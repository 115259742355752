import React from "react";
import { Popup } from "devextreme-react";
import Form, { Item, Label } from "devextreme-react/form";
import DataGrid, { IDataGridOptions, IColumnProps } from "devextreme-react/data-grid";
import { bottleTypeService, customerService, employeeService } from "../../api";
import { alertError, alertWarning, notifySuccess, dataSources, modelClone } from "../../base";
import { PopupContentBox, RefPopup, StdGrid } from "../../components";
import { orderService, productService } from "../../api";
import { BottleTypeModel, CustomerModel, EmployeeRef, OrderEditModel, OrderItemModel, ProductRef } from "../../models";

export interface OrderFormProps {
    onSaved: () => void;
}

interface OrderFormState {
    bottleTypes: BottleTypeModel[];
    delivers: EmployeeRef[];
    products: ProductRef[];
    customer: CustomerModel;
}

export default class OrderForm extends React.Component<OrderFormProps, OrderFormState> {
    order: OrderEditModel = { items: [] };
    itemModuleName = "OrderItem";
    popupRef = React.createRef<Popup>();
    itemGridRef = React.createRef<DataGrid>();

    itemGridOptions: IDataGridOptions = {
        columnAutoWidth: true,
        editing: { mode: "cell", allowUpdating: true, selectTextOnEditStart: true },
        sorting: { mode: "none" },
        scrolling: { showScrollbar: "never" },
        summary: {
            texts: { sum: "" },
            calculateCustomSummary: () => 0,
            totalItems: [
                { column: "typeName", summaryType: "custom", displayFormat: "共计" },
                { column: "amount", summaryType: "sum" },
                { column: "totalPrice", summaryType: "sum" }
            ]
        }
    }

    itemColumns: IColumnProps[] = [
        {
            dataField: "typeName",
            caption: "项目",
            allowEditing: false
        },
        {
            dataField: "amount",
            showEditorAlways: true,
            setCellValue: function (newData: any, value: any, currentData: any) {
                newData.amount = value;
                newData.totalPrice = currentData.piecePrice * value + currentData.shippingFee;
            }
        },
        {
            dataField: "piecePrice",
            showEditorAlways: true,
            setCellValue: function (newData: any, value: any, currentData: any) {
                newData.piecePrice = value;
                newData.totalPrice = currentData.amount * value + currentData.shippingFee;
            }
        },
        {
            dataField: "discount",
            allowEditing: false,
        },
        {
            dataField: "totalPrice",
            caption: "金额",
            allowEditing: false
        }
    ];

    constructor(props: Readonly<OrderFormProps>) {
        super(props);

        this.state = {
            bottleTypes: [],
            delivers: [],
            products: [],
            customer: {}
        };

        this.onNoteFocusOut = this.onNoteFocusOut.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.loadBottleTypes();
    }

    async loadBottleTypes() {
        let bottleTypes: any[] = [], products: any[] = [];

        await Promise.all([
            (async () => bottleTypes = await bottleTypeService.getAuthorizedRefs())(),
            (async () => products = await productService.getBottles())()
        ]);

        this.setState({ 
            bottleTypes: bottleTypes,
            products: products 
        });
    }

    async show(order: OrderEditModel) {
        this.popupRef.current &&
            this.popupRef.current.instance.show();

        this.order = modelClone(order);
        this.loadDelivers();
        
        this.order.items = this.mergeItems();

        const customer = await customerService.get(order.customerId!);
        this.setState( {
            customer: customer
        });        
    }

    mergeItems(): OrderItemModel[] {
        const { bottleTypes, products } = this.state;

        return bottleTypes.map(t => {
            const product = products.find(p => p.bottleTypeId === t.id);
            const stdPrice = product?.webPrice || product?.price || 0;
            const item = this.order.items!.find(i => i.bottleTypeId === t.id);
            const discount = this.state.customer.discounts?.find((d: any) => d.bottleTypeId === t.id)?.discountValue || 0;
            
            return {
                id: item?.id || 0,
                bottleTypeId: t.id!,
                typeName: t.name!,
                amount: item?.amount || 0,
                piecePrice: item?.piecePrice || stdPrice,
                discount: discount,
                shippingFee: item?.shippingFee || 0,
                totalPrice: item ? (item.piecePrice! - item.discount!) * item.amount! : 0
            }
        });
    }

    async loadDelivers() {
        const data = await employeeService.searchDelivers(this.order.customerId!);
        this.setState({ delivers: data });
    }

    hide() {
        this.popupRef.current &&
            this.popupRef.current.instance.hide();
    }

    onNoteFocusOut(e: any) {
        if (!e.event.shiftKey && e.event.keyCode === 9 /* tab */) {
            this.itemGridRef.current?.instance.editCell(0, "amount");
        }
    }

    async save() {
        const items = this.order.items!;

        if (!items.find(i => i.amount)) {
            alertWarning("请选择要配送的项目");
            return;
        }

        const res = await orderService.update(this.order);

        if (res.succeeded) {
            this.hide();
            notifySuccess("已成功保存");
            this.props.onSaved();
        } else {
            alertError(res.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"编辑"}
                width={800}
                height={500}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <Form colCount={2} formData={this.order}>
                        <Item
                            disabled={true}
                            colSpan={2}
                            dataField={"deliverType"}
                            editorType={"dxRadioGroup"}
                            editorOptions={{
                                layout: "horizontal",
                                dataSource: dataSources.deliverTypes,
                                displayExpr: 'text',
                                valueExpr: 'value'
                            }}>
                            <Label visible={false} />
                        </Item>
                        <Item
                            disabled={true}
                            dataField={"deliverId"}
                            editorType={"dxSelectBox"}
                            editorOptions={{
                                dataSource: this.state.delivers,
                                displayExpr: 'name',
                                valueExpr: 'id'
                            }}>
                            <Label text={"送气工"} />
                        </Item>
                        <Item
                            dataField={"reservedTime"}
                            editorType={"dxDateBox"}
                            editorOptions={{ type: "datetime" }}>
                            <Label text={"预约时间"} />
                        </Item>
                        <Item
                            dataField={"note"}
                            colSpan={2}
                            editorOptions={{ onKeyDown: this.onNoteFocusOut }}>
                            <Label text={"备注"} />
                        </Item>
                    </Form>
                    <br />
                    <StdGrid
                        gridRef={this.itemGridRef}
                        moduleName={this.itemModuleName}
                        dataSource={this.order.items}
                        columns={this.itemColumns}
                        options={this.itemGridOptions}
                        autoHeight={true} />
                </PopupContentBox>
            </RefPopup>
        );
    }
}