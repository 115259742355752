import React from "react";
import DataGrid, { IColumnProps, IDataGridOptions } from "devextreme-react/data-grid";
import { ExportingEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import { authorizationService, baseApiUrl, enums, globalPopupManager, globalPopupNames, ModuleConfig, StringMap, User } from "../../base";
import { createGridOptions, ExportModel, remoteReadOnlyGridOptions, setColumnDataSource, StdGrid } from "../../components";
import { bottleService, bottleStore, bottleTypeService, employeeService, organizationService } from "../../api";
import { BottleModel, BottleTypeModel, EmployeeRef, OrganizationRef } from "../../models";
import CustomStore from "devextreme/data/custom_store";

export interface BottleGridProps {
    user?: User;
    onBottleSelected(bottle: BottleModel): void;
    onButtonClick(bottle: BottleModel, name: string): void;
}

interface BottleGridState {
    store: CustomStore;
    orgs: OrganizationRef[];
    bottleTypes: BottleTypeModel[];
    employees: EmployeeRef[];
    columns: IColumnProps[];
}

export default class BottleGrid extends React.Component<BottleGridProps, BottleGridState> {
    moduleName: ModuleConfig = {
        moduleName: "Bottle",
        children: {
            "bottleQrCode": "BottleQrCode"
        }
    };

    objectName = "Bottle";
    gridRef = React.createRef<DataGrid>();
    gridOptions: IDataGridOptions;

    nameMap: StringMap = { "bottleQrCode.firstOperatorId": "bottleQrCode.firstOperatorName" }

    constructor(props: Readonly<BottleGridProps>) {
        super(props);

        this.gridOptions = createGridOptions("bottles", this.gridRef, remoteReadOnlyGridOptions);

        this.state = {
            store: bottleStore,
            orgs: [],
            bottleTypes: [],
            employees: [],
            columns: [
                { dataField: 'barCode' },
                { dataField: 'code' },
                { dataField: 'vendor' },
                {
                    dataField: 'typeId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: 'typeCode' },
                { dataField: 'produceDate', dataType: "date" },
                { dataField: 'inspectDate', dataType: "date" },
                { dataField: 'nextInspectDate', dataType: "date" },
                { dataField: 'enterDate', dataType: "date" },
                { dataField: 'scrapDate', dataType: "date" },
                { dataField: 'originalWeight' },
                { dataField: 'weight' },
                { dataField: 'volume' },
                { dataField: 'thickness' },
                { dataField: 'gpressure' },
                { dataField: 'wpressure' },
                { dataField: 'phase' },
                { dataField: 'gasType' },
                { dataField: 'status' },
                { dataField: 'createdAt', dataType: 'datetime' },
                { dataField: 'updatedAt', dataType: 'datetime' },
                { dataField: 'bottleQrCode.firstPrintTime', dataType: 'datetime' },
                { 
                    dataField: 'bottleQrCode.firstOperatorId',
                    lookup: { dataSource: [], displayExpr: 'nameAndCode', valueExpr: 'id' }
                },
                { 
                    dataField: 'bottleQrCode.firstOrganizationId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
        this.gridOptions.onExporting = this.onExporting.bind(this);
        this.gridOptions.export!.allowExportSelectedData = false;
        this.gridOptions.export!.enabled = false;
    }

    async componentDidMount() {
        this.authorizeImport();
        this.loadData();
    }

    async authorizeImport() {
        if (await authorizationService.authorize(this.objectName, enums.stdActions.export)) {
            this.gridOptions.export!.enabled = true;
            this.gridRef.current?.instance.option("export.enabled", true);
        }
    }

    async loadData() {
        let types: BottleTypeModel[] = [],
            orgs: OrganizationRef[] = [],
            employees: EmployeeRef[] = [];

        await Promise.all([
            (async () => types = await bottleTypeService.getAll())(),
            (async () => orgs = await organizationService.getRefs())(),
            (async () => employees = await employeeService.getRefs())(),
        ]);

        setColumnDataSource(this.state.columns, "typeId", types);
        setColumnDataSource(this.state.columns, "bottleQrCode.firstOrganizationId", orgs);
        setColumnDataSource(this.state.columns, "bottleQrCode.firstOperatorId", employees);

        this.setState({
            bottleTypes: types,
            orgs: orgs,
            employees: employees
        });
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onBottleSelected(e.selectedRowsData[0]);
        } else {
            this.props.onBottleSelected({});
        }
    }

    onExporting(e: ExportingEvent) {
        const args: ExportModel = {
            moduleName: this.moduleName.moduleName,
            exportUrl: `${baseApiUrl}${bottleService.apiPath}/export`,
            columns: this.state.columns,
            keys: e.component.getSelectedRowKeys(),
            filter: e.component.getCombinedFilter()
        };

        e.cancel = true;
        globalPopupManager.show(globalPopupNames.exportPopup, args);
    }

    specialFilter(isPorcelainCode?: boolean) {
        if (typeof(isPorcelainCode) === "boolean") {
            this.setState({
                store: bottleService.getDxData(isPorcelainCode)
            });
        } else {
            this.setState({ store: bottleStore });
        }
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns}
                columnNameMap={this.nameMap}>
            </StdGrid>
        )
    }
}

