import React from "react";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { ContentBlock, StdGrid, StdToolbar, createGridOptions, stdReadOnlyGridOptions } from "../../components";
import { siteCheckTypeStore } from "../../api";
import AuthorizedComponent from "../authorized-component";
import { dataSources, enums } from "../../base";
import { SiteCheckModel } from "../../models";

export default class SiteCheckTypePage extends AuthorizedComponent {
    objectName = "SiteCheckType";
    moduleName = "SiteCheckType";
    gridRef = React.createRef<DataGrid>();
    toolbarRef = React.createRef<StdToolbar>();
    gridOptions = createGridOptions("siteCheckTypes", this.gridRef, stdReadOnlyGridOptions);

    columns: IColumnProps[] = [
        {
            dataField: 'type',
            lookup: { dataSource: dataSources.siteCheckTypes, displayExpr: 'text', valueExpr: 'value' }
        },
        { dataField: 'name' },
        // { dataField: 'standard' },
        { dataField: 'orderNumber' },
        {
            dataField: 'dangerLevel',
            lookup: { dataSource: dataSources.siteDangerLevels, displayExpr: 'text', valueExpr: 'value' }
        }
    ];

    constructor(props: Readonly<any>) {
        super(props);

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.onButtonClick(e.data, "edit");
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.toolbarRef.current?.setModel(e.selectedRowsData[0]);
        } else {
            this.toolbarRef.current?.setModel({});
        }
    }

    onButtonClick(model: SiteCheckModel, name: string) {
        const grid = this.gridRef.current?.instance;

        if (grid) {
            switch (name) {
                case "add":
                    if (this.permissions[enums.stdActions.create]) {
                        grid.addRow();
                    }
                    break;

                case "edit":
                    if (this.permissions[enums.stdActions.update]) {
                        grid.editRow(grid.getRowIndexByKey(model.id));
                    }
                    break;

                case "delete":
                    if (this.permissions[enums.stdActions.delete]) {
                        grid.deleteRow(grid.getRowIndexByKey(model.id));
                    }
                    break;
            }
        }
    }

    render() {
        return (
            <ContentBlock caption={"站点检查项目设置"}>
                <StdToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onButtonClick={this.onButtonClick} />
                <StdGrid
                    moduleName={this.moduleName}
                    gridRef={this.gridRef}
                    dataSource={siteCheckTypeStore}
                    options={this.gridOptions}
                    columns={this.columns}
                    popupWidth={400}
                    popupHeight={340}
                    formColCount={1} />
            </ContentBlock>
        );
    }
}