import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { ExportingEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import { createImageColumn, StdGrid, createGridOptions, remoteReadOnlyGridOptions, setColumnDataSource, ExportModel, createBoolColumn } from "../../components";
import { bottleDebtDeletedStore, bottleDebtService, bottleDebtStore, customerTypeService, employeeService, organizationService, serverDataService } from "../../api";
import { DataMap, ModuleConfig, dataSources, enums, authorizationService, baseApiUrl, globalPopupManager, globalPopupNames, StringMap, createEmptyStore } from "../../base";
import { BottleDebtModel, CustomerTypeRef, EmployeeRef, OrganizationRef } from "../../models";
import CustomStore from "devextreme/data/custom_store";

export interface BottleDebtGridProps {
    permissions: DataMap<boolean>;
    onBottleDebtSelected: (bottleDebt: BottleDebtModel) => void;
    onButtonClick: (bottleDebt: any, name: string) => void;
}

interface BottleDebtGridState {
    customerTypes: CustomerTypeRef[];
    orgs: OrganizationRef[];
    employees: EmployeeRef[];
    columns: IColumnProps[];
    store: CustomStore;
}

export default class BottleDebtGrid extends React.Component<BottleDebtGridProps, BottleDebtGridState> {
    objectName = "BottleDebt";
    moduleName: ModuleConfig = {
        moduleName: "BottleDebt",
        children: {
            "customer": "Customer"
        }
    };

    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("bottleDebts", this.gridRef, remoteReadOnlyGridOptions);
    
    nameMap: StringMap = {
        "customer.name": "customerId"
    }
    
    constructor(props: Readonly<BottleDebtGridProps>) {
        super(props);

        this.state = {
            customerTypes: [],
            employees: [],
            orgs: [],
            store: createEmptyStore(),
            columns: [
                {
                    dataField: 'customer.gasCode',
                    cellRender: (e: any) => (
                        <span className="link-button"
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.gasCode}
                        </span>
                    )
                },
                {
                    dataField: 'customer.name',
                    cellRender: (e: any) => (
                        <span className="link-button"
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.name}
                        </span>
                    )
                },
                {
                    dataField: 'customer.typeId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                {
                    dataField: 'customer.organizationId',
                    allowFiltering: false,
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                {
                    dataField: 'type',
                    lookup: { dataSource: dataSources.deptTypes, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: 'code' },
                ...serverDataService.bottleTypes.map((t: any) => ({
                    dataField: `item_${t.id}`,
                    caption: t.name,
                    allowGrouping: false,
                    allowSorting: false,
                    allowFiltering: false,
                    cellRender: function (e: any) {
                        return e.data.items.find((i: any) => i.bottleTypeId === t.id)?.number || "0";
                    }
                })),
                { 
                    dataField: 'deposit', 
                    dataType: "number", 
                    format: "currency",
                    calculateCellValue: function(rowData: BottleDebtModel) {
                        return rowData.type === enums.debtTypes.debt ? undefined : rowData.deposit;
                    }
                },
                { dataField: 'debtReason' },
                {
                    dataField: 'createOperator',
                    allowFiltering: false,
                    lookup: { dataSource: [], displayExpr: 'nameAndCode', valueExpr: 'id' }
                },
                { dataField: 'chargedAt', dataType: "datetime" },
                {
                    dataField: 'completed',
                    trueText: "已退",
                    falseText: "正常",
                    cellRender: (e: any) => {
                        if (e.rowType !== "data") {
                            return "";
                        }

                        const completed = !!e.data.completed,
                            text = completed ? "已退" : "正常";

                        return (
                            <React.Fragment>
                                {text} &nbsp;
                                {this.props.permissions[enums.stdActions.update] && !completed && (
                                    <span
                                        className="link-button"
                                        onClick={() => this.props.onButtonClick(e.data, "edit")}>
                                        退还
                                    </span>
                                )}
                            </React.Fragment>
                        );
                    }
                },
                { 
                    dataField: 'completeOperator',
                    allowFiltering: false,
                    lookup: { dataSource: [], displayExpr: 'nameAndCode', valueExpr: 'id' } 
                },
                { dataField: 'completedAt', dataType: 'datetime' },
                {
                    dataField: 'archived',
                    trueText: "已归档",
                    falseText: "未归档",
                    cellRender: (e: any) => {
                        if (e.rowType !== "data") {
                            return "";
                        }

                        const archived = !!e.data.archived,
                            text = archived ? "已归档" : "未归档";

                        return (
                            <React.Fragment>
                                {text} &nbsp;
                                {this.props.permissions["Archive"] && !archived && (
                                    <span
                                        className="link-button"
                                        onClick={() => this.props.onButtonClick(e.data, "archive")}>
                                        归档
                                    </span>
                                )}
                            </React.Fragment>
                        );
                    }
                },
                createBoolColumn({ dataField: 'depositReceived' }),
                { dataField: 'receivedAt', dataType: 'datetime' },
                createImageColumn({ dataField: 'photo' }),
                { dataField: 'note' }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
        this.gridOptions.onExporting = this.onExporting.bind(this);
        this.gridOptions.export!.allowExportSelectedData = false;
        this.gridOptions.export!.enabled = false;
        this.gridOptions.summary = {
            totalItems: [
                { column: "deposit", summaryType: "sum", valueFormat: "currency", displayFormat: "计：{0}" },
                ...serverDataService.bottleTypes.map((t) => ({
                    column: `item_${t.id}`, summaryType: "sum", displayFormat: "计：{0}"
                }))
            ],
        };
    }

    componentDidMount() {
        this.authorizeImport();
        this.loadData();
    }

    async authorizeImport() {
        if (await authorizationService.authorize(this.objectName, enums.stdActions.export)) {
            this.gridOptions.export!.enabled = true;
            this.gridRef.current?.instance.option("export.enabled", true);
        }
    }

    async loadData() {
        let types: CustomerTypeRef[] = [], 
            employees: EmployeeRef[] = [], 
            orgs: OrganizationRef[] = [];

        await Promise.all([
            (async () => types = await customerTypeService.getRefs())(),
            (async () => employees = await employeeService.getAllRefs())(),
            (async () => orgs = await organizationService.getRefs())()
        ]);

        setColumnDataSource(this.state.columns, "createOperator", employees);
        setColumnDataSource(this.state.columns, "completeOperator", employees);
        setColumnDataSource(this.state.columns, "customer.typeId", types);
        setColumnDataSource(this.state.columns, "customer.organizationId", orgs);

        this.setState({
            customerTypes: types,
            employees: employees,
            orgs: orgs
        });
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onBottleDebtSelected(e.selectedRowsData[0]);
        } else {
            this.props.onBottleDebtSelected({});    // 为避免混乱，多选不能编辑
        }
    }

    onExporting(e: ExportingEvent) {
        const args: ExportModel = {
            moduleName: this.moduleName.moduleName,
            exportUrl: `${baseApiUrl}${bottleDebtService.apiPath}/export`,
            columns: this.state.columns,
            keys: e.component.getSelectedRowKeys(),
            filter: e.component.getCombinedFilter(),
            showDeleted: this.state.store === bottleDebtDeletedStore
        };

        e.cancel = true;
        globalPopupManager.show(globalPopupNames.exportPopup, args);
    }
    
    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    filter(filterValue: any, showDeleted: boolean) {
        if (showDeleted && this.state.store !== bottleDebtDeletedStore) {
            this.setState({ store: bottleDebtDeletedStore }, () => this._filter(filterValue));
        } else if (!showDeleted && this.state.store !== bottleDebtStore) {
            this.setState({ store: bottleDebtStore }, () => this._filter(filterValue))
        } else {
            this._filter(filterValue);
        }
    }

    _filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns}
                columnNameMap={this.nameMap}
                toolbarHeight={80}>
            </StdGrid>
        );
    }
}