import React from "react";
import { cpBatchService, cpCheckBackService, cpCheckService, cpNewService, cpScrapService } from "../../api";
import { alertError, authorizationService, baseApiUrl, confirm2, download, enums, notifySuccess } from "../../base";
import { ContentBlock } from "../../components";
import { CpBatchModel } from "../../models";
import AuthorizedComponent from "../authorized-component";
import CpBatchForm from "./cp-batch-form";
import CpBatchGrid from "./cp-batch-grid";
import CpBatchPrintDialog from "./cp-batch-print-dialog";
import CpBatchToolbar from "./cp-batch-toolbar";
import CpCheckBackDialog from "./cp-check-back-dialog";
import CpCheckDialog from "./cp-check-dialog";
import CpNewDialog from "./cp-new-dialog";
import CpScrapDialog from "./cp-scrap-dialog";
import CodePrintDialog from "./code-print-dialog";

export default class CpBatchPage extends AuthorizedComponent {
    objectName = "CpBatch";
    gridRef = React.createRef<CpBatchGrid>();
    toolbarRef = React.createRef<CpBatchToolbar>();
    formRef = React.createRef<CpBatchForm>();
    newDialogRef = React.createRef<CpNewDialog>();
    checkDialogRef = React.createRef<CpCheckDialog>();
    checkBackDialogRef = React.createRef<CpCheckBackDialog>();
    scrapDialogRef = React.createRef<CpScrapDialog>();
    printDialogRef = React.createRef<CpBatchPrintDialog>();
    codePrintDialogRef = React.createRef<CodePrintDialog>();

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
    }

    get actionNames(): string[] {
        return [
            enums.stdActions.create, 
            enums.stdActions.update, 
            enums.stdActions.delete, 
            "PrintCode"
        ];
    }

    async authorize(): Promise<void> {
        await super.authorize();
        
        this.permissions["PrintCode"] = await authorizationService.authorize("GlobalPermission", "PrintCode");
    }

    onModelSelected(model: CpBatchModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onButtonClick(model: CpBatchModel, name: string) {

        switch (name) {
            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.formRef.current?.show({});
                }
                break;

            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.formRef.current?.show(model);
                }
                break;
                case "detail":
                    this.showDetail(model);
                    break;
    
    
            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(model);
                }
                break;

            case "printcode":
                this.printCode(model);
                break;

            case "printreport":
                this.printReport(model);
                break;

            case "exportreport":
                this.exportReport(model);
                break;

            case "exportny":
                this.exportNY(model);
                break;
        }
    }

    showDetail(model: CpBatchModel) {
        switch (model.batchType) {
            case enums.cpBatchTypes.new:
                this.newDialogRef.current?.show(model.id!);
                break;

            case enums.cpBatchTypes.check:
                this.checkDialogRef.current?.show(model.id!);
                break;

            case enums.cpBatchTypes.scrap:
                this.scrapDialogRef.current?.show(model.id!);
                break;

            default:
                this.checkBackDialogRef.current?.show(model);
                break;
        }
    }

    printCode(model: CpBatchModel) {
        this.codePrintDialogRef.current?.show(model.id!, model.batchType!);
    }

    printReport(model: CpBatchModel) {
        let url = "";

        switch (model.batchType) {
            case enums.cpBatchTypes.new:
                url = `/codeprintreport/new?batchId=${model.id}`;
                break;

            case enums.cpBatchTypes.check:
                url = `/codeprintreport/check?batchId=${model.id}`;
                break;

            case enums.cpBatchTypes.scrap:
                url = `/codeprintreport/scrap?batchId=${model.id}`;
                break;

            default:
                url = `/codeprintreport/checkback?batchId=${model.id}`;
                break;
        }
                
        this.printDialogRef.current?.show(url);
    }

    async exportReport(model: CpBatchModel) {
        if (!await confirm2("确定要导出报表吗？")) {
            return;
        }
        
        let url =  '';

        switch (model.batchType) {
            case enums.cpBatchTypes.new:
                url = `${baseApiUrl}${cpNewService.apiPath}/exportform?batchId=${model.id}`;
                break;

            case enums.cpBatchTypes.scrap:
                url = `${baseApiUrl}${cpScrapService.apiPath}/exportform?batchId=${model.id}`;
                break;

            case enums.cpBatchTypes.check:
                url = `${baseApiUrl}${cpCheckService.apiPath}/exportform?batchId=${model.id}`;
                break;

            default:
                url = `${baseApiUrl}${cpCheckBackService.apiPath}/exportform?batchId=${model.id}`;
                break;
        }
                
        download(url, `${model.batchCode}.xls`);
    }

    async exportNY(model: CpBatchModel) {
        if (!await confirm2("确定要导出兰洋的导入格式吗？")) {
            return;
        }

        const url = `${baseApiUrl}${cpBatchService.apiPath}/export/ny?id=${model.id}&type=${model.batchType}`;
        download(url, `兰洋${model.batchCode}.xls`);
    }

    async delete(model: CpBatchModel) {
        if (!await confirm2("确定要删除该批次吗？")) {
            return;
        }
        
        const result = await cpBatchService.delete(model.id!);

        if (result.succeeded) {
            notifySuccess("删除成功");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return (
            <ContentBlock caption={"条码打印-批次管理"}>
                <CpBatchToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onButtonClick={this.onButtonClick} />
                <CpBatchGrid
                    ref={this.gridRef}
                    permissions={this.permissions}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
                <CpBatchForm ref={this.formRef} onSaved={this.onSaved} />
                <CpNewDialog ref={this.newDialogRef} />
                <CpCheckDialog ref={this.checkDialogRef} />
                <CpScrapDialog ref={this.scrapDialogRef} />
                <CpCheckBackDialog ref={this.checkBackDialogRef} />
                <CpBatchPrintDialog ref={this.printDialogRef} />
                <CodePrintDialog ref={this.codePrintDialogRef} />
            </ContentBlock>
        )
    }
}