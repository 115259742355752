import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import React from "react";
import { cpBatchStore } from "../../api";
import { DataMap, dataSources } from "../../base";
import { createGridOptions, remoteReadOnlyGridOptions, StdGrid } from "../../components";
import { CpBatchModel } from "../../models";

export interface CpBatchGridProps {
    permissions: DataMap<boolean>;
    onModelSelected: (model: CpBatchModel) => void;
    onButtonClick: (model: CpBatchModel, name: string) => void;
}

interface CpBatchGridState {
    store: CustomStore;
    columns: IColumnProps[];
}

export default class CpBatchGrid extends React.Component<CpBatchGridProps, CpBatchGridState> {
    moduleName = "CpBatch";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("cpBatches", this.gridRef, remoteReadOnlyGridOptions);

    constructor(props: Readonly<CpBatchGridProps>) {
        super(props);

        this.state = {
            store: cpBatchStore,
            columns: [
                { 
                    dataField: "createdAt", 
                    formItem: { visible: false }, 
                    dataType: "datetime" 
                },
                { 
                    dataField: "updatedAt", 
                    formItem: { visible: false },
                    dataType: 'datetime' 
                },
                { dataField: "batchCode" },
                {
                    dataField: "batchType",
                    lookup: { dataSource: dataSources.cpBatchTypes, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: "batchNote" }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "detail");
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});    // 为避免混乱，多选不能编辑
        }
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns}
                popupWidth={400}
                popupHeight={320}
                formColCount={1} />
        )
    }
}