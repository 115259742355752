import React from "react";
import AuthorizedComponent from "../authorized-component";
import BottleDebtGrid from "./bottle-debt-grid";
import BottleDebtNewForm from "./bottle-debt-new-form";
import BottleDebtToolbar from "./bottle-debt-toolbar";
import { bottleDebtService } from "../../api";
import { alertError, confirm2, notifySuccess, enums, globalPopupManager, globalPopupNames, prompt2, download, baseApiUrl } from "../../base";
import { CompleteDebtPopup, ContentBlock, ImagePopup } from "../../components";
import { BottleDebtModel } from "../../models";

export default class BottleDebtPage extends AuthorizedComponent {
    objectName = "BottleDebt";
    gridRef = React.createRef<BottleDebtGrid>();
    newFormRef = React.createRef<BottleDebtNewForm>();
    comFormRef = React.createRef<CompleteDebtPopup>();
    toolbarRef = React.createRef<BottleDebtToolbar>();
    photoRef = React.createRef<ImagePopup>();

    constructor(props: any) {
        super(props);

        this.onBottleDebtSelected = this.onBottleDebtSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
        this.filter = this.filter.bind(this);
        this.uploadPhoto = this.uploadPhoto.bind(this);
    }

    get actionNames(): string[] {
        return [
            enums.stdActions.create,
            enums.stdActions.update,
            enums.stdActions.delete,
            "Archive",
            "SetReceived"
        ]
    }

    filter(filterValue: any, showDeleted: boolean) {
        this.gridRef.current?.filter(filterValue, showDeleted);
    }

    onBottleDebtSelected(bottleDebt: BottleDebtModel) {
        this.toolbarRef.current?.setBottleDebt(bottleDebt);
    }

    onButtonClick(bottleDebt: BottleDebtModel, name: string) {
        switch (name) {
            case "callcenter":
                globalPopupManager.show(globalPopupNames.callCenter, { id: bottleDebt.customerId });
                break;

            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.newFormRef.current?.show();
                }
                break;

            case "edit":
                if (this.permissions[enums.stdActions.update] && !bottleDebt.completed) {
                    this.comFormRef.current?.show(bottleDebt);
                }
                break;

            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(bottleDebt);
                }
                break;

            case "archive":
                if (this.permissions["Archive"]) {
                    this.archive(bottleDebt);
                }
                break;

            case "note":
                if (this.permissions[enums.stdActions.update]) {
                    this.setNote(bottleDebt);
                }
                break;

            case "restore":
                if (this.permissions[enums.stdActions.delete]) {
                    this.restore(bottleDebt);
                }
                break;

            case "setreceived":
                if (this.permissions["SetReceived"]) {
                    this.setReceived(bottleDebt);
                }
                break;

            case "uploadphoto":
                if (this.permissions[enums.stdActions.update]) {
                    this.showPhotoPopup(bottleDebt);
                }
                break;

            case "exportdetail":
                const filter = this.gridRef.current?.gridRef.current?.instance.getCombinedFilter();
                const url = `${baseApiUrl}${bottleDebtService.apiPath}/export/detail?filter=${JSON.stringify(filter)}`;
                download(url, `押金明细报表.xlsx`);
                break;
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    async setNote(model: BottleDebtModel) {
        const note = await prompt2("请输入备注：", { defaultValue: model.note || "" });

        if (note) {
            const res = await bottleDebtService.setNote({ id: model.id!, note: note });
            if (res.succeeded) {
                notifySuccess("成功保存备注");
                this.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    async delete(bottleDebt: BottleDebtModel) {
        if (!await confirm2("确定要作废该条数据吗？")) {
            return;
        }

        const result = await bottleDebtService.delete(bottleDebt.id!);

        if (result.succeeded) {
            notifySuccess("已成功作废数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async restore(bottleDebt: BottleDebtModel) {
        if (!await confirm2("确定要还原该条数据吗？")) {
            return;
        }

        const result = await bottleDebtService.restore(bottleDebt.id!);

        if (result.succeeded) {
            notifySuccess("已成功还原数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async archive(bottleDebt: BottleDebtModel) {
        if (!await confirm2("确定要将该数据归档吗？")) {
            return;
        }

        const result = await bottleDebtService.archive(bottleDebt.id!);

        if (result.succeeded) {
            notifySuccess("已成功归档数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async setReceived(bottleDebt: BottleDebtModel) {
        if (!await confirm2("确定要将该数据设置为已回款吗？")) {
            return;
        }

        const result = await bottleDebtService.setReceived(bottleDebt.id!);

        if (result.succeeded) {
            notifySuccess("已成功设置回款");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async showPhotoPopup(bottleDebt: BottleDebtModel) {
        if (bottleDebt.photo && !await confirm2("继续上传将覆盖现有照片，是否继续？")) {
            return;
        }
        
        this.photoRef.current?.show(bottleDebt.id);
    }

    async uploadPhoto(photo: string, id: number) {
        const res = await bottleDebtService.uploadPhoto({ id: id, photo: photo });

        if (res.succeeded) {
            this.photoRef.current?.hide();
            this.gridRef.current?.refresh();
            notifySuccess("成功上传图片");
        } else {
            alertError(res.message!);
        }
    }

    render() {
        return (
            <ContentBlock caption={"欠瓶押瓶"}>
                <BottleDebtToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onFiltering={this.filter}
                    onButtonClick={this.onButtonClick} />
                <BottleDebtGrid
                    permissions={this.permissions}
                    ref={this.gridRef}
                    onBottleDebtSelected={this.onBottleDebtSelected}
                    onButtonClick={this.onButtonClick} />
                <BottleDebtNewForm ref={this.newFormRef} onSaved={this.onSaved} />
                <CompleteDebtPopup ref={this.comFormRef} onSaved={this.onSaved} />
                <ImagePopup ref={this.photoRef} onSaving={this.uploadPhoto} />
            </ContentBlock>
        );
    }
}