import React from "react";
import { Form, Popup } from "devextreme-react";
import { DangerAuditModel } from "../../models";
import { dangerService } from "../../api";
import { alertError, dataSources, enums, MessageBool, notifySuccess } from "../../base";
import { RefPopup } from "../../components";
import { PopupContentBox } from "../layouts";
import { Item, Label, RequiredRule } from "devextreme-react/form";

export interface DangerAuditPopupProps {
    title?: string;
    saveFunc?: (model: DangerAuditModel) => Promise<MessageBool>;
    onSaved: () => void;
}

interface DangerAuditPopupState {
    formData: DangerAuditModel;
}

export default class DangerAuditPopup extends React.Component<DangerAuditPopupProps, DangerAuditPopupState> {
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    auditStatus = dataSources.dangerAuditStatus.filter(s => s.value !== enums.dangerAuditStatus.submitted);

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            formData: { id: 0 }
        };

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    show(id: number) {
        this.popupRef.current?.instance.show();

        this.setState({ formData: { id: id } });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();
        if (!isValid?.isValid) {
            return;
        }

        const res = this.props.saveFunc ?  
            await this.props.saveFunc(this.state.formData) : 
            await dangerService.audit(this.state.formData);

        if (res.succeeded) {
            this.hide();
            notifySuccess("已成功完成审核");
            this.props.onSaved();
        } else {
            alertError(res.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={this.props.title || "隐患整改审核"}
                width={400}
                height={300}>
                <PopupContentBox
                    scrollable={true}
                    onSave={this.save}
                    onCancel={this.cancel}>
                    <Form formData={this.state.formData} ref={this.formRef}>
                        <Item
                            dataField={"auditStatus"}
                            editorType={"dxRadioGroup"}
                            editorOptions={{
                                layout: "horizontal",
                                dataSource: this.auditStatus,
                                displayExpr: 'text',
                                valueExpr: 'value',                            
                            }}>
                            <Label text={"审核结果"} />
                            <RequiredRule message={"请选择审核结果"} />
                        </Item>
                        <Item dataField={"note"} editorType={"dxTextArea"}>
                            <Label text={"备注"} />
                        </Item>
                    </Form>
                </PopupContentBox>
            </RefPopup>
        )
    }
}