import React from "react";
import { OrganizationAdvProfileModel } from "../../models";
import { Form, Popup } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import RefPopup from "../popup/ref-popup";
import { PopupContentBox } from "../layouts";
import { StdForm } from "../form";
import { organizationProfileService } from "../../api";
import { alertError, notifySuccess } from "../../base/dialogs";

interface OrganizationProfilePopupState {
    profile: OrganizationAdvProfileModel;
}

export default class OrganizationProfilePopup
    extends React.Component<any, OrganizationProfilePopupState> {
    moduleName = "OrganizationProfile";
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();

    items: IItemProps[] = [
        { dataField: "merchantId" },
        { dataField: "apiV2Key" },
        { dataField: "apiV3Key" },
        { dataField: "appId" },
        { dataField: "disableStockOutPatch", label: { text: "禁止补领出记录" }, editorType: 'dxCheckBox' },
        { dataField: "disableRecyclePatch", label: { text: "禁止补回收记录" }, editorType: 'dxCheckBox' },
        { dataField: "disableOverloadStockOut", label: { text: "禁止超量领出" }, editorType: 'dxCheckBox' }
    ]

    constructor(props: any) {
        super(props);

        this.state = {
            profile: {}
        };

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    show(id: number) {
        this.popupRef.current?.instance.show();

        this.loadProfile(id);
    }

    async loadProfile(id: number) {
        const profile = await organizationProfileService.get(id);

        this.setState({ profile: profile || { id: id } });
    }

    hide() {
        this.popupRef.current?.instance.hide();

        this.setState({ profile: {} });
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const res = await organizationProfileService.save(this.state.profile);

            if (res.succeeded) {
                this.hide();
                notifySuccess("已成功保存");
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title="编辑"
                width={400}
                height={460}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        items={this.items}
                        formData={this.state.profile} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}