import React from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import './login-card.scss';

export default function LoginCard({ title, subtitle, description, children }: any) {
    return (
        <ScrollView height={'100%'} width={'100%'} className={'with-footer login-card'}>      
            <div className={'dx-card content'}>
                <div className={'header'}>
                    <img src={'/logo.png'} alt="集顺燃气"></img>
                    <div className={'title'}>{title}</div>
                    <div className={'description'}>{description}</div>
                </div>
                {children}
            </div>
        </ScrollView>
    );
}
