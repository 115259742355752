import { AuthContext, AuthContextType } from "../../hooks/auth";
import ChangePasswordPopup from "../popups/change-password-popup";
import MockPopup from "../popups/mock-popup";
import { adminService } from "../../api";
import { alert2, confirm2, globalPopupManager, globalPopupNames, tokenStorage, ubox } from "../../base";
import React from "react";
import { DropDownButton } from "devextreme-react";
import { ScreenSize, ScreenSizeContext } from "../../hooks";

interface UserBoxState {
    pwdPopupVisible: boolean;
    mockPopupVisible: boolean;
}

export default class UserBox extends React.Component<any, UserBoxState> {
    authContext?: AuthContextType;

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            pwdPopupVisible: false,
            mockPopupVisible: false
        };

        this.showPasswordPopup = this.showPasswordPopup.bind(this);
        this.onPasswordPopupHiding = this.onPasswordPopupHiding.bind(this);
        this.switchMocking = this.switchMocking.bind(this);
        this.onMockPopupHiding = this.onMockPopupHiding.bind(this);
    }

    componentDidMount() {
        ubox.start((phoneNumber) => {
            globalPopupManager.show(globalPopupNames.callCenter, { phone: phoneNumber });
        });
    }

    componentWillUnmount() {
        ubox.stop();
    }

    showPasswordPopup() {
        this.setState({ pwdPopupVisible: true });
    }

    onPasswordPopupHiding(succeeded?: boolean) {
        this.setState({ pwdPopupVisible: false });

        if (succeeded) {
            this.authContext?.clearPwdSignal && this.authContext.clearPwdSignal();
        }
    }

    async switchMocking() {
        const user = this.authContext?.user;
        if (!user) {
            return;
        }

        if (user.mocking) {
            if (await confirm2("确定要退出模拟吗？")) {
                const newToken = await adminService.endMocking();
                tokenStorage.token = newToken;
                await alert2("已成功退出模拟，如页面未更新，请手动刷新");
                window.location.reload();
            }
        } else {
            this.setState({ mockPopupVisible: true });
        }
    }

    async onMockPopupHiding(succeeded?: boolean) {
        this.setState({ mockPopupVisible: false });

        if (succeeded) {
            await alert2("已成功模拟用户，从原菜单位置可退出模拟，如页面未更新，请手动刷新");
            window.location.reload();
        }
    }

    render() {
        return (
            <ScreenSizeContext.Consumer>
                {sizeContext => (
                    <AuthContext.Consumer>
                        {context => this.renderMain(context, sizeContext)}
                    </AuthContext.Consumer>
                )}
            </ScreenSizeContext.Consumer>
        )
    }

    collapseName(name: string) {
        let regex = /[(（]([^()（）]*)[)）]/g;
        const realName = name.replace(regex, "");
        const additional = [];
        let match = regex.exec(name);

        while (match) {
            if (match[1]) {
                additional.push(match[1]);
            }

            match = regex.exec(name);
        }

        return additional.length ? `${realName}（${additional.join('，')}）` : realName;
    }

    renderMain(context: AuthContextType, sizeContext: ScreenSize) {
        this.authContext = context;
        const mocking = context.user?.mocking;
        const name = sizeContext.isXSmall ? 
            context.user?.name.replace(/[(（]([^()（）]*)[)）]/g, "") : 
            this.collapseName(context.user?.name + (context.user?.post ? `（${context.user?.post}）` : ''));

        const menuItems = [
            {
                text: '修改密码',
                icon: 'bi bi-lock-fill',
                onClick: this.showPasswordPopup
            },
            {
                text: (mocking ? '退出模拟' : '模拟其它用户'),
                icon: 'bi bi-person-bounding-box' + (mocking ? ' text-primary' : ''),
                onClick: this.switchMocking
            },
            {
                text: '注销退出',
                icon: 'bi bi-box-arrow-right',
                onClick: context.signOut
            }
        ];

        if (!context.user?.isAdmin && !mocking) {
            menuItems.splice(1, 1);
        }

        return (
            <div className={"user-box"}>
                <DropDownButton
                    id={"headerUserDropDown"}
                    stylingMode={"text"}
                    text={name}
                    icon={"user"}
                    dropDownOptions={{ width: 160 }}
                    items={menuItems}
                    displayExpr={"text"}
                />

                <ChangePasswordPopup
                    visible={this.state.pwdPopupVisible}
                    pwdSignal={context.pwdSignal}
                    onHiding={this.onPasswordPopupHiding} />

                <MockPopup visible={this.state.mockPopupVisible} onHiding={this.onMockPopupHiding} />
            </div>
        );
    }
}