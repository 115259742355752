import React from "react";
import { DataGrid, LoadIndicator } from "devextreme-react";
import { ProductOrderModel, CustomerTypeRef, ProductRef } from "../../models";
import { productOrderDeletedStore, productOrderService, productOrderStore, customerTypeService, productService } from "../../api";
import { createGridOptions, ExportModel, remoteReadOnlyGridOptions, setColumnDataSource, StdGrid } from "../../components";
import { IColumnProps } from "devextreme-react/data-grid";
import { ExportingEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import { authorizationService, baseApiUrl, createEmptyStore, enums, globalPopupManager, globalPopupNames, ModuleConfig, StringMap } from "../../base";
import CustomStore from "devextreme/data/custom_store";

export interface ProductOrderGridProps {
    onButtonClick: (model: ProductOrderModel, name: string) => void;
    onModelSelected: (model: ProductOrderModel) => void;
}

interface ProductOrderGridState {
    customerTypes: CustomerTypeRef[];
    products: ProductRef[];
    columns: IColumnProps[];
    store: CustomStore;
}

export default class ProductOrderGrid extends React.Component<ProductOrderGridProps, ProductOrderGridState> {
    objectName = "ProductOrder";
    moduleName: ModuleConfig = {
        moduleName: "ProductOrder",
        children: {
            "customer": "Customer",
            "organization": "Organization",
            "operator": "Employee",
            "order": "Order"
        }
    };

    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("productOrders", this.gridRef, remoteReadOnlyGridOptions);

    nameMap: StringMap = {
        "customer.name": "customerId",
        "organization.name": "OrganizationId",
        "operator.name": "OperatorId"
    }

    constructor(props: Readonly<ProductOrderGridProps>) {
        super(props);

        this.state = {
            customerTypes: [],
            products: [],
            store: createEmptyStore(),
            columns: [
                {
                    dataField: 'customer.name',
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.name}
                        </span>
                    )
                },
                {
                    dataField: 'customer.gasCode',
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.gasCode}
                        </span>
                    )
                },
                {
                    dataField: 'customer.typeId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                {
                    dataField: 'organization.name',
                    allowFiltering: false,
                },
                { 
                    dataField: "operator.name",
                    calculateFilterExpression: function(filterValue: any, op: string) {
                        return [
                            ["operator.name", op, filterValue],
                            "or",
                            ["operator.code", op, filterValue]
                        ]
                    },
                    cellRender: (e: any) => (<span>{e.data.operator?.nameAndCode}</span>)
                },
                { dataField: "order.code", caption: "关联订单" },    
                { dataField: "createdAt", dataType: "datetime" },
                { dataField: "totalPrice", type: "number", format: "currency" },   
                { dataField: "note" }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
        this.gridOptions.onExporting = this.onExporting.bind(this);
        this.gridOptions.export!.allowExportSelectedData = false;
        this.gridOptions.export!.enabled = false;
    }

    componentDidMount() {
        this.authorizeImport();
        this.loadData();
    }

    async authorizeImport() {
        if (await authorizationService.authorize(this.objectName, enums.stdActions.export)) {
            this.gridOptions.export!.enabled = true;
            this.gridRef.current?.instance.option("export.enabled", true);
        }
    }

    async loadData() {
        var types = await customerTypeService.getRefs();
        var products = await productService.getAccessories()

        setColumnDataSource(this.state.columns, "customer.typeId", types);

        const columns = [
            ...this.state.columns.slice(0, this.state.columns.length - 1),
            ...products.map((p: any) => ({
                dataField: `item_${p.id}`,
                caption: p.name,
                allowGrouping: false,
                allowSorting: false,
                allowFiltering: false,
                cellRender: function (e: any) {
                    return e.data.items.find((i: any) => i.productId === p.id)?.amount || "0";
                }
            })),   
            this.state.columns[this.state.columns.length - 1]
        ]

        this.gridOptions.summary = {
            totalItems: [
                { column: "totalPrice", summaryType: "sum", valueFormat: "currency", displayFormat: "计：{0}" },
                ...products.map((p) => ({
                    column: `item_${p.id}`, summaryType: "sum", displayFormat: "计：{0}"
                }))
            ],
        };

        this.setState({ 
            customerTypes: types, 
            products: products,
            columns: columns 
        });
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    filter(filterValue: any, showDeleted: boolean) {
        if (showDeleted && this.state.store !== productOrderDeletedStore) {
            this.setState({ store: productOrderDeletedStore }, () => this._filter(filterValue));
        } else if (!showDeleted && this.state.store !== productOrderStore) {
            this.setState({ store: productOrderStore }, () => this._filter(filterValue))
        } else {
            this._filter(filterValue);
        }
    }

    _filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});
        }
    }
    
    onExporting(e: ExportingEvent) {
        const args: ExportModel = {
            moduleName: this.moduleName.moduleName,
            exportUrl: `${baseApiUrl}${productOrderService.apiPath}/export`,
            columns: this.state.columns,
            keys: e.component.getSelectedRowKeys(),
            filter: e.component.getCombinedFilter(),
            showDeleted: this.state.store === productOrderDeletedStore
        };

        e.cancel = true;
        globalPopupManager.show(globalPopupNames.exportPopup, args);
    }

    render() {
        return this.state.products.length ? (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columnNameMap={this.nameMap}
                columns={this.state.columns}
                toolbarHeight={80} />
        ) : (
            <div className="flex-box" style={{height:"200px"}}>
                <LoadIndicator />
            </div>
        )
    }
}