import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import { createGridOptions, stdReadOnlyGridOptions, StdGrid, setColumnDataSource } from "../../components";
import { organizationService, vehicleUserStore } from "../../api";
import { dataSources, enums } from "../../base";
import { OrganizationRef, VehicleUserModel } from "../../models";

interface VehicleUserGridProps {
    onModelSelected: (model: VehicleUserModel) => void;
    onButtonClick: (model: VehicleUserModel, name: string) => void;
}

interface VehicleUserGridState {
    orgs: OrganizationRef[];
    columns: IColumnProps[];
}

export default class VehicleUserPage extends React.Component<VehicleUserGridProps, VehicleUserGridState> {
    moduleName = "VehicleUser";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("vehicleUsers", this.gridRef, stdReadOnlyGridOptions);

    constructor(props: Readonly<VehicleUserGridProps>) {
        super(props);

        this.state = {
            orgs: [],
            columns: [
                { dataField: 'name' },
                {
                    dataField: 'post',
                    lookup: {
                        dataSource: [
                            { value: enums.vehiclePosts.all, text: '司机、押运员' },
                            ...dataSources.vehiclePosts
                        ],
                        displayExpr: 'text',
                        valueExpr: 'value'
                    }
                },
                {
                    dataField: 'organizationId',
                    allowFiltering: false,
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: 'code' },
                { dataField: 'idNo' },
                { dataField: 'phoneNumber' }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
    }

    componentDidMount(): void {
        this.loadOrgs();
    }

    async loadOrgs() {
        const orgs = await organizationService.getRefs();
        setColumnDataSource(this.state.columns, "organizationId", orgs);
        this.setState({ orgs: orgs });
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});
        }
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }
    
    filter(condition: any) {
        this.gridRef.current?.instance.filter(condition);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={vehicleUserStore}
                options={this.gridOptions}
                columns={this.state.columns} />
        );
    }
}