import { ReactNode } from "react";
import { bottleTypeService } from "../../../api";
import { baseApiUrl, DataMap, dateAdd, download, enums, fdate, http } from "../../../base";
import { ContentBlock, FullPanel } from "../../../components";
import { BottleStocktakingModel, BottleTypeModel, GasStocktakingModel } from "../../../models";
import { ScrollView } from "devextreme-react";
import { StocktakingReportCondition } from "../../../models";
import React from "react";
import { bottleStocktakingReportService, gasStocktakingReportService } from "../../../api/reports";
import StocktakingReportToolbar from "./stocktaking-report-toolbar";

interface DataGroup {
    date: string;
    gas?: GasStocktakingModel;
    bottle?: BottleStocktakingModel;
}

interface StocktakingReportPageState {
    bottleTypes: BottleTypeModel[];
    condition?: StocktakingReportCondition;
    gasList: DataMap<GasStocktakingModel>;
    bottleList: DataMap<BottleStocktakingModel>;
    groupList: DataGroup[];
}

export default class StocktakingReportPage
    extends React.Component<any, StocktakingReportPageState> {
    constructor(props: any) {
        super(props);

        this.state = {
            bottleTypes: [],
            gasList: {},
            bottleList: {},
            groupList: []
        };

        this.search = this.search.bind(this);
        this.export = this.export.bind(this);
    }

    componentDidMount(): void {
        this.loadBottleTypes();
    }

    async loadBottleTypes() {
        const bottleTypes = await bottleTypeService.getRefs();
        this.setState({ bottleTypes: bottleTypes });
    }

    async search(condition: StocktakingReportCondition) {
        let gasList: DataMap<GasStocktakingModel> = {},
            bottleList: DataMap<BottleStocktakingModel> = {};

        await Promise.all([
            ((async () => gasList = await gasStocktakingReportService.get(condition)))(),
            ((async () => bottleList = await bottleStocktakingReportService.get(condition)))()
        ]);

        const groupList: DataGroup[] = [],
            startDate = new Date(condition.startDate),
            endDate = new Date(condition.endDate);

        for (let d = startDate; d <= endDate; d = dateAdd(d as Date, 1)) {
            const dateString = fdate(d, true);

            // 仅显示有数据的项（以支持显示按天或其它方式的盘点）
            if (gasList[dateString] || bottleList[dateString]) {
                groupList.push({
                    date: dateString,
                    gas: gasList[dateString],
                    bottle: bottleList[dateString]
                });
            }
        }

        this.setState({
            condition: condition,
            gasList: gasList,
            bottleList: bottleList,
            groupList: groupList
        })
    }

    async export(condition: StocktakingReportCondition) {
        await download(
            `${baseApiUrl}${gasStocktakingReportService.apiPath}/export?${http.serialize([condition])}`, 
            "库存报表.xlsx");
    }

    render(): ReactNode {
        const { groupList, bottleTypes } = this.state;

        return (
            <ContentBlock caption={"库存报表"}>
                <StocktakingReportToolbar onSearching={this.search} onExporting={this.export} />
                <FullPanel>
                    <ScrollView width={"100%"} showScrollbar="always">
                        <div>
                            {groupList.map(g => (
                                <div key={g.date} className="stocktaking-container">
                                    <fieldset>
                                        <legend>{g.date}</legend>
                                        <h4>燃气库存{g.gas ? '' : '（未盘点）'}</h4>
                                        {g.gas && (
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>商品名称</th>
                                                        <th>期初库存</th>
                                                        <th>重瓶调拨入库（+）</th>
                                                        <th>重瓶出库（-）</th>
                                                        <th>重瓶退回供应站（+）</th>
                                                        <th>重瓶退回充装站（-）</th>
                                                        <th>应有库存</th>
                                                        <th>实际库存</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bottleTypes.map(t => {
                                                        const item = g.gas?.items?.find(i => i.bottleTypeId === t.id);
                                                        const typeItems = g.gas?.typeItems?.filter(i => i.bottleTypeId === t.id) || [];

                                                        return (
                                                            <tr key={t.id}>
                                                                <th>{t.name}液化气</th>
                                                                <th>{item?.initGas}</th>
                                                                <th>{typeItems.find(i => i.operationType === enums.bottleOperations.allot)?.amount}</th>
                                                                <th>{typeItems.find(i => i.operationType === enums.bottleOperations.stockOut)?.amount}</th>
                                                                <th>{typeItems.find(i => i.operationType === enums.bottleOperations.customerBack)?.amount}</th>
                                                                <th>{typeItems.find(i => i.operationType === enums.bottleOperations.sendBack)?.amount}</th>
                                                                <th>{item?.expectGas}</th>
                                                                <th>{item?.actualGas}</th>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        )}
                                        <h4>气瓶库存{g.bottle ? '' : '（未盘点）'}</h4>
                                        {g.bottle && (
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>商品名称</th>
                                                        <th>期初库存</th>
                                                        <th>重瓶调拨入库（+）</th>
                                                        <th>空瓶调拨出库（-）</th>
                                                        <th>重瓶出库（-）</th>
                                                        <th>空瓶入库（+）</th>
                                                        <th>重瓶退回供应站（+）</th>
                                                        <th>重瓶退回充装站（-）</th>
                                                        <th>应有库存</th>
                                                        <th>实际库存</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {g.bottle && bottleTypes.map(t => {
                                                        const item = g.bottle?.items?.find(i => i.bottleTypeId === t.id);
                                                        const typeItems = g.bottle?.typeItems?.filter(i => i.bottleTypeId === t.id) || [];

                                                        return (
                                                            <tr key={t.id}>
                                                                <th>{t.name}瓶</th>
                                                                <th>{item?.initBottles}</th>
                                                                <th>{typeItems.find(i => i.operationType === enums.bottleOperations.allot)?.amount}</th>
                                                                <th>{typeItems.find(i => i.operationType === enums.bottleOperations.emptyBack)?.amount}</th>
                                                                <th>{typeItems.find(i => i.operationType === enums.bottleOperations.stockOut)?.amount}</th>
                                                                <th>{typeItems.find(i => i.operationType === enums.bottleOperations.emptyIn)?.amount}</th>
                                                                <th>{typeItems.find(i => i.operationType === enums.bottleOperations.customerBack)?.amount}</th>
                                                                <th>{typeItems.find(i => i.operationType === enums.bottleOperations.sendBack)?.amount}</th>
                                                                <th>{item?.expectBottles}</th>
                                                                <th>{item?.actualBottles}</th>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        )}
                                    </fieldset>
                                </div>
                            ))}
                        </div>
                    </ScrollView>
                </FullPanel>
            </ContentBlock>
        )
    }
}