import React from "react";
import DataGrid, { IColumnProps, IDataGridOptions } from "devextreme-react/data-grid";
import { ExportingEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import { authorizationService, baseApiUrl, createEmptyStore, enums, globalPopupManager, globalPopupNames, ModuleConfig, User } from "../../base";
import { bottleFillingStore, fillingUserService, fillingTargetService, bottleFillingService, bottleTypeService } from "../../api";
import { createGridOptions, ExportModel, remoteReadOnlyGridOptions, setColumnDataSource, StdGrid } from "../../components";
import { BottleFillingModel, BottleTypeModel, FillingTargetRef, FillingUserRef } from "../../models";
import CustomStore from "devextreme/data/custom_store";

export interface BottleFillingGridProps {
    user?: User;
    onModelSelected(model: BottleFillingModel): void;
    onButtonClick(model: BottleFillingModel, name: string): void;
}

interface BottleFillingGridState {
    store: CustomStore;
    users: FillingUserRef[];
    targets: FillingTargetRef[];
    bottleTypes: BottleTypeModel[];
    columns: IColumnProps[];
}

export default class BottleFillingGrid 
    extends React.Component<BottleFillingGridProps, BottleFillingGridState> {
    objectName = "BottleFilling";
    moduleName: ModuleConfig = {
        moduleName: "BottleFilling",
        children: {
            "bottle": "Bottle"
        }
    };

    gridRef = React.createRef<DataGrid>();
    gridOptions: IDataGridOptions;

    constructor(props: Readonly<BottleFillingGridProps>) {
        super(props);

        this.gridOptions = createGridOptions(
            "bottleFillings",
            this.gridRef,
            remoteReadOnlyGridOptions,
            (items: any[]) => {
                // state清空会同时清空filter，需要重新绑定旧的
                items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
                return items;
            });

        this.state = {
            store: createEmptyStore(),
            users: [],
            targets: [],
            bottleTypes: [],
            columns: [
                { dataField: 'barCode' },
                { dataField: 'bottle.code', allowFiltering: false },
                { dataField: 'bottle.vendor' },
                {
                    dataField: 'bottle.typeId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: 'bottle.typeCode' },
                { dataField: 'fillingTime', dataType: "datetime", allowFiltering: false },
                {
                    dataField: 'userCode',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'code' }
                },
                { dataField: 'scaleNum' },
                {
                    dataField: 'target',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'code' }
                },
                { dataField: 'originalWeight' },
                { dataField: 'weight' },
                { dataField: 'fillingWeight' },
                { dataField: 'setOriginalWeight' },
                { dataField: 'setWeight' },
                { dataField: 'setFillingWeight' }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onExporting = this.onExporting.bind(this);
        this.gridOptions.export!.allowExportSelectedData = false;
        this.gridOptions.export!.enabled = false;
    }

    componentDidMount() {
        this.authorizeImport();
        this.loadData();
    }
    
    async authorizeImport() {
        if (await authorizationService.authorize(this.objectName, enums.stdActions.export)) {
            this.gridOptions.export!.enabled = true;
            this.gridRef.current?.instance.option("export.enabled", true);
        }
    }
    
    async loadData() {
        const newState: BottleFillingGridState = { ...this.state };

        await Promise.all([
            (async () => newState.users = await fillingUserService.getRefs())(),
            (async () => {
                const data = await fillingTargetService.getRefs();
                newState.targets = data.map(t => ({ code: t.code, name: `${t.organization!.name} | ${t.code}` }));
            })(),
            (async () => {
                newState.bottleTypes = await bottleTypeService.getAll();
            })()
        ]);

        setColumnDataSource(newState.columns, "userCode", newState.users);
        setColumnDataSource(newState.columns, "target", newState.targets);
        setColumnDataSource(this.state.columns, "bottle.typeId", newState.bottleTypes);

        this.setState(newState);
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }
    
    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});
        }
    }
    
    onExporting(e: ExportingEvent) {
        const args: ExportModel = {
            moduleName: this.moduleName.moduleName,
            exportUrl: `${baseApiUrl}${bottleFillingService.apiPath}/export`,
            columns: this.state.columns,
            keys: e.component.getSelectedRowKeys(),
            filter: e.component.getCombinedFilter()
        };

        e.cancel = true;
        globalPopupManager.show(globalPopupNames.exportPopup, args);
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    filter(filterValue: any) {
        if (filterValue) {
            this.setState({ store: bottleFillingStore }, () => this.gridRef.current?.instance.filter(filterValue));
        } else {
            this.setState({ store: createEmptyStore() });
        }
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns}>
            </StdGrid>
        );
    }
}

