import { http } from "../../base";
import { AreaDeliveringReport, AreaDeliveringReportCondition } from "../../models";

class AreaReportService {
    apiPath = "/api/report/area";

    getFillingDelivering(condition: AreaDeliveringReportCondition) {
        return http.getData<AreaDeliveringReport[]>(
            `${this.apiPath}/delivering?${http.serialize([condition])}`);
    }
}

export const areaReportService = new AreaReportService();