import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { enums, SignalBool, User, tokenService, tokenTimer, TokenEventArgs } from "../base";

export interface AuthContextType {
    user?: User;
    signIn?: (username: string, password: string, remember: boolean) => Promise<SignalBool<string>>;
    autoSignIn?: () => Promise<SignalBool<string>>;
    signOut?: () => void;
    refreshUser?: () => void;
    loading?: boolean; // 使用loading在加载时显示读取中而不是从登录页突然跳转到首页（或者相反）
    pwdSignal?: string | null;
    clearPwdSignal?: () => void;
}

const AuthContext: React.Context<AuthContextType> = createContext({});
const useAuth = () => useContext(AuthContext);
const pwdStorageKey = "pwd-signal";

function AuthProvider(props: any) {
    const [user, setUser] = useState<User | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [pwdSignal, setPwdSignal] = useState<string | null>(localStorage.getItem(pwdStorageKey));

    const onAuthChanged = useCallback(function (e: TokenEventArgs) {
        setUser(e.user);
    }, []);

    const signIn = useCallback(async (username, password, remember) => {
        const res = await tokenService.signIn(username, password, remember);
        const signal = res.signals && res.signals[enums.signalTypes.changePassword];
        if (signal) {
            localStorage.setItem(pwdStorageKey, signal);    // 保存，以防止使用刷新跳过密码修改
            setPwdSignal(signal);
        }
        return res;
    }, []);

    const autoSignIn = useCallback(async () => {
        const res = await tokenService.autoSignIn();
        const signal = res.signals && res.signals[enums.signalTypes.changePassword];
        if (signal) {
            localStorage.setItem(pwdStorageKey, signal);    // 保存，以防止使用刷新跳过密码修改
            setPwdSignal(signal);
        }
        return res;
    }, [])

    const signOut = useCallback(function () {
        tokenService.signOut();
    }, []);

    const refreshUser = useCallback(async function () {
        const data = await tokenService.getUser(true);
        setUser(data);
    }, []);

    const clearPwdSignal = useCallback(function () {
        localStorage.removeItem(pwdStorageKey);
        setPwdSignal(null);
    }, []);

    useEffect(function () {
        tokenService.addHandler(onAuthChanged);
        return function () {
            tokenService.removeHandler(onAuthChanged);
        }
    }, [onAuthChanged]);

    useEffect(function () {
        (async function () {
            setLoading(true);

            var user = await tokenService.getUser();

            // 未登录尝试自动登录
            if (!user) {
                const result = await tokenService.autoSignIn();
                if (result.succeeded) {
                    user = await tokenService.getUser();
                }
            }

            setUser(user);

            setLoading(false);

            if (user) {
                tokenTimer.initTimer(tokenService.refreshToken.bind(tokenService));
            }
        })();
    }, []);

    return (
        <AuthContext.Provider value={{ user, signIn, autoSignIn, signOut, refreshUser, loading, pwdSignal, clearPwdSignal }} {...props} />
    );
}

export {
    AuthContext,
    AuthProvider,
    useAuth
}