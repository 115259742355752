import { tokenStorage } from '.';
import { now, timeAdd } from './base-values';
import { http } from './http'
import { MessageBool } from './models'

export interface BaseApi<T = any> {
    apiPath: string;
    searchAll(): Promise<T[]>;
    get(id: number | string): Promise<T>;
    create(model: T): Promise<MessageBool>;
    update(model: T): Promise<MessageBool>;
    delete(id: number | string): Promise<MessageBool>;
}

export abstract class CachableApiService {
    cache: any;

    getCached(getFunc: (...args: any[]) => Promise<any>, cacheSeconds: number, personal?: boolean, cacheKey?: string) {
        if (!this.cache) {
            this.cache = {};
        }

        let cache = this.cache;

        if (personal) {
            if (!this.cache.personal || this.cache.personalKey !== tokenStorage.token) {
                this.cache.personal = {};
                this.cache.personalKey = tokenStorage.token;
            }

            cache = this.cache.personal;
        }

        if (cacheKey) {
            if (!cache[cacheKey]) {
                cache[cacheKey] = {};
            }

            cache = cache[cacheKey];
        }

        // 共用promise，避免因多个组件同时请求（在缓存还未生成前）而多次请求服务器
        if (!cache.promise) {
            cache.promise = cacheWrap(getFunc, cache, cacheSeconds)();
        }

        return cache.promise;
    }
}

export abstract class BaseApiService<T = any> extends CachableApiService implements BaseApi<T> {
    abstract moduleName: string;

    get apiPath() { return `/api/${this.moduleName}`; }

    async searchAll(): Promise<T[]> {
        return await http.getData(this.apiPath);
    }

    async get(id: number | string): Promise<T> {
        return await http.getData(`${this.apiPath}/${id}`);
    }

    async create(model: T): Promise<MessageBool> {
        return await http.postData(this.apiPath, model);
    }

    async update(model: T): Promise<MessageBool> {
        return await http.putData(this.apiPath, model);
    }

    async delete(id: number | string): Promise<MessageBool> {
        return await http.deleteData(`${this.apiPath}/${id}`);
    } 
}

function cacheWrap(
    getFunc: (...args: any[]) => Promise<any>, 
    cacheObj: any, 
    cacheSeconds: number) {
    const t = cacheObj;

    return async function(...args: any[]) {
        if (t.cache && t.cacheExpiration && t.cacheExpiration > now()) {
            return t.cache;
        } else {
            t.cache = await getFunc.apply(window, args);
            t.cacheExpiration = timeAdd(now(), cacheSeconds, "s");
            return t.cache!;
        }
    }
}