import { RouteConfig } from "./route-model";
import CustomerTypePage from "../pages/admin/customer-type";
import AreaPage from "../pages/admin/area";
import FillingUserPage from "../pages/admin/filling-user";
import BottleVendorPage from "../pages/admin/bottle-vendor";
import FillingTargetPage from "../pages/admin/filling-target";
import ProductPage from "../pages/admin/product";
import DataSourcePage from "../pages/admin/data-source";
import PredefinedLabelPage from "../pages/admin/predefined-label";
import SecurityCheckTypePage from "../pages/admin/security-check-type";
import SiteCheckTypePage from "../pages/admin/site-check-type";
import ExceptionSettingPage from "../pages/admin/exception-setting";
import BugTracePage from "../pages/admin/bug-trace";
import FillingCheckTypePage from "../pages/admin/filling-check-type";
import WxConfigPage from "../pages/admin/wxconfig";
import SysLogPage from "../pages/admin/sys-log";
import OrderCheckTypePage from "../pages/admin/order-check-type";

export const adminRoutes: RouteConfig[] = [
    {
        path: '/customer-type',
        component: CustomerTypePage
    },
    {
        path: '/area',
        component: AreaPage
    },
    {
        path: '/bottle-vendor',
        component: BottleVendorPage
    },
    {
        path: '/filling-user',
        component: FillingUserPage
    },
    {
        path: '/filling-target',
        component: FillingTargetPage
    },
    {
        path: '/product',
        component: ProductPage
    },
    {
        path: '/data-source',
        component: DataSourcePage
    },
    {
        path: '/predefined-label',
        component: PredefinedLabelPage
    },
    {
        path: '/security-check-type',
        component: SecurityCheckTypePage
    },
    {
        path: '/site-check-type', 
        component: SiteCheckTypePage
    },
    {
        path: '/exception-setting',
        component: ExceptionSettingPage
    },
    {
        path: '/bug-trace',
        component: BugTracePage
    },
    {
        path: '/filling-check-type',
        component: FillingCheckTypePage
    },
    {
        path: '/wxconfig',
        component: WxConfigPage
    },
    {
        path: '/sys-log',
        component: SysLogPage
    },
    {
        path: '/order-check-type',
        component: OrderCheckTypePage
    }
]