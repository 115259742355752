import { LongTimeToken } from "./models";

const tokenKey = 'api.bearer';
const longTimeTokenKey = 'longtimetoken';

class TokenStorageClass {
    get token() { return localStorage.getItem(tokenKey) || ""; }
    set token(value: string) { localStorage.setItem(tokenKey, value); }

    get longTimeToken(): LongTimeToken|undefined { 
        const data = localStorage.getItem(longTimeTokenKey);
        return data ? JSON.parse(data) : undefined;
    }
    
    set longTimeToken(value: LongTimeToken|undefined) {
        if (value == null) {
            localStorage.removeItem(longTimeTokenKey);
        } else {
            localStorage.setItem(longTimeTokenKey, JSON.stringify(value));
        }
    }

    clear() { localStorage.removeItem(tokenKey); }
}

export const tokenStorage = new TokenStorageClass();