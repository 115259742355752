import { IColumnProps } from "devextreme-react/tree-list";
import React from "react";
import { StdGrid } from "../grid";
import { bottleDebtService, bottleTypeService } from "../../api";
import { BottleTypeModel, BriefBottleDebt } from "../../models";
import { IDataGridOptions } from "devextreme-react/data-grid";

export interface CustomerDebtProps {
    id?: number;
}

interface CustomerDebtState {
    debts: BriefBottleDebt[];
    types: BottleTypeModel[];
    columns: IColumnProps[];
}

export default class CustomerDebt extends React.Component<CustomerDebtProps, CustomerDebtState> {
    moduleName = "BottleDebt";

    gridOptions: IDataGridOptions = {
        columnAutoWidth: true,
        scrolling: { showScrollbar: "never" },
    };
    
    constructor(props: Readonly<CustomerDebtProps>) {
        super(props);

        this.state = {
            debts: [],
            types: [],
            columns: [
                { dataField: "code" },
                { dataField: "deposit" }
            ]
        };
    }

    componentDidMount() {
        this.loadTypes();
    }

    async loadTypes() {
        const types = await bottleTypeService.getAuthorizedRefs();

        const columns = [
            this.state.columns[0],
            {
                name: 'items',
                caption: '气瓶',
                cellRender: function (e: any) {
                    const texts: string[] = [];

                    for (const type of types) {
                        const item = e.data.items.find((i: any) => i.bottleTypeId === type.id);
                        if (item && item.number) {
                            texts.push(`${type.name}(${item.number})`);
                        }
                    }

                    return texts.join("，");
                }
            },
            this.state.columns[1]
        ];

        this.setState({
            types: types,
            columns: columns
        });
    }

    componentDidUpdate(prevProps: CustomerDebtProps) {
        if (this.props.id && this.props.id !== prevProps.id) {
            this.loadData();
        } else if (!this.props.id && prevProps.id) {
            this.setState({ debts: [] });
        }
    }

    async loadData() {
        const data = await bottleDebtService.searchByCustomerId(this.props.id!);
        this.setState({ debts: data });
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                dataSource={this.state.debts}
                columns={this.state.columns}
                options={this.gridOptions}
                autoHeight={true} />
        )
    }
}