import React from "react";
import { fdate } from "../../base";

export default class Welcome extends React.Component<any, any> {
    timer: any;

    constructor(props: any) {
        super(props);

        this.state = {
            time: fdate(new Date())
        };
    }

    componentDidMount(): void {
        this.timer = setInterval(() => {
            this.setState({ time: fdate(new Date()) });
        }, 1000);
    }
    
    componentWillUnmount(): void {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    render() {
    return (
        <div className='home-welcome'>
            <h2>欢迎使用</h2>
            <h1 className='text-primary'>集顺燃气配送管理系统</h1>
            <h2>{this.state.time}</h2>
        </div>
    );
    }
}