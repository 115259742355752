import React from "react";
import { Button, DateBox, SelectBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { DataMap, dateAdd, enums, ListItem, today } from "../../base";
import { AreaRef, EmployeeRef, OrganizationRef, SecurityCheckModel } from "../../models";
import { areaService, employeeService, emptyDataSources, organizationService } from "../../api";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { OrgTreeDropDown } from "../../components";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { ValueChangedEvent as TextChangedEvent } from "devextreme/ui/text_box";

interface SecurityCheckToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (securityCheck: SecurityCheckModel, name: string) => void;
    onContextButtonClick: (ids: number[], name: string) => void;
    onFiltering: (filterValue: any, showDeleted: boolean) => void;
}

interface SecurityCheckToolbarState {
    model: SecurityCheckModel;
    ids: number[];
    orgs: OrganizationRef[];
    allCheckers: EmployeeRef[];
    checkers: EmployeeRef[];
    counties: AreaRef[];
    allStreets: AreaRef[];
    streets: AreaRef[];
    organizationId?: number;
    checkerId?: string;
    countyId?: number;
    streetId?: number;
    startDate?: Date | string;
    endDate?: Date | string;
    showDeleted: boolean;
    customerKey?: string;
}

export default class SecurityCheckToolbar
    extends React.Component<SecurityCheckToolbarProps, SecurityCheckToolbarState> {
    constructor(props: Readonly<SecurityCheckToolbarProps>) {
        super(props);

        const startDate = today();

        this.state = {
            model: {},
            ids: [],
            orgs: [],
            allCheckers: [],
            checkers: [],
            counties: [],
            allStreets: [],
            streets: [],
            showDeleted: false,
            startDate: dateAdd(startDate, -7),
            endDate: startDate
        };

        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onDeliverChanged = this.onDeliverChanged.bind(this);
        this.onCountyChanged = this.onCountyChanged.bind(this);
        this.onStreetChanged = this.onStreetChanged.bind(this);
        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);
        this.onCustomerChanged = this.onCustomerChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount() {
        this.loadDataSources();
        setTimeout(this.onFiltering, 100);
    }

    async loadDataSources() {
        const dataSources = emptyDataSources();

        await Promise.all([
            (async () => dataSources.orgs = await organizationService.getRefs())(),
            (async () => dataSources.employees = await employeeService.getRefs(
                enums.employeePosts.deliver, enums.employeePosts.securityInspector))(),
            (async () => dataSources.counties = await areaService.getCounties())(),
            (async () => dataSources.streets = await areaService.getStreets())(),
        ]);

        this.setState({
            orgs: dataSources.orgs,
            allCheckers: dataSources.employees,
            checkers: dataSources.employees,
            counties: dataSources.counties,
            allStreets: dataSources.streets,
            streets: dataSources.streets
        })
    }

    setModel(model: SecurityCheckModel) {
        this.setState({ model: model });
    }

    setIds(ids: number[]) {
        this.setState({ ids: ids });
    }

    onOrganizationChanged(organizationId?: number) {
        const delivers = organizationId ?
            this.state.allCheckers.filter(d => d.organizationId === organizationId) :
            this.state.allCheckers;

        const deliverId = delivers.find(d => d.id === this.state.checkerId) ? this.state.checkerId : undefined;

        this.setState({
            organizationId: organizationId,
            checkers: delivers,
            checkerId: deliverId
        });
    }

    onFiltering() {
        this.props.onFiltering(this.getFilterValue(), this.state.showDeleted);
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { startDate, endDate, organizationId, checkerId, countyId, streetId, customerKey } = this.state;

        if (startDate) {
            filters.push(["checkTime", ">=", startDate]);
        }
        if (endDate) {
            filters.push(["checkTime", "<", dateAdd(new Date(endDate), 1)])
        }
        if (organizationId) {
            filters.push(["organizationId", "=", organizationId]);
        }
        if (checkerId) {
            filters.push(["checkerId", "=", checkerId]);
        }
        if (countyId) {
            filters.push(["customer.areaId", "=", countyId]);
        }
        if (streetId) {
            filters.push(["customer.streetId", "=", streetId]);
        }
        if (customerKey) {
            filters.push([
                [ "customer.gasCode", "contains", customerKey],
                "or",
                [ "customer.name", "contains", customerKey]
            ]);
        }

        return filters.length > 0 ? filters : null;
    }

    onDeliverChanged(e: ValueChangedEvent) {
        this.setState({ checkerId: e.value });
    }

    onCountyChanged(e: ValueChangedEvent) {
        const streets = e.value ?
            this.state.allStreets.filter(s => s.parentId === e.value) :
            this.state.allStreets;

        const streetId = streets.find(s => s.id === this.state.streetId) ? this.state.streetId : undefined;

        this.setState({
            countyId: e.value,
            streets: streets,
            streetId: streetId
        });
    }

    onStreetChanged(e: ValueChangedEvent) {
        this.setState({ streetId: e.value });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onStatusChanged(e: ValueChangedEvent) {
        this.setState({ showDeleted: e.value });
    }

    onCustomerChanged(e: TextChangedEvent) {
        this.setState({ customerKey: e.value });
    }

    render() {
        const { model, ids, orgs, checkers, counties, streets, organizationId, checkerId,
            countyId, streetId, startDate, endDate, showDeleted, customerKey } = this.state;
        const permissions = this.props.permissions;
        const canAudit = !!model.id;

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto" text={"检查时间："} />
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={startDate}
                            placeholder="开始时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"}>
                        <Button
                            text="查看详情"
                            disabled={!model?.id}
                            onClick={() => this.props.onButtonClick(model, "detail")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            text="导出检查表"
                            disabled={!ids.length}
                            onClick={() => this.props.onContextButtonClick(ids, "export")} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions["Audit"]}>
                        <Button
                            text="审核"
                            disabled={!canAudit}
                            onClick={() => this.props.onButtonClick(model, "audit")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions["Archive"]}>
                        <Button
                            text="归档"
                            disabled={!model?.id || model?.archived}
                            onClick={() => this.props.onButtonClick(model, "archive")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.delete]}>
                        <Button
                            text="作废"
                            disabled={!model?.id || model.deleted}
                            onClick={() => this.props.onButtonClick(model, "delete")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" 
                        visible={permissions[enums.stdActions.delete] && !!model?.deleted}>
                        <Button
                            text="还原"
                            onClick={() => this.props.onButtonClick(model, "restore")} />
                    </Item>
                </Toolbar>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={showDeleted}
                            dataSource={statusSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={120}
                            onValueChanged={this.onStatusChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={checkerId}
                            dataSource={checkers}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={160}
                            placeholder="巡查人员"
                            onValueChanged={this.onDeliverChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={countyId}
                            dataSource={counties}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder="行政区域"
                            onValueChanged={this.onCountyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={streetId}
                            dataSource={streets}
                            displayExpr="name"
                            valueExpr={"id"}
                            searchEnabled={true}
                            showClearButton={true}
                            width={100}
                            placeholder="街道"
                            onValueChanged={this.onStreetChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <TextBox
                            value={customerKey}
                            showClearButton={true}
                            placeholder="客户名称或卡号"
                            onValueChanged={this.onCustomerChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                </Toolbar>
            </div>
        )
    }
}

const statusSource: ListItem<boolean>[] = [
    { value: false, text: "查看正常数据" },
    { value: true, text: "查看已作废" }
]