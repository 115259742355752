import React from "react";
import { Form, Popup } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import { bottleTypeService, productService } from "../../api";
import { alertError, notifySuccess, dataSources, enums, modelClone } from "../../base";
import { PopupContentBox, StdForm, RefPopup } from "../../components";
import { BottleTypeModel, ProductModel } from "../../models";

export interface ProductFormProps {
    onSaved: () => void;
}

interface ProductFormState {
    bottleTypes: BottleTypeModel[];
    items: IItemProps[];
}

export default class ProductForm extends React.Component<ProductFormProps, ProductFormState> {
    moduleName = "Product";
    
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    product: ProductModel = {};

    constructor(props: Readonly<ProductFormProps>) {
        super(props);

        this.state = {
            bottleTypes: [],
            items: [
                { 
                    dataField: "type", 
                    editorType: "dxSelectBox",
                    editorOptions: { 
                        dataSource: dataSources.productTypes,
                        displayExpr: "text",
                        valueExpr: "value",
                        onValueChanged: this.onTypeChanged.bind(this)
                    } 
                },
                { dataField: "name" },
                { 
                    dataField: "bottleTypeId",
                    editorType: "dxSelectBox",
                    editorOptions: { 
                        dataSource: [], 
                        displayExpr: "name", 
                        valueExpr: "id",
                        onValueChanged: this.onBottleTypeChanged.bind(this)
                    }
                },
                { dataField: "price" },
                // { dataField: "webPrice" },
                // { dataField: "appPrice" },
                // { dataField: "wxPrice" }
            ]
        };

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.loadBottleTypes();
    }
    
    async loadBottleTypes() {
        const data = await bottleTypeService.getAll();
        const item = this.state.items.find(i => i.dataField === "bottleTypeId");
        item!.editorOptions!.dataSource = data;

        this.setState({
            bottleTypes: data,
        });
    }

    onTypeChanged(e: any) {
        const bottleTypeItem = this.state.items.find(i => i.dataField === "bottleTypeId")!;
        bottleTypeItem.visible = e.value === enums.productTypes.bottle;

        const nameItem = this.state.items.find(i => i.dataField === "name")!;
        nameItem.visible = e.value !== enums.productTypes.bottle;

        if (e.value === enums.productTypes.gas) {
            nameItem.editorOptions = { readOnly: true };
            this.product.name = dataSources.productTypes.find(t => t.value === e.value)?.text;
        } else {
            nameItem.editorOptions = { readOnly: false };
        }

        this.setState({});
    }

    onBottleTypeChanged(e: any) {
        if (this.product.type === enums.productTypes.bottle) {
            const type = this.state.bottleTypes.find(t => t.id === e.value);
            this.product.name = type?.name;
        }
    }

    show(product: ProductModel) {
        this.popupRef.current?.instance.show();

        this.product = modelClone(product);
        this.onTypeChanged({ value: product.type });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const product = this.product;
            const res = product.id ? 
                await productService.update(product) : 
                await productService.create(product);

            if (res.succeeded) {
                this.hide();
                notifySuccess("已成功保存");
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup 
                popupRef={this.popupRef}
                title={"编辑"}
                width={800}
                height={280}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm 
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        formData={this.product} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}