import React from "react";
import OrderEvaluationGrid from "./order-evaluation-grid";
import AuthorizedComponent from "../authorized-component";
import { ContentBlock } from "../../components";
import { OrderEvaluationModel } from "../../models";
import OrderEvaluationToolbar from "./order-evaluation-toolbar";

export default class OrderEvaluationPage extends AuthorizedComponent {
    objectName = "OrderEvaluation";
    gridRef = React.createRef<OrderEvaluationGrid>();
    toolbarRef = React.createRef<OrderEvaluationToolbar>();

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    onModelSelected(model: OrderEvaluationModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onButtonClick(model: any, name: string) {
     
    }

    onFiltering(filterValue: any) {
        this.gridRef.current?.filter(filterValue);
    }

    render() {
        return (
            <ContentBlock caption={"客户评价"}>
                <OrderEvaluationToolbar 
                    permissions={this.permissions}
                    ref={this.toolbarRef} 
                    onButtonClick={this.onButtonClick}
                    onFiltering={this.onFiltering} />
                <OrderEvaluationGrid
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
            </ContentBlock>
        )
    }
}