import React from "react";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { ContentBlock, StdGrid, StdToolbar, createGridOptions, setColumnDataSource, stdReadOnlyGridOptions } from "../../components";
import { orderCheckTypeStore, customerTypeService } from "../../api";
import AuthorizedComponent from "../authorized-component";
import { dataSources, enums } from "../../base";
import { CustomerTypeRef, OrderCheckTypeModel } from "../../models";

interface OrderCheckTypeState {
    customerTypes: CustomerTypeRef[];
    columns: IColumnProps[];
}

export default class OrderCheckTypePage extends AuthorizedComponent<any, OrderCheckTypeState> {
    moduleName = "OrderCheckType";
    objectName = "OrderCheckType";
    gridRef = React.createRef<DataGrid>();
    toolbarRef = React.createRef<StdToolbar>();
    gridOptions = createGridOptions("OrderCheckTypes", this.gridRef, stdReadOnlyGridOptions);

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            customerTypes: [],
            columns: [
                {
                    dataField: 'customerTypeId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: 'name' },
                { dataField: 'suggest' },
                { dataField: 'orderNumber' },
                { 
                    dataField: 'dangerLevel',
                    lookup: { dataSource: dataSources.dangerLevels, displayExpr: 'text', valueExpr: 'value' }
                }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.onButtonClick(e.data, "edit");
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.loadCustomerTypes();
    }

    async loadCustomerTypes() {
        const data = await customerTypeService.getRefs();
        setColumnDataSource(this.state.columns, "customerTypeId", data);
        this.setState({ customerTypes: data });
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.toolbarRef.current?.setModel(e.selectedRowsData[0]);
        } else {
            this.toolbarRef.current?.setModel({});
        }
    }

    onButtonClick(model: OrderCheckTypeModel, name: string) {
        const grid = this.gridRef.current?.instance;
        
        if (grid) {
            switch (name) {
                case "add":
                    if (this.permissions[enums.stdActions.create]) {
                        grid.addRow();
                    }
                    break;

                case "edit":
                    if (this.permissions[enums.stdActions.update]) {
                        grid.editRow(grid.getRowIndexByKey(model.id));
                    }
                    break;
                
                case "delete":
                    if (this.permissions[enums.stdActions.delete]) {
                        grid.deleteRow(grid.getRowIndexByKey(model.id));
                    }
                    break;
            }
        }
    }

    render() {
        return (
            <ContentBlock caption={"随单安检项目设置"}>
                <StdToolbar 
                    permissions={this.permissions}
                    ref={this.toolbarRef} 
                    onButtonClick={this.onButtonClick} />
                <StdGrid
                    moduleName={this.moduleName}
                    gridRef={this.gridRef}
                    dataSource={orderCheckTypeStore}
                    options={this.gridOptions}
                    columns={this.state.columns}
                    popupWidth={400}
                    popupHeight={380}
                    formColCount={1} />
            </ContentBlock>
        );
    }
}