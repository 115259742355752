import { useMemo } from "react";
import { useServerData } from "./server-data";
import { IColumnProps } from "devextreme-react/data-grid";
import { ModuleData, ModuleConfig, ServerData, StringMap, stdDateFormat, stdTimeFormat } from "../base";

export function gridColumnFill(
    moduleName: string | ModuleConfig,
    columns: IColumnProps[],
    map?: StringMap,
    serverData?: ServerData) {
    if (serverData) {
        const moduleData = new ModuleData(serverData, moduleName);

        for (const c of columns) {
            if (!c.dataField) {
                continue;
            }

            if (!c.caption) {
                const name = map ? map[c.dataField] || c.dataField : c.dataField;
                c.caption = moduleData.findLabel(name);
            }

            if (!c.dataType) {
                const type = moduleData.findPropType(c.dataField);
                c.dataType = type === "bool" ? "boolean" : type;
            }

            if (c.dataType === "datetime") {
                c.format = stdTimeFormat;
            } else if (c.dataType === "date") {
                c.format = stdDateFormat;
            }

            c.validationRules = moduleData.getValidationRules(c.dataField).concat(c.validationRules || []);
        }
    }
}

export function useGridColumnFill() {
    const serverData = useServerData().serverData;

    return useMemo(() => {
        return function (
            moduleName: string | ModuleConfig,
            columns: IColumnProps[],
            map?: StringMap) {
            return gridColumnFill(moduleName, columns, map, serverData);
        }
    }, [serverData]);
}
