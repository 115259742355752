import "./stocktaking.scss";
import { ReactNode } from "react";
import { bottleStocktakingService, bottleTypeService, gasStocktakingService } from "../../api";
import { alertError, dataSources, enums, notifySuccess } from "../../base";
import { ContentBlock, FullPanel } from "../../components";
import { BottleStocktakingModel, BottleTypeModel, GasStocktakingModel, StocktakingCondition } from "../../models";
import AuthorizedComponent from "../authorized-component";
import StocktakingToolbar from "./stocktaking-toolbar";
import { Button, NumberBox } from "devextreme-react";
import { ValueChangedEvent } from "devextreme/ui/number_box";

interface StocktakingPageState {
    bottleTypes: BottleTypeModel[];
    condition?: StocktakingCondition;
    gas?: GasStocktakingModel;
    bottle?: BottleStocktakingModel;
    gasMessage?: string;
    bottleMessage?: string;
}

export default class StocktakingPage extends AuthorizedComponent<any, StocktakingPageState> {
    objectName = "GasStocktaking";
    moduleName = "GasStocktaking";

    constructor(props: any) {
        super(props);

        this.state = {
            bottleTypes: []
        };

        this.search = this.search.bind(this);
        this.saveBottle = this.saveBottle.bind(this);
        this.saveGas = this.saveGas.bind(this);
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.loadBottleTypes();
    }

    async loadBottleTypes() {
        const bottleTypes = await bottleTypeService.getRefs();
        this.setState({ bottleTypes: bottleTypes });
    }

    async search(condition: StocktakingCondition) {
        const gas = await gasStocktakingService.get(condition);

        if (!gas.succeeded) {
            alertError(gas.message!);
            return;
        }

        const bottle = await bottleStocktakingService.get(condition);

        if (!bottle.succeeded) {
            alertError(bottle.message!);
            return;
        }

        this.setState({
            condition: condition,
            gas: gas.data,
            gasMessage: gas.message,
            bottle: bottle.data,
            bottleMessage: bottle.message
        })
    }

    setGasTypeItem(event: ValueChangedEvent, bottleTypeId?: number, operationType?: string) {
        const value = event.value || 0;
        const items = this.state.gas!.items!;
        const item = items.find(i => i.bottleTypeId === bottleTypeId);
        const typeItems = this.state.gas!.typeItems!;
        const typeItem = typeItems.find(
            ti => ti.bottleTypeId === bottleTypeId && ti.operationType === operationType);
        var changed = value - (typeItem?.amount || 0);
        var isOut = [enums.bottleOperations.stockOut, enums.bottleOperations.sendBack].indexOf(operationType || '') !== -1;

        if (isOut) {
            changed = -changed;
        }

        if (typeItem) {
            typeItem.amount = value;
        } else {
            typeItems.push({
                bottleTypeId: bottleTypeId,
                operationType: operationType,
                direction: isOut ? enums.stockDirection.out : enums.stockDirection.in,
                amount: value
            });
        }

        if (item) {
            item.expectGas = Math.round(((item.expectGas || 0) + changed) * 10) / 10;
        } else {
            items.push({
                bottleTypeId: bottleTypeId,
                expectGas: value
            });
        }

        this.setState({ gas: this.state.gas });
    }

    setBottleTypeItem(event: any, bottleTypeId?: number, operationType?: string) {
        const value = Math.round(event.value || 0);
        const items = this.state.bottle!.items!;
        const item = items.find(i => i.bottleTypeId === bottleTypeId);
        const typeItems = this.state.bottle!.typeItems!;
        const typeItem = typeItems.find(
            ti => ti.bottleTypeId === bottleTypeId && ti.operationType === operationType);
        var changed = value - (typeItem?.amount || 0);
        var isOut = [enums.bottleOperations.stockOut, enums.bottleOperations.emptyBack, enums.bottleOperations.sendBack]
            .indexOf(operationType || '') !== -1;

        if (isOut) {
            changed = -changed;
        }

        if (typeItem) {
            typeItem.amount = value;
        } else {
            typeItems.push({
                bottleTypeId: bottleTypeId,
                operationType: operationType,
                direction: isOut ? enums.stockDirection.out : enums.stockDirection.in,
                amount: value
            });
        }

        if (item) {
            item.expectBottles = (item.expectBottles || 0) + changed;
        } else {
            items.push({
                bottleTypeId: bottleTypeId,
                expectBottles: value
            });
        }

        this.setState({ bottle: this.state.bottle });
    }

    setActualGas(event: ValueChangedEvent, bottleTypeId?: number) {
        const value = event.value || 0;
        const items = this.state.gas?.items || [];
        const item = items.find(i => i.bottleTypeId === bottleTypeId);

        if (item) {
            item.actualGas = value
        } else {
            items.push({
                bottleTypeId: bottleTypeId,
                actualGas: value
            });
        }

        this.setState({ gas: this.state.gas });
    }

    setActualBottle(event: any, bottleTypeId?: number) {
        const value = event.value || 0;
        const items = this.state.bottle?.items || [];
        const item = items.find(i => i.bottleTypeId === bottleTypeId);

        if (item) {
            item.actualBottles = value
        } else {
            items.push({
                bottleTypeId: bottleTypeId,
                actualBottles: value
            });
        }

        this.setState({ gas: this.state.gas });
    }

    async saveGas() {
        const result = await gasStocktakingService.save(this.state.gas!);

        if (result.succeeded) {
            this.refreshGas();
            notifySuccess("燃气库存保存成功");
        } else {
            alertError(result.message!);
        }
    }

    async refreshGas() {
        const gas = await gasStocktakingService.get(this.state.condition!);

        if (!gas.succeeded) {
            alertError(gas.message!);
            return;
        }
        
        this.setState({ gas: gas.data });
    }

    async saveBottle() {
        const result = await bottleStocktakingService.save(this.state.bottle!);

        if (result.succeeded) {
            this.refreshBottle();
            notifySuccess("气瓶库存保存成功");
        } else {
            alertError(result.message!);
        }
    }

    async refreshBottle() {
        const bottle = await bottleStocktakingService.get(this.state.condition!);

        if (!bottle.succeeded) {
            alertError(bottle.message!);
            return;
        }
        
        this.setState({ bottle: bottle.data });
    }

    render(): ReactNode {
        const { bottle, bottleTypes, gas, gasMessage, bottleMessage } = this.state;
        const stockOutType = this.state.condition?.outType;
        const typeText = dataSources.stockOutTypes.find(t => t.value === stockOutType)?.text;
        const canEdit = this.permissions[enums.stdActions.update];

        return (
            <ContentBlock caption={"库存盘点"}>
                <StocktakingToolbar onSearching={this.search} />
                <FullPanel>
                    <div className="stocktaking-container">
                        <h4>燃气库存盘点</h4>
                        <div className="stocktaking-note">{gasMessage}</div>
                        <table>
                            <thead>
                                <tr>
                                    <th>商品名称</th>
                                    <th>期初库存</th>
                                    <th>重瓶调拨入库（+）</th>
                                    <th>{typeText}（-）</th>
                                    <th>重瓶退回供应站（+）</th>
                                    <th>重瓶退回充装站（-）</th>
                                    <th>应有库存</th>
                                    <th>实际库存</th>
                                </tr>
                            </thead>
                            <tbody>
                                {gas && bottleTypes.map(t => {
                                    const item = gas.items?.find(i => i.bottleTypeId === t.id);
                                    const typeItems = gas.typeItems?.filter(i => i.bottleTypeId === t.id) || [];

                                    return (
                                        <tr key={t.id}>
                                            <th>{t.name}液化气</th>
                                            <th>{item?.initGas}</th>
                                            <th>
                                                <NumberBox value={typeItems.find(i => i.operationType === enums.bottleOperations.allot)?.amount}
                                                    onValueChanged={(event) => this.setGasTypeItem(event, t.id, enums.bottleOperations.allot)} />
                                            </th>
                                            <th>
                                                <NumberBox value={typeItems.find(i => i.operationType === enums.bottleOperations.stockOut)?.amount}
                                                    onValueChanged={(event) => this.setGasTypeItem(event, t.id, enums.bottleOperations.stockOut)} />
                                            </th>
                                            <th>
                                                <NumberBox value={typeItems.find(i => i.operationType === enums.bottleOperations.customerBack)?.amount}
                                                    onValueChanged={(event) => this.setGasTypeItem(event, t.id, enums.bottleOperations.customerBack)} />
                                            </th>
                                            <th>
                                                <NumberBox value={typeItems.find(i => i.operationType === enums.bottleOperations.sendBack)?.amount}
                                                    onValueChanged={(event) => this.setGasTypeItem(event, t.id, enums.bottleOperations.sendBack)} />
                                            </th>
                                            <th>{item?.expectGas}</th>
                                            <th>
                                                <NumberBox value={item?.actualGas} onValueChanged={(event) => this.setActualGas(event, t.id)} />
                                            </th>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {gas && canEdit && (
                            <div className="stocktaking-button">
                                <Button text="确认和保存燃气库存" type="default" onClick={this.saveGas} />
                            </div>
                        )}
                        <h4>气瓶库存盘点</h4>
                        <div className="stocktaking-note">{bottleMessage}</div>
                        <table>
                            <thead>
                                <tr>
                                    <th>商品名称</th>
                                    <th>期初库存</th>
                                    <th>重瓶调拨入库（+）</th>
                                    <th>空瓶调拨出库（-）</th>
                                    <th>{typeText}（-）</th>
                                    <th>空瓶入库（+）</th>
                                    <th>重瓶退回供应站（+）</th>
                                    <th>重瓶退回充装站（-）</th>
                                    <th>应有库存</th>
                                    <th>实际库存</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bottle && bottleTypes.map(t => {
                                    const item = bottle.items?.find(i => i.bottleTypeId === t.id);
                                    const typeItems = bottle.typeItems?.filter(i => i.bottleTypeId === t.id) || [];

                                    return (
                                        <tr key={t.id}>
                                            <th>{t.name}瓶</th>
                                            <th>{item?.initBottles}</th>
                                            <th>
                                                <NumberBox value={typeItems.find(i => i.operationType === enums.bottleOperations.allot)?.amount}
                                                    onValueChanged={(event) => this.setBottleTypeItem(event, t.id, enums.bottleOperations.allot)} />
                                            </th>
                                            <th>
                                                <NumberBox value={typeItems.find(i => i.operationType === enums.bottleOperations.emptyBack)?.amount}
                                                    onValueChanged={(event) => this.setBottleTypeItem(event, t.id, enums.bottleOperations.emptyBack)} />
                                            </th>
                                            <th>
                                                <NumberBox value={typeItems.find(i => i.operationType === enums.bottleOperations.stockOut)?.amount}
                                                    onValueChanged={(event) => this.setBottleTypeItem(event, t.id, enums.bottleOperations.stockOut)} />
                                            </th>
                                            <th>
                                                <NumberBox value={typeItems.find(i => i.operationType === enums.bottleOperations.emptyIn)?.amount}
                                                    onValueChanged={(event) => this.setBottleTypeItem(event, t.id, enums.bottleOperations.emptyIn)} />
                                            </th>
                                            <th>
                                                <NumberBox value={typeItems.find(i => i.operationType === enums.bottleOperations.customerBack)?.amount}
                                                    onValueChanged={(event) => this.setBottleTypeItem(event, t.id, enums.bottleOperations.customerBack)} />
                                            </th>
                                            <th>
                                                <NumberBox value={typeItems.find(i => i.operationType === enums.bottleOperations.sendBack)?.amount}
                                                    onValueChanged={(event) => this.setBottleTypeItem(event, t.id, enums.bottleOperations.sendBack)} />
                                            </th>
                                            <th>{item?.expectBottles}</th>
                                            <th>
                                                <NumberBox value={item?.actualBottles} onValueChanged={(event) => this.setActualBottle(event, t.id)} />
                                            </th>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {bottle && canEdit && (
                            <div className="stocktaking-button">
                                <Button text="确认和保存气瓶库存" type="default" onClick={this.saveBottle} />
                            </div>
                        )}
                    </div>
                </FullPanel>
            </ContentBlock>
        )
    }
}