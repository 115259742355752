import { http, DataBool } from "../base";
import { serverDataService } from "./server-data.service";

export interface MockData {
    username?: string;
    testMode?: boolean;
}

class AdminService {
    apiPath = "/api/admin";

    async mock(model: MockData): Promise<DataBool<string>> {
        const result = await http.putData(`${this.apiPath}/mock`, model);

        if (result.succeeded) {
            await serverDataService.resetBottleTypes();
        }

        return result;
    }

    async endMocking(): Promise<string> {
        const result = await http.putData(`${this.apiPath}/endmocking`, '');

        if (result.succeeded) {
            await serverDataService.resetBottleTypes();
        }

        return result;
    }
}

export const adminService = new AdminService();