import { http, MessageBool } from "../base";

class EmployeeProfileService {
    apiPath = '/api/profile';

    getFavorite() : Promise<string> {
        return http.getData(`${this.apiPath}/favorite`);
    }

    saveFavorite(favorite: string): Promise<MessageBool> {
        return http.putData(`${this.apiPath}/favorite`, favorite);
    }
}

export const employeeProfileService = new EmployeeProfileService();