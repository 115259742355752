import { CheckBox, Popup, RadioGroup } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";
import { ValueChangedEvent } from "devextreme/ui/check_box";
import React from "react";
import { alertError, globalPopupManager, globalPopupNames, http, ListItem } from "../../base";
import { PopupContentBox } from "../layouts";
import RefPopup from "../popup/ref-popup";

export interface ExportModel {
    /**
     * 模块名
     */
    moduleName: string;

    /**
     * 导出基础URL，如果有条件，应包含在URL中
     */
    exportUrl: string;

    /**
     * 表格的可用列
     */
    columns: IColumnProps[];

    /**
     * 选中的行主键
     */
    keys?: any[];

    /**
     * 过滤器
     */
    filter?: any;

    /**
     * 使用已删除数据
     */
    showDeleted?: boolean;
}

interface ExportPopupState {
    model?: ExportModel;
    exportSelected: boolean;
    columns: ListItem[];
    token?: string;
    checkAll: boolean;
}

const exportMethods: ListItem<boolean>[] = [
    { value: false, text: "导出全部" },
    { value: true, text: "导出选中数据" }
];

export default class ExportPopup extends React.Component<any, ExportPopupState> {
    popupRef = React.createRef<Popup>();

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            exportSelected: false,
            columns: [],
            checkAll: true
        };

        this.show = this.show.bind(this);
        this.onMethodChanged = this.onMethodChanged.bind(this);
        this.onCheckAllChanged = this.onCheckAllChanged.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        globalPopupManager.subscribe(globalPopupNames.exportPopup, this.show);
    }

    show(props: ExportModel) {
        this.popupRef.current?.instance.show();

        this.setState({ 
            model: props,
            columns: props.columns.map(c => ({ text: c.caption, value: c.dataField, selected: true }))
        });

        this.loadToken();
    }

    /**
     * 提前加载一个一次性TOKEN
     */
    async loadToken() {
        const token = await http.getData(`/api/token/onetimetoken?type=${this.state.model?.moduleName}`);
        this.setState({ token: token });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    onMethodChanged(value: boolean) {
        this.setState({ exportSelected: value });
    }

    onCheckAllChanged(e: ValueChangedEvent) {
        this.state.columns.forEach(c => c.selected = e.value);

        this.setState({
            checkAll: e.value
        });
    }

    onCheckChanged(column: ListItem, value?: boolean) {
        column.selected = value;
        this.setState({});
    }

    exportExcel() {
        const { model, token, columns, exportSelected } = this.state;

        if (!token) {
            alertError("导出凭证已失效，请稍后重试");
            this.hide();
            return;
        }

        if (exportSelected) {
            if (!model?.keys?.length) {
                alertError("请先选择要导出的行（可以跨页选择）");
                this.hide();
                return;
            }

            var form = document.getElementById("exportForm")!;
            var inputs = form.getElementsByTagName("input");
            inputs[0].value = token;
            inputs[1].value = columns.filter(c => c.selected).map(c => c.value).join(",");
            inputs[2].value = model.keys?.join(",") || '';
            inputs[3].value = model.showDeleted?.toString() || '';

            (form as HTMLFormElement).submit();
        } else {
            const selectedColumns = columns.filter(c => c.selected).map(c => c.value);
            const filter = model?.filter ? JSON.stringify(model.filter) : "";
            let url = model!.exportUrl;
            url += (url.indexOf("?") !== -1 ? "&" : "?") + 
                `token=${token}&columns=${selectedColumns}&filter=${filter}` +
                (model?.showDeleted ? "&showDeleted=true" : "");

            window.open(url);
        }

        this.hide();
    }

    cancel() {
        this.hide();
    }

    render() {
        const { columns, exportSelected, checkAll } = this.state;

        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"导出"}
                width={600}>
                <PopupContentBox 
                    saveButtonText="导出" 
                    scrollable={true} 
                    onSave={this.exportExcel} 
                    onCancel={this.cancel}>
                    <h4 className="sm-margin">导出方式</h4>
                    <RadioGroup 
                        dataSource={exportMethods} 
                        displayExpr="text" 
                        valueExpr="value"
                        value={exportSelected}
                        layout="horizontal"
                        onValueChange={this.onMethodChanged} />
                    <h4 className="sm-margin">请选择要导出的列：</h4>
                    <div className="checkbox-all">
                        <CheckBox text="全选" value={checkAll} onValueChanged={this.onCheckAllChanged} />
                    </div>
                    <div className="checkbox-list checkbox-list-3">
                        {columns.map(c => (
                            <div key={c.value}>
                                <CheckBox 
                                    text={c.text} 
                                    value={c.selected} 
                                    onValueChanged={(e) => this.onCheckChanged(c, e.value)} />
                            </div>
                        ))}
                    </div>
                    <form 
                        id="exportForm" 
                        style={{display: "none"}} 
                        method="post" 
                        target="_blank" 
                        action={this.state.model?.exportUrl}>
                        <input type="hidden" name="token"  />
                        <input type="hidden" name="columns" />
                        <input type="hidden" name="ids" />
                        <input type="hidden" name="showDeleted" />
                    </form>
                </PopupContentBox>
            </RefPopup>
        )
    }
}