import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import { siteCheckDeletedStore, siteCheckStore } from "../../api";
import { dataSources, StringMap } from "../../base";
import { createBoolColumn, createGridOptions, createImageColumn, remoteReadOnlyGridOptions, StdGrid } from "../../components";
import { SiteCheckModel } from "../../models";
import CustomStore from "devextreme/data/custom_store";

export interface SiteCheckGridProps {
    onModelSelected: (model: SiteCheckModel) => void;
    onRowChecked: (models: SiteCheckModel[]) => void;
    onButtonClick: (siteCheck: SiteCheckModel, name: string) => void;
}

interface SiteCheckGridState {
    store: CustomStore;
    columns: IColumnProps[];
}

export default class SiteCheckGrid extends 
    React.Component<SiteCheckGridProps, SiteCheckGridState> {
    moduleName = "SiteCheck";

    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions(
        "siteChecks", 
        this.gridRef, 
        remoteReadOnlyGridOptions,
        (items: any[]) => {
            // state清空会同时清空filter，需要重新绑定旧的
            items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
            return items;
        });

    nameMap: StringMap = { "organization.name": "organizationId" };

    constructor(props: Readonly<SiteCheckGridProps>) {
        super(props);

        this.state = {
            store: siteCheckStore,
            columns: [
                { dataField: "checkTime", dataType: "datetime" },
                { 
                    dataField: "type",
                    lookup: { dataSource: dataSources.siteCheckTypes, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: "organization.name" },
                createBoolColumn({ dataField: "hasDanger" }),
                createImageColumn({ dataField: "checkerSign" }),
                createImageColumn({ dataField: "receiverSign" })
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = (e) => this.props.onButtonClick(e.data, "detail");
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});    // 为避免混乱，多选不能编辑
        }

        this.props.onRowChecked(e.selectedRowsData);
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }
    
    filter(filterValue: any, showDeleted: boolean) {
        if (showDeleted && this.state.store !== siteCheckDeletedStore) {
            this.setState({ store: siteCheckDeletedStore }, () => this._filter(filterValue));
        } else if (!showDeleted && this.state.store !== siteCheckStore) {
            this.setState({ store: siteCheckStore }, () => this._filter(filterValue))
        } else {
            this._filter(filterValue);
        }
    }

    _filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }
    
    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns}
                columnNameMap={this.nameMap}>
            </StdGrid>
        );
    }
}