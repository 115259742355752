import { createStoreFromApi, http, BaseApiService, enums } from "../base";
import { ProductModel, ProductRef } from "../models";

class ProductService extends BaseApiService<ProductModel> {
    moduleName = "product";
    cacheSeconds = 60;

    getRefs(): Promise<ProductRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/refs`), this.cacheSeconds, false, "refs");
    }
    
    getBottles(): Promise<ProductRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/bottles`), this.cacheSeconds, false, "bottles");
    }

    async getAccessories(): Promise<ProductRef[]> {
        const products = await this.getRefs();
        return products.filter(p => p.type === enums.productTypes.accessory);
    }
}

export const productService = new ProductService();
export const productStore = createStoreFromApi(productService);