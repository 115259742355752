import { createStoreFromUrl, http } from "../../base";
import { TurnoverReportCondition } from "../../models";

class BottleFlowReportService {
    apiPath = "/api/report/bottleflow";

    getTurnover(condition: TurnoverReportCondition) {
        return createStoreFromUrl(
            `${this.apiPath}/turnover?` + http.serialize([condition]), "code");
    }
}

export const bottleFlowReportService = new BottleFlowReportService();