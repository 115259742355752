import React from "react";
import { dateAdd, today } from "../../base";
import { BottleFillingModel, SysLogModel } from "../../models";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box"
import { Button, DateBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";

export interface SysLogToolbarProps {
    onButtonClick: (model: BottleFillingModel, name: string) => void;
    onFiltering: (filterValue: any) => void;
}

interface SysLogToolbarState {
    model: SysLogModel;
    startTime?: Date | string;
    endTime?: Date | string;
}

export default class SysLogToolbar extends React.Component<SysLogToolbarProps, SysLogToolbarState> {
    constructor(props: Readonly<SysLogToolbarProps>) {
        super(props);

        const day = today();

        this.state = {
            model: {},
            startTime: dateAdd(day, -7),
            endTime: day
        };

        this.onStartTimeChanged = this.onStartTimeChanged.bind(this);
        this.onEndTimeChanged = this.onEndTimeChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount(): void {
        setTimeout(this.onFiltering, 100);
    }

    setModel(model: SysLogModel) {
        this.setState({ model: model });
    }

    onStartTimeChanged(e: DateChangedEvent) {
        this.setState({ startTime: e.value });
    }

    onEndTimeChanged(e: DateChangedEvent) {
        this.setState({ endTime: e.value });
    }

    onFiltering() {
        const filterValue: any[] = [];

        if (this.state.startTime) {
            filterValue.push(["logTime", ">=", this.state.startTime]);
        }

        if (this.state.endTime) {
            filterValue.push(["logTime", "<", dateAdd(new Date(this.state.endTime!), 1)])
        }

        this.props.onFiltering(filterValue);
    }

    render() {
        const { startTime, endTime } = this.state;

        return (
            <div className={"page-toolbar page-large-toolbar"}>
                <Toolbar>                 
                    <Item location={"before"} locateInMenu={"auto"}>           
                        <DateBox
                            value={startTime}
                            placeholder="开始时间"
                            type="datetime"
                            showClearButton={true}
                            onValueChanged={this.onStartTimeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endTime}
                            placeholder="结束时间"
                            type="datetime"
                            showClearButton={true}
                            onValueChanged={this.onEndTimeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                </Toolbar>
            </div>
        )
    }
}