import React from "react";
import { Popup } from "devextreme-react";
import { organizationService } from "../../api";
import { RefPopup } from "../popup";
import { PopupContentBox } from "../layouts";
import { alertWarning } from "../../base";
import { OrganizationRef } from "../../models";
import { OrgTreeDropDown } from "../tree";

export interface AssignOrganizationProps {
    caption?: string;
    save: (org: OrganizationRef) => void;
}

interface AssignOrganizationState {
    orgs: OrganizationRef[];
    organizationId?: number;
}

export default class AssignOrganizationPopup extends React.Component<AssignOrganizationProps, AssignOrganizationState> {
    popupRef = React.createRef<Popup>();

    constructor(props: Readonly<AssignOrganizationProps>) {
        super(props);

        this.state = { 
            orgs: [], 
        };

        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.loadOrgs();
    }

    async loadOrgs() {
        const data = await organizationService.getRefs();
        this.setState({ orgs: data });
    }

    show(organizationId?: number) {
        this.popupRef.current?.instance.show();

        this.setState({ organizationId: organizationId });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    onOrganizationChanged(organizationId?: number) {
        this.setState({ organizationId: organizationId });
    }

    save() {
        if (!this.state.organizationId) {
            alertWarning("请选择要分配的供应站");
            return;
        }
        
        const org = this.state.orgs.find(o => o.id === this.state.organizationId);
        this.props.save(org!);
    }

    cancel() {
        this.hide();
    }

    render() {
        const { organizationId, orgs } = this.state;
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={this.props.caption || "分配供应站"}
                width={300}
                height={150}>
                <PopupContentBox onSave={this.save} onCancel={this.cancel}>
                    <OrgTreeDropDown 
                        organizations={orgs} 
                        organizationId={organizationId}
                        placeholder="请选择..."
                        onValueChanged={this.onOrganizationChanged} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}