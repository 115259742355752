import { createStoreFromUrl, http, MessageBool, BaseApiService } from "../base";
import { TroubleshootingModel } from "../models";

class TroubleshootingService extends BaseApiService<TroubleshootingModel> {
    moduleName = "troubleshooting";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }

    getDeletedDxData() {
        return createStoreFromUrl(`${this.apiPath}/deleteddx`, 'id');
    }

    assign(id: number, employeeId: string) {
        return http.putData<MessageBool>(
            `${this.apiPath}/assign/${id}?employeeId=${employeeId}`, '');
    }

    restore(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/restore/${id}`, '');
    }
}

export const troubleshootingService = new TroubleshootingService();
export const troubleshootingStore = troubleshootingService.getDxData();
export const troubleshootingDeletedStore = troubleshootingService.getDeletedDxData();