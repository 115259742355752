import React from "react";
import { DataMap, dateAdd, enums, ListItem } from "../../base";
import { OrganizationRef, TrainingModel } from "../../models";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { Button, DateBox, SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { organizationService } from "../../api";
import { OrgTreeDropDown } from "../../components";

export interface TrainingToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (model: TrainingModel, name: string) => void;
    onContextButtonClick: (ids: number[], name: string) => void;
    onFiltering: (filterValue: any, showDeleted: boolean) => void;
}

interface TrainingToolbarState {
    model: TrainingModel;
    ids: number[];
    orgs: OrganizationRef[];
    organizationId?: number;
    startDate?: Date | string;
    endDate?: Date | string;
    showDeleted: boolean;
}

export default class TrainingToolbar 
    extends React.Component<TrainingToolbarProps, TrainingToolbarState> {
    constructor(props: Readonly<TrainingToolbarProps>) {
        super(props);

        this.state = {
            model: {},
            ids: [],
            orgs: [],
            showDeleted: false
        }

        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount(): void {
        this.loadOrgs();
    }

    async loadOrgs() {
        const orgs = await organizationService.getRefs();
        this.setState({ orgs: orgs });
    }

    setModel(model: TrainingModel) {
        this.setState({ model: model });
    }

    setIds(ids: number[]) {
        this.setState({ ids: ids });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }
    
    onStatusChanged(e: ValueChangedEvent) {
        this.setState({ showDeleted: e.value });
    }

    onOrganizationChanged(organizationId?: number) {
        this.setState({ organizationId: organizationId });
    }

    onFiltering() {
        const { startDate, endDate, showDeleted, organizationId } = this.state;
        const filters: any[] = [];

        if (startDate) {
            filters.push(["date", ">=", startDate]);
        }

        if (endDate) {
            filters.push(["date", "<", dateAdd(new Date(endDate), 1)]);
        }

        if (organizationId) {
            filters.push(["organizationId", "=", organizationId]);
        }

        this.props.onFiltering(filters.length > 0 ? filters : null, showDeleted);
    }
    
    render() {
        const { model, startDate, endDate, showDeleted, orgs, organizationId, ids } = this.state;
        const permissions = this.props.permissions;

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={showDeleted}
                            dataSource={statusSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={120}
                            onValueChanged={this.onStatusChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={startDate}
                            placeholder="开始时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>              
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.create]}>
                        <Button
                            text="新增"
                            onClick={() => this.props.onButtonClick({}, "add")} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"}>
                        <Button
                            text={permissions[enums.stdActions.update] ? "编辑" : "查看"}
                            disabled={!model?.id}
                            onClick={() => this.props.onButtonClick(model, "edit")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.delete]}>
                        <Button
                            text="作废"
                            disabled={!model?.id || model.deleted}
                            onClick={() => this.props.onButtonClick(model, "delete")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.delete]}>
                        <Button
                            text="还原"
                            disabled={!model?.id || !model.deleted}
                            onClick={() => this.props.onButtonClick(model, "restore")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            text="导出表格"
                            disabled={!ids.length}
                            onClick={() => this.props.onContextButtonClick(ids, "export")} />
                    </Item>
                </Toolbar>
            </div>
        )
    }
}

const statusSource: ListItem<boolean>[] = [
    { value: false, text: "查看正常数据" },
    { value: true, text: "查看已作废" }
]