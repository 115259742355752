import { Popup, TextBox } from "devextreme-react";
import Form, { IItemProps } from "devextreme-react/form";
import React from "react";
import { organizationService } from "../../api";
import { alertError, dataSources, modelClone, notifySuccess } from "../../base";
import { OrgTreeDropDown, PopupContentBox, RefPopup, StdForm } from "../../components";
import { OrganizationModel } from "../../models";

export interface OrganizationFormProps {
    organizations: OrganizationModel[];
    onSaved: () => void;
}

interface OrganizationFormState {
    organization: OrganizationModel;
    items: IItemProps[];
}

export default class OrganizationForm extends React.Component<OrganizationFormProps, OrganizationFormState> {
    moduleName = "Organization";
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();

    constructor(props: Readonly<OrganizationFormProps>) {
        super(props);

        this.state = {
            organization: {},
            items: [
                { dataField: 'name' },
                { dataField: 'sortNumber' },
                {
                    dataField: 'type',
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: Object.values(dataSources.organizationTypes), displayExpr: 'text', valueExpr: 'value' }
                },
                {
                    dataField: "turnover",
                    editorType: "dxCheckBox"
                },
                {
                    dataField: 'parentId',
                    render: () => {
                        return this.state.organization?.id ?
                        (
                            <TextBox readOnly={true} value={this.state.organization.parent?.name} />
                        ) :
                        (
                            <OrgTreeDropDown
                                organizationId={this.state.organization.parentId}
                                organizations={this.props.organizations}
                                onValueChanged={(organizationId) => {
                                    this.state.organization.parentId = organizationId;
                                    this.setState({})
                                }} />
                            )
                        }
                },
                { dataField: 'contact' },
                { dataField: 'phoneNumber' },
                { dataField: 'address' },
                { dataField: 'note' }
            ]
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    show(organization: OrganizationModel) {
        this.popupRef.current?.instance.show();

        this.setState({ organization: modelClone(organization) });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const org = this.state.organization;
            const res = org.id ?
                await organizationService.update(org) :
                await organizationService.create(org);

            if (res.succeeded) {
                this.hide();
                notifySuccess("已成功保存");
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title="编辑"
                width={800}
                height={360}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        formData={this.state.organization} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}