import './area-delivering.scss';
import { bottleTypeService } from "../../../api";
import { areaReportService } from "../../../api/reports";
import { baseApiUrl, download, http } from "../../../base";
import { ContentBlock } from "../../../components";
import { useGridHeight } from "../../../hooks";
import { AreaDeliveringReport, AreaDeliveringReportCondition, BottleTypeModel } from "../../../models";
import AuthorizedComponent from "../../authorized-component";
import AreaDeliveringToolbar from "./area-delivering-toolbar";
import React from 'react';
import { LoadPanel, ScrollView } from 'devextreme-react';

interface AreaDeliveringReportPageState {
    bottleTypes: BottleTypeModel[];
    data: AreaDeliveringReport[];
    loading: boolean;
}

export default class AreaDeliveringReportPage
    extends AuthorizedComponent<any, AreaDeliveringReportPageState> {
    objectName = "Order";

    constructor(props: any) {
        super(props);

        this.state = {
            bottleTypes: [],
            data: [],
            loading: false
        };

        this.search = this.search.bind(this);
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.loadBottleTypes();
    }

    async loadBottleTypes() {
        const bottleTypes = await bottleTypeService.getRefs();
        this.setState({ bottleTypes: bottleTypes });
    }

    async search(condition: AreaDeliveringReportCondition) {
        this.setState({ loading: true });

        setTimeout(async () => {
            const data = await areaReportService.getFillingDelivering(condition);
            this.setState({ data: data, loading: false });

        }, 2000)

    }

    async export(condition: AreaDeliveringReportCondition) {
        await download(
            `${baseApiUrl}${areaReportService.apiPath}/delivering/export?${http.serialize([condition])}`,
            "区域配送统计报表.xlsx");
    }

    render() {
        return (
            <ContentBlock caption="区域配送统计报表">
                <AreaDeliveringToolbar onFiltering={this.search} onExporting={this.export} />
                <AreaDeliveringGrid bottleTypes={this.state.bottleTypes} data={this.state.data} loading={this.state.loading} />
            </ContentBlock>
        )
    }
}

interface GridProps extends AreaDeliveringReportPageState {
}

function AreaDeliveringGrid(props: GridProps) {
    const height = useGridHeight();
    const { bottleTypes, data, loading } = props;

    return (
        <div className="area-delivering" style={{ height: height }}>
            <ScrollView width={"100%"} showScrollbar="always">
                <LoadPanel visible={loading} showPane={true} width={"100%"} height={"100%"}></LoadPanel>
                <table>
                    <thead>
                        <tr>
                            <th>区域</th>
                            <th>用户类型</th>
                            <th>气瓶类型</th>
                            <th>数量</th>
                            <th>金额</th>
                            <th>合计数量</th>
                            <th>合计金额</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(item => {
                            let itemFlag = 0;

                            return (
                                <React.Fragment>
                                    {item.data.map(typeItem => {
                                        let typeItemFlag = 0;

                                        return (
                                            <React.Fragment>

                                                {bottleTypes.map(t => (
                                                    <tr>
                                                        {itemFlag++ === 0 && (
                                                            <td rowSpan={item.data.length * bottleTypes.length}>{item.areaName}</td>
                                                        )}

                                                        {typeItemFlag++ === 0 && (
                                                            <td rowSpan={bottleTypes.length}>{typeItem.customerType}</td>
                                                        )}

                                                        <td>{t.name}</td>
                                                        <td>{typeItem.data[t.id!]?.count || 0}</td>
                                                        <td>{typeItem.data[t.id!]?.price || 0}</td>

                                                        {typeItemFlag === 1 && (
                                                            <React.Fragment>
                                                                <td rowSpan={bottleTypes.length}>{sum(typeItem.data, "count")}</td>
                                                                <td rowSpan={bottleTypes.length}>{sum(typeItem.data, "price")}</td>
                                                            </React.Fragment>
                                                        )}
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </table>
            </ScrollView>
        </div>
    )
}

function sum(data: { [key: number]: AreaDeliveringReport.BottleTypeData }, type: string) {
    let sum = 0;

    for (const key in data)
        sum += type === "price" ? data[key].price : data[key].count;

    return sum;
}