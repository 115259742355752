import React from "react";
import { Button, DateBox, SelectBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { OrgTreeDropDown } from "../../components";
import { enums, DataMap, ListItem, today, dateAdd } from "../../base";
import { AreaRef, EmployeeRef, OrderModel, OrganizationRef } from "../../models";
import { emptyDataSources } from "../../api/data-source-helper";
import { areaService, employeeService, organizationService } from "../../api";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { ValueChangedEvent as TextChangedEvent } from "devextreme/ui/text_box";

interface OrderToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (order: OrderModel, name: string) => void;
    onFiltering: (filterValue: any) => void;
}

interface OrderToolbarState {
    order: OrderModel;
    orgs: OrganizationRef[];
    allDelivers: EmployeeRef[];
    delivers: EmployeeRef[];
    counties: AreaRef[];
    allStreets: AreaRef[];
    streets: AreaRef[];
    organizationId?: number;
    deliverId?: string;
    countyId?: number;
    streetId?: number;
    timeProperty: "orderTime"|"completedAt"|"paidAt";
    startDate?: Date|string;
    endDate?: Date|string;
    orderStatus?: number;
    customerKey?: string;
}

export default class OrderToolbar extends React.Component<OrderToolbarProps, OrderToolbarState> {
    constructor(props: Readonly<OrderToolbarProps>) {
        super(props);

        const startDate = today();

        this.state = {
            order: {},
            orgs: [],
            allDelivers: [],
            delivers: [],
            counties: [],
            allStreets: [],
            streets: [],
            timeProperty: "orderTime",
            startDate: startDate,
            endDate: startDate,
        };

        this.onTimePropertyChanged = this.onTimePropertyChanged.bind(this);
        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onDeliverChanged = this.onDeliverChanged.bind(this);
        this.onCountyChanged = this.onCountyChanged.bind(this);
        this.onStreetChanged = this.onStreetChanged.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);
        this.onCustomerChanged = this.onCustomerChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount() {
        this.loadDataSources();
        setTimeout(this.onFiltering, 100);
    }

    async loadDataSources() {
        const dataSources = emptyDataSources();

        await Promise.all([
            (async () => dataSources.orgs = await organizationService.getRefs())(),
            (async () => dataSources.employees = await employeeService.getDelivers())(),
            (async () => dataSources.counties = await areaService.getCounties())(),
            (async () => dataSources.streets = await areaService.getStreets())(),
        ]);

        this.setState({
            orgs: dataSources.orgs,
            allDelivers: dataSources.employees,
            delivers: dataSources.employees,
            counties: dataSources.counties,
            allStreets: dataSources.streets,
            streets: dataSources.streets
        });
    }

    setOrder(order: any) {
        this.setState({ order: order });
    }

    onTimePropertyChanged(e: ValueChangedEvent) {
        this.setState({ timeProperty: e.value });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onOrganizationChanged(organizationId?: number) {
        const delivers = organizationId ?
            this.state.allDelivers.filter(d => d.organizationId === organizationId) :
            this.state.allDelivers;

        const deliverId = delivers.find(d => d.id === this.state.deliverId) ? this.state.deliverId : undefined;

        this.setState({
            organizationId: organizationId,
            delivers: delivers,
            deliverId: deliverId
        });
    }

    onFiltering() {
        this.props.onFiltering(this.getFilterValue());
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { timeProperty, startDate, endDate, organizationId, deliverId, countyId, streetId, orderStatus, customerKey } = this.state;

        if (startDate) {
            filters.push([timeProperty, ">=", startDate]);
        }
        if (endDate) {
            filters.push([timeProperty, "<", dateAdd(new Date(endDate), 1)])
        }
        if (organizationId) {
            filters.push(["organizationId", "=", organizationId]);
        }
        if (deliverId) {
            filters.push(["deliverId", "=", deliverId]);
        }
        if (countyId) {
            filters.push(["customer.areaId", "=", countyId]);
        }
        if (streetId) {
            filters.push(["customer.streetId", "=", streetId]);
        }
        if (customerKey) {
            filters.push([
                [ "customer.gasCode", "contains", customerKey],
                "or",
                [ "customer.name", "contains", customerKey]
            ]);
        }
        switch (orderStatus) {
            case 0:
                filters.push([
                    ["status", "=", enums.orderStatus.created],
                    "or",
                    ["status", "=", enums.orderStatus.assigned],
                    "or",
                    ["status", "=", enums.orderStatus.delivering]
                ]);
                break;
            case 1:
                filters.push([
                    ["status", "=", enums.orderStatus.delivery],
                    "or",
                    ["status", "=", enums.orderStatus.paid],
                ]);
                break;
            case 2:
                filters.push(["status", "=", enums.orderStatus.discard]);
                break;
        }

        return filters.length > 0 ? filters : null;
    }

    onDeliverChanged(e: ValueChangedEvent) {
        this.setState({ deliverId: e.value });
    }

    onCountyChanged(e: ValueChangedEvent) {
        const streets = e.value ?
            this.state.allStreets.filter(s => s.parentId === e.value) :
            this.state.allStreets;

        const streetId = streets.find(s => s.id === this.state.streetId) ? this.state.streetId : undefined;

        this.setState({
            countyId: e.value,
            streets: streets,
            streetId: streetId
        });
    }

    onStreetChanged(e: ValueChangedEvent) {
        this.setState({ streetId: e.value });
    }

    onStatusChanged(e: ValueChangedEvent) {
        this.setState({ orderStatus: e.value });
    }

    onCustomerChanged(e: TextChangedEvent) {
        this.setState({ customerKey: e.value });
    }

    getButtonText() {
        const permissions = this.props.permissions;

        switch (this.state.order?.status) {
            case enums.orderStatus.created:
                return permissions["Assign"] ? "分配" : undefined;
            case enums.orderStatus.assigned:
            case enums.orderStatus.delivering:
                return permissions["Assign"] ? "派工" : undefined;
            case enums.orderStatus.delivery:
                return permissions["SetPaid"] ? "回款" : undefined;
            default:
                return undefined;
        }
    }

    render() {
        const { order, orgs, delivers, counties, streets, organizationId, deliverId, 
            countyId, streetId, timeProperty, startDate, endDate, orderStatus, customerKey } = this.state;

        const permissions = this.props.permissions;

        const buttonText = this.getButtonText();

        const canEdit = [enums.orderStatus.created, enums.orderStatus.assigned, enums.orderStatus.delivering]
            .indexOf(order?.status || "") !== -1;
        const canDiscard = [enums.orderStatus.discard].indexOf(order?.status || "") === -1;
        const canRestore = [enums.orderStatus.discard].indexOf(order?.status || "") !== -1;

        return (
            <div className={"page-toolbar page-large-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={timeProperty}
                            dataSource={timePropertySource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={100}
                            onValueChanged={this.onTimePropertyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={startDate}
                            placeholder="开始时间"
                            width={120}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束时间"
                            width={120}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions[enums.stdActions.create]}>
                        <Button
                            text={"呼叫中心"}
                            type="default"
                            onClick={() => this.props.onButtonClick({}, "callcenter")} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"}>
                        <Button
                            text="随单安检详情"
                            disabled={!order?.id}
                            onClick={() => this.props.onButtonClick(order, "checkdetail")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.update]}>
                        <Button
                            text="编辑"
                            disabled={!order?.id || !canEdit}
                            onClick={() => this.props.onButtonClick(order, "edit")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={!!buttonText}>
                        <Button
                            text={buttonText}
                            disabled={!order?.id}
                            onClick={() => this.props.onButtonClick(order, "next")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.delete]}>
                        <Button
                            text="作废"
                            disabled={!order?.id || !canDiscard}
                            onClick={() => this.props.onButtonClick(order, "discard")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.delete]}>
                        <Button
                            text="还原"
                            disabled={!order?.id || !canRestore}
                            onClick={() => this.props.onButtonClick(order, "restore")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            text="导出销售明细"
                            onClick={() => this.props.onButtonClick(order, "saledetail")} />
                    </Item>
                </Toolbar>
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={deliverId}
                            dataSource={delivers}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={160}
                            placeholder="送气工"
                            onValueChanged={this.onDeliverChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={countyId}
                            dataSource={counties}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder="行政区域"
                            onValueChanged={this.onCountyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={streetId}
                            dataSource={streets}
                            displayExpr="name"
                            valueExpr={"id"}
                            searchEnabled={true}
                            showClearButton={true}
                            width={100}
                            placeholder="街道"
                            onValueChanged={this.onStreetChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={orderStatus}
                            dataSource={statusSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            showClearButton={true}
                            width={100}
                            placeholder={"状态过滤"}
                            onValueChanged={this.onStatusChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <TextBox
                            value={customerKey}
                            showClearButton={true}
                            placeholder="客户名称或卡号"
                            onValueChanged={this.onCustomerChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>

                </Toolbar>
            </div>
        );
    }
}

const timePropertySource: ListItem<string>[] = [
    { value: "orderTime", text: "下单时间" },
    { value: "completedAt", text: "完成时间" },
    { value: "paidAt", text: "回款时间" }
]

const statusSource: ListItem<number>[] = [
    { 
        value: 0, 
        text: "未送达" 
    },
    { 
        value: 1,
        text: "已送达"
    },
    {
        value: 2,
        text: "已作废"
    }
]