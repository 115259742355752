import React from "react";
import { Popup, ScrollView } from "devextreme-react";
import DataGrid, { IColumnProps, IDataGridOptions } from "devextreme-react/data-grid";
import { createBoolColumn, StdGrid, RefPopup, createImageColumn, ImagesComponent } from "../../components";
import { SecurityCheckModel } from "../../models";
import { securityCheckService } from "../../api";

interface SecurityCheckDetailState {
    securityCheck: SecurityCheckModel;
}

export default class SecurityCheckDetail extends React.Component<any, SecurityCheckDetailState> {
    moduleName = "SecurityCheckItem";

    popupRef = React.createRef<Popup>();
    gridRef = React.createRef<DataGrid>();
    gridOptions: IDataGridOptions = {
        columnAutoWidth: true,
        paging: { enabled: false }
    };
    
    columns: IColumnProps[] = [
        { dataField: "itemName" },
        createBoolColumn({ dataField: "hasDanger" }),
        createImageColumn({ dataField: "photo" })
    ];

    constructor(props: any) {
        super(props);

        this.state = { 
            securityCheck: {}
        };
    }

    async show(model: SecurityCheckModel) {
        this.popupRef.current?.instance.show();

        const securityCheck = await securityCheckService.get(model.id!);
        this.setState({ securityCheck: securityCheck });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    render() {
        const securityCheck = this.state.securityCheck;
        const fileNames: string[] = (securityCheck.envPhoto ?? "").split(';').filter((s: string) => !!s);

        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"安检检查详情"}
                width={1000}
                height={600}>
                <ScrollView height={"100%"} scrollByContent={true} showScrollbar={"always"}>
                    <h4 style={{ marginTop: 0 }}>用气环境拍照</h4>
                    <div className="large-images">
                        <ImagesComponent fileNames={fileNames} />
                    </div>
                    <StdGrid
                        moduleName={this.moduleName}
                        gridRef={this.gridRef}
                        dataSource={securityCheck.items}
                        columns={this.columns}
                        options={this.gridOptions}
                        autoHeight={true}
                    />
                </ScrollView>
            </RefPopup>
        );
    }
}