import { baseApiUrl, now } from './base-values';
import { DataBool, SignalBool, User } from './models';
import { TokenEventHandler, TokenServiceDefine } from './token.service.define';
import { tokenStorage } from './token-storage';
import { enums } from './enums';

function fetchData(method: string, token?: string, body?: string, query?: string) {
    const headers: any = { 'content-type': 'application/json' };
    if (token) headers.Authorization = `bearer ${token}`;

    return fetch(`${baseApiUrl}/api/token` + (query || ''), {
        body: body,
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: headers,
        method: method,
        mode: 'cors',
        redirect: 'follow',
        referrer: 'no-referrer'
    });
}

export const tokenTimer = {
    timer: 0,
    tokenTimeoutStorageKey: 'token.timeout',
    checkInterval: 10 * 60000, // 必须小于服务器的超时时间

    /**
       * 在初始化时调用，以确保刷新浏览器时能保持timer
       */
    initTimer(callback: TimerHandler) {
        const timeout = localStorage.getItem(this.tokenTimeoutStorageKey);
        if (!timeout) { return; }

        if (this.timer) { clearTimeout(this.timer); }

        const nowTick = now().getTime();
        let tick = parseInt(timeout) - nowTick;
        if (tick < 0) { tick = 0; }

        this.timer = setTimeout(callback, tick);
    },

    /**
       * 正常流程调用timer时，在指定的interval后触发
       */
    startTimer(callback: TimerHandler) {
        if (this.timer) { clearTimeout(this.timer); }

        this.timer = setTimeout(callback, this.checkInterval);

        // 记录timer的触发时间点，以便在浏览器刷新后重置该timer
        const nowTick = now().getTime();
        localStorage.setItem(this.tokenTimeoutStorageKey, nowTick + this.checkInterval + "");
    },

    clearStorage() {
        localStorage.removeItem(this.tokenTimeoutStorageKey);
    }
}

let userCache: User | undefined = undefined;
const handlers: TokenEventHandler[] = [];


export const tokenService: TokenServiceDefine = {
    /**
     * 自动使用长时TOKEN，该方法属于初始化流程一部分，不需要startTimer
     */
    async autoSignIn(): Promise<SignalBool<string>> {
        const token = tokenStorage.longTimeToken;
        if (!token) {
            return { succeeded: false };
        } else {
            const res = await fetchData('POST', undefined, JSON.stringify(token));
            const result: SignalBool<string> = await res.json();
            
            // 登录失败则清空
            if (!result.succeeded) {
                tokenStorage.longTimeToken = undefined;
            } else {
                tokenStorage.token = result.data!;
                
                tokenTimer.startTimer(() => this.refreshToken());

                const user = await this.getUser(true);
                setTimeout(() => handlers.forEach(h => h({ signedIn: true, user: user })), 0);
            }

            return result;
        }
    },

    async signIn(username: string, password: string, remember: boolean): Promise<SignalBool<string>> {
        const data = { username: username, password: password, remember: remember };
        const res = await fetchData('POST', undefined, JSON.stringify(data));
        const result: SignalBool<string> = await res.json();

        if (result.succeeded) {
            tokenStorage.token = result.data!;

            // 保存长时TOKEN
            if (result.signals && result.signals[enums.signalTypes.longTimeToken]) {
                tokenStorage.longTimeToken = {
                    username: username,
                    longTimeToken: result.signals[enums.signalTypes.longTimeToken],
                    remember: true
                }
            }

            tokenTimer.startTimer(() => this.refreshToken());

            const user = await this.getUser(true);
            setTimeout(() => handlers.forEach(h => h({ signedIn: true, user: user })), 0);
        }

        return result;
    },

    async refreshToken(): Promise<SignalBool<string>> {
        const token = tokenStorage.token;
        if (!token) {
            this.clearStorage(); // 确保清除资源
            return { succeeded: false };
        }

        const res = await fetchData('PUT', token, '');
        if (res.status === 401) {
            this.clearStorage(); // 同上
            return { succeeded: false };
        }

        // 现在refresh会返回失败，当绝对时间超时（默认一天），或登录权限被禁止时
        const result: DataBool<string> = await res.json();

        if (result.succeeded) {
            tokenStorage.token = result.data!;
            tokenTimer.startTimer(() => this.refreshToken());
        } else {
            this.clearStorage();
            return { succeeded: false };
        }

        return result;
    },

    async signOut(): Promise<void> {
        const longTimeToken = tokenStorage.longTimeToken;

        if (longTimeToken) {
            try {
                await fetchData('DELETE', tokenStorage.token, undefined, '/longtimetoken?token=' + longTimeToken);
            }
            catch (e) {
                console.log(e);
            } finally {
                tokenStorage.longTimeToken = undefined;
            }
        }  
        
        this.clearStorage();
    },

    clearStorage(): void {
        userCache = undefined;
        tokenStorage.clear();
        tokenTimer.clearStorage();
        setTimeout(() => handlers.forEach(h => h({ signedIn: false })));
    },

    async getUser(refresh?: boolean): Promise<User | undefined> {
        const token = tokenStorage.token;
        if (!token) { return undefined; }

        if (!refresh && userCache) { return userCache; }

        const res = await fetchData('GET', token);
        if (!res.ok) { return undefined; }

        userCache = await res.json();
        return userCache;
    },

    addHandler(handler: TokenEventHandler) {
        if (handlers.indexOf(handler) === -1) {
            handlers.push(handler);
        }
    },

    removeHandler(handler: TokenEventHandler) {
        const index = handlers.indexOf(handler);
        if (index !== -1) {
            handlers.splice(index, 1);
        }
    }
};