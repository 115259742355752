import { Form, Popup } from "devextreme-react";
import { Item, Label } from "devextreme-react/form";
import { RequiredRule } from "devextreme-react/tree-list";
import React from "react";
import { PopupContentBox } from "../layouts";
import RefPopup from "../popup/ref-popup";

interface DurationData {
    startDate?: Date | string;
    endDate?: Date | string;
}

interface DurationPopupState {
    data: DurationData;
    onSearch?: (startDate: Date|string, endDate: Date|string) => void;
}

export default class DuartionPopup extends React.Component<any, DurationPopupState> {
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            data: {}
        };

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    show(data: DurationData, onSearch: (startDate: Date|string, endDate: Date|string) => void) {
        this.popupRef.current?.instance.show();

        this.setState({ 
            data: data,
            onSearch: onSearch
        });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    save() {
        const isValid = this.formRef.current?.instance.validate();
        if (!isValid?.isValid) {
            return;
        }

        this.hide();
        const data = this.state.data;
        this.state.onSearch && this.state.onSearch(data.startDate!, data.endDate!);
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                width={350}
                height={260}
                title={"选择时间段"}>
                <PopupContentBox
                    saveButtonText={this.props.searchButtonText ?? "查询"}
                    onSave={this.save}
                    onCancel={this.cancel}>
                    <Form
                        ref={this.formRef}
                        formData={this.state.data}
                        onEditorEnterKey={this.save}>
                        <Item dataField={"startDate"} editorType="dxDateBox">
                            <RequiredRule message={"开始时间不能为空"} />
                            <Label text={"开始日期"} />
                        </Item>
                        <Item dataField={"endDate"} editorType="dxDateBox">
                            <RequiredRule message={"结束时间不能为空"} />
                            <Label text={"结束日期"} />
                        </Item>
                    </Form>
                </PopupContentBox>
            </RefPopup>
        )
    }
}