import React from "react";
import { ContentBlock, StdToolbar } from "../../components";
import { fillingCheckTypeService } from "../../api";
import AuthorizedComponent from "../authorized-component";
import { alertError, confirm2, enums, notifySuccess } from "../../base";
import { FillingCheckTypeModel } from "../../models";
import FillingCheckTypeGrid from "./filling-check-type-grid";
import FillingCheckTypeForm from "./filling-check-type-form";

export default class FillingCheckTypePage extends AuthorizedComponent {
    objectName = "FillingCheckType";
    gridRef = React.createRef<FillingCheckTypeGrid>();
    formRef = React.createRef<FillingCheckTypeForm>();
    toolbarRef = React.createRef<StdToolbar>();
    
    constructor(props: Readonly<any>) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
    }

    onModelSelected(e?: FillingCheckTypeModel) {
        this.toolbarRef.current?.setModel(e);
    }

    onButtonClick(model: FillingCheckTypeModel, name: string) {
        switch (name) {
            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.formRef.current?.show({});
                }
                break;

            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.formRef.current?.show(model);
                }
                break;

            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(model);
                }
                break;
        }
    }

    async delete(model: FillingCheckTypeModel) {
        if (!await confirm2("确定要删除该数据吗？")) {
            return;
        }

        const res = await fillingCheckTypeService.delete(model.id!);

        if (res.succeeded) {
            notifySuccess("已成功删除数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(res.message!);
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return (
            <ContentBlock caption={"充装检查项目"}>
                <StdToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onButtonClick={this.onButtonClick} />
                <FillingCheckTypeGrid
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
                <FillingCheckTypeForm ref={this.formRef} onSaved={this.onSaved} />
            </ContentBlock>
        );
    }
}