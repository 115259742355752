import React from "react";
import { alert2 } from "../../base/dialogs";
import { http } from "../../base/http";

export default class SmsPage extends React.Component<any> {
    constructor(props: any) {
        super(props);

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    userId = "";

    onValueChanged(event: any) {
        this.userId = event.target.value;
        this.setState({}) 
    }

    async sendTest() {
        const res = await http.postData(`/api/sms/test?userId=${this.userId}`, "");
        alert2(JSON.stringify(res));
    }

    render() {
        return <div>
            <button onClick={this.sendTest}>测试推送</button>
            <input value={this.userId} onChange={this.onValueChanged} />
        </div>
    }
}