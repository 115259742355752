import { IItemProps } from "devextreme-react/form";
import { ModuleConfig, ServerData, StringMap, ModuleData, stdTimeFormat } from "../base";
import { useServerData } from "./server-data";
import { useMemo } from "react";

export function formItemFill(
    moduleName: string | ModuleConfig,
    items: IItemProps[],
    map?: StringMap,
    serverData?: ServerData) {
    if (serverData) {
        const moduleData = new ModuleData(serverData, moduleName);

        for (const i of items) {
            if (!i.dataField) {
                continue;
            }

            if (!i.label) {
                const name = map ? map[i.dataField] || i.dataField : i.dataField;
                i.label = { text: moduleData.findLabel(name) };
            }

            if (!i.editorType) {
                const type = moduleData.findPropType(i.dataField);
                switch (type) {
                    case "number":
                        i.editorType = "dxNumberBox";
                        break;
                    case "date":
                        i.editorType = "dxDateBox";
                        break;
                }
            }

            if (i.editorType === "dxDateBox" && i.editorOptions?.type === "datetime") {
                i.editorOptions.displayFormat = stdTimeFormat;
            }

            const rules = moduleData.getValidationRules(i.dataField);

            if (!i.validationRules?.length) {
                i.validationRules = rules;
            } else {
                for (const rule of rules) {
                    const found = i.validationRules.find((r: any) => r.type === rule.type && r.message === rule.message);

                    if (!found) {
                        i.validationRules.push(rule);
                    }
                }
            }
        }
    }
}

export function useFormItemFill() {
    const serverData = useServerData().serverData;

    return useMemo(() => {
        return function (
            moduleName: string | ModuleConfig,
            items: IItemProps[],
            map?: StringMap) {
            return formItemFill(moduleName, items, map, serverData);
        }
    }, [serverData]);
}