import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React from "react";
import { orderReportService } from "../../api/reports";
import { createEmptyStore, globalPopupManager, globalPopupNames } from "../../base";
import { ContentBlock, createGridOptions, remoteReadOnlyGridOptions, StdGrid } from "../../components";
import { OrderForecastCondition } from "../../models";
import AuthorizedComponent from "../authorized-component";
import OrderForecastToolbar from "./order-forecast-toolbar";

interface OrderForecastState {
    store: CustomStore;
}

export default class OrderForecastPage extends AuthorizedComponent<any, OrderForecastState> {
    objectName = "OrderForecast";
    moduleName = "";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("customerTrendReport", this.gridRef, remoteReadOnlyGridOptions);

    columns: IColumnProps[] = [
        { 
            dataField: "name", 
            caption: "客户名称",
            cellRender: (e: any) => (
                <span className={"link-button"}
                    onClick={() => this.callcenter(e.data.id)}>
                    {e.data.name}
                </span>
            ) 
        },
        { 
            dataField: "gasCode", 
            caption: "用气卡号",
            cellRender: (e: any) => (
                <span className={"link-button"}
                    onClick={() => this.callcenter(e.data.id)}>
                    {e.data.gasCode}
                </span>
            )
        },
        { dataField: 'phoneNumber', caption: '电话' },
        { dataField: 'address', caption: '地址' },
        { dataField: 'organizationName', caption: '供应站' },
        { dataField: 'deliverName', caption: '送气工' },
        { dataField: 'typeName', caption: '类型' },
        { dataField: 'bottleCount', caption: '在用气瓶数', dataType: "number" },
        { dataField: 'avgInterval', caption: '叫气频率', dataType: "number" },
        { dataField: 'orderCount', caption: '历史订单数', dataType: "number" },
        { dataField: 'nextTime', caption: '预计叫气时间', dataType: 'date' },
        { 
            dataField: 'expiration', 
            caption: '异常情况',
            cellRender: (e: any) => (<span>{e.data.exception}</span>)
        }
    ]

    constructor(props: any) {
        super(props);

        this.state = {
            store: createEmptyStore()
        };

        this.gridOptions.filterRow!.visible = false;
        this.gridOptions.groupPanel!.visible = false;

        this.search = this.search.bind(this);
    }

    callcenter(id: number) {
        globalPopupManager.show(globalPopupNames.callCenter, { id: id });
    }

    async search(condition: OrderForecastCondition) {
        const store = orderReportService.getForecastReport(condition);
        this.setState({ store: store });
    }

    render() {
        return (
            <ContentBlock caption="订单预测">
                <OrderForecastToolbar onFiltering={this.search} />
                <StdGrid
                    moduleName={this.moduleName}
                    gridRef={this.gridRef}
                    dataSource={this.state.store}
                    options={this.gridOptions}
                    columns={this.columns}
                    toolbarHeight={80} />
            </ContentBlock>
        )
    }
}