import React from "react";
import { Button, DateBox, SelectBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { alertWarning, DataMap, dateAdd, enums, isPorcelainCode, ListItem, timeAdd, today, toShortCode } from "../../base";
import { BottleFlowModel, CustomerModel, EmployeeRef, OrganizationRef } from "../../models";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box"
import { employeeService, organizationService } from "../../api";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { CustomerPopup, OrgTreeDropDown } from "../../components";

export interface BottleFlowToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (model: BottleFlowModel, name: string) => void;
    onFiltering: (filterValue: any) => void;
}

interface BottleFlowToolbarState {
    model: BottleFlowModel;
    startTime?: Date | string;
    endTime?: Date | string;
    orgs: OrganizationRef[];
    delivers: EmployeeRef[];
    owners: EmployeeRef[];
    keyType: string;
    key?: string;
    customerId?: number;
}

export default class BottleFlowToolbar
    extends React.Component<BottleFlowToolbarProps, BottleFlowToolbarState> {
    customerPopup = React.createRef<CustomerPopup>();

    constructor(props: Readonly<BottleFlowToolbarProps>) {
        super(props);

        const day = today();

        this.state = {
            model: {},
            startTime: day,
            endTime: timeAdd(dateAdd(day, 1), -1, "s"),
            orgs: [],
            delivers: [],
            owners: [],
            keyType: "Bottle"
        };

        this.onKeyTypeChanged = this.onKeyTypeChanged.bind(this);
        this.onKeyChanged = this.onKeyChanged.bind(this);
        this.showCustomerPopup = this.showCustomerPopup.bind(this);
        this.onCustomerSelected = this.onCustomerSelected.bind(this);
        this.clearCustomer = this.clearCustomer.bind(this);
        this.onStartTimeChanged = this.onStartTimeChanged.bind(this);
        this.onEndTimeChanged = this.onEndTimeChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        var orgs: OrganizationRef[] = [],
            delivers: EmployeeRef[] = [],
            owners: EmployeeRef[] = [];

        await Promise.all([
            (async () => orgs = await organizationService.getRefs())(),
            (async () => delivers = await employeeService.getDelivers())(),
            (async () => owners = await employeeService.getRefs(
                enums.employeePosts.deliver, enums.employeePosts.warehouseKeeper))()
        ]);

        this.setState({
            orgs: orgs,
            delivers: delivers,
            owners: owners
        });
    }

    setModel(model: BottleFlowModel) {
        this.setState({ model: model });
    }

    onKeyTypeChanged(e: ValueChangedEvent) {
        this.setState({ keyType: e.value, key: undefined, customerId: undefined });
    }

    onKeyChanged(key?: string) {
        this.setState({ key: key?.trim() });
    }

    showCustomerPopup() {
        this.customerPopup.current?.show(this.state.key);
    }

    onCustomerSelected(customer: CustomerModel) {
        this.setState({
            customerId: customer.id,
            key: this.getCustomerName(customer)
        });
    }

    clearCustomer() {
        this.setState({
            customerId: undefined,
            key: undefined
        });
    }

    getCustomerName(customer: any) {
        return `${customer.name}（${customer.gasCode}${customer.status === enums.customerStatus.inactive ? '，已退户' : ''}）`;
    }

    onStartTimeChanged(e: DateChangedEvent) {
        this.setState({ startTime: e.value });
    }

    onEndTimeChanged(e: DateChangedEvent) {
        this.setState({ endTime: e.value });
    }

    onFiltering() {
        const globalPermission = this.props.permissions["Global"];

        if (!globalPermission && !this.state.key) {
            alertWarning("请输入气瓶条码查询");
            return;
        }

        const filterValue: any[] = [];

        switch (this.state.keyType) {
            case enums.bottlePositions.customer:
                if (this.state.customerId) {
                    filterValue.push(["customerId", "=", this.state.customerId]);
                } else if (!globalPermission) {
                    alertWarning("请选择要查询的客户");
                    return;
                }

                break;

            case enums.bottlePositions.deliveryman:
                if (this.state.key) {
                    filterValue.push(["deliverId", "=", this.state.key]);
                } else if (!globalPermission) {
                    alertWarning("请选择要查询的送气工");
                    return;
                }

                break;

            case enums.bottlePositions.site:
                if (this.state.key) {
                    filterValue.push(["organizationId", "=", this.state.key]);
                } else if (!globalPermission) {
                    alertWarning("请选择要查询的站点");
                    return;
                }

                break;

            case "Owner":
                if (this.state.key) {
                    filterValue.push(["ownerId", "=", this.state.key]);
                } else if (!globalPermission) {
                    alertWarning("请选择要查询的操作员");
                    return;
                }

                break;

            case "Bottle":
            default:
                if (this.state.key) {
                    if (isPorcelainCode(this.state.key)) {
                        filterValue.push(["bottle.barCode", "=", this.state.key]);
                    } else {
                        filterValue.push(["bottle.code", "=", toShortCode(this.state.key)]);
                    }
                } else if (!globalPermission) {
                    alertWarning("请输入要查询的气瓶陶瓷码或钢印号");
                    return;
                }

                break;
        }

        if (this.state.startTime) {
            filterValue.push(["createdAt", ">=", this.state.startTime]);
        }
        if (this.state.endTime) {
            filterValue.push(["createdAt", "<=", this.state.endTime]);
        }

        if (!filterValue.length) {
            alertWarning("请输入条件或起止时间查询");
            return;
        }

        this.props.onFiltering(filterValue);
    }

    render() {
        const { startTime, endTime, keyType, key, orgs, delivers, owners } = this.state;

        const target = this.state.model?.target;
        const buttonVisible = this.props.permissions["Global"] &&
            !!this.state.model.id && keyType === "Bottle" && !!key;

        const markText = this.state.model.flag ? "删除标记" : "标记为错误";

        const textButtons: any = [
            {
                name: 'clear2',
                location: 'after',
                options: {
                    icon: 'clear',
                    // stylingMode: 'text',
                    elementAttr: { "class": "form-popup-icon" },
                    onClick: this.clearCustomer
                }
            },
            {
                name: 'search',
                location: 'after',
                options: {
                    icon: "spindown",
                    stylingMode: 'text',
                    elementAttr: { "class": "form-popup-icon" },
                    onClick: this.showCustomerPopup
                }
            }
        ];

        return (
            <div className={"page-toolbar page-large-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            dataSource={keyTypes}
                            displayExpr={"text"}
                            valueExpr={"value"}
                            value={keyType}
                            width={120}
                            placeholder="选择类型"
                            onValueChanged={this.onKeyTypeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto" visible={keyType === "Bottle"}>
                        {keyType === "Bottle" && (
                            <TextBox
                                value={key}
                                placeholder={"陶瓷码或钢印号"}
                                width={120}
                                onValueChanged={e => this.onKeyChanged(e.value)}
                                onEnterKey={this.onFiltering} />
                        )}
                    </Item>
                    <Item
                        location={"before"}
                        locateInMenu="auto"
                        visible={keyType === enums.bottlePositions.customer}>
                        {keyType === enums.bottlePositions.customer && (
                            <TextBox
                                value={key}
                                placeholder={"请选择客户"}
                                width={200}
                                buttons={textButtons}
                                onValueChanged={e => this.onKeyChanged(e.value?.trim())}
                                onEnterKey={this.showCustomerPopup} />
                        )}
                    </Item>
                    <Item
                        location={"before"}
                        locateInMenu="auto"
                        visible={keyType === enums.bottlePositions.site}>
                        {keyType === enums.bottlePositions.site && (<OrgTreeDropDown
                            organizationId={key ? parseInt(key) : undefined}
                            organizations={orgs}
                            placeholder="选择供应站"
                            width={180}
                            onValueChanged={id => this.onKeyChanged(id?.toString())} />
                        )}
                    </Item>
                    <Item
                        location={"before"}
                        locateInMenu="auto"
                        visible={keyType === enums.bottlePositions.deliveryman}>
                        {keyType === enums.bottlePositions.deliveryman && (<SelectBox
                            value={key}
                            dataSource={delivers}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={160}
                            placeholder="选择送气工"
                            onValueChanged={e => this.onKeyChanged(e.value)} />
                        )}
                    </Item>
                    <Item
                        location={"before"}
                        locateInMenu="auto"
                        visible={keyType === "Owner"}>
                        {keyType === "Owner" && (<SelectBox
                            value={key}
                            dataSource={owners}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={200}
                            placeholder="选择操作员"
                            onValueChanged={e => this.onKeyChanged(e.value)} />
                        )}
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <DateBox
                            value={startTime}
                            placeholder="开始时间"
                            type="datetime"
                            showClearButton={true}
                            onValueChanged={this.onStartTimeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endTime}
                            placeholder="结束时间"
                            type="datetime"
                            showClearButton={true}
                            onValueChanged={this.onEndTimeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={buttonVisible}>
                        <Button
                            text={`更新气瓶最后位置为“${target}”`}
                            onClick={() => this.props.onButtonClick(this.state.model, "updateposition")} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={buttonVisible}>
                        <Button text={markText} onClick={() => this.props.onButtonClick(this.state.model, "setflag")} />
                    </Item>
                </Toolbar>
                <CustomerPopup ref={this.customerPopup} onCustomerSelected={this.onCustomerSelected} />
            </div>
        );
    }
}

const keyTypes: ListItem[] = [
    { value: "Bottle", text: "瓶号" },
    { value: enums.bottlePositions.customer, text: "客户" },
    { value: enums.bottlePositions.deliveryman, text: "送气工" },
    { value: enums.bottlePositions.site, text: "供应站" },
    { value: "Owner", text: "操作员" }
];