import { createStoreFromUrl, http, MessageBool } from "../base";
import { BottleRentModel } from "../models";

class BottleRentService {
    apiPath = "/api/bottlerent";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }
    
    getDeletedDxData() {
        return createStoreFromUrl(`${this.apiPath}/deleteddx`, 'id');
    }

    create(model: BottleRentModel) {
        return http.postData<MessageBool>(this.apiPath, model);
    }

    setNote(model: { id: number, note: string}) {
        return http.putData<MessageBool>(`${this.apiPath}/note`, model);
    }

    restore(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/restore/${id}`, '');
    }

    delete(id: number) {
        return http.deleteData<MessageBool>(`${this.apiPath}/${id}`);
    }
}

export const bottleRentService = new BottleRentService();
export const bottleRentStore = bottleRentService.getDxData();
export const bottleRentDeletedStore = bottleRentService.getDeletedDxData();