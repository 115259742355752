import { ModelValidatorInfo, ModuleConfig, ServerData, StringMap, ValidatorInfo } from "./models";

interface FindResult {
    name: string;
    mod: ModuleData;
}

export class ModuleData {
    private _moduleName?: string;
    private _validatorData?: ModelValidatorInfo;
    private _propMap?: StringMap;
    private _textMap?: StringMap;
    private _childrenData: { [key: string]: ModuleData } = {};

    constructor(
        serverData: ServerData,
        moduleName: string | ModuleConfig) {
        
        const name = typeof(moduleName) === "string" ? moduleName : moduleName.moduleName;

        this._moduleName = name;
        this._validatorData = serverData.validatorData[name] || {};
        this._propMap = serverData.propertyTypes[name] || {};
        this._textMap = serverData.textMaps[name] || {};

        if (typeof(moduleName) !== "string") { 
            this._childrenData = {};
            for (const key in moduleName.children) {
                this._childrenData[key] = new ModuleData(serverData, moduleName.children[key]);
            } 
        }
    }

    get moduleName() { return this._moduleName; }
    get validatorData() { return this._validatorData; }
    get propMap() { return this._propMap; }
    get textMap() { return this._textMap; }
    get childrenData() { return this._childrenData; }

    findLabel(fieldName: string) {
        return this._findData(fieldName, function(data: FindResult) {
            return data.mod.textMap!;
        });
    }

    findPropType(fieldName: string) {
        return this._findData(fieldName, function(data: FindResult) {
            return data.mod.propMap!;
        });
    }

    _findData(fieldName: string, mapFunc: (res: FindResult) => StringMap) {
        const result = this._findModule(fieldName),
            name = result.name,
            map = mapFunc(result);

        if (!map) { return name; }
        else if (map[name]) { return map[name]; }

        const lowerName = name.toLowerCase();
        for (const key in map) {
            if (key.toLowerCase() === lowerName) { return map[key]; }
        }

        return name;
    }

    private _findModule(fieldName: string): FindResult {
        const defaultRet = { name: fieldName, mod: this };
        const arr = fieldName.split('.');

        if (arr.length === 1) {
            return defaultRet;
        }

        let md: ModuleData;
        let children = this._childrenData;

        for (let i = 0; i < arr.length - 1; i++) {
            const name = arr[i];
            md = children[name];
            if (md) { 
                children = md._childrenData;
                continue; 
            }

            const lowerName = name.toLowerCase();

            for (const key in children) {
                if (key.toLowerCase() === lowerName) { 
                    md = children[key];
                    children = md._childrenData;
                    break;
                }
            }

            if (!md) { return defaultRet; }
        }

        return { name: arr[arr.length - 1], mod: md! };
    }

    /**
     * 获取表单的映射规则集合
     * @param moduleName 模块名
     * @param fieldNames 字段名列表
     */
     getFormValidationRules(fieldNames?: string[]) {
        if (!fieldNames) {
            const vdata = this._validatorData!;
            if (!vdata)
                return {};

            fieldNames = [];
            for (const key in vdata)
                fieldNames.push(key);
        }

        const formRules: { [key: string]: any[] } = {};

        for (const fieldName of fieldNames)
            formRules[fieldName] = this.getValidationRules(fieldName);

        return formRules;
    }

    /**
     * 获取字段的映射规则
     * @param fieldName 字段名
     */
    getValidationRules(fieldName: string) {
        const { name, mod } = this._findModule(fieldName);
        const vdata = mod.validatorData!;
        let vinfos = vdata[name] || [];

        if (!vinfos) {
            const lowerName = name.toLowerCase();
            for (const key in vdata) {
                if (key.toLowerCase() === lowerName) { 
                    vinfos = vdata[key]; 
                    break; 
                }
            }
        }

        let rules: any[] = [];
        for (let vinfo of vinfos)
            this.setRules(rules, vinfo, fieldName);

        return rules;
    }

    private setRules(rules: any[], vinfo: ValidatorInfo, fieldName: string) {
        switch (vinfo.type) {
            case 'required':
                if (this._propMap![fieldName] !== "bool") { // 布尔值在dev必填会理解为true!!!
                    rules.push({ type: 'required', message: vinfo.message });
                }
                break;
            case 'rangelength':
                let lrule: any = { type: 'stringLength', message: vinfo.message };
                if (vinfo.args[0] != null) lrule.min = vinfo.args[0];
                if (vinfo.args[1] != null) lrule.max = vinfo.args[1];
                rules.push(lrule);
                break;
            case 'regex':
                let pattern = vinfo.args[1] ? new RegExp(vinfo.args[0], vinfo.args[1]) : vinfo.args[0];
                rules.push({ type: 'pattern', message: vinfo.message, pattern: pattern });
                break;
            case 'range':
                let rrule: any = { type: 'range', message: vinfo.message };
                if (vinfo.args[0] != null) rrule.min = vinfo.args[0];
                if (vinfo.args[1] != null) rrule.max = vinfo.args[1];
                rules.push(rrule);
                break;
        }
    }
}