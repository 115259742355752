import React from "react";
import { Button, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { DataMap, enums } from "../../base";

export interface StdToolbarProps {
    permissions?: DataMap<boolean>;
    onButtonClick: (model: any, name: string) => void;
    itemsBefore?: (model: any) => React.ReactNode;
    itemsAfter?: (model: any) => React.ReactNode;
    primaryKey?: string;
}

interface StdToolbarState {
    model: any;
}

export default class StdToolbar extends React.Component<StdToolbarProps, StdToolbarState> {
    constructor(props: Readonly<StdToolbarProps>) {
        super(props);

        this.state = {
            model: {}
        };
    }

    setModel(model: any) {
        this.setState({ model: model });
    }

    render() {
        const { itemsAfter, itemsBefore, primaryKey } = this.props;
        const { model } = this.state;
        const permissions = this.props.permissions || {};
        const hasModel = !!model[primaryKey || 'id'];

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    {itemsBefore && itemsBefore(model)}
                    <Item location={"after"} locateInMenu={"auto"} visible={!!permissions[enums.stdActions.create]}>
                        <Button
                            text="新增"
                            onClick={() => this.props.onButtonClick(null, "add")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={!!permissions[enums.stdActions.update]}>
                        <Button
                            disabled={!hasModel}
                            text="编辑"
                            onClick={() => this.props.onButtonClick(model, "edit")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={!!permissions[enums.stdActions.delete]}>
                        <Button
                            disabled={!hasModel}
                            text="删除"
                            onClick={() => this.props.onButtonClick(model, "delete")} />
                    </Item>
                    {itemsAfter && itemsAfter(model)}
                </Toolbar>
            </div>
        );
    }
}