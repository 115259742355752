import './side-nav-outer-toolbar.scss';
import Drawer from "devextreme-react/drawer";
import ScrollView from "devextreme-react/scroll-view";
import React, { useState, useCallback, useRef } from "react";
import { useScreenSize, useMenuPatch } from '../../hooks';
import { Template } from 'devextreme-react/core/template';
import { historyInstance } from '../../base';
import Header from './header';
import Footer from './footer';
import SideNavigationMenu from "./side-navigation-menu";

export default function SideNavOuterToolbar({ children }: any) {
    const scrollViewRef: any = useRef();
    const history = historyInstance;
    const { isXSmall } = useScreenSize();
    const [patchCssClass, onMenuReady] = useMenuPatch();
    const [menuStatus, setMenuStatus] = useState(isXSmall ? MenuStatus.Closed : MenuStatus.Opened);

    const toggleMenu = useCallback(({ event }) => {
        setMenuStatus(
            prevMenuStatus => prevMenuStatus === MenuStatus.Closed
                ? MenuStatus.Opened
                : MenuStatus.Closed
        );
        event.stopPropagation();
    }, []);

    const onNavigationChanged = useCallback(({ itemData: { path }, event, node }) => {
        if (menuStatus === MenuStatus.Closed || !path || node.selected) {
            event.preventDefault();
            return;
        }

        history.push(path);
        scrollViewRef.current.instance.scrollTo(0);

        if (isXSmall && menuStatus === MenuStatus.Opened) {
            setMenuStatus(MenuStatus.Closed);
        }
    }, [history, menuStatus, isXSmall]);

    return (
        <div className={'side-nav-outer-toolbar'}>
            <Header
                className={'layout-header'}
                toggleMenu={toggleMenu}
            />
            <Drawer
                className={['drawer', patchCssClass].join(' ')}
                position={'before'}
                openedStateMode={'shrink'}
                revealMode={isXSmall ? 'slide' : 'expand'}
                maxSize={220}
                opened={menuStatus === MenuStatus.Opened || !isXSmall}
                template={'menu'}
            >
                <div className={'container'}>
                    <ScrollView ref={scrollViewRef} className={'layout-body with-footer'}>
                        <div className={'content'}>
                            {React.Children.map(children, item => {
                                return item.type !== Footer && item;
                            })}
                        </div>
                        <div className={'content-block'}>
                            {React.Children.map(children, item => {
                                return item.type === Footer && item;
                            })}
                        </div>
                    </ScrollView>
                </div>
                <Template name={'menu'}>
                    <SideNavigationMenu 
                        selectedItemChanged={onNavigationChanged}
                        onMenuReady={onMenuReady}>
                    </SideNavigationMenu>
                </Template>
            </Drawer>
        </div>
    );
}

const MenuStatus = {
    Closed: 1,
    Opened: 2
};