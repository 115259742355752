import { Button, DateBox, SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import React from "react";
import { WebMessageModel } from "../../models";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { dateAdd, ListItem } from "../../base";
import { ValueChangedEvent } from "devextreme/ui/select_box";

export interface WebMessageToolbarProps {
    onButtonClick: (model: WebMessageModel, name: string) => void;
    onFiltering: (filterValue: any) => void;
}

interface WebMessageToolbarState {
    model: WebMessageModel;
    read?: boolean;
    startDate?: Date | string;
    endDate?: Date | string;
}

export default class WebMessageToolbar
    extends React.Component<WebMessageToolbarProps, WebMessageToolbarState> {
    constructor(props: Readonly<WebMessageToolbarProps>) {
        super(props);

        this.state = {
            model: {}
        };

        this.onReadChanged = this.onReadChanged.bind(this);
        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    setModel(model: WebMessageModel) {
        this.setState({ model: model });
    }

    onReadChanged(e: ValueChangedEvent) {
        this.setState({ read: e.value });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onFiltering() {
        const { read, startDate, endDate } = this.state;
        const filterValue: any[] = [];

        if (read === false) {
            filterValue.push("readAt", "=", null);
        } else if (read === true) {
            filterValue.push("readAt", "<>", null);
        }

        if (startDate) {
            filterValue.push("createdAt", ">=", startDate);
        }

        if (endDate) {
            filterValue.push("createdAt", "<", dateAdd(new Date(endDate), 1));
        }

        this.props.onFiltering(filterValue);
    }

    render() {
        const { model, read, startDate: startTime, endDate: endTime } = this.state;

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            dataSource={readSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            value={read}
                            onValueChanged={this.onReadChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <DateBox
                            value={startTime}
                            placeholder="开始时间"
                            showClearButton={true}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endTime}
                            placeholder="结束时间"
                            showClearButton={true}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"}>
                        <Button
                            text="设置选中为已读"
                            disabled={!!model.readAt}
                            onClick={() => this.props.onButtonClick(model, "read")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            text="全部设置为已读"
                            onClick={() => this.props.onButtonClick(model, "readall")} />
                    </Item>
                </Toolbar>
            </div>
        )
    }
}

const readSource: ListItem<boolean | undefined>[] = [
    { value: undefined, text: "显示全部" },
    { value: true, text: "显示已读" },
    { value: false, text: "显示未读" }
];