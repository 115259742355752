import { Button, DateBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import React from "react";
import { organizationService } from "../../../api";
import { alertError, dateAdd, fdate, today } from "../../../base";
import { OrganizationRef, StocktakingReportCondition } from "../../../models";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { OrgTreeDropDown } from "../../../components";

interface StocktakingReportToolbarProps {
    onSearching: (condition: StocktakingReportCondition) => void;
    onExporting: (condition: StocktakingReportCondition) => void;
}

interface StocktakingReportToolbarState {
    orgs: OrganizationRef[];
    organizationId?: number;
    startDate: Date | string;
    endDate: Date | string;
}

export default class StocktakingReportToolbar
    extends React.Component<StocktakingReportToolbarProps, StocktakingReportToolbarState> {

    constructor(props: Readonly<StocktakingReportToolbarProps>) {
        super(props);

        const day = today();

        this.state = {
            orgs: [],
            startDate: dateAdd(day, -6),
            endDate: day
        };

        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onSearching = this.onSearching.bind(this);
        this.onExporting = this.onExporting.bind(this);
    }

    componentDidMount() {
        this.loadOrgs();
    }

    async loadOrgs() {
        const orgs = await organizationService.getRefs();

        this.setState({ 
            orgs: orgs,
            organizationId: orgs.length === 1 ? orgs[0].id : undefined
        });
    }

    onOrganizationChanged(organizationId?: number) {
        this.setState({ organizationId: organizationId });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onSearching() {
        if (!this.state.organizationId) {
            alertError("请选择供应站");
            return;
        }

        this.props.onSearching({ 
            organizationId: this.state.organizationId, 
            startDate: fdate(this.state.startDate), 
            endDate: fdate(this.state.endDate)
        });
    }

    onExporting() {
        if (!this.state.organizationId) {
            alertError("请选择供应站");
            return;
        }

        this.props.onExporting({ 
            organizationId: this.state.organizationId, 
            startDate: fdate(this.state.startDate), 
            endDate: fdate(this.state.endDate)
        });
    }

    render() {
        const { orgs, organizationId, startDate, endDate } = this.state;

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={startDate}
                            placeholder="开始日期"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束日期"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onSearching} />
                    </Item>
                    <Item location={"after"} locateInMenu="false">
                        <Button
                            text="导出"
                            type="default"
                            onClick={this.onExporting} />
                    </Item>
                </Toolbar>
            </div>
        )
    }

}