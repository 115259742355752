import { Button, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import React from "react";
import { DataMap, enums } from "../../base";
import { CpBatchModel } from "../../models";

export interface CpBatchToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (model: CpBatchModel, name: string) => void; 
}

interface CpBatchToolbarState {
    model: CpBatchModel;
}

export default class CpBatchToolbar 
    extends React.Component<CpBatchToolbarProps, CpBatchToolbarState> {
    constructor(props: Readonly<CpBatchToolbarProps>) {
        super(props);

        this.state = {
            model: {},
        }
    }

    setModel(model: CpBatchModel) {
        this.setState({ model: model });
    }

    render(): React.ReactNode {
        const { model } = this.state;
        const permissions = this.props.permissions || {};
        const codePrint = [enums.cpBatchTypes.new, enums.cpBatchTypes.checkBack, enums.cpBatchTypes.specialCheckBack]
            .indexOf(model.batchType!) !== -1;

        const exportNy = model.batchType !== enums.cpBatchTypes.check;

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"after"} locateInMenu={"auto"} visible={!!permissions[enums.stdActions.create]}>
                        <Button
                            text="新增"
                            onClick={() => this.props.onButtonClick({}, "add")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={!!permissions[enums.stdActions.update]}>
                        <Button
                            disabled={!model.id}
                            text="编辑"
                            onClick={() => this.props.onButtonClick(model, "edit")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            text="查看批次内容"
                            onClick={() => this.props.onButtonClick(model, "detail")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={!!permissions["PrintCode"]}>
                        <Button
                            text="打印标签"
                            disabled={!codePrint}
                            onClick={() => this.props.onButtonClick(model, "printcode")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            text="打印报表"
                            onClick={() => this.props.onButtonClick(model, "printreport")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            text="导出报表"
                            onClick={() => this.props.onButtonClick(model, "exportreport")} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"}>
                        <Button
                            text="导出兰洋格式"
                            disabled={!exportNy}
                            onClick={() => this.props.onButtonClick(model, "exportny")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={!!permissions[enums.stdActions.delete]}>
                        <Button
                            disabled={!model.id}
                            text="删除"
                            onClick={() => this.props.onButtonClick(model, "delete")} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}