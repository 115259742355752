import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";
import React from "react";
import { serverDataService } from "../../../api";
import { orderReportService } from "../../../api/reports";
import { baseApiUrl, download, http } from "../../../base";
import { ContentBlock, createGridOptions, StdGrid, stdReadOnlyGridOptions } from "../../../components";
import { DeliverPerformanceReport, DeliverPerformanceReportCondition } from "../../../models";
import AuthorizedComponent from "../../authorized-component";
import DeliverPerformanceToolbar from "./deliver-performance-toolbar";

interface DeliverPerformanceReportPageState {
    columns: IColumnProps[];
    data: DeliverPerformanceReport[];
}

export default class DeliverPerformanceReportPage 
    extends AuthorizedComponent<any, DeliverPerformanceReportPageState> {
    
    objectName = "DeliverPerformance";
    moduleName = "";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("deliverPerformanceReport", this.gridRef, stdReadOnlyGridOptions);

    constructor(props: any) {
        super(props);

        this.state = {
            data: [],
            columns: [
                { 
                    dataField: 'nameAndCode', 
                    caption: '送气工',
                    allowGrouping: false
                },
                ...serverDataService.gasBottleTypes.map(t => ({
                    dataField: `item_${t.id}`,
                    caption: t.name,
                    allowGrouping: false,
                    allowSorting: false,
                    cellRender: (e: any) => {
                        const count = e.data.bottleCount[t.id!] || 0;
                        return (<span>{count}</span>)
                    }
                }))
            ]
        };

        this.gridOptions.filterRow!.visible = false;
        this.gridOptions.export!.enabled = false;
        this.gridOptions.summary = {
            calculateCustomSummary: (options) => {
                if (options.summaryProcess === "finalize" && options.name) {
                    const id = parseInt(options.name!.split('_')[1]);
                    let totalValue = 0;
                    this.state.data.forEach(d => totalValue += d.bottleCount[id] || 0);
                    options.totalValue = totalValue;
                }
            },
            totalItems: [
                ...serverDataService.gasBottleTypes.map((t) => ({
                    column: `item_${t.id}`, 
                    name: `item_${t.id}`, 
                    summaryType: "custom", 
                    displayFormat: "计：{0}"
                }))
            ]
        }

        this.filter = this.filter.bind(this);
    }

    async filter(condition: DeliverPerformanceReportCondition) {
        const data = await orderReportService.getPerformance(condition);
        this.setState({ data: data });
    }

    async export(condition: DeliverPerformanceReportCondition) {
        await download(
            `${baseApiUrl}${orderReportService.apiPath}/performance/export?${http.serialize([condition])}`, 
            "送气工绩效统计报表.xlsx");
    }

    render(): React.ReactNode {
        return (
            <ContentBlock caption="送气工绩效统计报表">
                <DeliverPerformanceToolbar onFiltering={this.filter} onExporting={this.export} />
                <StdGrid
                    moduleName={this.moduleName}
                    gridRef={this.gridRef}
                    dataSource={this.state.data}
                    options={this.gridOptions}
                    columns={this.state.columns} />
            </ContentBlock>
        )
    }
}