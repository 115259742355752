import { createStoreFromApi, http, BaseApiService } from "../base";
import { RoleModel, RoleRef } from "../models";


class RoleService extends BaseApiService<RoleModel> {
    moduleName = "role";
    cacheSeconds = 30;

    getRefs(): Promise<RoleRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/refs`), this.cacheSeconds);
    }
}

export const roleService = new RoleService();
export const roleStore = createStoreFromApi(roleService);