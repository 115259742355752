import React from "react";
import { Button, DateBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { DataMap, dateAdd, enums, today } from "../../base";
import { OrganizationRef, ShippingModel } from "../../models";
import { OrgTreeDropDown } from "../../components";
import { organizationService } from "../../api";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";

export interface ShippingToolBarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (model: ShippingModel, name: string) => void;
    onFiltering: (filterValue: any) => void;
}

interface ShippingToolbarState {
    model: ShippingModel;
    orgs: OrganizationRef[];
    organizationId?: number;
    startDate?: Date | string;
    endDate?: Date | string;
}

export default class ShippingToolbar extends
    React.Component<ShippingToolBarProps, ShippingToolbarState> {
    constructor(props: Readonly<ShippingToolBarProps>) {
        super(props);

        const startDate = today();

        this.state = {
            model: {},
            orgs: [],
            startDate: dateAdd(startDate, -7),
            endDate: startDate
        };

        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }
    
    componentDidMount(): void {
        this.loadOrgs();
        setTimeout(this.onFiltering, 100);
    }

    async loadOrgs() {
        const orgs = await organizationService.getRefs();
        this.setState({ orgs: orgs });
    }

    setModel(model: ShippingModel) {
        this.setState({ model: model });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onOrganizationChanged(organizationId?: number) {
        this.setState({
            organizationId: organizationId
        });
    }

    onFiltering() {
        this.props.onFiltering(this.getFilterValue());
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { startDate, endDate, organizationId } = this.state;

        if (startDate) {
            filters.push(["shippingTime", ">=", startDate]);
        }
        if (endDate) {
            filters.push(["shippingTime", "<", dateAdd(new Date(endDate), 1)])
        }
        if (organizationId) {
            filters.push(["organizationId", "=", organizationId]);
        }

        return filters.length > 0 ? filters : null;
    }

    render() {
        const { model, organizationId, orgs, startDate, endDate } = this.state;
        const permissions = this.props.permissions;

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu={true}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="选择供应站过滤"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={startDate}
                            placeholder="开始时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions[enums.stdActions.create]}>
                        <Button
                            text="添加运输记录"
                            onClick={() => this.props.onButtonClick({}, "add")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            text="导出运输单"
                            disabled={!model?.id}
                            onClick={() => this.props.onButtonClick(model, "export")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            disabled={!model?.id}
                            text="查看钢瓶详情"
                            onClick={() => this.props.onButtonClick(model, "view")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            disabled={!model?.id}
                            text="复制钢瓶详情"
                            onClick={() => this.props.onButtonClick(model, "copy")} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}