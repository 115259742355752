import { ContentBlock } from "../../components";
import React from "react";
import MyAnnouncementGrid from "./my-announcement-grid";
import { AnnouncementEmployeeModel } from "../../models";
import MyAnnouncementToolbar from "./my-announcement-toolbar";
import MyAnnouncementDetail from "./my-announcement-detail";


export default class MyAnnouncementPage extends React.Component {
    gridRef = React.createRef<MyAnnouncementGrid>();
    detailRef = React.createRef<MyAnnouncementDetail>();
    toolbarRef = React.createRef<MyAnnouncementToolbar>();

    constructor(props: any) {
        super(props);
        
        this.onModelSelected = this.onModelSelected.bind(this);
        this.filter = this.filter.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
    }

    onModelSelected(model: AnnouncementEmployeeModel) {
        this.toolbarRef.current?.setModel(model);
    }

    filter(filterValue: any, showAll: boolean) {
        this.gridRef.current?.filter(filterValue, showAll);
    }

    onButtonClick(model: AnnouncementEmployeeModel, name: string) {
        switch (name) {
            case "view":
                this.detailRef.current?.show(model);
                break;
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return (
            <ContentBlock caption={"公告列表"}>
                <MyAnnouncementToolbar
                    ref={this.toolbarRef}
                    onButtonClick={this.onButtonClick}
                    onFiltering={this.filter} />
                <MyAnnouncementGrid
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
               <MyAnnouncementDetail ref={this.detailRef} onSaved={this.onSaved} />
            </ContentBlock>
        );
    }
}