import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import React from "react";
import { sysLogStore } from "../../api/sys-log.service";
import { createGridOptions, remoteReadOnlyGridOptions, StdGrid } from "../../components";
import { SysLogModel } from "../../models";
import CustomStore from "devextreme/data/custom_store";
import { createEmptyStore } from "../../base";

export interface SysLogGridProps {
    onModelSelected(model: SysLogModel): void;
    onButtonClick(model: SysLogModel, name: string): void;
}

interface SysLogGridState {
    store: CustomStore;
}


const logEvents = [
    "Exception",
    "APP提交失败",
    "APP代码同步失败",
    "APP：提交异常",
    "APP：网络异常",
    "APP：异常",
    "充装同步日志",
    "气瓶同步日志",
    "气瓶详情同步日志",
    "充前检查同步日志",
    "充后检查同步日志",
    "同步钢瓶记录失败",
    "同步充装记录失败",
    "同步充装前检查记录失败",
    "同步充装后检查记录失败",
    "未发现气瓶信息，已自动创建，请手动更新该数据"
]

export default class SysLogGrid extends React.Component<SysLogGridProps, SysLogGridState> {
    moduleName = "SysLog";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions(
        "bottleFillings",
        this.gridRef,
        remoteReadOnlyGridOptions,
        (items: any[]) => {
            // state清空会同时清空filter，需要重新绑定旧的
            items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
            return items;
        });

    columns: IColumnProps[] = [
        { dataField: "id" },
        { dataField: "logTime", dataType: "datetime" },
        { 
            dataField: "logEvent",
            lookup: { dataSource: logEvents }
        },
        {
            dataField: "logData",
            cellRender: (e) => {
                return (
                    <div>
                        {e.data.logData?.substr(0, 50)} &nbsp;
                        {e.data.logData && e.data.logData.length > 50 && (
                            <span 
                                className="link-button" 
                                onClick={() => this.props.onButtonClick(e.data, "logdata")}>
                                更多...
                            </span>
                        )}
                    </div>
                )
            }
        },
        { 
            dataField: "desc",
            cellRender: (e) => {
                return (
                    <div>
                        {e.data.desc?.substr(0, 50)} &nbsp;
                        {e.data.desc && e.data.desc.length > 50 && (
                            <span 
                                className="link-button" 
                                onClick={() => this.props.onButtonClick(e.data, "desc")}>
                                更多...
                            </span>
                        )}
                    </div>
                )
            }
        }
    ];

    constructor(props: Readonly<SysLogGridProps>) {
        super(props);

        this.state = { store: createEmptyStore() }

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }
    
    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});
        }
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    filter(filterValue: any) {
        if (filterValue) {
            this.setState({ store: sysLogStore }, () => this.gridRef.current?.instance.filter(filterValue));
        } else {
            this.setState({ store: createEmptyStore() });
        }
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.columns}>
            </StdGrid>
        );
    }
}
