import { Button, DateBox, SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import React from "react";
import { organizationService } from "../../api";
import { alertError, dataSources, enums, fdate, today } from "../../base";
import { OrganizationRef, StocktakingCondition } from "../../models";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { OrgTreeDropDown } from "../../components";

interface StocktakingToolbarProps {
    onSearching: (condition: StocktakingCondition) => void;
}

interface StocktakingToolbarState {
    orgs: OrganizationRef[];
    organizationId?: number;
    date: Date | string;
    outType: string;
}

const stockOutTypes = dataSources.stockOutTypes.map(p => ({ value: p.value, text: `调出统计：${p.text}` }));

export default class StocktakingToolbar
    extends React.Component<StocktakingToolbarProps, StocktakingToolbarState> {

    constructor(props: Readonly<StocktakingToolbarProps>) {
        super(props);

        this.state = {
            orgs: [],
            date: today(),
            outType: enums.stockOutTypes.stockOutFlow
        };

        this.onDateChanged = this.onDateChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onStockOutTypeChanged = this.onStockOutTypeChanged.bind(this);
        this.onSearching = this.onSearching.bind(this);
    }

    componentDidMount() {
        this.loadOrgs();
    }

    async loadOrgs() {
        const orgs = await organizationService.getRefs();

        this.setState({ 
            orgs: orgs,
            organizationId: orgs.length === 1 ? orgs[0].id : undefined
        });
    }

    onOrganizationChanged(organizationId?: number) {
        this.setState({ organizationId: organizationId });
    }

    onDateChanged(e: DateChangedEvent) {
        this.setState({ date: e.value });
    }

    onStockOutTypeChanged(e: ValueChangedEvent) {
        this.setState({ outType: e.value });
    }

    onSearching() {
        if (!this.state.organizationId) {
            alertError("请选择供应站");
            return;
        }

        this.props.onSearching({ 
            organizationId: this.state.organizationId, 
            date: fdate(this.state.date), 
            outType: this.state.outType
        });
    }

    render() {
        const { orgs, organizationId, date, outType } = this.state;

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={date}
                            placeholder="日期"
                            width={120}
                            onValueChanged={this.onDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto" text={"调出统计："}>
                        <SelectBox
                            value={outType}
                            dataSource={stockOutTypes}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={180}
                            onValueChanged={this.onStockOutTypeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onSearching} />
                    </Item>
                </Toolbar>
            </div>
        )
    }

}