import React from "react";
import { Popup } from "devextreme-react";
import Form, { IItemProps } from "devextreme-react/form";
import { createPopupField, setItemDataSource, CustomerPopup, FormMultiImageUploader, PopupContentBox, RefPopup, StdForm } from "../../components";
import { ModuleConfig, StringMap, alertError, notifySuccess, enums, modelClone } from "../../base";
import { troubleshootingService, organizationService, dataSourceService, employeeService } from "../../api";
import { CustomerModel, EmployeeRef, OrganizationRef, TroubleshootingModel } from "../../models";

interface TroubleshootingWithName extends TroubleshootingModel {
    _customerName?: string;
}

export interface TroubleshootingFormProps {
    onSaved?: () => void;
}

interface TroubleshootingFormState {
    orgs: OrganizationRef[];
    employees: EmployeeRef[];
    types: string[];
    items: IItemProps[];
    model: TroubleshootingWithName;
}

export default class TroubleshootingForm extends
    React.Component<TroubleshootingFormProps, TroubleshootingFormState> {
    moduleName: ModuleConfig = {
        moduleName: "Troubleshooting",
        children: {
            "customer": "Customer"
        }
    }

    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    customerRef = React.createRef<CustomerPopup>();
    nameMap: StringMap = { "_customerName": "customerId" };

    constructor(props: Readonly<TroubleshootingFormProps>) {
        super(props);

        this.state = {
            orgs: [],
            employees: [],
            types: [],
            model: {},
            items: [
                createPopupField("_customerName", this.customerRef),
                {
                    dataField: "organizationId",
                    editorType: "dxSelectBox",
                    editorOptions: { dataSource: [], displayExpr: 'name', valueExpr: 'id', readOnly: true }
                },
                {
                    dataField: 'customer.deliverId',
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: [], displayExpr: 'name', valueExpr: 'id', readOnly: true }
                },
                { dataField: 'address' },
                { dataField: 'phoneNumber' },
                { dataField: "reportTime", editorOptions: { type: "datetime" } },
                {
                    dataField: "type",
                    editorType: "dxSelectBox",
                    editorOptions: { dataSource: [] }
                },
                { dataField: "reason" },
                {
                    dataField: "photo",
                    render: (e: any) => {
                        return (
                            <FormMultiImageUploader
                                formData={this.state.model}
                                dataField={"photo"}
                                sourceField={"photoSource"}
                                id={"troubleshootingPhoto"} />
                        )
                    }
                }
            ]
        }

        this.onCustomerSelected = this.onCustomerSelected.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let orgs: OrganizationRef[] = [], employees: EmployeeRef[] = [], types: string[] = [];

        await Promise.all([
            (async () => orgs = await organizationService.getRefs())(),
            (async () => employees = await employeeService.getDelivers())(),
            (async () => types = await dataSourceService.getDataSource(enums.dataSourceTypes.troubleTypes))()
        ])

        setItemDataSource(this.state.items, "organizationId", orgs);
        setItemDataSource(this.state.items, "customer.deliverId", employees);
        setItemDataSource(this.state.items, "type", types);

        this.setState({
            orgs: orgs,
            employees: employees,
            types: types
        });
    }

    show(model: TroubleshootingWithName) {
        this.popupRef.current?.instance.show();

        model = modelClone(model);

        if (model.customer) {
            model._customerName = this.getCustomerName(model.customer);
            model.customerId = model.customer.id;
            model.organizationId = model.customer.organizationId;
            model.address = model.customer.address;
            model.phoneNumber = model.customer.phoneNumber;
        }

        this.setState({ 
            model: model
        });
    }

    getCustomerName(customer: any) {
        return `${customer.name}（${customer.gasCode}${customer.status === enums.customerStatus.inactive ? '，已退户' : ''}）`;
    }
    
    async onCustomerSelected(customer: CustomerModel) {
        const model = {
            ...this.state.model,
            customer: customer,
            customerId: customer.id,
            organizationId: customer.organizationId,
            _customerName: this.getCustomerName(customer),
            address: customer.address,
            phoneNumber: customer.phoneNumber
        };

        this.setState({ model: model });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const valid = this.formRef.current?.instance.validate();
        if (!valid?.isValid) {
            return;
        }

        const model = this.state.model;

        const result = model.id ?
            await troubleshootingService.update(model) :
            await troubleshootingService.create(model);

        if (result.succeeded) {
            notifySuccess("已成功保存数据");
            this.hide();
            this.props.onSaved && this.props.onSaved();
        } else {
            alertError(result.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"创建抢修单"}
                width={800}
                height={380}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        nameMap={this.nameMap}
                        formData={this.state.model} />
                </PopupContentBox>
                <CustomerPopup ref={this.customerRef} onCustomerSelected={this.onCustomerSelected} />
            </RefPopup>
        )
    }
}