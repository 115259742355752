import { DataBool, http, MessageBool } from "../base";
import { BottleStocktakingModel, StocktakingCondition } from "../models";

class BottleStocktakingService {
    apiPath = "/api/bottlestocktaking";

    get(condition: StocktakingCondition) {
        return http.getData<DataBool<BottleStocktakingModel>>(`${this.apiPath}`, condition);
    }

    save(model: BottleStocktakingModel) {
        return http.putData<MessageBool>(this.apiPath, model);
    }
}

export const bottleStocktakingService = new BottleStocktakingService();