import { http, MessageBool } from "../base";
import { EmployeeShareModel, MutualShareModel } from "../models";

class EmployeeShareService {
    apiPath = "/api/employeeshare";

    getByOwnerId(id: string)  {
        return http.getData<EmployeeShareModel>(`${this.apiPath}/${id}`);
    }

    save(model: EmployeeShareModel) {
        return http.putData<MessageBool>(this.apiPath, model);
    }

    saveMutual(model: MutualShareModel) {
        return http.putData<MessageBool>(`${this.apiPath}/mutual`, model);
    }
}

export const employeeShareService = new EmployeeShareService();