import React from "react";
import { exceptionDataService } from "../../../api/exception-data.service";
import { alertError, alertWarning, globalPopupManager, globalPopupNames, isPorcelainCode, notifySuccess, prompt2, toShortCode } from "../../../base";
import { ContentBlock, RefPopup } from "../../../components";
import AuthorizedComponent from "../../authorized-component";
import ExceptionWarningToolbar from "./exception-warning-toolbar";
import ExceptionWarningGrid from "./exception-warning-grid";
import { Popup } from "devextreme-react";
import BottleFlowGrid from "../../bottle/bottle-flow-grid";
import { BottleFlowModel } from "../../../models";

export default class ExceptionWarningPage extends AuthorizedComponent {
    objectName = "ExceptionData";
    toolbarRef = React.createRef<ExceptionWarningToolbar>();
    gridRef = React.createRef<ExceptionWarningGrid>();
    popupRef = React.createRef<Popup>();
    flowGridRef = React.createRef<BottleFlowGrid>();

    constructor(props: Readonly<any>) {
        super(props);

        this.onRowChecked = this.onRowChecked.bind(this);
        this.filter = this.filter.bind(this);
        this.handle = this.handle.bind(this);
        this.showFlowPopup = this.showFlowPopup.bind(this);
        this.onFlowGridButtonClick = this.onFlowGridButtonClick.bind(this);
    }

    onRowChecked(ids: number[]) {
        this.toolbarRef.current?.setIds(ids);
    }

    filter(filterValue: any) {
        this.gridRef.current?.filter(filterValue);
    }

    async handle(ids: number[]) {
        var note = await prompt2("请输入处理说明：" +
            (ids.length === 1 ? "（提示：点击表格右上角√可调出复选框多选同时处理）" : ""));

        if (!note) {
            alertWarning("未提供说明，处理已取消");
            return;
        }
        
        var result = await exceptionDataService.handle({
            ids: ids,
            note: note
        });

        if (result.succeeded) {
            notifySuccess("处理成功");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message || "");
        }
    }

    showFlowPopup(code: string) {
        this.popupRef.current?.instance.show();
        
        const filter: any = isPorcelainCode(code) ?
            ["bottle.barCode", "=", code] :
            ["bottle.code", "=", toShortCode(code)];

        this.flowGridRef.current?.filter(filter);
    }

    showCallCenter(gasCode: string) {
        globalPopupManager.show(globalPopupNames.callCenter, { phone: gasCode });
    }

    onFlowGridButtonClick(model: BottleFlowModel, eventName: string) {
        switch (eventName) {
            case "callCenter":
                globalPopupManager.show(globalPopupNames.callCenter, { id: model.customerId });
                break;

            default:
                alertError("不支持在此窗口执行操作");
                break;
        }
    }

    render() {
        return (
            <ContentBlock caption="异常数据预警">
                <ExceptionWarningToolbar
                    ref={this.toolbarRef} 
                    permissions={this.permissions}
                    onFiltering={this.filter}
                    handle={this.handle} />
                <ExceptionWarningGrid
                    ref={this.gridRef}
                    onRowChecked={this.onRowChecked} showFlowPopup={this.showFlowPopup}
                    showCallCenter={this.showCallCenter} />
                <RefPopup popupRef={this.popupRef} title={"流转记录"}>
                    <BottleFlowGrid 
                        ref={this.flowGridRef} 
                        showDangerIssueButton={false}
                        onModelSelected={() => { }} 
                        onButtonClick={this.onFlowGridButtonClick} />
                </RefPopup>
            </ContentBlock>
        )
    }
}
