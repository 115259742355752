import React from "react";
import { IItemProps } from "devextreme-react/form";

export function createPopupField(dataField: string, ref: React.RefObject<any>, editorOptions?: any): IItemProps {
    let key = '';

    return {
        dataField: dataField,
        editorType: 'dxTextBox',
        editorOptions: {
            ...editorOptions,
            onValueChanged: function(e: any) {
                key = e.value;
            },
            onEnterKey: function(e: any) {
                ref.current?.show(key);
            },
            buttons: [
                { 
                    location: 'after', 
                    name: 'popup', 
                    options: { 
                        icon: "spindown",
                        stylingMode: 'text',
                        elementAttr: { "class": "form-popup-icon" },
                        onClick: function() {
                            ref.current?.show(key);
                        }
                    }
                }
            ]
        }
    }
}