import React from "react";
import { Popup, Form } from "devextreme-react";
import { RefPopup } from "../popup";
import { PopupContentBox } from "../layouts";
import { Item, Label, RequiredRule } from "devextreme-react/form";
import { vehicleService } from "../../api";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { VehicleRef } from "../../models";

export interface VehicleData {
    id: number;
    driver: string;
    supercargo: string;
}

export interface VehiclePopupProps {
    onSelected: (data: VehicleData) => void;
}

interface VehiclePopupState {
    formData: any;
    allVehicles: VehicleRef[];
    vehicles: VehicleRef[];
    drivers: string[];
    supercargos: string[];
}

export default class VehiclePopup extends React.Component<VehiclePopupProps, VehiclePopupState> {
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    
    constructor(props: Readonly<VehiclePopupProps>) {
        super(props);

        this.state = {
            formData: {},
            allVehicles: [],
            vehicles: [],
            drivers: [],
            supercargos: []
        };

        this.onVehicleChanged = this.onVehicleChanged.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.loadVehicles();
    }

    async loadVehicles() {
        const data = await vehicleService.getRefs();
        this.setState({ 
            allVehicles: data,
            vehicles: data 
        });
    }

    show(organizationId?: number) {
        this.popupRef.current?.instance.show();

        const vehicles = organizationId ? 
            this.state.allVehicles.filter(v => v.organizations?.find(o => o.organizationId === organizationId)) : 
            this.state.allVehicles;

        this.setState({ 
            formData: {}, 
            vehicles: vehicles 
        });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    onVehicleChanged(e: ValueChangedEvent) {
        const vehicle = this.state.vehicles.find(v => v.id === e.value);
        this.setState({
            drivers: vehicle?.driverList || [],
            supercargos: vehicle?.supercargoList || []
        });
    }

    save() {
        const isValid = this.formRef.current?.instance.validate();
        if (!isValid?.isValid) {
            return;
        }

        this.props.onSelected(this.state.formData);
    }

    cancel() {
        this.hide();
    }

    render() {
        const { vehicles, drivers, supercargos } = this.state;

        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"选择车辆"}
                width={400}
                height={360}>
                <PopupContentBox
                    scrollable={true}
                    saveButtonText={"确定"}
                    onSave={this.save}
                    onCancel={this.cancel}>
                    <Form formData={this.state.formData} ref={this.formRef}>
                        <Item 
                            dataField={"id"} 
                            editorType={"dxSelectBox"}
                            editorOptions={{ 
                                dataSource: vehicles, 
                                displayExpr: 'vehicleNumber', 
                                valueExpr: 'id',
                                searchEnabled: true,
                                onValueChanged: this.onVehicleChanged
                            }}>
                            <Label text="车辆" />
                            <RequiredRule message="车辆信息不可为空" />
                        </Item>
                        <Item dataField={"driver"} editorType={"dxSelectBox"} editorOptions={{ dataSource: drivers }}>
                            <Label text="司机" />
                            <RequiredRule message="司机不可为空" />
                        </Item>
                        <Item dataField={"supercargo"} editorType={"dxSelectBox"} editorOptions={{ dataSource: supercargos }}>
                            <Label text="押运员" />
                            <RequiredRule message="押运员不可为空" />
                        </Item>
                    </Form>
                </PopupContentBox>
            </RefPopup>
        )
    }
}