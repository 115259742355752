import { createStoreFromApi, http, DataMap, BaseApiService } from "../base";
import { DataSourceModel } from "../models";

class DataSourceService extends BaseApiService<DataSourceModel> {
    moduleName = "datasource";
    cacheSeconds = 60;

    getMap(): Promise<DataMap<string[]>> {
        return this.getCached(() => http.getData(`${this.apiPath}/map`), this.cacheSeconds);
    }

    async getDataSource(type: string): Promise<string[]> {
        const map = await this.getMap();
        return map[type] || [];
    }
}

export const dataSourceService = new DataSourceService();
export const dataSourceStore = createStoreFromApi(dataSourceService);