import { MessageBool, createStoreFromUrl, http } from "../base";
import { ExceptionDataHandleModel, ExceptionDataSummary } from "../models";

class ExceptionDataService {
    apiPath = "/api/exceptiondata";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }

    getWarningDxData() {
        return createStoreFromUrl(`${this.apiPath}/warning/dx`, 'id');
    }

    getSummaryByType(organizationId?: number) {
        return http.getData<ExceptionDataSummary[]>(`${this.apiPath}/summary/type?organizationId=${organizationId || ''}`);
    }

    GetSummaryByOrganization(type?: string) {
        return http.getData<ExceptionDataSummary[]>(`${this.apiPath}/summary/org?type=${type || ''}`);
    }

    clearSummaryCache() {
        return http.deleteData<boolean>(`${this.apiPath}/summary/cache`);
    }

    handle(model: ExceptionDataHandleModel) {
        return http.putData<MessageBool>(`${this.apiPath}/handle`, model);
    }
}

export const exceptionDataService = new ExceptionDataService();
export const exceptionDataStore = exceptionDataService.getDxData();
export const exceptionWarningStore = exceptionDataService.getWarningDxData();
