import React from "react";
import { dateAdd } from "../../base";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box"
import { Button, DateBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";

export interface CpLogToolbarProps {
    onFiltering: (filterValue: any) => void;
}

interface CpLogToolbarState {
    startDate?: Date | string;
    endDate?: Date | string;
    code?: string;
}

export default class CpLogToolbar extends React.Component<CpLogToolbarProps, CpLogToolbarState> {
    constructor(props: Readonly<CpLogToolbarProps>) {
        super(props);

        this.state = {};

        this.onCodeChanged = this.onCodeChanged.bind(this);
        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    onCodeChanged(code?: string) {
        this.setState({ code: code });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onFiltering() {
        const filterValue: any[] = [];

        if (this.state.code) {
            filterValue.push(["barcode", "contains", this.state.code]);
        }

        if (this.state.startDate) {
            filterValue.push(["logTime", ">=", this.state.startDate]);
        }

        if (this.state.endDate) {
            filterValue.push(["logTime", "<", dateAdd(new Date(this.state.endDate), 1)]);
        }

        this.props.onFiltering(filterValue);
    }

    render() {
        const { code, startDate, endDate } = this.state;

        return (
            <div className={"page-toolbar page-large-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <TextBox
                            value={code}
                            placeholder="气瓶条码后6位"
                            width={120}
                            showClearButton={true}
                            onValueChange={this.onCodeChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>

                        <DateBox
                            value={startDate}
                            placeholder="开始时间"
                            type="datetime"
                            showClearButton={true}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束时间"
                            type="datetime"
                            showClearButton={true}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                </Toolbar>
            </div>
        )
    }
}