import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import { dataSources } from "../../base";
import { bugTraceStore, sysObjectService } from "../../api";
import { StdGrid, createGridOptions, createImageColumn, remoteReadOnlyGridOptions, setColumnDataSource } from "../../components";
import { BugTraceModel, SysObjectModel } from "../../models";

export interface BugTraceGridProps {
    onButtonClick: (model: BugTraceModel, name: string) => void;
    onModelSelected: (model?: BugTraceModel) => void;
}

interface BugTraceGridState {
    sysObjects: SysObjectModel[];
    columns: IColumnProps[];
}

export default class BugTracePage extends React.Component<BugTraceGridProps, BugTraceGridState>  {
    moduleName = "BugTrace";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("bugTraces", this.gridRef, remoteReadOnlyGridOptions);

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            sysObjects: [],
            columns: [
                {
                    dataField: 'status',
                    lookup: { dataSource: dataSources.bugStatus, displayExpr: 'text', valueExpr: 'value' }
                },
                {
                    dataField: 'priority',
                    lookup: { dataSource: dataSources.bugPriority, displayExpr: 'text', valueExpr: 'value' }
                },
                {
                    dataField: 'platform',
                    lookup: { dataSource: dataSources.platforms, displayExpr: 'text', valueExpr: 'value' }
                },
                { 
                    dataField: 'objectName',
                    lookup: { dataSource: [], displayExpr: 'label', valueExpr: 'objectName' }
                },
                { dataField: 'actionName' },
                { dataField: 'operator' },
                createImageColumn({ dataField: 'photos' }),
                { dataField: 'question' },
                { dataField: 'answer' },
                { dataField: 'createdAt', dataType: 'datetime' },
                { dataField: 'updatedAt', dataType: 'datetime' }
            ]
        }

        this.gridOptions.wordWrapEnabled = true;
        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
    }

    componentDidMount(): void {
        this.loadData();
    }

    async loadData() {
        const objs = await sysObjectService.getVisible();
        setColumnDataSource(this.state.columns, "objectName", objs);
        this.setState({ sysObjects: objs })
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected(undefined);
        }
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    render() {
        return (

            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={bugTraceStore}
                options={this.gridOptions}
                columns={this.state.columns}
                popupWidth={800}
                popupHeight={350}
                formColCount={2} />
        );
    }
}