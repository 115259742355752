import { createStoreFromApi, http, BaseApiService } from "../base";
import { SiteCheckTypeModel, SiteCheckTypeRef } from "../models";

class SiteCheckTypeService extends BaseApiService<SiteCheckTypeModel> {
    moduleName = "sitechecktype";
    cacheSeconds = 60;

    getRefs(): Promise<SiteCheckTypeRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/refs`), this.cacheSeconds);
    }
}

export const siteCheckTypeService = new SiteCheckTypeService();
export const siteCheckTypeStore = createStoreFromApi(siteCheckTypeService);