import React from "react";
import { Form, Popup, TagBox, TextBox } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import { alertError, dataSources, enums, modelClone, notifySuccess } from "../../base";
import { EmployeeModel, OrganizationRef, RoleRef } from "../../models";
import { dataSourceService, employeeService, organizationService, roleService } from "../../api";
import { OrgTreeDropDown, PopupContentBox, RefPopup, setItemDataSource, StdForm } from "../../components";
import { ValueChangedEvent } from "devextreme/ui/tag_box";

export interface EmployeeFormProps {
    rolePermission: boolean;
    onSaved: () => void;
}

interface EmployeeFormState {
    employee: EmployeeModel;
    roles: RoleRef[];
    orgs: OrganizationRef[];
    customPosts: string[];
    items: IItemProps[];
}

export default class EmployeeForm extends React.Component<EmployeeFormProps, EmployeeFormState> {
    moduleName = "Employee";

    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();

    constructor(props: Readonly<EmployeeFormProps>) {
        super(props);

        this.state = {
            employee: {},
            orgs: [],
            roles: [],
            customPosts: [],
            items: [
                { dataField: 'code' },
                { dataField: 'name' },
                {
                    dataField: 'organizationId',
                    render: () => {
                        return this.state.employee?.id ?
                        (
                            <TextBox readOnly={true} value={this.state.orgs.find(o => o.id === this.state.employee.organizationId)?.name} />
                        ) :
                        <OrgTreeDropDown 
                            organizationId={this.state.employee.organizationId}
                            organizations={this.state.orgs}
                            onValueChanged={(organizationId) => {
                                this.state.employee.organizationId = organizationId;
                                this.setState({})
                            }} />
                    }
                },
                { dataField: 'sortNumber' },
                {
                    dataField: 'status',
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: dataSources.userStatus, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: 'phoneNumber' },
                {
                    dataField: 'post',
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: dataSources.employeePosts, displayExpr: 'text', valueExpr: 'value' }
                },
                { 
                    dataField: 'employeeRoles',
                    label: { text: '角色' },
                    render: this.renderRoleTagBox.bind(this)
                },
                { dataField: 'idNo' },
                { dataField: 'certNumber' },
                { dataField: 'certExpiration' },
                // { dataField: 'address' },
                { dataField: 'note', colSpan: 2 }
            ]
        }

        this.onTagValueChanged = this.onTagValueChanged.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount(): void {
        this.loadData();
    }

    async loadData() {
        let orgs: OrganizationRef[] = [], roles: RoleRef[] = [], customPosts: string[] = [];

        await Promise.all([
            (async () => orgs = await organizationService.getRefs())(),
            (async () => roles = await roleService.getRefs())(),
            (async () => customPosts = await dataSourceService.getDataSource(enums.dataSourceTypes.customPosts))()
        ]);

        // setItemDataSource(this.state.items, "organizationId", orgs);
        setItemDataSource(this.state.items, "post",
            [...dataSources.employeePosts, ...(customPosts || []).map(p => ({ text: p, value: p }))])

        this.setState({
            orgs: orgs,
            roles: roles,
            customPosts: customPosts
        });
    }

    show(employee: EmployeeModel) {
        this.popupRef.current?.instance.show();

        this.setState({ employee: modelClone(employee) });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    onTagValueChanged(e: ValueChangedEvent) {
        const employee = this.state.employee;
        const roles = (e.value as number[]).map(v => ({ employeeId: employee.id, roleId: v }));
        employee.employeeRoles = roles;
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const employee = this.state.employee;
            const res = employee.id ?
                await employeeService.update(employee) :
                await employeeService.create(employee);

            if (res.succeeded) {
                this.hide();
                notifySuccess("已成功保存");
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title="编辑"
                width={800}
                height={460}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        formData={this.state.employee} />
                </PopupContentBox>
            </RefPopup>
        )
    }

    renderRoleTagBox() {
        const employee = this.state.employee;
        const roles = this.state.roles;
        const roleIds = employee.employeeRoles ? employee.employeeRoles.map(r => r.roleId) : [];

        return (
            <TagBox
                readOnly={!this.props.rolePermission}
                dataSource={roles}
                defaultValue={roleIds}
                valueExpr={"id"}
                displayExpr={"name"} 
                onValueChanged={this.onTagValueChanged}>
            </TagBox>
        )
    }
}