import './announcement-detail.scss';
import { DataGrid, Popup } from "devextreme-react";
import React from "react";
import { announcementService, organizationService } from "../../api";
import { baseApiUrl } from "../../base";
import { RefPopup } from "../../components";
import { AnnouncementModel, OrganizationRef } from "../../models";
import { Column, GroupPanel, Lookup } from 'devextreme-react/data-grid';

interface AnnouncementDetailState {
    model: AnnouncementModel;
    orgs: OrganizationRef[];
}

export default class AnnouncementDetail extends React.Component<any, AnnouncementDetailState> {
    popupRef = React.createRef<Popup>();

    constructor(props: any) {
        super(props);

        this.state = {
            model: { employees: [] },
            orgs: []
        };
    }

    componentDidMount(): void {
        this.loadOrgs();
    }

    async loadOrgs() {
        const orgs = await organizationService.getRefs();
        this.setState({ orgs: orgs });
    }

    async show(model: AnnouncementModel) {
        this.popupRef.current?.instance.show();

        const detail = await announcementService.get(model.id!);
        this.setState({ model: detail });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    render() {
        const modelEmployees = this.state.model.employees;

        return (
            <RefPopup
                popupRef={this.popupRef}
                title={this.state.model.caption || ""}
                width={1000}
                height={800}>
                <DataGrid dataSource={modelEmployees} height={"100%"}>
                    <GroupPanel visible={true} />
                    <Column 
                        dataField={"employee.organizationId"} 
                        caption="站点" 
                        defaultGroupIndex={0} 
                        autoExpandGroup={false}>
                        <Lookup dataSource={this.state.orgs} displayExpr={"name"} valueExpr={"id"} />
                    </Column>
                    <Column dataField={"employee.name"} caption="员工" />
                    <Column dataField={"employee.code"} caption="员工号" defaultSortIndex={0} defaultSortOrder={"asc"} />
                    <Column dataField={"readAt"} dataType={"datetime"} caption={"阅读时间"} />
                    <Column dataField={"sign"} caption={"确认签字"} cellRender={this.renderSign}></Column>
                </DataGrid>
            </RefPopup>
        )
    }

    renderSign(data: any) {
        return (
            <div>
                {data.data.sign && <span className="announcement-sign">{data.data.sign}</span>}
                {data.data.signPhoto && 
                    <img className="announcement-sign-photo" src={`${baseApiUrl}/api/file?name=${data.data.signPhoto}`} alt="" />}
            </div>
        )
    }
}

