import React, { useState, useCallback, useEffect, createContext, useContext } from "react";
import { Handler } from "../base/models";
import devices from "devextreme/core/devices";

const timerInterval = 500;

export interface ScreenSize {
    isXSmall?: boolean;
    isSmall?: boolean;
    isMedium?: boolean;
    isLarge?: boolean;
    width: number;
    height: number;
}

class MediaQuery {
    handlers: Handler[] = [];
    timer: any = null;
    xSmallMedia = window.matchMedia('(max-width: 599.99px)');
    smallMedia = window.matchMedia('(min-width: 600px) and (max-width: 959.99px)');
    mediumMedia = window.matchMedia('(min-width: 960px) and (max-width: 1279.99px)');
    largeMedia = window.matchMedia('(min-width: 1280px)');

    constructor() {
        var self = this;

        // 如升级DEV版本，应检查此项，如有问题，改用其它组件或自行判断
        if (devices.current().deviceType === "desktop") {
            window.addEventListener("resize", (e) => {
                if (self.timer) {
                    clearTimeout(self.timer);
                }

                self.timer = setTimeout(() => {
                    self.handlers.forEach(handler => handler());
                    self.timer = null;
                }, timerInterval);
            });
        }
    }

    subscribe(handler: Handler) {
        this.handlers.push(handler);
    }

    unsubscribe(handler: Handler) {
        const index = this.handlers.indexOf(handler);
        if (index !== -1) {
            this.handlers.splice(index, 1);
        }
    }

    getScreenSize(): ScreenSize {
        return {
            isXSmall: this.xSmallMedia.matches,
            isSmall: this.smallMedia.matches,
            isMedium: this.mediumMedia.matches,
            isLarge: this.largeMedia.matches,
            width: window.innerWidth,
            height: window.innerHeight
        };
    }
}

const mediaQuery = new MediaQuery();
const ScreenSizeContext: React.Context<ScreenSize> =
    createContext({ width: window.innerWidth, height: window.innerHeight });
const useScreenSize = () => useContext(ScreenSizeContext);

function ScreenSizeProvider(props: any) {
    const [screenSize, setScreenSize] = useState(mediaQuery.getScreenSize());
    const onSizeChanged = useCallback(() => {
        setScreenSize(mediaQuery.getScreenSize());
    }, []);

    useEffect(() => {
        mediaQuery.subscribe(onSizeChanged);
        // 不能释放，必须持续监听
    }, [onSizeChanged]);

    return (
        <ScreenSizeContext.Provider value={screenSize} {...props} />
    );
}

const useScreenSizeClass = () => {
    const screenSize = useScreenSize();

    if (screenSize.isLarge) {
        return 'screen-large';
    } else if (screenSize.isMedium) {
        return 'screen-medium';
    } else if (screenSize.isSmall) {
        return 'screen-small';
    } else {
        return 'screen-x-small';
    }
};

function getGridHeight(screenSize: ScreenSize) {
    if (screenSize.isXSmall) {
        return undefined;
    } else {
        const height = screenSize.height - 142;
        return height < 500 ? undefined : height;
    }
}

const useGridHeight = () => {
    const screenSize = useScreenSize();
    return getGridHeight(screenSize);
}

export {
    mediaQuery,
    ScreenSizeContext,
    ScreenSizeProvider,
    useScreenSize,
    useScreenSizeClass,
    getGridHeight,
    useGridHeight
}