import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import React from "react";
import { fillingSettingStore } from "../../api";
import { createGridOptions, remoteReadOnlyGridOptions, StdGrid } from "../../components";
import { FillingSettingModel } from "../../models";

export interface FillingSettingGridProps {
    onModelSelected(model: FillingSettingModel): void;
    onButtonClick(model: FillingSettingModel, name: string): void;
}

interface FillingSettingGridState {
}

export default class FillingSettingGrid 
    extends React.Component<FillingSettingGridProps, FillingSettingGridState> {
    moduleName = "FillingSetting";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("bottles", this.gridRef, remoteReadOnlyGridOptions);

    columns: IColumnProps[] = [
        { dataField: 'settingDate', dataType: "date" },
        { dataField: 'prechecker' },
        { dataField: 'rechecker' }
    ];

    constructor(props: Readonly<FillingSettingGridProps>) {
        super(props);

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});
        }
    }
    
    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={fillingSettingStore}
                options={this.gridOptions}
                columns={this.columns}>
            </StdGrid>
        )
    }
}