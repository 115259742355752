import { FileUploader, Form, Popup } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import { UploadedEvent } from "devextreme/ui/file_uploader";
import React from "react";
import { dataSourceService, trainingService } from "../../api";
import { alertError, authorizationService, baseApiUrl, enums, modelClone, notifySuccess, StringMap, temporaryUploadUrl, timeAdd, today, tokenStorage } from "../../base";
import { FormMultiImageUploader, PopupContentBox, RefPopup, setItemDataSource, StdForm } from "../../components";
import { TrainingModel } from "../../models";

interface TrainingEditModel extends TrainingModel {
    _beginTime?: any;
    _endTime?: any;
}

export interface TrainingFormProps {
    onSaved: () => void;
}

interface TrainingFormState {
    types: string[];
    methods: string[];
    fileTypes: string[];
    model: TrainingEditModel;
    items: IItemProps[];
    files: any[];
    editable: boolean;
}

export default class TrainingForm extends React.Component<TrainingFormProps, TrainingFormState> {
    moduleName = "Training";
    objectName = "Training";
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    header = { Authorization: `bearer ${tokenStorage.token}` };
    uploadUrl = `${baseApiUrl}${temporaryUploadUrl}`;

    nameMap: StringMap = {
        "_beginTime": "beginTime",
        "_endTime": "endTime"
    };

    constructor(props: Readonly<TrainingFormProps>) {
        super(props);

        this.state = {
            types: [],
            methods: [],
            fileTypes: [],
            files: [],
            model: {},
            editable: false,
            items: [
                { dataField: "caption", colSpan: 2 },
                { 
                    dataField: "type",
                    editorType: "dxSelectBox",
                    editorOptions: { dataSource: [] }
                },
                { 
                    dataField: "method",
                    editorType: "dxSelectBox",
                    editorOptions: { dataSource: [] }
                },
                { dataField: "date", editorType: "dxDateBox" },
                { itemType: "empty", name: 'empty1' },
                {
                    dataField: "_beginTime",
                    editorType: "dxDateBox",
                    editorOptions: { type: "time", showClearButton: true }
                },
                {
                    dataField: "_endTime",
                    editorType: "dxDateBox",
                    editorOptions: { type: "time", showClearButton: true }
                },
                { dataField: "address", colSpan: 2 },
                { 
                    dataField: "content",
                    colSpan: 2,
                    editorType: "dxTextArea",
                    editorOptions: { height: 100 }
                },
                {
                    dataField: "people",
                    colSpan: 2,
                    editorType: "dxTextArea"
                },
                {
                    dataField: "fileType",
                    editorType: "dxSelectBox",
                    editorOptions: { dataSource: [] }
                },
                { itemType: "empty", name: 'empty2' },
                { 
                    dataField: "files",
                    colSpan: 2,
                    render: (e: any) => (
                        <div>
                            {this.state.files.map((f, index) => (
                                <span>
                                    <a target={"_blank"} rel="noreferrer" href={f.url}>{f.name}</a> &nbsp;
                                    <i className="bi bi-x-square-fill text-danger" onClick={() => this.removeFile(index)}></i>
                                </span>
                            ))}
                            <FileUploader
                                multiple={true}
                                uploadMode="instantly"
                                uploadUrl={this.uploadUrl}
                                uploadHeaders={this.header}
                                name="file"
                                onUploaded={this.onUploaded.bind(this)} />
                        </div>
                    )
                },
                {
                    dataField: "photos",
                    colSpan: 2,
                    render: (e: any) => (
                        <FormMultiImageUploader
                            formData={this.state.model}
                            dataField="photos"
                            sourceField="photoSource"
                            id="trainingPhotos" />
                    )
                }
            ]
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.authorize();
        this.loadData();
    }

    async authorize() {
        const editable = await authorizationService.authorize(this.objectName, enums.stdActions.update);
        this.setState({ editable: editable });
    }

    async loadData() {
        let types: string[] = [], methods: string[] = [], fileTypes: string[] = [];

        await Promise.all([
            (async () => types = await dataSourceService.getDataSource(enums.dataSourceTypes.trainingTypes))(),
            (async () => methods = await dataSourceService.getDataSource(enums.dataSourceTypes.trainingMethods))(),
            (async () => fileTypes = await dataSourceService.getDataSource(enums.dataSourceTypes.trainingFileTypes))()
        ]);

        setItemDataSource(this.state.items, "type", types);
        setItemDataSource(this.state.items, "method", methods);
        setItemDataSource(this.state.items, "fileType", fileTypes);

        this.setState({
            types: types,
            methods: methods,
            fileTypes: fileTypes
        });
    }

    onUploaded(e: UploadedEvent) {
        const model = this.state.model;
        const fileName = e.request.responseText!;
        model.files = model.files ? model.files + ';' + fileName : fileName;

        this.setState({ 
            model: model, 
            files: this._getFiles(model) 
        });
    }

    removeFile(index: number) {
        const { model, files } = this.state;
        const fileArray = model.files ? model.files.split(';') : [];
        fileArray.splice(index, 1);
        files.splice(index, 1);

        this.setState({ model: model, files: files });
    }

    show(model: TrainingModel) {
        this.popupRef.current?.instance.show();

        const copy: TrainingEditModel = modelClone(model);
        const day = today();
        copy._beginTime = copy.beginTime ? timeAdd(day, copy.beginTime, "n") : undefined;
        copy._endTime = copy.endTime ? timeAdd(day, copy.endTime, "n") : undefined;

        this.setState({ 
            model: copy,
            files: this._getFiles(model)
        });
    }
    
    _getFiles(model: TrainingModel) {
        return model.files ? model.files.split(';').map(f => ({
            name: f.split('/').pop(),
            url: `${baseApiUrl}/api/file?name=${f}`
        })) : [];
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const valid = this.formRef.current?.instance.validate();
        if (!valid?.isValid) {
            return;
        }

        const model = this.state.model;

        if (model._beginTime) {
            const beginTime = new Date(model._beginTime);
            model.beginTime = beginTime.getHours() * 60 + beginTime.getMinutes();
        } else {
            model.beginTime = 0;
        }
        
        if (model._endTime) {
            const endTime = new Date(model._endTime);
            model.endTime = endTime.getHours() * 60 + endTime.getMinutes();
        } else {
            model.endTime = 0;
        }

        const result = model.id ?
            await trainingService.update(model) :
            await trainingService.create(model);

        if (result.succeeded) {
            notifySuccess("已成功保存数据");
            this.hide();
            this.props.onSaved && this.props.onSaved();
        } else {
            alertError(result.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        const editable = this.state.editable;

        return (
            <RefPopup
                popupRef={this.popupRef}
                title={editable ? "编辑培训记录" : "查看培训记录"}
                width={800}
                height={680}>
                <PopupContentBox 
                    scrollable={true} 
                    onSave={this.save} 
                    onCancel={this.cancel}
                    hideSavebutton={!editable}
                    hideCancelButton={!editable}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        formData={this.state.model}
                        nameMap={this.nameMap}
                        options={{readOnly: !editable}} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}