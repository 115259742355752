import React from "react";
import { Button, DateBox, SelectBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { DataMap, dateAdd, enums, ListItem, today } from "../../base";
import { AreaRef, BottleRentModel, EmployeeRef, OrganizationRef } from "../../models";
import { areaService, employeeService, emptyDataSources, organizationService } from "../../api";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { OrgTreeDropDown } from "../../components";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { ValueChangedEvent as TextChangedEvent } from "devextreme/ui/text_box";

interface BottleRentToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (model: BottleRentModel, name: string) => void;
    onFiltering: (filterValue: any, showDeleted: boolean) => void;
}

interface BottleRentToolbarState {
    model: BottleRentModel;
    orgs: OrganizationRef[];
    allEmployees: EmployeeRef[];
    employees: EmployeeRef[];
    counties: AreaRef[];
    allStreets: AreaRef[];
    streets: AreaRef[];
    organizationId?: number;
    countyId?: number;
    streetId?: number;
    operator?: string;
    startDate?: Date | string;
    endDate?: Date | string;
    showDeleted: boolean;
    customerKey?: string;
}

export default class BottleRentToolbar
    extends React.Component<BottleRentToolbarProps, BottleRentToolbarState> {
    constructor(props: Readonly<BottleRentToolbarProps>) {
        super(props);

        const startDate = today();

        this.state = {
            model: {},
            orgs: [],
            allEmployees: [],
            employees: [],
            counties: [],
            allStreets: [],
            streets: [],
            showDeleted: false,
            startDate: dateAdd(startDate, -7),
            endDate: startDate
        };

        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onCountyChanged = this.onCountyChanged.bind(this);
        this.onStreetChanged = this.onStreetChanged.bind(this);
        this.onOperatorChanged = this.onOperatorChanged.bind(this);
        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);
        this.onCustomerChanged = this.onCustomerChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount() {
        this.loadDataSources();
        setTimeout(this.onFiltering, 100);
    }

    async loadDataSources() {
        const dataSources = emptyDataSources();

        await Promise.all([
            (async () => dataSources.orgs = await organizationService.getRefs())(),
            (async () => dataSources.employees = await employeeService.getAllRefs())(),
            (async () => dataSources.counties = await areaService.getCounties())(),
            (async () => dataSources.streets = await areaService.getStreets())(),
        ]);

        this.setState({
            orgs: dataSources.orgs,
            allEmployees: dataSources.employees,
            employees: dataSources.employees,
            counties: dataSources.counties,
            allStreets: dataSources.streets,
            streets: dataSources.streets
        })
    }

    setModel(model: BottleRentModel) {
        this.setState({ model: model });
    }

    onOrganizationChanged(organizationId?: number) {
        const employees = organizationId ?
            this.state.allEmployees.filter(e => e.organizationId === organizationId) :
            this.state.allEmployees;

        const operator = employees.find(e => e.id === this.state.operator) ?
            this.state.operator : undefined;

        this.setState({
            organizationId: organizationId,
            operator: operator
        });
    }

    onOperatorChanged(e: ValueChangedEvent) {
        this.setState({ operator: e.value });
    }

    onFiltering() {
        this.props.onFiltering(this.getFilterValue(), this.state.showDeleted);
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { startDate, endDate, organizationId, operator, countyId, streetId, customerKey } = this.state;

        if (startDate) {
            filters.push(["chargeTime", ">=", startDate]);
        }
        if (endDate) {
            filters.push(["chargeTime", "<", dateAdd(new Date(endDate), 1)])
        }
        if (organizationId) {
            filters.push(["customer.organizationId", "=", organizationId]);
        }
        if (operator) {
            filters.push(["operator", "=", operator]);
        }
        if (countyId) {
            filters.push(["customer.areaId", "=", countyId]);
        }
        if (streetId) {
            filters.push(["customer.streetId", "=", streetId]);
        }
        if (customerKey) {
            filters.push([
                [ "customer.gasCode", "contains", customerKey],
                "or",
                [ "customer.name", "contains", customerKey]
            ]);
        }

        return filters.length > 0 ? filters : null;
    }

    onCountyChanged(e: ValueChangedEvent) {
        const streets = e.value ?
            this.state.allStreets.filter(s => s.parentId === e.value) :
            this.state.allStreets;

        const streetId = streets.find(s => s.id === this.state.streetId) ? this.state.streetId : undefined;

        this.setState({
            countyId: e.value,
            streets: streets,
            streetId: streetId
        });
    }

    onStreetChanged(e: ValueChangedEvent) {
        this.setState({ streetId: e.value });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onStatusChanged(e: ValueChangedEvent) {
        this.setState({ showDeleted: e.value });
    }

    onCustomerChanged(e: TextChangedEvent) {
        this.setState({ customerKey: e.value });
    }

    render() {
        const { model, orgs, employees, counties, streets, organizationId, countyId, streetId, operator,
            startDate, endDate, showDeleted, customerKey } = this.state;
        const permissions = this.props.permissions;

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto" text={"收取时间："} />
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={startDate}
                            placeholder="开始时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions[enums.stdActions.create]}>
                        <Button
                            text="新增"
                            onClick={() => this.props.onButtonClick({}, "add")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.update]}>
                        <Button
                            text="备注"
                            disabled={!model?.id}
                            onClick={() => this.props.onButtonClick(model, "edit")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.delete]}>
                        <Button
                            text="作废"
                            disabled={!model?.id || model.deleted}
                            onClick={() => this.props.onButtonClick(model, "delete")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" 
                        visible={permissions[enums.stdActions.delete] && !!model?.deleted}>
                        <Button
                            text="还原"
                            onClick={() => this.props.onButtonClick(model, "restore")} />
                    </Item>
                </Toolbar>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={showDeleted}
                            dataSource={statusSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={120}
                            onValueChanged={this.onStatusChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={operator}
                            dataSource={employees}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={160}
                            placeholder="操作员"
                            onValueChanged={this.onOperatorChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={countyId}
                            dataSource={counties}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder="行政区域"
                            onValueChanged={this.onCountyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={streetId}
                            dataSource={streets}
                            displayExpr="name"
                            valueExpr={"id"}
                            searchEnabled={true}
                            showClearButton={true}
                            width={100}
                            placeholder="街道"
                            onValueChanged={this.onStreetChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <TextBox
                            value={customerKey}
                            showClearButton={true}
                            placeholder="客户名称或卡号"
                            onValueChanged={this.onCustomerChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                </Toolbar>
            </div>
        )
    }
}

const statusSource: ListItem<boolean>[] = [
    { value: false, text: "查看正常数据" },
    { value: true, text: "查看已作废" }
]