import { createStoreFromApi, http, BaseApiService } from "../base";
import { BottleVendorModel, BottleVendorRef } from "../models";

class BottleVendorService extends BaseApiService<BottleVendorModel> {
    moduleName = "bottlevendor";
    cacheSeconds = 60;

    getRefs(): Promise<BottleVendorRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/refs`), this.cacheSeconds);
    }
}

export const bottleVendorService = new BottleVendorService();
export const bottleVendorStore = createStoreFromApi(bottleVendorService, "code");