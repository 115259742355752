import { createStoreFromUrl, BaseApiService, http } from "../base";
import { BottleModel } from "../models";

class BottleService extends BaseApiService<BottleModel> {
    moduleName = "bottle";

    getDxData(isPorcelainCode?: boolean) {
        return createStoreFromUrl(`${this.apiPath}/dx` + 
            (typeof(isPorcelainCode) === "boolean" ? `?isPorcelainCode=${isPorcelainCode}` : ''), 'id');
    }

    publicGetByCode(code: string) {
        return http.getData<BottleModel>(`${this.apiPath}/public?code=${code}`);
    }
}

export const bottleService = new BottleService();
export const bottleStore = bottleService.getDxData();