import { Button, DateBox, SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import React from "react";
import { organizationService } from "../../../api";
import { dateAdd, enums, ListItem, today } from "../../../base";
import { OrgTreeDropDown } from "../../../components";
import { OrganizationRef } from "../../../models";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";

export interface OrderSummaryReportToolbarProps {
    onFiltering: (conditions: any) => void;
}

interface OrderSummaryReportToolbarState {
    orgs: OrganizationRef[];
    timeProperty: "orderTime" | "completedAt" | "paidAt"
    startDate?: Date | string;
    endDate?: Date | string;
    organizationId?: number;
    orderStatus?: number;
}

export default class OrderSummaryReportToolbar extends
    React.Component<OrderSummaryReportToolbarProps, OrderSummaryReportToolbarState> {
    constructor(props: Readonly<OrderSummaryReportToolbarProps>) {
        super(props);

        const startDate = today();

        this.state = {
            orgs: [],
            timeProperty: "orderTime",
            startDate: startDate,
            endDate: startDate
        }

        this.onTimePropertyChanged = this.onTimePropertyChanged.bind(this);
        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount() {
        this.loadDataSources();
    }

    async loadDataSources() {
        const orgs = await organizationService.getRefs();
        this.setState({ orgs: orgs });
    }

    onTimePropertyChanged(e: ValueChangedEvent) {
        this.setState({ timeProperty: e.value });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onOrganizationChanged(organizationId?: number) {
        this.setState({ organizationId: organizationId });
    }

    onStatusChanged(e: ValueChangedEvent) {
        this.setState({ orderStatus: e.value });
    }

    onFiltering() {
        this.props.onFiltering(this.getFilterValue());
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { timeProperty, startDate, endDate, organizationId, orderStatus } = this.state;

        if (startDate) {
            filters.push([timeProperty, ">=", startDate]);
        }
        if (endDate) {
            filters.push([timeProperty, "<", dateAdd(new Date(endDate), 1)])
        }
        if (organizationId) {
            filters.push(["organizationId", "=", organizationId]);
        }
        switch (orderStatus) {
            case 0:
                filters.push([
                    "status", 
                    "anyof", 
                    [enums.orderStatus.created, enums.orderStatus.assigned, enums.orderStatus.delivering]
                ]);
                break;
            case 1:
                filters.push([
                    "status", 
                    "anyof", 
                    [enums.orderStatus.delivery, enums.orderStatus.paid],
                ]);
                break;
            case 2:
                filters.push(["status", "=", enums.orderStatus.discard]);
                break;
        }

        return filters.length > 0 ? filters : null;
    }
    
    render() {
        const { orgs, organizationId, timeProperty, startDate, endDate, orderStatus } = this.state;

        return (
            <div className={"page-toolbar page-large-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="选择供应站过滤"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={timeProperty}
                            dataSource={timePropertySource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={100}
                            onValueChanged={this.onTimePropertyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={startDate}
                            placeholder="开始时间"
                            width={120}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束时间"
                            width={120}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={orderStatus}
                            dataSource={statusSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            showClearButton={true}
                            width={100}
                            placeholder={"状态过滤"}
                            onValueChanged={this.onStatusChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}

const timePropertySource: ListItem<string>[] = [
    { value: "orderTime", text: "下单时间" },
    { value: "completedAt", text: "完成时间" },
    { value: "paidAt", text: "回款时间" }
]

const statusSource: ListItem<number>[] = [
    { 
        value: 0, 
        text: "未送达" 
    },
    { 
        value: 1,
        text: "已送达"
    },
    {
        value: 2,
        text: "已作废"
    }
]