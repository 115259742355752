import { BaseApiService, createStoreFromUrl } from "../base";
import { FillingRecheckModel } from "../models";

class FillingRecheckService extends BaseApiService<FillingRecheckModel> {
    moduleName = 'fillingrecheck';

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id', true);
    }
}

export const fillingRecheckService = new FillingRecheckService();
export const fillingRecheckStore = fillingRecheckService.getDxData();