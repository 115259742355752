import React from "react";
import { Item } from "devextreme-react/toolbar";
import { Button } from "devextreme-react";
import { organizationService } from "../../api";
import { alertError, authorizationService, enums, notifySuccess } from "../../base";
import { AssignOrganizationPopup, ContentBlock, OrganizationProfilePopup, StdToolbar } from "../../components";
import AuthorizedComponent from "../authorized-component";
import OrganizationTreeList from "./organization-tree-list";
import OrganizationForm from "./organization-form";
import { OrganizationModel, OrganizationRef } from "../../models";

interface OrganizationState {
    orgs: OrganizationModel[];
    assignAction?: string;
    assignOrg?: OrganizationModel;
    assignCaption?: string;
}

export default class OrganizationPage extends AuthorizedComponent<any, OrganizationState> {
    objectName = "Organization";
    treeRef = React.createRef<OrganizationTreeList>();
    formRef = React.createRef<OrganizationForm>();
    toolbarRef = React.createRef<StdToolbar>();
    orgRef = React.createRef<AssignOrganizationPopup>();
    profileRef = React.createRef<OrganizationProfilePopup>();
    assignAction?: string;
    assignOrg?: OrganizationModel;

    get actionNames(): string[] {
        return [
            enums.stdActions.create,
            enums.stdActions.update,
            "ReadSpecial",
            "Move",
            "Profile"
        ]
    }

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            orgs: []
        };

        this.onOrganizationSelected = this.onOrganizationSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.renderAddItem = this.renderAddItem.bind(this);
        this.loadOrganizations = this.loadOrganizations.bind(this);
        this.assign = this.assign.bind(this);
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.loadOrganizations();
    }

    async authorize(): Promise<void> {
        await super.authorize();

        this.permissions["MoveEmployees"] =
            await authorizationService.authorize("Employee", "move");

        this.setState({});
    }

    async loadOrganizations() {
        let orgs = await organizationService.searchAll();
        if (!this.permissions["ReadSpecial"]) {
            orgs = orgs.filter(o => o.type !== enums.organizationTypes.special);
        }

        this.setState({ orgs: orgs });
    }

    onOrganizationSelected(e?: OrganizationModel) {
        this.toolbarRef.current?.setModel(e);
    }

    onButtonClick(model: OrganizationModel, name: string) {
        switch (name) {
            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.formRef.current?.show({ parentId: model?.id });
                }
                break;

            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.formRef.current?.show(model);
                }
                break;

            case "move":
                if (this.permissions["Move"]) {
                    this.setState({
                        assignOrg: model,
                        assignAction: 'move',
                        assignCaption: '移到站点到...下'
                    });
                    this.orgRef.current?.show();
                }
                break;

            case "moveemployees":
                if (this.permissions["MoveEmployees"]) {
                    this.setState({
                        assignOrg: model,
                        assignAction: 'moveemployees',
                        assignCaption: '迁移站点员工到'
                    });
                    this.orgRef.current?.show();
                }
                break;

            case "profile":
                if (this.permissions["Profile"]) {
                    this.profileRef.current?.show(model.id!);
                }
                break;
        }
    }

    async assign(org: OrganizationRef) {
        switch (this.state.assignAction) {
            case "move":
                const res = await organizationService.move(this.state.assignOrg!.id!, org.id);

                if (res.succeeded) {
                    notifySuccess("已成功移动站点");
                    this.orgRef.current?.hide();
                    this.loadOrganizations();
                } else {
                    alertError(res.message!);
                }

                break;

            case "moveemployees":
                const res2 = await organizationService.moveEmployees(this.state.assignOrg!.id!, org.id!);

                if (res2.succeeded) {
                    notifySuccess("已成功迁移员工");
                    this.orgRef.current?.hide();
                    this.loadOrganizations();
                } else {
                    alertError(res2.message!);
                }

                break;
        }
    }

    render() {
        return (
            <ContentBlock caption={"组织架构"}>
                <StdToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    itemsBefore={this.renderAddItem}
                    onButtonClick={this.onButtonClick} />
                <OrganizationTreeList
                    ref={this.treeRef}
                    organizations={this.state.orgs}
                    onButtonClick={this.onButtonClick}
                    onOrganizationSelected={this.onOrganizationSelected} />
                <OrganizationForm
                    ref={this.formRef}
                    organizations={this.state.orgs}
                    onSaved={this.loadOrganizations} />
                <AssignOrganizationPopup
                    ref={this.orgRef}
                    caption={this.state.assignCaption}
                    save={this.assign} />
                <OrganizationProfilePopup ref={this.profileRef} />
            </ContentBlock>
        );
    }

    renderAddItem(model: any) {
        return [
            (
                <Item
                    key={"addChild"}
                    location={"after"}
                    locateInMenu="auto"
                    visible={this.permissions[enums.stdActions.create]}>
                    <Button
                        disabled={!model}
                        text="添加下级组织"
                        onClick={() => this.onButtonClick(model, "add")} />
                </Item>
            ),
            (
                <Item
                    key={"move"}
                    location={"after"}
                    locateInMenu="auto"
                    visible={this.permissions["Move"]}>
                    <Button
                        disabled={!model}
                        text="移动"
                        onClick={() => this.onButtonClick(model, "move")} />
                </Item>
            ),
            (
                <Item
                    key={"moveEmployees"}
                    location={"after"}
                    locateInMenu="auto"
                    visible={this.permissions["MoveEmployees"]}>
                    <Button
                        disabled={!model}
                        text="迁移员工"
                        onClick={() => this.onButtonClick(model, "moveemployees")} />
                </Item>
            ),
            (
                <Item
                    key={"profile"}
                    location={"after"}
                    locateInMenu={"auto"}
                    visible={this.permissions["Profile"]}>
                    <Button 
                        disabled={!model} 
                        text="配置" 
                        onClick={() => this.onButtonClick(model, "profile")} />
                </Item>
            )
        ];
    }
}