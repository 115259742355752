import { HomeReportCountData } from "../../models"
import HomeCountBlock from "./home-count-block"

export interface HomeCountProps {
    data?: HomeReportCountData;
}

export default function HomeCount(props: HomeCountProps) {
    const data = props.data;

    return (
        <div className="home-count-container">
            <HomeCountBlock 
                caption="订单" 
                countText={`${data?.orders || 0}/￥${data?.orderTotalAmount || 0}`} 
                icon="bi bi-file-earmark-text" 
                colorClass="home-count-order" />
            <HomeCountBlock 
                caption="开户/退户" 
                countText={`${data?.newCustomers || 0}/${data?.exitCustomers || 0}`} 
                icon="bi bi-people" 
                colorClass="home-count-customer" />
            <HomeCountBlock 
                caption="安全检查" 
                countText={`${data?.securityChecks || 0}`} 
                icon="bi bi-shield-check" 
                colorClass="home-count-check" />
            <HomeCountBlock 
                caption="新增隐患" 
                countText={`${data?.newDangers || 0}`}
                icon="bi bi-exclamation-circle" 
                colorClass="home-count-danger" />
            <HomeCountBlock 
                caption="隐患整改" 
                countText={`${data?.fixDangers || 0}`}
                icon="bi bi-check-circle" 
                colorClass="home-count-dangerfix" />
        </div>
    )
}