import React from "react";
import { Form, Popup } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import { dataSources, enums, alertError, notifySuccess, ListItem, modelClone } from "../../base";
import { vehicleUserService, vehicleService } from "../../api";
import { setItemDataSource, PopupContentBox, RefPopup, StdForm } from "../../components";
import { OrganizationRef, VehicleModel, VehicleUserRef } from "../../models";

interface VehicleEditModel extends VehicleModel {
    drivers?: ListItem<number>[];
    supercargos?: ListItem<number>[];
    driverList?: number[];
    supercargoList?: number[];
    organizationIds?: number[];
}

export interface VehicleFormProps {
    organizations: OrganizationRef[];
    onSaved: () => void;
}

interface VehicleFormState {
    vehicle: VehicleEditModel;
    users: VehicleUserRef[];
    drivers: VehicleUserRef[];
    supercargos: VehicleUserRef[];
    items: IItemProps[];
}

export default class VehicleForm extends React.Component<VehicleFormProps, VehicleFormState> {
    moduleName = 'Vehicle';

    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();

    constructor(props: Readonly<VehicleFormProps>) {
        super(props);

        this.state = {
            vehicle: {},
            users: [],
            drivers: [],
            supercargos: [],
            items: [
                { dataField: 'vehicleNumber' },
                {
                    dataField: 'vehicleType',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                        dataSource: dataSources.vehicleTypes,
                        displayExpr: 'text',
                        valueExpr: 'value',
                    }
                },
                {
                    dataField: 'organizationIds',
                    label: { text: "站点（多选）" },
                    editorType: 'dxTagBox',
                    editorOptions: {
                        dataSource: 
                        props.organizations, 
                        displayExpr: 'name', 
                        valueExpr: 'id',
                        onValueChanged: this.setUsers.bind(this)
                    }
                }, 
                { dataField: 'startDate', editorType: 'dxDateBox', editorOptions: { type: 'date' } },
                {
                    dataField: "driverList",
                    label: { text: '司机' },
                    colSpan: 2,
                    editorType: 'dxTagBox',
                    editorOptions: {
                        dataSource: [],
                        displayExpr: 'name',
                        valueExpr: 'id',
                        searchExpr: 'name',
                        searchEnabled: true
                    }
                },
                {
                    dataField: "supercargoList",
                    label: { text: '押运员' },
                    colSpan: 2,
                    editorType: 'dxTagBox',
                    editorOptions: {
                        dataSource: [],
                        displayExpr: 'name',
                        valueExpr: 'id',
                        searchExpr: 'name',
                        searchEnabled: true
                    }
                }
            ]
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount(): void {
        this.loadUsers();
    }

    componentDidUpdate(prevProps: Readonly<VehicleFormProps>) {
        if (this.props.organizations.length !== prevProps.organizations.length) {
            setItemDataSource(this.state.items, "organizationIds", this.props.organizations);
            this.setState({});
        }
    }

    async loadUsers() {
        const users = await vehicleUserService.getRefs();
        this.setState({ users: users });
    }

    show(vehicle: VehicleEditModel) {
        this.popupRef.current?.instance.show();

        vehicle = modelClone(vehicle);
        vehicle.driverList = vehicle.drivers?.map((d: any) => d.value);
        vehicle.supercargoList = vehicle.supercargos?.map((s: any) => s.value);

        this.setState({
            vehicle: vehicle,
        });
    }

    setUsers() {
        const users = this.state.users;
        const drivers = users.filter(u => u.post !== enums.vehiclePosts.supercargo &&
            this.state.vehicle.organizationIds!.indexOf(u.organizationId || 0) !== -1);
        const supercargos = users.filter(u => u.post !== enums.vehiclePosts.driver &&
            this.state.vehicle.organizationIds!.indexOf(u.organizationId || 0) !== -1);

        setItemDataSource(this.state.items, "driverList", drivers);
        setItemDataSource(this.state.items, "supercargoList", supercargos);

        const vehicle = this.state.vehicle;
        vehicle.driverList = vehicle.driverList?.filter(vc => drivers.find(d => d.id === vc));
        vehicle.supercargoList = vehicle.supercargoList?.filter(vs => supercargos.find(s => s.id === vs));

        this.setState({
            drivers: drivers,
            supercargos: supercargos
        });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const vehicle = this.state.vehicle;
            vehicle.drivers = vehicle.driverList?.map((d: number) => ({ value: d, text: '' }));
            vehicle.supercargos = vehicle.supercargoList?.map((d: number) => ({ value: d, text: '' }));
            vehicle.organizations = vehicle.organizationIds?.map(
                (id: number) => ({ organizationId: id, vehicleId: vehicle.id }));

            delete vehicle.driverList;
            delete vehicle.supercargoList;
            delete vehicle.organizationIds;

            const res = vehicle.id ?
                await vehicleService.update(vehicle) :
                await vehicleService.create(vehicle);

            if (res.succeeded) {
                this.hide();
                notifySuccess("已成功保存");
                this.props.onSaved();
            } else {
                vehicle.driverList = vehicle.drivers?.map((d: any) => d.value);
                vehicle.supercargoList = vehicle.supercargos?.map((s: any) => s.value);

                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"编辑"}
                width={800}
                height={360}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        formData={this.state.vehicle} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}