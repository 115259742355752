import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import { ContentBlock, createGridOptions, stdReadOnlyGridOptions, StdGrid, StdToolbar } from "../../components";
import { dataSourceStore } from "../../api";
import { dataSources, enums } from "../../base";
import AuthorizedComponent from "../authorized-component";
import { DataSourceModel } from "../../models";

export default class DataSourcePage extends AuthorizedComponent {
    objectName = "DataSource";
    moduleName = "DataSource";
    gridRef = React.createRef<DataGrid>();
    toolbarRef = React.createRef<StdToolbar>();
    gridOptions = createGridOptions("dataSources", this.gridRef, stdReadOnlyGridOptions);
    columns: IColumnProps[] = [
        { 
            dataField: 'type',
            lookup: { dataSource: dataSources.dataSourceTypes, displayExpr: 'text', valueExpr: 'value' }
        },
        { dataField: 'value' },
        { dataField: 'orderNumber' }
    ];

    constructor(props: Readonly<any>) {
        super(props);

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.onButtonClick(e.data, "edit");
        this.onButtonClick = this.onButtonClick.bind(this);
    }
    
    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.toolbarRef.current?.setModel(e.selectedRowsData[0]);
        } else {
            this.toolbarRef.current?.setModel({});
        }
    }

    onButtonClick(model: DataSourceModel, name: string) {
        const grid = this.gridRef.current?.instance;
        
        if (grid) {
            switch (name) {
                case "add":
                    if (this.permissions[enums.stdActions.create]) {
                        grid.addRow();
                    }
                    break;

                case "edit":
                    if (this.permissions[enums.stdActions.update]) {
                        grid.editRow(grid.getRowIndexByKey(model.id));
                    }
                    break;
                
                case "delete":
                    if (this.permissions[enums.stdActions.delete]) {
                        grid.deleteRow(grid.getRowIndexByKey(model.id));
                    }
                    break;
            }
        }
    }

    render() {
        return (
            <ContentBlock caption={"数据源"}>
                <StdToolbar 
                    permissions={this.permissions}
                    ref={this.toolbarRef} 
                    onButtonClick={this.onButtonClick} />
                <StdGrid
                    moduleName={this.moduleName}
                    gridRef={this.gridRef}
                    dataSource={dataSourceStore}
                    options={this.gridOptions}
                    columns={this.columns}
                    popupWidth={400}
                    popupHeight={280}
                    formColCount={1} />
            </ContentBlock>
        );
    }
}