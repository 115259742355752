import { createStoreFromUrl, http, MessageBool } from "../base";

class WebMessageService {
    apiPath = "/api/webmessage";

    count() {
        return http.getData<number>(`${this.apiPath}/count`);
    }

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }

    read(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/read/${id}`, '');
    }

    readAll() {
        return http.putData<MessageBool>(`${this.apiPath}/readall`, '');
    }
}

export const webMessageService = new WebMessageService();
export const webMessageStore = webMessageService.getDxData();