import { createContext, useContext } from "react";
import { ServerData } from '../base';
import { serverDataService } from '../api';

export interface ServerDataContextType {
    serverData?: ServerData;
}
 
const ServerDataContext = createContext<ServerDataContextType>({});
const useServerData = () => useContext(ServerDataContext);

function ServerDataProvider(props: any) {
    return (
        <ServerDataContext.Provider value={{ serverData: serverDataService.serverData }} {...props} />
    )
}

export {
    ServerDataContext,
    ServerDataProvider,
    useServerData
}