import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import { alertError, confirm2, dataSources, enums, notifySuccess } from "../../base";
import { createGridOptions, setColumnDataSource, StdGrid, remoteReadOnlyGridOptions } from "../../components";
import { organizationService, dataSourceService, roleService, employeeService, employeeDefaultStore } from "../../api";
import { EmployeeModel, OrganizationRef, RoleRef } from "../../models";
import CustomStore from "devextreme/data/custom_store";

export interface EmployeeGridProps {
    onButtonClick: (employee: EmployeeModel, name: string) => void;
    onEmployeeSelected: (employees: EmployeeModel[]) => void;
}

interface EmployeeGridState {
    orgs: OrganizationRef[];
    roles: RoleRef[];
    customPosts: string[];
    columns: IColumnProps[];
    store: CustomStore;
}

export default class EmployeeGrid extends React.Component<EmployeeGridProps, EmployeeGridState> {
    moduleName = "Employee";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("employees", this.gridRef, {
        ...remoteReadOnlyGridOptions,
        stateStoring: {
            customLoad: function () {
                const value = localStorage.getItem(`grid-options-employees`);
                const state = value ? JSON.parse(value) : null;
                if (state) {
                    state.columns.find((c: any) => c.dataField === "status").filterValue = enums.userStatus.active;
                }
                return state;
            },
            customSave: remoteReadOnlyGridOptions.stateStoring?.customSave
        }
    });

    constructor(props: Readonly<EmployeeGridProps>) {
        super(props);

        this.state = {
            orgs: [],
            roles:[],
            customPosts: [],
            store: employeeDefaultStore,
            columns: [
                { dataField: 'code' },
                { dataField: 'name' },
                {
                    allowFiltering: false,
                    dataField: 'organizationId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id', allowClearing: true }
                },
                { dataField: 'sortNumber' },
                {
                    dataField: 'status',
                    filterValue: enums.userStatus.active,
                    lookup: { dataSource: dataSources.userStatus, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: 'phoneNumber' },
                {
                    dataField: 'post',
                    lookup: { dataSource: dataSources.employeePosts, displayExpr: 'text', valueExpr: 'value' }
                },
                {
                    dataField: 'roleName',
                    allowSorting: false,
                    allowFiltering: false,
                    allowGrouping: false
                },
                { dataField: 'idNo' },
                { dataField: 'certNumber' },
                { dataField: 'certExpiration' },
                { 
                    dataField: 'weiXinOpenId',
                    caption: '微信',
                    cellRender: (e: any) => {
                        return e.data.weiXinOpenId ? (
                            <span>
                                <span>已绑定</span> &nbsp;
                                <span className="link-button" onClick={() => this.unbindWeiXin(e.data)}>解绑</span>
                            </span>
                            ) : "";
                    }
                },
                // { dataField: 'address' },
                { dataField: 'note', formItem: { colSpan: 2 } }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let orgs: OrganizationRef[] = [], roles: RoleRef[] = [], customPosts: string[] = [];

        await Promise.all([
            (async () => orgs = await organizationService.getRefs())(),
            (async () => roles = await roleService.getRefs())(),
            (async () => customPosts = await dataSourceService.getDataSource(enums.dataSourceTypes.customPosts))()
        ]);

        setColumnDataSource(this.state.columns, "organizationId", orgs);
        setColumnDataSource(this.state.columns, "post",
            [...dataSources.employeePosts, ...(customPosts || []).map(p => ({ text: p, value: p }))])

        this.setState({
            orgs: orgs,
            roles: roles,
            customPosts: customPosts
        });
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        this.props.onEmployeeSelected(e.selectedRowsData);
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    onRoleChanged(roleId?: number) {
        if (roleId) {
            this.setState({ store: employeeService.getDxData(roleId) });
        } else {
            this.setState({ store: employeeDefaultStore });
        }        
    }

    filter(condition: any) {
        this.gridRef.current?.instance.filter(condition);
    }

    async unbindWeiXin(employee: EmployeeModel) {
        if (!await confirm2("确定要解绑该员工的微信吗？")) {
            return;
        }

        const res = await employeeService.unbindWeiXin(employee.weiXinOpenId!);

        if (res.succeeded) {
            notifySuccess("解绑成功");
            this.refresh();
        } else {
            alertError(res.message!);
        }
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns} >
            </StdGrid>
        )
    }
}