import { Button, TagBox } from "devextreme-react";
import Form, { IItemProps } from "devextreme-react/form";
import React from "react";
import { FormMultiImageUploader, StdForm } from "../form";
import { customerService, serverDataService } from "../../api";
import { alertError, confirm2, notifySuccess, prompt2, dataSources, enums, stdSeparator, authorizationService } from "../../base";
import { ScreenSize, ScreenSizeContext } from "../../hooks";
import { DataSources, emptyDataSources, loadCustomerDataSources } from "../../api/data-source-helper";
import { Panel } from "../layouts";
import { CustomerModel } from "../../models";
import { ValueChangedEvent } from "devextreme/ui/tag_box";
import DataSource from "devextreme/data/data_source";

export interface CustomerDetailProps {
    customer: CustomerModel;
    onCustomerChanged: (key: { id?: number; phone?: string }) => void;
}

interface CustomerDetailState {
    dataSources: DataSources;
}

export default class CustomerDetail extends React.Component<CustomerDetailProps, CustomerDetailState> {
    moduleName = "Customer";
    formRef = React.createRef<Form>();

    items: IItemProps[];

    constructor(props: Readonly<CustomerDetailProps>) {
        super(props);

        this.state = {
            dataSources: emptyDataSources()
        };

        this.onCountyChanged = this.onCountyChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.switchCustomer = this.switchCustomer.bind(this);
        this.save = this.save.bind(this);
        this.activate = this.activate.bind(this);
        this.switchLock = this.switchLock.bind(this);
        this.inactivate = this.inactivate.bind(this);
        this.renderMain = this.renderMain.bind(this);

        this.items = [
            { dataField: 'gasCode', editorOptions: { readOnly: false } },
            { dataField: 'name' },
            {
                dataField: 'typeId',
                editorType: 'dxSelectBox',
                editorOptions: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
            },
            {
                dataField: 'status',
                disabled: true,
                editorType: 'dxSelectBox',
                editorOptions: { dataSource: dataSources.customerStatus, displayExpr: 'text', valueExpr: 'value' }
            },
            { dataField: 'phoneNumber' },
            { dataField: 'phoneNumber2' },
            {
                dataField: 'areaId',
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    displayExpr: 'name',
                    valueExpr: 'id',
                    onValueChanged: this.onCountyChanged
                }
            },
            {
                dataField: 'streetId',
                editorType: 'dxSelectBox',
                editorOptions: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
            },
            { dataField: 'address' },
            { dataField: 'personInCharge' },
            { dataField: 'note', colSpan: 2 },
            { dataField: 'idNumber' },
            { dataField: 'businessLicense' },
            {
                dataField: 'addressType',
                editorType: 'dxSelectBox',
                editorOptions: { dataSources: [] }
            },
            {
                dataField: 'organizationId',
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    displayExpr: 'name',
                    valueExpr: 'id',
                    onValueChanged: this.onOrganizationChanged
                }
            },
            {
                dataField: 'deliverId',
                editorType: 'dxSelectBox',
                editorOptions: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
            },
            { dataField: 'contractNumber' },
            { dataField: 'contractDate', editorType: "dxDateBox" },
            {
                dataField: 'contractPhoto',
                label: { text: "合同照片" },
                render: (e: any) => {
                    return (
                        <FormMultiImageUploader
                            formData={this.props.customer}
                            dataField={"contractPhoto"}
                            sourceField={"contractPhotoSource"}
                            id={"contractPhoto"} />
                    )
                }
            },
            { dataField: 'securityCheckTime', editorType: "dxDateBox", disabled: true },
            {
                name: "activatedDate",
                dataField: 'activatedDate',
                editorType: "dxDateBox",
                editorOptions: {
                    readOnly: true,
                    onEnterKey: () => this.editActivatedDate()
                }
            },
            { dataField: 'rentReceiveTime', editorType: "dxDateBox", disabled: true },
            { dataField: 'exitTime', editorType: 'dxDateBox', disabled: true },
            { dataField: 'exitNote', disabled: true },
            { dataField: 'code' },
            {
                dataField: 'idNoPhoto',
                label: { text: "证件照片" },
                render: (e: any) => {
                    return (
                        <FormMultiImageUploader
                            formData={this.props.customer}
                            dataField={"idNoPhoto"}
                            sourceField={"idNoPhotoSource"}
                            id={"idNoPhoto"} />
                    )
                }
            },
            {
                dataField: 'alerter',
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: dataSources.alerterStatus,
                    displayExpr: 'text',
                    valueExpr: 'value',
                    showClearButton: true
                }
            },
            { dataField: 'alerterNumber' },
            { 
                dataField: 'isolatedStoreroom',
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: dataSources.trueFalseList,
                    displayExpr: 'text',
                    valueExpr: 'value',
                    showClearButton: true
                } 
            },
            ...serverDataService.bottleTypes.map((t: any) => ({
                dataField: `discount_${t.id}`,
                label: { text: `${t.name}优惠折扣` }
            })),
            {
                dataField: 'label',
                colSpan: 2,
                render: (args: any) => {
                    const defaultValue = this.props.customer.labels ?
                        this.props.customer.labels.map((l: any) => l.labelId) : [];
                    const store = new DataSource({
                        store: this.state.dataSources.labels,
                        key: 'id',
                        group: 'groupName'
                    });

                    return (
                        <TagBox
                            dataSource={store}
                            defaultValue={defaultValue}
                            valueExpr={"id"}
                            displayExpr={"labelText"}
                            grouped={true}
                            groupRender={(e: any) => e.key || "未分类"}
                            onValueChanged={this.onTagValueChanged.bind(this)}
                        />
                    )
                }
            },
        ];
    }

    componentDidMount() {
        this.loadDataSources();
    }

    async loadDataSources() {
        const dataSources = await loadCustomerDataSources();
        const customer = this.props.customer;

        this.setDataSource({
            "typeId": dataSources.types,
            "areaId": dataSources.counties,
            "streetId": dataSources.streets.filter(s => s.parentId === customer.areaId),
            "organizationId": dataSources.orgs,
            "deliverId": dataSources.employees.filter(e => e.organizationId === customer.organizationId),
            "addressType": dataSources.addressTypes
        });

        this.setState({ dataSources: dataSources });
    }

    setDataSource(map: { [key: string]: any[] }) {
        for (const key in map) {
            const options = this.items.find(i => i.dataField === key)!.editorOptions;
            options.dataSource = map[key];
        }
    }

    async editActivatedDate() {
        const options = this.items.find(i => i.dataField === "activatedDate")!.editorOptions;
        if (options.readOnly) {
            const authorized = await authorizationService.authorize("Customer", "SpecialEdit");
            if (authorized) {
                options.readOnly = false;
                this.setState({});
            }
        } else {
            options.readOnly = true;
            this.setState({});
        }
    }

    componentDidUpdate(prevProps: CustomerDetailProps) {
        if ((this.props.customer.id && this.props.customer.id !== prevProps.customer.id) ||
            (!this.props.customer.id && prevProps.customer.id)) {
            this.setStreets();
            this.setDelivers();
            this.setDiscounts();

            this.items.find(i => i.dataField === "gasCode")!.editorOptions.readOnly = !!this.props.customer.id;
            this.setState({});
        }
    }

    setStreets() {
        const filteredStreets = this.state.dataSources.streets
            .filter(s => s.parentId === this.props.customer.areaId);

        if (!filteredStreets.find(s => s.id === this.props.customer.streetId)) {
            this.props.customer.streetId = undefined;
        }

        this.setDataSource({ "streetId": filteredStreets });
    }

    setDelivers() {
        const filteredDelivers = this.state.dataSources.employees
            .filter(d => d.organizationId === this.props.customer.organizationId);

        if (!filteredDelivers.find(d => d.id === this.props.customer.deliverId)) {
            this.props.customer.deliverId = undefined;
        }

        this.setDataSource({ "deliverId": filteredDelivers });
    }

    setDiscounts() {
        const customer: any = this.props.customer;

        for (const discount of customer.discounts || []) {
            customer[`discount_${discount.bottleTypeId}`] = discount.discountValue;
        }
    }

    onCountyChanged() {
        this.setStreets();
        this.setState({});
    }

    onOrganizationChanged() {
        this.setDelivers();
        this.setState({});
    }

    onTagValueChanged(e: ValueChangedEvent) {
        const groups = e.value
            .filter((v: number) => e.previousValue.indexOf(v) === -1)
            .map((v: number) => this.state.dataSources.labels.find(l => l.id === v)?.groupName)
            .filter((g: string) => g);

        const toRemove = e.previousValue
            .map((v: number) => this.state.dataSources.labels.find(l => l.id === v))
            .filter((l: any) => groups.indexOf(l.groupName) !== -1)
            .map((l: any) => l.id);

        const newValues = e.value.filter((v: number) => toRemove.indexOf(v) === -1);

        const labels = this.state.dataSources.labels.filter(l => newValues.indexOf(l.id) !== -1);

        const text = labels.map(l => l.labelText).join(stdSeparator);
        const customer = this.props.customer;

        customer.label = text;
        customer.labels = labels.map(l => ({ customerId: customer.id, labelId: l.id }));

        // 会再次引发事件，一定要判断条件
        if (toRemove.length > 0) {
            e.component.option("value", newValues);
        }
    }

    async switchCustomer() {
        const codeOrPhone = await prompt2("请输入客户购气卡号或电话查询");

        if (codeOrPhone) {
            this.props.onCustomerChanged({ phone: codeOrPhone });
        }
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();
        if (!isValid?.isValid) {
            return;
        }

        const customer: any = this.props.customer;

        // 优惠项
        if (!customer.discounts) {
            customer.discounts = [];
        }

        for (const key in customer) {
            if (key.startsWith("discount_")) {
                const bottleTypeId = parseInt(key.substring("discount_".length));
                let discount = customer.discounts.find((d: any) => d.bottleTypeId === bottleTypeId);

                if (!discount) {
                    discount = { customerId: customer.id, bottleTypeId: bottleTypeId };
                    customer.discounts.push(discount);
                }

                discount.discountValue = customer[key];
            }
        }

        const result = customer.id ?
            await customerService.update(customer) :
            await customerService.create(customer);

        if (result.succeeded) {
            notifySuccess("已成功保存客户信息");
            this.props.onCustomerChanged({ id: customer.id || (result as any).data.id });
        } else {
            alertError(result.message!);
        }
    }

    async activate() {
        if (await confirm2("确定要恢复该客户吗？")) {
            const result = await customerService.activate(this.props.customer.id!);

            if (result.succeeded) {
                notifySuccess("已成功恢复客户");
                this.props.onCustomerChanged({ id: this.props.customer.id });
            } else {
                alertError(result.message!);
            }
        }
    }

    async inactivate() {
        const reason = await prompt2("请输入退户原因（必填）");

        if (reason) {
            const result = await customerService.inactivate(this.props.customer.id!, reason);

            if (result.succeeded) {
                notifySuccess("已成功办理退户");
                this.props.onCustomerChanged({ id: this.props.customer.id });
            } else {
                alertError(result.message!);
            }
        }
    }

    async switchLock() {
        const customer = this.props.customer;
        const locked = customer.locked,
            action = locked ? "解锁" : "锁定";

        const note = await prompt2(`确定要${action}该客户吗？请输入备注（可选）`);

        if (note === undefined) {
            return;
        }

        const result = await customerService.switchLock(customer.id!, note);

        if (result.succeeded) {
            notifySuccess(`已成功${action}客户`);
            this.props.onCustomerChanged({ id: this.props.customer.id });
        } else {
            alertError(result.message!);
        }
    }

    render() {
        return (
            <ScreenSizeContext.Consumer>
                {this.renderMain}
            </ScreenSizeContext.Consumer>
        )
    }

    renderMain(context: ScreenSize) {
        const colCount = 2;//context.isLarge ? 4 : context.isMedium ? 2 : 2;
        const customer = this.props.customer;

        return (
            <Panel caption="客户详情" height="100%">
                <StdForm
                    moduleName={this.moduleName}
                    formRef={this.formRef}
                    colCount={colCount}
                    items={this.items}
                    formData={customer}
                    options={{ labelLocation: "top" }} />
                <div className={"buttonbar"}>
                    <Button text="...更换客户" type="default" stylingMode="outlined" onClick={this.switchCustomer} />
                    <Button text="保存更改" type="default" onClick={this.save} />
                    {customer.status === enums.customerStatus.inactive && (
                        <Button text="恢复" type="danger" onClick={this.activate} />
                    )}
                    {customer.status === enums.customerStatus.active && (
                        <Button text="退户" type="danger" onClick={this.inactivate} />
                    )}
                    {customer.locked &&
                        <Button text="解锁" type="danger" onClick={this.switchLock} />
                    }
                </div>
            </Panel>
        );
    }
}