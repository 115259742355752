import { PivotGrid } from "devextreme-react";
import { Export, FieldChooser, Scrolling } from "devextreme-react/pivot-grid";
import PivotGridDataSource, { Field } from "devextreme/ui/pivot_grid/data_source";
import { serverDataService } from "../../../api";
import { orderReportService } from "../../../api/reports";
import { ContentBlock } from "../../../components";
import { getGridHeight, ScreenSize, ScreenSizeContext } from "../../../hooks";
import AuthorizedComponent from "../../authorized-component";
import OrderSummaryReportToolbar from "./order-summary-report-toolbar";

interface OrderSummaryReportState {
    report?: any;
    dataSource?: PivotGridDataSource
}

export default class OrderSummaryReport extends AuthorizedComponent<any, OrderSummaryReportState> {
    objectName = "Order";

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {};

        this.onFiltering = this.onFiltering.bind(this);
    }

    async onFiltering(filterValue: any) {
        const report = await orderReportService.getSummary(filterValue);

        this.setState({ 
            report: report,
            dataSource: new PivotGridDataSource({
                fields: [
                    {
                        caption: "站点",
                        dataField: "organizationName",
                        area: "row",
                        expanded: true,
                        width: 180,
                    },
                    {
                        caption: "人员",
                        dataField: "deliverName",
                        area: "row",
                        expanded: true,
                        width: 160,
                    },
                    {
                        caption: "用户类型",
                        dataField: "customerTypeName",
                        area: "row",
                        expanded: true,
                        width: 120,
                    },
                    ...serverDataService.bottleTypes.map<Field>(t => ({
                        caption: t.name!,
                        dataField: `bottleAmount.${t.id}`,
                        dataType: "number",
                        summaryType: "sum",
                        area: "data"                        
                    })),
                    {
                        caption: "金额",
                        dataField: "totalPrice",
                        dataType: "number",
                        summaryType: "sum",
                        format: "currency",
                        area: "data"
                    }
                ],
                store: report
            })
        });
    }

    render() {
        return (
            <ScreenSizeContext.Consumer>
                {context => this.renderMain(context)}
            </ScreenSizeContext.Consumer>
        )
    }
    
    renderMain(size: ScreenSize) {
        const height = getGridHeight(size);

        return (
            <ContentBlock caption="销售汇总报表">
                <OrderSummaryReportToolbar onFiltering={this.onFiltering} />
                {this.state.dataSource && (
                    <PivotGrid
                        dataSource={this.state.dataSource} 
                        height={height} 
                        allowExpandAll={true}>
                        <Scrolling mode={"virtual"} />
                        <FieldChooser enabled={false} />
                        <Export enabled={true} fileName={"销售汇总报表.xlsx"} />
                    </PivotGrid>
                )}                    
            </ContentBlock>
        )
    }
}