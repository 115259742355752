import React from "react";
import { enums, fdate, today } from "../../../base";
import { ActiveCustomerReportCondition, AreaRef, CustomerTypeRef, OrganizationRef, PredefinedLabelRef } from "../../../models";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { Button, DateBox, SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { OrgTreeDropDown } from "../../../components";
import { areaService, customerTypeService, organizationService, predefinedLabelService } from "../../../api";
import { ValueChangedEvent } from "devextreme/ui/select_box";

export interface ActiveCustomerToolbarProps {
    onFiltering: (condition: ActiveCustomerReportCondition) => void;
    onExporting: (condition: ActiveCustomerReportCondition) => void;
}

interface ActiveCustomerToolbarState {
    orgs: OrganizationRef[];
    counties: AreaRef[];
    allStreets: AreaRef[];
    streets: AreaRef[];
    customerTypeLabels: PredefinedLabelRef[];
    customerTypes: CustomerTypeRef[];
    condition: ActiveCustomerReportCondition;
}

export default class ActiveCustomerToolbar extends
    React.Component<ActiveCustomerToolbarProps, ActiveCustomerToolbarState> {

    constructor(props: Readonly<ActiveCustomerToolbarProps>) {
        super(props);

        const day = today();

        this.state = {
            orgs: [],
            counties: [],
            allStreets: [],
            streets: [],
            customerTypeLabels: [],
            customerTypes: [],
            condition: {
                startDate: fdate(new Date(day.getFullYear(), day.getMonth(), 1)),
                endDate: fdate(day)
            }
        };

        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onCountyChanged = this.onCountyChanged.bind(this);
        this.onStreetChanged = this.onStreetChanged.bind(this);
        this.onCustomerTypeLabelChanged = this.onCustomerTypeLabelChanged.bind(this);
        this.onCustomerTypeChanged = this.onCustomerTypeChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.onExporting = this.onExporting.bind(this);
    }

    componentDidMount(): void {
        this.loadDataSources();
    }

    async loadDataSources() {
        let orgs: OrganizationRef[] = [],
            counties: AreaRef[] = [],
            streets: AreaRef[] = [],
            customerTypeLabels: PredefinedLabelRef[] = [],
            customerTypes: CustomerTypeRef[] = [];

        await Promise.all([
            (async () => orgs = await organizationService.getRefs())(),
            (async () => counties = await areaService.getCounties())(),
            (async () => streets = await areaService.getStreets())(),
            (async () => customerTypeLabels = 
                await predefinedLabelService.getByDataType(enums.labelDataTypes.customerType))(),
            (async () => customerTypes = await customerTypeService.getRefs())()
        ]);

        this.setState({
            orgs: orgs,
            counties: counties,
            allStreets: streets,
            streets: streets,
            customerTypeLabels: customerTypeLabels,
            customerTypes: customerTypes
        });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.updateCondition({ startDate: e.value });
    }

    private updateCondition(updated: any) {
        const cond = { ...this.state.condition, ...updated };
        cond.startDate = fdate(cond.startDate);
        cond.endDate = fdate(cond.endDate);

        this.setState({
            condition: cond
        });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.updateCondition({ endDate: e.value });
    }

    onOrganizationChanged(organizationId?: number) {
        this.updateCondition({ organizationId: organizationId });
    }

    onCustomerTypeLabelChanged(e: ValueChangedEvent) {
        this.updateCondition({ typeLabelId: e.value });
    }

    onCustomerTypeChanged(e: ValueChangedEvent) {
        this.updateCondition({ typeId: e.value });
    }

    onCountyChanged(e: ValueChangedEvent) {
        const streets = e.value ?
            this.state.allStreets.filter(s => s.parentId === e.value) :
            this.state.allStreets;

        const streetId = streets.find(s => s.id === this.state.condition.streetId) ?
            this.state.condition.streetId : undefined;

        this.setState({ streets: streets }, 
            () => this.updateCondition({
                countyId: e.value,
                streetId: streetId
            })
        );
    }

    onStreetChanged(e: ValueChangedEvent) {
        this.updateCondition({ streetId: e.value });
    }

    onFiltering() {
        this.props.onFiltering(this.state.condition);
    }

    onExporting() {
        this.props.onExporting(this.state.condition);
    }

    render() {
        const { condition, orgs, counties, customerTypes, streets, customerTypeLabels } = this.state;

        return (
            <div className="page-toolbar">
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={condition.startDate}
                            placeholder="开始时间"
                            width={120}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={condition.endDate}
                            placeholder="结束时间"
                            width={120}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={condition.organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={condition.typeLabelId}
                            dataSource={customerTypeLabels}
                            displayExpr="labelText"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder={"客户分组"}
                            onValueChanged={this.onCustomerTypeLabelChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={condition.typeId}
                            dataSource={customerTypes}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder={"客户类型"}
                            onValueChanged={this.onCustomerTypeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={condition.countyId}
                            dataSource={counties}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder={"行政区域"}
                            onValueChanged={this.onCountyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={condition.streetId}
                            dataSource={streets}
                            displayExpr="name"
                            valueExpr={"id"}
                            searchEnabled={true}
                            showClearButton={true}
                            width={100}
                            placeholder="街道"
                            onValueChanged={this.onStreetChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            text="导出"
                            type="default"
                            onClick={this.onExporting} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}