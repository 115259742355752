import React from "react";
import { authorizationService, DataMap, enums } from "../base";

export default abstract class AuthorizedComponent<TProp = any, TState = any> extends React.Component<TProp, TState> {
    permissions: DataMap<boolean> = {};

    abstract get objectName(): string;

    get actionNames() {
        return [enums.stdActions.create, enums.stdActions.update, enums.stdActions.delete];
    }
    
    componentDidMount(): void {
        this.authorize();
    }

    async authorize() {
        Object.assign(this.permissions, await authorizationService.authorizeAll(this.objectName, this.actionNames));
        this.setState({});
    }
}