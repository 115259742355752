import Form, { Item, Label, RequiredRule } from "devextreme-react/form";
import Popup from "devextreme-react/popup";
import { useCallback, useRef, useState } from "react";
import { adminService, MockData } from "../../api";
import { PopupContentBox } from "../layouts";
import { alertError, tokenStorage } from "../../base";

export interface MockProps {
    visible: boolean;
    onHiding: (success?: boolean) => void;
}

export default function MockPopup(props: MockProps) {
    const [loading, setLoading] = useState(false);
    const formData = useRef<MockData>({});
    const formRef = useRef<Form|null>(null);

    const save = useCallback(async () => {
        if (formRef.current) {
            const valid = formRef.current.instance.validate();
            if (!valid.isValid) {
                return;
            }

            setLoading(true);

            const result = await adminService.mock(formData.current);

            setLoading(false);

            if (result.succeeded) {
                formData.current = {};
                tokenStorage.token = result.data!;
                props.onHiding(true);
            } else {
                alertError(result.message!);
            }
        }
    }, [formRef, formData, props]);

    const cancel = useCallback(() => {
        props.onHiding();
    }, [props]);

    return (
        <Popup
            visible={props.visible}
            showCloseButton={true}
            width={350}
            height={180}
            title={"模拟用户"}
            onHiding={cancel}>
            <PopupContentBox saveButtonText="确定" onSave={save} onCancel={cancel}>
                <Form 
                    ref={formRef} 
                    formData={formData.current} 
                    disabled={loading} 
                    onEditorEnterKey={save}>
                    <Item
                        dataField={'username'}
                        editorType={'dxTextBox'}
                        editorOptions={{ placeholder: "输入要模拟的用户名" }}>
                        <RequiredRule message="用户名不能为空" />
                        <Label visible={false} />
                    </Item>
                </Form>
            </PopupContentBox>
        </Popup>
    )
}