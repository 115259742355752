import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import React from "react";
import { cpScrapService, cpScrapStore } from "../../api";
import { createEmptyStore, DataMap, ModuleConfig } from "../../base";
import { createGridOptions, remoteReadOnlyGridOptions, StdGrid } from "../../components";
import { CpScrapModel } from "../../models";

export interface CpScrapGridProps {
    deferLoad: boolean;
    permissions: DataMap<boolean>;
    onModelSelected: (model: CpScrapModel) => void;
    onRowChecked: (ids: number[]) => void;
    onButtonClick: (model: CpScrapModel, name: string) => void;
}

interface CpScrapGridState {
    store: CustomStore;
    columns: IColumnProps[];
}

export default class CpScrapGrid extends React.Component<CpScrapGridProps, CpScrapGridState> {
    moduleName: ModuleConfig = {
        moduleName: "CpScrap",
        children: {
            "batch": "CpBatch"
        } 
    };

    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("cpScraps", this.gridRef, remoteReadOnlyGridOptions);

    constructor(props: Readonly<CpScrapGridProps>) {
        super(props);

        this.state = {
            store: props.deferLoad ? createEmptyStore() : cpScrapStore,
            columns: [
                { dataField: "createdAt", dataType: "datetime" },
                { dataField: "updatedAt", dataType: "datetime" },
                { dataField: "scrapDate", dataType:"date" },
                { dataField: "vendor" },
                { dataField: "produceDate", dataType: "date" },
                { dataField: "enterDate", dataType: "date" },
                { dataField: "bottleType" },
                { dataField: "code" },
                { dataField: "batch.batchCode" },
                { dataField: "checker" },
                { dataField: "organization" },
                { dataField: "note" }
            ]
        }

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = (e) => this.props.onButtonClick(e.data, "edit");
    }

    load(batchId: number) {
        this.setState({
            store: cpScrapService.getDxData(batchId)
        });
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});    // 为避免混乱，多选不能编辑
        }

        this.props.onRowChecked(e.selectedRowKeys);
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns} />
        )
    }
}