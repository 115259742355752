import { baseApiUrl } from './base-values';
import { tokenStorage } from './token-storage';
import { alertError } from './dialogs';
import { tokenService } from './token.service';

async function httpData(url: string, method: string, data?: any, rawData?: boolean) {
    const header: any = { 'content-type': 'application/json' };
    const token: string = tokenStorage.token;
    if (token) {
        header.Authorization = `bearer ${token}`;
    }

    const res = await fetch(url, {
        body: JSON.stringify(data),
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: header,
        method: method,
        mode: 'cors',
        redirect: 'follow'
    });

    if (res.ok) {
        return rawData ? res.text() : res.json();
    } else {
        if (res.status === 401) {
            tokenService.clearStorage();
        } else if (res.status === 403) {
            alertError("您没有权限访问该模块");
        } else {
            alertError("服务器发生了异常，请与管理员联系");
        }
    }
}

function serialize(params: any[]) {
    const combined: any = Object.assign({}, ...params);
    const arr: string[] = [];

    for (const key in combined) {
        const value = combined[key] === null || combined[key] === undefined ? '' : combined[key];
        arr.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }

    return arr.join('&');
}

function getData<T = any>(url: string, ...params: any[]): Promise<T> {
    if (params.length) {
        url = url + (url.indexOf('?') !== -1 ? '&' : '?') + serialize(params)
    }
    return httpData(`${baseApiUrl}${url}`, 'GET')
}

function postData<T = any>(url: string, data: any): Promise<T> {
    return httpData(`${baseApiUrl}${url}`, 'POST', data)
}

function putData<T = any>(url: string, data: any): Promise<T> {
    return httpData(`${baseApiUrl}${url}`, 'PUT', data)
}

function deleteData<T = any>(url: string): Promise<T> {
    return httpData(`${baseApiUrl}${url}`, 'DELETE')
}

export const http = {
    getData: getData,
    postData: postData,
    putData: putData,
    deleteData: deleteData,
    httpData: httpData,
    serialize: serialize
}
