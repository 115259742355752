import { createStoreFromApi, http, BaseApiService } from "../base";
import { SecurityCheckTypeModel, SecurityCheckTypeRef } from "../models";

class SecurityCheckTypeService extends BaseApiService<SecurityCheckTypeModel> {
    moduleName = "securitychecktype";
    cacheSeconds = 60;

    getRefs(): Promise<SecurityCheckTypeRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/refs`), this.cacheSeconds);
    }
}

export const securityCheckTypeService = new SecurityCheckTypeService();
export const securityCheckTypeStore = createStoreFromApi(securityCheckTypeService);