import React from "react";
import { alert2, modalIcons } from "../../base";
import { ContentBlock } from "../../components";
import { SysLogModel } from "../../models";
import AuthorizedComponent from "../authorized-component";
import SysLogGrid from "./sys-log-grid";
import SysLogToolbar from "./sys-log-toolbar";

export default class SysLogPage extends AuthorizedComponent {
    objectName = "SysLog";
    gridRef = React.createRef<SysLogGrid>();
    toolbarRef = React.createRef<SysLogToolbar>();

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    onModelSelected(model: SysLogModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onFiltering(filterValue: any) {
        this.gridRef.current?.filter(filterValue);
    }

    onButtonClick(model: SysLogModel, name: string) {
        switch (name) {
            case "logdata":
                alert2(model.logData!, { icon: modalIcons.info, title: '日志详情' });
                break;
                
            case "desc":
                alert2(model.desc!, { icon: modalIcons.info, title: '日志描述' });
                break;
        }
    }

    render() {
        return (
            <ContentBlock caption="系统日志">
                <SysLogToolbar
                    ref={this.toolbarRef}
                    onFiltering={this.onFiltering}
                    onButtonClick={this.onButtonClick} />
                <SysLogGrid
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
            </ContentBlock>
        )
    }
}