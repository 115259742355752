import React from "react";
import { ExportingEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { ModuleConfig, dataSources, authorizationService, enums, baseApiUrl, globalPopupManager, globalPopupNames, createEmptyStore } from "../../base";
import { shippingService, shippingStore } from "../../api";
import { remoteReadOnlyGridOptions, createGridOptions, StdGrid, ExportModel } from "../../components";
import { ShippingModel } from "../../models";
import CustomStore from "devextreme/data/custom_store";

export interface ShippingGridProps {
    onButtonClick: (shipping: ShippingModel, name: string) => void;
    onShippingSelected: (shipping: ShippingModel) => void;
}

export interface ShippingGridState {
    store: CustomStore;
}

export default class ShippingGridPage extends React.Component<ShippingGridProps, ShippingGridState> {
    objectName = "Shipping";
    moduleName: ModuleConfig = {
        moduleName: "Shipping",
        children: {
            "vehicle": "Vehicle"
        }
    };

    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("shippings", this.gridRef, remoteReadOnlyGridOptions);

    columns: IColumnProps[] = [
        { dataField: 'shippingTime', dataType: 'datetime' },
        { dataField: 'batchCode' },
        { dataField: 'vehicle.vehicleNumber' },
        { 
            dataField: 'vehicle.vehicleType',
            lookup: { dataSource: dataSources.vehicleTypes, displayExpr: 'text', valueExpr: 'value' }
        },
        { dataField: 'driver' },
        { dataField: 'supercargo' },
        { 
            dataField: "shippingType",
            lookup: { dataSource: dataSources.bottleOperations, displayExpr: "text", valueExpr: "value" }
        },
        {
            dataField: "sourceType",
            lookup: { dataSource: dataSources.bottlePositions, displayExpr: "text", valueExpr: "value" }
        },
        { dataField: 'source' },
        {
            dataField: "targetType",
            lookup: { dataSource: dataSources.bottlePositions, displayExpr: "text", valueExpr: "value" }
        },
        { dataField: 'target' },
        { dataField: 'operators', allowSorting: false, allowGrouping: false },
        {
            dataField: 'bottleCodes',
            allowSorting: false,
            allowGrouping: false,
            cellRender: function (e: any) {
                return (
                    <div>{e.data.bottleCodes[0] && `${e.data.bottleCodes[0]}等${e.data.bottleCodes.length}个`}</div>
                );
            }
        }
    ];

    constructor(props: Readonly<ShippingGridProps>) {
        super(props);

        this.state = {
            store: createEmptyStore()
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "view");
        this.gridOptions.onExporting = this.onExporting.bind(this);
        this.gridOptions.export!.allowExportSelectedData = false;
        this.gridOptions.export!.enabled = false;
    }

    componentDidMount() {
        this.authorizeImport();
    }

    async authorizeImport() {
        if (await authorizationService.authorize(this.objectName, enums.stdActions.export)) {
            this.gridOptions.export!.enabled = true;
            this.gridRef.current?.instance.option("export.enabled", true);
        }
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onShippingSelected(e.selectedRowsData[0]);
        } else {
            this.props.onShippingSelected({});
        }
    }
    
    onExporting(e: ExportingEvent) {
        const args: ExportModel = {
            moduleName: this.moduleName.moduleName,
            exportUrl: `${baseApiUrl}${shippingService.apiPath}/export`,
            columns: this.columns,
            keys: e.component.getSelectedRowKeys(),
            filter: e.component.getCombinedFilter()
        };

        e.cancel = true;
        globalPopupManager.show(globalPopupNames.exportPopup, args);
    }

    filter(filterValue: any) {
        if (this.state.store !== shippingStore) {
            this.setState({ store: shippingStore }, () => this._filter(filterValue));
        } else {
            this._filter(filterValue);
        }
    }

    _filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.columns}>
            </StdGrid>
        );
    }
}