import { Chart } from "devextreme-react";
import { CommonSeriesSettings, Export, Grid, Label, Legend, Margin, Series, Tooltip, ValueAxis } from "devextreme-react/chart";
import { useMemo } from "react";
import { dateAdd, today } from "../../base";
import { HomeReportOrderTrendData } from "../../models";

export interface OrderTrendProps {
    trendType: string;
    data?: HomeReportOrderTrendData;
}

export default function OrderTrend(props: OrderTrendProps) {
    const dataSource = useMemo(() => {
        const list: any[] = [];
        const data = props.data;
        const day = today();
        if (!data) { return []; }

        for (let i = 0; i < data.counts.length; i++) {
            list.push({
                arg: props.trendType === "year" ? 
                    `${(day.getMonth() + i + 1) % 12 + 1}` : 
                    `${dateAdd(day, -29 + i, "d").getDate()}`,
                count: data.counts[i],
                amount: data.amounts[i]
            });
        }

        return list;
    }, [props]);

    return (
        <Chart
            palette={"Violet"}
            dataSource={dataSource}
            title={props.trendType === "year" ? "12个月订单趋势图" : "30天订单趋势图"}>
            <CommonSeriesSettings argumentField={"arg"} type="bar" />
            <ValueAxis title={"数量"}>
                <Label format={"decimal"} />
                <Grid visible={true} />
            </ValueAxis>
            <ValueAxis name="amount" position={"right"} title="金额">
                <Label format={"decimal"} />
                <Grid visible={true} />
            </ValueAxis>
            <Series key={"count"} valueField="count" name="数量" color={"#009688"} />
            <Series type={"spline"} key={"amount"} valueField="amount" name="金额" axis={"amount"} color={"#ed9c28"} />
            <Margin bottom={20} />        
            <Legend verticalAlignment="bottom" horizontalAlignment="center" />
            <Export enabled={true} />
            <Tooltip enabled={true} />
        </Chart>
    )
}
