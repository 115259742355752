import { RouteConfig } from "./route-model";
import SecurityCheckPage from "../pages/security/security-check";
import DangerPage from "../pages/security/danger";
import DangerNotificationPage from "../pages/security/danger-notification";
import TroubleshootingPage from "../pages/security/troubleshooting";
import SiteCheckPage from "../pages/site-security/site-check";
import SiteDangerPage from "../pages/site-security/site-danger";
import TrainingPage from "../pages/training/training";

export const securityRoutes: RouteConfig[] = [
    {
        path: '/security-check',
        component: SecurityCheckPage
    },
    {
        path: '/danger',
        component: DangerPage
    },
    {
        path: '/danger-notification',
        component: DangerNotificationPage
    },
    {
        path: '/troubleshooting',
        component: TroubleshootingPage
    },
    {
        path: '/site-check',
        component: SiteCheckPage
    },
    {
        path: '/site-danger',
        component: SiteDangerPage
    },
    {
        path: '/training',
        component: TrainingPage
    }
]