import Form, { CompareRule, Item, Label, RequiredRule } from "devextreme-react/form";
import Popup from "devextreme-react/popup";
import { useCallback, useMemo, useRef, useState } from "react";
import { employeeService } from "../../api";
import { alertError, notifySuccess } from "../../base";
import { createResizablePopupOptions } from "../options";
import { PopupContentBox } from "../layouts";

export interface ChangePasswordProps {
    visible: boolean;
    pwdSignal?: string|null;
    onHiding: (succeeded?: boolean) => void;
}

interface FormData {
    oldPassword?: string;
    newPassword?: string;
    repeatPassword?: string;
}

function getEditorOptions(placeholder: string) {
    return {
        placeholder: placeholder,
        mode: 'password'
    };
}

export default function ChangePasswordPopup(props: ChangePasswordProps) {
    const [loading, setLoading] = useState(false);
    const formData = useRef<FormData>({});
    const formRef = useRef<Form | null>(null);
    const popupOptions = useMemo(
        () => createResizablePopupOptions(400, 350, !props.pwdSignal), [props]);

    const getNewPassword = useCallback(() => {
        return formData.current?.newPassword;
    }, [formData]);

    const save = useCallback(async () => {
        if (formRef.current) {
            const valid = formRef.current.instance.validate();
            if (!valid.isValid) {
                return;
            }

            setLoading(true);

            const result = await employeeService.changePassword(formData.current);

            setLoading(false);

            if (result.succeeded) {
                notifySuccess("成功修改密码，请牢记您的新密码！");
                formData.current = {};
                props.onHiding(true);
            } else {
                alertError(result.message!);
            }
        }
    }, [formRef, formData, props]);

    const cancel = useCallback(() => {
        props.onHiding();
    }, [props]);

    return (
        <Popup
            visible={props.visible}
            title={props.pwdSignal ? "请修改您的密码" : "修改密码"}
            onHiding={cancel}
            {...popupOptions}
        >
            <PopupContentBox hideCancelButton={!!props.pwdSignal} onSave={save} onCancel={cancel}>
                {props.pwdSignal && (
                    <p className={"text-danger"}>{props.pwdSignal}</p>
                )}
                <Form ref={formRef} formData={formData.current} disabled={loading} onEditorEnterKey={save}>
                    <Item
                        dataField={'oldPassword'}
                        editorType={'dxTextBox'}
                        editorOptions={getEditorOptions('旧密码')}
                    >
                        <RequiredRule message="旧密码不能为空" />
                        <Label visible={false} />
                    </Item>
                    <Item
                        dataField={'newPassword'}
                        editorType={'dxTextBox'}
                        editorOptions={getEditorOptions('新密码')}
                    >
                        <RequiredRule message="新密码不能为空" />
                        <Label visible={false} />
                    </Item>
                    <Item
                        dataField={'repeatPassword'}
                        editorType={'dxTextBox'}
                        editorOptions={getEditorOptions('重复新密码')}
                    >
                        <RequiredRule message="重复新密码不能为空" />
                        <CompareRule
                            comparisonTarget={getNewPassword}
                            message="两次输入的新密码不一致" />
                        <Label visible={false} />
                    </Item>
                </Form>
            </PopupContentBox>
        </Popup>
    )
}