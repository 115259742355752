import { RouteConfig } from "./route-model";
import EmployeeAppStatusPage from "../pages/report/employee-app-status";
import ExceptionDataPage from "../pages/report/exception-data/exception-data";
import OperationLogPage from "../pages/report/operation-log";
import OrderSummaryReport from "../pages/report/order/order-summary-report";
import CustomerBottleReportPage from "../pages/report/customer/customer-bottle-report";
import TurnoverReportPage from "../pages/report/bottle-flow/turnover";
import DeliverPerformanceReportPage from "../pages/report/order/deliver-performance";
import FillingDeliveringReportPage from "../pages/report/organization/filling-delivering";
import ActiveCustomerReportPage from "../pages/report/customer/active-customer";
import AverageUsageReportPage from "../pages/report/order/average-usage";
import CustomerTrendReportPage from "../pages/report/customer/customer-trend";
import OrderTrendReportPage from "../pages/report/order/order-trend";
import OrderForecastPage from "../pages/order/order-forecast";
import StocktakingReportPage from "../pages/report/stocktaking/stocktaking-report";
import ExceptionWarningPage from "../pages/report/exception-data/exception-warning";
import ExceptionDataSummaryPage from "../pages/report/exception-data/exception-data-summary";
import AreaDeliveringReportPage from "../pages/report/area/area-delivering";

export const reportRoutes: RouteConfig[] = [
    {
        path: '/report/order-summary',
        component: OrderSummaryReport
    },
    {
        path: '/employee-app-status',
        component: EmployeeAppStatusPage
    },
    {
        path: '/exception-data',
        component: ExceptionDataPage
    },
    {
        path: '/operation-log',
        component: OperationLogPage
    },
    {
        path: '/report/customer-bottle',
        component: CustomerBottleReportPage
    },
    {
        path: '/report/bottle-turnover',
        component: TurnoverReportPage
    },
    {
        path: '/report/deliver-performance',
        component: DeliverPerformanceReportPage
    },
    {
        path: '/report/filling-delivering',
        component: FillingDeliveringReportPage
    },
    {
        path: '/report/active-customer',
        component: ActiveCustomerReportPage
    },
    {
        path: '/report/average-usage',
        component: AverageUsageReportPage
    },
    {
        path: '/report/customer-trend',
        component: CustomerTrendReportPage
    },
    {
        path: '/report/order-trend',
        component: OrderTrendReportPage
    },
    {
        path: '/report/order-forecast',
        component: OrderForecastPage
    },
    {
        path: '/report/stocktaking',
        component: StocktakingReportPage
    },
    {
        path: '/report/exception-warning',
        component: ExceptionWarningPage
    },
    {
        path: '/report/exception-summary',
        component: ExceptionDataSummaryPage
    },
    {
        path: '/report/area-delivering',
        component: AreaDeliveringReportPage
    }
]