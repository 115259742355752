import { ServerData, authorizationService, enums, http, tokenService } from '../base';
import { BottleTypeModel } from '../models';

class ServerDataService {
    private _serverData: any = null;
    private _bottleTypes: BottleTypeModel[] = [];

    get serverData(): ServerData { return this._serverData; }

    get bottleTypes(): BottleTypeModel[] { return this._bottleTypes; }

    get gasBottleTypes(): BottleTypeModel[] {
        return this.bottleTypes.filter(t => t.gasType === enums.gasTypes.gas);
    }

    constructor() {
        tokenService.addHandler(async event => {
            if (event.signedIn) {
                this.resetBottleTypes();
            }
        });
    }

    async init() {
        this._serverData = await http.getData('/api/serverdata');
        this.resetBottleTypes();
    }

    async resetBottleTypes() {
        const propanePermission = await authorizationService.authorize("GlobalPermission", "Propane");
        
        if (!propanePermission) {
            this._bottleTypes = this._serverData.bottleTypes
                .filter((t: BottleTypeModel) => t.gasType === enums.gasTypes.gas);
        } else {
            this._bottleTypes = this._serverData.bottleTypes;
        }
    }
}


export const serverDataService = new ServerDataService();