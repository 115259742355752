import React from "react";
import OrderGrid from "./order-grid";
import OrderForm from "./order-form";
import OrderToolbar from "./order-toolbar";
import AuthorizedComponent from "../authorized-component";
import { IColumnProps } from "devextreme-react/data-grid";
import { AssignEmployeePopup, AssignOrganizationPopup, ContentBlock } from "../../components";
import { enums, alertError, confirm2, globalPopupManager, globalPopupNames, notifySuccess, prompt2, modelClone, alertSuccess, baseApiUrl, download } from "../../base";
import { orderService, employeeService } from "../../api";
import { BottleTypeModel, EmployeeRef, OrderModel, OrganizationRef } from "../../models";
import OrderCheckDetail from "./order-check-detail";

interface OrderState {
    orgs: OrganizationRef[];
    employees: EmployeeRef[];
    types: BottleTypeModel[];
    columns: IColumnProps[];
}

export default class OrderPage extends AuthorizedComponent<any, OrderState> {
    objectName = "Order";
    gridRef = React.createRef<OrderGrid>();
    formRef = React.createRef<OrderForm>();
    toolbarRef = React.createRef<OrderToolbar>();
    orgPopupRef = React.createRef<AssignOrganizationPopup>();
    empPopupRef = React.createRef<AssignEmployeePopup>();
    checkDetailRef = React.createRef<OrderCheckDetail>();
    orderOfNext: OrderModel = {};

    constructor(props: any) {
        super(props);

        this.onFiltering = this.onFiltering.bind(this);
        this.onOrderSelected = this.onOrderSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);

        this.onSaved = this.onSaved.bind(this);
        this.getDelivers = this.getDelivers.bind(this);
        this.assignOrganization = this.assignOrganization.bind(this);
        this.assignDeliver = this.assignDeliver.bind(this);
        this.discard = this.discard.bind(this);
    }

    get actionNames(): string[] {
        return [
            enums.stdActions.create,
            enums.stdActions.update,
            enums.stdActions.delete,
            "Assign",
            "SetPaid"
        ];
    }

    onOrderSelected(order: OrderModel) {
        this.toolbarRef.current?.setOrder(order);
    }

    onFiltering(filterValue: any) {
        this.gridRef.current?.applyFilter(filterValue);
    }

    onButtonClick(order: OrderModel, name: string) {
        switch (name) {
            case "edit":
                const canEdit = [enums.orderStatus.created, enums.orderStatus.assigned, enums.orderStatus.delivering]
                    .indexOf(order?.status || "") !== -1;
                if (canEdit && this.permissions[enums.stdActions.update]) {
                    this.edit(order);
                }
                break;

            case "callcenter":
                this.callcenter(order);
                break;

            case "bottles":
                let arr = [];
                if (order.deliverBottles?.length) { arr.push("送达：" + order.deliverBottles.join("，")); }
                if (order.recycleBottles?.length) { arr.push("回收：" + order.recycleBottles.join("，")); }
                alertSuccess(arr.length ? arr.join("<br />") : "该订单未送达");
                break;

            case "next":
                // 权限在按钮处控制
                this.next(order);
                break;

            case "discard":
                if (this.permissions[enums.stdActions.delete]) {
                    this.discard(order);
                }
                break;

            case "restore":
                if (this.permissions[enums.stdActions.delete]) {
                    this.restore(order);
                }
                break;

            case "saledetail":
                const filter = this.gridRef.current?.gridRef.current?.instance.getCombinedFilter();
                const url = `${baseApiUrl}${orderService.apiPath}/export/saledetail?filter=${JSON.stringify(filter)}`;
                download(url, `销售明细报表.xlsx`);
                break;

            case "checkdetail":
                this.checkDetailRef.current?.show(order);
                break;
        }
    }

    edit(order: OrderModel) {
        this.formRef.current?.show(modelClone(order));
    }

    async callcenter(order: OrderModel) {
        if (order?.customerId) {
            globalPopupManager.show(globalPopupNames.callCenter, { id: order.customerId });
        } else {
            const phone = await prompt2("请输入客户购气卡号或电话");
            if (phone) {
                globalPopupManager.show(globalPopupNames.callCenter, { phone: phone });
            }
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    next(order: OrderModel) {
        this.orderOfNext = order;

        switch (order.status) {
            case enums.orderStatus.created:
                this.showOrganizationPopup();
                break;

            case enums.orderStatus.assigned:
            case enums.orderStatus.delivering:
                this.showEmployeePopup(order);
                break;

            case enums.orderStatus.delivery:
                this.setPaid();
                break;
        }
    }

    showOrganizationPopup() {
        this.orgPopupRef.current?.show();
    }

    async assignOrganization(org: OrganizationRef) {
        const result = await orderService.assignOrganization(this.orderOfNext.id!, org.id!);

        if (result.succeeded) {
            notifySuccess("已成功分配供应站");
            this.orgPopupRef.current?.hide();
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    showEmployeePopup(order: OrderModel) {
        this.empPopupRef.current?.show(order.deliverId);
    }

    async getDelivers() {
        const delivers = await employeeService.getDelivers();
        return delivers.filter(d => d.organizationId === this.orderOfNext.organizationId);
    }

    async assignDeliver(deliver: EmployeeRef) {
        const result = await orderService.assignDeliver(this.orderOfNext.id!, deliver.id!);

        if (result.succeeded) {
            notifySuccess("已成功派工");
            this.empPopupRef.current?.hide();
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async setPaid() {
        if (!await confirm2("确定要该订单设置为已回款吗？"))
            return;

        const result = await orderService.setPaid(this.orderOfNext.id!);

        if (result.succeeded) {
            notifySuccess("已成功设置回款");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async discard(order: OrderModel) {
        if (!await confirm2("确定要作废此订单吗？"))
            return;

        const result = await orderService.discard(order.id!);

        if (result.succeeded) {
            notifySuccess("已成功作废订单");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async restore(order: OrderModel) {
        if (!await confirm2("确定要还原此订单吗？"))
            return;

        const result = await orderService.restore(order.id!);

        if (result.succeeded) {
            notifySuccess("已成功还原订单");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    render() {
        return (
            <ContentBlock caption={"订单管理"}>
                <OrderToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onFiltering={this.onFiltering}
                    onButtonClick={this.onButtonClick} />
                <OrderGrid
                    permissions={this.permissions}
                    ref={this.gridRef}
                    onOrderSelected={this.onOrderSelected}
                    onButtonClick={this.onButtonClick} />
                <OrderForm ref={this.formRef} onSaved={this.onSaved} />
                <AssignOrganizationPopup ref={this.orgPopupRef} save={this.assignOrganization} />
                <AssignEmployeePopup ref={this.empPopupRef} getFunc={this.getDelivers} save={this.assignDeliver} />
                <OrderCheckDetail ref={this.checkDetailRef} />
            </ContentBlock>
        );
    }
}