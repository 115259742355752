import "./full-panel.scss";
import { useGridHeight } from "../../hooks"

export default function FullPanel(props: any) {
    const height = useGridHeight();
    const styleObject = Object.assign(props.style || {}, { height: height });

    return (
        <div style={styleObject} className="full-panel">
            {props.children}
        </div>
    )
}