import React from "react";
import { cpScrapService } from "../../api";
import { alertError, confirm2, enums, notifySuccess, today } from "../../base";
import { ContentBlock } from "../../components";
import { CpScrapModel } from "../../models";
import AuthorizedComponent from "../authorized-component";
import CpScrapForm from "./cp-scrap-form";
import CpScrapGrid from "./cp-scrap-grid";
import CpScrapToolbar from "./cp-scrap-toolbar";

export default class CpScrapPage extends AuthorizedComponent {
    objectName = "CpScrap";
    gridRef = React.createRef<CpScrapGrid>();
    toolbarRef = React.createRef<CpScrapToolbar>();
    formRef = React.createRef<CpScrapForm>();
    deferLoad = false;

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onRowChecked = this.onRowChecked.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onContextButtonClick = this.onContextButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
    }

    onModelSelected(model: CpScrapModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onRowChecked(ids: number[]) {
        this.toolbarRef.current?.setIds(ids);
    }

    onButtonClick(model: CpScrapModel, name: string) {

        switch (name) {
            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.formRef.current?.show({ id: 0, scrapDate: today() });
                }
                break;

            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.formRef.current?.show(model);
                }
                break;
        }
    }

    onContextButtonClick(ids: number[], name: string) {
        switch (name) {
            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.deleteSelected(ids);
                }
                break;
        }
    }

    async deleteSelected(ids: number[]) {
        if (!await confirm2("确定要删除选中数据吗？" + (ids.length === 1 ? "（提示：点击表格右上角√可调出复选框）" : ""))) {
            return;
        }

        const result = await cpScrapService.deleteSelected(ids);

        if (result.succeeded) {
            notifySuccess("删除成功");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return (
            <ContentBlock caption={"条码打印-报废瓶管理"}>
                {this.renderContent()}
            </ContentBlock>
        )
    }

    renderContent() {
        return (
            <React.Fragment>
                <CpScrapToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onButtonClick={this.onButtonClick}
                    onContextButtonClick={this.onContextButtonClick} />
                <CpScrapGrid
                    ref={this.gridRef}
                    deferLoad={this.deferLoad}
                    permissions={this.permissions}
                    onModelSelected={this.onModelSelected}
                    onRowChecked={this.onRowChecked}
                    onButtonClick={this.onButtonClick} />
                <CpScrapForm ref={this.formRef} onSaved={this.onSaved} />
            </React.Fragment>
        )
    }
}