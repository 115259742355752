import { createStoreFromUrl, http, MessageBool } from "../base";
import { ShippingModel } from "../models";

class ShippingService {
    apiPath = "/api/shipping";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }

    create(model: ShippingModel) {
        return http.postData<MessageBool>(this.apiPath, model);
    }
}

export const shippingService = new ShippingService();
export const shippingStore = shippingService.getDxData();