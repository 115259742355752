import { useMemo } from "react";
import { dateAdd, today } from "../../base";
import { HomeReportCustomerTrendData } from "../../models";
import { Chart } from "devextreme-react";
import { CommonSeriesSettings, Export, Grid, Label, Legend, Margin, Series, Tooltip, ValueAxis } from "devextreme-react/chart";

export interface CustomerTrendProps {
    trendType: string;
    data?: HomeReportCustomerTrendData;
}

export default function CustomerTrend(props: CustomerTrendProps) {
    const dataSource = useMemo(() => {
        const list: any[] = [];
        const data = props.data;
        const day = today();
        if (!data) { return []; }

        for (let i = 0; i < data.newCounts.length; i++) {
            list.push({
                arg: props.trendType === "year" ? 
                    `${(day.getMonth() + i + 1) % 12 + 1}` : 
                    `${dateAdd(day, -29 + i, "d").getDate()}`,
                newCount: data.newCounts[i],
                exitCount: data.exitCounts[i]
            });
        }

        return list;
    }, [props]);

    return (
        <Chart
            palette={"Violet"}
            dataSource={dataSource}
            title={props.trendType === "year" ? "12个月客户趋势图" : "30天客户趋势图"}>
            <CommonSeriesSettings argumentField={"arg"} type="spline" />
            <ValueAxis>
                <Label format={"decimal"} />
                <Grid visible={true} />
            </ValueAxis>
            <Series key={"newCount"} valueField="newCount" name="开户" color={"#009688"} />
            <Series key={"exitCount"} valueField="exitCount" name="退户" color={"#ed9c28"} />
            <Margin bottom={20} />        
            <Legend verticalAlignment="bottom" horizontalAlignment="center" />
            <Export enabled={true} />
            <Tooltip enabled={true} />
        </Chart>
    )
}