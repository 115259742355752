import { DataGrid } from "devextreme-react";
import { IDataGridOptions, IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import React from "react";
import { webMessageService, webMessageStore } from "../../api";
import { historyInstance } from "../../base";
import { createGridOptions, remoteReadOnlyGridOptions, StdGrid } from "../../components";
import { WebMessageModel } from "../../models";

export interface WebMessageGridProps {
    onModelSelected(model: WebMessageModel): void;
    onButtonClick(model: WebMessageModel, name: string): void;
}

export default class WebMessageGrid extends React.Component<WebMessageGridProps, any> {
    moduleName = "WebMessage";
    gridRef = React.createRef<DataGrid>();
    gridOptions: IDataGridOptions = createGridOptions(
        "webMessages",
        this.gridRef,
        remoteReadOnlyGridOptions,
        (items: any[]) => {
            // state清空会同时清空filter，需要重新绑定旧的
            items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
            return items;
        });

    columns: IColumnProps[] = [
        { 
            dataField: "title",
            cellRender: (e: any) => {
                return (
                    <div>
                        {e.data.link && (<span className="link-button" onClick={() => this.gotoLink(e.data)}>
                            {e.data.title}
                        </span>)}
                        {!e.data.link && <span>{e.data.title}</span>}
                    </div>
                )
            }
        },
        { dataField: "content" },
        { dataField: "createdAt", dataType: "datetime" },
        { dataField: "readAt", dataType: "datetime" }
    ];

    constructor(props: Readonly<WebMessageGridProps>) {
        super(props);

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = (e) => this.props.onButtonClick(e.data, "read");
        this.gridOptions.onRowPrepared = (e) => {
            if (e.rowType === "data" && !e.data.readAt) {
                e.rowElement.style.fontWeight = "bold";
            }
        }
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});
        }
    }

    async gotoLink(model: WebMessageModel) {
        if (!model.readAt) {
            await webMessageService.read(model.id!);
        }
        
        historyInstance.push(model.link!);
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={webMessageStore}
                options={this.gridOptions}
                columns={this.columns}>
            </StdGrid>
        );
    }
}