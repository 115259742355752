import React from "react";
import { OrderCheckItemModel, OrderModel } from "../../models";
import { DataGrid, Popup, ScrollView } from "devextreme-react";
import { IColumnProps, IDataGridOptions } from "devextreme-react/data-grid";
import { ImagesComponent, RefPopup, StdGrid, createBoolColumn, createImageColumn } from "../../components";
import { orderCheckItemService } from "../../api";

interface OrderCheckDetailState {
    order: OrderModel;
    items: OrderCheckItemModel[];
}

export default class OrderCheckDetail extends React.Component<any, OrderCheckDetailState> {
    moduleName = "OrderCheckItem";

    popupRef = React.createRef<Popup>();
    gridRef = React.createRef<DataGrid>();
    gridOptions: IDataGridOptions = {
        columnAutoWidth: true,
        paging: { enabled: false }
    };
    
    columns: IColumnProps[] = [
        { dataField: "itemName" },
        createBoolColumn({ dataField: "hasDanger" }),
        createImageColumn({ dataField: "photo" })
    ];

    constructor(props: any) {
        super(props);

        this.state = { 
            order: {},
            items: []
        };
    }

    async show(order: OrderModel) {
        this.popupRef.current?.instance.show();

        const items = await orderCheckItemService.getByOrderId(order.id!);

        this.setState({
            order: order,
            items: items
        });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    render() {
        const order = this.state.order;
        const items = this.state.items;
        const fileNames: string[] = (order.checkPhoto ?? "").split(';').filter((s: string) => !!s);

        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"随单安检详情"}
                width={1000}
                height={600}>
                <ScrollView height={"100%"} scrollByContent={true} showScrollbar={"always"}>
                    <h4 style={{ marginTop: 0 }}>安检照片</h4>
                    <div className="large-images">
                        <ImagesComponent fileNames={fileNames} />
                    </div>
                    <StdGrid
                        moduleName={this.moduleName}
                        gridRef={this.gridRef}
                        dataSource={items}
                        columns={this.columns}
                        options={this.gridOptions}
                        autoHeight={true}
                    />
                </ScrollView>
            </RefPopup>
        );
    }   
}