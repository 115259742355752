import { createStoreFromUrl } from '../base';

class SysLogService {
    apiPath = "/api/syslog";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }
}

export const sysLogService = new SysLogService();
export const sysLogStore = sysLogService.getDxData();