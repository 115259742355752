import { http, MessageBool } from "../base";
import { BriefCustomerPhone, CustomerPhoneModel } from "../models";

class CustomerPhoneService {
    apiPath = "/api/customerphone";

    async searchByCustomerId(customerId: number) {
        return await http.getData<BriefCustomerPhone[]>(`${this.apiPath}/bycustomer/${customerId}`);
    }

    async create(model: CustomerPhoneModel): Promise<MessageBool> {
        return await http.postData(this.apiPath, model);
    }

    async delete(id: number): Promise<MessageBool> {
        return await http.deleteData(`${this.apiPath}/${id}`);
    }
}

export const customerPhoneService = new CustomerPhoneService();