import React from "react";
import BottleGrid from "./bottle-grid";
import BottleForm from "./bottle-form";
import BottleToolbar from "./bottle-toolbar";
import AuthorizedComponent from "../authorized-component";
import { AuthContext, AuthContextType } from "../../hooks";
import { alertError, confirm2, enums, notifySuccess } from "../../base";
import { bottleService } from "../../api";
import { ContentBlock } from "../../components";
import { BottleModel } from "../../models";

export default class BottlePage extends AuthorizedComponent {
    objectName = "Bottle";
    gridRef = React.createRef<BottleGrid>();
    formRef = React.createRef<BottleForm>();
    toolbarRef = React.createRef<BottleToolbar>();

    get actionNames(): string[] {
        return [enums.stdActions.create, enums.stdActions.update];
    }

    constructor(props: any) {
        super(props);

        this.onBottleSelected = this.onBottleSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    onBottleSelected(bottle: BottleModel) {
        this.toolbarRef.current?.setModel(bottle);
    }

    onButtonClick(bottle: BottleModel, name: string) {
        switch (name) {
            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.formRef.current?.show(bottle);
                }
                break;

            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.formRef.current?.show({});
                }
                break;
        }
    }

    onSaved() {
        this.gridRef.current &&
            this.gridRef.current.gridRef.current?.instance.refresh();
    }

    async delete(e: any) {
        if (!await confirm2("确定要删除吗？")) {
            return;
        }

        const res = await bottleService.delete(e.data.id);

        if (res.succeeded) {
            this.gridRef.current?.refresh();
            notifySuccess("成功删除记录");
        } else {
            alertError(res.message!);
        }
    }

    onFiltering(isPorcelainCode?: boolean) {
        this.gridRef.current?.specialFilter(isPorcelainCode);
    }

    render() {
        return <AuthContext.Consumer>
            {context => this.renderMain(context)}
        </AuthContext.Consumer>
    }

    renderMain(context: AuthContextType) {
        return (
            <ContentBlock caption={"钢瓶档案"}>
                <BottleToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef} 
                    user={context.user}
                    onButtonClick={this.onButtonClick}
                    onFiltering={this.onFiltering} />
                <BottleGrid 
                    ref={this.gridRef} 
                    user={context.user} 
                    onBottleSelected={this.onBottleSelected}
                    onButtonClick={this.onButtonClick} />
                <BottleForm ref={this.formRef} onSaved={this.onSaved} />
            </ContentBlock>
        );
    }
}