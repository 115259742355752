import { BaseApiService, createStoreFromUrl, http, MessageBool } from "../base";
import { CpCheckBackBatchModel, CpCheckBackModel } from "../models";

class CpCheckBackService extends BaseApiService<CpCheckBackModel> {
    moduleName = "cpcheckback";

    getDxData(batchId?: number) {
        return createStoreFromUrl(`${this.apiPath}/dx?batchId=${batchId || ''}`, 'id');
    }

    getTemplate(batchId: number) {
        return http.getData(`${this.apiPath}/template?batchId=${batchId}`);
    }

    getTemplateFromCheck(checkBatchId: number) {
        return http.getData(`${this.apiPath}/templatefromcheck?checkBatchId=${checkBatchId}`);
    }

    getDataByCode(checkBatchId: number, code: string) {
        return http.getData(`${this.apiPath}/codedata?checkBatchId=${checkBatchId}&code=${code}`);
    }
    
    deleteSelected(ids: number[]) {
        return http.deleteData<MessageBool>(`${this.apiPath}?ids=${ids}`);
    }
    
    getBatch(batchId: number) {
        return http.getData<CpCheckBackBatchModel>(`${this.apiPath}/batch/${batchId}`);
    }
}

export const cpCheckBackService = new CpCheckBackService();
export const cpCheckBackStore = cpCheckBackService.getDxData();