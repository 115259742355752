import { Switch, Route, Redirect } from 'react-router-dom';
import { LoginCard, LoginForm, ResetPasswordForm, ChangePasswordForm } from './components';

export default function UnauthenticatedContent() {
    return (
        <Switch>
            <Route exact path='/login' >
                <LoginCard title="欢迎访问集顺燃气">
                    <LoginForm />
                </LoginCard>
            </Route>
            <Route exact path='/reset-password' >
                <LoginCard
                    title="重置密码"
                    description="请输入您用来注册的电子邮件地址，我们将通过电子邮件向您发送一个重置密码的链接."
                >
                    <ResetPasswordForm />
                </LoginCard>
            </Route>
            <Route exact path='/change-password/:recoveryCode' >
                <LoginCard title="修改密码">
                    <ChangePasswordForm />
                </LoginCard>
            </Route>
            <Redirect to={'/login'} />
        </Switch>
    );
}
