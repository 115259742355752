import { DataMap, http } from "../../base";
import { BottleStocktakingModel, StocktakingReportCondition } from "../../models";

class BottleStocktakingReportService {
    apiPath = "/api/report/bottlestocktaking";

    get(condition: StocktakingReportCondition) {
        return http.getData<DataMap<BottleStocktakingModel>>(`${this.apiPath}`, condition);
    }
}

export const bottleStocktakingReportService = new BottleStocktakingReportService();