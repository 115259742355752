import React from "react";
import { trainingService } from "../../api";
import { alertError, baseApiUrl, confirm2, download, enums, notifySuccess } from "../../base";
import { ContentBlock } from "../../components";
import { TrainingModel } from "../../models";
import AuthorizedComponent from "../authorized-component";
import TrainingForm from "./training-form";
import TrainingGrid from "./training-grid";
import TrainingToolbar from "./training-toolbar";

export default class TrainingPage extends AuthorizedComponent {
    objectName = "Training";
    gridRef = React.createRef<TrainingGrid>();
    formRef = React.createRef<TrainingForm>();
    toolbarRef = React.createRef<TrainingToolbar>();

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onRowChecked = this.onRowChecked.bind(this);
        this.filter = this.filter.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onContextButtonClick = this.onContextButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
    }

    onModelSelected(model: TrainingModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onRowChecked(ids: number[]) {
        this.toolbarRef.current?.setIds(ids);
    }

    filter(filterValue: any, showDeleted: boolean) {
        this.gridRef.current?.filter(filterValue, showDeleted);
    }

    onButtonClick(model: TrainingModel, name: string) {
        switch (name) {
            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.formRef.current?.show({});
                }
                break;

            case "edit":
                this.formRef.current?.show(model);
                break;

            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(model);
                }
                break;

            case "restore":
                if (this.permissions[enums.stdActions.delete]) {
                    this.restore(model);
                }
                break;
        }
    }

    async onContextButtonClick(ids: number[], name: string) {
        switch (name) {
            case "export":
                if (!await confirm2("确定导出选中的安全会议培训吗？")) {
                    return;
                }

                const url = `${baseApiUrl}${trainingService.apiPath}/exportform?ids=${ids.join(',')}`;
                download(url, `安全会议培训.xls`);
                break;
        }
    }

    async restore(model: TrainingModel) {
        if (!await confirm2("确定要还原该条数据吗？")) {
            return;
        }

        const result = await trainingService.restore(model.id!);

        if (result.succeeded) {
            notifySuccess("已成功还原数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async delete(model: TrainingModel) {
        if (!await confirm2("确定要作废此数据吗？")) {
            return;
        }

        const result = await trainingService.delete(model.id!);

        if (result.succeeded) {
            notifySuccess("已成功作废数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return (
            <ContentBlock caption={"安全会议培训"}>
                <TrainingToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onFiltering={this.filter}
                    onButtonClick={this.onButtonClick}
                    onContextButtonClick={this.onContextButtonClick} />
                <TrainingGrid
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick}
                    onRowChecked={this.onRowChecked} />
                <TrainingForm ref={this.formRef} onSaved={this.onSaved} />
            </ContentBlock>
        )
    }
}