import React from "react";
import Form, { IFormOptions, IItemProps, Item } from "devextreme-react/form";
import { useFormItemFill } from "../../hooks";
import { ModuleConfig, StringMap } from "../../base";

export interface StdFormProps {
    moduleName: string | ModuleConfig;
    formRef?: React.LegacyRef<Form>;
    colCount?: number;
    items: IItemProps[];
    nameMap?: StringMap;
    formData: any;
    options?: IFormOptions;
}

/**
 * 使用该类以实现自动标签文本填充，这里应使用Item子元素，而不是items属性（不会更新）
 */
export default function StdForm(props: StdFormProps) {
    const fill = useFormItemFill();
    fill(props.moduleName, props.items, props.nameMap);

    return (
        <Form 
            ref={props.formRef}
            colCount={props.colCount}
            formData={props.formData}
            labelLocation="left"
            {...props.options}>
            {props.items.map(i => (
                <Item key={i.dataField || i.name} {...i}>
                    {i.render && i.render()}
                </Item>
            ))}
        </Form>
    )
}