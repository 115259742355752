import { SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import React from "react";
import { employeeService, organizationService } from "../../api";
import { OrgTreeDropDown } from "../../components";
import { EmployeeRef, OrganizationRef } from "../../models";

export interface EmployeeAppStatusToolbarProps {
    onFiltering: (filterValue: any, orgainzationId?: number) => void;
}

interface EmployeeAppStatusToolbarState {
    orgs: OrganizationRef[];
    allEmployees: EmployeeRef[];
    employees: EmployeeRef[];
    organizationId?: number;
    employeeId?: string;
}

export default class EmployeeAppStatusToolbar extends
    React.Component<EmployeeAppStatusToolbarProps, EmployeeAppStatusToolbarState> {

    constructor(props: Readonly<EmployeeAppStatusToolbarProps>) {
        super(props);

        this.state = {
            orgs: [],
            allEmployees: [],
            employees: []
        };

        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onEmployeeChanged = this.onEmployeeChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let orgs: OrganizationRef[] = [], employees: EmployeeRef[] = [];

        await Promise.all([
            (async () => orgs = await organizationService.getRefs())(),
            (async () => employees = await employeeService.getRefs())()
        ]);

        this.setState({
            orgs: orgs,
            allEmployees: employees,
            employees: employees
        });
    }

    onOrganizationChanged(organizationId?: number) {
        const employees = organizationId ?
            this.state.allEmployees.filter(e => e.organizationId === organizationId) :
            this.state.allEmployees;

        const employeeId = employees.find(e => e.id === this.state.employeeId) ?
            this.state.employeeId : undefined;

        this.setState({
            organizationId: organizationId,
            employees: employees,
            employeeId: employeeId,
        }, this.onFiltering);
    }

    onEmployeeChanged(e: ValueChangedEvent) {
        this.setState({ employeeId: e.value }, this.onFiltering);
    }

    onFiltering() {
        this.props.onFiltering(this.getFilterValue(), this.state.organizationId);
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { employeeId } = this.state;

        if (employeeId) {
            filters.push(["employeeId", "=", employeeId]);
        }

        return filters.length > 0 ? filters : null;
    }

    render() {
        const { orgs, employees, organizationId, employeeId } = this.state;

        return (
            <div className="page-toolbar">
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="选择供应站过滤"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={employeeId}
                            dataSource={employees}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={160}
                            placeholder="员工过滤"
                            onValueChanged={this.onEmployeeChanged} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}