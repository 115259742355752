import React, { useState, useRef, useCallback } from 'react';
import { Link, useHistory } from "react-router-dom";
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    EmailRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import './reset-password-form.scss';

const notificationText = 'We\'ve sent a link to reset your password. Check your inbox.';

export default function ResetPasswordForm(props: any) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const formData = useRef<any>({});

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        // const { email }: any = formData.current;
        setLoading(true);

        const result: any = {};//await resetPassword(email);
        setLoading(false);

        if (result.isOk) {
            history.push('/login');
            notify(notificationText, 'success', 2500);
        } else {
            notify(result.message, 'error', 2000);
        }
    }, [history]);

    return (
        <form className={'reset-password-form'} onSubmit={onSubmit}>
            <Form formData={formData.current} disabled={loading}>
                <Item
                    dataField={'email'}
                    editorType={'dxTextBox'}
                    editorOptions={emailEditorOptions}
                >
                    <RequiredRule message="邮件不能为空" />
                    <EmailRule message="邮件不能为空" />
                    <Label visible={false} />
                </Item>
                <ButtonItem>
                    <ButtonOptions
                        elementAttr={submitButtonAttributes}
                        width={'100%'}
                        type={'default'}
                        useSubmitBehavior={true}
                    >
                        <span className="dx-button-text">
                            {
                                loading
                                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                    : '重置密码'
                            }
                        </span>
                    </ButtonOptions>
                </ButtonItem>
                <Item>
                    <div className={'login-link'}>
                         <Link to={'/login'}>去登录</Link>
                    </div>
                </Item>
            </Form>
        </form>
    );
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: '邮件', mode: 'email' };
const submitButtonAttributes = { class: 'submit-button' };
