import React from "react";
import { Form, Popup, TagBox } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import DataSource from "devextreme/data/data_source";
import { ValueChangedEvent } from "devextreme/ui/tag_box";
import { customerTypeService, predefinedLabelService, serverDataService } from "../../api";
import { stdSeparator, alertError, enums, modelClone, notifySuccess } from "../../base";
import { PopupContentBox, RefPopup, StdForm } from "../../components";
import { CustomerTypeModel, PredefinedLabelRef } from "../../models";

export interface CustomerTypeFormProps {
    onSaved: () => void;
}

interface CustomerTypeFormState {
    labels: PredefinedLabelRef[];
    items: IItemProps[];
    model: CustomerTypeModel;
}

export default class CustomerTypeForm 
    extends React.Component<CustomerTypeFormProps, CustomerTypeFormState> {
    moduleName = "CustomerType";

    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();

    constructor(props: Readonly<CustomerTypeFormProps>) {
        super(props);

        this.state = {
            labels: [],
            model: {},
            items: [
                { dataField: 'sortNumber' },
                { dataField: 'name' },
                { dataField: 'label', render: this.renderTagBox.bind(this) },
                { dataField: 'securityCheckPeriod' },
                { dataField: 'notifyAhead' },
                { dataField: 'lockAhead' },
                ...serverDataService.bottleTypes.map((t: any) => ({
                    dataField: `limit_${t.id}`,
                    label: { text: `${t.name}最大配送瓶数` }
                })),
            ]
        };

        this.onTagValueChanged = this.onTagValueChanged.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.loadLabels();
    }

    async loadLabels() {
        const data = await predefinedLabelService.getByDataType(enums.labelDataTypes.customerType);
        this.setState({ labels: data });
    }

    show(model: CustomerTypeModel) {
        this.popupRef.current?.instance.show();

        model = modelClone(model);
        const modelAny: any = model;

        for (const bottleType of serverDataService.bottleTypes) {
            const found = model.limits?.find(l => l.bottleTypeId === bottleType.id);
            modelAny[`limit_${bottleType.id}`] = found?.maxNumber;
        }

        this.setState({ model: modelClone(model) });
    }
    
    hide() {
        this.popupRef.current?.instance.hide();
    }

    onTagValueChanged(e: ValueChangedEvent) {
        const groups = e.value
            .filter((v: number) => e.previousValue.indexOf(v) === -1)
            .map((v: number) => this.state.labels.find(l => l.id === v)?.groupName)
            .filter((g: string) => g);

        const toRemove = e.previousValue
            .map((v: number) => this.state.labels.find(l => l.id === v))
            .filter((l: any) => groups.indexOf(l.groupName) !== -1)
            .map((l: any) => l.id);

        const newValues = e.value.filter((v: number) => toRemove.indexOf(v) === -1);

        const labels = this.state.labels.filter(l => newValues.indexOf(l.id) !== -1);

        const text = labels.map(l => l.labelText).join(stdSeparator);
        const model = this.state.model;

        model.label = text;
        model.labels = labels.map(l => ({ customerTypeId: model.id, labelId: l.id }));

        // 会再次引发事件，一定要判断条件
        if (toRemove.length > 0) {
            e.component.option("value", newValues);
        }
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const model = this.state.model,
                modelAny: any = model;

            model.limits = model.limits || [];

            for (const bottleType of serverDataService.bottleTypes) {
                const found = model.limits?.find(l => l.bottleTypeId === bottleType.id);
                if (found) {
                    found.maxNumber = modelAny[`limit_${bottleType.id}`];
                } else {
                    model.limits!.push({
                        customerTypeId: model.id,
                        bottleTypeId: bottleType.id,
                        maxNumber: modelAny[`limit_${bottleType.id}`]
                    });
                }
            }

            const res = model.id ?
                await customerTypeService.update(model) :
                await customerTypeService.create(model);

            if (res.succeeded) {
                this.hide();
                notifySuccess("已成功保存");
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup 
                popupRef={this.popupRef}
                title={"编辑"}
                width={900}
                height={380}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm 
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        formData={this.state.model} />
                </PopupContentBox>
            </RefPopup>
        )
    }

    renderTagBox() {
        const model = this.state.model;
        const defaultValue = model.labels ? model.labels.map((l: any) => l.labelId) : [];
        const store = new DataSource({
            store: this.state.labels,
            key: 'id',
            group: 'groupName'
        });

        return (
            <TagBox
                dataSource={store}
                defaultValue={defaultValue}
                valueExpr={"id"}
                displayExpr={"labelText"}
                grouped={true}
                groupRender={(e: any) => e.key || "未分类"}
                onValueChanged={this.onTagValueChanged}
            />
        )
    }
}