import React from "react";
import { employeeService, emptyDataSources, organizationService } from "../../api";
import { DataMap, dateAdd, enums, ListItem, today } from "../../base";
import { CustomerComplainModel, EmployeeRef, OrganizationRef } from "../../models";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { ValueChangedEvent as TextChangedEvent } from "devextreme/ui/text_box";
import { Button, DateBox, SelectBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { OrgTreeDropDown } from "../../components";
import { ValueChangedEvent } from "devextreme/ui/select_box";

export interface CustomerComplainToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (model: CustomerComplainModel, name: string) => void;
    onFiltering: (filterValue: any) => void;
}

interface CustomerComplainToolbarState {
    model: CustomerComplainModel;
    orgs: OrganizationRef[];
    allDelivers: EmployeeRef[];
    delivers: EmployeeRef[];
    organizationId?: number;
    deliverId?: string;
    startDate?: Date | string;
    endDate?: Date | string;
    customerKey?: string;
    handleStatus?: boolean;
}

export default class CustomerComplainToolbar
    extends React.Component<CustomerComplainToolbarProps, CustomerComplainToolbarState> {
    constructor(props: Readonly<CustomerComplainToolbarProps>) {
        super(props);

        const endDate = today();

        this.state = {
            model: {},
            orgs: [],
            allDelivers: [],
            delivers: [],
            startDate: dateAdd(endDate, -1, "m"),
            endDate: endDate,
        };

        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onCustomerChanged = this.onCustomerChanged.bind(this);
        this.onHandleStatusChanged = this.onHandleStatusChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount() {
        this.loadDataSources();
        setTimeout(this.onFiltering, 100);
    }

    async loadDataSources() {
        const dataSources = emptyDataSources();

        await Promise.all([
            (async () => dataSources.orgs = await organizationService.getRefs())(),
            (async () => dataSources.employees = await employeeService.getDelivers())(),
        ]);

        this.setState({
            orgs: dataSources.orgs,
            allDelivers: dataSources.employees,
            delivers: dataSources.employees
        });

    }

    setModel(model: CustomerComplainModel) {
        this.setState({ model: model });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onOrganizationChanged(organizationId?: number) {
        const delivers = organizationId ?
            this.state.allDelivers.filter(d => d.organizationId === organizationId) :
            this.state.allDelivers;

        const deliverId = delivers.find(d => d.id === this.state.deliverId) ? this.state.deliverId : undefined;

        this.setState({
            organizationId: organizationId,
            delivers: delivers,
            deliverId: deliverId
        });
    }

    onDeliverChanged(e: ValueChangedEvent) {
        this.setState({ deliverId: e.value });
    }

    onFiltering() {
        this.props.onFiltering(this.getFilterValue());
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { startDate, endDate, organizationId, deliverId, customerKey, handleStatus } = this.state;

        if (startDate) {
            filters.push(["complainTime", ">=", startDate]);
        }
        if (endDate) {
            filters.push(["complainTime", "<", dateAdd(new Date(endDate), 1)])
        }
        if (organizationId) {
            filters.push(["organizationId", "=", organizationId]);
        }
        if (deliverId) {
            filters.push(["deliverId", "=", deliverId]);
        }
        if (handleStatus !== undefined) {
            if (handleStatus) {
                filters.push(["handleTime", "<>", null]);
            } else {
                filters.push(["handleTime", "=", null]);
            }
        }
        if (customerKey) {
            filters.push([
                ["customer.gasCode", "contains", customerKey],
                "or",
                ["customer.name", "contains", customerKey]
            ]);
        }

        return filters.length > 0 ? filters : null;
    }

    onCustomerChanged(e: TextChangedEvent) {
        this.setState({ customerKey: e.value });
    }

    onHandleStatusChanged(e: ValueChangedEvent) {
        this.setState({ handleStatus: e.value });
    }

    render() {
        const { model, orgs, organizationId, delivers, deliverId, startDate, endDate, customerKey, handleStatus } = this.state;
        const permissions = this.props.permissions;

        return (
            <div className={"page-toolbar page-large-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu="false" text={"评价时间"}>
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={startDate}
                            placeholder="开始时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束时间"
                            showClearButton={true}
                            width={120}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={deliverId}
                            dataSource={delivers}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={160}
                            placeholder="送气工"
                            onValueChanged={this.onDeliverChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={handleStatus}
                            dataSource={handleStatusList}
                            displayExpr="text"
                            valueExpr={"value"}
                            showClearButton={true}
                            width={100}
                            placeholder={"处理情况"}
                            onValueChanged={this.onHandleStatusChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <TextBox
                            value={customerKey}
                            showClearButton={true}
                            placeholder="客户名称或卡号"
                            onValueChanged={this.onCustomerChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={!!permissions[enums.stdActions.create]}>
                        <Button
                            text="新增"
                            onClick={() => this.props.onButtonClick({}, "add")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={!!permissions[enums.stdActions.update]}>
                        <Button
                            disabled={!model.id}
                            text="编辑"
                            onClick={() => this.props.onButtonClick(model, "edit")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={!!permissions[enums.stdActions.delete]}>
                        <Button
                            disabled={!model.id}
                            text="删除"
                            onClick={() => this.props.onButtonClick(model, "delete")} />
                    </Item>
                </Toolbar>
            </div>
        );
    }

}

const handleStatusList: ListItem<boolean>[] = [
    { value: true, text: "已处理" },
    { value: false, text: "未处理" }
]