import { http, MessageBool } from "../base";
import { EmployeeRoleModel } from "../models";


class EmployeeRoleService {
    apiPath = "/api/employeerole";

    getByEmployeeId(employeeId: string): Promise<EmployeeRoleModel[]> {
        return http.getData(this.apiPath, { employeeId: employeeId });
    }

    save(employeeId: string, roles: EmployeeRoleModel[]): Promise<MessageBool> {
        return http.putData(`${this.apiPath}?employeeId=${employeeId}`, roles);
    }
}

export const employeeRoleService = new EmployeeRoleService();