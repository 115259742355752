import { BaseApiService, createStoreFromUrl, http } from "../base";
import { CpBatchModel, CpBatchRef } from "../models";

class CpBatchService extends BaseApiService<CpBatchModel> {
    moduleName = "cpbatch";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }

    getRefs(type: string) {
        return http.getData<CpBatchRef[]>(`${this.apiPath}/ref?type=${type}`)
    }
}

export const cpBatchService = new CpBatchService();
export const cpBatchStore = cpBatchService.getDxData();