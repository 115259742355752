import React from "react";
import { createContext, useContext, useEffect, useState } from "react";

export interface NavigationData {
    currentPath: string
}

export interface NavigationContextType { 
    navigationData?: NavigationData;
    setNavigationData?: (data: NavigationData) => void;
}

const NavigationContext: React.Context<NavigationContextType> = createContext({});
const useNavigation = () => useContext(NavigationContext);

function NavigationProvider(props: any) {
    const [navigationData, setNavigationData] = useState({});

    return (
        <NavigationContext.Provider value={{ navigationData, setNavigationData }} {...props} />
    );
}

function withNavigationWatcher(componentType: any) {
    return function(props: any) {
        const { path } = props.match;
        const { setNavigationData } = useNavigation();

        useEffect(() => {
            setNavigationData!({ currentPath: path });
        }, [path, setNavigationData])

        return React.createElement(componentType, props);
    }
}

export {
    NavigationContext,
    NavigationProvider,
    useNavigation,
    withNavigationWatcher
}