import React from "react";
import { Form, Popup } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import { customerCallbackService, organizationService } from "../../api";
import { alertError, alertWarning, modelClone, notifySuccess, StringMap } from "../../base";
import { CustomerPopup, PopupContentBox, RefPopup, StdForm, createPopupField, OrgTreeDropDown } from "../../components";
import { CustomerCallbackModel, OrganizationRef } from "../../models";

interface CallbackWithName extends CustomerCallbackModel {
    _customerName?: string;
}

export interface CustomerCallbackFormProps {
    onSaved: () => void;
}

interface CustomerCallbackFormState {
    items: IItemProps[];
    organizations: OrganizationRef[];
    model: CallbackWithName;
}

export default class CustomerCallbackForm
    extends React.Component<CustomerCallbackFormProps, CustomerCallbackFormState> {
    moduleName = "CustomerCallback";
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    customerPopupRef = React.createRef<CustomerPopup>();
    nameMap: StringMap = { "_customerName": "customerId" };
    
    constructor(props: Readonly<CustomerCallbackFormProps>) {
        super(props);

        this.state = {
            organizations: [],
            model: {},
            items: [
                {
                    dataField: 'organizationId',
                    render: () => {
                        return (
                            <OrgTreeDropDown
                                organizationId={this.state.model.organizationId}
                                organizations={this.state.organizations}
                                onValueChanged={(organizationId) => {
                                    this.state.model.organizationId = organizationId;
                                    this.setState({})
                                }} />
                        )
                    }
                },
                createPopupField("_customerName", this.customerPopupRef),
                { dataField: "customerName" },
                { dataField: "phoneNumber" },
                { dataField: "callbackTime", editorOptions: { type: "datetime" } },
                { dataField: "score", editorOptions: { min: 1, max: 10 } },
                { 
                    dataField: "content",
                    editorType: 'dxTextArea',
                    colSpan: 2,
                    editorOptions: { height: 80 }
                }
            ]
        };

        this.onCustomerSelected = this.onCustomerSelected.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.loadDataSources();
    }

    async loadDataSources() {
        const orgs = await organizationService.getRefs();
        this.setState({ organizations: orgs });
    }

    show(customerCallback: CallbackWithName) {
        this.popupRef.current?.instance.show();

        customerCallback = modelClone(customerCallback);
        customerCallback._customerName = customerCallback.customer!.name ?
            `${customerCallback.customer!.name}（${customerCallback.customer!.gasCode}）` : '';

        this.setState({ model: customerCallback });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    onCustomerSelected(customer: any) {
        const model = {
            ...this.state.model,
            customerId: customer.id,
            _customerName: `${customer.name}（${customer.gasCode}）`,
            customerName: customer.name,
            phoneNumber: customer.phoneNumber
        };

        this.setState({ model: model });
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (!isValid?.isValid) {
            return;
        } else if (!this.state.model.customerId) {
            alertWarning("请选择回访客户！");
            return;
        }

        const model = this.state.model;
        const res = model.id ?
            await customerCallbackService.update(model) :
            await customerCallbackService.create(model);

        if (res.succeeded) {
            notifySuccess("已成功保存");
            this.hide();
            this.props.onSaved();
        } else {
            alertError(res.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"编辑"}
                width={800}
                height={450}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        nameMap={this.nameMap}
                        formData={this.state.model} />
                </PopupContentBox>
                <CustomerPopup ref={this.customerPopupRef} onCustomerSelected={this.onCustomerSelected} />
            </RefPopup>
        )
    }
}