import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { employeeProfileService } from "../api";
import { alertError, NavigationItem } from "../base";
import { useAuth } from "./auth";
import { useNavigation } from "./navigation";

export interface FavoriteContextType {
    favorite?: NavigationItem[];
    inFavorite?: boolean;
    addFavorite?: (caption: string) => void;
    removeFavorite?: () => void;

    pageCaption?: string;
    setPageCaption?: (caption: string) => void;
}

const FavoriteContext: React.Context<FavoriteContextType> = createContext({});
const useFavorite = () => useContext(FavoriteContext);

function FavoriteProvider(props: any) {
    const { user } = useAuth();
    const { navigationData } = useNavigation();
    const [favorite, setFavorite] = useState<NavigationItem[]>([]);
    const [pageCaption, setPageCaption] = useState<string>();

    useEffect(function () {
        (async function () {
            if (user) {
                const json = await employeeProfileService.getFavorite();
                const data = JSON.parse(json || "[]");

                for (const item of data) {
                    item.id = `favorite_${item.path}`;
                }
                
                setFavorite(data);
            }
        })();
    }, [user]);

    const inFavorite = useMemo(function () {
        if (!navigationData?.currentPath) { return false; }

        const index = favorite.findIndex(
            f => f.path?.toLowerCase() === navigationData.currentPath.toLowerCase());

        return index !== -1;
    }, [favorite, navigationData]);

    const saveFavorite = useCallback(async function () {
        const json = JSON.stringify(favorite);
        const result = await employeeProfileService.saveFavorite(json);

        if (result.succeeded) {
            setFavorite([...favorite]);
        } else {
            alertError(result.message!);
        }
    }, [favorite]);

    const addFavorite = useCallback(function (caption: string) {
        if (navigationData?.currentPath) {
            favorite.push({ path: navigationData.currentPath, text: caption });
            saveFavorite();
        }
    }, [favorite, navigationData, saveFavorite]);

    const removeFavorite = useCallback(function () {
        if (navigationData?.currentPath) {
            const index = favorite.findIndex(
                f => f.path?.toLowerCase() === navigationData.currentPath.toLowerCase());

            if (index !== -1) {
                favorite.splice(index, 1);
                saveFavorite();
            }
        }
    }, [favorite, navigationData, saveFavorite]);

    const context = { favorite, inFavorite, removeFavorite, addFavorite, pageCaption, setPageCaption };

    return (
        <FavoriteContext.Provider value={context} {...props} />
    )
}

export {
    FavoriteContext,
    FavoriteProvider,
    useFavorite
}