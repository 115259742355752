import { tokenStorage } from "./token-storage";

export async function download(url: string, fileName: string) {
    const resp = await fetch(url, {
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: { Authorization: `bearer ${tokenStorage.token}` },
        method: 'get',
        mode: 'cors',
        redirect: 'follow'  
    });

    if (resp.ok) {
        const reader = new FileReader();
        reader.readAsDataURL(await resp.blob());
        reader.onload = function (e: ProgressEvent<FileReader>) {
           const a = document.createElement("a");
           a.download = fileName;
           a.href = e.target?.result as string;
           document.body.appendChild(a);
           a.click();
           a.remove();
        }
    }
}