import React from "react";
import { DataGrid } from "devextreme-react";
import { BottleRentModel, CustomerTypeRef, EmployeeRef, OrganizationRef } from "../../models";
import { bottleRentDeletedStore, bottleRentService, bottleRentStore, customerTypeService, employeeService, organizationService } from "../../api";
import { createGridOptions, ExportModel, remoteReadOnlyGridOptions, setColumnDataSource, StdGrid } from "../../components";
import { IColumnProps } from "devextreme-react/data-grid";
import { ExportingEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import { authorizationService, baseApiUrl, createEmptyStore, enums, fmonth, globalPopupManager, globalPopupNames, ModuleConfig, StringMap } from "../../base";
import CustomStore from "devextreme/data/custom_store";

export interface BottleRentGridProps {
    onButtonClick: (model: BottleRentModel, name: string) => void;
    onModelSelected: (model: BottleRentModel) => void;
}

interface BottleRentGridState {
    employees: EmployeeRef[];
    customerTypes: CustomerTypeRef[];
    orgs: OrganizationRef[];
    columns: IColumnProps[];
    store: CustomStore;
}

export default class BottleRentGrid extends React.Component<BottleRentGridProps, BottleRentGridState> {
    objectName = "BottleRent";
    moduleName: ModuleConfig = {
        moduleName: "BottleRent",
        children: {
            "customer": "Customer"
        }
    };

    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("bottleRents", this.gridRef, remoteReadOnlyGridOptions);

    nameMap: StringMap = {
        "customer.name": "customerId"
    }

    constructor(props: Readonly<BottleRentGridProps>) {
        super(props);

        this.state = {
            employees: [],
            customerTypes: [],
            orgs: [],
            store: createEmptyStore(),
            columns: [
                {
                    dataField: 'customer.name',
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.name}
                        </span>
                    )
                },
                {
                    dataField: 'customer.gasCode',
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.gasCode}
                        </span>
                    )
                },
                {
                    dataField: 'customer.typeId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                {
                    dataField: 'customer.organizationId',
                    allowFiltering: false,
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { 
                    dataField: "startTime",
                    cellRender: function(e: any) {
                        return (<div>{fmonth(e.data.startTime)}</div>)
                    }
                },
                { 
                    dataField: "endTime",
                    cellRender: function(e: any) {
                        return (<div>{fmonth(e.data.endTime)}</div>)
                    }
                },
                { dataField: "amount", dataType: "number", format: "currency" },
                {
                    dataField: "operator",
                    allowFiltering: false,
                    lookup: { dataSource: [], displayExpr: 'nameAndCode', valueExpr: 'id' }
                },
                { dataField: "chargeTime", dataType: "datetime" },
                { dataField: "note" }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
        this.gridOptions.onExporting = this.onExporting.bind(this);
        this.gridOptions.export!.allowExportSelectedData = false;
        this.gridOptions.export!.enabled = false;        
        this.gridOptions.summary = {
            totalItems: [
                { column: "amount", summaryType: "sum", valueFormat: "currency", displayFormat: "计：{0}" }
            ],
        };
    }

    componentDidMount() {
        this.authorizeImport();
        this.loadData();
    }

    async authorizeImport() {
        if (await authorizationService.authorize(this.objectName, enums.stdActions.export)) {
            this.gridOptions.export!.enabled = true;
            this.gridRef.current?.instance.option("export.enabled", true);
        }
    }

    async loadData() {
        var types: CustomerTypeRef[] = [],
            employees: EmployeeRef[] = [],
            orgs: OrganizationRef[] = [];

        await Promise.all([
            (async () => types = await customerTypeService.getRefs())(),
            (async () => employees = await employeeService.getAllRefs())(),
            (async () => orgs = await organizationService.getRefs())()
        ]);

        setColumnDataSource(this.state.columns, "operator", employees);
        setColumnDataSource(this.state.columns, "customer.typeId", types);
        setColumnDataSource(this.state.columns, "customer.organizationId", orgs);

        this.setState({
            customerTypes: types,
            employees: employees,
            orgs: orgs
        });
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    filter(filterValue: any, showDeleted: boolean) {
        if (showDeleted && this.state.store !== bottleRentDeletedStore) {
            this.setState({ store: bottleRentDeletedStore }, () => this._filter(filterValue));
        } else if (!showDeleted && this.state.store !== bottleRentStore) {
            this.setState({ store: bottleRentStore }, () => this._filter(filterValue))
        } else {
            this._filter(filterValue);
        }
    }

    _filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }


    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});
        }
    }
    
    onExporting(e: ExportingEvent) {
        const args: ExportModel = {
            moduleName: this.moduleName.moduleName,
            exportUrl: `${baseApiUrl}${bottleRentService.apiPath}/export`,
            columns: this.state.columns,
            keys: e.component.getSelectedRowKeys(),
            filter: e.component.getCombinedFilter(),
            showDeleted: this.state.store === bottleRentDeletedStore
        };

        e.cancel = true;
        globalPopupManager.show(globalPopupNames.exportPopup, args);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columnNameMap={this.nameMap}
                columns={this.state.columns}
                toolbarHeight={80} />
        )
    }
}