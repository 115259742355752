import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React from "react";
import { bottleFlowReportService } from "../../../api/reports/bottle-flow-report.service";
import { createEmptyStore } from "../../../base";
import { ContentBlock, createGridOptions, remoteReadOnlyGridOptions, StdGrid } from "../../../components";
import { TurnoverReportCondition } from "../../../models";
import AuthorizedComponent from "../../authorized-component";
import TurnoverToolbar from "./turnover-toolbar";

interface TurnoverReportPageState {
    store: CustomStore;
}

export default class TurnoverReportPage extends AuthorizedComponent<any, TurnoverReportPageState> {
    objectName = "TurnoverReport";
    moduleName = "";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("turnoverReport", this.gridRef, remoteReadOnlyGridOptions);

    columns: IColumnProps[] = [
        { dataField: "code", caption: "钢印号" },
        { dataField: "bottleTypeName", caption: "气瓶类型" },
        { dataField: "deliverCount", caption: "配送数" },
        { dataField: "fillingCount", caption: "充装数" },
        { 
            dataField: "rate", 
            caption: "周转率", 
            format: function(value: number) { return Math.round(value * 100) + '%'; }
        }
    ]

    constructor(props: any) {
        super(props);

        this.state = {
            store: createEmptyStore()
        }

        this.gridOptions.filterRow!.visible = false;
        this.gridOptions.summary = {
            totalItems: [
                { column: 'deliverCount', summaryType: 'sum', displayFormat: "共：{0}" },
                { column: 'fillingCount', summaryType: 'sum', displayFormat: "共：{0}" },
                { 
                    column: 'rate', 
                    summaryType: 'server', 
                    displayFormat: "{0}", 
                    valueFormat: function(value: number) { return Math.round(value * 100) + '%'; } 
                }
            ]
        }

        this.filter = this.filter.bind(this);
    }

    filter(condition: TurnoverReportCondition) {
        const store = bottleFlowReportService.getTurnover(condition);
        this.setState({ store: store });
    }

    render() {
        return (
            <ContentBlock caption="气瓶周转率报表">
                <TurnoverToolbar onFiltering={this.filter} />
                <StdGrid
                    moduleName={this.moduleName}
                    gridRef={this.gridRef}
                    dataSource={this.state.store}
                    options={this.gridOptions}
                    columns={this.columns} />
            </ContentBlock>
        )
    }
}