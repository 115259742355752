import { BaseApiService, CachableApiService, createStoreFromApi, DataMap, http } from "../base";
import { CpDataSourceModel, CpDataSourceRef } from "../models";

class CpDataSourceService extends CachableApiService {
    apiPath = "/api/cpdatasource";
    cacheSeconds = 60;

    getMap(): Promise<DataMap<CpDataSourceRef[]>> {
        return this.getCached(() => http.getData(`${this.apiPath}/map`), this.cacheSeconds);
    }

    async getDataSource(type: string): Promise<CpDataSourceRef[]> {
        const map = await this.getMap();
        return map[type] || [];
    }    
}

class CpTypedDataSourceService extends BaseApiService<CpDataSourceModel> {
    moduleName = "cpdatasource";
    type: string;

    constructor(type: string) {
        super();

        this.type = type;
    }
    
    searchAll(): Promise<CpDataSourceModel[]> {
        return http.getData(`${this.apiPath}/bytype?type=${this.type}`);
    }
}

export const cpDataSourceService = new CpDataSourceService();
export const cpBottleTypeService = new CpTypedDataSourceService("BottleType");
export const cpVendorService = new CpTypedDataSourceService("Vendor");
export const cpOrganizationService = new CpTypedDataSourceService("Organization");
export const cpCheckerService = new CpTypedDataSourceService("Checker");

export const cpBottleTypeStore = createStoreFromApi(cpBottleTypeService, "id");
export const cpVendorStore = createStoreFromApi(cpVendorService, "id");
export const cpOrganizationStore = createStoreFromApi(cpOrganizationService, "id");
export const cpCheckerStore = createStoreFromApi(cpCheckerService, "id");