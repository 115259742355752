import { RouteConfig } from "./route-model";
import StockInitPage from "../pages/stock-init/stock-init";
import StocktakingPage from "../pages/stocktaking/stocktaking";

export const stockRoutes: RouteConfig[] = [
    {
        path: '/stock-init',
        component: StockInitPage
    },
    {
        path: '/stocktaking',
        component: StocktakingPage
    }
];