import { Form, Popup } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import React from "react";
import { organizationService, vehicleUserService } from "../../api";
import { alertError, dataSources, enums, modelClone, notifySuccess } from "../../base";
import { OrgTreeDropDown, PopupContentBox, RefPopup, StdForm } from "../../components";
import { OrganizationRef, VehicleUserModel } from "../../models";

export interface VehicleUserFormProps {
    onSaved: () => void;
}

interface VehicleUserFormState {
    model: VehicleUserModel;
    orgs: OrganizationRef[];
    items: IItemProps[];
}

export default class VehicleUserForm extends React.Component<VehicleUserFormProps, VehicleUserFormState> {
    moduleName = "VehicleUser";
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();

    constructor(props: Readonly<VehicleUserFormProps>) {
        super(props);

        this.state = {
            model: {},
            orgs: [],
            items: [
                { dataField: 'name' },
                {
                    dataField: 'post',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                        dataSource: [
                            { value: enums.vehiclePosts.all, text: '司机、押运员' },
                            ...dataSources.vehiclePosts
                        ],
                        displayExpr: 'text',
                        valueExpr: 'value'
                    }
                },
                {
                    dataField: 'organizationId',
                    render: () => {
                        return (
                            <OrgTreeDropDown
                                organizationId={this.state.model.organizationId}
                                organizations={this.state.orgs}
                                onValueChanged={(organizationId) => {
                                    this.state.model.organizationId = organizationId;
                                    this.setState({})
                                }} />
                        )
                    }
                },
                { dataField: 'code' },
                { dataField: 'idNo' },
                { dataField: 'phoneNumber' }
            ]
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount(): void {
        this.loadOrgs();
    }

    async loadOrgs() {
        const data = await organizationService.getRefs();
        this.setState({ orgs: data });
    }

    show(model: VehicleUserModel) {
        this.popupRef.current?.instance.show();

        this.setState({ model: modelClone(model) });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const model = this.state.model;
            const res = model.id ?
                await vehicleUserService.update(model) :
                await vehicleUserService.create(model);

            if (res.succeeded) {
                this.hide();
                notifySuccess("已成功保存");
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"编辑"}
                width={400}
                height={440}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={1}
                        items={this.state.items}
                        formData={this.state.model} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}