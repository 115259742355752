import { BaseApiService, createStoreFromUrl, http, MessageBool } from "../base";
import { CpScrapModel } from "../models";

class CpScrapService extends BaseApiService<CpScrapModel> {
    moduleName = "cpscrap";

    getDxData(batchId?: number) {
        return createStoreFromUrl(`${this.apiPath}/dx?batchId=${batchId || ''}`, 'id');
    }

    getTemplate(batchId: number) {
        return http.getData(`${this.apiPath}/template?batchId=${batchId}`);
    }

    deleteSelected(ids: number[]) {
        return http.deleteData<MessageBool>(`${this.apiPath}?ids=${ids}`);
    }
}

export const cpScrapService = new CpScrapService();
export const cpScrapStore = cpScrapService.getDxData();