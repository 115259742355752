import { RouteConfig } from "./route-model";
import CustomerPage from "../pages/customer/customer";
import CustomerCallbackPage from "../pages/customer/customer-callback";
import CustomerRequestPage from "../pages/customer/customer-request";
import RecycleRequestPage from "../pages/customer/recycle-request";
import CustomerComplainPage from "../pages/customer/customer-complain";
import OrderEvaluationPage from "../pages/customer/order-evaluation";

export const customerRoutes: RouteConfig[] = [
    {
        path: '/customer',
        component: CustomerPage
    },
    {
        path: '/customer-callback',
        component: CustomerCallbackPage
    },
    {
        path: '/customer-request',
        component: CustomerRequestPage
    },
    {
        path: '/recycle-request',
        component: RecycleRequestPage
    },
    {
        path: '/customer-complain',
        component: CustomerComplainPage
    },
    {
        path: '/order-evaluation',
        component: OrderEvaluationPage
    }
];
