import React from "react";
import { Popup, ScrollView } from "devextreme-react";
import DataGrid, { IColumnProps, IDataGridOptions } from "devextreme-react/data-grid";
import { createBoolColumn, StdGrid, RefPopup, createImageColumn } from "../../components";
import { DangerModel } from "../../models";
import { dangerService } from "../../api";

interface DangerState {
    danger: DangerModel;
}

export default class DangerDetail extends React.Component<any, DangerState> {
    moduleName = "DangerItem";

    popupRef = React.createRef<Popup>();
    gridRef = React.createRef<DataGrid>();
    gridOptions: IDataGridOptions = {
        columnAutoWidth: true,
        paging: { enabled: false }
    };

    columns: IColumnProps[] = [
        { dataField: "itemName" },
        createBoolColumn({ dataField: "fixed" }),
        createImageColumn({ dataField: "photo" }),
        createImageColumn({ dataField: "fixedPhoto" }),
        { dataField: "note" }
    ];

    constructor(props: any) {
        super(props);

        this.state = { danger: {} };
    }

    async show(model: DangerModel) {
        this.popupRef.current?.instance.show();

        const danger = await dangerService.get(model.id!);
        this.setState({ danger: danger });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"隐患整改详情"}
                width={1200}
                height={600}>
                <ScrollView height={"100%"} scrollByContent={true} showScrollbar={"always"}>
                    <StdGrid
                        moduleName={this.moduleName}
                        gridRef={this.gridRef}
                        dataSource={this.state.danger.items}
                        columns={this.columns}
                        options={this.gridOptions}
                        autoHeight={true}
                    />
                </ScrollView>
            </RefPopup>
        );
    }
}