import { http } from "../base";
import { OrderCheckItemModel } from "../models";

class OrderCheckItemService {
    apiPath = "/api/ordercheckitem";

    getByOrderId(orderId: number) {
        return http.getData<OrderCheckItemModel[]>(`${this.apiPath}/byorder?orderId=${orderId}`);
    }
}

export const orderCheckItemService = new OrderCheckItemService();