import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React from "react";
import { serverDataService } from "../../../api";
import { customerReportService } from "../../../api/reports";
import { baseApiUrl, createEmptyStore, download, globalPopupManager, globalPopupNames, http } from "../../../base";
import { ContentBlock, createGridOptions, remoteReadOnlyGridOptions, StdGrid } from "../../../components";
import { ActiveCustomerReportCondition } from "../../../models";
import AuthorizedComponent from "../../authorized-component";
import ActiveCustomerToolbar from "./active-customer-toolbar";

interface ActiveCustomerReportPageState {
    store: CustomStore;
    columns: IColumnProps[];
}

export default class ActiveCustomerReportPage
    extends AuthorizedComponent<any, ActiveCustomerReportPageState> {

    objectName = "ActiveCustomer";
    moduleName = "";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("activeCustomerReport", this.gridRef, remoteReadOnlyGridOptions);

    constructor(props: any) {
        super(props);

        this.state = {
            store: createEmptyStore(),
            columns: [
                { 
                    dataField: "name", 
                    caption: "客户名称",
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.callcenter(e.data.id)}>
                            {e.data.name}
                        </span>
                    ) 
                },
                { 
                    dataField: "gasCode", 
                    caption: "用气卡号",
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.callcenter(e.data.id)}>
                            {e.data.gasCode}
                        </span>
                    )
                },
                { dataField: "typeName", caption: "客户类型" },
                { dataField: "organizationName", caption: "供应站" },
                { dataField: "countyName", caption: "行政区域" },
                { dataField: "streetName", caption: "街道" },
                { dataField: "lastTime", caption: "最后订单时间", dataType: "datetime" },
                { dataField: "orderCount", caption: "订单数量" },
                ...serverDataService.gasBottleTypes.map(t => ({
                    dataField: `item_${t.id}`,
                    caption: t.name,
                    allowGrouping: false,
                    allowSorting: false,
                    allowFiltering: false,
                    cellRender: (e: any) => {
                        const count = e.data.bottleCount[t.id!] || 0;
                        return (<span>{count}</span>)
                    }
                }))
            ]
        }

        this.gridOptions.filterRow!.visible = false;
        this.gridOptions.export!.enabled = false;
        this.gridOptions.summary = {
            totalItems: [
                { column: "orderCount", summaryType: "sum", displayFormat: "计：{0}" },
                ...serverDataService.gasBottleTypes.map((t) => ({
                    column: `item_${t.id}`, summaryType: "sum", displayFormat: "计：{0}"
                })),
            ]
        }

        this.filter = this.filter.bind(this);
        this.export = this.export.bind(this);
    }

    callcenter(id: number) {
        globalPopupManager.show(globalPopupNames.callCenter, { id: id });
    }

    filter(condition: ActiveCustomerReportCondition) {
        const store = customerReportService.getActiveReport(condition);
        this.setState({ store: store });
    }

    async export(condition: ActiveCustomerReportCondition) {
        await download(
            `${baseApiUrl}${customerReportService.apiPath}/active/export?${http.serialize([condition])}`,
            "活跃客户及配送数量统计报表.xlsx");
    }

    render() {
        return (
            <ContentBlock caption="活跃客户及配送数量统计报表">
                <ActiveCustomerToolbar onFiltering={this.filter} onExporting={this.export} />
                <StdGrid
                    moduleName={this.moduleName}
                    gridRef={this.gridRef}
                    dataSource={this.state.store}
                    options={this.gridOptions}
                    columns={this.state.columns} />
            </ContentBlock>
        )
    }
}