import React from "react";
import { Popup } from "devextreme-react";
import Form, { IItemProps } from "devextreme-react/form";
import { bottleTypeService, stockInitService } from "../../api";
import { alertError, modelClone, notifySuccess } from "../../base";
import { PopupContentBox, RefPopup, StdForm } from "../../components";
import { BottleTypeModel, OrganizationStockInitModel } from "../../models";

export interface StockInitFormProps {
    onSaved: () => void;
}

interface StockInitFormState {
    model: OrganizationStockInitModel;
    bottleTypes: BottleTypeModel[];
    items: IItemProps[];
}

export default class StockInitForm extends React.Component<StockInitFormProps, StockInitFormState> {
    moduleName = 'StockInit';

    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();

    constructor(props: Readonly<StockInitFormProps>) {
        super(props);

        this.state = {
            model: {},
            bottleTypes: [],
            items: [
                { dataField: 'organizationName', label: { text: '供应站' }, editorOptions: { readOnly: true } }     
            ]
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount(): void {
        this.loadBottleTypes();
    }

    async loadBottleTypes() {
        const bottleTypes = await bottleTypeService.getRefs();
        const items: IItemProps[] = [
            ...this.state.items,
            ...bottleTypes.map(t => ({ 
                dataField: `bottle_${t.id}`, 
                label: { text: `${t.name}瓶` },
                editorType: 'dxNumberBox',
                validationRules: [{ type: 'required', message: `${t.name}瓶不能为空`}]
            })),
            ...bottleTypes.map(t => ({ 
                dataField: `gas_${t.id}`, 
                label: { text: `${t.name}气` },
                editorType: 'dxNumberBox',
                validationRules: [{ type: 'required', message: `${t.name}气不能为空`}]
            }))
        ];

        this.setState({ 
            bottleTypes: bottleTypes,
            items: items
        });
    }

    show(model: OrganizationStockInitModel) {
        this.popupRef.current?.instance.show();
        
        model = modelClone(model);
        const modelAny: any = model;
        
        for (const bottleType of this.state.bottleTypes) {
            const found = model.stocks?.find(s => s.bottleTypeId === bottleType.id);
            modelAny[`bottle_${bottleType.id}`] = found?.initBottles || "";
            modelAny[`gas_${bottleType.id}`] = found?.initGas || "";
        }

        this.setState({ model: model });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const model = this.state.model, 
                modelAny: any = model;

            model.stocks = model.stocks || [];

            for (const bottleType of this.state.bottleTypes) {
                const found = model.stocks?.find(s => s.bottleTypeId === bottleType.id);
                if (found) {
                    found.initBottles = modelAny[`bottle_${bottleType.id}`];
                    found.initGas = modelAny[`gas_${bottleType.id}`];
                } else {
                    model.stocks!.push({
                        organizationId: model.organizationId,
                        bottleTypeId: bottleType.id,
                        initBottles: modelAny[`bottle_${bottleType.id}`],
                        initGas:modelAny[`gas_${bottleType.id}`]
                    });
                }
            }

            const res = await stockInitService.save(model);
            if (res.succeeded) {
                this.hide();
                notifySuccess("已成功保存");
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title="编辑"
                width={800}
                height={320}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        formData={this.state.model} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}