import React from "react";
import DropDownButton from "devextreme-react/drop-down-button";
import MultiImageUploader from "../file/multi-image-uploader";
import { baseApiUrl, temporaryUploadUrl } from "../../base";

export interface MultiFormImageUploaderProps {
    formData: any;
    dataField: string;
    sourceField: string;
    id: string;
    uploadUrl?: string;
}

export default class MultiFormImageUploader extends React.Component<MultiFormImageUploaderProps, any> {
    constructor(props: Readonly<MultiFormImageUploaderProps>) {
        super(props);

        this.renderImageUploader = this.renderImageUploader.bind(this);
        this.onUploaded = this.onUploaded.bind(this);
        this.onRemoved = this.onRemoved.bind(this);

        let fileNames = props.formData[props.dataField] || [];
        if (typeof(fileNames) === "string") {
            fileNames = fileNames
                .split(';')
                .filter(n => n.trim())
                .map(n => `${baseApiUrl}/api/file?name=${n}`);
        }

        props.formData[props.sourceField] = fileNames;
    }

    onUploaded(tmpName: string, _source: any) {
        const { formData, dataField } = this.props;

        formData[dataField] = formData[dataField] ? 
            formData[dataField] + ';' + tmpName : tmpName;

        // formData[sourceField].push(source);

        this.setState({});
    }

    onRemoved(index: number, _source: any) {
        const { formData, dataField } = this.props;
        // formData[sourceField].splice(index, 1);

        const arr = formData[dataField].split(';');
        arr.splice(index, 1);
        formData[dataField] = arr.join(";");

        this.setState({});
    }

    render() {
        const { formData, dataField } = this.props;
        let fileNames = formData[dataField] || [];
        if (typeof(fileNames) === "string") {
            fileNames = fileNames.split(';');
        }

        const name = fileNames.length > 1 ? "多张图片..." :
            fileNames.length === 1 ? fileNames[0].split('/').pop() : 
            "请选择要上传的图片...";

        return (
            <DropDownButton 
                width={"100%"}
                className={"file-dropdown"}
                text={name}
                dropDownContentRender={this.renderImageUploader} />
        )
    }

    renderImageUploader() {
        const { formData, sourceField, id, uploadUrl } = this.props;

        const sources = formData[sourceField];
        const url = `${baseApiUrl}${uploadUrl || temporaryUploadUrl}`;

        return (
            <MultiImageUploader
                id={id}
                uploadUrl={url} 
                sources={sources}
                onUploaded={this.onUploaded}
                onRemoved={this.onRemoved} />
        );
    }
}