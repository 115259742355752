import React from "react";
import { Button, SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { DataMap, dataSources, User } from "../../base";
import { BottleModel } from "../../models";
import { ValueChangedEvent } from "devextreme/ui/select_box";

export interface BottleToolbarProps {
    permissions: DataMap<boolean>;
    user?: User;
    onButtonClick: (model: BottleModel, name: string) => void;
    onFiltering: (isPorcelainCode?: boolean) => void;
}

interface BottleToolbarState {
    isPorcelainCode?: boolean;
    model: BottleModel;
}

export default class BottleToolbar extends React.Component<BottleToolbarProps, BottleToolbarState> {
    constructor(props: Readonly<BottleToolbarProps>) {
        super(props);

        this.state = {
            model: {}
        };

        this.onPorcelainCodeChanged = this.onPorcelainCodeChanged.bind(this);
    }

    setModel(model: BottleModel) {
        this.setState({ model: model });
    }

    onPorcelainCodeChanged(event: ValueChangedEvent) {
        this.setState({ isPorcelainCode: event.value });
        this.props.onFiltering(event.value);
    }

    render() {
        const { user, onButtonClick } = this.props;
        const { model, isPorcelainCode } = this.state;

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            dataSource={dataSources.trueFalseList}
                            displayExpr={"text"}
                            valueExpr={"value"}
                            value={isPorcelainCode} 
                            showClearButton={true}
                            placeholder="是否陶瓷码"
                            onValueChanged={this.onPorcelainCodeChanged}
                            />
                    </Item>
                    {user?.isAdmin && (
                        <Item location={"after"} locateInMenu={"auto"}>
                            <Button 
                                text="新增"
                                onClick={() => onButtonClick({}, "add")} />
                        </Item>
                    )}
                    {user?.isAdmin && (
                        <Item location={"after"} locateInMenu="auto">
                            <Button
                                disabled={!model?.id}
                                text="编辑"
                                onClick={() => onButtonClick(model, "edit")} />
                        </Item>
                    )}
                </Toolbar>
            </div>
        );
    }
}