import { useMemo } from "react";
import { useServerData } from "./server-data";
import { IColumnProps } from "devextreme-react/tree-list";
import { ModuleData, ModuleConfig, ServerData, StringMap } from "../base";

export function treeColumnFill(
    moduleName: string | ModuleConfig,
    columns: IColumnProps[],
    map?: StringMap,
    serverData?: ServerData) {
    if (serverData) {
        const moduleData = new ModuleData(serverData, moduleName);

        for (const c of columns) {
            if (!c.dataField) {
                continue;
            }

            if (!c.caption) {
                const name = map ? map[c.dataField] || c.dataField : c.dataField;
                c.caption = moduleData.findLabel(name);
            }

            if (!c.dataType) {
                const type = moduleData.findPropType(c.dataField);
                c.dataType = type;
            }

            c.validationRules = moduleData.getValidationRules(c.dataField).concat(c.validationRules || []);
        }
    }
}

export function useTreeColumnFill() {
    const serverData = useServerData().serverData;

    return useMemo(() => {
        return function (
            moduleName: string | ModuleConfig,
            columns: IColumnProps[],
            map?: StringMap) {
            return treeColumnFill(moduleName, columns, map, serverData);
        }
    }, [serverData]);
}