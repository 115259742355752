import { createStoreFromApi, http, BaseApiService } from "../base";
import { VehicleModel, VehicleRef } from "../models";

class VehicleService extends BaseApiService<VehicleModel> {
    moduleName = "vehicle";
    cacheSeconds = 30;

    async searchAll(): Promise<VehicleModel[]> {
        const list = await http.getData(this.apiPath);

        for (const item of list) {
            item.driverString = item.drivers?.map((d: any) => d.text)?.join("，");
            item.supercargoString = item.supercargos?.map((d: any) => d.text)?.join("，");
            item.organizationIds = item.organizations?.map((o: any) => o.organizationId);
            item.organizationIdString = ',' + item.organizationIds.join(',') + ',';
        }

        return list;
    }

    getRefs(): Promise<VehicleRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/refs`), this.cacheSeconds, true);
    }
}

export const vehicleService = new VehicleService();
export const vehicleStore = createStoreFromApi(vehicleService);