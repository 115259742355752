import React from "react";
import { SecurityCodePrintService, UsbPrinter, tscPrinter } from "../../base/tsc-plugin";
import { Button, Form, Popup } from "devextreme-react";
import { alertError, alertWarning, confirm2 } from "../../base";
import { CustomerBatchModel } from "../../models";
import RefPopup from "../popup/ref-popup";
import { PopupContentBox } from "../layouts";
import { Item, Label } from "devextreme-react/form";
import { customerService } from "../../api";

interface SecurityCodePopupProps {
    onSaved: () => void;
}

interface SecurityCodePopupState {
    customers: CustomerBatchModel[];
    printers: UsbPrinter[];
    formData: any;
    message?: string;
    printing: boolean;
    cancelPrint: boolean;
}

export default class SecurityCodePopup
    extends React.Component<SecurityCodePopupProps, SecurityCodePopupState> {
    popupRef = React.createRef<Popup>();
    numbers: number[] = [1, 2, 3, 4, 5];

    constructor(props: Readonly<SecurityCodePopupProps>) {
        super(props);

        this.state = {
            customers: [],
            printers: [],
            formData: { copies: 1 },
            printing: false,
            cancelPrint: false
        };

        this.print = this.print.bind(this);
        this.cancel = this.cancel.bind(this);
        this.cancelPrint = this.cancelPrint.bind(this);
    }

    async getPrinters() {
        const printers = await tscPrinter.getUsbPrinters() || [];
        const newState: any = { printers: printers };

        if (printers.length === 1) {
            newState.formData = { printer: printers[0].USBPath, copies: this.state.formData.copies };
        }

        this.setState(newState);
    }

    async show(customers: CustomerBatchModel[]) {
        this.popupRef.current?.instance.show();
        this.getPrinters();

        this.setState({
            customers: customers,
            cancelPrint: false,
            printing: false,
            message: ''
        });
    }

    async hide() {
        if (this.state.printing) {
            this.setState({
                cancelPrint: true
            });
        }

        this.popupRef.current?.instance.hide();
    }

    async print() {
        const testPrinter: UsbPrinter = { USBName: 'te344', USBPath: 'Test' };

        if (!this.state.formData.printer) {
            if (this.state.printers.length) {
                alertError("未选择打印机");
                return;
            }
            else if (!await confirm2("未发现打印机，是否模拟打印流程？")) {
                return;
            }
        }

        if (this.state.formData.printer && !await confirm2("确定打印选中的条码吗？")) {
            return;
        }

        const gasCodes = this.state.customers.map(c => c.gasCode);
        const printer = this.state.printers.find(p => p.USBPath === this.state.formData.printer) || testPrinter;
        const service = new SecurityCodePrintService(printer);
        tscPrinter.continueOnError = printer === testPrinter;

        try {
            this.setState({
                printing: true,
                message: `准备开始打印，共计【${gasCodes.length}】户...`
            });

            await service.prepare();

            for (let i = 0; i < gasCodes.length; i++) {
                if (this.state.cancelPrint) {
                    alertWarning("打印已取消");
                    this.setState({ message: '打印已取消', printing: false, cancelPrint: false });
                    break;
                }

                const gasCode = gasCodes[i];

                await service.print(gasCode, this.state.formData.copies);

                this.setState({
                    message: gasCodes.length === i + 1 ? '打印完成' : `打印中，剩余【${gasCodes.length - i - 1}】户...`,
                    printing: gasCodes.length > i + 1
                });
            }
        } catch (e: any) {
            this.setState({ message: `打印失败，错误信息：${e}`, cancelPrint: false, printing: false });
            alertError(`打印失败，错误信息：${e}`);
        } finally {
            try {
                await customerService.setSecurityCodePrintedAsync(this.state.customers.map(c => c.id));
            } catch (e1) { }

            this.props.onSaved();
        }
    }

    async cancelPrint() {
        if (await confirm2("确定要取消打印吗？")) {
            this.setState({ cancelPrint: true });
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title="公示牌二维码打印"
                width={400}
                height={300}
                showCloseButton={false}>
                <PopupContentBox
                    saveButtonText="打印"
                    onSave={this.print}
                    onCancel={this.cancel}>
                    <div>
                        {this.state.message && (<div className="code-print-message">
                            {this.state.message} &nbsp;&nbsp;
                            <Button
                                type="danger"
                                visible={this.state.printing && !this.state.cancelPrint}
                                text="取消"
                                onClick={this.cancelPrint} />
                        </div>)}
                        <Form formData={this.state.formData} labelLocation="left">
                            <Item dataField={"printer"} editorType={"dxSelectBox"}
                                editorOptions={{ dataSource: this.state.printers, displayExpr: 'USBName', valueExpr: 'USBPath' }}>
                                <Label text={"打印机"} />
                            </Item>
                            <Item dataField={"copies"} editorType={"dxSelectBox"} editorOptions={{ dataSource: this.numbers }}>
                                <Label text={"份数"} />
                            </Item>
                        </Form>
                    </div>
                </PopupContentBox>
            </RefPopup>
        );
    }
}