import { withNavigationWatcher } from '../hooks/navigation';
import HomePage from '../pages/home/home';
import { RouteConfig } from './route-model';
import { orgRoutes } from './org';
import { bottleRoutes } from "./bottle";
import { customerRoutes } from "./customer";
import { adminRoutes } from "./admin";
import { orderRoutes } from "./order";
import { securityRoutes } from "./security";
import { announcementRoutes } from "./announcement"
import { stockRoutes } from "./stock";
import { reportRoutes } from './report';
import { codePrintRoutes } from './code-print';

const routes: RouteConfig[] = [
    ...orgRoutes,
    ...bottleRoutes,
    ...customerRoutes,
    ...adminRoutes,
    ...orderRoutes,
    ...securityRoutes,
    ...announcementRoutes,
    ...stockRoutes,
    ...reportRoutes,
    ...codePrintRoutes,
    {
        path: '/home',
        component: HomePage
    }
];

export default routes.map(route => {
    return {
        ...route,
        component: withNavigationWatcher(route.component)
    };
});
