import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { InitNewRowEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import React from "react";
import { enums } from "../../base";
import { ContentBlock, createGridOptions, StdGrid, stdReadOnlyGridOptions, StdToolbar } from "../../components";
import { CpDataSourceModel } from "../../models";
import AuthorizedComponent from "../authorized-component";

interface CpDataSourcePageState {
    data: CpDataSourceModel[];
}

export default abstract class CpDataSourcePage 
    extends AuthorizedComponent<any, CpDataSourcePageState> {
    objectName = "CpDataSource";
    moduleName = "CpDataSource";
    gridRef = React.createRef<DataGrid>();
    toolbarRef = React.createRef<StdToolbar>();
    gridOptions = createGridOptions("cpDataSources", this.gridRef, stdReadOnlyGridOptions);

    abstract get type(): string;
    abstract get caption(): string;
    abstract get store(): CustomStore;
    abstract get columns(): IColumnProps[];

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            data: []
        };

        this.gridOptions.keyExpr = "id";
        this.gridOptions.onInitNewRow = this.onInitNewRow.bind(this);
        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.onButtonClick(e.data, "edit");
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    onInitNewRow(e: InitNewRowEvent) {
        e.data.type = this.type;
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.toolbarRef.current?.setModel(e.selectedRowsData[0]);
        } else {
            this.toolbarRef.current?.setModel({});
        }
    }

    onButtonClick(model: CpDataSourceModel, name: string) {
        const grid = this.gridRef.current?.instance;
        
        if (grid) {
            switch (name) {
                case "add":
                    if (this.permissions[enums.stdActions.create]) {
                        grid.addRow();
                    }
                    break;

                case "edit":
                    if (this.permissions[enums.stdActions.update]) {
                        grid.editRow(grid.getRowIndexByKey(model.id));
                    }
                    break;
                
                case "delete":
                    if (this.permissions[enums.stdActions.delete]) {
                        grid.deleteRow(grid.getRowIndexByKey(model.id));
                    }
                    break;
            }
        }
    }

    render() {
        return (
            <ContentBlock caption={this.caption}>
                <StdToolbar 
                    permissions={this.permissions}
                    ref={this.toolbarRef} 
                    onButtonClick={this.onButtonClick} />
                <StdGrid
                    moduleName={this.moduleName}
                    gridRef={this.gridRef}
                    dataSource={this.store}
                    options={this.gridOptions}
                    columns={this.columns}
                    popupWidth={400}
                    popupHeight={280}
                    formColCount={1} />
            </ContentBlock>
        );
    }
}