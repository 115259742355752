import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import { createGridOptions, stdReadOnlyGridOptions, StdGrid } from "../../components";
import { vehicleStore } from "../../api";
import { dataSources } from "../../base";
import { VehicleModel } from "../../models";

export interface VehicleGridProps {
    onVehicleSelected: (vehicle: VehicleModel) => void;
    onButtonClick: (vehicle: VehicleModel, name: string) => void;
}

interface VehicleGridState {
    columns: IColumnProps[];
}

export default class VehicleGrid extends React.Component<VehicleGridProps, VehicleGridState> {
    moduleName = "Vehicle";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("vehicles", this.gridRef, stdReadOnlyGridOptions);

    constructor(props: Readonly<VehicleGridProps>) {
        super(props);

        this.state = {
            columns: [
                { dataField: 'vehicleNumber' },
                {
                    dataField: 'vehicleType',
                    lookup: { dataSource: dataSources.vehicleTypes, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: "driverString", caption: '司机', dataType: "string" },
                { dataField: "supercargoString", caption: '押运员', dataType: "string" },
                {
                    dataField: 'organizationName',
                    allowSorting: false,
                    allowFiltering: false,
                    allowGrouping: false,
                },
                { dataField: 'startDate', dataType: "date" }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onVehicleSelected(e.selectedRowsData[0]);
        } else {
            this.props.onVehicleSelected({});
        }
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }
    
    filter(condition: any) {
        this.gridRef.current?.instance.filter(condition);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={vehicleStore}
                options={this.gridOptions}
                columns={this.state.columns} />
        );
    }
}