import React from "react";
import { Button, DateBox, SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { DataMap, dateAdd, enums, ListItem } from "../../base";
import { OrganizationRef, SiteDangerModel } from "../../models";
import { organizationService } from "../../api";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { OrgTreeDropDown } from "../../components";

interface SiteDangerToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (siteDanger: SiteDangerModel, name: string) => void;
    onContextButtonClick: (ids: number[], name: string) => void;
    onFiltering: (filterValue: any, showDeleted: boolean) => void;
}

interface SiteDangerToolbarState {
    model: SiteDangerModel;
    ids: number[];
    orgs: OrganizationRef[];
    organizationId?: number;
    startDate?: Date | string;
    endDate?: Date | string;
    showDeleted: boolean;
}

export default class SiteDangerToolbar
    extends React.Component<SiteDangerToolbarProps, SiteDangerToolbarState> {
    constructor(props: Readonly<SiteDangerToolbarProps>) {
        super(props);

        this.state = {
            model: {},
            ids: [],
            orgs: [],
            showDeleted: false
        };

        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);
    }

    componentDidMount() {
        this.loadOrgs();
    }

    async loadOrgs() {
        const orgs = await organizationService.getRefs();
        this.setState({ orgs: orgs });
    }

    setModel(model: SiteDangerModel) {
        this.setState({ model: model });
    }

    setIds(ids: number[]) {
        this.setState({ ids: ids });
    }

    onOrganizationChanged(organizationId?: number) {
        this.setState({ organizationId: organizationId });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.setState({ startDate: e.value });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.setState({ endDate: e.value });
    }

    onStatusChanged(e: ValueChangedEvent) {
        this.setState({ showDeleted: e.value });
    }

    onFiltering() {
        const { startDate, endDate, organizationId, showDeleted } = this.state;
        const filters: any[] = [];

        if (startDate) {
            filters.push(["checkTime", ">=", startDate]);
        }

        if (endDate) {
            filters.push(["checkTime", "<", dateAdd(new Date(endDate), 1)]);
        }

        if (organizationId) {
            filters.push(["organizationId", "=", organizationId]);
        }

        this.props.onFiltering(filters.length > 0 ? filters : null, showDeleted);
    }

    render() {
        const { model, ids, orgs, organizationId, startDate, endDate, showDeleted } = this.state;
        const permissions = this.props.permissions;
        const canAudit = !!model.id && model.fixed;

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={showDeleted}
                            dataSource={statusSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            width={120}
                            onValueChanged={this.onStatusChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={startDate}
                            placeholder="开始时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endDate}
                            placeholder="结束时间"
                            width={120}
                            showClearButton={true}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"}>
                        <Button
                            text="查看详情"
                            disabled={!model?.id}
                            onClick={() => this.props.onButtonClick(model, "detail")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            text="导出整改单"
                            disabled={!ids.length}
                            onClick={() => this.props.onContextButtonClick(ids, "export")} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions["Audit"]}>
                        <Button
                            text="审核"
                            disabled={!canAudit}
                            onClick={() => this.props.onButtonClick(model, "audit")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.delete]}>
                        <Button
                            text="作废"
                            disabled={!model?.id || model.deleted}
                            onClick={() => this.props.onButtonClick(model, "delete")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.delete]}>
                        <Button
                            text="还原"
                            disabled={!model?.id || !model.deleted}
                            onClick={() => this.props.onButtonClick(model, "restore")} />
                    </Item>
                </Toolbar>
            </div>
        )
    }
}

const statusSource: ListItem<boolean>[] = [
    { value: false, text: "查看正常数据" },
    { value: true, text: "查看已作废" }
]