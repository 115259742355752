import { BaseApiService, createStoreFromUrl, http, MessageBool } from "../base";
import { TrainingModel } from "../models";

class TrainingService extends BaseApiService<TrainingModel> {
    moduleName = "training";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }

    getDeletedDxData() {
        return createStoreFromUrl(`${this.apiPath}/deleteddx`, 'id');
    }

    restore(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/restore/${id}`, '');
    }
}

export const trainingService = new TrainingService();
export const trainingStore = trainingService.getDxData();
export const trainingDeletedStore = trainingService.getDeletedDxData();