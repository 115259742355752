import { createStoreFromUrl, http } from "../../base";
import { ActiveCustomerReportCondition, CustomerBottleCondition, CustomerTrendReport, CustomerTrendReportCondition } from "../../models";

class CustomerReportService {
    apiPath = "/api/report/customer";

    getWithBottles(condition: CustomerBottleCondition) {
        return createStoreFromUrl(
            `${this.apiPath}/withbottles?${http.serialize([condition])}`, 
            'customer.id');
    }

    getActiveReport(condition: ActiveCustomerReportCondition) {
        return createStoreFromUrl(`${this.apiPath}/active?${http.serialize([condition])}`, 'id');
    }

    getTrendReport(condition: CustomerTrendReportCondition) {
        return http.getData<CustomerTrendReport[]>(`${this.apiPath}/trend?${http.serialize([condition])}`);
    }
}

export const customerReportService = new CustomerReportService();
