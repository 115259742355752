import React from "react";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { dataSources } from "../../base";
import { roleStore, organizationService } from "../../api";
import { createGridOptions, setColumnDataSource, stdReadOnlyGridOptions, StdGrid } from "../../components";
import { OrganizationRef, RoleModel } from "../../models";

export interface RoleGridProps {
    onButtonClick: (role: RoleModel, name: string) => void;
    onRowSelected: (role: RoleModel) => void;
}

interface RoleGridState {
    orgs: OrganizationRef[];
    columns: IColumnProps[];
}

export default class RoleGrid extends React.Component<RoleGridProps, RoleGridState> {
    moduleName = "Role";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("roles", this.gridRef, stdReadOnlyGridOptions);

    constructor(props: Readonly<RoleGridProps>) {
        super(props);

        this.state = {
            orgs: [],
            columns: [
                { dataField: 'name' },
                // {
                //     dataField: 'organizationId',
                //     lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id', allowClearing: true }
                // },
                {
                    dataField: 'permissionValue',
                    lookup: { dataSource: dataSources.permissionValues, displayExpr: 'text', valueExpr: 'value' }
                },
                {
                    dataField: 'passwordRule',
                    lookup: { dataSource: dataSources.passwordRules, displayExpr: 'text', valueExpr: 'value', allowClearing: true }
                }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        // this.loadOrgs();
    }

    async loadOrgs() {
        const data = await organizationService.getRefs();
        setColumnDataSource(this.state.columns, "organizationId", data);
        
        this.setState({ 
            orgs: data
        });
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onRowSelected(e.selectedRowsData[0]);
        } else {
            this.props.onRowSelected({});
        }
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }
    
    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={roleStore}
                options={this.gridOptions}
                columns={this.state.columns}
                popupWidth={400}
                popupHeight={320}
                formColCount={1}>
            </StdGrid>
        );
    }
}