import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { ExportingEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import React from "react";
import { fillingCheckTypeService } from "../../api";
import { fillingPrecheckService, fillingPrecheckStore } from "../../api/filling-precheck.service";
import { authorizationService, baseApiUrl, createEmptyStore, dataSources, enums, globalPopupManager, globalPopupNames, ModuleConfig, StringMap } from "../../base";
import { createGridOptions, ExportModel, remoteReadOnlyGridOptions, restoreGridState, StdGrid } from "../../components";
import { FillingCheckTypeRef, FillingPrecheckModel } from "../../models";

export interface FillingPrecheckGridProps {
    onModelSelected(model: FillingPrecheckModel): void;
    onButtonClick(model: FillingPrecheckModel, name: string): void;
}

interface FillingPrecheckGridState {
    store: CustomStore;
    types: FillingCheckTypeRef[];
    columns: IColumnProps[];
}

export default class FillingPrecheckGrid
    extends React.Component<FillingPrecheckGridProps, FillingPrecheckGridState> {
    objectName = "FillingPrecheck";
    moduleName: ModuleConfig = {
        moduleName: "FillingPrecheck",
        children: {
            "bottle": "Bottle"
        }
    }

    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions(
        "fillingPrecheck",
        this.gridRef,
        remoteReadOnlyGridOptions,
        (items: any[]) => {
            // state清空会同时清空filter，需要重新绑定旧的
            items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
            return items;
        });

    nameMap: StringMap = { "organization.name": "organizationId" };

    constructor(props: Readonly<FillingPrecheckGridProps>) {
        super(props);

        this.state = {
            store: createEmptyStore(),
            types: [],
            columns: [
                { dataField: "organization.name" },
                { dataField: "barcode" },
                { dataField: "bottle.code" },
                { dataField: "bottle.originalWeight", caption: "标定瓶重" },
                { dataField: "bottle.weight", caption: "预检瓶重" },
                { dataField: "bottle.nextInspectDate" },
                { dataField: "bottle.scrapDate" },
                { dataField: "checkTime", dataType: "datetime" },
                { dataField: "checker" },
                {
                    dataField: "result",
                    lookup: { dataSource: dataSources.qualifiedOrNot, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: "reason" },
                { dataField: "note" }
            ]
        };

        this.gridOptions.onRowDblClick = (e) => this.props.onButtonClick(e.data, "edit");
        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onExporting = this.onExporting.bind(this);
        this.gridOptions.export!.allowExportSelectedData = false;
        this.gridOptions.export!.enabled = false;

        // 没有加载子项时不保存
        if (this.gridOptions.stateStoring) {
            const customSave = this.gridOptions.stateStoring.customSave;

            this.gridOptions.stateStoring!.customSave = function (state: any) {
                if (state.columns.find((c: IColumnProps) => c.dataField.substr(0, 5) === 'item_')) {
                    customSave && customSave(state);
                }
            }
        }
    }

    componentDidMount() {
        this.authorizeImport();

        this.loadTypes();
    }

    async loadTypes() {
        const types = await fillingCheckTypeService.getRefs(enums.fillingCheckTypes.precheck);

        const columns: IColumnProps[] = [
            ...this.state.columns.slice(0, 8),
            ...types.map(t => ({
                dataField: `item_${t.itemName}`,
                caption: t.itemName,
                allowGrouping: false,
                allowSorting: false,
                allowFiltering: false,
                cellRender: function (e: any) {
                    const pass = e.data.items ?
                        e.data.items.find((i: any) => i.itemName === t.itemName)?.pass :
                        undefined;

                    return t.displayType === enums.fillingCheckTypeValues.trueFalse ?
                        dataSources.trueFalseOfPass.find(p => p.value === pass)?.text || "" :
                        dataSources.qualifiedOrNot.find(q => q.value === pass)?.text || "";
                }
            })),
            ...this.state.columns.slice(8)
        ]

        this.setState({ types: types, columns: columns },
            () => restoreGridState(this.gridRef, this.gridOptions));
    }

    async authorizeImport() {
        if (await authorizationService.authorize(this.objectName, enums.stdActions.export)) {
            this.gridOptions.export!.enabled = true;
            this.gridRef.current?.instance.option("export.enabled", true);
        }
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});
        }
    }

    onExporting(e: ExportingEvent) {
        const args: ExportModel = {
            moduleName: this.moduleName.moduleName,
            exportUrl: `${baseApiUrl}${fillingPrecheckService.apiPath}/export`,
            columns: this.state.columns,
            keys: e.component.getSelectedRowKeys(),
            filter: e.component.getCombinedFilter()
        };

        e.cancel = true;
        globalPopupManager.show(globalPopupNames.exportPopup, args);
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    filter(filterValue: any) {
        if (filterValue) {
            this.setState({ store: fillingPrecheckStore }, () => this.gridRef.current?.instance.filter(filterValue));
        } else {
            this.setState({ store: createEmptyStore() });
        }
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns}
                columnNameMap={this.nameMap}>
            </StdGrid>
        );
    }
}