import { useEffect, useState } from "react";
import { orderService } from "../../api";
import { CustomerModel } from "../../models";
import PhotoList from "./photo-list";

export interface OrderPhotoProps {
    customer: CustomerModel;
}

export default function OrderPhoto(props: OrderPhotoProps) {
    const [fileNames, setFileNames] = useState<string[]>([]);

    useEffect(function () {
        (async () => {
            const photo = props.customer.id ? await orderService.checkPhoto(props.customer.id!) : [];
            setFileNames(photo);
        })();
    }, [props]);

    return (
        <PhotoList fileNames={fileNames} />
    )
}