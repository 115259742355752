import React, { Component } from 'react'
import Form, { IItemProps } from "devextreme-react/form";
import { PopupContentBox, RefPopup, setItemDataSource, StdForm } from "../../components";
import { dataSources, enums } from "../../base/enums";
import { FileUploader, Popup } from 'devextreme-react';
import { announcementService } from '../../api/announcement.service';
import { baseApiUrl, temporaryUploadUrl } from '../../base/base-values';
import { AnnouncementModel, OrganizationRef } from '../../models';
import { alertError, ListItem, notifySuccess, StringMap, tokenStorage } from '../../base';
import { UploadedEvent } from 'devextreme/ui/file_uploader';
import { dataSourceService, organizationService } from '../../api';

interface AnnouncementEditModel extends AnnouncementModel {
    orgIdArray?: number[];
    postArray?: string[];
}

export interface AnnouncementFormProps {
    onSaved: () => void;
}

interface AnnouncementFormState {
    model: AnnouncementEditModel;
    orgs: OrganizationRef[];
    posts: ListItem[];
    items: IItemProps[];
}

export default class AnnouncementForm
    extends Component<AnnouncementFormProps, AnnouncementFormState> {
    moduleName = "Announcement";
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    header = { Authorization: `bearer ${tokenStorage.token}` };
    uploadUrl = `${baseApiUrl}${temporaryUploadUrl}`;

    nameMap: StringMap = {
        "orgIdArray": "orgNames",
        "postArray": "posts"
    };

    constructor(props: AnnouncementFormProps) {
        super(props);

        this.state = {
            model: {},
            orgs: [],
            posts: [],
            items: [
                { dataField: 'caption' },
                {
                    dataField: 'replyType',
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: dataSources.announcementTypes, displayExpr: 'text', valueExpr: 'value' },
                },
                {
                    dataField: 'content',
                    editorType: "dxTextArea",
                    editorOptions: {
                        height: 150
                    }
                },
                {
                    dataField: 'orgIdArray',
                    editorType: 'dxTagBox',
                    editorOptions: {
                        dataSources: [],
                        displayExpr: 'name',
                        valueExpr: 'id',
                        searchExpr: 'name',
                        searchEnabled: true,
                        placeholder: "留空表示所有站点"
                    }
                },
                {
                    dataField: "postArray",
                    editorType: 'dxTagBox',
                    editorOptions: {
                        dataSources: dataSources.employeePosts,
                        displayExpr: 'text',
                        valueExpr: 'value',
                        placeholder: "留空表示不限岗位"
                    }
                },
                {
                    dataField: "files",
                    label: { text: "附件" },
                    colSpan: 2,
                    render: (e: any) => (
                        <div>
                            {this.state.model.attachments?.map((a, index) => (
                                <span className='text-margin'>
                                    <a target={"_blank"} rel="noreferrer" href={`${baseApiUrl}/api/file?name=${a.path}`}>{a.name}</a> &nbsp;
                                    <i className="bi bi-x-square-fill text-danger" onClick={() => this.removeFile(index)}></i>
                                </span>
                            ))}
                            <FileUploader
                                multiple={true}
                                uploadMode="instantly"
                                uploadUrl={this.uploadUrl}
                                uploadHeaders={this.header}
                                name="file"
                                onUploaded={this.onUploaded.bind(this)} />
                        </div>
                    )
                },
            ]
        };

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let orgs: OrganizationRef[] = [], posts: string[] = [];

        await Promise.all([
            (async () => orgs = await organizationService.getRefs())(),
            (async () => posts = await dataSourceService.getDataSource(enums.dataSourceTypes.customPosts))()
        ]);

        const allPosts: ListItem[] = [
            ...dataSources.employeePosts,
            ...posts.map(p => ({ value: p, text: p }))
        ];

        setItemDataSource(this.state.items, "orgIdArray", orgs);
        setItemDataSource(this.state.items, "postArray", allPosts);

        this.setState({
            orgs: orgs,
            posts: allPosts,
            items: this.state.items
        });
    }

    async show(model: AnnouncementModel) {
        this.popupRef.current?.instance.show();

        const editModel: AnnouncementEditModel = await announcementService.get(model.id!);
        editModel.orgIdArray = model.orgIds ? model.orgIds.split(',').map(id => parseInt(id)) : [];
        editModel.postArray = model.posts ? model.posts.split('，') : [];

        this.setState({ model: editModel });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    cancel() {
        this.hide();
    }

    onUploaded(e: UploadedEvent) {
        const model = this.state.model;
        const fileName = e.request.responseText!;
        model.attachments = model.attachments || [];
        model.attachments!.push({ name: fileName.split('/').pop(), path: fileName });

        this.setState({ model: model });
    }

    removeFile(index: number) {
        this.state.model.attachments!.splice(index, 1);

        this.setState({ model: this.state.model });
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();
        if (!isValid?.isValid) {
            return;
        }

        const { model, orgs } = this.state;
        model.orgIds = model.orgIdArray?.join(',');
        model.orgNames = model.orgIdArray?.map(id => orgs.find(o => o.id === id)?.name).join('，');
        model.posts = model.postArray?.join('，');

        delete model.orgIdArray;
        delete model.postArray;

        const res = model.id ?
            await announcementService.update(model) :
            await announcementService.create(model);

        if (res.succeeded) {
            notifySuccess("保存成功");
            this.hide();
            this.props.onSaved();
        } else {
            alertError(res.message!);
            model.orgIdArray = model.orgIds ? model.orgIds.split(',').map(id => parseInt(id)) : [];
            model.postArray = model.posts ? model.posts.split('，') : [];
            this.setState({ model: model });
        }
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"编辑公告"}
                width={800}
                height={600}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        items={this.state.items}
                        formData={this.state.model}
                        nameMap={this.nameMap} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}
