import { createStoreFromUrl } from "../base";

class OperationLogService {
    apiPath = "/api/operationlog";

    getDxData(organizationId?: number) {
        return createStoreFromUrl(
            `${this.apiPath}/dx` + (organizationId ? `?organizationId=${organizationId}` : ''), 'id');
    }
}

export const operationLogService = new OperationLogService();
export const operationLogStore = operationLogService.getDxData();
