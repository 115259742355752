import "./popup-content-box.scss";
import { Button, ScrollView } from "devextreme-react";

export interface PopupContentBoxProps {
    onSave: () => void;
    onCancel: () => void;
    children: any;
    scrollable?: boolean;
    hideCancelButton?: boolean;
    hideSavebutton?: boolean;
    saveButtonText?: string;
    cancelButtonText?: string;
}

export default function PopupContentBox(props: PopupContentBoxProps) {
    return (
        <div className={"popup-content-box"}>
            <div className={"popup-content-main"}>
                {props.scrollable && (
                    <ScrollView width={"100%"} scrollByContent={true}>
                        {props.children}
                    </ScrollView>
                )}
                {!props.scrollable && props.children}
            </div>
            <div className={"popup-content-footer dx-popup-bottom"}>
                {!props.hideSavebutton && (
                <Button text={props.saveButtonText || "保存"} type={"default"} onClick={props.onSave} />
                )}
                {!props.hideCancelButton && (
                    <Button text={props.cancelButtonText || "取消"} type={"normal"} onClick={props.onCancel} />
                )}
            </div>
        </div>
    )
}
