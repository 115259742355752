import React from "react";
import { areaService } from "../../../api";
import { fdate, today } from "../../../base";
import { AreaDeliveringReportCondition, AreaRef } from "../../../models";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { Button, DateBox, SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { ValueChangedEvent } from "devextreme/ui/select_box";

export interface AreaDeliveringToolbarProps {
    onFiltering: (condition: AreaDeliveringReportCondition) => void;
    onExporting: (condition: AreaDeliveringReportCondition) => void;
}

interface AreaDeliveringToolbarState {
    counties: AreaRef[];
    condition: AreaDeliveringReportCondition;
}

export default class AreaDeliveringToolbar extends
    React.Component<AreaDeliveringToolbarProps, AreaDeliveringToolbarState> {

    constructor(props: Readonly<AreaDeliveringToolbarProps>) {
        super(props);

        const day = today();

        this.state = {
            counties: [],
            condition: {
                startDate: fdate(new Date(day.getFullYear(), day.getMonth(), 1)),
                endDate: fdate(day)
            }
        }

        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onAreaChanged = this.onAreaChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.onExporting = this.onExporting.bind(this);

    }

    componentDidMount(): void {
        this.loadDataSources();
    }

    async loadDataSources() {
        const counties = await areaService.getCounties();
       
        this.setState({
            counties: counties
        });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.updateCondition({ startDate: e.value });
    }

    private updateCondition(updated: any) {
        const cond = { ...this.state.condition, ...updated };
        cond.startDate = fdate(cond.startDate);
        cond.endDate = fdate(cond.endDate);

        this.setState({ 
            condition: cond
        });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.updateCondition({ endDate: e.value });
    }

    onAreaChanged(event: ValueChangedEvent) {
        this.updateCondition({ areaId: event.value });
    }

    onFiltering() {
        this.props.onFiltering(this.state.condition);
    }

    onExporting() {
        this.props.onExporting(this.state.condition);
    }
    
    render() {
        const { condition, counties } = this.state;

        return (
            <div className="page-toolbar">
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={condition.startDate}
                            placeholder="开始时间"
                            width={120}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={condition.endDate}
                            placeholder="结束时间"
                            width={120}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={condition.areaId}
                            dataSource={counties}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder={"行政区域"}
                            onValueChanged={this.onAreaChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            text="导出"
                            onClick={this.onExporting} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}