import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import { createGridOptions, remoteReadOnlyGridOptions, StdGrid } from "../../components";
import { OrderEvaluationModel } from "../../models";
import { orderEvaluationStore } from "../../api";
import { createEmptyStore, ModuleConfig, StringMap } from "../../base";
import CustomStore from "devextreme/data/custom_store";

export interface OrderEvaluationGridProps {
    onButtonClick: (model: OrderEvaluationModel, name: string) => void;
    onModelSelected: (model: OrderEvaluationModel) => void;
}

interface OrderEvaluationGridState {
    columns: IColumnProps[];
    store: CustomStore;
}

export default class OrderEvaluationGrid 
    extends React.Component<OrderEvaluationGridProps, OrderEvaluationGridState> {
    moduleName: ModuleConfig = {
        moduleName : "OrderEvaluation",
        children: {
            order: {
                moduleName: "Order",
                children: {
                    organization: "Organization",
                    deliver: "Employee",
                    customer: "Customer"
                }
            }
        }
    };

    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("orderEvaluations", this.gridRef, remoteReadOnlyGridOptions);
    nameMap: StringMap = { 
        "order.organization.name": "order.organizationId",
        "order.deliver.nameAndCode": "order.deliverId",
        "order.customer.name": "order.customerId"
    };

    constructor(props: Readonly<OrderEvaluationGridProps>) {
        super(props);

        this.state = {
            store: createEmptyStore(),
            columns: [
                { dataField: "evaluatedAt", dataType: "datetime" },
                { dataField: "order.organization.name" },
                { dataField: "order.deliver.nameAndCode" },
                {
                    dataField: "order.customer.name",
                    cellRender: e => {
                        return <span>{e.data.order.customer.name}（{e.data.order.customer.gasCode}）</span>
                    }
                },
                { dataField: "order.code" },
                { dataField: "order.totalPrice" },
                { dataField: "serviceRate" },
                { dataField: "gasRate" },
                { dataField: "deliveryRate" },
                { dataField: "securityRate" },
                { dataField: "evaluation" }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});
        }
    }

    filter(filterValue: any) {
        if ("orderId" !== this.state.store.key as any) {
            this.setState({ store: orderEvaluationStore }, () => this._filter(filterValue));
        } else {
            this._filter(filterValue);
        }
    }

    _filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={orderEvaluationStore}
                options={this.gridOptions}
                columnNameMap={this.nameMap}
                columns={this.state.columns}>
            </StdGrid>
        )
    }
}
