import { IColumnProps } from "devextreme-react/data-grid";
import { cpVendorStore } from "../../api";
import { enums } from "../../base";
import CpDataSourcePage from "./cp-data-source";

export default class CpVendorPage extends CpDataSourcePage {
    type = enums.cpDataSourceTypes.vendor;
    caption = "条码打印-厂家档案";
    store = cpVendorStore;

    columns: IColumnProps[] = [
        { 
            dataField: "createdAt",
            formItem: { visible: false },
            dataType: 'datetime' 
        },
        { 
            dataField: "updatedAt", 
            formItem: { visible: false },
            dataType: 'datetime' 
        },
        { dataField: "text" }
    ]
}