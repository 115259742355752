import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import React from "react";
import { myAllAnnouncementStore, myAnnouncementStore } from "../../api";
import { ModuleConfig } from "../../base";
import { createGridOptions, remoteReadOnlyGridOptions, StdGrid } from "../../components";
import { AnnouncementEmployeeModel } from "../../models";

export interface MyAnnouncementGridProps {
    onModelSelected: (model: AnnouncementEmployeeModel) => void;
    onButtonClick: (model: AnnouncementEmployeeModel, name: string) => void;
}

interface MyAnnouncementGridState {
    store: CustomStore;
    columns: IColumnProps[];
}

export default class MyhAnnouncementGrid 
    extends React.Component<MyAnnouncementGridProps, MyAnnouncementGridState> {
    moduleName: ModuleConfig = {
        moduleName: "AnnouncementEmployee",
        children: {
            announcement: "Announcement"
        }
    };
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions(
        "myAnnouncements", 
        this.gridRef, 
        remoteReadOnlyGridOptions,
        (items: any[]) => {
            // state清空会同时清空filter，需要重新绑定旧的
            items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
            return items;
        });

    constructor(props: Readonly<MyAnnouncementGridProps>) {
        super(props);

        this.state = {
            store: myAnnouncementStore,
            columns: [
                { dataField: "announcement.caption" },
                { dataField: "announcement.createdBy" },
                { dataField: "announcement.createdAt", dataType: "datetime" },
                { dataField: "readAt", dataType: "datetime" }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = (e) => this.props.onButtonClick(e.data, "view");
        this.gridOptions.onRowPrepared = (e) => {
            if (e.rowType === "data" && !e.data.readAt) {
                e.rowElement.style.fontWeight = "bold";
            }
        }
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});    // 为避免混乱，多选不能编辑
        }
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    filter(filterValue: any, showAll: boolean) {
        if (showAll && this.state.store !== myAllAnnouncementStore) {
            this.setState({ store: myAllAnnouncementStore }, () => this._filter(filterValue));
        } else if (!showAll && this.state.store !== myAnnouncementStore) {
            this.setState({ store: myAnnouncementStore }, () => this._filter(filterValue));
        } else {
            this._filter(filterValue);
        }
    }

    _filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns}>
            </StdGrid>
        );
    }
}