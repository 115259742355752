import { http } from "../base";
import { EmployeePositionModel } from "../models";

class EmployeePositionService {
    apiPath = "/api/employeeposition";

    getEmployee(date: string, employeeId: string) {
        return http.getData<EmployeePositionModel[]>(
            `${this.apiPath}/employee?date=${date}&employeeId=${employeeId}`);
    }

    get(date: string, organizationId?: number) {
        return http.getData<EmployeePositionModel[]>(`${this.apiPath}?date=${date}&organizationId=${organizationId}`);
    }
}

export const employeePositionService = new EmployeePositionService();