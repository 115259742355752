import React from "react";
import { Button, DateBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { User, timeAdd, dateAdd, today, alertWarning, isPorcelainCode, toShortCode } from "../../base";
import { BottleFillingModel, FillingTargetRef, OrganizationRef } from "../../models";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box"
import { fillingTargetService, organizationService } from "../../api";
import { OrgTreeDropDown } from "../../components";

export interface BottleFillingToolbarProps {
    onButtonClick: (model: BottleFillingModel, name: string) => void;
    user?: User;
    onFiltering: (filterValue: any) => void;
}

interface BottleFillingToolbarState {
    model: BottleFillingModel;
    orgs: OrganizationRef[];
    fillingTargets: FillingTargetRef[];
    organizationId?: number;
    startTime?: Date | string;
    endTime?: Date | string;
}

export default class BottleFillingToolbar
    extends React.Component<BottleFillingToolbarProps, BottleFillingToolbarState> {
    code?: string;

    constructor(props: Readonly<BottleFillingToolbarProps>) {
        super(props);

        const day = today();

        this.state = {
            model: {},
            orgs: [],
            fillingTargets: [],
            startTime: day,
            endTime: timeAdd(dateAdd(day, 1), -1, "s")
        };

        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onCodeChanged = this.onCodeChanged.bind(this);
        this.onStartTimeChanged = this.onStartTimeChanged.bind(this);
        this.onEndTimeChanged = this.onEndTimeChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount() {
        this.loadOrgs();
    }

    async loadOrgs() {
        const orgs = await organizationService.getRefs();
        const targets = await fillingTargetService.getRefs();
        this.setState({ orgs: orgs, fillingTargets: targets });
    }

    setModel(model: BottleFillingModel) {
        this.setState({ model: model });
    }

    onOrganizationChanged(organizationId?: number) {
        this.setState({ organizationId: organizationId });
    }

    onCodeChanged(code?: string) {
        this.code = code?.trim();
        this.setState({});
    }

    onStartTimeChanged(e: DateChangedEvent) {
        this.setState({ startTime: e.value });
    }

    onEndTimeChanged(e: DateChangedEvent) {
        this.setState({ endTime: e.value });
    }

    onFiltering() {
        const filterValue: any[] = [];

        if (this.code) {
            if (isPorcelainCode(this.code)) {
                filterValue.push(["bottle.barCode", "=", this.code]);
            } else {
                filterValue.push(["bottle.code", "=", toShortCode(this.code)]);
            }
        }

        if (this.state.startTime && this.state.endTime) {
            filterValue.push(["fillingTime", ">=", this.state.startTime]);
            filterValue.push(["fillingTime", "<=", this.state.endTime]);
        }

        if (!filterValue.length) {
            alertWarning("请输入气瓶陶瓷码或钢印号或起止时间查询");
            return;
        }

        if (this.state.organizationId) {
            const targets = this.state.fillingTargets
                .filter(t => t.organizationId === this.state.organizationId)
                .map(t => ["target", "=", t.code]);

            const targetFilter = [];
            for (const target of targets) {
                targetFilter.push(target, "or");
            }
            
            targetFilter.length--;

            filterValue.push(targetFilter);
        }

        this.props.onFiltering(filterValue);
    }
    
    render() {
        const { user, onButtonClick } = this.props;
        const { model, startTime, endTime, orgs, organizationId } = this.state;

        return (
            <div className={"page-toolbar page-large-toolbar"}>
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="选择供应站过滤"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <TextBox
                            value={this.code}
                            placeholder="陶瓷码或钢印号"
                            width={120}
                            showClearButton={true}
                            onValueChange={this.onCodeChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                    
                        <DateBox
                            value={startTime}
                            placeholder="开始时间"
                            type="datetime"
                            showClearButton={true}
                            onValueChanged={this.onStartTimeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={endTime}
                            placeholder="结束时间"
                            type="datetime"
                            showClearButton={true}
                            onValueChanged={this.onEndTimeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                    {user?.isAdmin && (
                        <Item location={"after"} locateInMenu={"auto"}>
                            <Button
                                text="新增"
                                onClick={() => onButtonClick(model, "add")} />
                        </Item>
                    )}
                </Toolbar>
            </div>
        );
    }
}