import { BaseApiService, createStoreFromUrl } from "../base";
import { CustomerComplainModel } from "../models";

class CustomerComplainService extends BaseApiService<CustomerComplainModel> {
    moduleName ="customercomplain";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }
}

export const customerComplainService = new CustomerComplainService();
export const customerComplainStore = customerComplainService.getDxData();