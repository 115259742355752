import React from "react";
import { CheckBox, Popup, TagBox } from "devextreme-react"
import { alertError, authorizationService, notifySuccess } from "../../base";
import { employeeService, employeeShareService } from "../../api";
import { RefPopup } from "../popup";
import { PopupContentBox } from "../layouts";
import { EmployeeRef } from "../../models";
import { ValueChangedEvent } from "devextreme/ui/tag_box";
import { ValueChangedEvent as CheckChangedEvent } from "devextreme/ui/check_box"

interface EmployeeSharePopupState {
    sameOrganization: boolean;
    employees: EmployeeRef[];
    allEmployees: EmployeeRef[];
    shareEmployeeIds: string[];
    canEdit: boolean;
}

export default class EmployeeSharePopup extends React.Component<any, EmployeeSharePopupState> {
    popupRef = React.createRef<Popup>();
    employee: any;

    constructor(props: any) {
        super(props);

        this.state = {
            sameOrganization: true,
            employees: [],
            allEmployees: [],
            shareEmployeeIds: [],
            canEdit: false
        };

        this.onCheckChanged = this.onCheckChanged.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    async componentDidMount() {
        const canEdit = await authorizationService.authorize("Employee", "EditShare");
        this.setState({ canEdit: canEdit });

        this.loadDefaultEmployees();
    }

    async loadDefaultEmployees() {
        const employees = await employeeService.getAllRefs();

        this.setState({ 
            allEmployees: employees,
        });
    }

    show(employee: any) {
        this.popupRef.current?.instance.show();

        this.employee = employee;
        this.loadEmployeeShares();
        this.filterEmployees();
    }
    
    hide() {
        this.popupRef.current?.instance.hide();
    }

    async loadEmployeeShares() {
        const model = await employeeShareService.getByOwnerId(this.employee.id);
        const shares = this.state.allEmployees.filter(e => model.shareEmployeeIds?.indexOf(e.id!) !== -1);
        const crossOrganization = !!shares.find(s => s.organizationId !== this.employee.organizationId);

        this.setState({ 
            shareEmployeeIds: model.shareEmployeeIds || [],
            sameOrganization: !crossOrganization
        }); 
    }

    async filterEmployees() {
        this.setState({
            employees: this.state.sameOrganization ?
                this.state.allEmployees.filter(e => e.organizationId === this.employee.organizationId) : 
                this.state.allEmployees
        });
    }

    onCheckChanged(e: CheckChangedEvent) {
        this.setState({ sameOrganization: e.value }, () => this.filterEmployees());
    }

    onValueChanged(e: ValueChangedEvent) {
        this.setState({
            shareEmployeeIds: e.value
        });
    }

    async save() {
        const res = await employeeShareService.save({ 
            ownerEmployeeId: this.employee.id, 
            shareEmployeeIds: this.state.shareEmployeeIds 
        });

        if (res.succeeded) {
            notifySuccess("已成功设置共享");
            this.hide();
        } else {
            alertError(res.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        const { employees, canEdit, shareEmployeeIds, sameOrganization } = this.state;

        return (
            <RefPopup
                popupRef={this.popupRef}
                baseOptions={{className: "global-dialogs"}}
                width={800}
                height={300}
                title={`设置共享`}>
                <PopupContentBox
                    scrollable={true}
                    onSave={this.save}
                    onCancel={this.cancel}
                    hideSavebutton={!canEdit}
                    hideCancelButton={!canEdit}>
                    <h4 className="no-margin">允许<span className="text-primary">{this.employee?.name}</span>查看以下员工的数据：</h4>
                    <div>
                        <p></p>
                        <CheckBox text="仅显示同站点员工" value={sameOrganization} onValueChanged={this.onCheckChanged} />
                        <p></p>
                    </div>
                    <div>
                        <TagBox 
                            readOnly={!canEdit}
                            dataSource={employees}
                            value={shareEmployeeIds}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            onValueChanged={this.onValueChanged} />
                    </div>
                </PopupContentBox>
            </RefPopup>
        )
    }
}