import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { CustomerTypeRef } from "../../models";
import AuthorizedComponent from "../authorized-component";
import { ContentBlock, StdGrid, StdToolbar, createBoolColumn, createGridOptions, remoteReadOnlyGridOptions, setColumnDataSource } from "../../components";
import React from "react";
import { customerTypeService } from "../../api";
import { bottleRecycleLogStore } from "../../api/bottle-recycle-log.service";
import { ModuleConfig } from "../../base";

const RecycleTypes = [
    { text: '退押金', value: 'Deposit' },
    { text: '还欠瓶', value: 'Debt' }
];

interface BottleRecycleLogState {
    customerTypes: CustomerTypeRef[];
    columns: IColumnProps[];
}

export default class BottleRecycleLogPage extends AuthorizedComponent<any, BottleRecycleLogState> {
    moduleName: ModuleConfig = {
        moduleName: "BottleRecycleLog",
        children: {
            "customer": "Customer"
        }
    };
    objectName = "BottleRecycleLog";
    gridRef = React.createRef<DataGrid>();
    toolbarRef = React.createRef<StdToolbar>();
    gridOptions = createGridOptions("bottleRecycleLogs", this.gridRef, remoteReadOnlyGridOptions);

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            customerTypes: [],
            columns: [
                {
                    dataField: 'customer.typeId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                {
                    dataField: "customer.name",
                    cellRender: e => {
                        return <span>{e.data.customer.name}（{e.data.customer.gasCode}）</span>
                    }
                },
                {
                    dataField: "recycleType",
                    lookup: { dataSource: RecycleTypes, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: "barcodes" },
                { dataField: "depositCodes" },
                { dataField: "debtItems" },
                { dataField: "newDepositCode" },
                { dataField: "serviceFee" },
                createBoolColumn({ dataField: "customerExit" }),
                { dataField: "exitNote" }
            ]
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.loadCustomerTypes();
    }

    async loadCustomerTypes() {
        const data = await customerTypeService.getRefs();
        setColumnDataSource(this.state.columns, "customer.typeId", data);
        this.setState({ customerTypes: data });
    }

    render() {
        return (
            <ContentBlock caption={"退瓶还瓶记录"}>
                <StdGrid
                    moduleName={this.moduleName}
                    gridRef={this.gridRef}
                    dataSource={bottleRecycleLogStore}
                    options={this.gridOptions}
                    columns={this.state.columns} />
            </ContentBlock>
        );
    }
}