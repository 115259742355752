import { BaseApiService, createStoreFromUrl, http, MessageBool } from "../base";
import { CpCheckModel } from "../models";

class CpCheckService extends BaseApiService<CpCheckModel> {
    moduleName = "cpcheck";

    getDxData(batchId?: number) {
        return createStoreFromUrl(`${this.apiPath}/dx?batchId=${batchId || ''}`, 'id');
    }

    getTemplate(batchId: number) {
        return http.getData(`${this.apiPath}/template?batchId=${batchId}`);
    }
    
    deleteSelected(ids: number[]) {
        return http.deleteData<MessageBool>(`${this.apiPath}?ids=${ids}`);
    }
}

export const cpCheckService = new CpCheckService();
export const cpCheckStore = cpCheckService.getDxData();