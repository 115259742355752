import { createStoreFromUrl, http, MessageBool } from "../base";
import { BriefSecurityCheck, SecurityCheckAuditModel, SecurityCheckModel } from "../models";

class SecurityCheckService {
    apiPath = "/api/securitycheck";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }

    getDeletedDxData() {
        return createStoreFromUrl(`${this.apiPath}/deleteddx`, 'id');
    }

    get(id: number) {
        return http.getData<SecurityCheckModel>(`${this.apiPath}/detail/${id}`);
    }

    searchByCustomerId(customerId: number) {
        return http.getData<BriefSecurityCheck[]>(`${this.apiPath}/bycustomer/${customerId}`);
    }

    envPhoto(customerId: number) {
        return http.getData<string[]>(`${this.apiPath}/envphoto?customerId=${customerId}`);
    }

    audit(model: SecurityCheckAuditModel) {
        return http.putData<MessageBool>(`${this.apiPath}/audit`, model);
    }

    archive(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/archive/${id}`, '');
    }

    restore(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/restore/${id}`, '');
    }

    delete(id: number) {
        return http.deleteData<MessageBool>(`${this.apiPath}/${id}`);
    }
}

export const securityCheckService = new SecurityCheckService();
export const securityCheckStore = securityCheckService.getDxData();
export const securityCheckDeletedStore = securityCheckService.getDeletedDxData();