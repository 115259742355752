import { createStoreFromUrl, http, MessageBool, BaseApiService } from "../base";
import { BottleDebtModel, BriefBottleDebt } from "../models";

class BottleDebtService extends BaseApiService<BottleDebtModel> {
    moduleName = "debt";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }

    getDeletedDxData() {
        return createStoreFromUrl(`${this.apiPath}/deleteddx`, 'id');
    }

    searchByCustomerId(customerId: number)  {
        return http.getData<BriefBottleDebt[]>(`${this.apiPath}/bycustomer/${customerId}`);
    }

    archive(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/archive/${id}`, '');
    }

    setReceived(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/setreceived/${id}`, '');
    }

    setNote(model: { id: number, note: string}) {
        return http.putData<MessageBool>(`${this.apiPath}/note`, model);
    }

    uploadPhoto(model: { id: number, photo: string }) {
        return http.putData<MessageBool>(`${this.apiPath}/photo`, model);
    }

    restore(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/restore/${id}`, '');
    }
}

export const bottleDebtService = new BottleDebtService();
export const bottleDebtStore = bottleDebtService.getDxData();
export const bottleDebtDeletedStore = bottleDebtService.getDeletedDxData();