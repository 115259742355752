import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";
import React from "react";
import { exceptionDataStore } from "../../../api/exception-data.service";
import { createEmptyStore, dataSources } from "../../../base";
import { createGridOptions, remoteReadOnlyGridOptions, setColumnDataSource, StdGrid } from "../../../components";
import { exceptionTypes, OrganizationRef } from "../../../models";
import { organizationService } from "../../../api";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import CustomStore from "devextreme/data/custom_store";
import { renderExceptionData } from "./exception-data-render";

interface ExceptionDataGridProps {
    onRowChecked(ids: number[]): void;
    showFlowPopup(code: string): void;
    showCallCenter(gasCode: string): void;
}

interface ExceptionDataGridState {
    orgs: OrganizationRef[];
    columns: IColumnProps[];
    store: CustomStore;
}


export default class ExceptionDataGrid
    extends React.Component<ExceptionDataGridProps, ExceptionDataGridState> {
    moduleName = "ExceptionData";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions(
        "exceptionData",
        this.gridRef,
        remoteReadOnlyGridOptions,
        (items: any[]) => {
            // state清空会同时清空filter，需要重新绑定旧的
            items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
            return items;
        });

    flowTypes = [
        ...dataSources.bottleOperations,
        { value: "Scrap", text: '气瓶报废' }
    ];

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            store: createEmptyStore(),
            orgs: [],
            columns: [
                {
                    dataField: "type",
                    lookup: { dataSource: exceptionTypes, displayExpr: 'text', valueExpr: 'value' },
                    allowFiltering: false,
                },
                {
                    dataField: "organizationId",
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' },
                    allowFiltering: false,
                },
                {
                    dataField: 'flowType',
                    lookup: { dataSource: this.flowTypes, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: "operationTime", dataType: "datetime", allowFiltering: false },
                { dataField: "operatorName", allowFiltering: false },
                { 
                    dataField: "data",
                    cellRender: (e: any) => {
                        return renderExceptionData(e.data.data, this.props.showFlowPopup, this.props.showCallCenter);
                    }
                },
                { dataField: "note" },
                { dataField: "handledAt", dataType: "datetime" },
                { dataField: "handlerName" },
                { dataField: "handleDesc" }
            ]
        }

        this.filter = this.filter.bind(this);
        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    componentDidMount(): void {
        this.loadOrgs();
    }

    async loadOrgs() {
        const orgs = await organizationService.getAllRefs();
        setColumnDataSource(this.state.columns, "organizationId", orgs);
        this.setState({ orgs: orgs });
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    onSelectionChanged(event: SelectionChangedEvent) {
        this.props.onRowChecked(event.selectedRowKeys);
    }

    filter(filterValue: any) {
        if (filterValue) {
            this.setState({ store: exceptionDataStore }, () => this.gridRef.current?.instance.filter(filterValue));
        } else {
            this.setState({ store: createEmptyStore() });
        }
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns} />
        )
    }
}
