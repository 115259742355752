import { createStoreFromUrl, BaseApiService, http } from "../base";
import { BottleFillingModel } from "../models";

class BottleFillingService extends BaseApiService<BottleFillingModel> {
    moduleName = "filling"

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id', true);
    }

    async update(model: any) {
        return Promise.reject("不支持更新");
    }

    async findOrganizationid(barcode: string) {
        return http.getData<number|undefined>(`${this.apiPath}/findorganization?barcode=${barcode}`);
    }
}

export const bottleFillingService = new BottleFillingService();
export const bottleFillingStore = bottleFillingService.getDxData();