import React from "react";
import DataGrid, { IColumnProps, IDataGridOptions } from "devextreme-react/data-grid";
import { ModuleConfig } from "../../base";
import { bottleFillingStore, fillingUserService, fillingTargetService } from "../../api";
import { remoteReadOnlyGridOptions, createGridOptions, setColumnDataSource, StdGrid } from "../../components";
import { FillingTargetRef, FillingUserRef } from "../../models";

export interface NewShippingGridProps {
    onSelectionChanged: (fillingIds: number[]) => void;
}

interface NewShippingGridState {
    users: FillingUserRef[];
    targets: FillingTargetRef[];
    columns: IColumnProps[];
}

export default class NewShippingGrid
    extends React.Component<NewShippingGridProps, NewShippingGridState> {
    moduleName: ModuleConfig = {
        moduleName: "BottleFilling",
        children: {
            "bottle": "Bottle"
        }
    };

    gridRef = React.createRef<DataGrid>();
    gridOptions: IDataGridOptions;
    selectedIds: number[] = [];

    constructor(props: Readonly<NewShippingGridProps>) {
        super(props);

        this.gridOptions = {
            ...createGridOptions(
                "newShippings",
                this.gridRef,
                remoteReadOnlyGridOptions,
                (items: any[]) => {
                    items.splice(items.findIndex(i => i.id === "gtb_check"), 1);    // 该页面无需单选

                    // state清空会同时清空filter，需要重新绑定旧的
                    items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
                    return items;
                }),

            pager: {
                displayMode: 'adaptive',
                showPageSizeSelector: true,
                showInfo: true,
                visible: true,             
                allowedPageSizes: [100, 200, 300, 500, 1000]
            },
            selection: {
                mode: "multiple", selectAllMode: "page"
            },
            onSelectionChanged: e => this.props.onSelectionChanged(e.selectedRowKeys),

            // 条件改变时应该要清楚数据，否则会意外选中
            onFilterValueChange: () => { alert('xx'); this.gridRef.current?.instance.clearSelection() }
        };

        this.state = {
            users: [],
            targets: [],
            columns: [
                { dataField: 'barCode' },
                { dataField: 'bottle.code', allowFiltering: true },
                { dataField: 'bottle.vendor' },
                { dataField: 'bottle.typeCode' },
                { dataField: 'fillingTime', dataType: "datetime", allowFiltering: false },
                {
                    dataField: 'userCode',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'code' }
                },
                { dataField: 'originalWeight' },
                { dataField: 'weight' },
                { dataField: 'fillingWeight' },
                {
                    dataField: 'target',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'code' }
                }
            ]
        };

        this.filter = this.filter.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        const newState: NewShippingGridState = { ...this.state };

        await Promise.all([
            (async () => newState.users = await fillingUserService.getRefs())(),
            (async () => {
                const data = await fillingTargetService.getRefs();
                newState.targets = data.map(t => ({ code: t.code, name: `${t.organization!.name} | ${t.code}` }));
            })()
        ]);

        setColumnDataSource(newState.columns, "userCode", newState.users);
        setColumnDataSource(newState.columns, "target", newState.targets);

        this.setState(newState);
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    clear() {
        // 因为条件不再重置，先清除选择，再清空
        this.gridRef.current?.instance.clearSelection();
        this.gridRef.current?.instance.filter(null);
    }

    render() {
        return (
            <React.Fragment>
                <StdGrid
                    moduleName={this.moduleName}
                    gridRef={this.gridRef}
                    dataSource={bottleFillingStore}
                    options={this.gridOptions}
                    columns={this.state.columns}>
                </StdGrid>
            </React.Fragment>
        );
    }
}