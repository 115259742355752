import { CustomerModel } from "../../models";
import PhotoList from "./photo-list";

export interface CustomerPhotoProps {
    customer: CustomerModel;
}

export default function CustomerPhoto(props: CustomerPhotoProps) {
    const fileNames = props.customer.photos
        ?.filter(p => p.photoType === "IdCard")
        ?.map(p => p.fileName!)[0]
        ?.split(';')
        ?? [];

    return (
        <PhotoList fileNames={fileNames} />
    )
}