import CustomStore from "devextreme/data/custom_store";
import { http } from "../base";
import { OrganizationStockInitModel } from "../models";

class StockInitService {
    apiPath = "/api/stockinit";

    getAll(key?: string) {
        return http.getData<OrganizationStockInitModel[]>(`${this.apiPath}?key=${key || ''}`);
    }

    save(model: OrganizationStockInitModel) {
        return http.putData(this.apiPath, model);
    }
}

export const stockInitService = new StockInitService();
export const stockInitStore = new CustomStore({
    key: 'organizationId',

    load: async function () {
        const data = await stockInitService.getAll();
        return {
            data: data,
            totalCount: data.length
        };
    }
})