import { Button, SelectBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/tree-list";
import React from "react";
import { ListItem } from "../../../base";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { ClickEvent } from "devextreme/ui/button";

const groupTypes: ListItem[] = [
    { value: 'Type', text: '类型' },
    { value: 'OrganizationId', text: '站点' }
];

export interface ExceptionDataSummaryToolbarProps {
    groupType: string;
    onGroupTypeChanged: (groupType: string) => void;
    refreshSummary: () => void;
}

interface ExceptionDataSummaryToolbarState {
    updatedAt: string;
}

export default class ExceptionDataSummaryToolbar extends
    React.Component<ExceptionDataSummaryToolbarProps, ExceptionDataSummaryToolbarState> {
    
    constructor(props: Readonly<ExceptionDataSummaryToolbarProps>) {
        super(props);

        this.state = {
            updatedAt: ''
        }

        this.onGroupTypeChanged = this.onGroupTypeChanged.bind(this);
        this.onRefreshButtonClicked = this.onRefreshButtonClicked.bind(this);
    }

    onGroupTypeChanged(e: ValueChangedEvent) {
        this.props.onGroupTypeChanged(e.value);
    }

    onRefreshButtonClicked(e: ClickEvent) {
        this.props.refreshSummary();
    }

    setUpdatedAt(updatedAt: string) {
        this.setState({ updatedAt: `统计更新时间：${updatedAt} ` });
    }

    render() {
        return (
            <div className="page-toolbar">
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"} text={"分组类型："}></Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={this.props.groupType}
                            dataSource={groupTypes}
                            displayExpr={"text"}
                            valueExpr={"value"}
                            width={120}
                            placeholder="分组类型"
                            onValueChanged={this.onGroupTypeChanged} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} text={this.state.updatedAt}></Item>
                    <Item location={"after"} locateInMenu={"auto"}>
                        &nbsp; <Button text="立即更新" onClick={this.onRefreshButtonClicked} />
                    </Item>
                </Toolbar>
            </div>
        )
    }
}