import React from "react";
import SiteDangerGrid from "./site-danger-grid";
import SiteDangerDetail from "./site-danger-detail";
import SiteDangerToolbar from "./site-danger-toolbar";
import AuthorizedComponent from "../authorized-component";
import { AssignEmployeePopup, ContentBlock, DangerAuditPopup } from "../../components";
import { alertError, baseApiUrl, confirm2, download, enums, notifySuccess } from "../../base";
import { siteDangerService, employeeService, dangerService } from "../../api";
import { DangerAuditModel, EmployeeRef, SiteDangerModel } from "../../models";

export default class SiteDangerPage extends AuthorizedComponent {
    objectName = "SiteDanger";
    gridRef = React.createRef<SiteDangerGrid>();
    detailRef = React.createRef<SiteDangerDetail>();
    assignRef = React.createRef<AssignEmployeePopup>();
    toolbarRef = React.createRef<SiteDangerToolbar>();
    auditRef = React.createRef<DangerAuditPopup>();
    dangerOfAssign: SiteDangerModel = {};

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onRowChecked = this.onRowChecked.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onContextButtonClick = this.onContextButtonClick.bind(this);
        this.getHandlers = this.getHandlers.bind(this);
        this.assignHandler = this.assignHandler.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.audit = this.audit.bind(this);
        this.onSaved = this.onSaved.bind(this);
    }

    get actionNames(): string[] {
        return [
            enums.stdActions.create,
            enums.stdActions.update,
            enums.stdActions.delete,
            "Audit"
        ];
    }

    onModelSelected(model: SiteDangerModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onRowChecked(ids: number[]) {
        this.toolbarRef.current?.setIds(ids);
    }

    onFiltering(filterValue: any, showDeleted: boolean) {
        this.gridRef.current?.filter(filterValue, showDeleted);
    }

    onButtonClick(model: SiteDangerModel, name: string) {
        switch (name) {
            case "detail":
                this.detailRef.current?.show(model);
                break;

            case "audit":
                if (this.permissions["Audit"]) {
                    this.showAuditPopup(model);
                }
                break;

            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(model);
                }
                break;

            case "restore":
                if (this.permissions[enums.stdActions.delete]) {
                    this.restore(model);
                }
                break;
        }
    }

    async onContextButtonClick(ids: number[], name: string) {
        switch (name) {
            case "export":
                if (!await confirm2("确定要导出选中的站点隐患整改吗？")) {
                    return;
                }

                const url = `${baseApiUrl}${siteDangerService.apiPath}/export?ids=${ids.join(',')}`;
                download(url, `站点隐患整改单.xls`);
                break;
        }
    }

    showEmployeePopup(model: SiteDangerModel) {
        this.dangerOfAssign = model;
        this.assignRef.current?.show(model.ownerId);
    }

    showAuditPopup(danger: SiteDangerModel) {
        this.auditRef.current?.show(danger.id!);
    }

    async getHandlers() {
        const handlers = await employeeService.getRefs();

        return handlers.filter(c => c.organizationId === this.dangerOfAssign.organizationId);
    }

    async assignHandler(employee: EmployeeRef) {
        const result = await dangerService.assign(this.dangerOfAssign.id!, employee.id!);

        if (result.succeeded) {
            notifySuccess("成功分配送气工/安检员");
            this.assignRef.current?.hide();
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async audit(model: DangerAuditModel) {
        return siteDangerService.audit(model);
    }

    async delete(danger: SiteDangerModel) {
        if (!await confirm2("确定要作废此数据吗？")) {
            return;
        }

        const result = await siteDangerService.delete(danger.id!);

        if (result.succeeded) {
            notifySuccess("已成功作废");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async restore(danger: SiteDangerModel) {
        if (!await confirm2("确定要还原此数据吗？")) {
            return;
        }

        const result = await siteDangerService.restore(danger.id!);

        if (result.succeeded) {
            notifySuccess("已成功还原");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return (
            <ContentBlock caption={"站点隐患管理"}>
                <SiteDangerToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onButtonClick={this.onButtonClick}
                    onContextButtonClick={this.onContextButtonClick}
                    onFiltering={this.onFiltering} />
                <SiteDangerGrid
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onRowChecked={this.onRowChecked}
                    onButtonClick={this.onButtonClick} />
                <SiteDangerDetail ref={this.detailRef} />  
                <DangerAuditPopup 
                    title="站点隐患整改审核" 
                    ref={this.auditRef} 
                    saveFunc={this.audit} 
                    onSaved={this.onSaved} />                  
            </ContentBlock>
        )
    }
}