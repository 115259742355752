import "./toolbar-time-filter.scss";
import { DateBox } from "devextreme-react";
import { ValueChangedEvent } from "devextreme/ui/date_box";
import React from "react";
import { stdTimeOnlyFormat, today } from "../../base";

export interface ToolbarTimeFilterEvent {
    startTime: Date | string;
    endTime: Date | string;
}

export interface ToolbarTimeFilterProps {
    onChanged: (e: ToolbarTimeFilterEvent) => void;
}

interface ToolbarTimeFilterState {
    date: Date;
    startTime?: Date;
    endTime?: Date;
}

/**
 * 选择日期后，+时间进行过滤
 */
export default class ToolbarTimeFilter
    extends React.Component<ToolbarTimeFilterProps, ToolbarTimeFilterState> {

    constructor(props: Readonly<ToolbarTimeFilterProps>) {
        super(props);

        const date = today();

        this.state = {
            date: date,
            startTime: undefined,
            endTime: undefined
        };

        this.dateChanged = this.dateChanged.bind(this);
        this.startTimeChanged = this.startTimeChanged.bind(this);
        this.endTimeChanged = this.endTimeChanged.bind(this);
    }

    dateChanged(e: ValueChangedEvent) {
        this.setState({
            date: e.value
        });

        let stime = this.state.startTime;
        let etime = this.state.endTime;

        if (stime && etime) {
            this.fireChanged(e.value, stime, etime);
        }
    }

    fireChanged(date: Date | string, startTime: Date | string, endTime: Date | string) {
        date = new Date(date);
        startTime = new Date(startTime);
        endTime = new Date(endTime);

        startTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(),
            startTime.getHours(), startTime.getMinutes());
        endTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(),
            endTime.getHours(), endTime.getMinutes());

        this.props.onChanged({ startTime: startTime, endTime: endTime });
    }

    startTimeChanged(e: ValueChangedEvent) {
        this.setState({
            startTime: e.value,
        });

        let etime = this.state.endTime;
        if (etime) {
            this.fireChanged(this.state.date, e.value, etime);
        }
    }

    endTimeChanged(e: ValueChangedEvent) {
        this.setState({
            endTime: e.value
        });

        let stime = this.state.startTime;
        if (stime) {
            this.fireChanged(this.state.date, stime, e.value);
        }
    }

    reset() {
        this.setState({
            date: today(),
            startTime: undefined,
            endTime: undefined
        })
    }

    render() {
        const { date, startTime, endTime } = this.state;

        return (
            <div className={"toolbar-time-filter"}>
                <DateBox
                    type={"date"}
                    value={date}
                    onValueChanged={this.dateChanged} />
                <div>
                    <DateBox
                        type={"time"}
                        value={startTime}
                        displayFormat={stdTimeOnlyFormat}
                        onValueChanged={this.startTimeChanged} />
                    <DateBox
                        type={"time"}
                        value={endTime}
                        displayFormat={stdTimeOnlyFormat}
                        onValueChanged={this.endTimeChanged} />
                </div></div>
        );
    }
}