import { createStoreFromUrl, BaseApiService, http, MessageBool } from "../base";
import { DangerAuditModel, SiteDangerModel } from "../models";

class SiteDangerService extends BaseApiService<SiteDangerModel> {
    moduleName = "sitedanger";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }

    getDeletedDxData() {
        return createStoreFromUrl(`${this.apiPath}/deleteddx`, 'id');
    }

    restore(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/restore/${id}`, '');
    }

    audit(model: DangerAuditModel) {
        return http.putData<MessageBool>(`${this.apiPath}/audit`, model);
    }
}

export const siteDangerService = new SiteDangerService();
export const siteDangerStore = siteDangerService.getDxData();
export const siteDangerDeletedStore = siteDangerService.getDeletedDxData();