import { createStore } from "devextreme-aspnet-data-nojquery";
import CustomStore from "devextreme/data/custom_store";
import { BaseApi } from "./base-api.service";
import { baseApiUrl } from "./base-values";
import { tokenStorage } from "./token-storage";
import { tokenService } from "./token.service";

export function createEmptyStore() {
    return new CustomStore({
        load: function() {
            return Promise.resolve({
                data: [],
                totalCount: 0
            });
        }
    })
}

export function createStoreFromApi(api: BaseApi, keyName: string | string[] = 'id') {
    return new CustomStore({
        key: keyName,

        load: async function (options?:any) {
            const data = await api.searchAll();
            return {
                data: data,
                totalCount: data.length
            };
        },

        byKey: async function (key: any) {
            if (!key) { return null; }
            const item = await api.get(key);
            return item;
        },

        insert: async function (values: any) {
            const res = await api.create(values);
            if (!res.succeeded)
                throw res.message;
        },

        update: async function (key: any, values: any) {
            const res = await api.update(values);
            if (!res.succeeded)
                throw res.message;
        },

        remove: async function (key: any) {
            const res = await api.delete(key);
            if (!res.succeeded)
                throw res.message;
        }
    })
}

export interface SendSettings {
    cache?: boolean;
    contentType?: any;
    data?: any;
    dataType?: string;
    headers?: { [key: string]: any; };
    method?: string;
    password?: string;
    timeout?: number;
    url?: string;
    username?: string;
    xhrFields?: { [key: string]: any; };
}

export interface CreateStoreOptions {
    key?: string | Array<string>,
    errorHandler?: (e: Error) => void,

    loadUrl?: string,
    loadParams?: Object,
    loadMethod?: string,

    updateUrl?: string,
    updateMethod?: string,

    insertUrl?: string,
    insertMethod?: string,

    deleteUrl?: string,
    deleteMethod?: string,

    loadMode?: "processed" | "raw",
    cacheRawData?: boolean,

    onBeforeSend?: (operation: string, ajaxSettings: SendSettings) => void,
    onAjaxError?: (e: { xhr: XMLHttpRequest, error: string | Error }) => void

    onLoading?: (loadOptions: any) => void;
    onLoaded?: (result: Array<any>) => void;

    onInserting?: (values: any) => void;
    onInserted?: (values: any, key: any) => void;

    onUpdating?: (key: any, values: any) => void;
    onUpdated?: (key: any, values: any) => void;

    onRemoving?: (key: any) => void;
    onRemoved?: (key: any) => void;

    onModifying?: Function;
    onModified?: Function;

    onPush?: (changes: Array<any>) => void;
}

export function createStoreFromUrl(
    url: string,
    key: string | string[],
    requireFilter?: boolean,
    options?: CreateStoreOptions,
    onBeforeSend?: (op: string, settings: SendSettings) => void) {

    const store = createStore({
        key: key,
        loadUrl: `${baseApiUrl}${url}`,

        onBeforeSend: function (_operation: string, settings: SendSettings) {
            settings.xhrFields = settings.xhrFields || {};
            settings.xhrFields.withCredentials = true;
            settings.headers = { "Authorization": `bearer ${tokenStorage.token}` };
            onBeforeSend && onBeforeSend(_operation, settings);
        },

        onAjaxError: function (e: { xhr: XMLHttpRequest, error: string | Error }) {
            if (e.xhr.status === 401) {
                tokenService.clearStorage();
            }
        },
        ...(options || {})
    });

    // 由于DEV GRID的过滤框无法控制，因此修改了源代码
    // devextreme/esm/ui/grid_core/ui.grid_core.filter_row.js
    // 中的FILTERING_TIMEOUT值，如更新npm或在其它机器上编译，应做同样处理

    if (requireFilter) {
        /**
         * 限制必须要有过滤器才加载，配合界面控制，实现时间（或其它）过滤的必选要求
         * ！！！DEV的load并不是Promise，而是类似jQuery的Deferred
         */
        const originalLoad = store.load;
        store.load = function(options?: any): any {
            if (!options?.filter) {
                return {
                    done: function(callback: (data: any) => void) {
                        callback({ data: [], totalCount: 0 });
                        return this;
                    },
                    fail: function() {
                        return this;
                    }
                }
            } else {
                return originalLoad.call(store, options);
            }
        }
    }

    return store;
}