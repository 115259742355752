import React from "react";
import BottleFillingGrid from "./bottle-filling-grid";
import BottleFillingForm from "./bottle-filling-form";
import BottleFillingToolbar from "./bottle-filling-toolbar";
import { ContentBlock } from "../../components";
import { AuthContext, AuthContextType } from "../../hooks";
import AuthorizedComponent from "../authorized-component";
import { BottleFillingModel } from "../../models";

export default class BottleFillingPage extends AuthorizedComponent {
    objectName = "BottleFilling";
    gridRef = React.createRef<BottleFillingGrid>();
    formRef = React.createRef<BottleFillingForm>();
    toolbarRef = React.createRef<BottleFillingToolbar>();

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
    }

    onModelSelected(model: BottleFillingModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onFiltering(filterValue: any) {
        this.gridRef.current?.filter(filterValue);
    }

    onButtonClick(model: BottleFillingModel, name: string) {
        switch (name) {
            case "add": 
                this.formRef.current?.show({});
                break;
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return <AuthContext.Consumer>
            {context => this.renderMain(context)}
        </AuthContext.Consumer>
    }

    renderMain(context: AuthContextType) {
        return (
            <ContentBlock caption={"充装档案"}>
                <BottleFillingToolbar
                    ref={this.toolbarRef}
                    user={context.user}
                    onFiltering={this.onFiltering}
                    onButtonClick={this.onButtonClick} />
                <BottleFillingGrid 
                    ref={this.gridRef} 
                    user={context.user} 
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
                <BottleFillingForm ref={this.formRef} onSaved={this.onSaved} />
            </ContentBlock>
        );
    }
}