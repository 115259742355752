import { dataSources, ListItem } from "../base";

export interface ExceptionDataHandleModel {
    ids: number[];
    note?: string;
}

export interface ExceptionDataSummary {
    type?: string;
    organizationId?: number;
    yearDataCount: number;
    monthDataCount: number;
    weekDataCount: number;
    dayDataCount: number;
    latestTime: string | Date;
    updatedAt: string | Date;
}

export const exceptionTypes: ListItem<string>[] = [
    { value: 'BarcodeNotEqualsCode', text: '重瓶领出时发现条码和钢印号不一致' },
    { value: 'RequireFilling', text: '使用没有任何充装记录的气瓶' },
    { value: 'NoRepeatFlow', text: '在短时间内创建重复的流转记录' },
    { value: 'StockOutTimeout', text: '气瓶领出后超时未送达' },
    { value: 'FillingTimeout', text: '气瓶充装后未送达' },
    { value: 'RecycleTimeout', text: '气瓶回收后超时未入库' },
    { value: 'RepeatDeliver', text: '重瓶多次送达' },
    { value: 'NoRecycle', text: '回收空瓶未扫描' },
    { value: 'RecycleNotMatch', text: '回收气瓶不匹配' },
    { value: 'EmptyInNotMatch', text: '入库空瓶异常' },
    { value: 'SiteNotMatch', text: '窜瓶' },
    { value: 'OverloadDeliver', text: '超限额配送' },
    { value: 'EmptyInTimeout', text: '气瓶入库超时未充装' },
    ...dataSources.bottleOperations.map(o => ({
        value: `BottleFlow.${o.value}`,
        text: `流转异常：${o.text}`
    })),
];

export const exceptionWarningTypes: ListItem<string>[] = [
    { value: 'StockOutTimeout', text: '气瓶领出后超时未送达' },
    { value: 'RecycleTimeout', text: '气瓶回收后超时未入库' },
    { value: 'OverloadDeliver', text: '超限额配送' }
];