import React from "react";
import DangerNotificationGrid from "./danger-notification-grid";
import DangerNotificationDetail from "./danger-notification-detail";
import DangerNotificationToolbar from "./danger-notification-toolbar";
import { ContentBlock } from "../../components";
import { DangerModel } from "../../models";
import { baseApiUrl, confirm2, download, globalPopupManager, globalPopupNames } from "../../base";
import { dangerService } from "../../api";

export default class DangerNotificationPage extends React.Component<any> {
    detailRef = React.createRef<DangerNotificationDetail>();
    toolbarRef = React.createRef<DangerNotificationToolbar>();
    gridRef = React.createRef<DangerNotificationGrid>();

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onRowChecked = this.onRowChecked.bind(this);
        this.filter = this.filter.bind(this);
    }

    onModelSelected(model: DangerModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onRowChecked(ids: number[]) {
        this.toolbarRef.current?.setIds(ids);
    }

    onButtonClick(model: DangerModel, name: string) {
        switch (name) {
            case "callcenter":
                globalPopupManager.show(globalPopupNames.callCenter, { id: model.customerId });
                break;
                
            case "detail":
                this.detailRef.current?.show(model);
                break;
        }
    }

    async onContextButtonClick(ids: number[], name: string) {
        switch (name) {
            case "export":
                if (!await confirm2("确定导出选中的整改通知吗？")) {
                    return;
                }

                const url = `${baseApiUrl}${dangerService.apiPath}/exportnotificationform?ids=${ids.join(',')}`;
                download(url, `整改通知单.xls`);
                break;
        }
    }

    filter(filterValue: any) {
        this.gridRef.current?.filter(filterValue);
    }

    render() {
        return (
            <ContentBlock caption={"整改通知"}>
                <DangerNotificationToolbar
                    ref={this.toolbarRef}
                    onFiltering={this.filter}
                    onButtonClick={this.onButtonClick}
                    onContextButtonClick={this.onContextButtonClick} />
                <DangerNotificationGrid 
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onRowChecked={this.onRowChecked} 
                    onButtonClick={this.onButtonClick} />
                <DangerNotificationDetail ref={this.detailRef} />
            </ContentBlock>
        );
    }
}