import { Popup } from "devextreme-react";
import React from "react";
import { baseApiUrl, http } from "../../base";
import { RefPopup } from "../../components";

export default class CpBatchPrintDialog extends React.Component {
    popupRef = React.createRef<Popup>();

    async show(url: string) {
        await this.popupRef.current?.instance.show();

        const frame = document.getElementById("cpBatchPrintDialog") as HTMLIFrameElement; 
        const doc = frame.contentWindow!.document;
        doc.write("加载中，请稍候...");
        doc.close();

        const html = await http.httpData(`${baseApiUrl}${url}`, "GET", undefined, true);
        doc.write(html || "无数据");
        doc.close();
    }

    render() {
        return (
            <RefPopup 
                popupRef={this.popupRef} 
                title={"批次打印"}
                width={1150}
                height={"95%"}>
                <iframe 
                    id="cpBatchPrintDialog" 
                    title="cpBatchPrintDialog"
                    style={{ width: "100%", height: "100%", border: "none" }} />
            </RefPopup>
        )
    }
}