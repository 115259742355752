import './login-form.scss';
import { useState, useRef, useCallback } from 'react';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useAuth } from '../../hooks';
import { alertError, corpName, tokenStorage } from '../../base';

interface FormData {
    username?: string;
    password?: string;
    longTimeToken?: string;
    remember: boolean;
}

export default function LoginForm() {
    const { signIn, autoSignIn } = useAuth();
    const [loading, setLoading] = useState(false);
    const formData = useRef<FormData>({ remember: false });
    const longTimeToken = tokenStorage.longTimeToken;

    if (longTimeToken) {
        formData.current.username = longTimeToken.username;
        formData.current.password = "123456";
        formData.current.longTimeToken = longTimeToken.longTimeToken;
        formData.current.remember = true;
    }
    
    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        const { username, password, remember } = formData.current;
        setLoading(true);

        const longTimeToken = tokenStorage.longTimeToken;

        const result = longTimeToken?.username === username ?
            await autoSignIn!() :
            await signIn!(username!, password!, remember);
            
        setLoading(false);

        if (!result.succeeded) {
            alertError(result.message!);
        }
    }, [signIn, autoSignIn]);

    return (
        <form className={'login-form'} onSubmit={onSubmit}>
            <Form formData={formData.current} disabled={loading}>
                <Item
                    dataField={'username'}
                    editorType={'dxTextBox'}
                    editorOptions={usernameEditorOptions}
                >
                    <RequiredRule message="用户名不能为空" />
                    <Label text="用户名" />
                </Item>
                <Item
                    dataField={'password'}
                    editorType={'dxTextBox'}
                    editorOptions={passwordEditorOptions}
                >
                    <RequiredRule message="密码不能为空" />
                    <Label text="密码" /> 
                </Item>
                <Item dataField={'remember'}
                    editorType={'dxCheckBox'}
                    editorOptions={{'text': '记住我（请勿在公共场所选择此项）'}}>
                    <Label visible={false} />
                </Item>
                <ButtonItem>
                    <ButtonOptions
                        width={'100%'}
                        type={'default'}
                        useSubmitBehavior={true}
                    >
                        <span className="dx-button-text">
                            {
                                loading
                                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                    : '登录'
                            }
                        </span>
                    </ButtonOptions>
                </ButtonItem>
                <Item>
                    <div className={'login-about'}>
                        {corpName}
                    </div>
                </Item>
            </Form>
        </form>
    );
}

const usernameEditorOptions = { stylingMode: 'outlined', mode: 'text' };
const passwordEditorOptions = { stylingMode: 'outlined', mode: 'password' };
