import { Popup, SelectBox } from "devextreme-react";
import React from "react";
import { alertWarning } from "../../base";
import { EmployeeRef } from "../../models";
import { PopupContentBox } from "../layouts";
import { RefPopup } from "../popup";

export interface AssignEmployeeProps {
    caption?: string;
    getFunc: () => Promise<EmployeeRef[]>;
    save: (employee: EmployeeRef) => void;
}

interface AssignEmployeeState {
    employees: EmployeeRef[];
}

export default class AssignEmployeePopup extends React.Component<AssignEmployeeProps, AssignEmployeeState> {
    popupRef = React.createRef<Popup>();
    selectRef = React.createRef<SelectBox>();
    employeeId?: string;

    constructor(props: Readonly<AssignEmployeeProps>) {
        super(props);

        this.state = { employees: [] };

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    show(defaultId?: string) {
        this.popupRef.current?.instance.show();
        
        this.employeeId = defaultId;
        this.selectRef.current?.instance.option("value", defaultId);
        this.loadEmployees();
    }

    async loadEmployees() {
        const data = await this.props.getFunc();
        this.setState({ employees: data });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    save() {
        if (!this.employeeId) {
            alertWarning("请选择要分配的员工");
            return;
        }
        
        const employee = this.state.employees.find(e => e.id === this.employeeId);
        this.props.save(employee!);
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={this.props.caption || "分配员工"}
                width={300}
                height={150}>
                <PopupContentBox onSave={this.save} onCancel={this.cancel}>
                    <SelectBox 
                        ref={this.selectRef}
                        dataSource={this.state.employees} 
                        displayExpr={"nameAndCode"} 
                        valueExpr={"id"}
                        defaultValue={this.employeeId}
                        searchEnabled={true}
                        onValueChanged={(e) => this.employeeId = e.value} />
                </PopupContentBox>
            </RefPopup>            
        )
    }
}