import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import './dx-styles.scss';
import { loadMessages, locale } from 'devextreme/localization';
import zhMessages from 'devextreme/localization/messages/zh.json';
import { Router } from 'react-router-dom';
import { NavigationProvider, AuthProvider, useAuth, useScreenSizeClass, 
    ServerDataProvider, FavoriteProvider, ScreenSizeProvider } from "./hooks";
import Content from './Content';
import { historyInstance } from "./base/history-instance";
import UnauthenticatedContent from "./UnauthenticatedContent";
import { LoadPanel } from 'devextreme-react';
import config from "devextreme/core/config";

loadMessages(zhMessages);
locale('zh');

config({
    editorStylingMode: "outlined",
    defaultCurrency: "CNY"
});

function AppContent() {
    const { user, loading } = useAuth();

    if (loading) {
        return <LoadPanel visible={true} />;
    } else if (user) {
        return <Content />;
    } else {
        return <UnauthenticatedContent />;
    }
}

function AppLayout() {
    const screenSizeClass = useScreenSizeClass();

    return (
        <div className={`app ${screenSizeClass}`}>
            <AppContent />
        </div>
    );
}

export default function App() {
    const history = historyInstance;

    return (
        <Router history={history}>
            <ServerDataProvider>
                <AuthProvider>
                    <NavigationProvider>
                        <FavoriteProvider>
                            <ScreenSizeProvider>
                                <AppLayout />
                            </ScreenSizeProvider>
                        </FavoriteProvider>
                    </NavigationProvider>
                </AuthProvider>
            </ServerDataProvider>
        </Router>
    );
}