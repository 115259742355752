import React from "react";
import { alertError, confirm2, enums, notifySuccess } from "../../base";
import { bugTraceService } from "../../api";
import { ContentBlock, StdToolbar } from "../../components";
import AuthorizedComponent from "../authorized-component";
import BugTraceGrid from "./bug-trace-grid";
import BugTraceForm from "./bug-trace-form";
import { BugTraceModel } from "../../models";

export default class BugTracePage extends AuthorizedComponent {
    objectName = "BugTrace";
    gridRef = React.createRef<BugTraceGrid>();
    formRef = React.createRef<BugTraceForm>();
    toolbarRef = React.createRef<StdToolbar>();

    constructor(props: Readonly<any>) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
    }

    onModelSelected(e?: BugTraceModel) {
        this.toolbarRef.current?.setModel(e);
    }

    onButtonClick(model: BugTraceModel, name: string) {
        switch (name) {
            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.formRef.current?.show({});
                }
                break;

            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.formRef.current?.show(model);
                }
                break;

            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(model);
                }
                break;
        }
    }

    async delete(model: BugTraceModel) {
        if (!await confirm2("确定要删除该数据吗？")) {
            return;
        }

        const res = await bugTraceService.delete(model.id!);

        if (res.succeeded) {
            notifySuccess("已成功删除数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(res.message!);
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    render() {
        return (
            <ContentBlock caption={"BUG跟踪"}>
                <StdToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onButtonClick={this.onButtonClick} />
                <BugTraceGrid
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
                <BugTraceForm ref={this.formRef} onSaved={this.onSaved} />
            </ContentBlock>
        );
    }
}