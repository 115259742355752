import { createStoreFromApi, http, BaseApiService } from "../base";
import { FillingTargetModel, FillingTargetRef } from "../models";

class FillingTargetService extends BaseApiService<FillingTargetModel> {
    moduleName = "fillingtarget";
    cacheSeconds = 60;

    getRefs(): Promise<FillingTargetRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/refs`), this.cacheSeconds);
    }
}

export const fillingTargetService = new FillingTargetService();
export const fillingTargetStore = createStoreFromApi(fillingTargetService, "code");