import React from "react";
import AuthorizedComponent from "../authorized-component";
import VehicleGrid from "./vehicle-grid";
import VehicleForm from "./vehicle-form";
import VehicleToolbar from "./vehicle-toolbar";
import { ContentBlock } from "../../components";
import { organizationService, vehicleService } from "../../api";
import { enums, alertError, confirm2, notifySuccess, alertWarning, tokenService } from "../../base";
import { OrganizationRef, VehicleModel } from "../../models";

interface VehiclePageState {
    orgs: OrganizationRef[];
}

export default class VehiclePage extends AuthorizedComponent<any, VehiclePageState> {
    objectName = "Vehicle";
    gridRef = React.createRef<VehicleGrid>();
    formRef = React.createRef<VehicleForm>();
    toolbarRef = React.createRef<VehicleToolbar>();

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            orgs: []
        };

        this.onVehicleSelected = this.onVehicleSelected.bind(this)
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
        this.filter = this.filter.bind(this);
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.loadOrgs();
    }

    async loadOrgs() {
        const orgs = await organizationService.getRefs();
        this.setState({ orgs: orgs });
    }

    onVehicleSelected(vehicle: VehicleModel) {
        this.toolbarRef.current?.setModel(vehicle);
    }

    async onButtonClick(model: VehicleModel, name: string) {
        switch (name) {
            case "add":
                const user = await tokenService.getUser();
                if (this.permissions[enums.stdActions.create]) {
                    this.formRef.current?.show({ organizationIds: [ user!.organizationId ] });
                }
                break;

            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    if (model.organizations?.find(ov => !this.state.orgs.find(o => o.id === ov.organizationId))) {
                        alertWarning("您没有权限编辑共享的车辆，请联系公司管理员修改");
                        return;
                    }
                    
                    this.formRef.current?.show(model);
                }
                break;

            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    if (model.organizations?.find(ov => !this.state.orgs.find(o => o.id === ov.organizationId))) {
                        alertWarning("您没有权限删除共享的车辆，请联系公司管理员修改");
                        return;
                    }

                    this.delete(model.id!);
                }
                break;
        }
    }
    
    async delete(id: number) {
        if (!await confirm2("确定要删除该车辆档案吗？")) {
            return;
        }

        const res = await vehicleService.delete(id);

        if (res.succeeded) {
            notifySuccess("已成功删除数据");
            this.gridRef.current?.refresh();
        } else {
            alertError(res.message!);
        }
    }

    onSaved() {
        this.gridRef.current?.refresh();
    }

    filter(condition: any) {
        this.gridRef.current?.filter(condition);
    }

    render() {
        return (
            <ContentBlock caption={"车辆档案"}>
                <VehicleToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    onButtonClick={this.onButtonClick}
                    onFiltering={this.filter} />
                <VehicleGrid 
                    ref={this.gridRef}
                    onVehicleSelected={this.onVehicleSelected}
                    onButtonClick={this.onButtonClick} />
                <VehicleForm 
                    ref={this.formRef} 
                    organizations={this.state.orgs} 
                    onSaved={this.onSaved} />
            </ContentBlock>
        );
    }
}