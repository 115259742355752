import './wxconfig.scss';
import { Button, ScrollView, TagBox } from "devextreme-react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { ContentBlock, FullPanel, ImagePopup } from "../../components";
import AuthorizedComponent from "../authorized-component";
import { alertError, baseApiUrl, DataMap, dataSources, enums, ListItem, notifySuccess, StringMap } from '../../base';
import { dataSourceService, wxConfigService } from '../../api';
import React from 'react';

interface WxConfigState {
    imageMap: DataMap<string[]>;
    messageMap: DataMap<string[]>;
    posts: ListItem[];
}

export default class WxConfigPage extends AuthorizedComponent<any, WxConfigState> {
    objectName = "WxConfg";
    imageRef = React.createRef<ImagePopup>();

    constructor(props: any) {
        super(props);

        this.state = {
            imageMap: {},
            messageMap: {},
            posts: []
        };

        this.setImage = this.setImage.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.loadPosts();
        this.loadImages();
        this.loadConfigs();
    }

    async loadPosts() {
        const customPosts = await dataSourceService.getDataSource(enums.dataSourceTypes.customPosts);
        this.setState({ 
            posts: [...dataSources.employeePosts, ...(customPosts || []).map(p => ({ text: p, value: p }))]
        })
    }

    async loadImages() {
        const images = await wxConfigService.getImages(imageKeys.map(k => k.value));
        this.setState({ imageMap: images });
    }

    async loadConfigs() {
        const configs = await wxConfigService.getConfigs(Object.values(enums.messageTypes));
        const messageMap: DataMap<string[]> = {};

        for (const key in configs) {
            messageMap[key] = configs[key] ? configs[key].split(',') : [];
        }

        this.setState({ messageMap: messageMap });
    }

    showImagePopup(key: string, index?: number) {
        this.imageRef.current?.show({ key: key, index: index });
    }

    setImage(photo: string, {key, index}: { key: string, index?: number }) {
        this.imageRef.current?.hide();

        const imageMap = this.state.imageMap;
        imageMap[key] = imageMap[key] || [];

        if (index === undefined) {
            imageMap[key].push(...photo.split(';'));
        } else {
            imageMap[key].splice(index, 1, ...photo.split(';'));
        }

        this.setState({ imageMap: imageMap });
    }

    removeImage(key: string, index: number) {
        const imageMap = this.state.imageMap;
        imageMap[key].splice(index, 1);

        this.setState({ imageMap: imageMap });
    }

    setMessage(posts: string[], key: string) {
        const messageMap = this.state.messageMap;
        messageMap[key] = posts;
        this.setState({ messageMap: messageMap });
    }

    async save() {
        let res = await wxConfigService.setImages(this.state.imageMap);

        if (res.succeeded) {
            const configs: StringMap = {};

            for (const key in this.state.messageMap) {
                configs[key] = this.state.messageMap[key].join(",");
            }
            
            res = await wxConfigService.setConfigs(configs);

            if (res.succeeded) {
                notifySuccess("已成功保存");
            } else {
                alertError(res.message!);
            }
        } else {
            alertError(res.message!);
        }
    }

    render() {
        const { imageMap, messageMap, posts } = this.state;

        return (
            <ContentBlock caption="微信设置">
                <div className="page-toolbar">
                    <Toolbar>
                        <Item location="after" locateInMenu="auto">
                            <Button text="保存设置" type="default" onClick={this.save} />
                        </Item>
                    </Toolbar>
                </div>
                <FullPanel>
                    <ScrollView width={"100%"}>
                        <div className="wxconfig">
                            {imageKeys.map(k => (
                                <div key={k.value}>
                                    <h4>{k.text}</h4>
                                    {imageMap[k.value] && imageMap[k.value].map((img, index) => (
                                        <div className="wxconfig-image" key={index}>
                                            <img src={`${baseApiUrl}/api/file?name=${img}`} alt="" onClick={() => this.showImagePopup(k.value, index)} />
                                            <div className='wxconfig-image-close'>
                                                <i className="bi bi-x-square-fill" onClick={() => this.removeImage(k.value, index)}></i>
                                            </div>
                                        </div>
                                    ))}
                                    {!imageMap[k.value]?.length && (<p>尚未进行配置</p>)}
                                    <div><Button icon='add' type='success' text="添加" onClick={() => this.showImagePopup(k.value)} /></div>
                                    <hr />
                                </div>
                            ))}
                            <h4>消息提醒设置（设置岗位）</h4>
                            <div className="text-warning">默认发送平台内消息，如需要公众号消息，员工需要在小程序上绑定账号。除开户申请外，其它提醒发送时均按照供应站进行过滤</div>
                            <div className="wxconfig-message-container">
                                {dataSources.messageTypes.map(t => (
                                    <div key={t.value}>
                                        <label>{t.text}</label>
                                        <TagBox 
                                            dataSource={posts} 
                                            displayExpr="text" 
                                            valueExpr="value"
                                            value={messageMap[t.value]}
                                            onValueChanged={(e) => this.setMessage(e.value, t.value)} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </ScrollView>
                </FullPanel>
                <ImagePopup ref={this.imageRef} onSaving={this.setImage} />
            </ContentBlock>
        )
    }

}

const imageKeys: ListItem[] = [
    { value: 'PriceImage', text: '价格公示' },
    { value: 'CorpImage', text: '公司简介' },
    { value: 'GasQuality', text: '燃气质量' },
    { value: 'SecurityImage', text: '安全使用须知' }
];