import { IColumnProps, IDataGridOptions, MasterDetail } from "devextreme-react/data-grid";
import React from "react";
import { dangerService } from "../../api";
import { UnfixedDangerItemModel, UnfixedDangerModel } from "../../models";
import { StdGrid } from "../grid";

export interface UnfixedDangerProps {
    id?: number;
}

interface UnfixedDangerState {
    dangers: UnfixedDangerModel[];
}

export default class UnfixedDanger extends React.Component<UnfixedDangerProps, UnfixedDangerState> {
    moduleName = "Danger";

    gridOptions: IDataGridOptions = {
        columnAutoWidth: true,
        scrolling: { showScrollbar: "never" },
        paging: { pageSize: 10 }
    };

    columns: IColumnProps[] = [
        { dataField: "checker", caption: "检查人" },
        { dataField: "checkTime", dataType: "date", caption: "时间" },
        { dataField: "expirationDate", dataType: "date", caption: "整改期限" }
    ];
    
    constructor(props: Readonly<UnfixedDangerProps>) {
        super(props);

        this.state = {
            dangers: []
        };

        this.renderItems = this.renderItems.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<UnfixedDangerProps>): void {
        if (this.props.id && this.props.id !== prevProps.id) {
            this.loadData();
        } else if (!this.props.id && prevProps.id) {
            this.setState({ dangers: [] });
        }
    }

    async loadData() {
        const data = await dangerService.getUnfixedByCustomerId(this.props.id!);
        this.setState({ dangers: data });
    }

    render () {
        return (
            <StdGrid
                moduleName={this.moduleName}
                dataSource={this.state.dangers}
                columns={this.columns}
                options={this.gridOptions}
                autoHeight={true}>
                <MasterDetail enabled={true} autoExpandAll={true} render={this.renderItems} />
            </StdGrid>
        )
    }

    renderItems(props: any) {
        const items: UnfixedDangerItemModel[] = props.data.items;

        return (
            <div>
                {items.map(i => (<div key={i.itemName}>{i.itemName}</div>))}
            </div>
        )
    }
}