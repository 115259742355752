import { IColumnProps } from "devextreme-react/data-grid";
import { cpCheckerStore } from "../../api";
import { enums } from "../../base";
import CpDataSourcePage from "./cp-data-source";

export default class CpCheckerPage extends CpDataSourcePage {
    type = enums.cpDataSourceTypes.checker;
    caption = "条码打印-检验单位";
    store = cpCheckerStore;

    columns: IColumnProps[] = [
        { 
            dataField: "createdAt",
            formItem: { visible: false },
            dataType: 'datetime' 
        },
        { 
            dataField: "updatedAt", 
            formItem: { visible: false },
            dataType: 'datetime' 
        },
        { dataField: "text" },
        { 
            dataField: "extra",
            caption: '前缀',
            formItem: { helpText: '没有前缀该值留空' }
        }
    ]
}