import { createStoreFromUrl, http, MessageBool } from "../base";
import { BriefOrder, OrderEditModel } from "../models";

class OrderService {
    apiPath = "/api/order";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id', true);
    }

    searchByCustomerId(customerId: number) {
        return http.getData<BriefOrder[]>(`${this.apiPath}/bycustomer/${customerId}`);
    }

    checkPhoto(customerId: number) {
        return http.getData<string[]>(`${this.apiPath}/checkphoto?customerId=${customerId}`);
    }

    create(order: OrderEditModel) {
        return http.postData<MessageBool>(this.apiPath, order);
    }

    update(order: OrderEditModel) {
        return http.putData<MessageBool>(this.apiPath, order);
    }

    assignOrganization(id: number, organizationId: number) {
        return http.putData<MessageBool>(
            `${this.apiPath}/assignorg/${id}?organizationId=${organizationId}`, '');
    }

    assignDeliver(id: number, deliverId: string) {
        return http.putData<MessageBool>(
            `${this.apiPath}/assigndeliver/${id}?deliverId=${deliverId}`, '');
    }
    
    setPaid(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/setpaid/${id}`, '');
    }

    discard(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/discard/${id}`, '');
    }

    restore(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/restore/${id}`, '');
    }
}

export const orderService = new OrderService();
export const orderStore = orderService.getDxData();