import { Popup, Form } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import { ValueChangedEvent } from "devextreme/ui/text_box";
import React from "react";
import { bottleFillingService, fillingCheckTypeService, organizationService } from "../../api";
import { fillingRecheckService } from "../../api/filling-recheck.service";
import { alertError, dataSources, enums, modelClone, ModuleConfig } from "../../base";
import { OrgTreeDropDown, PopupContentBox, RefPopup, StdForm } from "../../components";
import { FillingCheckTypeRef, FillingRecheckModel, OrganizationRef } from "../../models";

export interface FillingRecheckFormProps {
    onSaved: () => void;
}

interface FillingRecheckFormState {
    types: FillingCheckTypeRef[];
    orgs: OrganizationRef[];
    items: IItemProps[];
    model: FillingRecheckModel;
}

export default class FillingRecheckForm 
    extends React.Component<FillingRecheckFormProps, FillingRecheckFormState> {
    moduleName: ModuleConfig = {
        moduleName: "FillingRecheck",
        children: {
            "bottle": "Bottle"
        }
    };

    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();

    constructor(props: Readonly<FillingRecheckFormProps>) {
        super(props);

        this.state = {
            types: [],
            orgs: [],
            items: [
                { 
                    dataField: "barcode", 
                    validationRules: [{ type: "required", message: "气瓶条码不可为空" }],
                    editorOptions: {
                        onValueChanged: async (e: ValueChangedEvent) => {
                            if (e.value) {
                                this.state.model.organizationId = await bottleFillingService.findOrganizationid(e.value);
                                this.setState({ });
                            }
                        }
                    }
                },
                { 
                    dataField: "organizationId",
                    render: () => (
                        <OrgTreeDropDown
                            organizationId={this.state.model.organizationId}
                            organizations={this.state.orgs}
                            onValueChanged={(organizationId) => {
                                this.state.model.organizationId = organizationId;
                                this.setState({});
                            }} />
                    )
                },
                { dataField: "checkTime", editorType: 'dxDateBox', editorOptions: { type: "datetime" } },
                { dataField: "checker" },
                {
                    dataField: "result",
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: dataSources.qualifiedOrNot, displayExpr: 'text', valueExpr: 'value' },
                    validationRules: [boolValidator]
                },
                // { dataField: "reason" },
                { dataField: "note" }
            ],
            model: { bottle: {} }
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }
    
    async loadData() {
        let types: FillingCheckTypeRef[] = [], orgs: OrganizationRef[] = [];

        await Promise.all([
            (async () => orgs = await organizationService.getRefs())(),
            (async() => types = await fillingCheckTypeService.getRefs(enums.fillingCheckTypes.recheck))()
        ])

        const items: IItemProps[] = [
            ...this.state.items.slice(0, 3),
            ...types.map(t => ({
                dataField: `item_${t.itemName}`,
                label: { text: t.itemName },
                editorType: 'dxSelectBox',
                editorOptions: { 
                    dataSource: t.displayType === enums.fillingCheckTypeValues.trueFalse ? 
                        dataSources.trueFalseOfPass : dataSources.qualifiedOrNot,
                    displayExpr: 'text',
                    valueExpr: 'value'
                },
                validationRules: [boolValidator]
            })),
            ...this.state.items.slice(3)
        ]

        this.setState({ 
            types: types, 
            orgs: orgs,
            items: items
        });
    }

    show(model: FillingRecheckModel) {
        this.popupRef.current?.instance.show();

        this.setState({ model: this.setDefault(model) });        
    }

    setDefault(model: FillingRecheckModel) {
        model.checkTime = model.checkTime || new Date();
        const recheck: any = modelClone(model);

        for (const type of this.state.types) {
            recheck[`item_${type.itemName}`] = type.defaultValue;
        }

        for (const item of model.items || []) {
            recheck[`item_${item.itemName}`] = item.pass;
        }

        return recheck as FillingRecheckModel;
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const model = this.state.model;
            model.items = model.items || [];
            const modelAny: any = model;

            for (const type of this.state.types) {
                const pass = modelAny[`item_${type.itemName}`];
                const item = this.state.model.items?.find(i => i.itemName === type.itemName);

                if (item) {
                    item.pass = pass;
                } else {
                    model.items.push({
                        recheckId: model.id,
                        itemName: type.itemName,
                        pass: pass                        
                    });
                }
            }

            const res = model.id ?
                await fillingRecheckService.update(model) :
                await fillingRecheckService.create(model);

            if (res.succeeded) {
                this.hide();
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup 
                popupRef={this.popupRef}
                title={"编辑"}
                width={800}
                height={480}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm 
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        formData={this.state.model} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}

const boolValidator = {
    type: "custom",
    message: "请选择",
    validationCallback: (e: any) => {
        return typeof (e.value) === "boolean";
    }
}
