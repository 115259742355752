import React from "react";
import { Form, Popup } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import { customerComplainService, employeeService, organizationService } from "../../api";
import { alertError, alertWarning, modelClone, notifySuccess, StringMap } from "../../base";
import { CustomerPopup, PopupContentBox, RefPopup, StdForm, setItemDataSource, createPopupField, OrgTreeDropDown } from "../../components";
import { CustomerComplainModel, EmployeeRef, OrganizationRef } from "../../models";

interface ComplainWithName extends CustomerComplainModel {
    _customerName?: string;
}

export interface CustomerComplainFormProps {
    onSaved: () => void;
}

interface CustomerComplainFormState {
    items: IItemProps[];
    organizations: OrganizationRef[];
    allDelivers: EmployeeRef[];
    delivers: EmployeeRef[];
    model: ComplainWithName;
}

export default class CustomerComplainForm
    extends React.Component<CustomerComplainFormProps, CustomerComplainFormState> {
    moduleName = "CustomerComplain";
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    customerPopupRef = React.createRef<CustomerPopup>();
    nameMap: StringMap = { "_customerName": "customerId" };
    
    constructor(props: Readonly<CustomerComplainFormProps>) {
        super(props);

        this.state = {
            organizations: [],
            allDelivers: [],
            delivers: [],
            model: {},
            items: [
                {
                    dataField: 'organizationId',
                    render: () => {
                        return (
                            <OrgTreeDropDown
                                organizationId={this.state.model.organizationId}
                                organizations={this.state.organizations}
                                onValueChanged={(organizationId) => {
                                    this.state.model.organizationId = organizationId;
                                    this.state.model.organizationName = 
                                        this.state.organizations.find(o => o.id === organizationId)?.name;

                                    const delivers = this.state.allDelivers.filter(d => d.organizationId === organizationId);
                                    setItemDataSource(this.state.items, "deliverId", delivers);

                                    this.setState({ delivers: delivers });
                                }} />
                        )
                    }
                },
                {
                    dataField: "deliverId",
                    editorType: "dxSelectBox",
                    editorOptions: { 
                        dataSource: [], 
                        displayExpr: 'name', 
                        valueExpr: 'id',
                        onValueChanged: (e: any) => {
                            this.state.model.deliverName = 
                                this.state.delivers.find(d => d.id === this.state.model.deliverId)?.name;

                            this.setState({});
                        }
                    },
                },
                createPopupField("_customerName", this.customerPopupRef),
                { dataField: "customerName" },
                { dataField: "phoneNumber" },
                { dataField: "complainTime", editorOptions: { type: "datetime" } },
                { dataField: "complainType" },
                { itemType: "empty" },
                { 
                    dataField: "complainContent",
                    editorType: 'dxTextArea',
                    colSpan: 2,
                    editorOptions: { height: 80 }
                },
                { 
                    dataField: "result",
                    editorType: 'dxTextArea',
                    colSpan: 2,
                    editorOptions: { height: 80 }
                }
            ]
        };

        this.onCustomerSelected = this.onCustomerSelected.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        this.loadDataSources();
    }

    async loadDataSources() {
        let orgs: OrganizationRef[] = [], delivers: EmployeeRef[] = [];

        await Promise.all([
            (async () => orgs = await organizationService.getRefs())(),
            (async () => delivers = await employeeService.getDelivers())()
        ]);
        
        this.setState({ 
            organizations: orgs,
            allDelivers: delivers
        });
    }

    show(customerComplain: ComplainWithName) {
        this.popupRef.current?.instance.show();

        customerComplain = modelClone(customerComplain);
        customerComplain._customerName = customerComplain.customer!.name ?
            `${customerComplain.customer!.name}（${customerComplain.customer!.gasCode}）` : '';

        this.setState({ model: customerComplain });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    onCustomerSelected(customer: any) {
        const model = {
            ...this.state.model,
            customerId: customer.id,
            _customerName: `${customer.name}（${customer.gasCode}）`,
            customerName: customer.name,
            phoneNumber: customer.phoneNumber
        };

        this.setState({ model: model });
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (!isValid?.isValid) {
            return;
        } else if (!this.state.model.customerId) {
            alertWarning("请选择客户！");
            return;
        }

        const model = this.state.model;
        const res = model.id ?
            await customerComplainService.update(model) :
            await customerComplainService.create(model);

        if (res.succeeded) {
            notifySuccess("已成功保存");
            this.hide();
            this.props.onSaved();
        } else {
            alertError(res.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"编辑"}
                width={800}
                height={550}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        nameMap={this.nameMap}
                        formData={this.state.model} />
                </PopupContentBox>
                <CustomerPopup ref={this.customerPopupRef} onCustomerSelected={this.onCustomerSelected} />
            </RefPopup>
        )
    }
}