import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/tree-list";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import React from "react";
import { areaService, customerRequestStore, customerTypeService } from "../../api";
import { dataSources, ModuleConfig, StringMap } from "../../base";
import { createGridOptions, remoteReadOnlyGridOptions, setColumnDataSource, StdGrid } from "../../components";
import { AreaRef, CustomerRequestModel, CustomerTypeRef } from "../../models";

export interface CustomerRequestGridProps {
    onButtonClick: (model: CustomerRequestModel, name: string) => void;
    onModelSelected: (model: CustomerRequestModel) => void;
}

interface CustomerRequestGridState {
    columns: IColumnProps[];
    counties: AreaRef[];
    streets: AreaRef[];
    customerTypes: CustomerTypeRef[];
}

export default class CustomerRequestGrid
    extends React.Component<CustomerRequestGridProps, CustomerRequestGridState> {
    moduleName: ModuleConfig = {
        moduleName: "CustomerRequest",
        children: {
            "organization": "Organization"
        }
    };

    objectName = "CustomerRequest";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("customerRequests", this.gridRef, remoteReadOnlyGridOptions,
        (items: any[]) => {
            // state清空会同时清空filter，需要重新绑定旧的
            items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
            return items;
        });

    nameMap: StringMap = { "organization.name": "organizationId" };

    constructor(props: Readonly<CustomerRequestGridProps>) {
        super(props);

        this.state = {
            counties: [],
            streets: [],
            customerTypes: [],
            columns: [
                { dataField: 'organization.name' },
                { dataField: 'name' },
                {
                    dataField: 'typeId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: 'phoneNumber' },
                {
                    dataField: 'areaId',
                    allowFiltering: false,
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                {
                    dataField: 'streetId',
                    allowFiltering: false,
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: 'address' },
                { dataField: 'personInCharge' },
                { dataField: 'idNumber' },
                { dataField: 'createdAt', dataType: 'datetime' },
                { 
                    dataField: 'status',
                    lookup: { dataSource: dataSources.customerRequestStatus, displayExpr: 'text', valueExpr: 'value' }
                },
                { dataField: 'completedAt', dataType: 'datetime' }
            ]
        }

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = (e) => this.props.onButtonClick(e.data, "customer");
    }

    componentDidMount() {
        this.loadDataSources();
    }

    async loadDataSources() {
        let counties: AreaRef[] = [], streets: AreaRef[] = [], customerTypes: CustomerTypeRef[] = [];

        await Promise.all([
            (async () => counties = await areaService.getCounties())(),
            (async () => streets = await areaService.getStreets())(),
            (async () => customerTypes = await customerTypeService.getRefs())()
        ]);

        setColumnDataSource(this.state.columns, "areaId", counties);
        setColumnDataSource(this.state.columns, "streetId", streets);
        setColumnDataSource(this.state.columns, "typeId", customerTypes);

        this.setState({
            counties: counties,
            streets: streets,
            customerTypes: customerTypes
        });
    }
    
    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});    // 为避免混乱，多选不能编辑
        }
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={customerRequestStore}
                options={this.gridOptions}
                columns={this.state.columns}
                columnNameMap={this.nameMap}>

            </StdGrid>
        )
    }
}