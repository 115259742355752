import "./call-center.scss";
import React from "react";
import Popup from "devextreme-react/popup";
import { Accordion, LoadPanel, ScrollView } from "devextreme-react";
import NewOrder from "./new-order";
import CustomerDetail from "./customer-detail";
import CallHistory from "./call-history";
import CustomerBottle from "./customer-bottle";
import CustomerPhoto from "./customer-photo";
import CustomerDebt from "./customer-debt";
import OrderHistory from "./order-history";
import SecurityCheckHistory from "./security-check-history";
import { customerService } from "../../api";
import { SelectionChangedEvent } from "devextreme/ui/accordion";
import { authorizationService, confirm2, enums, globalPopupManager, globalPopupNames, phoneRegex } from "../../base";
import { ScreenSize, ScreenSizeContext } from "../../hooks";
import OrderPhoto from "./order-photo";
import SecurityCheckPhoto from "./security-check-photo";
import UnfixedDanger from "./unfixed-danger";

interface SidebarItem {
    id: string;
    title: string;
    component: React.ComponentType<any>;
    collapsed?: boolean;
    objectName?: string;
}

const _sidebarRight: SidebarItem[] = [
    { id: "securityCheckHistory", title: "安检记录", component: SecurityCheckHistory, objectName: "SecurityCheck" },
    { id: "callHistory", title: "来电记录", component: CallHistory },
    { id: "customerBottle", title: "在用气瓶", component: CustomerBottle, objectName: "BottlePosition" },
    { id: "customerDebt", title: "押金信息", component: CustomerDebt, objectName: "BottleDebt" },
    { id: "unfixedDanger", title: "未整改隐患", component: UnfixedDanger, objectName: "Danger" },
    { id: "customerPhoto", title: "证件照片", component: CustomerPhoto },
    { id: "orderPhoto", title: "最新试漏照片", component: OrderPhoto },
    { id: "securityCheckPhoto", title: "最新安检照片", component: SecurityCheckPhoto }
];

export interface CallCenterKey {
    id?: number;
    phone?: string;
}

interface CallCenterPopupState {
    id?: number;
    customer: any;
    rightIds: string[];
    orderSaved: boolean;
    phone?: string;
    loading: boolean;
    visible: boolean;
    keyQueue: CallCenterKey[];
    sidebarRight: SidebarItem[];
}

export default class CallCenterPopup extends React.Component<any, CallCenterPopupState> {
    popupRef = React.createRef<Popup>();

    constructor(props: any) {
        super(props);

        this.state = {
            id: undefined,
            customer: customerService.newCustomer(),
            sidebarRight: [],
            rightIds: [],
            orderSaved: false,
            loading: false,
            visible: false,
            keyQueue: []
        }

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.onHidden = this.onHidden.bind(this);
        this.onCustomerChanged = this.onCustomerChanged.bind(this);
        this.onOrderSaved = this.onOrderSaved.bind(this);
        this.onRightAccordionChanged = this.onRightAccordionChanged.bind(this);
        this.next = this.next.bind(this);

        (window as any)["_callcenter"] = this.show;
    }

    async componentDidMount() {
        globalPopupManager.subscribe(globalPopupNames.callCenter, this.show);

        const items: SidebarItem[] = [];

        for (const item of _sidebarRight) {
            if (!item.objectName || await authorizationService.authorize(item.objectName!, enums.stdActions.read)) {
                items.push(item);
            }
        }

        // Accordion同时设置数据源和选中项会挂，需要分两次设置
        this.setState({ sidebarRight: items }, 
            () => this.setState({ rightIds: items.filter(i => !i.collapsed).map(i => i.id)}));
    }

    show(key: CallCenterKey) {
        if (!this.state.visible) {
            this.popupRef.current?.instance.show();

            this.setState({
                loading: true,
                visible: true,
                phone: key.phone && key.phone.match(phoneRegex) ? key.phone : undefined
            });


            if (key.id) {
                this.getCustomer(key.id);
            } else {
                this.searchCustomerByPhone(key.phone!);
            }
        } else {
            // 重复调用不处理
            if (key.phone !== this.state.phone && !this.state.keyQueue.find(k => k.phone === key.phone)) {
                const keyQueue = [...this.state.keyQueue, key];
                this.setState({ keyQueue: keyQueue });
            }
        }
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    onHidden() {
        this.setState({
            id: undefined,
            visible: false,
            customer: customerService.newCustomer()
        });

        // 如果有等待队列，关闭时再次打开
        if (this.state.keyQueue.length > 0) {
            const key = this.state.keyQueue.shift()!;
            setTimeout(() => this.show(key), 0);
        }
    }

    async getCustomer(id: number) {
        const customer = await customerService.get(id) || customerService.newCustomer();

        this.setState({
            id: customer.id,
            customer: customer,
            orderSaved: false,
            loading: false
        });
    }

    async searchCustomerByPhone(phone: string) {
        const customer = await customerService.find(phone) || customerService.newCustomer();

        this.setState({
            id: customer.id,
            customer: customer,
            orderSaved: false,
            phone: this.state.phone === customer.gasCode ? undefined : this.state.phone,
            loading: false
        });
    }

    onRightAccordionChanged(e: SelectionChangedEvent) {
        const ids = this.mergeIds(e, this.state.rightIds)
        this.setState({ rightIds: ids });
    }

    mergeIds(e: SelectionChangedEvent, ids: string[]) {
        ids = [...ids];

        if (e.addedItems) {
            ids.push(...e.addedItems.map(i => i.id));
        }
        if (e.removedItems) {
            ids = ids.filter(id => !e.removedItems.find(ri => ri.id === id));
        }

        return ids;
    }

    onCustomerChanged(e: { id?: number; phone?: string }) {
        e.id ? this.getCustomer(e.id) : this.searchCustomerByPhone(e.phone!);
    }

    onOrderSaved() {
        this.setState({ orderSaved: true });
        globalPopupManager.show(globalPopupNames.orderGrid, undefined);
    }

    async next() {
        if (!this.state.keyQueue.length) {
            return;
        }

        if (await confirm2("确定要切换到下一个客户吗？请确保所有工作都已被保存")) {
            const key = this.state.keyQueue.shift()!;
            
            this.setState({
                loading: true,
                visible: true,
                phone: key.phone && key.phone.match(phoneRegex) ? key.phone : undefined
            });

            this.onCustomerChanged({ phone: key.phone });
        }
    }

    render() {
        const { loading } = this.state;

        return (
            <Popup
                ref={this.popupRef}
                title={"呼叫中心"}
                resizeEnabled={true}
                showCloseButton={true}
                defaultWidth={"90%"}
                defaultHeight={"95%"}
                onHidden={this.onHidden}>
                <LoadPanel visible={loading} />
                <ScreenSizeContext.Consumer>
                    {context => (
                        (context.isSmall || context.isXSmall) ?
                            <ScrollView height="100%" scrollByContent={true}>
                                {this.renderMain(context)}
                            </ScrollView> :
                            this.renderMain(context)
                    )}
                </ScreenSizeContext.Consumer>
            </Popup>
        );
    }

    renderMain(context: ScreenSize) {
        const { loading, keyQueue } = this.state;

        return (
            <div className={"call-center"} style={{ display: loading ? "none" : "" }}>
                <div className="cc-col-left">
                    {keyQueue.length > 0 && (
                        <div className="key-queue" onClick={this.next}>
                            等待队列：{keyQueue.map(k => (<span key={k.phone}>{k.phone}</span>))}
                        </div>
                    )}
                    {
                        (context.isSmall || context.isXSmall) ?
                            this.renderLeft() :
                            <ScrollView height={"100%"} scrollByContent={true} showScrollbar="always">
                                {this.renderLeft()}
                            </ScrollView>
                    }
                </div>
                <div className={"cc-col-main"}>
                    {
                        (!context.isMedium) ?
                            this.renderCenter(context) :
                            <ScrollView height={"100%"} scrollByContent={true} showScrollbar="always">
                                {this.renderCenter(context)}
                            </ScrollView>
                    }
                </div>
            </div>
        );
    }

    renderLeft() {
        const { customer } = this.state;

        return (
            <div className={"cc-customer"}>
                <CustomerDetail customer={customer} onCustomerChanged={this.onCustomerChanged} />
            </div>
        );
    }

    renderCenter(context: ScreenSize) {
        return (
            <React.Fragment>
                <div className={"cc-order"}>
                    {
                        (context.isSmall || context.isXSmall) ? 
                        this.renderOrder() : 
                        <ScrollView height={"100%"} scrollByContent={true} showScrollbar="always">
                            {this.renderOrder()}
                        </ScrollView>
                    }
                </div>
                <div className={"cc-col-sidebar"}>
                    {
                        (context.isSmall || context.isXSmall) ?
                            this.renderRight() :
                            <ScrollView height="100%" scrollByContent={true} showScrollbar="always">
                                {this.renderRight()}
                            </ScrollView>
                    }
                </div>
            </React.Fragment>
        );
    }

    renderOrder() {
        const { id, customer, orderSaved } = this.state;

        return (
            <React.Fragment>
                <div className="cc-order-row">
                    <NewOrder id={id} customer={customer} onOrderSaved={this.onOrderSaved} />
                </div>
                <div className="cc-order-row">
                    <OrderHistory id={id} orderSaved={orderSaved} />
                </div>                
            </React.Fragment>
        )
    }

    renderRight() {
        return (
            <Accordion
                keyExpr={"id"}
                dataSource={this.state.sidebarRight}
                multiple={true}
                itemTitleRender={this.renderAccordionTitle}
                itemRender={(dataItem: SidebarItem) => this.renderAccordionItem(dataItem)}
                selectedItemKeys={this.state.rightIds}
                onSelectionChanged={this.onRightAccordionChanged} />
        );
    }

    renderAccordionTitle(dataItem: SidebarItem) {
        return (
            <span>{dataItem.title}</span>
        );
    }

    renderAccordionItem(dataItem: SidebarItem) {
        return React.createElement(dataItem.component,
            { id: this.state.id, customer: this.state.customer, phone: this.state.phone });
    }
}