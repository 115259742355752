export const enums = {
    signalTypes: {
        changePassword: 'ChangePassword',
        concurrencyConflict: 'ConcurrencyConflict',
        longTimeToken: 'LongTimeToken'
    },
    organizationTypes: {
        site: 'Site',
        corp: 'Corp',
        special: 'Special'
    },
    permissionValues: {
        personal: 1,
        relation: 5,
        organization: 10,
        all: 100
    },
    permissionTypes: {
        open: 'Open',
        main: 'Main',
        affiliate: 'Affiliate',
        create: 'Create'
    },
    stdActions: {
        read: 'Read',
        create: 'Create',
        update: 'Update',
        delete: 'Delete',
        export: 'Export'
    },
    passwordRules: {
        mixed: 'Mixed',
        length: 'Length',
        rule: 'Rule',
        none: 'None'
    },
    userStatus: {
        active: 'Active',
        inactive: 'Inactive',
        deleted: 'Deleted'
    },
    customerStatus: {
        active: 'Active',
        inactive: 'Inactive'
    },
    installTypes: {
        deliver: 'Deliver',
        self: 'Self'
    },
    payment: {
        unpaid: false,
        paid: true
    },
    paidStatus: {
        unpaid: "Unpaid",
        paid: 'Paid',
        paidByCash: "PaidByCash",
        paidByWeChatPay: "PaidByWeChatPay",
        paidByAlipay: "PaidByAlipay"
    },
    orderStatus: {
        created: 'Created',
        assigned: 'Assigned',
        delivering: 'Delivering',
        delivery: 'Delivery',
        paid: 'Paid',
        discard: 'Discard'
    },
    bottleOperations: {
        filling: 'Filling',
        allot: 'Allot',
        stockOut: 'StockOut',
        deliver: 'Deliver',
        recycle: 'Recycle',
        emptyIn: 'EmptyIn',
        emptyBack: 'EmptyBack',
        selfPickUp: 'SelfPickUp',
        sendBack: 'SendBack',
        customerBack: 'CustomerBack'
    },
    specialBottleOperations: {
        lostRecycle: 'LostRecycle',
        lostEmptyIn: 'LostEmptyIn',
        discardRecycle: 'DiscardRecycle'
    },
    bottlePositions: {
        filling: 'Filling',
        site: 'Site',
        deliveryman: 'Deliveryman',
        customer: 'Customer'
    },
    vehicleUserPosts: {
        driver: 'Driver',
        supercargo: 'Supercargo'
    },
    debtTypes: {
        debt: 'Debt',
        deposit: 'Deposit'
    },
    productTypes: {
        bottle: 'Bottle',
        gas: 'Gas',
        accessory: 'Accessory'
    },
    deliverTypes: {
        deliver: 'Deliver',
        selfPickUp: 'SelfPickUp'
    },
    employeePosts: {
        deliver: '送气工',
        securityInspector: '安检员',
        warehouseKeeper: '仓管员'
    },
    dataSourceTypes: {
        customPosts: 'CustomPosts',
        addressTypes: 'AddressTypes',
        troubleTypes: 'TroubleTypes',
        troubleHandlings: 'TroubleHandlings',
        troubleResults: 'TroubleResults',
        exitReasons: 'ExitReasons',
        debtReasons: 'DebtReasons',
        fillingPrecheckReasons: 'FillingPrecheckReasons',
        dangerIMFixStatus: 'DangerIMFixStatus',
        trainingTypes: 'TrainingTypes',
        trainingMethods: 'TrainingMethods',
        trainingFileTypes: 'TrainingFileTypes'
    },
    announcementTypes: {
        auto: 'Auto',
        manual: 'Manual'
    },
    labelDataTypes: {
        customerType: 'CustomerType',
        customer: 'Customer',
        product: 'Product'
    },
    troubleshootingStatus: {
        created: 'Created',
        assigned: 'Assigned',
        complete: 'Complete'
    },
    siteCheckType: {
        issue: 'Issue',
        selfCheck: 'SelfCheck'
    },
    vehiclePosts: {
        driver: 'Driver',
        supercargo: 'Supercargo',
        all: 'All'
    },
    vehicleTypes: {
        truck: 'Truck',
        motorbike: 'Motorbike',
        ebike: 'EBike',
        tricycle: 'Tricycle'
    },
    exceptionSettingValue: {
        on: 'On',
        off: 'Off',
        log: 'Log'
    },
    fillingUserPosts: {
        filling: 'Filling',
        prechecker: 'Prechecker',
        checker: 'Checker'
    },
    dangerAuditStatus: {
        submitted: 'Submitted',
        approved: 'Approved',
        rejected: 'Rejected'
    },
    platforms: {
        web: 'Web',
        app: 'App',
        wx: 'WX'
    },
    bugStatus: {
        opened: 'Opened',
        fixed: 'Fixed',
        rejected: 'Rejected',
        closed: 'Closed'
    },
    bugPriority: {
        urgent: 4,
        important: 3,
        normal: 2,
        low: 1
    },
    alerterStatus: {
        installed: 'Installed',
        uninstalled: 'Uninstalled'
    },
    dangerLevels: {
        level1: 1,
        level2: 2
    },
    fillingCheckTypes: {
        precheck: "Precheck",
        recheck: "Recheck"
    },
    fillingCheckTypeValues: {
        trueFalse: "TrueFalse",
        qualifiedOrNot: "QualifiedOrNot"
    },
    dangerFixStatus: {
        fixed: '已整改',
        unfixed: '未整改',
        fail: '无法整改，停止供气'
    },
    dangerTypes: {
        securityCheck: 'SecurityCheck',
        orderCheck: 'OrderCheck',
        issue: 'Issue'
    },
    customerRequestStatus: {
        requested: 'Requested',
        completed: 'Completed',
        discard: 'Discard'
    },
    recycleRequestStatus: {
        requested: 'Requested',
        assigned: 'Assigned',
        completed: 'Completed',
        discard: 'Discard'
    },
    messageTypes: {
        customerRequest: 'CustomerRequest',
        newOrder: 'NewOrder',
        recycleRequest: 'RecycleRequest',
        customerAssigned: 'CustomerAssigned'
    },
    keyTypes: {
        startsWith: 'StartsWith',
        endsWith: 'EndsWith',
        contains: 'Contains'
    },
    stockOutTypes: {
        stockOutFlow: 'StockOutFlow',
        orderComplete: 'OrderComplete',
        orderPaid: 'OrderPaid'
    },
    stockDirection: {
        in: 'In',
        out: 'Out'
    },
    cpBatchTypes: {
        new: 'New',
        check: 'Check',
        checkBack: 'CheckBack',
        specialCheckBack: 'SpecialCheckBack',
        scrap: 'Scrap'
    },
    cpDataSourceTypes: {
        vendor: 'Vendor',
        organization: 'Organization',
        checker: 'Checker',
        bottleType: 'BottleType'
    },
    gasTypes: {
        gas: 'Gas',
        propane: 'Propane'
    },
    cpCheckBackStatus: {
        normal: 'Normal',
        exception: 'Exception',    
    },
    bottleFlowFlags: {
        patch: 'Patch',
        exception: 'Exception',
        error: 'Error'
    }
}

export const dataSources = {
    organizationTypes: [
        { value: enums.organizationTypes.site, text: "供应站" },
        { value: enums.organizationTypes.corp, text: "公司" },
        { value: enums.organizationTypes.special, text: "充装特殊" }
    ],
    permissionValues: [
        { value: enums.permissionValues.personal, text: '个人数据' },
        { value: enums.permissionValues.relation, text: '个人及共享数据' },
        { value: enums.permissionValues.organization, text: '组织及下级组织' },
        { value: enums.permissionValues.all, text: '所有数据' }
    ],
    openPermissionValues: [
        { value: enums.permissionValues.all, text: '所有数据' }
    ],
    affiliatePermissionValues: [
        { value: enums.permissionValues.all, text: '取决于依赖项：{0}' }
    ],
    createPermissionValues: [
        { value: enums.permissionValues.all, text: '有权限' }
    ],
    passwordRules: [
        { value: enums.passwordRules.mixed, text: '8个及以上字母、数字和符号的组合' },
        { value: enums.passwordRules.length, text: '8位及以上长度' },
        { value: enums.passwordRules.rule, text: '字母、数字和符号的组合' },
        { value: enums.passwordRules.none, text: '无要求' }
    ],
    userStatus: [
        { value: enums.userStatus.active, text: '在职' },
        { value: enums.userStatus.inactive, text: '离职' }
    ],
    customerStatus: [
        { value: enums.customerStatus.active, text: '正常' },
        { value: enums.customerStatus.inactive, text: '已退户' }
    ],
    installTypes: [
        { value: enums.installTypes.deliver, text: '送气工安装' },
        { value: enums.installTypes.self, text: '用户自行安装' }
    ],
    payment: [
        { value: enums.payment.unpaid, text: '未回款' },
        { value: enums.payment.paid, text: '已回款' }
    ],
    paidStatus: [
        { value: enums.paidStatus.unpaid, text: "气款未付，欠款" },
        { value: enums.paidStatus.paid, text: "气款已收取" },
        { value: enums.paidStatus.paidByCash, text: "气款已现金收取" },
        { value: enums.paidStatus.paidByWeChatPay, text: "气款已微信收取" },
        { value: enums.paidStatus.paidByAlipay, text: "气款已支付宝收取" }
    ],
    onlinePayTypes: [
        { value: enums.paidStatus.paidByWeChatPay, text: "微信支付" },
    ],
    orderStatus: [
        { value: enums.orderStatus.created, text: '已创建待分配' },
        { value: enums.orderStatus.assigned, text: '已分配待派工' },
        { value: enums.orderStatus.delivering, text: '配送中' },
        { value: enums.orderStatus.delivery, text: '已送达' },
        { value: enums.orderStatus.paid, text: '已回款' },
        { value: enums.orderStatus.discard, text: '已作废' }
    ],
    bottleOperations: [
        { value: enums.bottleOperations.filling, text: "气瓶充装" },
        { value: enums.bottleOperations.allot, text: "重瓶调拨入库" },
        { value: enums.bottleOperations.stockOut, text: "重瓶领出" },
        { value: enums.bottleOperations.deliver, text: "重瓶送达" },
        { value: enums.bottleOperations.recycle, text: "空瓶回收" },
        { value: enums.bottleOperations.emptyIn, text: "空瓶入库" },
        { value: enums.bottleOperations.emptyBack, text: "空瓶调拨出库" },
        { value: enums.bottleOperations.selfPickUp, text: "用户自提" },
        { value: enums.bottleOperations.sendBack, text: "重瓶退回" },
        { value: enums.bottleOperations.customerBack, text: "用户重瓶退回" }
    ],
    specialBottleOperations: [
        { value: enums.specialBottleOperations.lostRecycle, text: "空瓶回收-疑似流失瓶" },
        { value: enums.specialBottleOperations.lostEmptyIn, text: "空瓶入库-疑似流失瓶" },
        { value: enums.specialBottleOperations.discardRecycle, text: "空瓶回收-气瓶已报废" }
    ],
    bottlePositions: [
        { value: enums.bottlePositions.filling, text: "充装站" },
        { value: enums.bottlePositions.site, text: "供应站" },
        { value: enums.bottlePositions.deliveryman, text: "送气工" },
        { value: enums.bottlePositions.customer, text: "客户" }
    ],
    deptTypes: [
        { value: enums.debtTypes.debt, text: '欠瓶' },
        { value: enums.debtTypes.deposit, text: '押瓶' }
    ],
    productTypes: [
        { value: enums.productTypes.bottle, text: '燃气（瓶）' },
        { value: enums.productTypes.gas, text: '余气（KG）' },
        { value: enums.productTypes.accessory, text: '配件' }
    ],
    deliverTypes: [
        { value: enums.deliverTypes.deliver, text: "配送到家" },
        { value: enums.deliverTypes.selfPickUp, text: "上门自提" }
    ],
    employeePosts: [
        { value: enums.employeePosts.deliver, text: enums.employeePosts.deliver },
        { value: enums.employeePosts.securityInspector, text: enums.employeePosts.securityInspector },
        { value: enums.employeePosts.warehouseKeeper, text: enums.employeePosts.warehouseKeeper }
    ],
    dataSourceTypes: [
        { value: enums.dataSourceTypes.customPosts, text: '自定义职位' },
        { value: enums.dataSourceTypes.addressTypes, text: '地址类型' },
        { value: enums.dataSourceTypes.troubleTypes, text: '故障类型' },
        { value: enums.dataSourceTypes.troubleHandlings, text: '故障处理方法' },
        { value: enums.dataSourceTypes.troubleResults, text: '故障处理结果' },
        { value: enums.dataSourceTypes.exitReasons, text: '客户退户原因' },
        { value: enums.dataSourceTypes.debtReasons, text: '欠瓶原因' },
        { value: enums.dataSourceTypes.fillingPrecheckReasons, text: '充装检查不合格原因' },
        { value: enums.dataSourceTypes.dangerIMFixStatus, text: '隐患现场整改状态' },
        { value: enums.dataSourceTypes.trainingTypes, text: '培训类型' },
        { value: enums.dataSourceTypes.trainingMethods, text: '培训方式' },
        { value: enums.dataSourceTypes.trainingFileTypes, text: '培训文件类型' }
    ], 
    announcementTypes:[
        { value: enums.announcementTypes.auto, text: '自动标志已读' },
        { value: enums.announcementTypes.manual, text: '手动签收' }
    ],
    labelDataTypes: [
        { value: enums.labelDataTypes.customerType, text: '客户类型' },
        { value: enums.labelDataTypes.customer, text: '客户' },
        { value: enums.labelDataTypes.product, text: '产品' }
    ],
    troubleshootingStatus: [
        { value: enums.troubleshootingStatus.created, text: '已创建待分配' },
        { value: enums.troubleshootingStatus.assigned, text: '已派工' },
        { value: enums.troubleshootingStatus.complete, text: '已完成' }
    ],
    siteCheckTypes: [
        { value: enums.siteCheckType.issue, text: '公司检查' },
        { value: enums.siteCheckType.selfCheck, text: '站点自检' }
    ],
    vehiclePosts: [
        { value: enums.vehiclePosts.driver, text: '司机' },
        { value: enums.vehiclePosts.supercargo, text: '押运员' }
    ],
    vehicleTypes: [
        { value: enums.vehicleTypes.truck, text: '危货运输车' },
        { value: enums.vehicleTypes.motorbike, text: '摩托车' },
        { value: enums.vehicleTypes.ebike, text: '电动车' },
        { value: enums.vehicleTypes.tricycle, text: '三轮车' }
    ],
    exceptionSettingValues: [
        { value: enums.exceptionSettingValue.log, text: '仅记录日志' },
        { value: enums.exceptionSettingValue.on, text: '阻止操作' },
        { value: enums.exceptionSettingValue.off, text: '忽略' }
    ],
    otherExceptionSettingValues: [
        { value: enums.exceptionSettingValue.log, text: '仅记录日志' },
        { value: enums.exceptionSettingValue.off, text: '忽略' }
    ],
    fillingUserPosts: [
        { value: enums.fillingUserPosts.filling, text: '充装工' },
        { value: enums.fillingUserPosts.prechecker, text: '预检人员' },
        { value: enums.fillingUserPosts.checker, text: '复检人员' }
    ],
    dangerAuditStatus: [
        { value: enums.dangerAuditStatus.submitted, text: '未审核' },
        { value: enums.dangerAuditStatus.approved, text: '审核通过' },
        { value: enums.dangerAuditStatus.rejected, text: '审核不通过' }
    ],
    platforms: [
        { value: enums.platforms.web, text: 'WEB管理平台' },
        { value: enums.platforms.app, text: 'APP' },
        { value: enums.platforms.wx, text: "微信小程序" }
    ],
    bugStatus: [
        { value: enums.bugStatus.opened, text: '待处理' },
        { value: enums.bugStatus.fixed, text: '已修改' },
        { value: enums.bugStatus.rejected, text: '不处理' },
        { value: enums.bugStatus.closed, text: '已关闭' }
    ],
    bugPriority: [
        { value: enums.bugPriority.urgent, text: '紧急' },
        { value: enums.bugPriority.important, text: '高' },
        { value: enums.bugPriority.normal, text: '普通' },
        { value: enums.bugPriority.low, text: '低' }
    ],
    alerterStatus: [
        { value: enums.alerterStatus.installed, text: "已安装" },
        { value: enums.alerterStatus.uninstalled, text: "未安装" }
    ],
    dangerLevels: [
        { value: enums.dangerLevels.level1, text: "重大隐患" },
        { value: enums.dangerLevels.level2, text: "一般隐患" }
    ],
    fillingCheckTypes: [
        { value: enums.fillingCheckTypes.precheck, text: "充前检查" },
        { value: enums.fillingCheckTypes.recheck, text: "充后检查" }
    ],
    fillingCheckTypeValues: [
        { value: enums.fillingCheckTypeValues.trueFalse, text: "是/否" },
        { value: enums.fillingCheckTypeValues.qualifiedOrNot, text: "合格/不合格" }
    ],
    trueFalseList: [
        { value: true, text: "是" },
        { value: false, text: "否" }
    ],
    trueFalseOfPass: [
        { value: false, text: "是" },
        { value: true, text: "否" }
    ],
    qualifiedOrNot: [
        { value: true, text: "合格" },
        { value: false, text: "不合格" }
    ],
    customerRequestStatus: [
        { value: enums.customerRequestStatus.requested, text: '已提交' },
        { value: enums.customerRequestStatus.completed, text: '已处理' },
        { value: enums.customerRequestStatus.discard, text: '已作废' }
    ],
    recycleRequestStatus: [
        { value: enums.recycleRequestStatus.requested, text: '已提交' },
        { value: enums.recycleRequestStatus.assigned, text: '已派工' },
        { value: enums.recycleRequestStatus.completed, text: '已完成' },
        { value: enums.recycleRequestStatus.discard, text: '已作废' }
    ],
    messageTypes: [
        { value: enums.messageTypes.customerRequest, text: '开户申请' },
        { value: enums.messageTypes.newOrder, text: '新订单' },
        { value: enums.messageTypes.recycleRequest, text: '退瓶申请' },
        // { value: enums.messageTypes.customerAssigned, text: '新客户分配' }
    ],
    siteDangerLevels: [
        { value: enums.dangerLevels.level1, text: "重大隐患" },
        { value: enums.dangerLevels.level2, text: "一般隐患" }
    ],
    keyTypes: [
        { value: enums.keyTypes.contains, text: '包含关键字' },
        { value: enums.keyTypes.startsWith, text: '以关键字开头' },
        { value: enums.keyTypes.endsWith, text: '以关键字结尾' }
    ],
    stockOutTypes: [
        { value: enums.stockOutTypes.stockOutFlow, text: '重瓶出库' },
        { value: enums.stockOutTypes.orderComplete, text: '订单完成' },
        { value: enums.stockOutTypes.orderPaid, text: '订单回款' }
    ],
    cpBatchTypes: [
        { value: enums.cpBatchTypes.new, text: '新瓶' },
        { value: enums.cpBatchTypes.check, text: '送检' },
        { value: enums.cpBatchTypes.checkBack, text: '检回' },
        { value: enums.cpBatchTypes.specialCheckBack, text: '无批次检回' },
        { value: enums.cpBatchTypes.scrap, text: '报废瓶' }
    ],
    cpCheckBackStatus: [
        { value: enums.cpCheckBackStatus.normal, text: '已检回' },
        { value: enums.cpCheckBackStatus.exception, text: '异常瓶' }
    ],
    bottleFlowFlags: [
        { value: enums.bottleFlowFlags.patch, text: '补记录' },
        { value: enums.bottleFlowFlags.exception, text: '异常' },
        { value: enums.bottleFlowFlags.error, text: '错误' }
    ]
}

export const allBottleOperations = [
    ...dataSources.bottleOperations, 
    ...dataSources.specialBottleOperations
];