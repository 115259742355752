import { createStoreFromUrl } from "../base";

class EmployeeAppStatusService {
    apiPath = "/api/employeeappstatus";

    getDxData(organizationId?: number) {
        return createStoreFromUrl(
            `${this.apiPath}/dx` + (organizationId ? `?organizationId=${organizationId}` : ''), 'sessionId');
    }
}

export const employeeAppStatusService = new EmployeeAppStatusService();
export const employeeAppStatusStore = employeeAppStatusService.getDxData();
