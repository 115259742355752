import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";
import React from "react";
import { orderReportService } from "../../../api/reports";
import { ContentBlock, createGridOptions, StdGrid, stdReadOnlyGridOptions } from "../../../components";
import { OrderTrendReport, OrderTrendReportCondition } from "../../../models";
import AuthorizedComponent from "../../authorized-component";
import OrderTrendChart from "./order-trend-chart";
import OrderTrendToolbar from "./order-trend-toolbar";

interface OrderTrendReportPageState {
    data: OrderTrendReport[];
}

export default class OrderTrendReportPage
    extends AuthorizedComponent<any, OrderTrendReportPageState> {

    objectName = "OrderTrend";
    moduleName = "";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("customerTrendReport", this.gridRef, stdReadOnlyGridOptions);
    chartRef = React.createRef<OrderTrendChart>();

    columns: IColumnProps[] = [
        { dataField: 'time', caption: '时间' },
        { dataField: 'count', caption: '订单数' },
        { dataField: 'totalPrice', caption: '金额', dataType: "number", format: "currency" }
    ]

    constructor(props: any) {
        super(props);

        this.state = {
            data: []
        };

        this.gridOptions.filterRow!.visible = false;

        this.search = this.search.bind(this);
        this.showChart = this.showChart.bind(this);
    }

    async search(condition: OrderTrendReportCondition) {
        const data = await orderReportService.getTrendReport(condition);
        this.setState({ data: data });
    }

    showChart() {
        this.chartRef.current?.show(this.state.data);
    }

    render() {
        return (
            <ContentBlock caption="订单趋势报表">
                <OrderTrendToolbar onFiltering={this.search} showChart={this.showChart} />
                <StdGrid
                    moduleName={this.moduleName}
                    gridRef={this.gridRef}
                    dataSource={this.state.data}
                    options={this.gridOptions}
                    columns={this.columns} />
                <OrderTrendChart ref={this.chartRef} />
            </ContentBlock>
        )
    }
}