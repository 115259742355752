import { createStoreFromUrl, http, BaseApiService, MessageBool } from "../base";

class AnnouncementService extends BaseApiService {
    moduleName = "announcement";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }

    count() {
        return http.getData<number>(`${this.apiPath}/count`);
    }

    read(id: number) {
        return http.putData<MessageBool>(`${this.apiPath}/read/${id}`, '');
    }

    reply(id: number, sign: string) {
        return http.putData<MessageBool>(
            `${this.apiPath}/reply`, { id: id, sign: sign });
    }

    getMyDxData(showAll: boolean) {
        return createStoreFromUrl(`${this.apiPath}/my?showAll=${showAll}`, 'announcementId');
    }
}

export const announcementService = new AnnouncementService();
export const annoucementStore = announcementService.getDxData();
export const myAnnouncementStore = announcementService.getMyDxData(false);
export const myAllAnnouncementStore = announcementService.getMyDxData(true);