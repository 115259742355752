import React from "react";
import DataGrid, { IColumnProps } from "devextreme-react/data-grid";
import { ExportingEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import { remoteReadOnlyGridOptions, createGridOptions, setColumnDataSource, createImageColumn, StdGrid, ExportModel } from "../../components";
import { ModuleConfig, StringMap, enums, authorizationService, baseApiUrl, globalPopupManager, globalPopupNames, dataSources, createEmptyStore } from "../../base";
import { dangerNotificationStore, customerTypeService, dangerService } from "../../api";
import { CustomerTypeRef, DangerModel, EmployeeRef, OrganizationRef } from "../../models";
import CustomStore from "devextreme/data/custom_store";

export interface DangerNotificationGridProps {
    onModelSelected: (model: DangerModel) => void;
    onButtonClick: (model: DangerModel, name: string) => void;
    onRowChecked: (ids: number[]) => void;
}

interface DangerNotificationGridState {
    customerTypes: CustomerTypeRef[];
    orgs: OrganizationRef[];
    delivers: EmployeeRef[];
    columns: IColumnProps[];
    store: CustomStore;
}

export default class DangerNotificationGrid
    extends React.Component<DangerNotificationGridProps, DangerNotificationGridState> {
    objectName = "Danger";
    moduleName: ModuleConfig = {
        moduleName: "Danger",
        children: {
            "customer": {
                moduleName: "Customer",
                children: {
                    "deliver": "Employee"
                }
            },
            "organization": "Organization"
        }
    };

    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("dangerNotifications", this.gridRef, remoteReadOnlyGridOptions);

    nameMap: StringMap = {
        "customer.name": "customerId",
        "organization.name": "organizationId"
    }

    constructor(props: Readonly<DangerNotificationGridProps>) {
        super(props);

        this.state = {
            store: createEmptyStore(),
            customerTypes: [],
            orgs: [],
            delivers: [],
            columns: [
                {
                    dataField: 'customer.name',
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.name}
                        </span>
                    )
                },
                {
                    dataField: 'customer.gasCode',
                    cellRender: (e: any) => (
                        <span className={"link-button"}
                            onClick={() => this.props.onButtonClick(e.data, "callcenter")}>
                            {e.data.customer.gasCode}
                        </span>
                    )
                },
                {
                    dataField: 'customer.typeId',
                    lookup: { dataSource: [], displayExpr: 'name', valueExpr: 'id' }
                },
                { dataField: 'address' },
                {
                    dataField: 'organization.name',
                    allowFiltering: false
                },
                {
                    dataField: 'checker',
                    caption: "送气工/安检员",
                },
                { dataField: 'checkTime', dataType: 'datetime' },
                { dataField: 'expirationDate', dataType: 'date' },
                { 
                    dataField: 'dangerLevel',
                    lookup: { dataSource: dataSources.dangerLevels, displayExpr: 'text', valueExpr: 'value' }
                },
                createImageColumn({ dataField: 'customerSign' }),
                createImageColumn({ dataField: 'checkerSign' }),
                { dataField: "notificationDepositCert" }
            ]
        };

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "detail");
        this.gridOptions.onExporting = this.onExporting.bind(this);
        this.gridOptions.export!.allowExportSelectedData = false;
        this.gridOptions.export!.enabled = false;
    }

    componentDidMount() {
        this.authorizeImport();
        this.loadData();
    }

    async authorizeImport() {
        if (await authorizationService.authorize(this.objectName, enums.stdActions.export)) {
            this.gridOptions.export!.enabled = true;
            this.gridRef.current?.instance.option("export.enabled", true);
        }
    }

    async loadData() {
        let types = await customerTypeService.getRefs();
        setColumnDataSource(this.state.columns, "customer.typeId", types);

        this.setState({
            customerTypes: types
        });
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});    // 为避免混乱，多选不能编辑
        }

        this.props.onRowChecked(e.selectedRowKeys);
    }
    
    onExporting(e: ExportingEvent) {
        const args: ExportModel = {
            moduleName: this.moduleName.moduleName,
            exportUrl: `${baseApiUrl}${dangerService.apiPath}/exportnotification`,
            columns: this.state.columns,
            keys: e.component.getSelectedRowKeys(),
            filter: e.component.getCombinedFilter()
        };

        e.cancel = true;
        globalPopupManager.show(globalPopupNames.exportPopup, args);
    }

    filter(filterValue: any) {
        if (this.state.store !== dangerNotificationStore) {
            this.setState({ store: dangerNotificationStore }, () => this._filter(filterValue));
        } else {
            this._filter(filterValue);
        }        
    }

    _filter(filterValue: any) {
        this.gridRef.current?.instance.filter(filterValue);
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={this.state.store}
                options={this.gridOptions}
                columns={this.state.columns}
                columnNameMap={this.nameMap}
                toolbarHeight={80}>
            </StdGrid>
        )
    }
}