import { BaseApiService, createStoreFromUrl } from "../base";
import { CustomerCallbackModel } from "../models";

class CustomerCallbackService extends BaseApiService<CustomerCallbackModel> {
    moduleName ="customercallback";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'id');
    }
}

export const customerCallbackService = new CustomerCallbackService();
export const customerCallbackStore = customerCallbackService.getDxData();