import React from "react";
import { bottleFlowService, bottlePositionService } from "../../api";
import { alertError, authorizationService, confirm2, enums, globalPopupManager, globalPopupNames, isPorcelainCode, notifySuccess, toShortCode } from "../../base";
import { ContentBlock, RefPopup } from "../../components";
import { BottleFlowModel, BottlePositionModel, PositionCondition } from "../../models";
import AuthorizedComponent from "../authorized-component";
import BottlePositionGrid from "./bottle-position-grid";
import BottlePositionToolbar from "./bottle-position-toolbar";
import BottleFlowGrid from "./bottle-flow-grid";
import { Popup } from "devextreme-react";

export default class BottlePositionPage extends AuthorizedComponent<any> {
    objectName = "BottlePosition";
    gridRef = React.createRef<BottlePositionGrid>();
    toolbarRef = React.createRef<BottlePositionToolbar>();
    popupRef = React.createRef<Popup>();
    flowGridRef = React.createRef<BottleFlowGrid>();

    get actionNames(): string[] {
        return [
            enums.stdActions.update
        ];
    }

    constructor(props: any) {
        super(props);

        this.onFiltering = this.onFiltering.bind(this);
        this.onModelSelected = this.onModelSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.showFlowPopup = this.showFlowPopup.bind(this);
        this.onFlowGridButtonClick = this.onFlowGridButtonClick.bind(this);
    }

    async authorize(): Promise<void> {
        await super.authorize();
        
        this.permissions["SetLost"] = await authorizationService.authorize("BottleFlow", "SetLost");
    }

    onModelSelected(model: BottlePositionModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onButtonClick(model: BottlePositionModel, name: string) {
        switch (name) {
            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.updatePosition(model);
                }
                break;

            case "setlost":
                if (this.permissions["SetLost"]) {
                    this.setLost(model);
                }
                break;
        }
    }

    async updatePosition(model: BottlePositionModel) {
        if (!await confirm2("确定要重新计算气瓶位置吗？")) {
            return;
        }
        
        const res = await bottlePositionService.update(model.bottleId!);
        
        if (res.succeeded) {
            notifySuccess("已成功更新气瓶位置");
            this.gridRef.current?.refresh();
        } else {
            alertError(res.message!);
        }
    }

    async setLost(model: BottlePositionModel) {
        if (!await confirm2("确定将该气瓶设置为疑似流失瓶吗？该操作不可撤销")) {
            return;
        }

        const res = await bottleFlowService.setLost(model.bottleId!);

        if (res.succeeded) {
            notifySuccess("已成功设置流失瓶");
            this.gridRef.current?.refresh();
        } else {
            alertError(res.message!);
        }
    }

    async onFiltering(condition: PositionCondition) {
        this.gridRef.current?.applyFilter(condition);
    }
    
    showFlowPopup(code: string) {
        this.popupRef.current?.instance.show();

        const filter: any = isPorcelainCode(code) ?
            ["bottle.barCode", "=", code] :
            ["bottle.code", "=", toShortCode(code)];

        this.flowGridRef.current?.filter(filter);
    }

    onFlowGridButtonClick(model: BottleFlowModel, eventName: string) {
        switch (eventName) {
            case "callCenter":
                globalPopupManager.show(globalPopupNames.callCenter, { id: model.customerId });
                break;

            default:
                alertError("不支持在此窗口执行操作");
                break;
        }
    }

    render() {
        return (
            <ContentBlock caption={"气瓶位置"}>
                <BottlePositionToolbar
                    ref={this.toolbarRef}
                    permissions={this.permissions}
                    onFiltering={this.onFiltering}
                    onButtonClick={this.onButtonClick} />
                <BottlePositionGrid
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    showFlowPopup={this.showFlowPopup} />
                <RefPopup popupRef={this.popupRef} title={"流转记录"}>
                    <BottleFlowGrid 
                        showDangerIssueButton={false}
                        ref={this.flowGridRef} 
                        onModelSelected={() => { }} 
                        onButtonClick={this.onFlowGridButtonClick} />
                </RefPopup>
            </ContentBlock>
        );
    }
}