import DataGrid, { IColumnProps, IDataGridOptions } from "devextreme-react/data-grid";
import React from "react";
import { ScreenSize } from "../../hooks";
import { updatePack } from "./dev-packs";
import { confirm2 } from "../../base/dialogs";
import { deepClone, modelClone } from "../../base/format-functions";
import { createResizablePopupOptions, stdGridPopupOptions } from "./popup-options";

export const stdReadOnlyGridOptions: IDataGridOptions = {
    rowAlternationEnabled: true,
    columnAutoWidth: true,
    allowColumnReordering: true,
    allowColumnResizing: true,
    columnResizingMode: "widget",
    columnMinWidth: 80,
    scrolling: { showScrollbar: "always" },
    filterRow: { visible: true },
    selection: { mode: "single", selectAllMode: "page" },
    columnChooser: { enabled: true },
    groupPanel: { visible: true },
    export: { enabled: true, allowExportSelectedData: true },
    pager: {
        displayMode: 'adaptive',
        showPageSizeSelector: true,
        showInfo: true,
        visible: true,
        allowedPageSizes: [20, 50, 100, 200]
    },
    editing: {
        mode: 'popup',
        confirmDelete: false,
        form: { labelLocation: "left" }
    },
    onRowUpdating: updatePack,
    onRowRemoving: function (e) {
        e.component.option("loadPanel.enabled", false);
        e.cancel = (async function () {
            const res = !(await confirm2("确定要删除此数据吗？"));
            e.component.option("loadPanel.enabled", true);
            return res;
        })() as any;
    },
    onRowPrepared: e => {
        // 测试数据标志
        if (e.data?.testFlag) {
            e.rowElement.className += " test-row";
        }
    }
};

export const stdGridOptions: IDataGridOptions = {
    ...stdReadOnlyGridOptions,
    editing: {
        ...stdReadOnlyGridOptions.editing,
        allowAdding: true,
        allowDeleting: true,
        allowUpdating: true
    }
};

export const remoteOperations = {
    filtering: true,
    grouping: true,
    groupPaging: true,
    paging: true,
    sorting: true,
    summary: true
};

export const remoteReadOnlyGridOptions: IDataGridOptions =
    { ...stdReadOnlyGridOptions, remoteOperations: remoteOperations };

export const remoteGridOptions: IDataGridOptions =
    { ...stdGridOptions, remoteOperations: remoteOperations };

export function createGridOptions(
    id: string,
    ref: React.MutableRefObject<DataGrid | null> | (() => DataGrid | null),
    baseOptions: IDataGridOptions,
    itemsFunc?: (fixedItems: any[]) => any[]) {

    let items = [
        {
            location: 'after',
            widget: 'dxButton',
            locateInMenu: "auto",
            showText: "inMenu",
            id: "gtb_refresh",     
            options: {
                icon: "refresh",
                text: "刷新",
                hint: "刷新",
                onClick: function () {
                    (typeof (ref) === "function" ? ref() : ref.current)?.instance.refresh();
                }
            }
        },
        {
            location: 'after',
            widget: 'dxButton',
            locateInMenu: "auto",
            showText: "inMenu",
            id: "gtb_reset",
            options: {
                icon: "undo",
                text: "还原默认设置",
                hint: "还原默认设置",
                onClick: function () {
                    (typeof (ref) === "function" ? ref() : ref.current)?.instance.state(null);
                }
            }
        },
        {
            location: 'after',
            widget: 'dxButton',
            locateInMenu: "auto",
            showText: "inMenu",
            id: "gtb_check",
            options: {
                icon: "check",
                text: "多选",
                hint: "多选",
                onClick: function() {
                    const mode = options.selection?.mode || "single";
                    options.selection!.mode = mode === "single" ? "multiple" : "single";
                    const grid = (typeof (ref) === "function" ? ref() : ref.current)?.instance;
                    grid?.option("selection.mode", options.selection!.mode);
                }
            }
        }
    ];

    items = itemsFunc ? itemsFunc(items) : items;

    const options: IDataGridOptions = {
        ...deepClone(baseOptions),
        id: id,
        stateStoring: {
            enabled: true,
            type: "custom",
            savingTimeout: 0,
            customLoad: baseOptions.stateStoring?.customLoad || function () {
                const value = localStorage.getItem(`grid-options-${id}`);
                const state = value ? JSON.parse(value) : null;
                if (state && state.pageSize <= 0) state.pageSize = 20;
                return state;
            },
            customSave: baseOptions.stateStoring?.customSave || function (state: any) {
                state.selectedRowKeys = null;
                state.pageIndex = null;
                for (const column of state.columns) {
                    column.filterValue = null;
                }

                localStorage.setItem(`grid-options-${id}`, JSON.stringify(state));
            }
        },
        toolbar: {
            items: ["groupPanel", ...items, "exportButton", "columnChooserButton"]
        }
    };

    if (options.editing) {
        setCustomEditButtons(options, ref);
    }

    return options;
}

function setCustomEditButtons(
    options: IDataGridOptions,
    ref: React.MutableRefObject<DataGrid | null> | (() => DataGrid | null)) {
    if (!options.editing!.popup) {
        options.editing!.popup = { ...stdGridPopupOptions };
    }

    const popup = options.editing!.popup!;
    popup.toolbarItems = [
        {
            widget: "dxButton",
            toolbar: "bottom",
            location: "after",
            options: {
                text: "保存",
                stylingMode: "contained",
                onClick: function () {
                    (typeof (ref) === "function" ? ref() : ref.current)?.instance.saveEditData();
                }
            }
        },
        {
            widget: "dxButton",
            toolbar: "bottom",
            location: "after",
            options: {
                text: "取消",
                stylingMode: "contained",
                type: "normal",
                onClick: function () {
                    (typeof (ref) === "function" ? ref() : ref.current)?.instance.cancelEditData();
                }
            }
        }
    ]
}

export function setGridPopupOptions(
    gridOptions: IDataGridOptions,
    width?: number,
    height?: number,
    screenSize?: ScreenSize) {

    if (gridOptions.editing) {
        gridOptions.editing.popup = createResizablePopupOptions(
            width, height, true, gridOptions.editing.popup || stdGridPopupOptions, screenSize);

        // grid会默认为弹出窗口设置width/height，必须自己设定才会生效
        gridOptions.editing.popup.width = width;
        gridOptions.editing.popup.height = height;
    }

    return gridOptions;
}

export function createBoolColumn(baseColumn: IColumnProps): IColumnProps {
    return {
        ...baseColumn,
        trueText: "是",
        falseText: "否",
        cellRender: function (e: any) {
            return (e.rowType !== "data" || 
                    e.data[baseColumn.dataField] === undefined || 
                    e.data[baseColumn.dataField] === null) ?
                "" :
                (e.data[baseColumn.dataField] ? "是" : "否");
        }
    }
}

export function setColumnDataSource(columns: IColumnProps[], name: string, dataSource: any[]) {
    const index = columns.findIndex(c => c.dataField === name);
    const copy = modelClone(columns[index]);
    (copy as any).lookup.dataSource = dataSource;
    columns.splice(index, 1, copy);
}

/**
 * DEV Grid在每次列变化后，会丢失State，因此，在每次执行列变动语句后应调用该方法恢复状态
 */
export function restoreGridState(
    ref: React.RefObject<DataGrid>,
    options: IDataGridOptions,
    callback?: (state: any) => void) {
    // 由于保存有延时，为避免在保存前就加载，使用同样的延时
    setTimeout(function () {
        const value = localStorage.getItem(`grid-options-${options.id}`);
        const state = value ? JSON.parse(value) : null;
        ref?.current?.instance.state(state);
        callback && callback(state);
    }, options.stateStoring?.savingTimeout || 0);
}