import { CachableApiService, authorizationService, http } from "../base";
import { BottleTypeModel } from "../models";

class BottleTypeService extends CachableApiService {
    apiPath = "/api/bottletype";
    cacheSeconds = 1200;

    /**
     * 获取引用，不包含丙烷
     */
    getRefs(): Promise<BottleTypeModel[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/refs`), this.cacheSeconds, false, "refs");
    }

    /**
     * 获取含丙烷在内的所有类型，仅用于气瓶查询
     */
    getAll(): Promise<BottleTypeModel[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/all`), this.cacheSeconds, false, "all");
    }

    /**
     * 根据权限获取引用，可包含丙烷
     */
    async getAuthorizedRefs(): Promise<BottleTypeModel[]> {
        const propanePermission = await authorizationService.authorize("GlobalPermission", "Propane");
        return propanePermission ? this.getAll() : this.getRefs();
    }
}

export const bottleTypeService = new BottleTypeService();