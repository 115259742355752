import { Switch, Route, Redirect } from 'react-router-dom';
import routes from './route/route';
import { SideNavOuterToolbar as SideNavBarLayout, Footer, CallCenterPopup, ExportPopup } from './components';
import { corpName } from './base';

export default function Content() {
    return (
        <SideNavBarLayout>
            <Switch>
                {routes.map(({ path, component }) => (
                    <Route
                        key={path}
                        path={path}
                        component={component}
                    />
                ))}
                <Redirect to={'/home'} />
            </Switch>
            <Footer>
                版权所有 © 2011-{new Date().getFullYear()} {corpName}
            </Footer>
            <CallCenterPopup />
            <ExportPopup />
        </SideNavBarLayout>
    );
}
