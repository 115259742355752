
import { Popup, RadioGroup } from "devextreme-react";
import React from "react";
import { RefPopup } from "../popup";
import { PopupContentBox } from "../layouts";
import { employeeShareService } from "../../api";
import { alertError, notifySuccess, ListItem, confirm2 } from "../../base";
import { EmployeeModel } from "../../models";

interface MutualSharePopupState {
    deleteOld: boolean;
    employeeIds: string[];
}

export default class MutualSharePopup extends React.Component<any, MutualSharePopupState> {
    popupRef = React.createRef<Popup>();

    constructor(props: any) {
        super(props);

        this.state = {
            deleteOld: true,
            employeeIds: []
        };

        this.onValueChanged = this.onValueChanged.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    async show(models: EmployeeModel[]) {
        const employeeIds = models.map(e => e.id!);
        const orgIds: (number | undefined)[] = [];
        models.forEach(e => {
            if (-1 === orgIds.indexOf(e.organizationId)) {
                orgIds.push(e.organizationId);
            }
        });

        if (orgIds.length === 1 || await confirm2("您选择的员工来自不同站点，确定要共享吗？")) {
            this.popupRef.current?.instance.show();
            this.setState({ employeeIds: employeeIds });
        }
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    onValueChanged(e: any) {
        this.setState({ deleteOld: e.value });
    }

    async save() {
        const result = await employeeShareService.saveMutual(this.state);

        if (result.succeeded) {
            notifySuccess("已成功设置数据共享");
            this.hide();
        } else {
            alertError(result.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title="警告"
                width={400}
                height={220}
                baseOptions={{ className: "global-dialog" }}>
                <PopupContentBox onSave={this.save} onCancel={this.cancel}>
                    <div className="global-dialog">
                        <div className="icon-body">
                            <div><i className="bi bi-question-circle" /></div>
                            <div>确实要共享选中员工的数据吗？</div>
                        </div>
                        <div style={{ padding: "15px" }}>
                            <RadioGroup
                                value={this.state.deleteOld}
                                dataSource={options}
                                displayExpr={"text"}
                                valueExpr={"value"}
                                onValueChanged={this.onValueChanged} />
                        </div>
                    </div>
                </PopupContentBox>
            </RefPopup>
        )
    }
}

const options: ListItem<boolean>[] = [
    { value: true, text: "先删除以往的共享数据" },
    { value: false, text: "保留以往的共享数据（合并）" }
]