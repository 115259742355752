import { createStoreFromUrl } from "../base";

class OrderEvaluationService {
    apiPath ="/api/orderevaluation";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'orderId');
    }
}

export const orderEvaluationService = new OrderEvaluationService();
export const orderEvaluationStore = orderEvaluationService.getDxData();