import React from "react";
import { Popup, ScrollView } from "devextreme-react";
import DataGrid, { IColumnProps, IDataGridOptions } from "devextreme-react/data-grid";
import { StdGrid, RefPopup, createImageColumn } from "../../components";
import { SiteDangerModel } from "../../models";
import { siteDangerService } from "../../api";

interface SiteDangerState {
    danger: SiteDangerModel;
}

export default class SiteDangerDetail extends React.Component<any, SiteDangerState> {
    moduleName = "SiteDangerItem";

    popupRef = React.createRef<Popup>();
    gridRef = React.createRef<DataGrid>();
    gridOptions: IDataGridOptions = {
        columnAutoWidth: true,
        paging: { enabled: false }
    };

    columns: IColumnProps[] = [
        { dataField: "itemName" },
        createImageColumn({ dataField: "photo" }),
        createImageColumn({ dataField: "fixPhoto" }),
        // { dataField: "fixDesc" }
    ];

    constructor(props: any) {
        super(props);

        this.state = { danger: {} };
    }

    async show(model: SiteDangerModel) {
        this.popupRef.current?.instance.show();

        const danger = await siteDangerService.get(model.id!);
        this.setState({ danger: danger });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"站点隐患整改详情"}
                width={1000}
                height={600}>
                <ScrollView height={"100%"} scrollByContent={true}>
                    <StdGrid
                        moduleName={this.moduleName}
                        gridRef={this.gridRef}
                        dataSource={this.state.danger.items || []}
                        columns={this.columns}
                        options={this.gridOptions}
                        autoHeight={true}
                    />
                </ScrollView>
            </RefPopup>
        );
    }
}