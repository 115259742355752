import React from "react";
import DataGrid, { IColumnProps, IDataGridOptions } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import { StdGrid, createGridOptions, setColumnDataSource, stdReadOnlyGridOptions } from "../../components";
import { bottlePositionService, bottlePositionStore, bottleTypeService } from "../../api";
import { allBottleOperations, dataSources, ModuleConfig } from "../../base";
import { BottlePositionModel, BottleTypeModel, PositionCondition } from "../../models";

export interface BottlePositionProps {
    onModelSelected(model: BottlePositionModel): void;
    showFlowPopup(code: string): void;
}

export interface BottlePositionState {
    bottleTypes: BottleTypeModel[];
    columns: IColumnProps[];
}

export default class BottlePositionGrid
    extends React.Component<BottlePositionProps, BottlePositionState> {
    moduleName: ModuleConfig = {
        moduleName: "BottleFlow",
        children: {
            "bottle": "Bottle"
        }
    };

    gridRef = React.createRef<DataGrid>();
    gridOptions: IDataGridOptions;
    condition?: PositionCondition;

    constructor(props: any) {
        super(props);

        this.state = {
            bottleTypes: [],
            columns: [
                { 
                    dataField: "bottle.barCode",
                    cellRender: (e: any) => {
                        return (
                            <span className="link-button" onClick={()=>this.props.showFlowPopup(e.data.barCode)}>
                                {e.data.bottle.barCode}
                            </span>
                        )
                    }
                },
                { dataField: "bottle.code" },
                {
                    dataField: "bottle.typeId",
                    lookup: { dataSource: [], displayExpr: "name", valueExpr: "id" }
                },
                { dataField: "createdAt", dataType: "datetime" },
                {
                    dataField: "flowType",
                    lookup: { dataSource: allBottleOperations, displayExpr: "text", valueExpr: "value" }
                },
                {
                    dataField: "sourceType",
                    lookup: { dataSource: dataSources.bottlePositions, displayExpr: "text", valueExpr: "value" }
                },
                { dataField: "source" },
                {
                    dataField: "targetType",
                    lookup: { dataSource: dataSources.bottlePositions, displayExpr: "text", valueExpr: "value" }
                },
                { dataField: "target" }
            ]
        }

        this.gridOptions = createGridOptions(
            "bottlePositions",
            this.gridRef,
            {
                ...stdReadOnlyGridOptions
            },
            (items: any[]) => {
                // state清空会同时清空filter，需要重新绑定旧的
                items.find(i => i.id === "gtb_reset").options.onClick = this.reset.bind(this);
                return items;
            });

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
    }
    
    componentDidMount(): void {
        this.loadBottleTypes();
    }

    async loadBottleTypes() {
        const bottleTypes = await bottleTypeService.getAll();
        setColumnDataSource(this.state.columns, "bottle.typeId", bottleTypes);
        this.setState({ bottleTypes: bottleTypes });
    }

    reset() {
        const grid = this.gridRef.current!.instance;
        const filter = grid.filter();
        grid.state(null);
        grid.filter(filter);
    }

    async refresh() {
        if (this.condition) {
            await bottlePositionService.searchAsync(this.condition);
            this.gridRef.current?.instance.refresh();
        }
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});
        }
    }

    async applyFilter(e: PositionCondition) {
        this.condition = e;
        this.gridRef.current?.instance.beginCustomLoading("数据加载中...");
        await bottlePositionService.searchAsync(e);
        this.gridRef.current?.instance.endCustomLoading();
        this.gridRef.current?.instance.refresh();
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={bottlePositionStore}
                options={this.gridOptions}
                columns={this.state.columns}>
            </StdGrid>
        );
    }
}