import { DataBool, http, MessageBool } from "../base";
import { GasStocktakingModel, StocktakingCondition } from "../models";

class GasStocktakingService {
    apiPath = "/api/gasstocktaking";

    get(condition: StocktakingCondition) {
        return http.getData<DataBool<GasStocktakingModel>>(`${this.apiPath}`, condition);
    }

    save(model: GasStocktakingModel) {
        return http.putData<MessageBool>(this.apiPath, model);
    }
}

export const gasStocktakingService = new GasStocktakingService();