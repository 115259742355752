import { http, MessageBool } from "../base";
import { RoleClaimModel } from "../models";

class RoleClaimService {
    apiPath = "/api/roleclaim";

    getByRoleId(roleId: number): Promise<RoleClaimModel[]> {
        return http.getData(this.apiPath, { roleId: roleId });
    }

    save(roleId: number, claims: RoleClaimModel[]): Promise<MessageBool> {
        return http.putData(`${this.apiPath}?roleId=${roleId}`, claims);
    }
}

export const roleClaimService = new RoleClaimService();