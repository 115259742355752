import { createStoreFromApi, http, BaseApiService } from "../base";
import { PredefinedLabelModel, PredefinedLabelRef } from "../models";

class PredefinedLabelService extends BaseApiService<PredefinedLabelModel> {
    moduleName = "predefinedlabel";
    cacheSeconds = 60;

    async getRefs(): Promise<PredefinedLabelRef[]> {
        return this.getCached(() => http.getData(`${this.apiPath}/refs`), this.cacheSeconds);
    }

    async getByDataType(dataType: string) {
        const labels = await this.getRefs();
        return labels.filter(l => l.dataType === dataType);
    }
}

export const predefinedLabelService = new PredefinedLabelService();
export const predefinedLabelStore = createStoreFromApi(predefinedLabelService);