import { Button, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import React from "react";
import { DataMap, enums } from "../../base";
import { CpCheckBackModel } from "../../models";

export interface CpCheckBackToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (model: CpCheckBackModel, name: string) => void;
    onContextButtonClick: (ids: number[], name: string) => void;
}

interface CpCheckBackToolbarState {
    model: CpCheckBackModel;
    ids: number[];
}

export default class CpCheckBackToolbar extends React.Component<CpCheckBackToolbarProps, CpCheckBackToolbarState> {
    constructor(props: Readonly<CpCheckBackToolbarProps>) {
        super(props);

        this.state = {
            model: {},
            ids: []
        }
    }

    setModel(model: CpCheckBackModel) {
        this.setState({ model: model });
    }

    setIds(ids: number[]) {
        this.setState({ ids: ids });
    }

    render(): React.ReactNode {
        const { model, ids } = this.state;
        const permissions = this.props.permissions || {};

        return (
            <div className={"page-toolbar"}>
                <Toolbar>
                    <Item location={"after"} locateInMenu={"auto"} visible={!!permissions[enums.stdActions.create]}>
                        <Button
                            text="新增"
                            onClick={() => this.props.onButtonClick({}, "add")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={!!permissions[enums.stdActions.update]}>
                        <Button
                            disabled={!model.id}
                            text="编辑"
                            onClick={() => this.props.onButtonClick(model, "edit")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={!!permissions[enums.stdActions.delete]}>
                        <Button
                            disabled={!model.id}
                            text="批量删除"
                            onClick={() => this.props.onContextButtonClick(ids, "delete")} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}