import { IColumnProps } from "devextreme-react/tree-list";
import { customerPhoneService } from "../../api";
import React from "react";
import { StdGrid } from "../grid";
import { Column, IDataGridOptions, Button as GridButton } from "devextreme-react/data-grid";
import { Button } from "devextreme-react";
import { alertError, confirm2, notifySuccess } from "../../base";
import { BriefCustomerPhone } from "../../models";

export interface CallHistoryProps {
    id?: number;
    phone?: string;
    customer?: any;
}

interface CallHistoryState {
    histories: BriefCustomerPhone[];
    phoneAdded: boolean;
}

export default class CallHistory extends React.Component<CallHistoryProps, CallHistoryState> {
    moduleName = "CustomerPhone";
    gridOptions: IDataGridOptions = {
        columnAutoWidth: true,
        editing: { allowDeleting: true },
        scrolling: { showScrollbar: "never" },
        paging: { pageSize: 10 }
    };

    columns: IColumnProps[] = [
        { dataField: "phoneNumber" },
        { dataField: "createdAt", dataType: "datetime", caption: "时间" }
    ];

    constructor(props: Readonly<CallHistoryProps>) {
        super(props);

        this.state = {
            histories: [],
            phoneAdded: false
        };

        this.addPhone = this.addPhone.bind(this);
        this.removePhone = this.removePhone.bind(this);
    }

    componentDidUpdate(prevProps: CallHistoryProps) {
        if (this.props.id && this.props.id !== prevProps.id) {
            this.loadData();
        } else if (!this.props.id && prevProps.id) {
            this.setState({ histories: [] });
        }
    }

    async loadData() {
        const data = await customerPhoneService.searchByCustomerId(this.props.id!);
        const phone = this.props.phone?.trim();
        const customer = this.props.customer;
        var added = false;

        if (!phone) {
            added = true;
        } else if (phone === customer?.phoneNumber?.trim() || phone === customer?.phoneNumber2?.trim() ||
            data.find(p => p.phoneNumber === phone)) {
            await this._addPhone(true);
            added = true;
        }        

        this.setState({ histories: data, phoneAdded: added });
    }

    async addPhone() {
        this._addPhone();
    }

    async _addPhone(ensured = false) {
        if (!this.props.phone || this.state.phoneAdded || 
            (!ensured && !await confirm2("确定要标记该电话吗？"))) {
            return;
        }
        
        const model = { customerId: this.props.id, phoneNumber: this.props.phone };
        const result = await customerPhoneService.create(model);

        if (result.succeeded) {
            notifySuccess("已成功标志电话并添加来电记录，请务重复添加");
            this.loadData();
        } else {
            alertError(result.message!);
        }
    }

    async removePhone(e: any) {
        if (!await confirm2("确定要删除该来电记录吗？")) {
            return;
        }

        const id = e.row.data.id;
        const result = await customerPhoneService.delete(id);

        if (result.succeeded) {
            this.loadData();
        } else {
            alertError(result.message!);
        }
    }

    render() {
        return (
            <div>
                <StdGrid
                    moduleName={this.moduleName}
                    dataSource={this.state.histories}
                    columns={this.columns}
                    options={this.gridOptions}
                    autoHeight={true}>
                    <Column type="buttons">
                        <GridButton icon="trash" onClick={this.removePhone} />
                    </Column>
                </StdGrid>
                {this.props.phone && (
                    <div className={"buttonbar"}>
                        <Button 
                            stylingMode={"text"} 
                            type="default" 
                            text={`标志电话${this.props.phone}`} 
                            onClick={this.addPhone} />
                    </div>
                )}
            </div>
        );
    }
}