import { areaService, bottleTypeService, customerTypeService, dataSourceService, 
    employeeService, organizationService, predefinedLabelService } from ".";
import { enums } from "../base";
import { AreaRef, BottleTypeModel, CustomerTypeRef, EmployeeRef, OrganizationRef, PredefinedLabelRef } from "../models";

export interface DataSources {
    types: CustomerTypeRef[];
    orgs: OrganizationRef[];
    employees: EmployeeRef[];
    counties: AreaRef[];
    streets: AreaRef[];
    bottleTypes: BottleTypeModel[];
    addressTypes: string[];
    labels: PredefinedLabelRef[];
}

export function emptyDataSources(): DataSources {
    return {
        types: [],
        orgs: [],
        employees: [],
        counties: [],
        streets: [],
        bottleTypes: [],
        addressTypes: [],
        labels: []
    };
}

export async function loadCustomerDataSources(): Promise<DataSources> {
    const dataSources = emptyDataSources();

    await Promise.all([
        (async () => dataSources.types = await customerTypeService.getRefs())(),
        (async () => dataSources.orgs = await organizationService.getRefs())(),
        (async () => dataSources.employees = await employeeService.getDelivers())(),
        (async () => dataSources.counties = await areaService.getCounties())(),
        (async () => dataSources.streets = await areaService.getStreets())(),
        (async () => dataSources.bottleTypes = await bottleTypeService.getAuthorizedRefs())(),
        (async () => dataSources.addressTypes = 
            await dataSourceService.getDataSource(enums.dataSourceTypes.addressTypes))(),
        (async () => dataSources.labels = 
            await predefinedLabelService.getByDataType(enums.labelDataTypes.customer))()
    ]);

    return dataSources;
}