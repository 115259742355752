import { Form, Popup } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import React from "react";
import { cpBatchService, cpDataSourceService, cpNewService } from "../../api";
import { alertError, dateAdd, enums, mergeModel, modelClone, notifySuccess } from "../../base";
import { PopupContentBox, RefPopup, setItemDataSource, StdForm } from "../../components";
import { CpBatchRef, CpDataSourceRef, CpNewModel } from "../../models";

export interface CpNewFormProps {
    onSaved: () => void;
}

interface CpNewFormState {
    model: CpNewModel;
    items: IItemProps[];
    batches: CpBatchRef[];
    vendors: CpDataSourceRef[];
    bottleTypes: CpDataSourceRef[];
}

export default class CpNewForm extends React.Component<CpNewFormProps, CpNewFormState> {
    moduleName = "CpNew";
    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    batchId?: number;

    constructor(props: Readonly<CpNewFormProps>) {
        super(props);

        this.state = {
            model: {},
            batches: [],
            vendors: [],
            bottleTypes: [],
            items: [
                {
                    dataField: "batchId",
                    editorType: "dxSelectBox",
                    editorOptions: {
                        dataSource: [],
                        displayExpr: 'batchCode',
                        valueExpr: 'id',
                        onValueChanged: this.onBatchChanged.bind(this)
                    }
                },
                {
                    dataField: "vendorId",
                    editorType: "dxSelectBox",
                    editorOptions: { dataSource: [], displayExpr: 'text', valueExpr: 'id' }
                },
                {
                    dataField: "bottleTypeId",
                    editorType: "dxSelectBox",
                    editorOptions: {
                        dataSource: [],
                        displayExpr: 'text',
                        valueExpr: 'id',
                        onValueChanged: () => this.setNextInspectDate()
                    }
                },
                {
                    dataField: "weight",
                    validationRules: {
                        type: "required", message: "重量不能为空"
                    }
                },
                {
                    dataField: "produceDate",
                    editorType: "dxDateBox",
                    editorOptions: {
                        onValueChanged: () => this.setNextInspectDate()
                    },
                },
                { dataField: "enterDate", editorType: "dxDateBox" },
                { dataField: "nextInspectDate", editorType: "dxDateBox" },
                { dataField: "code" },
                { dataField: "barcode" },
                { dataField: "note", colSpan: 2 }
            ]
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount(): void {
        this.loadData();
    }

    async loadData() {
        let batches: CpBatchRef[] = [],
            vendors: CpDataSourceRef[] = [],
            bottleTypes: CpDataSourceRef[] = [];

        await Promise.all([
            (async () => batches = await cpBatchService.getRefs(enums.cpBatchTypes.new))(),
            (async () => vendors = await cpDataSourceService.getDataSource(enums.cpDataSourceTypes.vendor))(),
            (async () => bottleTypes = await cpDataSourceService.getDataSource(enums.cpDataSourceTypes.bottleType))()
        ]);

        setItemDataSource(this.state.items, "batchId", batches);
        setItemDataSource(this.state.items, "vendorId", vendors);
        setItemDataSource(this.state.items, "bottleTypeId", bottleTypes);

        this.setState({
            batches: batches,
            vendors: vendors,
            bottleTypes: bottleTypes
        });
    }

    async onBatchChanged(e: ValueChangedEvent) {
        if (e.value && !this.state.model.batch) {
            const template = await cpNewService.getTemplate(e.value);
            mergeModel(this.state.model, template);
            this.setState({ model: this.state.model });
        }
    }

    setNextInspectDate() {
        const model = this.state.model;

        if (model.produceDate && model.bottleTypeId) {
            const bottleType = this.state.bottleTypes.find(t => t.id === model.bottleTypeId);
            const year = bottleType?.extra === enums.gasTypes.gas ? 4 : 3;
            let nextInspectDate = dateAdd(dateAdd(new Date(model.produceDate), year, "y"), -1, "m");
            if (nextInspectDate.getMonth() === new Date(model.produceDate).getMonth())
                nextInspectDate = dateAdd(nextInspectDate, -nextInspectDate.getDate(), "d");

            if (nextInspectDate !== model.nextInspectDate) {
                model.nextInspectDate = nextInspectDate;
                this.setState({ model: model });
            }
        }
    }

    setBatchId(batchId: number) {
        const item = this.state.items.find(i => i.dataField === "batchId");
        item!.editorOptions.readOnly = true;
        setItemDataSource(this.state.items, "batchId", this.state.batches.filter(b => b.id === batchId));

        this.batchId = batchId;

        this.setState({ items: this.state.items });
    }

    async show(model: CpNewModel) {
        this.popupRef.current?.instance.show();

        const item = setItemDataSource(this.state.items, "batchId",
            model.batch ? [model.batch] : 
            this.batchId ? [this.state.batches.find(b => b.id === this.batchId)] : this.state.batches);
        item.editorOptions.readOnly = !!model.batch || !!this.batchId;

        model = modelClone(model);

        if (this.batchId && !model.id) {
            model.batchId = this.batchId;
            const template = await cpNewService.getTemplate(this.batchId);
            mergeModel(this.state.model, template);
        }

        this.setState({ model: model });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const model = this.state.model;
            model.vendor = this.state.vendors.find(v => v.id === model.vendorId)?.text;
            model.bottleType = this.state.bottleTypes.find(v => v.id === model.bottleTypeId)?.text;

            const res = model.id ?
                await cpNewService.update(model) :
                await cpNewService.create(model);

            if (res.succeeded) {
                this.hide();
                notifySuccess("已成功保存");
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"编辑"}
                width={800}
                height={400}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        formData={this.state.model} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}