export interface HomeCountBlockProps {
    caption: string;
    countText: string;
    icon: string;
    colorClass: string;
}

export default function HomeCountBlock(props: HomeCountBlockProps) {
    return (
        <div className="home-count dx-card">
            <div className={`home-count-icon ${props.colorClass}`}>
                <i className={props.icon}></i>
            </div>
            <div className="home-count-content">
                <div className="home-count-caption">{props.caption}</div>
                <div className="home-count-value">{props.countText}</div>
            </div>
        </div>
    )
}