import { RouteConfig } from "./route-model";
import OrderPage from "../pages/order/order";
import BottleDebtPage from "../pages/order/bottle-debt";
import BottleRentPage from "../pages/order/bottle-rent";
import ProductOrderPage from "../pages/order/product-order";

export const orderRoutes: RouteConfig[] = [
    {
        path: '/order',
        component: OrderPage
    },
    {
        path: '/debt',
        component: BottleDebtPage
    },
    {
        path: '/rent',
        component: BottleRentPage
    },
    {
        path: '/product-order',
        component: ProductOrderPage
    }
];