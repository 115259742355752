import { DataGrid } from "devextreme-react";
import { IColumnProps } from "devextreme-react/data-grid";
import React from "react";
import { customerReportService } from "../../../api/reports";
import { ContentBlock, createGridOptions, StdGrid, stdReadOnlyGridOptions } from "../../../components";
import { CustomerTrendReport, CustomerTrendReportCondition } from "../../../models";
import AuthorizedComponent from "../../authorized-component";
import CustomerTrendChart from "./customer-trend-chart";
import CustomerTrendToolbar from "./customer-trend-toolbar";

interface CustomerTrendReportPageState {
    data: CustomerTrendReport[];
}

export default class CustomerTrendReportPage
    extends AuthorizedComponent<any, CustomerTrendReportPageState> {

    objectName = "CustomerTrend";
    moduleName = "";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("customerTrendReport", this.gridRef, stdReadOnlyGridOptions);
    chartRef = React.createRef<CustomerTrendChart>();

    columns: IColumnProps[] = [
        { dataField: 'time', caption: '时间' },
        { dataField: 'newCount', caption: '开户数' },
        { dataField: 'exitCount', caption: '退户数' },
        { dataField: 'increase', caption: '增减' }
    ]

    constructor(props: any) {
        super(props);

        this.state = {
            data: []
        };

        this.gridOptions.filterRow!.visible = false;

        this.search = this.search.bind(this);
        this.showChart = this.showChart.bind(this);
    }

    async search(condition: CustomerTrendReportCondition) {
        const data = await customerReportService.getTrendReport(condition);
        this.setState({ data: data });
    }

    showChart() {
        this.chartRef.current?.show(this.state.data);
    }

    render() {
        return (
            <ContentBlock caption="客户增长/衰减统计报表">
                <CustomerTrendToolbar onFiltering={this.search} showChart={this.showChart} />
                <StdGrid
                    moduleName={this.moduleName}
                    gridRef={this.gridRef}
                    dataSource={this.state.data}
                    options={this.gridOptions}
                    columns={this.columns} />
                <CustomerTrendChart ref={this.chartRef} />
            </ContentBlock>
        )
    }
}