import './filling-delivering.scss';
import { bottleTypeService } from "../../../api";
import { organizationReportService } from "../../../api/reports";
import { baseApiUrl, download, http } from "../../../base";
import { ContentBlock } from "../../../components";
import { useGridHeight } from "../../../hooks";
import { BottleTypeModel, FillingDeliveringReport, FillingDeliveringReportCondition } from "../../../models";
import AuthorizedComponent from "../../authorized-component";
import FillingDeliveringToolbar from "./filling-delivering-toolbar";
import React from 'react';
import { LoadPanel, ScrollView } from 'devextreme-react';

interface FillingDeliveringReportPageState {
    bottleTypes: BottleTypeModel[];
    data: FillingDeliveringReport[];
    loading: boolean;
}

export default class FillingDeliveringReportPage
    extends AuthorizedComponent<any, FillingDeliveringReportPageState> {
    objectName = "Order";

    constructor(props: any) {
        super(props);

        this.state = {
            bottleTypes: [],
            data: [],
            loading: false
        };

        this.search = this.search.bind(this);
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.loadBottleTypes();
    }

    async loadBottleTypes() {
        const bottleTypes = await bottleTypeService.getRefs();
        this.setState({ bottleTypes: bottleTypes });
    }

    async search(condition: FillingDeliveringReportCondition) {
        this.setState({ loading: true });

        const data = await organizationReportService.getFillingDelivering(condition);
        this.setState({ data: data, loading: false });
    }

    async export(condition: FillingDeliveringReportCondition) {
        await download(
            `${baseApiUrl}${organizationReportService.apiPath}/fillingdelivering/export?${http.serialize([condition])}`,
            "充装与配送统计报表.xlsx");
    }

    render() {
        return (
            <ContentBlock caption="充装与配送统计报表">
                <FillingDeliveringToolbar onFiltering={this.search} onExporting={this.export} />
                <FillingDeliveringGrid bottleTypes={this.state.bottleTypes} data={this.state.data} loading={this.state.loading} />
            </ContentBlock>
        )
    }
}

interface GridProps extends FillingDeliveringReportPageState {
}

function FillingDeliveringGrid(props: GridProps) {
    const height = useGridHeight();
    const { bottleTypes, data, loading } = props;

    return (
        <div className="filling-delivering" style={{ height: height }}>
            <ScrollView width={"100%"} showScrollbar="always">
                <LoadPanel visible={loading} showPane={true} width={"100%"} height={"100%"}></LoadPanel>
                <table>
                    <thead>
                        <tr>
                            <th rowSpan={2}>站点/班组</th>
                            <th colSpan={4}>充装数量</th>
                            <th colSpan={4}>配送数量</th>
                            <th rowSpan={2}>充装与配送差额</th>
                            <th rowSpan={2}>充装与配送百分比</th>
                        </tr>
                        <tr>
                            {bottleTypes.map(t => (<th key={t.id}>{t.name}</th>))}
                            <th>共计</th>
                            {bottleTypes.map(t => (<th key={t.id}>{t.name}</th>))}
                            <th>共计</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(item => {
                            const filling = sum(item.fillingCount), delivering = sum(item.deliveringCount);

                            return (
                                <tr>
                                    <td>{item.organizationName}</td>
                                    {bottleTypes.map(t => (<td key={t.id}>{item.fillingCount[t.id!] || 0}</td>))}
                                    <td>{filling}</td>
                                    {bottleTypes.map(t => (<td key={t.id}>{item.deliveringCount[t.id!] || 0}</td>))}
                                    <td>{delivering}</td>
                                    <td>{filling - delivering}</td>
                                    <td>{!filling ? "N/A" : Math.round(delivering / filling * 1000) / 10 + "%"}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </ScrollView>
        </div>
    )
}

function sum(data: { [key: number]: number }) {
    let sum = 0;

    for (const key in data)
        sum += data[key];

    return sum;
}