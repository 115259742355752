import { http } from "../../base";
import { HomeCountReport, HomeTrendReport } from "../../models";

class HomeReportService {
    apiPath = "/api/report/home";

    getCountReport(countType: string,) {
        return http.getData<HomeCountReport>(`${this.apiPath}/count?countType=${countType}`);
    }

    getTrendReport(trendType: string) {
        return http.getData<HomeTrendReport>(`${this.apiPath}/trend?trendType=${trendType}`);
    }
}

export const homeReportService = new HomeReportService();