import React from "react";
import { areaService, customerTypeService, emptyDataSources, organizationService } from "../../api";
import { dateAdd, fdate, ListItem, today } from "../../base";
import { AreaRef, CustomerTypeRef, OrderForecast, OrderForecastCondition, OrganizationRef } from "../../models";
import { ValueChangedEvent as DateChangedEvent } from "devextreme/ui/date_box";
import { ValueChangedEvent as TextChangedEvent } from "devextreme/ui/text_box";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { Button, DateBox, SelectBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { OrgTreeDropDown } from "../../components";

interface OrderForecastToolbarProps {
    onFiltering: (condition: OrderForecastCondition) => void;
}

interface OrderForecastToolbarState {
    model: OrderForecast;
    orgs: OrganizationRef[];
    counties: AreaRef[];
    allStreets: AreaRef[];
    streets: AreaRef[];
    types: CustomerTypeRef[];
    condition: OrderForecastCondition;
}

export default class OrderForecastToolbar 
    extends React.Component<OrderForecastToolbarProps, OrderForecastToolbarState> {

    constructor(props: Readonly<OrderForecastToolbarProps>) {
        super(props);

        const startDate = today();

        this.state = {
            model: {},
            orgs: [],
            counties: [],
            allStreets: [],
            streets: [],
            types: [],
            condition: {
                startDate: fdate(startDate),
                endDate: fdate(dateAdd(startDate, 7))
            }
        };

        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onCountyChanged = this.onCountyChanged.bind(this);
        this.onStreetChanged = this.onStreetChanged.bind(this);
        this.onCustomerTypeChanged = this.onCustomerTypeChanged.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);
        this.onCustomerKeyChanged = this.onCustomerKeyChanged.bind(this);
        this.onDeliverKeyChanged = this.onDeliverKeyChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
    }

    componentDidMount() {
        this.loadDataSources();
    }

    async loadDataSources() {
        const dataSources = emptyDataSources();

        await Promise.all([
            (async () => dataSources.orgs = await organizationService.getRefs())(),
            (async () => dataSources.counties = await areaService.getCounties())(),
            (async () => dataSources.streets = await areaService.getStreets())(),
            (async () => dataSources.types = await customerTypeService.getRefs())()
        ]);

        this.setState({
            orgs: dataSources.orgs,
            counties: dataSources.counties,
            allStreets: dataSources.streets,
            streets: dataSources.streets,
            types: dataSources.types
        });
    }

    setModel(model: OrderForecast) {
        this.setState({ model: model });
    }

    onStartDateChanged(e: DateChangedEvent) {
        this.updateCondition({ startDate: e.value });
    }

    private updateCondition(updated: any) {
        const cond = { ...this.state.condition, ...updated };
        cond.startDate = fdate(cond.startDate);
        cond.endDate = fdate(cond.endDate);

        this.setState({ 
            condition: cond
        });
    }

    onEndDateChanged(e: DateChangedEvent) {
        this.updateCondition({ endDate: e.value });
    }

    onOrganizationChanged(organizationId?: number) {
        this.updateCondition({ organizationId: organizationId });
    }

    onCountyChanged(e: ValueChangedEvent) {   
        const streets = e.value ?
            this.state.allStreets.filter(s => s.parentId === e.value) :
            this.state.allStreets;

        const streetId = streets.find(s => s.id === this.state.condition.streetId) ? 
            this.state.condition.streetId : undefined;

        this.setState({ streets: streets });
        this.updateCondition({ countyId: e.value, streetId: streetId });
    }

    onStreetChanged(e: ValueChangedEvent) {
        this.updateCondition({ streetId: e.value });
    }

    onCustomerTypeChanged(e: ValueChangedEvent) {
        this.updateCondition({ typeId: e.value });
    }

    onStatusChanged(e: ValueChangedEvent) {
        this.updateCondition({ status: e.value });
    }
 
    onCustomerKeyChanged(e: TextChangedEvent) {
        this.updateCondition({ customerKey: e.value });
    }

    onDeliverKeyChanged(e: TextChangedEvent) {
        this.updateCondition({ deliverKey: e.value });
    }
    
    onFiltering() {
        this.props.onFiltering(this.state.condition);
    }

    render() {
        const { orgs, counties, streets, types, condition } = this.state;

        return (
            <div className="page-toolbar">
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto" text={"预计下单时间："} />
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={condition.startDate}
                            placeholder="开始时间"
                            width={120}
                            onValueChanged={this.onStartDateChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <DateBox
                            value={condition.endDate}
                            placeholder="结束时间"
                            width={120}
                            onValueChanged={this.onEndDateChanged} />
                    </Item>
                </Toolbar>
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={condition.organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={condition.typeId}
                            dataSource={types}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder={"客户类型"}
                            onValueChanged={this.onCustomerTypeChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={condition.countyId}
                            dataSource={counties}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder={"行政区域"}
                            onValueChanged={this.onCountyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={condition.streetId}
                            dataSource={streets}
                            displayExpr="name"
                            valueExpr={"id"}
                            searchEnabled={true}
                            showClearButton={true}
                            width={100}
                            placeholder="街道"
                            onValueChanged={this.onStreetChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={condition.status}
                            dataSource={statusList}
                            displayExpr="text"
                            valueExpr={"value"}
                            showClearButton={true}
                            width={80}
                            placeholder="状态"
                            onValueChanged={this.onStatusChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <TextBox
                            value={condition.customerKey}
                            showClearButton={true}
                            placeholder="客户名称或卡号"
                            onValueChanged={this.onCustomerKeyChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <TextBox
                            value={condition.deliverKey}
                            showClearButton={true}
                            placeholder="送气工姓名或工号"
                            onValueChanged={this.onDeliverKeyChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}

const statusList: ListItem<string|undefined>[] = [
    { value: "Normal", text: "正常" },
    { value: "Exception", text: "异常" }
]