import React from "react";
import { webMessageService } from "../../api";
import { alertError, confirm2, notifySuccess } from "../../base";
import { ContentBlock } from "../../components";
import { WebMessageModel } from "../../models";
import WebMessageGrid from "./web-message-grid";
import WebMessageToolbar from "./web-message-toolbar";

export default class WebMessagePage extends React.Component {
    gridRef = React.createRef<WebMessageGrid>();
    toolbarRef = React.createRef<WebMessageToolbar>();

    constructor(props: any) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    onModelSelected(model: WebMessageModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onFiltering(filterValue: any) {
        this.gridRef.current?.filter(filterValue);
    }

    onButtonClick(model: WebMessageModel, name: string) {
        switch (name) {
            case "read": 
                this.read(model);
                break;

            case "readall":
                this.readAll();
                break;
        }
    }

    async read(model: WebMessageModel) {
        const result = await webMessageService.read(model.id!);
        
        if (result.succeeded) {
            notifySuccess("成功设置为已读");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    async readAll() {
        if (!await confirm2("确定要将所有消息都设置为已读吗？")) {
            return;
        }
        
        const result = await webMessageService.readAll();
        
        if (result.succeeded) {
            notifySuccess("已成功设置为全部已读");
            this.gridRef.current?.refresh();
        } else {
            alertError(result.message!);
        }
    }

    render() {     
        return (
            <ContentBlock caption={"消息提醒"}>
                <WebMessageToolbar
                    ref={this.toolbarRef}
                    onFiltering={this.onFiltering}
                    onButtonClick={this.onButtonClick} />
                <WebMessageGrid 
                    ref={this.gridRef} 
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
            </ContentBlock>
        );
    }

}