import './header.scss';
import UserBox from "./user-box";
import { useFavorite } from '../../hooks';
import { useCallback } from 'react';
import { confirm2 } from '../../base';
import { Button } from 'devextreme-react';
import MessageBox from './message-box';

export default function Header({ toggleMenu }: any) {
    const { pageCaption, inFavorite, addFavorite, removeFavorite } = useFavorite();

    const buttonText = inFavorite ? "从收藏夹移除" : "添加到收藏夹";
    const icon = inFavorite ? "bi bi-star-fill" : "bi bi-star";

    const toggleFavorite = useCallback(async function() {
        const actionText = inFavorite ? "从收藏夹中移除该页" : "将该页添加到收藏夹";
        if (await confirm2(`确定要${actionText}吗？`)) {
            inFavorite ? removeFavorite!() : addFavorite!(pageCaption!);
        }
    }, [inFavorite, removeFavorite, addFavorite, pageCaption]);

    return (
        <header className={'header-component'}>
            <div className={"header-bar"}>
                <div className={"logo-container"}>
                    <img src={"/titled-logo.png"} alt="" />
                </div>
                <div className={"menu-toggle-container"}>
                    <Button icon={'menu'} stylingMode={'text'} onClick={toggleMenu} />
                </div>
                <div className={"header-caption"}>
                    <h3 onClick={toggleFavorite}>
                        <i title={buttonText} className={icon}></i>
                        {pageCaption}
                    </h3>
                </div>
                <div className={"header-user"}>
                    <MessageBox />
                    <UserBox />
                </div>
            </div>
        </header>
    );
}