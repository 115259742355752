import { http, DataMap, MessageBool } from "../base";

class ExceptionSettingService {
    apiPath = "/api/exceptionsetting";

    getAll(): Promise<DataMap<string>> {
        return http.getData(this.apiPath);
    }

    save(model: DataMap<string>): Promise<MessageBool> {
        return http.putData(this.apiPath, model);
    }
}

export const exceptionSettingService = new ExceptionSettingService();