import React from "react";
import { Popup } from "devextreme-react";
import Form, { IItemProps } from "devextreme-react/form";
import { bottleDebtService, serverDataService } from "../../api";
import { alertError, alertWarning, notifySuccess, ModuleConfig, StringMap, dataSources, enums } from "../../base";
import { createPopupField, CustomerPopup, PopupContentBox, RefPopup, StdForm, FormMultiImageUploader } from "../../components";
import { BottleDebtModel, CustomerModel } from "../../models";

export interface BottleDebtNewFormProps {
    onSaved: () => void;
}

interface BottleDebtNewFormState {
    items: IItemProps[];
    bottleDebt: BottleDebtModel;
}

export default class BottleDebtNewForm extends React.Component<BottleDebtNewFormProps, BottleDebtNewFormState> {
    moduleName: ModuleConfig = {
        moduleName: "BottleDebt",
        children: {
            "customer": "Customer"
        }
    };

    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();
    customerRef = React.createRef<CustomerPopup>();
    nameMap: StringMap = { "_customerName": "customerId" };

    constructor(props: Readonly<BottleDebtNewFormProps>) {
        super(props);

        this.state = {
            bottleDebt: { chargedAt: new Date() },
            items: [
                createPopupField("_customerName", this.customerRef),
                {
                    dataField: 'type',
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: dataSources.deptTypes, displayExpr: 'text', valueExpr: 'value' }
                },
                {
                    dataField: 'chargedAt',
                    editorType: 'dxDateBox',
                    editorOptions: { type: "datetime" }
                },
                { dataField: 'code', editorOptions: { placeholder: "留空由服务器自动生成" } },
                { dataField: 'deposit' },
                ...serverDataService.bottleTypes.map(t => ({
                    dataField: `item_${t.id}`,
                    label: { text: t.name },
                    editorType: 'dxNumberBox'
                })),
                {
                    dataField: "photo",
                    colSpan: 2,
                    render: (e: any) => {
                        return (
                            <FormMultiImageUploader
                                formData={this.state.bottleDebt}
                                dataField={"photo"}
                                sourceField={"photoSource"}
                                id={"bottleDebtPhoto"} />
                        )
                    }
                },
                { dataField: 'note', colSpan: 2 }
            ]
        };

        this.onCustomerSelected = this.onCustomerSelected.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    show() {
        this.popupRef.current?.instance.show();
    }

    async onCustomerSelected(customer: CustomerModel) {
        const bottleDebt = {
            ...this.state.bottleDebt,
            customer: customer,
            customerId: customer.id,
            _customerName: `${customer.name}（${customer.gasCode}）`
        };

        this.setState({ bottleDebt: bottleDebt });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const valid = this.formRef.current?.instance.validate();
        if (!valid?.isValid) {
            return;
        }

        const bottleDebt = this.state.bottleDebt;
        if (bottleDebt.type === enums.debtTypes.deposit && !bottleDebt.deposit) {
            alertWarning("请输入押金");
            return;
        }

        const items = serverDataService.bottleTypes
            .map(t => ({
                bottleTypeId: t.id,
                number: (bottleDebt as any)[`item_${t.id}`]
            }))
            .filter(t => t.number);

        if (!items.length) {
            alertWarning(`请输入${bottleDebt.type === enums.debtTypes.deposit ? '押瓶' : '欠瓶'}的数量`);
            return;
        }

        bottleDebt.items = items;
        const result = await bottleDebtService.create(bottleDebt);

        if (result.succeeded) {
            notifySuccess("已成功保存数据");
            this.hide();
            this.props.onSaved();
        } else {
            alertError(result.message!);
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"添加押瓶、欠瓶信息"}
                width={800}
                height={460}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        colCount={2}
                        items={this.state.items}
                        nameMap={this.nameMap}
                        formData={this.state.bottleDebt} />
                </PopupContentBox>
                <CustomerPopup ref={this.customerRef} onCustomerSelected={this.onCustomerSelected} />
            </RefPopup>
        )
    }
}